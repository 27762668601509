import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filter, find, get, isArray, isUndefined } from 'lodash';
import { Box, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { Add, Remove } from '@mui/icons-material/';
import {
  GridRenderCellParams,
  GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { getFacilities, setFavouriteFacilities, setFavouriteFacility } from './_api';
import { IFunctionItem } from './_types';
import useAlerts from 'components/Alerts/useAlerts';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import Button from 'components/Buttons/Button';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { EXCHANGE_NETWORK_EPACS, EXCHANGE_NETWORK_REDIMED } from 'constants/constants';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'userProfileFacilitiesMui';

const UserProfileFacilitiesComponent: React.FC = () => {
  const { t } = useTranslation('Facilities');
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();

  const [facilities, setFacilities] = useState<any[]>([]);
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const { toggleLoader } = useAppGlobals();

  const loadFacilities = useMemo(
    () => async () => {
      toggleLoader();
      const data = await getFacilities();
      if (isArray(data)) {
        await setFacilities(filter(data, ['activeFacility', false]));
      }
      toggleLoader(false);
    },
    [toggleLoader],
  );

  useEffect(() => {
    loadFacilities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const changeFacility = async (id: string, state: boolean) => {
    toggleLoader();
    const changed = await setFavouriteFacility(id, state);
    if (changed) {
      await loadFacilities();
      addSuccessAlert(t('changeFacilities.success'));
    } else {
      addErrorAlert(t('changeFacilities.error'));
    }
    toggleLoader(false);
  };
  const changeFacilities = useMemo(
    () => async (state: boolean) => {
      toggleLoader();
      const changed = await setFavouriteFacilities(selection, state);
      if (changed) {
        await setSelection([]);
        await loadFacilities();
        addSuccessAlert(t('changeFacilities.success'));
      } else {
        addErrorAlert(t('changeFacilities.error'));
      }
      toggleLoader(false);
    },
    [addErrorAlert, addSuccessAlert, loadFacilities, selection, t, toggleLoader],
  );

  const confirmActionChangeFavourite = () => {
    changeFacility(get(confirmationData, 'id'), get(confirmationData, 'state'));
    clearAction();
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      [
        {
          field: 'actions',
          headerName: t('Grid:actions'),
          type: 'actions',
          hideable: false,
          width: 250,
          renderCell: ({ row }: GridRenderCellParams) => (
            <Box sx={{ width: '200px' }}>
              <FormControlLabel
                sx={{ maxHeight: 30 }}
                control={
                  <Switch
                    checked={row.favourite}
                    onChange={(e, state) => {
                      dispatch(
                        storeConfirmationDataToStore({
                          title: 'Grid:confirmChangeState',
                          id: get(row, 'id'),
                          state,
                          confirmAction: 'confirmActionChangeFavourite',
                        }),
                      );
                    }}
                  />
                }
                label={t('Grid:favourite') as string}
              />
            </Box>
          ),
        },
        { field: 'name', headerName: t('facility') },
        { field: 'code', headerName: t('code') },
        { field: 'city', headerName: t('city') },
        { field: 'country', headerName: t('country') },
        {
          field: 'epacs',
          headerName: t('epacs'),
          valueGetter: (value: any, row: any) => {
            const out = find(row.facilityExchangeNetworkModels, (item) => {
              return get(item, 'exchangeNetwork.name', null) === EXCHANGE_NETWORK_EPACS;
            });
            return out ? `${EXCHANGE_NETWORK_EPACS} (${get(out, 'identifier', '')})` : null;
          },
        },
        {
          field: 'redimed',
          headerName: t('redimed'),
          valueGetter: (value: any, row: any) => {
            const out = find(row.facilityExchangeNetworkModels, (item) => {
              return get(item, 'exchangeNetwork.name', null) === EXCHANGE_NETWORK_REDIMED;
            });
            return out ? `${EXCHANGE_NETWORK_REDIMED} (${get(out, 'identifier', '')})` : null;
          },
        },
      ],
      200,
    ),
  );

  const functionList: IFunctionItem[] = useMemo(
    () => [
      {
        key: 'addFavourite',
        label: t('addFavourite'),
        show: true,
        onClick: () => {
          changeFacilities(true);
        },
        icon: <Add fontSize="small" />,
        color: 'primary',
      },
      {
        key: 'removeFavourite',
        label: t('removeFavourite'),
        show: true,
        onClick: () => {
          changeFacilities(false);
        },
        icon: <Remove fontSize="small" />,
        color: 'error',
      },
    ],
    [t, changeFacilities],
  );

  const QuickSearchToolbar = useCallback(
    (props: any) => {
      return (
        <div>
          <Box style={{ padding: '5px', display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
            </Box>
          </Box>
          <Box
            sx={{
              px: 1,
              py: 0.5,
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            {functionList.map((item: IFunctionItem, index: number) => {
              const showCount = isUndefined(item.showCount) ? true : item.showCount;
              const count = selection.length;
              const xcount = showCount && count ? ` (${count})` : '';
              const maxAllowedItem = isUndefined(item.maxAllowedItem) ? 0 : item.maxAllowedItem;
              return (
                <Tooltip key={index} title={item.label + xcount}>
                  <span>
                    <Button
                      key={item.key}
                      variant="contained"
                      color={item.color}
                      onClick={() => item.onClick(null)}
                      disabled={
                        selection.length === 0 ||
                        (maxAllowedItem > 0 && maxAllowedItem < selection.length)
                      }
                      sx={{ mr: 0.5, mb: 0.5 }}
                    >
                      {item.icon}
                    </Button>
                  </span>
                </Tooltip>
              );
            })}
          </Box>
        </div>
      );
    },
    [selection, functionList],
  );

  return (
    <>
      <MuiGrid
        gridKey={muiGridKey}
        rows={facilities}
        columns={columns}
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
        rowSelecting={{ selecting: true, selection, setSelection }}
        toolbar={QuickSearchToolbar}
      />
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmActionChangeFavourite}
        />
      )}
    </>
  );
};

export default UserProfileFacilitiesComponent;
