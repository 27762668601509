import HelperComponent from 'components/Tour/HelperComponent';
import { getDropDownStep } from 'components/Tour/_dropDownStep';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';
export const TourAuditLogs = () => {
  const { t } = useTranslation('Reactour');

  const allSteps = ({ isDropDownOpen }: any) => {
    const steps = {
      '[data-tour="auditLogsForm"]': t('auditLogs.form'),
      ...getDropDownStep({ isDropDownOpen, t }),
      '[data-tour="search-header-searchForm-searchButton"]': t('searchList.searchButton'),
      '[data-tour="auditLogsGrid"]': t('auditLogs.grid'),
      '[data-tour="common-gridColumns"]': t('common.gridColumns'),
      '[data-tour="common-gridFilters"]': t('common.gridFilters'),
    };
    return steps;
  };

  useReactTour({ stepsDefinition: allSteps });

  return <HelperComponent />;
};
