import { Box, Button } from '@mui/material';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import PatientAndStudyEditForm from 'modules/Studies/Form/PatientAndStudyEditForm';
import { FormProvider, useForm } from 'react-hook-form';
import { get, omit, omitBy } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

import { useTranslation } from 'react-i18next';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import useValidationSchema from '../_form';
import { useEffect, useMemo } from 'react';
import { format, parse } from 'date-fns';
import { IUpdatedStudies } from '../_types';
import { IPatient } from 'components/Import/_types';

const EditDicomTagsDialog: React.FC<any> = ({
  editDialogState,
  toggleEditDialog,
  patientForEditDialog,
  setUpdatedStudies,
  updatePatients,
}) => {
  const { t } = useTranslation('Import');
  const { validators, portalSetting } = useAppInfo();

  const { EditDicomTagsValidationScheme } = useValidationSchema(
    t,
    get(validators, 'importForm', {}),
    portalSetting,
  );

  const parseDicomTags = (data: IPatient) => {
    const name = get(data, 'name', '').split('^');
    const dateBirth = get(data, 'dateBirth');
    return {
      name: {
        lastName: get(name, '[0]', ''),
        firstName: get(name, '[1]', ''),
        middleName: get(name, '[2]', ''),
        prefix: get(name, '[3]', ''),
        suffix: get(name, '[4]', ''),
      },
      identificationNumber: get(data, 'patID', ''),
      sex: get(data, 'patSex', ''),
      dateBirth: dateBirth ? parse(dateBirth, 'yyyyMMdd', new Date()) : null,
    };
  };

  const methods = useForm<any>({
    resolver: yupResolver(EditDicomTagsValidationScheme),
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const initialValues = useMemo(() => parseDicomTags(patientForEditDialog), [patientForEditDialog]);

  useEffect(() => {
    reset({ patient: initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientForEditDialog]);

  const onSubmit = async (values: IUpdatedStudies) => {
    const cleanName = omitBy(values.patient.name, (value: any) => value === '' || value === null);
    const patientWithoutName = omit(values.patient, 'name');
    const patientWithoutNameFormatted = {
      ...patientWithoutName,
      dateBirth: format(patientWithoutName.dateBirth, `yyyy-MM-dd'T'HH:mm:ss`),
    };

    setUpdatedStudies((prev: IUpdatedStudies[]) => {
      return [
        ...prev,
        {
          studyInstanceUid: patientForEditDialog.studyInstanceUid,
          patient: { ...patientWithoutNameFormatted, name: cleanName },
        },
      ];
    });
    updatePatients(values.patient, initialValues.identificationNumber);
    toggleEditDialog(false);
  };

  return (
    <FormProvider {...methods}>
      <form>
        <OverflowedDialog
          title={t('dicom.editDicomTagsDialog')}
          open={editDialogState}
          onClose={() => {
            toggleEditDialog(false);
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth={true}
          actions={
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                '@media print': {
                  display: 'none',
                },
              }}
            >
              <Button
                onClick={() => toggleEditDialog(false)}
                variant="contained"
                size="large"
                color="inherit"
                sx={{ mr: 1 }}
              >
                {t('close')}
              </Button>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                type="submit"
              >
                {t('confirm')}
              </Button>
            </Box>
          }
        >
          <PatientAndStudyEditForm errors={errors} isStudyEditType={false} />
        </OverflowedDialog>
      </form>
    </FormProvider>
  );
};

export default EditDicomTagsDialog;
