import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header/Header';
import PatientSearchForm from './PatientSearchForm';
import PatientSearchResults from './PatientSearchResults';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourClinicalPortalByPatient } from './TourClinicalPortalByPatient';
import { Box } from '@mui/material';

export const ClinicalPortalByPatient: React.FC = () => {
  const { t } = useTranslation('ClinicalPortal');
  useWithTitle(); // sets title to document
  const { patients } = useAppInfo();

  const [canShowResults, setCanShowResults] = useState<boolean>(false);

  const renderedSteps = () => {
    return <TourClinicalPortalByPatient canShowResults={canShowResults} />;
  };

  return (
    <>
      <Header title={t('title')} TourComponent={renderedSteps()} />
      <Box data-tour="patientSearchForm">
        <PatientSearchForm setCanShowResults={setCanShowResults} />
      </Box>

      {canShowResults && <PatientSearchResults items={patients} />}
    </>
  );
};
