import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';
import { find, get } from 'lodash';

const useValidationSchema = (
  t: TFunction,
  languages: any[],
  dictionary: string,
  isCreating: boolean,
) => {
  const { required, dicomTag } = useValidators();

  const language = find(languages, { default: true });

  const DictionaryFormSchema = yup.object().shape({
    ...(dictionary === 'dose'
      ? {
          dicomTag: dicomTag(required(yup.string())),
        }
      : {}),
    ...(dictionary === 'studyDetailBookmarks'
      ? {
          type: required(yup.string()),
          order: required(yup.number()),
        }
      : {}),
    ...(dictionary === 'decubits'
      ? {
          code: required(yup.string()),
          bodyPartExaminated: required(yup.object().nullable()),
          imageLaterality: required(yup.string()),
          patientPosition: required(yup.object().nullable()),
        }
      : {}),
    ...(dictionary !== 'dose' && dictionary !== 'studyDetailBookmarks' && dictionary !== 'decubits'
      ? {
          code: required(yup.string()),
          value: required(yup.string()),
        }
      : {}),
    [`text_${get(language, 'abbreviation')}`]: required(yup.string().trim()),
  });

  const PhysicianFormSchema = yup.object().shape({
    lastName: required(yup.string()),
    firstName: required(yup.string()),
  });

  const physicianFormItems = [
    {
      name: 'lastName',
      label: t('lastName'),
      required: true,
      disabled: !isCreating,
    },
    {
      name: 'firstName',
      label: t('firstName'),
      required: true,
      disabled: !isCreating,
    },
    {
      name: 'middleName',
      label: t('middleName'),
      disabled: !isCreating,
    },
    {
      name: 'prefix',
      label: t('prefix'),
      disabled: !isCreating,
    },
    {
      name: 'suffix',
      label: t('suffix'),
      disabled: !isCreating,
    },
  ];

  return { DictionaryFormSchema, PhysicianFormSchema, physicianFormItems };
};

export default useValidationSchema;
