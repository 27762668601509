import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';

const useValidationSchema = (t: TFunction) => {
  const { required, min, max } = useValidators();

  const FacilityFormSchema = yup.object().shape({
    code: max(min(required(yup.string()), 4, 'minMaxLength234'), 4, 'minMaxLength234'),
    name: required(yup.string()),
    city: required(yup.string()),
    country: required(yup.string()),
  });

  const formItems = [
    {
      name: 'code',
      label: t('code'),
      type: 'number',
      required: true,
    },
    {
      name: 'name',
      label: t('name'),
      required: true,
    },
    {
      name: 'city',
      label: t('city'),
      required: true,
    },
    {
      name: 'country',
      label: t('country'),
      required: true,
    },
  ];

  const FacilityExchangeNetworkFormSchema = yup.object().shape({
    identifier: required(yup.string()),
  });
  const formItemsForFacilityEchangeNetwork = [
    {
      name: 'identifier',
      label: t('facilityExchangeNetwork.identifier'),
      required: true,
    },
    {
      type: 'select',
      name: 'exchangeNetworkId',
      label: t('facilityExchangeNetworks'),
      required: true,
    },
  ];

  return {
    FacilityFormSchema,
    formItems,
    FacilityExchangeNetworkFormSchema,
    formItemsForFacilityEchangeNetwork,
  };
};

export default useValidationSchema;
