import React from 'react';
import { CircularProgress, Paper } from '@mui/material';

import { IPapeer } from './_types';
import { Box } from '@mui/system';
import { useAppInfo } from 'utils/hooks/useAppInfo';

export const Papeer: React.FC<IPapeer> = ({
  children,
  bottomMargin = false,
  topMargin = false,
  loading = false,
  sx = {},
  id = undefined,
}) => {
  const { compactMode } = useAppInfo();

  return (
    <Paper
      id={id}
      elevation={0}
      sx={{
        p: compactMode ? 1 : 2,
        mb: bottomMargin ? (compactMode ? 1 : 2) : 0,
        mt: topMargin ? (compactMode ? 1 : 2) : 0,
        ...(loading
          ? {
              position: 'relative',
              '&:after': {
                content: '" "',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                position: 'absolute',
                background: 'rgba(255, 255, 255, .6)',
              },
            }
          : {}),
        ...sx,
      }}
    >
      {loading && (
        <Box
          sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
        >
          <CircularProgress color="primary" size={60} />
        </Box>
      )}
      {children}
    </Paper>
  );
};
