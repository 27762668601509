import { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';

import Import from 'components/Import/Import';
import { useDicomParser } from 'components/Import/useFileParser';
import useAlerts from 'components/Alerts/useAlerts';
import { Announcement } from 'components/Announcement/Announcement';
import { sendMetadata } from './_api';

import { getRules } from 'modules/Import/_api';
import { join, get } from 'lodash';
import FilesGrid from 'components/Import/FilesGrid';
import { FormProvider, useForm } from 'react-hook-form';

import { createUppy } from 'utils/import';
import { Papeer } from 'components/Papeer/Papeer';
import CustomUppyFolderInput from 'modules/Import/CustomUppyFolderInput';
import CustomUppyFileInput from 'modules/Import/CustomUppyFileInput';
import { CloseButtonDialog } from 'components/Dialog/CloseDialogButton';

const OrderDetailAttachmentForm: React.FC<any> = ({
  order,
  loadOrder,
  refetchGrid,
  setActiveTab,
  handleDialog,
}) => {
  const [files, setFiles] = useState<any[]>([]);
  const [rules, setRules] = useState<any[]>([]);
  const { parseNonDicomFiles } = useDicomParser({ setFiles });
  const [timestamp, setTimestamp] = useState(new Date().getTime().toString());
  const [uppy, setUppy] = useState(() => createUppy('uppy', parseNonDicomFiles, timestamp));

  const { toggleLoader } = useAppGlobals();
  const { user } = useUser();
  const { t } = useTranslation('Import');
  const { addErrorAlert } = useAlerts();

  const methods = useForm<any>();
  const { handleSubmit } = methods;

  const removeFile = (id: any) => {
    uppy.removeFile(id);
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const removeAllFiles = () => {
    uppy.getFiles().forEach((file) => uppy.removeFile(file.id));
    setFiles([]);
  };

  const onSubmit = async (values: any) => {
    toggleLoader();
    // TODO pořešit chybové stavy
    uppy.upload().then(async () => {
      // Upload complete
      const resp = await sendMetadata({
        accessionNumber: order?.accessionNumber,
        patientId: order?.patient?.identificationNumber,
        path: `${timestamp}_${user?.sub}`,
        timestamp,
        typeOfUpload: 'requestsAttachment',
      });
      toggleLoader(false);
      if (resp) {
        setFiles([]);
        const newTimestamp = new Date().getTime().toString();
        setTimestamp(newTimestamp);
        setUppy(() =>
          createUppy(
            'uppy',
            parseNonDicomFiles,
            newTimestamp,
            get(rules, 'requestsAttachment', []),
          ),
        );
        await loadOrder();

        handleDialog('attachments', false);
        setActiveTab('comments');
        setTimeout(() => {
          setActiveTab('attachments');
        }, 300);

        refetchGrid();
      } else {
        addErrorAlert(t('error'));
      }
    });
  };

  const loadRules = async () => {
    const resp = await getRules();
    if (resp) {
      setRules(resp);
      setUppy(() =>
        createUppy('uppy', parseNonDicomFiles, timestamp, get(resp, 'requestsAttachment', [])),
      );
    }
  };

  useEffect(() => {
    loadRules();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCLoseDialog = () => {
    handleDialog('attachments', false);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CloseButtonDialog onClose={() => handleCLoseDialog()} />
        <Papeer>
          {!files.length && (
            <Announcement
              label={t('allowedExtensions', {
                rules: join(get(rules, 'requestsAttachment', []), ', '),
              })}
              type={'info'}
              spaced={true}
              component={'div'}
            />
          )}
          <Grid container spacing={2} mt={2}>
            {!files.length && (
              <Grid item xs={12}>
                <Import uppy={uppy} />
              </Grid>
            )}

            {!!files.length && (
              <>
                <Grid item xs={12}>
                  <FilesGrid files={files} removeFile={removeFile} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  sx={{ mt: 1, gap: 1 }}
                >
                  <Button variant="contained" color="inherit" onClick={removeAllFiles}>
                    {t('Import:removeAll')}
                  </Button>
                  <CustomUppyFolderInput uppy={uppy} />
                  <CustomUppyFileInput uppy={uppy} typeOfFiles="application/pdf" />
                </Grid>
              </>
            )}
          </Grid>
        </Papeer>

        {!!files.length && (
          <Grid item xs={12} container justifyContent="flex-end" alignItems="center" marginTop={2}>
            <Button variant="contained" type="submit" disabled={!files.length}>
              {t('confirm')}
            </Button>
          </Grid>
        )}
      </form>
    </FormProvider>
  );
};

export default OrderDetailAttachmentForm;
