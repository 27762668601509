import React, { useEffect, useState } from 'react';
import { compact, find, findIndex, get, isArray, isEmpty, keys, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import webstomp from 'webstomp-client';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import { Box, Button, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import { getAllDoseDictionary } from './_api';
import { getStudies } from 'modules/Search/_api';
import {
  IRowDetailHeader,
  IRowsAttr,
  IDoseDictionary,
  IStudyCsv,
  IRowsAttrEn,
  IRowDetailAttribute,
  IDicomTag,
} from './_types';
import { IArchive, IStudy, IStudyResultForGrid } from 'modules/Search/_types';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useActions } from 'utils/hooks/useActions';
import { generateIID } from 'utils/study';
import { useUser } from 'utils/hooks/useUser';
import { useLanguage } from 'utils/hooks/useLanguage';
import { findTranslation } from 'utils/grid/translate';
import { isIE11 } from 'utils/variables';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import Header from 'components/Header/Header';
import useAlerts from 'components/Alerts/useAlerts';
import { DOSE_MONITORING, SEARCH_FUNCTION_DOSE_MONITORING } from 'constants/constants';
import GridDicomAttributes from './GridDicomAttributes';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourDoseMonitoring } from './TourDoseMonitoring';
import { GridRenderCellParams, GridRowId, GridRowParams } from '@mui/x-data-grid-pro';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const translationModule = 'Studies';

const unitDlp = 'mGy*cm';
const unitDap = 'mGy·cm^2';

const localeForHeader = 'cs'; // 'en'
const muiGridKey = 'doseMonitoringCalculateMui';

const DoseMonitoringCalculate: React.FC = () => {
  const { t } = useTranslation('Studies');
  useWithTitle(); // sets title to document
  const { toggleLoader } = useAppGlobals();
  const { resetStudies } = useActions();
  const { archivesForFunctions, feConfig } = useAppInfo();
  const { doseMonitoringStore } = useStudyInfo();
  const archives = get(archivesForFunctions, SEARCH_FUNCTION_DOSE_MONITORING);

  const { user } = useUser();
  const navigate = useNavigate();
  const { currentLocale } = useLanguage();
  const { addErrorAlert } = useAlerts();

  const [studies, setStudies] = useState<any[]>([]);
  const [studiesCsv, setStudiesCsv] = useState<IStudyCsv[]>([]);
  const [renderDownload, setRenderDownload] = useState<boolean>(false);
  const [doseDictionaries, setDoseDictionaries] = useState<IDoseDictionary[]>([]);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([]);

  let [searchParams] = useSearchParams();
  const patientId = searchParams.get('patientId') || null;
  const isExportCsv = (searchParams.get('export') || null) === 'csv';
  const detailIID = searchParams.get('backTo') || null;
  const linkBack = detailIID ? `/study/${detailIID}` : `/studies`;

  const firstRowCsv = {
    1: findTranslation(localeForHeader, translationModule, 'patientName'),
    2: findTranslation(localeForHeader, translationModule, 'accessionNumber'),
    3: findTranslation(localeForHeader, translationModule, 'dateTime'),
    4: findTranslation(localeForHeader, translationModule, 'modalitiesInStudy'),
    5: findTranslation(localeForHeader, translationModule, 'patientWeight'),
    6: findTranslation(localeForHeader, translationModule, 'patientSize'),
    7: findTranslation(localeForHeader, translationModule, 'archive'),
    8: findTranslation(localeForHeader, translationModule, 'bodyPartExamined'),
    9: findTranslation(localeForHeader, translationModule, 'dose'),
  };

  const getDicomTags = (dicomTags: IDicomTag[] | null, language: string) => {
    let settingsObject = {};
    if (dicomTags) {
      dicomTags.forEach((item: IDicomTag) => {
        const dicomTag = get(
          get(item, 'name.name'),
          language,
          `${get(item, 'name.dicomTag', '?')}`,
        );
        settingsObject = { ...settingsObject, [dicomTag]: get(item, 'value') };
      });
    } else {
      return null;
    }
    return settingsObject;
  };
  const getDicomTagLabel = (key: string, language: string, wrapper: string = '') => {
    const language2 = isExportCsv ? localeForHeader : language;
    const wrapper2 = isExportCsv ? `'` : wrapper;
    let dicomTagLabel = `${wrapper2}${key}${wrapper2}`;
    const doseDictionary = find(doseDictionaries, { dicomTag: key });
    if (doseDictionary) {
      const name = JSON.parse(get(doseDictionary, 'name', '{}'));
      if (name) {
        const localeName = get(name, language2, '');
        if (localeName) {
          dicomTagLabel = `${localeName} ${isExportCsv ? dicomTagLabel : key}`;
        }
      }
    }
    return dicomTagLabel;
  };
  const getDicomTagUnit = (key: string) => {
    let dicomUnit: string | null = '';
    const doseDictionary = find(doseDictionaries, { dicomTag: key });
    if (doseDictionary) {
      dicomUnit = get(doseDictionary, 'unit', '');
    }
    return dicomUnit;
  };

  const setStudiesStateAndPrepareCsv = (studies: any) => {
    const rowsAttr: IRowsAttr[] = [];
    const rowsAttrEn: IRowsAttrEn[] = [];
    const studiesWithAttributes = studies.map((study: any) => {
      const dicomTagsStudy = getDicomTags(get(study, 'dicomTagsStudy', null), currentLocale);
      const dicomTagsStudyEn = getDicomTags(get(study, 'dicomTagsStudy', null), localeForHeader);
      const series = get(study, 'series', null);
      if (series) {
        series.forEach((serie: any) => {
          const dicomTagsSeries = getDicomTags(get(serie, 'tags', null), currentLocale);
          const dicomTagsSeriesEn = getDicomTags(get(serie, 'tags', null), localeForHeader);
          const instances = get(serie, 'instances', null);
          if (instances) {
            instances.forEach((instance: any) => {
              const dicomTagsInstances = getDicomTags(get(instance, 'tags', null), currentLocale);
              rowsAttr.push({
                seriesUID: get(serie, 'uid', null),
                instancesUID: get(instance, 'uid', null),
                dicomTagsStudy,
                dicomTagsSeries,
                dicomTagsInstances,
              });
            });
          } else {
            rowsAttr.push({
              seriesUID: get(serie, 'uid', null),
              dicomTagsStudy,
              dicomTagsSeries,
            });
          }
          rowsAttrEn.push({
            seriesUID: get(serie, 'uid', null),
            dicomTagsStudyEn,
            dicomTagsSeriesEn,
          });
        });
      } else {
        rowsAttr.push({ dicomTagsStudy });
        rowsAttrEn.push({ dicomTagsStudyEn });
      }
      return { ...study, attributes: rowsAttr, attributesEn: rowsAttrEn };
    });
    let studiesWithDoseHead: any = null;
    const studiesCsv: IStudyCsv[] = [];
    let rowDetailAttributes: IRowDetailAttribute[] = [];
    let rowDetailHeaders: IRowDetailHeader[] = [];
    const rowDetailUnits: any[] = [];
    const studiesWithDoses = studiesWithAttributes.map((study: any) => {
      rowDetailAttributes = [];
      const studiesWithDose: any[] = [];
      const patientName = compact([
        get(study, 'patient.name.lastName', ''),
        get(study, 'patient.name.firstName', ''),
        get(study, 'patient.name.prefix'),
        get(study, 'patient.name.suffix'),
      ])
        .join(', ')
        .trim();
      const accessionNumber = get(study, 'accessionNumber') || '';
      const dateTime = get(study, 'dateTime', false)
        ? format(new Date(study.dateTime), 'dd.MM.yyyy, HH:mm')
        : '';
      const modality = isArray(get(study, 'modalitiesInStudy'))
        ? get(study, 'modalitiesInStudy').join(', ')
        : '';
      const patientWeight = get(study, 'patientDispositions.patientWeight', '');
      const patientSize = get(study, 'patientDispositions.patientSize', '');
      const archive = get(study, 'archive.name', '');

      const exposures = get(study, 'exposures', null);
      const exposuresLength = isArray(exposures) && !isEmpty(exposures) ? exposures.length : 0;
      const bodyPartExamined =
        exposuresLength > 0 ? get(exposures[0], 'bodyPartExamined') || '' : '';
      let dose =
        exposuresLength > 0
          ? get(exposures[0], 'dlp', 0) > 0
            ? get(exposures[0], 'dlp', 0)
            : get(exposures[0], 'dap', 0)
          : '';
      dose = dose.toString().replace('.', ',');
      const unitDose =
        exposuresLength > 0 ? (get(exposures[0], 'dlp', 0) > 0 ? unitDlp : unitDap) : '';
      const row = {
        1: patientName,
        2: accessionNumber,
        3: dateTime,
        4: modality,
        5: patientWeight,
        6: patientSize,
        7: archive,
        8: bodyPartExamined,
        9: `${dose} ${unitDose}`,
      };
      studiesWithDose.push(row);

      if (exposuresLength > 1) {
        exposures.forEach((exposure: any, index: number) => {
          if (index > 0) {
            const exposureBodyPartExamined = get(exposure, 'bodyPartExamined', '') || '';
            const exposureDose =
              get(exposure, 'dlp', 0) > 0 ? get(exposure, 'dlp', 0) : get(exposure, 'dap', 0);
            const unitExposureDose = get(exposure, 'dlp', 0) > 0 ? unitDlp : unitDap;
            const exposureRow = {
              1: patientName,
              2: accessionNumber,
              3: dateTime,
              4: modality,
              5: patientWeight,
              6: patientSize,
              7: archive,
              8: exposureBodyPartExamined,
              9: `${exposureDose.toString().replace('.', ',')} ${unitExposureDose}`,
            };
            studiesWithDose.push(exposureRow);
          }
        });
      }

      const attributes = get(study, 'attributesEn', []);
      let i = 1;
      if (isEmpty(studiesWithDoseHead)) {
        rowDetailHeaders = [
          {
            key: '8',
            label: findTranslation(currentLocale, translationModule, 'bodyPartExamined'),
          },
          { key: '9', label: findTranslation(currentLocale, translationModule, 'dose') },
        ];
        studiesWithDoseHead = { ...firstRowCsv };
        studiesWithDoseHead = {
          ...studiesWithDoseHead,
          10: findTranslation(localeForHeader, translationModule, 'seriesUID'),
        };
        rowDetailHeaders.push({
          key: '10',
          label: findTranslation(localeForHeader, translationModule, 'seriesUID'),
        });
        const dicomTagsStudy = get(attributes[0], 'dicomTagsStudyEn', null);
        const dicomTagsSeries = get(attributes[0], 'dicomTagsSeriesEn', null);
        if (dicomTagsStudy) {
          keys(dicomTagsStudy)
            .sort()
            .forEach((key: string) => {
              i++;
              const keyText = getDicomTagLabel(key, currentLocale);
              studiesWithDoseHead = { ...studiesWithDoseHead, [i + 9]: keyText };
              rowDetailHeaders.push({ key: (i + 9).toString(), label: keyText });
              rowDetailUnits.push({ key: (i + 9).toString(), label: getDicomTagUnit(key) });
            });
        }
        if (dicomTagsSeries) {
          keys(dicomTagsSeries)
            .sort()
            .forEach((key: string) => {
              i++;
              const keyText = getDicomTagLabel(key, currentLocale);
              studiesWithDoseHead = { ...studiesWithDoseHead, [i + 9]: keyText };
              rowDetailHeaders.push({ key: (i + 9).toString(), label: keyText });
              rowDetailUnits.push({ key: (i + 9).toString(), label: getDicomTagUnit(key) });
            });
        }
        studiesCsv.push(studiesWithDoseHead);
      }
      attributes.forEach((att: any) => {
        studiesWithDose.forEach((studyWithDose) => {
          let attributesRow = {
            ...studyWithDose,
            10: get(att, 'seriesUID', ''),
          };
          let attributesRowNew = {
            ...omit(studyWithDose, ['1', '2', '3', '4', '5', '6', '7']),
            10: get(att, 'seriesUID', ''),
          };
          i = 10;
          const dicomTagsStudy2 = get(att, 'dicomTagsStudyEn', null);
          const dicomTagsSeries2 = get(att, 'dicomTagsSeriesEn', null);
          if (dicomTagsStudy2) {
            keys(dicomTagsStudy2)
              .sort()
              .forEach((key: string) => {
                i++;
                const detailUnit = find(rowDetailUnits, ['key', i.toString()]);
                const unit = get(detailUnit, 'label', '') || '';
                const studyValue = `${get(att, 'dicomTagsStudyEn.' + key, null)}${
                  unit ? ' ' + unit : ''
                }`;
                attributesRow = { ...attributesRow, [i]: studyValue };
                attributesRowNew = { ...attributesRowNew, [i]: studyValue };
              });
          }
          if (dicomTagsSeries2) {
            keys(dicomTagsSeries2)
              .sort()
              .forEach((key: string) => {
                i++;
                const detailUnit = find(rowDetailUnits, ['key', i.toString()]);
                const unit = get(detailUnit, 'label', '') || '';
                const seriesValue = `${get(att, 'dicomTagsSeriesEn.' + key, null)}${
                  unit ? ' ' + unit : ''
                }`;
                attributesRow = { ...attributesRow, [i]: seriesValue };
                attributesRowNew = { ...attributesRowNew, [i]: seriesValue };
              });
          }
          studiesCsv.push(attributesRow);
          rowDetailAttributes = [...rowDetailAttributes, { ...attributesRowNew }];
        });
      });
      return { ...study, rowDetailAttributes, rowDetailHeaders };
    });

    setStudiesCsv(studiesCsv);
    setStudies(studiesWithDoses);
    // setRefreshGrid(true);
  };

  const parseMessage = (studies: any, message: any) => {
    const studyDose = get(message, 'body');
    try {
      const parsedStudyDose = JSON.parse(studyDose);
      const findStudyIndex = findIndex(studies, {
        studyInstanceUid: get(parsedStudyDose, 'uid', null),
      });

      // add studyDose info to studies
      studies[findStudyIndex] = {
        ...studies[findStudyIndex],
        patientDispositions: {
          patientWeight: get(parsedStudyDose, 'studyDose.patientWeight', null),
          patientSize: get(parsedStudyDose, 'studyDose.patientHeight', null),
          patientAge: get(parsedStudyDose, 'studyDose.patientAge', null),
        },
        exposures: get(parsedStudyDose, 'studyDose.exposures', null),
        doseWait: false,
        doseError: get(parsedStudyDose, 'error', false),

        dicomTagsStudy: get(parsedStudyDose, 'studyDose.dicomTagsStudy', null),
        series: get(parsedStudyDose, 'studyDose.series', null),
      };
      setStudiesStateAndPrepareCsv(studies);
    } catch (e) {
      console.debug(e);
    }
  };
  const canDisconnect = (studies: any) => {
    let i = 0;
    studies.forEach((study: any) => {
      if (get(study, 'doseWait', true)) {
        i++;
      }
    });
    return i === 0;
  };
  const disconnectWs = () => {
    try {
      if (get(window, 'subscribedStudyDose')) {
        (window as any).subscribedStudyDose.unsubscribe();
      }
    } catch (e) {
      console.debug(e);
    }
    try {
      if (get(window, 'ws')) {
        (window as any).ws.disconnect();
      }
    } catch (e) {
      console.debug(e);
    }
  };

  const loadAllDoseDictionary = async () => {
    const doseDictionaries = await getAllDoseDictionary();
    if (doseDictionaries) {
      setDoseDictionaries(doseDictionaries);
    }
  };

  const loadPatient = async (patientId: string, archives: IArchive[]) => {
    let patients: IStudy[] = [];
    const request = {
      searchLevel: 'patient',
      patient: { id: patientId },
      source: { sources: archives.map((archive) => get(archive, 'id')) },
      study: {},
    };
    await getStudies(request, null).then(
      (response) => {
        if (isArray(response)) {
          patients = [...response];
        } else {
          toggleLoader(false);
          return navigate(linkBack);
        }
      },
      () => {
        toggleLoader(false);
        return navigate(linkBack);
      },
    );
    return patients;
  };

  const waitFor = (ms: number) => new Promise((r) => setTimeout(r, ms));
  async function asyncForEach(array: IStudyResultForGrid[], callback: any) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const loadPatients = async () => {
    let patients: IStudy[] = [];
    await asyncForEach(doseMonitoringStore, async (study: IStudyResultForGrid) => {
      await waitFor(50);
      const pats: IStudy[] = await loadPatient(study.patient.identificationNumber, archives);
      patients.push(...pats);
    });
    return patients;
  };

  const loadEntities = async () => {
    toggleLoader(true, true);

    if (doseMonitoringStore.length < 1 && (!patientId || !archives)) {
      toggleLoader(false);
      return navigate(linkBack);
    }

    loadAllDoseDictionary();

    let patients: IStudy[] = [];
    const studies: any[] = [];
    if (patientId && archives) {
      patients = await loadPatient(patientId, archives);
    } else {
      resetStudies(DOSE_MONITORING);
      patients = await loadPatients();
    }
    patients.forEach((patient) => {
      get(patient, 'studies', []).forEach((study: any) => {
        const iid = generateIID(study);
        studies.push({
          ...study,
          id: iid,
          doseWait: true,
        });
      });
    });
    // Studies without studyDose info
    setStudiesStateAndPrepareCsv(studies);

    const data = {
      username: get(user, 'sub', ''),
      studyUids: studies.map((study) => get(study, 'studyInstanceUid')),
    };

    const wsUrl = `${window.location.protocol === 'http:' ? 'ws://' : 'wss://'}${
      window.location.host
    }/portal-api/studyDose`;
    // wsUrl = 'ws://10.254.201.75/portal-api/studyDose';
    try {
      const socket = new WebSocket(wsUrl);
      (window as any).ws = webstomp.over(socket, { debug: false });
      (window as any).ws.connect({}, () => {
        (window as any).subscribedStudyDose = (window as any).ws.subscribe(
          `/dose/response`,
          async (message: any) => {
            parseMessage(studies, message);
            if (canDisconnect(studies)) {
              disconnectWs();

              setRenderDownload(true);
              if (isExportCsv) {
                toggleLoader(false);
              }
            }
          },
        );
        (window as any).ws.send('/dose/get', JSON.stringify(data));
      });
    } catch (e) {
      addErrorAlert(t(isIE11 ? 'doseErrorInIE' : 'doseError'));
      console.debug(e);
    }

    if (!isExportCsv) {
      toggleLoader(false);
    }
  };

  useEffect(() => {
    loadEntities();

    return () => {
      disconnectWs();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportDoseMonitoringCsv = () => {
    const options = {
      fieldSeparator: get(feConfig, 'csvFieldSeparator', ';') || ';',
      quoteStrings: '"',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      filename: 'dose-monitoring',
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(studiesCsv);
  };

  useEffect(() => {
    if (isExportCsv && renderDownload) {
      exportDoseMonitoringCsv();
      return navigate(`${linkBack}?action=back`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExportCsv, renderDownload]);

  const renderedSteps = () => <TourDoseMonitoring />;

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      compact([
        {
          field: 'patientName',
          headerName: t('patientName'),
          hideable: false,
          valueGetter: (value: any, row: any) => {
            const patientName = [
              get(row, 'patient.name.lastName', ''),
              get(row, 'patient.name.firstName', ''),
              get(row, 'patient.name.prefix'),
              get(row, 'patient.name.suffix'),
            ];
            return compact(patientName).join(', ').trim();
          },
        },
        { field: 'accessionNumber', headerName: t('accessionNumber') },
        {
          field: 'dateTime',
          headerName: t('dateTime'),
          type: 'dateTime',
          valueGetter: (value: any, row: any) => value && new Date(value),
          valueFormatter: (value: any) => value && format(value, 'dd. MM. yyyy HH:mm'),
        },
        {
          field: 'modality',
          headerName: t('modalitiesInStudy'),
          valueGetter: (value: any, row: any) =>
            isArray(get(row, 'modalitiesInStudy')) ? get(row, 'modalitiesInStudy').join(', ') : '',
        },
        {
          field: 'patientWeight',
          headerName: t('patientWeight'),
          renderCell: ({ row }: GridRenderCellParams) =>
            get(row, 'doseWait', true) ? (
              <div>
                <Tooltip title={t('loadingValue')} placement="right">
                  <CircularProgress size={16} disableShrink={true} />
                </Tooltip>
              </div>
            ) : get(row, 'doseError', false) ? (
              <Tooltip title={t('doseError')}>
                <span>?</span>
              </Tooltip>
            ) : (
              get(row, 'patientDispositions.patientWeight', null)
            ),
        },
        {
          field: 'patientSize',
          headerName: t('patientSize'),
          renderCell: ({ row }: GridRenderCellParams) =>
            get(row, 'doseWait', true) ? (
              <div>
                <Tooltip title={t('loadingValue')} placement="right">
                  <CircularProgress size={16} disableShrink={true} />
                </Tooltip>
              </div>
            ) : get(row, 'doseError', false) ? (
              <Tooltip title={t('doseError')}>
                <span>?</span>
              </Tooltip>
            ) : (
              get(row, 'patientDispositions.patientSize', null)
            ),
        },
        {
          field: 'archive',
          headerName: t('archive'),
          valueGetter: (value: any, row: any) => get(row, 'archive.name', ''),
        },
      ]),
    ),
  );

  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds);
  }, []);

  const DetailPanel = ({ row }: GridRowParams) => {
    const rowDetailHeaders = get(row, 'rowDetailHeaders', []);
    const rowDetailAttributes = get(row, 'rowDetailAttributes', []);
    const exposures = get(row, 'exposures');
    return (
      <Box sx={{ paddingLeft: 7 }}>
        {isArray(exposures) ? (
          <GridDicomAttributes results={rowDetailAttributes} rowDetailHeaders={rowDetailHeaders} />
        ) : (
          <Typography>{t('noData')}</Typography>
        )}
      </Box>
    );
  };

  return (
    <>
      {!isEmpty(studies) && !isExportCsv && (
        <>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={6}>
              <Header
                title={t('doseMonitoringTitle')}
                backUrl={`${linkBack}?action=back`}
                TourComponent={renderedSteps()}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => exportDoseMonitoringCsv()}
                sx={{ float: 'right' }}
                data-tour="studyDoseMonitoringExport"
              >
                {t('doseMonitoringExport')}
              </Button>
            </Grid>
          </Grid>

          <MuiGrid
            gridKey={muiGridKey}
            rows={studies}
            columns={columns}
            initialSortMode={[{ field: 'accessionNumber', sort: 'asc' }]}
            dataTour="studyDoseMonitoringGrid"
            rowDetail={{
              showRowDetail: true,
              DetailPanel,
              getDetailPanelHeight: () => 'auto',
              detailPanelExpandedRowIds,
              handleDetailPanelExpandedRowIdsChange: handleDetailPanelExpandedRowIdsChange,
            }}
          />
        </>
      )}
    </>
  );
};

export default DoseMonitoringCalculate;
