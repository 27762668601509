import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { AppBar, Tab, Tabs } from '@mui/material';

import { Workplaces } from '../Workplaces/Workplaces';
import { Products } from '../Products/Products';
import { Users } from '../Users/Users';

import { useUser } from 'utils/hooks/useUser';
import { ConnectedModalities } from '../ConnectedModalities/ConnectedModalities';

export const Settings: React.FC = () => {
  const { t } = useTranslation('Settings');
  const { hasRole } = useUser();

  let [searchParams] = useSearchParams();
  const tabFromUrl = searchParams.get('tab') || '';
  const tab = tabFromUrl
    ? isArray(tabFromUrl)
      ? tabFromUrl[0]
      : tabFromUrl
    : hasRole('ROLE_WORKPLACE')
    ? 'workplaces'
    : hasRole('ROLE_PRODUCT')
    ? 'products'
    : hasRole('ROLE_ADMINISTRATE_MODALITIES_IN_MARIE') ||
      hasRole('ROLE_ADMINISTRATE_MODALITIES_IN_DPGW')
    ? 'connectedModalities'
    : hasRole('ROLE_USER')
    ? 'users'
    : '';

  const [activeTab, setActiveTab] = useState<string>(tab);

  return (
    <>
      <AppBar position="static" color="default" sx={{ mt: 2, mb: 2 }}>
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons={false}
        >
          {hasRole('ROLE_WORKPLACE') && <Tab value="workplaces" label={t('setWorkplaces')} />}
          {hasRole('ROLE_PRODUCT') && <Tab value="products" label={t('setProducts')} />}
          {(hasRole('ROLE_ADMINISTRATE_MODALITIES_IN_MARIE') ||
            hasRole('ROLE_ADMINISTRATE_MODALITIES_IN_DPGW')) && (
            <Tab value="connectedModalities" label={t('setConnectedModalities')} />
          )}
          {hasRole('ROLE_USER') && <Tab value="users" label={t('setUsers')} />}
        </Tabs>
      </AppBar>

      {activeTab === 'workplaces' && <Workplaces />}
      {activeTab === 'products' && <Products />}
      {activeTab === 'connectedModalities' && <ConnectedModalities />}
      {activeTab === 'users' && <Users />}
    </>
  );
};
