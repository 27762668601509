import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import '@fortawesome/fontawesome-free/css/all.css';
import { get, isArray, pick, sortBy } from 'lodash';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Switch,
} from '@mui/material';
import {
  addButton,
  getAllButtons,
  removeButton,
  setAutomaticOpeningOfButton,
  setButtonPosition,
} from './_api';

import { IButton } from '../Buttons/_types';
import { getAllUnselectedButtons } from '../Buttons/_api';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { useLanguage } from 'utils/hooks/useLanguage';
import { Box } from '@mui/system';
import { getTitle } from 'utils/getTitle';

const UserProfileButtonsComponent: React.FC = () => {
  const { t } = useTranslation('Users');

  const [selectedButtons, fetchSelectedButtons] = useState<any[]>([]);
  const [unselectedButtons, fetchUnselectedButtons] = useState<any[]>([]);
  const { currentLocale } = useLanguage();
  const { toggleLoader } = useAppGlobals();

  const getSelectedButtons = async () => {
    toggleLoader();
    const selectedButtons = await getAllButtons();
    if (isArray(selectedButtons)) {
      // Filter buttons with URL and remap those
      const mappedButtons = sortBy(selectedButtons, ['position'])
        .filter((button: IButton) => button.url)
        .map((button: IButton) => ({
          ...pick(button, ['id', 'userButtonId', 'icon', 'url', 'position', 'automaticOpened']),
          title: getTitle(get(button, 'languageJsonText', '') as string, currentLocale),
          labelAutomaticOpened: t('userProfileButtonsSetAutomaticOpened'),
        }));
      fetchSelectedButtons(mappedButtons);
    } else {
      fetchSelectedButtons([]);
    }
    toggleLoader(false);
  };

  const getUnselectedButtons = async () => {
    toggleLoader();
    const unselectedButtons = await getAllUnselectedButtons();
    if (isArray(unselectedButtons)) {
      // Filter buttons with URL and remap those
      const mappedButtons = unselectedButtons
        .filter((button: IButton) => button.url)
        .map((button: IButton) => ({
          ...pick(button, ['id', 'icon', 'url']),
          title: getTitle(get(button, 'languageJsonText', '') as string, currentLocale),
        }));
      fetchUnselectedButtons(sortBy(mappedButtons, ['title']));
    } else {
      fetchUnselectedButtons([]);
    }
    toggleLoader(false);
  };

  const fetchButtons = async () => {
    await Promise.all([getSelectedButtons(), getUnselectedButtons()]);
  };

  useEffect(() => {
    fetchButtons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocale]); // přenačtění dlaždic při změně jazyka

  const onSortEnd = async ({ oldIndex, newIndex }: any) => {
    toggleLoader();
    if (oldIndex !== newIndex && oldIndex >= 0 && newIndex >= 0) {
      const userButtonId = selectedButtons[oldIndex].userButtonId;
      const changed = await setButtonPosition(newIndex + 1, userButtonId);
      if (changed) {
        await getSelectedButtons();
      }
    }
    toggleLoader(false);
  };

  const addSelectedButton = async (button: any) => {
    toggleLoader();
    const changed = await addButton(button.id);
    if (changed) {
      await fetchButtons();
    }
    toggleLoader(false);
  };

  const removeSelectedButton = async (id: any) => {
    toggleLoader();
    const changed = await removeButton(id);
    if (changed) {
      await fetchButtons();
    }
    toggleLoader(false);
  };

  const setAutomaticOpenedButton = async (userButtonId: any, state: any) => {
    toggleLoader();
    const changed = await setAutomaticOpeningOfButton(state, userButtonId);
    if (changed) {
      await getSelectedButtons();
    }
    toggleLoader(false);
  };

  const SortableItem = SortableElement<any>(
    ({ icon, id, userButtonId, title, automaticOpened, labelAutomaticOpened }: any) => {
      return (
        <Box
          sx={{
            p: 1,
            width: {
              xs: '100%',
              md: '50%',
              lg: '33.33%',
            },
          }}
        >
          <Card
            sx={{
              cursor: 'move',
              background: (theme) =>
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
              color: '#fff',
              ':hover': {
                background: (theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
              },
            }}
          >
            <CardHeader
              subheader={title}
              sx={{
                backgroundColor: 'primary.main',
              }}
              subheaderTypographyProps={{
                sx: {
                  color: '#fff',
                },
              }}
              action={
                <IconButton
                  sx={{ padding: '4px' }}
                  key="remove"
                  aria-label="Remove"
                  color="inherit"
                  onClick={() => removeSelectedButton(id)}
                  size="large"
                >
                  <RemoveIcon />
                </IconButton>
              }
            />
            <CardContent
              sx={{
                textAlign: 'center',
                height: 40,
                boxSizing: 'content-box',
                pb: 2,
              }}
            >
              <Icon
                baseClassName="material-icons"
                sx={{ fontSize: 40, color: (theme) => theme.palette.grey[600] }}
              >
                {icon || 'crop_square'}
              </Icon>
            </CardContent>
            <CardActions>
              {automaticOpened ? (
                <>
                  <FormControlLabel
                    sx={{
                      color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#333'),
                    }}
                    control={<Switch checked={true} />}
                    onChange={(e, state) => {
                      setAutomaticOpenedButton(userButtonId, state);
                    }}
                    label={labelAutomaticOpened}
                  />
                </>
              ) : (
                <>
                  <FormControlLabel
                    sx={{
                      color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#333'),
                    }}
                    control={<Switch checked={false} />}
                    onChange={(e, state) => {
                      setAutomaticOpenedButton(userButtonId, state);
                    }}
                    label={labelAutomaticOpened}
                  />
                </>
              )}
            </CardActions>
          </Card>
        </Box>
      );
    },
  );

  const SortableList = SortableContainer<any>(() => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {selectedButtons.map(
          (
            { icon, id, userButtonId, title, url, automaticOpened, labelAutomaticOpened }: any,
            index: number,
          ) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              icon={icon}
              id={id}
              userButtonId={userButtonId}
              title={title}
              url={url}
              automaticOpened={automaticOpened}
              labelAutomaticOpened={labelAutomaticOpened}
            />
          ),
        )}
      </Box>
    );
  });

  return (
    <>
      <Header title={t('userProfileButtonsSelected')} />
      <Papeer bottomMargin={true}>
        <SortableList
          onSortEnd={onSortEnd}
          setAutomaticOpenedButton={setAutomaticOpenedButton}
          axis="xy"
          distance={2}
        />
      </Papeer>

      <Header title={t('userProfileButtonsUnselected')} />
      {unselectedButtons.length ? (
        <Papeer>
          <Grid container={true} spacing={2}>
            {unselectedButtons.map((button: any) => (
              <Grid item={true} xs={12} md={6} lg={4} key={button.id}>
                <Card>
                  <CardHeader
                    subheader={button.title}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'dark'
                          ? theme.palette.grey[700]
                          : theme.palette.grey[300],
                    }}
                    action={
                      <IconButton
                        sx={{ p: (theme) => theme.spacing(0.5) }}
                        key="add"
                        aria-label="Add"
                        onClick={() => addSelectedButton(button)}
                        size="large"
                      >
                        <AddIcon />
                      </IconButton>
                    }
                  />
                  <CardContent
                    sx={{
                      textAlign: 'center',
                      height: 40,
                      boxSizing: 'content-box',
                      pb: 2,
                    }}
                  >
                    {button.icon && (
                      <Icon
                        baseClassName="material-icons"
                        sx={{ fontSize: 40, color: (theme) => theme.palette.grey[600] }}
                      >
                        {button.icon}
                      </Icon>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Papeer>
      ) : null}
    </>
  );
};

export default UserProfileButtonsComponent;
