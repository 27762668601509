import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { get, isEmpty, pull, find, isString, take, filter, uniq, compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { downloadPdfDocument, getDocumentDetail, getDocuments, getPdfDocument } from './_api';
import { IFacilityLoad, IGridLayoutParams, IPatientDocument } from './_types';
import { DocumentDetailGrids } from './DocumentDetailGrids';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useLanguage } from 'utils/hooks/useLanguage';
import { apiDataToDownloadFile, printOrDownloadBase64String } from 'utils/hooks/useApp';
import useAlerts from 'components/Alerts/useAlerts';
import Header from 'components/Header/Header';
import { getClinicalPortalSetting } from 'modules/Administration/ClinicalPortal/_api';
import { hookDownloadFromEldax } from './hooks';

const sxDisplayPrintNone = { '@media print': { display: 'none' } };

export const ClinicalPortalDocumentDetailFromTags: React.FC = () => {
  const { t } = useTranslation('ClinicalPortal');
  const { toggleLoader } = useAppGlobals();
  const { currentLocale } = useLanguage();
  let [searchParams] = useSearchParams();

  const { addErrorAlert } = useAlerts();
  const { selectedDocuments } = useAppInfo();

  const from = searchParams.get('from');
  const backToPathname = searchParams.get('pathname');
  const linkBack = backToPathname
    ? `${backToPathname}?action=back${from ? `&from=${from}` : ''}`
    : '/';

  const [entity, setEntity] = useState<any>(null);
  const [masterPatientID, setMasterPatientID] = useState<string>('');
  const [gridLayout, setGridLayout] = useState<string>('');
  const [requestedDocuments, setRequestedDocuments] = useState<any[]>([]);
  const [patientDocuments, setPatientDocuments] = useState<IPatientDocument[]>([]);
  const [documentUids, setDocumentUids] = useState<string[]>([]);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [standardizedPrint, setStandardizedPrint] = useState<boolean>(false);
  const [facilitiesManualLoad, setFacilitiesManualLoad] = useState<IFacilityLoad[]>([]);
  const [loadingFacility, setLoadingFacility] = useState<string>('');
  const [isDownloadingFromEldax, setIsDownloadingFromEldax] = useState<boolean>(false);

  const findDocumentsForFacility = async (
    masterPatientID: string,
    activeTab: string,
    facility: string,
  ) => {
    setLoadingFacility(facility);
    let allDocuments: any[] = [];
    const documents = await getDocuments(masterPatientID, activeTab, facility);
    if (documents) {
      const preparedDocuments = documents.map((item: any) => ({
        ...item,
        typeOfDocumentName: get(item, 'typeOfDocument.translateJson')
          ? get(
              JSON.parse(get(item, 'typeOfDocument.translateJson')),
              currentLocale,
              get(item, 'typeOfDocument.name'),
            )
          : '?',
        patientEntity: entity,
      }));
      allDocuments = [...preparedDocuments];
    }
    setLoadingFacility('');
    return allDocuments;
  };

  const loadPatient = async (id: string) => {
    const someDocuments: any[] = [
      {
        uuid: get(selectedDocuments[0], 'uuid', ''),
        typeOfDocument: get(selectedDocuments[0], 'typeOfDocument.name', ''),
      },
    ];
    const documentDetail = await getDocumentDetail(id, [], someDocuments);
    const patient = get(documentDetail, 'patient', null);
    const requestedDocuments = get(documentDetail, 'requestedDocuments', []);
    setEntity(patient);
    setRequestedDocuments(requestedDocuments);
  };

  const loadDocuments = async (masterPatientID: string) => {
    const clinicalPortalSetting = await getClinicalPortalSetting();
    let facilitiesAutoLoad: IFacilityLoad[] = [];
    let facilitiesManualLoad: IFacilityLoad[] = [];
    if (clinicalPortalSetting) {
      facilitiesAutoLoad = filter(get(clinicalPortalSetting, 'facilities', []), {
        autoLoad: true,
      }) as unknown as IFacilityLoad[];
      facilitiesManualLoad = filter(get(clinicalPortalSetting, 'facilities', []), {
        autoLoad: false,
      }) as unknown as IFacilityLoad[];
      setFacilitiesManualLoad(facilitiesManualLoad);
    }
    let documents: any[] = [];
    let allDocuments: any[] = [];
    if (!isEmpty(facilitiesAutoLoad)) {
      documents = await Promise.all(
        facilitiesAutoLoad.map((facilityItem) => {
          return findDocumentsForFacility(masterPatientID, '1', facilityItem.facility);
        }),
      );
      documents.forEach((docs) => {
        allDocuments = uniq(compact([...(allDocuments || []), ...(docs || [])]));
      });
      setPatientDocuments(
        allDocuments.map((doc) => ({
          ...doc,
          patientEntity: doc.patient,
        })),
      );
    }
  };

  const loadData = async () => {
    toggleLoader();
    const selectedDocumentsCount = selectedDocuments.length;
    setGridLayout(
      `${Math.ceil(selectedDocumentsCount / 4)}x${
        selectedDocumentsCount > 4 ? 4 : selectedDocumentsCount
      }`,
    );
    const uuid: string = get(selectedDocuments[0], 'uuid', '') || '';
    const id: string = get(selectedDocuments[0], 'patient.masterPatientID', '') || '';

    if (uuid) {
      setDocumentUids([uuid]);
    }
    if (id) {
      setMasterPatientID(id);
      await loadPatient(id);
      await loadDocuments(id);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findDocuments = async (facility: string) => {
    const newFacitiesManualLoad = facilitiesManualLoad.map((facilityItem) => ({
      facility: facilityItem.facility,
      label: facilityItem.label,
      disabled: get(facilityItem, 'disabled', false)
        ? true
        : facilityItem.facility === facility
        ? true
        : false,
    }));
    setFacilitiesManualLoad(newFacitiesManualLoad);

    const allDocuments = await findDocumentsForFacility(masterPatientID, '1', facility);
    setPatientDocuments(uniq(compact([...(patientDocuments || []), ...(allDocuments || [])])));
  };

  const loadDocumentForPrint = async (
    masterPatientID: string,
    documentUiid: any,
    typeOfDocument: any,
    isPrinting: boolean,
    isExporting: boolean,
    standardizedPrint: boolean,
  ) => {
    if (!isEmpty(masterPatientID) && documentUiid !== null && typeOfDocument !== null) {
      const documentPrintInfoResponse = await getPdfDocument(
        masterPatientID,
        documentUiid,
        typeOfDocument,
        standardizedPrint,
      );
      if (documentPrintInfoResponse) {
        return documentPrintInfoResponse;
      } else {
        if (isPrinting) {
          addErrorAlert(t('printDocumentError'));
        }
        if (isExporting) {
          addErrorAlert(t('exportDocumentError'));
        }
      }
    }
    return undefined;
  };

  const printOrExport = async () => {
    toggleLoader();
    for await (const uuid of selectedDocuments) {
      const patientdoc = find(patientDocuments, { uuid });
      const masterPatientID = get(entity, 'masterPatientID', '');
      const fileName = masterPatientID + '.pdf';
      if (isPrinting) {
        const base64PdfString = await loadDocumentForPrint(
          masterPatientID,
          uuid,
          get(patientdoc, 'typeOfDocument.name', null),
          isPrinting,
          isExporting,
          standardizedPrint,
        );
        const contentType = 'application/pdf';
        printOrDownloadBase64String(base64PdfString, contentType, fileName);
        setStandardizedPrint(false);
        setIsPrinting(false);
      } else if (isExporting) {
        const base64PdfString = await downloadPdfDocument(
          masterPatientID,
          uuid,
          get(patientdoc, 'typeOfDocument.name', ''),
          standardizedPrint,
        );
        apiDataToDownloadFile(base64PdfString, fileName);
        setStandardizedPrint(false);
        setIsExporting(false);
      }
    }
    toggleLoader(false);
  };
  useEffect(() => {
    if (isPrinting || isExporting) {
      printOrExport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrinting, isExporting]);

  const downloadFromEldaxFunction = async () => {
    toggleLoader();
    for await (const uuid of selectedDocuments) {
      const patientdoc = find(patientDocuments, { uuid });
      const masterPatientID = get(entity, 'masterPatientID', null);
      hookDownloadFromEldax(
        masterPatientID,
        patientdoc,
        uuid,
        t,
        addErrorAlert,
        setIsDownloadingFromEldax,
      );
    }
    toggleLoader(false);
  };
  useEffect(() => {
    if (isDownloadingFromEldax) {
      downloadFromEldaxFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadingFromEldax]);

  const unselectDocumentUuid = (uuid: string) => {
    const reducedDocumentUids = [...documentUids];
    pull(reducedDocumentUids, uuid);

    setDocumentUids(reducedDocumentUids);
  };
  const selectDocumentUuid = (uuid: string) => {
    const updatedDocumentUids = [...documentUids];
    updatedDocumentUids.push(uuid);

    setDocumentUids(updatedDocumentUids);
  };

  const replaceDocumentAtIndex = (uuid: string, index: number) => {
    const currentUids = [...documentUids];
    currentUids[index] = uuid;
    setDocumentUids(currentUids);
  };

  const [gridLayoutParams, setGridLayoutParams] = useState<IGridLayoutParams>({
    x: 1,
    y: 1,
    totalAvailableFields: 1,
    lastDocumentPositionIndex: 1,
  });

  useEffect(() => {
    if (isString(gridLayout)) {
      const dimesions = gridLayout.split('x');
      if (dimesions.length === 2) {
        const x = Number(dimesions[0]);
        const y = Number(dimesions[1]);
        const max = x * y;
        const newUids = take(documentUids, max);
        setDocumentUids(newUids);
        setGridLayoutParams({
          x,
          y,
          totalAvailableFields: x * y,
          lastDocumentPositionIndex: documentUids.length - 1,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridLayout, documentUids.length]);

  return (
    <>
      <Typography component="div" sx={sxDisplayPrintNone}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Header
            title={t('documentsDetail')}
            backUrl={linkBack}
            loading={loadingFacility}
            loadingDescription={t('loadingDataForFacility', { par1: loadingFacility })}
          />
        </Box>
      </Typography>
      {!isEmpty(facilitiesManualLoad) && (
        <div>
          {facilitiesManualLoad.map((facilityItem) => (
            <Button
              key={facilityItem.facility}
              variant="contained"
              color="primary"
              size="medium"
              sx={{ mb: 1 }}
              disabled={get(facilityItem, 'disabled', false)}
              onClick={() => findDocuments(facilityItem.facility)}
            >
              {facilityItem.label}
            </Button>
          ))}
        </div>
      )}

      {entity && documentUids && !isEmpty(patientDocuments) && patientDocuments.length > 0 && (
        <Box sx={sxDisplayPrintNone}>
          <DocumentDetailGrids
            gridLayout={gridLayout}
            documentUids={documentUids}
            selectDocumentUuid={selectDocumentUuid}
            unselectDocumentUuid={unselectDocumentUuid}
            patientDocuments={patientDocuments}
            onlyAddTag={true}
            setIsPrinting={setIsPrinting}
            setIsExporting={setIsExporting}
            replaceDocumentAtIndex={replaceDocumentAtIndex}
            setStandardizedPrint={setStandardizedPrint}
            requestedDocuments={requestedDocuments}
            gridLayoutParams={gridLayoutParams}
            setIsDownloadingFromEldax={setIsDownloadingFromEldax}
          />
        </Box>
      )}
    </>
  );
};
