import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { compact, find, get, includes, isArray, isEmpty, isEqual, pick, uniq } from 'lodash';
import { Box } from '@mui/system';
import { Grid, Typography } from '@mui/material';

import { IStudiesEditDetail, IStudiesEditForm } from './_types';
import useValidationSchema from './_form';
import {
  createRequestForUpdatePatient,
  createRequestForUpdateStudy,
  editForUpdatePatient,
  editForUpdateStudy,
  editPatient,
  editStudy,
  getProductsByTypeAndFunction,
  getStudiesForPatient,
} from './_api';

import RequestAdditionalForm from '../Form/RequestAdditionalForm';

import Header from 'components/Header/Header';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { generateIID, getParam } from 'utils/study';
import { useAppInfo } from 'utils/hooks/useAppInfo';

import {
  LOCKED_STUDY,
  EDIT_TYPE_PATIENT_SLUG,
  EDIT_TYPE_STUDY_SLUG,
  SELECT_ORDER_TO_EDIT_PATIENT,
  SELECT_ORDER_TO_EDIT_STUDY,
  VIEWER,
} from 'constants/constants';
import useAlerts from 'components/Alerts/useAlerts';
import { getProductByType } from 'modules/Administration/Products/_api';
import { settingHasValue } from 'utils/products';
import { getValuesFromDicomFile } from '../StudyDetail/_api';
import PatientAndStudyEditForm from '../Form/PatientAndStudyEditForm';
import { RequestDialog } from 'components/RequestDialog/RequestDialog';
import { formatDateForSearch } from 'utils/search';
import { useStorage } from 'utils/hooks/useStorage';
import { Announcement } from 'components/Announcement/Announcement';
import { SearchResultsMUI } from 'components/SearchResultsMUI/SearchResultsMUI';
import { IStudyResultForGrid } from 'modules/Search/_types';
import useSizesValidation from '../hooks/useSizesValidation';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourStudiesEdit } from './TourStudiesEdit';
import { Papeer } from 'components/Papeer/Papeer';
import Button from 'components/Buttons/Button';

const apiDateFormat = 'yyyy-MM-dd';

const StudiesEditDetail: React.FC<IStudiesEditDetail> = ({
  isRequest = false,
  isRequestEdit = false,
  request,
  operationType = '',
  forDistribute = false,
}) => {
  const { t } = useTranslation('Studies');
  useWithTitle(); // sets title to document

  const { toggleLoader } = useAppGlobals();

  const { defViewer, validators, portalSetting, compactMode } = useAppInfo();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { editStore } = useStudyInfo();

  const location = useLocation();
  const type = isRequest
    ? operationType
    : includes(get(location, 'pathname', ''), EDIT_TYPE_PATIENT_SLUG)
    ? EDIT_TYPE_PATIENT_SLUG
    : EDIT_TYPE_STUDY_SLUG;

  const isStudyEditType = type === EDIT_TYPE_STUDY_SLUG;
  const isPatientEditType = type === EDIT_TYPE_PATIENT_SLUG;

  const [linkBack, setLinkBack] = useState<any>(null);
  const [studies, setStudies] = useState<any>(false);
  const [rows, setRows] = useState<IStudyResultForGrid[]>([]);
  const [patientUUID, setPatientUUID] = useState<any>(null);
  const [showInfoPanel, setShowInfoPanel] = useState<boolean>(false);
  const [dicomData, setDicomData] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<any>(null);
  const [someStudyLocked, setSomeStudyLocked] = useState<any>(null);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [header, setHeader] = useState<any>(null);
  const [orderDialog, setOrderDialog] = useState<boolean>(false);
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);
  const [isDicomDataLoaded, setIsDicomDataLoaded] = useState<boolean>(false);

  const navigate = useNavigate();

  const { putItem } = useStorage();

  const {
    validateSizes,
    confirmationData,
    setConfirmationData,
    clearConfirmationData,
    confirmationTexts,
  } = useSizesValidation({
    maxNameLength: 60,
    prefixForDataAccession: 'study.',
  });

  const { StudiesEditFormSchema } = useValidationSchema(
    t,
    get(validators, 'editForm', {}),
    portalSetting,
    isStudyEditType,
  );

  const methods = useForm<IStudiesEditForm>({
    resolver: yupResolver(StudiesEditFormSchema),
    mode: 'onChange',
  });
  const { handleSubmit, reset, watch, register, setValue, trigger } = methods;

  const watchAllFields = watch();
  const watchRequestComment = watch('requestComment');

  const checkChanges = (values: any, requestComment: any) => {
    let numberOfChanges = 0;
    const attsPatient = [
      'patient.name',
      'patient.identificationNumber',
      'patient.dateBirth',
      'patient.sex',
    ];
    const attsStudy = [
      'patient.name',
      'patient.identificationNumber',
      'patient.dateBirth',
      'patient.sex',
      'studyDescription',
      'accessionNumber',
      'studyDate',
      'studyTime',
      'referringPhysician.name',
      'requestingPhysician.name',
      'patientDispositions',
    ];
    const atts = isPatientEditType ? attsPatient : attsStudy;
    const initialValuesToCompare = {
      patient: initialValues.patient,
      ...initialValues.study,
      referringPhysician: initialValues.referringPhysician,
      requestingPhysician: initialValues.requestingPhysician,
    };
    atts.forEach((att) => {
      let sourceObject;
      let targetObject;
      if (att === 'patientDispositions') {
        sourceObject = get(dicomData, 'patientWeightAndSize') || null;
        targetObject = get(values, 'patientDispositions', null)
          ? get(values, 'patientDispositions', null)
          : null;
      } else if (att === 'referringPhysician.name') {
        sourceObject = get(dicomData, 'referringPhysician') || null;
        targetObject = get(values, att, null);
      } else if (att === 'requestingPhysician.name') {
        sourceObject = get(dicomData, 'requestingPhysician') || null;
        targetObject = get(values, att, null);
      } else {
        sourceObject = get(initialValuesToCompare, att, null);
        targetObject = get(values, att, null);
      }
      if (!isEqual(sourceObject, targetObject)) {
        //console.log('notEqual', att, sourceObject, targetObject);
        ++numberOfChanges;
      }
    });
    if (!isEmpty(requestComment)) {
      ++numberOfChanges;
    }
    return numberOfChanges;
  };

  const handleEditStudy = async (values: any) => {
    toggleLoader();
    const requestComment = watchRequestComment;
    const values2 = {
      ...values,
      patientDispositions: {
        size: get(values, 'patientDispositions.size') || null,
        weight: get(values, 'patientDispositions.weight') || null,
      },
    };
    const numberOfChanges = checkChanges(values2, requestComment);
    if (numberOfChanges === 0) {
      addErrorAlert(t('errorNoChange'));
    } else {
      const date = get(values2, 'patient.dateBirth', null);
      const editedStudy = {
        ...values2,
        studyDate: formatDateForSearch(get(values2, 'studyDate', undefined), 'yyyyMMdd'),
        studyTime: formatDateForSearch(get(values2, 'studyTime', undefined), 'HH:mm:ss'),
        patient: {
          ...get(values2, 'patient', {}),
          dateBirth: date ? `${formatDateForSearch(date, apiDateFormat)}T00:00:00` : '',
          patientUUID,
        },
        requestComment,
      };
      const fn = isRequest ? createRequestForUpdateStudy : editStudy;
      const response = await fn(editedStudy, studies);
      if (response) {
        addSuccessAlert(t(isRequest ? 'requestForStudyEditAdded' : 'studyEditSubmitOk'));
        let searchJson = {
          source: {
            sources: studies.map((study: any) => {
              return get(study, 'archive');
            }),
          },
          patient: {},
        };
        const accessionNumber = get(editedStudy, 'accessionNumber', null);
        if (accessionNumber !== null) {
          searchJson = { ...searchJson, patient: { accessionNumber } };
        }
        const identificationNumber = get(editedStudy, 'patient.identificationNumber', null);
        if (identificationNumber !== null) {
          searchJson = {
            ...searchJson,
            patient: { ...get(searchJson, 'patient', {}), id: identificationNumber },
          };
        }
        if (!isRequest) {
          putItem('buttonRequestJson', JSON.stringify(searchJson));
        }
        navigate(linkBack);
      } else {
        addErrorAlert(t(isRequest ? 'errorCreatingRequest' : 'editSubmitError'));
      }
    }
    toggleLoader(false);
  };

  const handleEditPatient = async (values: any) => {
    toggleLoader();
    const requestComment = watchRequestComment || null;
    const date = get(values, 'patient.dateBirth', null);
    const fn = isRequest ? createRequestForUpdatePatient : editPatient;
    const study = get(studies, '[0]', {});

    const oldPatient = isRequest
      ? { ...get(study, 'patient', {}) }
      : {
          ...get(studies, '[0].patient', {}),
          studies: undefined,
          patientUUID: get(
            uniq(compact(rows.map((study) => get(study, 'patient.patientUUID', null)))),
            '[0]',
            null,
          ),
        };

    const newPatient = {
      ...get(values, 'patient', {}),
      dateBirth: date ? `${formatDateForSearch(date, apiDateFormat)}T00:00:00` : '',
    };

    const source = {
      sources: uniq(rows.map((study) => get(study, 'archive.id'))),
    };

    const numberOfChanges = checkChanges(values, requestComment);
    if (numberOfChanges === 0) {
      addErrorAlert(t('errorNoChange'));
    } else {
      const resp = await fn(oldPatient, newPatient, source, requestComment);
      if (resp) {
        addSuccessAlert(t(isRequest ? 'requestForPatientEditAdded' : 'patientEditSubmitOk'));
        navigate(linkBack);
      } else {
        addErrorAlert(t(isRequest ? 'errorCreatingRequest' : 'editSubmitError'));
      }
    }
    toggleLoader(false);
  };

  const handleRequestStudyEdit = async (values: any) => {
    toggleLoader();
    const requestComment = watchRequestComment || null;
    const date = get(values, 'patient.dateBirth', null);
    const editedStudy = {
      ...values,
      studyDate: formatDateForSearch(get(values, 'studyDate', undefined), 'yyyyMMdd'),
      studyTime: formatDateForSearch(get(values, 'studyTime', undefined), 'HH:mm:ss'),
      patient: {
        ...get(values, 'patient', {}),
        dateBirth: date ? `${formatDateForSearch(date, apiDateFormat)}T00:00:00` : '',
        patientUUID,
      },
      patientDispositions: get(values, 'patientDispositions')
        ? {
            size: get(values, 'patientDispositions.size') || null,
            weight: get(values, 'patientDispositions.weight') || null,
          }
        : {},
    };
    const editedRequest = {
      ...request,
      data: { ...request.data, ...editedStudy },
      requestComment,
    };
    const numberOfChanges = checkChanges(values, requestComment);
    if (numberOfChanges === 0) {
      addErrorAlert(t('errorNoChange'));
    } else {
      const response = await editForUpdateStudy(editedRequest);

      if (response) {
        addSuccessAlert(t('requestForStudyEditEdited'));
        navigate(linkBack);
      } else {
        addErrorAlert(t('errorEditingRequest'));
      }
    }
    toggleLoader(false);
  };

  const handleRequestPatientEdit = async (values: any) => {
    toggleLoader();
    const requestComment = watchRequestComment || null;
    const date = get(values, 'patient.dateBirth', null);

    const newPatient = {
      ...get(values, 'patient', {}),
      dateBirth: date ? `${formatDateForSearch(date, apiDateFormat)}T00:00:00` : '',
    };

    const editedRequest = {
      ...request,
      data: { ...request.data, patient: newPatient },
      requestComment,
    };
    /*let numberOfChanges = 0;
    if (
      !isEqual(
        pick(initialValues, [
          'patient.name',
          'patient.identificationNumber',
          'patient.dateBirth',
          'patient.sex',
        ]),
        pick(values, [
          'patient.name',
          'patient.identificationNumber',
          'patient.dateBirth',
          'patient.sex',
        ]),
      )
    ) {
      numberOfChanges++;
    }
    if (!isEmpty(requestComment)) {
      ++numberOfChanges;
    }*/
    const numberOfChanges = checkChanges(values, requestComment);
    if (numberOfChanges === 0) {
      addErrorAlert(t('errorNoChange'));
    } else {
      const response = await editForUpdatePatient(editedRequest);

      if (response) {
        addSuccessAlert(t('requestForPatientEditEdited'));
        navigate(linkBack);
      } else {
        addErrorAlert(t('errorEditingRequest'));
      }
    }
    toggleLoader(false);
  };

  const handleFormSubmit = async (values: any) =>
    isStudyEditType ? await handleEditStudy(values) : await handleEditPatient(values);

  const handleRequestEdit = async (values: any) =>
    isStudyEditType ? await handleRequestStudyEdit(values) : await handleRequestPatientEdit(values);

  const callHandleSubmit = async (values: any) =>
    isRequestEdit ? handleRequestEdit(values) : handleFormSubmit(values);

  const onSubmit = handleSubmit(async (values) => {
    callHandleSubmit(values);
  });

  const getEntities = async (studies: any[]) => {
    toggleLoader();
    if (isPatientEditType) {
      const patientId = isRequestEdit
        ? get(request, 'data.oldPatient.identificationNumber')
        : get(studies, '[0].patient.identificationNumber');
      const archives = isRequestEdit
        ? get(request, 'data.source.sources')
        : await getProductsByTypeAndFunction('archive', 'EDIT');
      if (patientId && archives) {
        const resp = await getStudiesForPatient(
          patientId,
          isRequestEdit ? archives : archives.map((archive: any) => archive.id),
        );
        if (isArray(resp)) {
          setRows(
            resp.map((result) => ({
              ...result,
              iid: generateIID(result),
            })),
          );
        }
      }
    } else if (isStudyEditType) {
      const archives = await getProductByType('MS');
      const archiveId = get(studies, '[0].archive.id') as unknown as string;
      const product = find(archives, { id: archiveId });
      const studyID = get(studies, '[0].studyInstanceUid') as unknown as string;
      setPatientUUID(get(studies, '[0].patient.patientUUID', null));
      if (product !== undefined) {
        const showInfoPanel = settingHasValue(
          get(product, 'systemSetting', null),
          'showInfoAboutRequestingPhysician',
          'true',
        );
        setShowInfoPanel(showInfoPanel);
      }
      if (!isRequestEdit || isRequestEdit === undefined) {
        setDicomData(null);
        getValuesFromDicomFile(parseInt(archiveId, 10), studyID, {
          REFERRING_PHYSICIAN_NAME: 'NAME',
          REQUESTING_PHYSICIAN_NAME: 'NAME',
          PERFORMING_PHYSICIAN_NAME: 'NAME',
          PATIENT_WEIGHT: 'STRING',
          PATIENT_HEIGHT: 'STRING',
        }).then((dicomData: any) => {
          if (dicomData) {
            const physician = {
              firstName: '',
              lastName: '',
              middleName: '',
              prefix: '',
              suffix: '',
            };
            const referringPhysician = get(dicomData, 'REFERRING_PHYSICIAN_NAME')
              ? JSON.parse(get(dicomData, 'REFERRING_PHYSICIAN_NAME'))
              : null;
            const requestingPhysician = get(dicomData, 'REQUESTING_PHYSICIAN_NAME')
              ? JSON.parse(get(dicomData, 'REQUESTING_PHYSICIAN_NAME'))
              : null;
            setDicomData({
              patientWeightAndSize: {
                size: get(dicomData, 'PATIENT_HEIGHT', null),
                weight: get(dicomData, 'PATIENT_WEIGHT', null),
              },
              referringPhysician: { ...physician, ...referringPhysician },
              requestingPhysician: { ...physician, ...requestingPhysician },
            });
          }
        });
      }
    }
    toggleLoader(false);
  };

  const loadStart = () => {
    const studies: any[] = isRequestEdit
      ? get(request, 'data.studies', []).map((study: any) => {
          return {
            ...study,
            iid: generateIID(study),
          };
        })
      : editStore;
    setStudies(studies);
    const detailIID = getParam(get(location, 'search'), 'backTo');
    let linkBack;
    if (detailIID) {
      linkBack = `/study/${detailIID}`;
    } else {
      linkBack = `/${isRequest ? `requests` : 'studies'}`;
    }
    setLinkBack(linkBack);
    if (!isRequestEdit && studies.length < 1) {
      toggleLoader(false);
      return navigate(linkBack);
    }
    getEntities(studies);
  };

  useEffect(() => {
    loadStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (studies) {
      if (isRequestEdit && isStudyEditType) {
        const requestData = get(request, 'data', null);
        const dicomData = {
          patientWeightAndSize: get(requestData, 'patientDispositions'),
          referringPhysician: get(requestData, 'referringPhysician.name'),
          requestingPhysician: get(requestData, 'requestingPhysician.name'),
        };
        setDicomData(dicomData);
      }
      const study = isRequestEdit
        ? isStudyEditType
          ? get(request, 'data')
          : get(request, 'data.patient')
        : get(studies, '[0]');
      const dateBirthForPatientOrStudyEdit = isPatientEditType
        ? isRequestEdit
          ? get(study, 'dateBirth', null)
          : get(study, 'patient.dateBirth', null)
        : isStudyEditType
        ? get(study, 'patient.dateBirth', null)
        : null;
      const initialValues = isStudyEditType
        ? {
            patient: {
              ...get(study, 'patient', {}),
              dateBirth: dateBirthForPatientOrStudyEdit
                ? new Date(dateBirthForPatientOrStudyEdit)
                : null,
            },
            study: {
              studyDescription: get(study, 'studyDescription', ''),
              studyDate: isRequestEdit
                ? get(study, 'studyDate', null)
                  ? new Date(get(study, 'studyDate', null))
                  : null
                : get(study, 'dateTime', null)
                ? new Date(get(study, 'dateTime', null))
                : null,
              studyTime: isRequestEdit
                ? get(study, 'studyDate', null) && get(study, 'studyTime', null)
                  ? new Date(`${get(study, 'studyDate', null)}T${get(study, 'studyTime', null)}`)
                  : null
                : get(study, 'dateTime', null)
                ? new Date(get(study, 'dateTime', null))
                : null,
              accessionNumber: get(study, 'accessionNumber', ''),
              patientDispositions: get(study, 'patientDispositions', null),
            },
            referringPhysician: get(study, 'referringPhysician', ''),
            requestingPhysician: get(study, 'requestingPhysician', ''),
          }
        : {
            patient: {
              ...(isRequestEdit ? study : get(study, 'patient', {})),
              dateBirth: dateBirthForPatientOrStudyEdit
                ? new Date(dateBirthForPatientOrStudyEdit)
                : null,
            },
          };
      setInitialValues(initialValues);
      reset(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studies]);

  useEffect(() => {
    const someStudyLocked = rows
      .map((study) => get(study, 'inProcessing', false))
      .some((x) => x === true);
    setSomeStudyLocked(someStudyLocked);
    setCanEdit(isStudyEditType || !someStudyLocked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStudyEditType, rows]);

  useEffect(() => {
    let title: string;
    if (isRequestEdit) {
      if (isStudyEditType) {
        title = 'editTypeStudyTitleRequestEdit';
      } else {
        title = 'editTypePatientTitleRequestEdit';
      }
    } else if (isRequest) {
      if (isStudyEditType) {
        title = 'editTypeStudyTitleRequest';
      } else {
        title = 'editTypePatientTitleRequest';
      }
    } else {
      if (isStudyEditType) {
        title = 'studyEditTitle';
      } else {
        title = 'patientEditTitle';
      }
    }

    const renderedSteps = () => {
      return <TourStudiesEdit type={isStudyEditType ? 'editStudyPatient' : 'editPatient'} />;
    };

    const header = (
      <Header
        title={t(title)}
        backUrl={`${linkBack}?action=back`}
        TourComponent={renderedSteps()}
      />
    );
    setHeader(header);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStudyEditType, isRequest, isRequestEdit, linkBack]);

  useEffect(() => {
    if (!isRequestEdit || isRequestEdit === undefined) {
      setValue('study.patientDispositions', get(dicomData, 'patientWeightAndSize', null));
      setValue('referringPhysician.name', get(dicomData, 'referringPhysician', null));
      setValue('requestingPhysician.name', get(dicomData, 'requestingPhysician', null));
    }
    if (dicomData) {
      setIsDicomDataLoaded(true);
    } else {
      if (!isStudyEditType) setIsDicomDataLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dicomData]);

  let submitButtonText;
  if (isRequestEdit) {
    if (isStudyEditType) {
      submitButtonText = 'setRequestForEditStudyEdit';
    } else {
      submitButtonText = 'setRequestForEditPatientEdit';
    }
  } else if (isRequest) {
    if (isStudyEditType) {
      submitButtonText = 'setRequestForEditStudy';
    } else {
      submitButtonText = 'setRequestForEditPatient';
    }
  } else {
    submitButtonText = 'save';
  }
  submitButtonText = t(submitButtonText);

  const preSubmitAction = () => {
    const patientInitial = get(initialValues, 'patient', {});
    const patientForm = get(watchAllFields, 'patient', null);
    if (
      patientInitial &&
      patientForm &&
      !isEqual(
        pick(patientInitial, ['name', 'identificationNumber', 'dateBirth', 'sex']),
        pick(patientForm, ['name', 'identificationNumber', 'dateBirth', 'sex']),
      )
    ) {
      confirmationTexts.push(
        isPatientEditType ? t('editPatientDataConfirm') : t('editPatientDataForStudyConfirm'),
      );
      // setConfirmDialog(true);
    }
    // else {
    //   callHandleSubmit({ patient: watchAllFields.patient, ...watchAllFields.study });
    // }
  };

  // Not open but save - but will look into it more
  const handleClickOpen = async () => {
    const result = await trigger();
    if (result) {
      //***Refactored code with hook

      const { areSizesValidated } = validateSizes(watchAllFields);
      preSubmitAction();
      if (confirmationTexts.length > 0) {
        setConfirmationData({ texts: confirmationTexts, values: watchAllFields });
      } else {
        if (areSizesValidated) sendingConfirmed();
      }
    }
  };

  const sendingConfirmed = () => {
    // setConfirmDialog(false);
    clearConfirmationData();
    callHandleSubmit({
      patient: watchAllFields.patient,
      ...watchAllFields.study,
      referringPhysician: watchAllFields.referringPhysician,
      requestingPhysician: watchAllFields.requestingPhysician,
    });
  };

  return (
    <>
      {header}
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <PatientAndStudyEditForm
            isRequest={isRequest}
            register={register}
            isStudyEditType={isStudyEditType}
            setOrderDialog={setOrderDialog}
          />

          {isPatientEditType && (
            <Box sx={{ mt: compactMode ? 1 : 2 }} data-tour="editPatientGrid">
              <Typography variant="h6" sx={{ mb: 1 }}>{`${t('selectedStudies')} (${
                rows.length
              })`}</Typography>
              <Announcement
                label={t('fixWillChangeAllStudiesOfSelectedePatient')}
                type={'danger'}
                spaced={true}
                component={'div'}
              />
              <SearchResultsMUI
                rows={rows}
                setRows={setRows}
                actions={defViewer ? [LOCKED_STUDY, VIEWER] : [LOCKED_STUDY]}
                selecting={false}
                selection={selection}
                setSelection={setSelection}
                showDetailPanel={false}
                nameAsLink={false}
              />
            </Box>
          )}
          {showInfoPanel && (
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} key={'announcement'}>
                <Announcement
                  label={t('showInfoAboutRequestingPhysician')}
                  type={'info'}
                  spaced={true}
                  component={'div'}
                />
              </Grid>
            </Grid>
          )}

          {someStudyLocked && (
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} key={'announcement'}>
                <Announcement
                  label={t('patientLocked')}
                  type={'danger'}
                  spaced={true}
                  component={'div'}
                />
              </Grid>
            </Grid>
          )}

          <Papeer sx={{ mt: compactMode ? 1 : 2 }}>
            {!forDistribute && initialValues && (
              <Box>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12}>
                    <RequestAdditionalForm isRequest={isRequest} initialValues={initialValues} />
                  </Grid>
                </Grid>
              </Box>
            )}

            <Grid container={true} justifyContent="flex-end">
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpen}
                  disabled={isDicomDataLoaded && canEdit ? false : true}
                  data-selenium-selector="edit-submit-button"
                  data-tour="saveButton"
                >
                  {submitButtonText}
                </Button>
              </Box>
            </Grid>
          </Papeer>
        </form>
      </FormProvider>

      {confirmationData !== null ? (
        <ConfirmationDialog
          title={isStudyEditType ? t('editStudy') : t('editPatient')}
          open={true}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}
          cancelAction={clearConfirmationData}
          confirmAction={sendingConfirmed}
        >
          <Typography variant="body1">
            {confirmationData.texts.map((title, index) =>
              index === 0 ? <div key={index}>{title}</div> : <p key={index}>{title}</p>,
            )}
          </Typography>
        </ConfirmationDialog>
      ) : null}

      {isStudyEditType && orderDialog && (
        <RequestDialog
          toggleDialog={setOrderDialog}
          actions={[isStudyEditType ? SELECT_ORDER_TO_EDIT_STUDY : SELECT_ORDER_TO_EDIT_PATIENT]}
          isActionFirstColumn={true}
          reset={reset}
          watchAllFields={watchAllFields}
        />
      )}
      {/* {renderedDialog} */}
    </>
  );
};

export default StudiesEditDetail;
