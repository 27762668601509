import { useEffect } from 'react';
import { useAppInfo } from 'utils/hooks/useAppInfo';

const useTourControlDropdown = ({
  currentTourStep,
  setCurrentTourStep,
}: {
  currentTourStep?: any;
  setCurrentTourStep?: any;
}) => {
  const { tourControlPropsStore } = useAppInfo();

  const isDropDownOpenStore = tourControlPropsStore?.isDropDownOpen;

  const handleDropDownOpen = () => {
    setCurrentTourStep(currentTourStep + 1);
  };
  const handleDropDownClose = () => {
    if (currentTourStep < 1) {
      setCurrentTourStep(0);
      return;
    }
    setCurrentTourStep(currentTourStep - 1);
  };

  useEffect(() => {
    if ((currentTourStep === 0 || currentTourStep) && setCurrentTourStep) {
      isDropDownOpenStore ? handleDropDownOpen() : handleDropDownClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropDownOpenStore]);
};

export default useTourControlDropdown;
