import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isArray, get } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { studyQuestionColumns } from './_grid';
import { deleteStudyQuestion, getStudyQuestions } from './_api';
import { WarningMessage } from 'components/Form/Messages/WarningMessage';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useColumns } from 'utils/hooks/useColumns';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useActions } from 'utils/hooks/useActions';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppDispatch } from 'store/hooks';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'studyTestDefinitionsQuestionsMui';

const ExistingStudyTestTypeDefinitions: React.FC<any> = ({
  id,
  editDefinition,
  fetchQuestions,
  questions,
  isPublished,
  readyToPublish,
  loadEntity,
}) => {
  const { t } = useTranslation('Tests');
  const { toggleLoader } = useAppGlobals();
  const { cols } = useColumns(studyQuestionColumns, t);
  const { confirmationData } = useAppInfo();
  const dispatch = useAppDispatch();
  const { storeConfirmationData } = useActions();
  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 185,
        headerAlign: 'left',
        align: 'left',
        getActions: ({ row }: GridRowParams) => [
          <GridActionsCellItem
            icon={
              <Tooltip title={t('Grid:definitionEdit')}>
                <EditIcon />
              </Tooltip>
            }
            label={t('definitionEdit')}
            onClick={() => {
              editDefinition(row);
            }}
            disabled={isPublished}
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title={t('delete')}>
                <DeleteIcon />
              </Tooltip>
            }
            label={t('delete')}
            onClick={() => {
              dispatch(
                storeConfirmationDataToStore({
                  id: get(row, 'id'),
                  row,
                  confirmAction: 'confirmTestTypeDefinitionRemove',
                }),
              );
            }}
            disabled={isPublished}
          />,
        ],
      },
      {
        field: 'registerItemGroup',
        headerName: t('group'),
        valueGetter: (value: any, row: any) => get(row, 'registerItem.registerGroupName'),
      },
      {
        field: 'registerItemName',
        headerName: t('registerItem'),
        valueGetter: (value: any, row: any) => get(row, 'registerItem.name'),
      },
      { field: 'questionsMin', headerName: t('questionsMin') },
      { field: 'questionsMax', headerName: t('questionsMax') },
    ]),
  );

  const loadAllQuestions = async () => {
    toggleLoader();
    const questions = await getStudyQuestions(id);
    fetchQuestions(isArray(questions) ? questions : []);
    toggleLoader(false);
  };

  const { onEntityRemove } = useEntityRemove(
    deleteStudyQuestion,
    t,
    loadAllQuestions,
    'questionDeleted',
  );
  const clearAction = () => storeConfirmationData(null);
  const cancelAction = () => {
    clearAction();
  };

  const deleteTestTypeDefinitionConfirmAction = async () => {
    const resp = await onEntityRemove(get(confirmationData, 'id'));
    if (resp) {
      clearAction();
      loadAllQuestions();
    }
  };

  useEffect(() => {
    loadAllQuestions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RenderedGrid = useMemo(() => {
    const grid = isArray(questions) ? (
      <>
        <MuiGrid gridKey={muiGridKey} rows={questions} columns={columns} />
        {!isPublished && !readyToPublish && <WarningMessage textError="lessThanTwoDefinitions" />}
      </>
    ) : null;
    return grid;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, cols, editDefinition, onEntityRemove, isPublished, readyToPublish, loadEntity]);

  return (
    <>
      {RenderedGrid}
      {confirmationData &&
        confirmationData.id &&
        confirmationData.confirmAction === 'confirmTestTypeDefinitionRemove' && (
          <ConfirmationDialog
            title={t('confirmDelete')}
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            cancelAction={cancelAction}
            confirmAction={deleteTestTypeDefinitionConfirmAction}
          />
        )}
    </>
  );
};

export default ExistingStudyTestTypeDefinitions;
