import React from 'react';

import Header from 'components/Header/Header';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { IDicomOrNonDicomDetail } from './_types';
import { NonDicom } from './NonDicom';
import { Dicom } from './Dicom';

export const DicomOrNonDicomForm: React.FC<IDicomOrNonDicomDetail> = ({
  type,
  tourControlProps,
}) => {
  useWithTitle();

  return (
    <>
      <Header title={''} />
      {type === 'nonDICOM' ? (
        <NonDicom
          isTourOpen={tourControlProps?.isTourOpen}
          setIsTourOpen={tourControlProps?.setIsTourOpen}
          setIsDropDownOpen={tourControlProps?.setIsDropDownOpen}
        />
      ) : (
        <Dicom
          isTourOpen={tourControlProps?.isTourOpen}
          setIsTourOpen={tourControlProps?.setIsTourOpen}
          setIsDropDownOpen={tourControlProps?.setIsDropDownOpen}
        />
      )}
    </>
  );
};
