import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';

const useValidationSchema = (t: TFunction) => {
  const { required } = useValidators();

  const CSTOREFormSchema = yup.object().shape({
    targetAet: required(yup.string()),
    targetIp: required(yup.string()),
    targetPort: required(yup.string()),
    archiveId: required(yup.mixed()),
  });

  const formItems = [
    {
      type: 'select',
      name: 'archiveId',
      label: t('archiveTo'),
      dataTour: 'cstore-archive-id',
    },
    {
      name: 'targetAet',
      label: t('targetAet'),
      required: true,
      inputProps: { maxLength: 16 },
      dataTour: 'cstore-target-aet',
    },
    {
      name: 'targetIp',
      label: t('targetIp'),
      required: true,
      dataTour: 'cstore-target-ip',
    },
    {
      name: 'targetPort',
      label: t('targetPort'),
      required: true,
      dataTour: 'cstore-target-port',
    },
    {
      name: 'sourceAet',
      label: t('sourceAet'),
      inputProps: { maxLength: 16 },
      dataTour: 'cstore-source-aet',
    },
  ];

  return { CSTOREFormSchema, formItems };
};

export default useValidationSchema;
