import { useEffect, useState } from 'react';
import {
  compact,
  each,
  filter,
  find,
  get,
  isArray,
  isEmpty,
  isFunction,
  isObject,
  isPlainObject,
  isString,
  join,
  reverse,
  sortBy,
  uniq,
  without,
} from 'lodash';
import { useAppGlobals } from './useAppGlobals';
import { confirmShreddingRecord, createShreddingRecord } from 'modules/Studies/StudiesList/_api';
import { archiveStudy } from 'modules/Studies/StudiesArchive/_api';
import {
  BACK,
  SEARCH_FUNCTION_COPY,
  SEARCH_FUNCTION_EDIT_PATIENT,
  SEARCH_FUNCTION_EDIT_STUDY,
  SEARCH_FUNCTION_MOVE,
  SEARCH_FUNCTION_SEND,
  SEXES,
  STUDY_AVAILABILITY_ARCHIVED,
  STUDY_AVAILABILITY_ONLINE,
  SYSTEM_SETTINGS_FOR_SEARCH_FUNCTION,
  SEARCH_FUNCTION_SPLIT,
  SEARCH_FUNCTION_EXPORT,
  SEARCH_FUNCTION_REORDER,
  SEARCH_FUNCTION_ARCHIVE,
  ARCHIVE_TYPE_NAME_DPGW,
  INSTANCE_TIME_ACQUISITION_TIME,
  INSTANCE_TIME,
  INSTANCE_TIME_SERIE_TIME,
  SEARCH_FUNCTION_CHANGE_STATUSES,
  SEARCH_FUNCTION_IMPORT,
  PROPOSAL_SHREDDING,
  ARCHIVE,
  SEARCH_FUNCTION_DOSE_MONITORING,
  DOSE_MONITORING,
  DOSE_MONITORING_EXPORT,
} from 'constants/constants';
import { deleteStudy, getAllComments, getAllStudyTags } from 'modules/Studies/StudyDetail/_api';
import { ISerieImage, IStudy } from 'modules/Studies/StudyDetail/_types';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { settingHasValue } from 'utils/products';
import { IProduct } from 'modules/Administration/Products/_types';
import { IFullName } from 'modules/Administration/Dictionaries/_types';
import { IArchive, IStudyResultForGrid } from 'modules/Search/_types';
import { useUser } from './useUser';
import { useAppInfo } from './useAppInfo';
import { getParam } from 'utils/study';

export const useStudy = () => {
  const { toggleLoader } = useAppGlobals();
  const { hasRole } = useUser();
  const params = useParams();
  const { trash, archives, archivesForFunctions, getArchivesForFunctions } = useAppInfo();
  const [canCreateDistribute, setCreateDistribute] = useState<boolean>(false);
  let [searchParams] = useSearchParams();
  const sourcesMove = getArchivesForFunctions(SEARCH_FUNCTION_MOVE);
  const location = useLocation();

  useEffect(() => {
    // Pokud je k dispozici pouze jeden archiv, pak nelze přesouvat/kopírovat (MOVE/COPY)
    setCreateDistribute(isArray(archives) && archives.length > 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archives]);

  const generateIID = (study: IStudy) => `${study.studyInstanceUid}_${study.archive.id}`;

  const decodeIID = (iid: string) => {
    const [studyID, archiveID] = iid.split('_');
    return { studyID, archiveID };
  };

  const getPatientSexSlug = (sex: string) => get(find(SEXES, { value: sex }), 'label', '');

  const joinParams = (params: any[], joiner: string = ' ') =>
    isArray(params) && isString(joiner) ? without(compact(params), ['null']).join(joiner) : null;

  const getFullName = (fullNameObject: IFullName) => {
    if (!isPlainObject(fullNameObject)) {
      return '';
    }
    const valuesFromObject: string[] = [];
    each(['prefix', 'lastName', 'firstName', 'middleName', 'suffix'], (paramName: string) =>
      valuesFromObject.push(get(fullNameObject, paramName)),
    );
    return joinParams(compact(valuesFromObject));
  };

  const getCommentNameWithLogin = (user: any) =>
    `${joinParams([get(user, 'lastName'), get(user, 'firstName')])} (${get(user, 'username')})`;

  const linkBack = () => searchParams.get('action') === BACK;

  const getInitialTabsForStudy = () => {
    const mainTab = searchParams.get('tab');
    const subTab = searchParams.get('subtab');
    return { mainTab, subTab };
  };

  const getStudyActions = (
    study: IStudy | undefined,
    isLocked: boolean,
    studyArchive: IArchive | null,
    archiveTypeName: string,
  ) => {
    if (!isPlainObject(study) || typeof study === 'undefined') {
      return {};
    }
    const studyArchiveId = get(studyArchive, 'id');
    const systemSetting = get(studyArchive, 'systemSetting', []);

    const canCopy = checkAllowedArchives(SEARCH_FUNCTION_COPY, [study]);
    const canMove =
      checkAllowedArchives(SEARCH_FUNCTION_MOVE, [study]) &&
      settingHasValue(systemSetting, 'moveAllowed', 'true');
    const canSend = checkAllowedArchives(SEARCH_FUNCTION_SEND, [study]);
    const canSplit = checkAllowedArchives(SEARCH_FUNCTION_SPLIT, [study]);
    const canEdit = checkAllowedArchives(SEARCH_FUNCTION_EDIT_STUDY, [study]);
    const canExport = checkAllowedArchives(SEARCH_FUNCTION_EXPORT, [study]);
    const canImport = checkAllowedArchives(SEARCH_FUNCTION_IMPORT, [study]);
    const canReorder = checkAllowedArchives(SEARCH_FUNCTION_REORDER, [study]);
    const canShredding = checkAllowedArchives(PROPOSAL_SHREDDING, [study]);
    const canArchive = checkAllowedArchives(ARCHIVE, [study]) && studyCanBeArchived(study);

    const canDelete = trash !== null && get(trash, 'id', 0) !== studyArchiveId ? true : false;
    const hashIID = get(params, 'iid', null);
    const availability = getAvailability(study);
    const patientId = get(study, 'patient.identificationNumber', 0);

    return {
      addComment: {
        disable: isLocked || (!hasRole('ROLE_COMMENTARY_ALL') && !hasRole('ROLE_COMMENTARY_MINE')),
      },
      addTag: { disable: isLocked },
      addToFolder: {
        disable: isLocked || !hasRole('ROLE_CAN_OPERATE_WITH_FOLDERS'),
      },
      send: { disable: isLocked || !canSend, url: `/studies/send/detail?backTo=${hashIID}` },
      reorder: {
        disable: isLocked || !canReorder,
        url: `/studies?type=reorder`,
      },
      split: {
        disable: isLocked || !canSplit || get(study, 'numberOfStudyRelatedInstances', 0) < 2,
        url: `/studies/split/detail?backTo=${hashIID}`,
      },
      updateStudy: {
        disable: isLocked || !canEdit || availability === STUDY_AVAILABILITY_ARCHIVED,
        url: `/studies/editStudy/detail?backTo=${hashIID}`,
      },
      updatePatient: {
        disable: isLocked || !canEdit || availability === STUDY_AVAILABILITY_ARCHIVED,
        url: `/studies/editPatient/detail?backTo=${hashIID}`,
      },
      copy: {
        disable: isLocked || !canCopy,
        url: `/studies/copy/detail?backTo=${hashIID}`,
      },
      move: {
        disable: isLocked || availability === STUDY_AVAILABILITY_ARCHIVED || !canMove,
        url: `/studies/move/detail?backTo=${hashIID}`,
      },
      archive: {
        disable: isLocked || !canArchive,
        url: ``,
      },
      export: { disable: isLocked || !canExport, url: `/exports/detail?backTo=${hashIID}` },
      import: { disable: isLocked || !canImport, url: `/import/nondicom?backTo=${hashIID}` },
      importFromWebcam: { disable: isLocked || !canImport },
      createSR: {
        disable: isLocked || !hasRole('ROLE_CREATE_STRUCTURED_REPORT'),
      },
      print: { disable: isLocked },
      showStudyOrder: { disable: isLocked },
      delete: {
        disable: isLocked || !canDelete,
      },
      proposalShredding: {
        disable: isLocked || !canShredding,
      },
      doseMonitoring: {
        disable: isLocked || archiveTypeName !== ARCHIVE_TYPE_NAME_DPGW,
        url: `/doseMonitoring/calculate?backTo=${hashIID}&patientId=${patientId}`,
      },
      viewDicomAttributes: {
        disable: isLocked || !hasRole('ROLE_SHOW_STUDY_DICOM_TAGS'),
      },
      changeStatuses: {
        disable:
          isLocked ||
          !hasRole('ROLE_CAN_MANAGE_STUDY_STATUS') ||
          archiveTypeName !== ARCHIVE_TYPE_NAME_DPGW,
      },
    };
  };

  const checkAllowedArchive = (studyArchiveId: number, type: string) => {
    let process = true;
    const action =
      type === 'edit_study'
        ? SEARCH_FUNCTION_EDIT_PATIENT
        : type === 'edit_patient'
        ? SEARCH_FUNCTION_EDIT_STUDY
        : type === 'send'
        ? SEARCH_FUNCTION_SEND
        : type === 'copy'
        ? SEARCH_FUNCTION_COPY
        : type === 'move'
        ? SEARCH_FUNCTION_MOVE
        : type === 'split'
        ? SEARCH_FUNCTION_SPLIT
        : type === 'export'
        ? SEARCH_FUNCTION_EXPORT
        : type === 'import'
        ? SEARCH_FUNCTION_IMPORT
        : type === 'reorder'
        ? SEARCH_FUNCTION_REORDER
        : type === 'archive'
        ? SEARCH_FUNCTION_ARCHIVE
        : '';
    if (action) {
      process = isObject(
        find(get(archivesForFunctions, action, []), {
          id: studyArchiveId,
        }),
      );
    }

    return process;
  };

  const isTypeOf = (archive: any, type: string) => {
    const typeName = get(archive, 'type.name', '');
    return typeName === type;
  };

  const checkAllowedArchives = (type: string, studies: IStudyResultForGrid[] | IStudy[]) => {
    const typeLowerCase = type.toLowerCase();
    let canProcess = true;
    const usedArchives: number[] = [];
    const variable =
      typeLowerCase === 'edit_study' || typeLowerCase === 'edit_patient'
        ? 'updateAllowed'
        : typeLowerCase === 'send'
        ? 'sendAllowed'
        : typeLowerCase === 'copy'
        ? 'copyAllowed'
        : typeLowerCase === 'move'
        ? 'moveAllowed'
        : typeLowerCase === 'split'
        ? 'splitAllowed'
        : typeLowerCase === 'export'
        ? 'exportAllowed'
        : typeLowerCase === 'reorder'
        ? 'reorderAllowed'
        : typeLowerCase === 'movetotrash'
        ? 'moveToTrash'
        : typeLowerCase === 'import'
        ? 'importAllowed'
        : typeLowerCase === 'proposalshredding'
        ? 'shreddingAllowed'
        : typeLowerCase === 'archive'
        ? 'archivingAllowed'
        : '';

    studies.forEach((study: any) => {
      if (canProcess) {
        const studyArchiveId = get(study, 'archive.id', 0);
        usedArchives.push(studyArchiveId);
        const studyArchive = find(archives, ['id', studyArchiveId]);
        let systemSetting = null;
        if (studyArchive && variable) {
          systemSetting = get(studyArchive, 'systemSetting', []);
          const availability = getAvailability(study);
          if (typeLowerCase === 'movetotrash') {
            canProcess = trash !== null && get(trash, 'id', 0) !== studyArchiveId ? true : false;
          } else {
            canProcess =
              settingHasValue(systemSetting, variable, 'true') &&
              checkAllowedArchive(studyArchiveId, typeLowerCase);
            if (canProcess) {
              if (
                typeLowerCase === 'move' &&
                isEmpty(filter(sourcesMove, (source) => source.id === studyArchiveId)) &&
                availability === STUDY_AVAILABILITY_ARCHIVED
              ) {
                canProcess = false;
              }
              if (
                (typeLowerCase === 'edit_study' || typeLowerCase === 'edit_patient') &&
                availability === STUDY_AVAILABILITY_ARCHIVED
              ) {
                canProcess = false;
              }
              if (typeLowerCase === 'split' && get(study, 'numberOfStudyRelatedInstances', 0) < 2) {
                canProcess = false;
              }
              if (typeLowerCase === 'archive' && availability === STUDY_AVAILABILITY_ARCHIVED) {
                canProcess = false;
              }
              if (
                (typeLowerCase === 'copy' || typeLowerCase === 'move') &&
                (uniq(compact(usedArchives)).length > 1 || !canCreateDistribute)
              ) {
                canProcess = false;
              }
            }
          }
        }
        if (
          (type === SEARCH_FUNCTION_CHANGE_STATUSES ||
            type === SEARCH_FUNCTION_DOSE_MONITORING ||
            type === DOSE_MONITORING ||
            type === DOSE_MONITORING_EXPORT) &&
          !isTypeOf(studyArchive, ARCHIVE_TYPE_NAME_DPGW)
        ) {
          canProcess = false;
        }
      }
    });
    return canProcess;
  };

  /*
  All props must be passed from parent component
  */
  const studyArchivation = async ({
    studies,
    t,
    addSuccessAlert,
    addErrorAlert,
    study,
    callback,
  }: any) => {
    toggleLoader();
    const data = study ? [study] : studies;
    let resp = null;
    resp = await archiveStudy(data);
    if (resp) {
      addSuccessAlert(t('submitArchivingOk'));
      if (isFunction(callback)) {
        callback();
      }
    } else {
      addErrorAlert(t('submitArchivingError'));
    }
    toggleLoader(false);
  };

  /*
 All props must be passed from parent component
  */
  const deleteStudies = async ({
    studies,
    t,
    addInfoAlert,
    addSuccessAlert,
    addErrorAlert,
    study,
    callback,
  }: any) => {
    toggleLoader();
    let succesCount = 0;
    let errorCount = 0;
    let resp = null;
    const studies2 = !isEmpty(studies) ? studies : study ? [study] : [];
    let i = 0;
    for (i = 0; i < studies2.length; i++) {
      const item = studies2[i];
      const archiveId = get(item, 'archive.id', null);
      const studyUid = get(item, 'studyInstanceUid', null);
      if (!archiveId || !studyUid) {
        resp = false;
      } else {
        resp = await deleteStudy(studyUid, archiveId);
      }
      if (resp) {
        ++succesCount;
      } else {
        ++errorCount;
      }
    }
    if (succesCount && errorCount) {
      addInfoAlert(t('submitDeletingOkAndNok', { par1: succesCount }));
      if (isFunction(callback)) {
        callback();
      }
    } else if (errorCount) {
      addErrorAlert(t('submitDeletingError'));
    } else if (succesCount) {
      addSuccessAlert(t('submitDeletingOk'));
      if (isFunction(callback)) {
        callback();
      }
    }
    toggleLoader(false);
  };

  /*
 All props must be passed from parent component
  */
  const studiesProposalsShredding = async ({
    studies,
    t,
    // setInfoNotification,
    addInfoAlert,
    addSuccessAlert,
    addErrorAlert,
    study,
    callback,
  }: any) => {
    toggleLoader();
    let succesCount = 0;
    let errorCount = 0;
    let resp = null;
    const studies2 = !isEmpty(studies) ? studies : study ? [study] : [];
    let i = 0;
    for (i = 0; i < studies2.length; i++) {
      const item = studies2[i];
      const archiveId = get(item, 'archive.id', null);
      const studyUid = get(item, 'studyInstanceUid', null);
      if (!archiveId || !studyUid) {
        resp = false;
      } else {
        resp = await createShreddingRecord(archiveId, studyUid);
      }
      if (resp) {
        ++succesCount;
      } else {
        ++errorCount;
      }
    }
    if (succesCount && errorCount) {
      addInfoAlert(t('submitProposalShreddingOkAndNok', { par1: succesCount }));
      if (isFunction(callback)) {
        callback();
      }
    } else if (errorCount) {
      addErrorAlert(t('submitProposalShreddingError'));
    } else if (succesCount) {
      addSuccessAlert(t('submitProposalShreddingOk'));
      if (isFunction(callback)) {
        callback();
      }
    }
    toggleLoader(false);
  };

  const studiesShredding = async ({
    studies,
    t,
    addSuccessAlert,
    addErrorAlert,
    callback,
  }: any) => {
    toggleLoader();
    const ids = studies.map((item: any) => {
      return `shreddingRecordIds=${get(item, 'proposal.id')}`;
    });
    const params = join(ids, '&');
    const resp = await confirmShreddingRecord(params);
    if (resp) {
      addSuccessAlert(t('submitShreddingOk'));
      if (isFunction(callback)) {
        callback();
      }
    } else {
      addErrorAlert(t('submitShreddingError'));
    }
    toggleLoader(false);
  };

  const getStudyCommentsByStudyID = async (localArchiveID: number, localStudyID: string) => {
    const comments = await getAllComments({
      product: { id: localArchiveID },
      studyUid: localStudyID,
    });
    return isArray(comments) && comments.length ? reverse(sortBy(comments, ['id'])) : [];
  };

  const getStudyTagsByStudyID = async (localArchiveID: number, localStudyID: string) => {
    const studyTags = await getAllStudyTags({
      product: { id: localArchiveID },
      studyUid: localStudyID,
    });
    return isArray(studyTags) && studyTags.length ? reverse(sortBy(studyTags, ['id'])) : [];
  };

  const studyIsLocked = (study: IStudy) => get(study, 'inProcessing', false);

  const filterProductsForSearchFunction = (products: IProduct[], action: string) => {
    if (isArray(products) && products.length) {
      const newProducts = filter(products, ({ systemSetting }) =>
        settingHasValue(systemSetting, get(SYSTEM_SETTINGS_FOR_SEARCH_FUNCTION, action), 'true'),
      );
      return sortBy(
        filter(newProducts, ({ systemSetting }: any) =>
          settingHasValue(systemSetting, 'dataOutputAllowed', 'true'),
        ),
        ['name'],
      );
    } else {
      return [];
    }
  };

  const getAvailability = (study: IStudy) => get(study, 'availability');

  const studyCanBeArchived = (study: IStudy) =>
    getAvailability(study) === STUDY_AVAILABILITY_ONLINE;

  const lowerCaseFirstLetter = (txt: string) => txt[0].toLowerCase() + txt.slice(1);

  const replaceCharacter = '-';
  const generateIIDforForms = (uid: string) => uid.replaceAll('.', replaceCharacter);
  const decodeIIDfromForms = (uid: string) => uid.replaceAll(replaceCharacter, '.');

  const backToStudyIID = getParam(get(location, 'search'), 'backTo');

  const getInstanceTime = (instance: ISerieImage, timeOfSerie: string) =>
    get(instance, 'acquisitionTime')
      ? {
          time: get(instance, 'acquisitionTime'),
          type: INSTANCE_TIME_ACQUISITION_TIME,
        }
      : get(instance, 'time')
      ? {
          time: get(instance, 'time'),
          type: INSTANCE_TIME,
        }
      : {
          time: timeOfSerie,
          type: INSTANCE_TIME_SERIE_TIME,
        };

  return {
    studyIsLocked,
    generateIID,
    decodeIID,
    linkBack,
    joinParams,
    getFullName,
    backToStudyIID,
    getStudyActions,
    studyArchivation,
    deleteStudies,
    getPatientSexSlug,
    checkAllowedArchives,
    getInitialTabsForStudy,
    getCommentNameWithLogin,
    getStudyTagsByStudyID,
    studyCanBeArchived,
    getInstanceTime,
    getAvailability,
    filterProductsForSearchFunction,
    getStudyCommentsByStudyID,
    studiesProposalsShredding,
    studiesShredding,
    lowerCaseFirstLetter,
    generateIIDforForms,
    decodeIIDfromForms,
  };
};
