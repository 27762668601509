import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';

const useValidationSchema = (keyValues: any) => {
  const { required, unique } = useValidators();

  const PriorityFormSchema = yup.object().shape({
    name: unique(required(yup.string()), keyValues),
  });

  return { PriorityFormSchema };
};

export default useValidationSchema;
