import React, { useMemo } from 'react';
import { CircularProgress, Grid, GridSpacing, IconButton, Tooltip } from '@mui/material';
import { compact, find, get, head, intersection, isArray, isEmpty } from 'lodash';
import PriorityIcon from '@mui/icons-material/PriorityHigh';
import ReopenIcon from '@mui/icons-material/Unarchive';
import ArchiveInboxIcon from '@mui/icons-material/Inbox';
import ArchiveArchivedIcon from '@mui/icons-material/Archive';
import ArchiveOfflineIcon from '@mui/icons-material/ExploreOff';
import ArchiveUnavailableIcon from '@mui/icons-material/Error';

import { IStudyDetailInfo } from './_types';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { stateIsSame } from 'utils/componentOptimizatons';
import { useTranslation } from 'react-i18next';
import { Img } from 'components/Image/Img';
import { usePreview } from 'utils/hooks/usePreview';
import { useStudy } from 'utils/hooks/useStudy';
import {
  ARCHIVE_TYPE_NAME_MS,
  CLOSED,
  COLLAPSED,
  STUDY_AVAILABILITY_ARCHIVED,
  STUDY_AVAILABILITY_OFFLINE,
  STUDY_AVAILABILITY_ONLINE,
} from 'constants/constants';
import { settingHasValue } from 'utils/products';
import { format } from 'date-fns';
import { getTitleForArchivingStudyIcon, joinParams } from 'utils/study';
import { useUser } from 'utils/hooks/useUser';
import { Box } from '@mui/system';
import { getMedoroIcon } from './_icons';

const formatDateTime = 'dd. MM. yyyy, HH:mm:ss';

const PlainStudyDetailInfo: React.FC<IStudyDetailInfo> = ({
  onViewer,
  isLocked,
  study,
  series,
  dicomData,
  reopen,
  statusOfCode,
  statuses,
}) => {
  const { t } = useTranslation('StudyDetail');
  const { getAvailability } = useStudy();
  const { defViewer, spacing, lists, portalProductSettings } = useAppInfo();
  const { modNoShow, products } = usePreview();
  const { hasRole } = useUser();

  const modalitiesNotShown = !isEmpty(study)
    ? intersection(get(study, 'modalitiesInStudy'), modNoShow)
    : [];
  const studyArchiveId = get(study, 'archive.id', 0);

  const studyArchive = find(products, ['id', studyArchiveId]);
  const archiveTypeName = get(studyArchive, 'type.name', '');
  const availability = getAvailability(study);
  const modalities = get(lists, 'modalities');

  const portalSetting = {
    seePerformingPhysician: settingHasValue(
      portalProductSettings,
      'seePerformingPhysician',
      'true',
    ),
    seeReferringPhysician: settingHasValue(portalProductSettings, 'seeReferringPhysician', 'true'),
    seeRequestingPhysician: settingHasValue(
      portalProductSettings,
      'seeRequestingPhysician',
      'true',
    ),
  };

  const fieldsForStudy = useMemo(
    () => {
      const medoroStudyFlags = get(study, 'medoroStudyFlags', []);
      const stats = compact(
        (medoroStudyFlags ? medoroStudyFlags : []).map((studyFlag) => {
          return find(statuses, { uuid: studyFlag }) || null;
        }),
      );
      return compact([
        {
          name: 'accessionNumber',
          label: t('accessionNumber'),
          value: `${get(study, 'accessionNumber') ? get(study, 'accessionNumber') : ''}`,
        },
        get(study, 'dateTime')
          ? {
              name: 'dateTime',
              label: t('dateTime'),
              value: format(new Date(get(study, 'dateTime')), formatDateTime),
            }
          : null,

        {
          name: 'modality',
          label: t('modality'),
          value:
            isArray(modalities) && modalities.length && isArray(get(study, 'modalitiesInStudy'))
              ? get(study, 'modalitiesInStudy').map((mod: any) => {
                  const foundModality = find(modalities, { name: mod }) || {
                    name: 'OTHER - ' + mod,
                    color: '#000000',
                    description: 'Other',
                  };
                  return (
                    <Tooltip key={mod} title={get(foundModality, 'description', '')}>
                      <Box
                        component="span"
                        sx={{
                          color: '#ffffff',
                          fontSize: 10,
                          padding: '3px 8px',
                          display: 'inline',
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                          verticalAlign: 'baseline',
                          borderRadius: '0.25em',
                          fontWeight: 'bold',
                          marginRight: (theme) => theme.spacing(1 / 2),
                          marginBottom: (theme) => theme.spacing(1 / 2),
                          backgroundColor: get(foundModality, 'color'),
                        }}
                      >
                        {foundModality.name}
                      </Box>
                    </Tooltip>
                  );
                })
              : '',
        },
        {
          name: 'numberOfStudyRelatedSeriesAndInstances',
          label: t('numberOfStudyRelatedSeriesAndInstances'),
          value: (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span>
                {get(study, 'numberOfStudyRelatedSeries', '')} /{' '}
                {get(study, 'numberOfStudyRelatedInstances', '')}
              </span>
              {!isEmpty(modalitiesNotShown) && (
                <Tooltip title={t('notify') as String}>
                  <PriorityIcon />
                </Tooltip>
              )}
            </Box>
          ),
        },
        {
          name: 'archive.name',
          label: t('archive.name'),
          value: (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span>
                {get(study, 'archive.name')} ({get(study, 'archive.code')}){' '}
              </span>
              <Tooltip title={t(getTitleForArchivingStudyIcon(study)) as String}>
                {availability === STUDY_AVAILABILITY_ONLINE ? (
                  <ArchiveInboxIcon />
                ) : availability === STUDY_AVAILABILITY_ARCHIVED ? (
                  <ArchiveArchivedIcon />
                ) : availability === STUDY_AVAILABILITY_OFFLINE ? (
                  <ArchiveOfflineIcon />
                ) : (
                  <ArchiveUnavailableIcon />
                )}
              </Tooltip>
            </Box>
          ),
        },
        get(portalSetting, 'seeReferringPhysician', false)
          ? {
              name: 'referringPhysician.name',
              label: t('referringPhysician.name'),
              value: dicomData ? (
                get(dicomData, 'referringPhysician') ? (
                  `${joinParams([
                    get(dicomData, 'referringPhysician.prefix'),
                    get(dicomData, 'referringPhysician.lastName'),
                    get(dicomData, 'referringPhysician.firstName'),
                    get(dicomData, 'referringPhysician.middleName'),
                    get(dicomData, 'referringPhysician.suffix'),
                  ])}`
                ) : (
                  ''
                )
              ) : (
                <div>
                  <Tooltip title={t('loadingValue') as String} placement="right">
                    <CircularProgress size={16} />
                  </Tooltip>
                </div>
              ),
            }
          : null,
        get(portalSetting, 'seeRequestingPhysician', null)
          ? {
              name: 'requestingPhysician.name',
              label: t('requestingPhysician.name'),
              value: dicomData ? (
                get(dicomData, 'requestingPhysician') ? (
                  `${joinParams([
                    get(dicomData, 'requestingPhysician.prefix'),
                    get(dicomData, 'requestingPhysician.lastName'),
                    get(dicomData, 'requestingPhysician.firstName'),
                    get(dicomData, 'requestingPhysician.middleName'),
                    get(dicomData, 'requestingPhysician.suffix'),
                  ])}`
                ) : (
                  ''
                )
              ) : (
                <div>
                  <Tooltip title={t('loadingValue') as String} placement="right">
                    <CircularProgress size={16} />
                  </Tooltip>
                </div>
              ),
            }
          : null,
        get(portalSetting, 'seePerformingPhysician', null)
          ? {
              name: 'performingPhysician.name',
              label: t('performingPhysician.name'),
              value: dicomData ? (
                get(dicomData, 'performingPhysician') ? (
                  `${joinParams([
                    get(dicomData, 'performingPhysician.prefix'),
                    get(dicomData, 'performingPhysician.lastName'),
                    get(dicomData, 'performingPhysician.firstName'),
                    get(dicomData, 'performingPhysician.middleName'),
                    get(dicomData, 'performingPhysician.suffix'),
                  ])}`
                ) : (
                  ''
                )
              ) : (
                <div>
                  <Tooltip title={t('loadingValue') as String} placement="right">
                    <CircularProgress size={16} />
                  </Tooltip>
                </div>
              ),
            }
          : null,
        {
          name: 'patientSize',
          label: t('patientSize'),
          value: dicomData ? (
            get(dicomData, 'height') || ''
          ) : (
            <div>
              <Tooltip title={t('loadingValue') as String} placement="right">
                <CircularProgress size={16} />
              </Tooltip>
            </div>
          ),
        },
        {
          name: 'patientWeight',
          label: t('patientWeight'),
          value: dicomData ? (
            get(dicomData, 'weight') || ''
          ) : (
            <div>
              <Tooltip title={t('loadingValue') as String} placement="right">
                <CircularProgress size={16} />
              </Tooltip>
            </div>
          ),
        },
        {
          name: 'studyDescription',
          label: t('studyDescription'),
          value: get(study, 'studyDescription', ''),
          grid: {
            lg: 6,
            md: 12,
          },
        },
        {
          name: 'institutionName',
          label: t('institutionName'),
          value: get(dicomData, 'institutionName'),
        },
        archiveTypeName === ARCHIVE_TYPE_NAME_MS
          ? {
              name: 'statusOfCode',
              label: t('statusOfCode'),
              value: (
                <div>
                  {statusOfCode ? (
                    <>
                      {t(statusOfCode.toLowerCase())}
                      {((hasRole('ROLE_REOPEN') && statusOfCode === CLOSED) ||
                        (hasRole('ROLE_REOPEN') && statusOfCode === COLLAPSED)) && (
                        <Tooltip title={t('reopen') as String} placement="right">
                          <IconButton
                            sx={{
                              width: '24px',
                              height: '24px',
                              padding: 0,
                            }}
                            onClick={() => reopen(get(study, 'studyInstanceUid'), studyArchiveId)}
                            size="large"
                          >
                            <ReopenIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    <Tooltip title={t('loadingValue') as String} placement="right">
                      <CircularProgress size={16} />
                    </Tooltip>
                  )}
                </div>
              ),
            }
          : null,
        {
          name: 'studyStatuses',
          label: t('studyStatuses'),
          value: isArray(stats)
            ? stats.map((status, index) => {
                const uuid = get(status, 'uuid', index);
                const name = get(status, 'name', '');
                const title = name.charAt(0) === '{' ? t(name.slice(1, -1)) : name;
                const icon = get(status, 'icon', '');
                return (
                  <span key={`uuid-${uuid || '?'}-${index}`}>
                    <Tooltip title={title ? (title as String) : '?'} placement="bottom">
                      {getMedoroIcon(icon)}
                    </Tooltip>
                  </span>
                );
              })
            : '',
        },
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [study, dicomData, series, statusOfCode, statuses],
  );

  return (
    <Grid container={true} spacing={spacing as GridSpacing} data-tour="study-detail-mainSection">
      <Grid item={true} xs={12} md={10}>
        <div id="study">
          <Grid container={true} spacing={spacing as GridSpacing}>
            {fieldsForStudy.map((field) => (
              <Grid item={true} xs={6} md={4} lg={3} key={field.name}>
                {/* TODO: add {...field.grid} */}
                <Box component="label" sx={{ fontWeight: 'bold' }}>
                  {field.label}
                </Box>
                <div>{field.value}</div>
              </Grid>
            ))}
          </Grid>
        </div>
      </Grid>
      <Grid item={true} xs={12} md={2}>
        <Box sx={{ textAlign: 'center', mb: 1 }}>
          <Img
            patientId={get(study, 'patient.identificationNumber', '')}
            productId={get(study, 'archive.id') as unknown as string}
            studyIUID={get(study, 'studyInstanceUid', '')}
            seriesIUID={get(head(series), 'uid')!}
            series={series}
            topIndex={0}
            width={150}
            dialogPreview={true}
            onlyText={t('preview')}
            disabled={isLocked}
          />
        </Box>
        <Tooltip title={defViewer ? (t('Studies:openInBrowser') as String) : ''}>
          <Box
            sx={{ textAlign: 'center', cursor: defViewer ? 'pointer' : 'default' }}
            data-tour="study-detail-mainSection-preview"
            {...(defViewer ? { onClick: onViewer } : {})}
          >
            <Img
              patientId={get(study, 'patient.identificationNumber', '')}
              productId={get(study, 'archive.id') as unknown as string}
              studyIUID={get(study, 'studyInstanceUid', '')}
              seriesIUID={get(head(series), 'uid')!}
              series={series}
              topIndex={0}
              width={150}
            />
          </Box>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export const StudyDetailInfo = React.memo(PlainStudyDetailInfo, stateIsSame);
