import clsx from 'clsx';
import { find, get, isArray, isEmpty, isNumber, isUndefined, pickBy } from 'lodash';
import {
  DataGridPro,
  GridColDef,
  GridColumnOrderChangeParams,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { Box, SxProps, Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { useGridSettings } from 'components/SearchResultsMUI/useGridSettings';
import { useGrid } from 'utils/hooks/useGrid';
import { Papeer } from 'components/Papeer/Papeer';
import { useAppInfo } from './useAppInfo';
import { useFilterableColumns } from './useFilterableColumns';
import {
  DATAGRID_SETTINGS_COLUMN_ORDER_MODEL,
  DATAGRID_SETTINGS_COLUMN_SORT_MODEL,
  DATAGRID_SETTINGS_COLUMN_WIDTHS,
  DATA_GRID_DEFAULT_SORTING,
  DATA_GRID_FILTER_HEADER_ROW_HEIGHT,
  DATA_GRID_ROW_HEIGHT,
  NOTIFICATIONS_STATE_NEW,
} from 'constants/constants';
import useGridLocalization from 'components/SearchResultsMUI/useGridLocalization';

const dangerBackground = '#efc1c1';
const dangerBackgroundHover = '#d7adad';
const dangerBackgroundSelected = '#bf9a9a';
const dangerBackgroundSelectedHover = '#ab8a8a';
const darkDangerBackground = '#873336';
const darkDangerBackgroundHover = '#792d30';
const darkDangerBackgroundSelected = '#6c282b';
const darkDangerBackgroundSelectedHover = '#5e2325';
const greenRow = '#5FB3D3';

export const useMuiGrid = (gridKey: string) => {
  const { compactMode, feConfig } = useAppInfo();
  const theme = useTheme() as Theme;
  const isDarkMode = theme.palette.mode === 'dark';

  const {
    gridSettings,
    getUpdatedColumnWidthsAfterResize,
    getGridColumnsSort,
    getUpdatedColumnOrderAfterReordering,
    getGridColumnsWidths,
    getGridColumnsOrder,
    getGridColumnsVisibility,
    storeGridSettings,
  } = useGridSettings();

  const columnWidths = getGridColumnsWidths(gridKey);
  const columnOrder = getGridColumnsOrder(gridKey);
  const gridSortModel = getGridColumnsSort(gridKey);
  const columnVisibilityModel = getGridColumnsVisibility(gridKey);

  const { setGridSettingsAndStore } = useGrid();

  const gridLocalization = useGridLocalization();

  const reorderColumnsByGridSettings = (columns: any[]) => {
    if (!isArray(columnOrder) || columnOrder.length === 0) {
      return columns;
    }
    const sortedColumns = [...columns].sort((a, b) => {
      const indexA = columnOrder.indexOf(a.field);
      const indexB = columnOrder.indexOf(b.field);

      // Pokud a ani b nejsou v order poli, ponecháme je ve stávajícím pořadí.
      if (indexA === -1 && indexB === -1) {
        return 0;
      }

      // Pokud a není v order poli, posuneme ho za b.
      if (indexA === -1) {
        return 1;
      }

      // Pokud b není v order poli, posuneme ho za a.
      if (indexB === -1) {
        return -1;
      }

      // Oba prvky jsou v order poli, vrátíme je podle jejich pořadí v order poli.
      return indexA - indexB;
    });

    return sortedColumns;
  };

  const injectColumnWidthsIntoColumns = (columns: any, defaultWidth: number = 150) =>
    columns.map((column: any) => {
      const columnWidthFromGridSettings = get(find(columnWidths, { field: column.field }), 'width');
      return {
        ...column,
        width: isNumber(columnWidthFromGridSettings)
          ? columnWidthFromGridSettings
          : isNumber(column.width)
          ? column.width
          : defaultWidth,
      };
    });

  const setAndStore = (gridSettingsForAPI: any) => {
    const gridSettingsForRedux = {
      ...gridSettings,
      [gridKey]: gridSettingsForAPI,
    };

    setGridSettingsAndStore({
      key: gridKey,
      gridSettingsForAPI,
      gridSettingsForRedux,
    });
  };

  const changeWidthColumn = (columns: any[], changedColumn: GridColumnResizeParams) => {
    const columnNewWidth = changedColumn.width;
    const columnName = changedColumn.colDef.field;

    const gridSettingsForAPI = {
      ...get(gridSettings, gridKey, {}),
      [DATAGRID_SETTINGS_COLUMN_WIDTHS]: getUpdatedColumnWidthsAfterResize(
        columns,
        columnName,
        columnNewWidth,
      ),
    };
    setAndStore(gridSettingsForAPI);
  };

  const updateColumnOrder = (
    columns: any[],
    params: GridColumnOrderChangeParams,
    indexShiftForReordering: number = 0,
  ) => {
    const newColumnOrder = getUpdatedColumnOrderAfterReordering(
      columns,
      params.column.field,
      params.oldIndex - indexShiftForReordering,
      params.targetIndex - indexShiftForReordering,
    );
    const gridSettingsForAPI = {
      ...get(gridSettings, gridKey, {}),
      [DATAGRID_SETTINGS_COLUMN_ORDER_MODEL]: newColumnOrder.map((column) => column.field),
    };

    setAndStore(gridSettingsForAPI);
  };

  const sortByColumn = (sortModel: GridSortModel) => {
    const gridSettingsForAPI = {
      ...get(gridSettings, gridKey, {}),
      [DATAGRID_SETTINGS_COLUMN_SORT_MODEL]: sortModel,
    };

    setAndStore(gridSettingsForAPI);
  };

  const setColumnVisibilityModel = (newModel: GridColumnVisibilityModel) => {
    storeGridSettings(gridKey, {
      // ukládáme pouze sloupce, které jsou false (= schované)
      columnVisibilityModel: pickBy(newModel, (item: Boolean) => item === false),
    });
  };

  const QuickSearchToolbar = (props: any) => {
    return (
      <Box sx={{ p: 0.5 }}>
        {!props.hideGridToolbarColumnsButton && (
          <GridToolbarColumnsButton data-tour="common-gridColumns" />
        )}
        <GridToolbarFilterButton data-tour="common-gridFilters" />
        {/* <GridToolbarDensitySelector /> */}
        {props.showGridToolbarExport && (
          <GridToolbarExport
            data-tour="common-gridExport"
            csvOptions={{
              delimiter: get(feConfig, 'csvFieldSeparator', ';') || ';',
              utf8WithBom: true,
            }}
            printOptions={{ disableToolbarButton: true }}
          />
        )}
      </Box>
    );
  };

  const hiddenFields = ['__detail_panel_toggle__', '__check__', 'actions'];

  const getTogglableColumns = (columns: GridColDef[]) => {
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };

  interface ISortMode {
    field: string;
    sort: 'asc' | 'desc';
  }
  const MuiGrid = (values: {
    rows: any[];
    columns: any[];
    getRowId?: any;
    rowDetail?: {
      showRowDetail: boolean;
      DetailPanel: any;
      getDetailPanelHeight: any;
      detailPanelExpandedRowIds: any;
      handleDetailPanelExpandedRowIdsChange: any;
    };
    autoHeight?: boolean;
    rowHeight?: boolean; // for multiple columns value
    defaultHiddenColumnNames?: any | undefined;
    toolbar?: any; // own toolbar
    rowSelecting?: {
      selecting: boolean;
      selection: any;
      setSelection: any;
      loadInitialSelection?: boolean;
    };
    dataTour?: string;
    disableColumnMenu?: boolean;
    hideGridToolbarColumnsButton?: boolean;
    withoutMaxHight?: boolean;
    hideFooter?: boolean;
    flex?: number;
    hideHeaderFilters?: boolean;
    sxPapeer?: SxProps;
    initialSortMode?: ISortMode[];
    showGridToolbarExport?: boolean;
  }) => {
    const columnVisibilityModelWithDefaultHidden = {
      ...(isUndefined(columnVisibilityModel)
        ? values.defaultHiddenColumnNames
        : columnVisibilityModel),
    };
    return (
      <Box
        sx={{
          flex: isNumber(values.flex) ? values.flex : 1,
          display: 'flex',
          ...(!values.withoutMaxHight ? { maxHeight: '100%', overflow: 'hidden' } : {}),
        }}
      >
        <Papeer
          id={gridKey}
          sx={{
            flex: 1,
            ...(!values.withoutMaxHight ? { maxHeight: '100%' } : {}),
            maxWidth: '100%',
            ...(compactMode ? { p: 0 } : {}),
            ...values.sxPapeer,
          }}
        >
          <Box
            data-tour={values.dataTour || gridKey}
            sx={{
              height: '100% !important',
              width: '100% !important',
              '& .css.boldRow': { fontWeight: 'bold' },
              '& .css.greenRow': {
                backgroundColor: greenRow,
                hover: { backgroundColor: greenRow },
              },
              '& .css.dangerRow': {
                backgroundColor: isDarkMode ? darkDangerBackground : dangerBackground,
                '&:hover': {
                  backgroundColor: isDarkMode ? darkDangerBackgroundHover : dangerBackgroundHover,
                },
                '&.Mui-selected': {
                  backgroundColor: isDarkMode
                    ? darkDangerBackgroundSelected
                    : dangerBackgroundSelected,
                  '&:hover': {
                    backgroundColor: isDarkMode
                      ? darkDangerBackgroundSelectedHover
                      : dangerBackgroundSelectedHover,
                  },
                },
              },
            }}
          >
            <DataGridPro
              sortingOrder={DATA_GRID_DEFAULT_SORTING}
              headerFilters={!values.hideHeaderFilters}
              disableColumnMenu={values.disableColumnMenu}
              getRowId={values.getRowId}
              // autoHeight={isEmpty(values.rows) || values.autoHeight}
              // Commented out 4. 1. 2024 - to all grids look same - stretched vertically to 100% height
              {...(values?.rows?.length === 0 && values?.autoHeight && { autoHeight: true })} // 30.1.2024 - Make grid autoHeight if there are no rows. To display no records/zadne zaznamy
              sx={{
                ...(values.rowHeight
                  ? {
                      '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: 0 },
                      '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: 0.5 },
                      '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '25.2px' },
                    }
                  : {}),
              }}
              getRowClassName={(params) =>
                clsx('css', {
                  boldRow: get(params, 'row.state') === NOTIFICATIONS_STATE_NEW,
                  greenRow: get(params, 'row.testIsUnfinished', false),
                  dangerRow:
                    (get(params, 'row.requestId.status', '') != null &&
                      get(params, 'row.requestId.status', '').toLowerCase() === 'failed') ||
                    (get(params, 'row.status', '') !== null &&
                      get(params, 'row.status', '').toLowerCase() === 'nok'),
                })
              }
              getRowHeight={values.rowHeight ? () => 'auto' : undefined}
              rowHeight={!values.rowHeight ? DATA_GRID_ROW_HEIGHT : undefined}
              density={compactMode ? 'compact' : 'standard'}
              rows={values.rows}
              columns={useFilterableColumns(gridKey, values.columns)}
              localeText={gridLocalization}
              disableRowSelectionOnClick={true}
              slots={{
                toolbar: values.toolbar
                  ? values.toolbar
                  : values.disableColumnMenu
                  ? undefined
                  : () =>
                      QuickSearchToolbar({
                        hideGridToolbarColumnsButton: values.hideGridToolbarColumnsButton || false,
                        showGridToolbarExport: values.showGridToolbarExport || false,
                      }),
                detailPanelExpandIcon: ChevronRight,
                detailPanelCollapseIcon: ExpandMore,
              }}
              slotProps={{
                columnsPanel: {
                  className: 'react-tour-column-menu',
                },
                columnsManagement: {
                  getTogglableColumns,
                },
                filterPanel: {
                  className: 'react-tour-filter-panel',
                },
                footer: {
                  ...(compactMode
                    ? {
                        sx: {
                          minHeight: 30,
                        },
                      }
                    : {}),
                },
                headerFilterCell: {
                  height: DATA_GRID_FILTER_HEADER_ROW_HEIGHT,
                  InputComponentProps: {
                    size: 'small',
                  },
                },
                baseCheckbox: values.rowHeight
                  ? {
                      sx: {
                        p: 0,
                      },
                    }
                  : undefined,
              }}
              {...(!values?.rowDetail?.showRowDetail
                ? {}
                : {
                    ...(values?.rowDetail?.DetailPanel && {
                      getDetailPanelContent: values.rowDetail.DetailPanel,
                    }),
                    ...(values?.rowDetail?.getDetailPanelHeight && {
                      getDetailPanelHeight: values.rowDetail.getDetailPanelHeight,
                    }),
                    ...(values?.rowDetail?.detailPanelExpandedRowIds && {
                      detailPanelExpandedRowIds: values.rowDetail.detailPanelExpandedRowIds,
                    }),
                    ...(values?.rowDetail?.handleDetailPanelExpandedRowIdsChange && {
                      onDetailPanelExpandedRowIdsChange:
                        values.rowDetail.handleDetailPanelExpandedRowIdsChange,
                    }),
                  })}
              {...(!values?.rowSelecting?.selecting
                ? {}
                : {
                    checkboxSelection: values?.rowSelecting?.selecting,
                    ...(values?.rowSelecting?.setSelection && {
                      onRowSelectionModelChange: (newSelectionModel: any) =>
                        values?.rowSelecting?.setSelection(newSelectionModel),
                    }),
                    ...(values?.rowSelecting?.selection && {
                      rowSelectionModel: values?.rowSelecting?.selection,
                    }),
                  })}
              // used for initial selection of rows) https://github.com/mui/mui-x/issues/5807
              keepNonExistentRowsSelected={values?.rowSelecting?.loadInitialSelection || false}
              columnVisibilityModel={columnVisibilityModelWithDefaultHidden}
              onColumnVisibilityModelChange={setColumnVisibilityModel}
              onColumnWidthChange={(changedColumn: any) =>
                changeWidthColumn(values.columns, changedColumn)
              }
              onColumnOrderChange={(params: any) =>
                updateColumnOrder(values.columns, params, values?.rowSelecting?.selecting ? 1 : 0)
              }
              sortModel={
                isArray(gridSortModel) && !isEmpty(gridSortModel)
                  ? gridSortModel
                  : values.initialSortMode
                  ? values.initialSortMode
                  : []
              }
              onSortModelChange={sortByColumn}
              hideFooter={values.hideFooter}
            />
          </Box>
        </Papeer>
      </Box>
    );
  };

  return {
    columnWidths,
    columnVisibilityModel,
    gridSortModel,
    injectColumnWidthsIntoColumns,
    reorderColumnsByGridSettings,
    setColumnVisibilityModel,
    changeWidthColumn,
    updateColumnOrder,
    sortByColumn,
    QuickSearchToolbar,
    getTogglableColumns,
    MuiGrid,
  };
};
