import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { get, isEmpty, pull, find, isString, take } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography } from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { downloadPdfDocument, getDocumentDetail, getPdfDocument } from './_api';
import { IGridLayoutParams, IPatientDocument } from './_types';
import { getFolder } from 'modules/Administration/Folders/_api';
import { DocumentDetailTimeline } from './DocumentDetailTimeline';
import { DocumentDetailLayoutDialog } from './DocumentDetailLayoutDialog';
import { DocumentDetailGrids } from './DocumentDetailGrids';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useLanguage } from 'utils/hooks/useLanguage';
import { apiDataToDownloadFile, printOrDownloadBase64String } from 'utils/hooks/useApp';
import useAlerts from 'components/Alerts/useAlerts';
import Header from 'components/Header/Header';
import { hookDownloadFromEldax } from './hooks';

const sxDisplayPrintNone = {
  '@media print': {
    display: 'none',
  },
};

export const ClinicalPortalDocumentDetailFromFolder: React.FC = () => {
  const { t } = useTranslation('ClinicalPortal');
  const { toggleLoader } = useAppGlobals();
  const { currentLocale } = useLanguage();
  let [searchParams] = useSearchParams();

  const { addErrorAlert } = useAlerts();
  const { selectedDocuments } = useAppInfo();

  const from = searchParams.get('from');
  const backToPathname = searchParams.get('pathname');
  const linkBack = backToPathname
    ? `${backToPathname}?action=back${from ? `&from=${from}` : ''}`
    : '/';

  const params = useParams();
  const folderId = get(params, 'folderId', '');

  const [entity, setEntity] = useState<any>(null);
  const [gridLayout, setGridLayout] = useState<string>('');
  const [requestedDocuments, setRequestedDocuments] = useState<any[]>([]);
  const [patientDocuments, setPatientDocuments] = useState<IPatientDocument[]>([]);
  const [documentUids, setDocumentUids] = useState<any[]>([]);
  const [openLayoutDialog, setOpenLayoutDialog] = useState<boolean>(false);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [standardizedPrint, setStandardizedPrint] = useState<boolean>(false);
  const [isDownloadingFromEldax, setIsDownloadingFromEldax] = useState<boolean>(false);

  const loadDocuments = async () => {
    toggleLoader();
    const selectedDocumentsCount = selectedDocuments.length;
    setGridLayout(
      `${Math.ceil(selectedDocumentsCount / 4)}x${
        selectedDocumentsCount > 4 ? 4 : selectedDocumentsCount
      }`,
    );
    setDocumentUids(selectedDocuments);

    toggleLoader(false);
  };

  useEffect(() => {
    loadDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const load = async (folderId: any) => {
    toggleLoader();
    const folder = await getFolder(folderId);
    if (folder) {
      const folderDocuments = get(folder, 'documents', []);
      const preparedDocuments = folderDocuments.map((item: any) => ({
        ...item,
        id: get(item, 'uuid', ''),
        typeOfDocumentName: get(item, 'typeOfDocument.translateJson')
          ? get(
              JSON.parse(get(item, 'typeOfDocument.translateJson')),
              currentLocale,
              get(item, 'typeOfDocument.name'),
            )
          : '?',
        patientEntity: get(item, 'patient', {}),
        patient: get(item, 'patient', {}),
      }));
      setPatientDocuments(preparedDocuments);

      const someDocuments: any[] = [
        {
          uuid: get(preparedDocuments[0], 'uuid', ''),
          typeOfDocument: get(preparedDocuments[0], 'typeOfDocument.name', ''),
        },
      ];
      const documentDetail = await getDocumentDetail(
        preparedDocuments[0].patientEntity.masterPatientID,
        [],
        someDocuments,
      );
      const patient = get(documentDetail, 'patient', null);
      const requestedDocuments = get(documentDetail, 'requestedDocuments', []);

      setEntity(patient);
      setRequestedDocuments(requestedDocuments);
    }
    toggleLoader(false);
  };
  useEffect(() => {
    if (folderId) {
      load(folderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId]);

  const loadDocumentForPrint = async (
    masterPatientID: any,
    documentUiid: any,
    typeOfDocument: any,
    isPrinting: boolean,
    isExporting: boolean,
    standardizedPrint: boolean,
  ) => {
    if (masterPatientID !== null && documentUiid !== null && typeOfDocument !== null) {
      const documentPrintInfoResponse = await getPdfDocument(
        masterPatientID,
        documentUiid,
        typeOfDocument,
        standardizedPrint,
      );
      if (documentPrintInfoResponse) {
        return documentPrintInfoResponse;
      } else {
        if (isPrinting) {
          addErrorAlert(t('printDocumentError'));
        }
        if (isExporting) {
          addErrorAlert(t('exportDocumentError'));
        }
      }
    }
    return undefined;
  };

  const printOrExport = async () => {
    toggleLoader();
    for await (const uuid of selectedDocuments) {
      const patientdoc = find(patientDocuments, { uuid });
      const masterPatientID = get(entity, 'masterPatientID', null);
      const fileName = masterPatientID + '.pdf';
      if (isPrinting) {
        const base64PdfString = await loadDocumentForPrint(
          masterPatientID,
          uuid,
          get(patientdoc, 'typeOfDocument.name', null),
          isPrinting,
          isExporting,
          standardizedPrint,
        );
        const contentType = 'application/pdf';
        printOrDownloadBase64String(base64PdfString, contentType, fileName);
        setStandardizedPrint(false);
        setIsPrinting(false);
      } else if (isExporting) {
        const base64PdfString = await downloadPdfDocument(
          masterPatientID,
          uuid,
          get(patientdoc, 'typeOfDocument.name', ''),
          standardizedPrint,
        );
        apiDataToDownloadFile(base64PdfString, fileName);
        setStandardizedPrint(false);
        setIsExporting(false);
      }
    }
    toggleLoader(false);
  };
  useEffect(() => {
    if (isPrinting || isExporting) {
      printOrExport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrinting, isExporting]);

  const downloadFromEldaxFunction = async () => {
    toggleLoader();
    for await (const uuid of selectedDocuments) {
      const patientdoc = find(patientDocuments, { uuid });
      const masterPatientID = get(patientdoc, 'patientEntity.masterPatientID', null);
      hookDownloadFromEldax(
        masterPatientID,
        patientdoc,
        uuid,
        t,
        addErrorAlert,
        setIsDownloadingFromEldax,
      );
    }
    toggleLoader(false);
  };
  useEffect(() => {
    if (isDownloadingFromEldax) {
      downloadFromEldaxFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadingFromEldax]);

  const unselectDocumentUuid = (uuid: string) => {
    const reducedDocumentUids = [...documentUids];
    pull(reducedDocumentUids, uuid);

    setDocumentUids(reducedDocumentUids);
  };
  const selectDocumentUuid = (uuid: string) => {
    const updatedDocumentUids = [...documentUids];
    updatedDocumentUids.push(uuid);

    setDocumentUids(updatedDocumentUids);
  };

  const replaceLastDocumentUuid = (uuid: string) => {
    const currentUids = [...documentUids];
    currentUids[currentUids.length - 1] = uuid;
    setDocumentUids(currentUids);
  };

  const replaceDocumentAtIndex = (uuid: string, index: number) => {
    const currentUids = [...documentUids];
    currentUids[index] = uuid;
    setDocumentUids(currentUids);
  };

  const [gridLayoutParams, setGridLayoutParams] = useState<IGridLayoutParams>({
    x: 1,
    y: 1,
    totalAvailableFields: 1,
    lastDocumentPositionIndex: 1,
  });

  useEffect(() => {
    if (isString(gridLayout)) {
      const dimesions = gridLayout.split('x');
      if (dimesions.length === 2) {
        const x = Number(dimesions[0]);
        const y = Number(dimesions[1]);
        const max = x * y;
        const newUids = take(documentUids, max);
        setDocumentUids(newUids);
        setGridLayoutParams({
          x,
          y,
          totalAvailableFields: x * y,
          lastDocumentPositionIndex: documentUids.length - 1,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridLayout, documentUids.length]);

  return (
    <>
      <Typography component="div" sx={sxDisplayPrintNone}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Header title={t('documentsDetail')} backUrl={linkBack} />
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setOpenLayoutDialog(true)}
            style={{ marginBottom: '8px', marginInlineStart: 'auto' }}
          >
            <ViewColumnIcon />
          </IconButton>
        </Box>
      </Typography>

      {!isEmpty(patientDocuments) && gridLayout && (
        <Box sx={sxDisplayPrintNone}>
          <DocumentDetailTimeline
            patientDocuments={patientDocuments}
            gridLayout={gridLayout}
            documentUids={documentUids}
            setDocumentUids={setDocumentUids}
            selectDocumentUuid={selectDocumentUuid}
            unselectDocumentUuid={unselectDocumentUuid}
            replaceLastDocumentUuid={replaceLastDocumentUuid}
            gridLayoutParams={gridLayoutParams}
          />

          <DocumentDetailGrids
            gridLayout={gridLayout}
            documentUids={documentUids}
            selectDocumentUuid={selectDocumentUuid}
            unselectDocumentUuid={unselectDocumentUuid}
            patientDocuments={patientDocuments}
            setIsPrinting={setIsPrinting}
            setStandardizedPrint={setStandardizedPrint}
            setIsExporting={setIsExporting}
            requestedDocuments={requestedDocuments}
            gridLayoutParams={gridLayoutParams}
            replaceDocumentAtIndex={replaceDocumentAtIndex}
            setIsDownloadingFromEldax={setIsDownloadingFromEldax}
          />
        </Box>
      )}

      {openLayoutDialog && (
        <DocumentDetailLayoutDialog
          openLayoutDialog={openLayoutDialog}
          setOpenLayoutDialog={setOpenLayoutDialog}
          setGridLayout={setGridLayout}
          gridLayoutParams={gridLayoutParams}
        />
      )}
    </>
  );
};
