import HelperComponent from 'components/Tour/HelperComponent';
import { getDropDownStep } from 'components/Tour/_dropDownStep';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

interface IUseStepsProps {
  type: 'copyStudy' | 'moveStudy';
  showEditFormSteps?: boolean;
}

export const TourStudyDistribute = ({ type, showEditFormSteps }: IUseStepsProps) => {
  const { t } = useTranslation('Reactour');

  const allStepsCopyStudy = ({ isDropDownOpen, currentOpenDropdown }: any) => {
    const steps = {
      '[data-tour="backButton"]': t('study.back'),
      '[data-tour="distributeStudyTargetArchive"]': t('study.copy.targetArchive'),
      ...(currentOpenDropdown === 'productIdTo' && getDropDownStep({ isDropDownOpen, t })),
      '[data-tour="studyAnonymization"]': t('study.copy.anonymization'),
      ...(currentOpenDropdown === 'anonymization' && getDropDownStep({ isDropDownOpen, t })),
      '[data-tour="distributeStudyListOfStudy"]': t('study.copy.listOfStudy'),
      '[data-tour="distributeSubmitButton"]': t('study.copy.copyButton'),
    };
    return steps;
  };

  const allStepsMoveStudy = ({ isDropDownOpen, currentOpenDropdown }: any) => {
    const steps = {
      '[data-tour="backButton"]': t('study.back'),
      '[data-tour="distributeStudyTargetArchive"]': t('study.move.targetArchive'),
      ...(currentOpenDropdown === 'productIdTo' && getDropDownStep({ isDropDownOpen, t })),
      ...(showEditFormSteps && {
        '[data-tour="distributeStudyShowEditForm"]': t('study.move.showEditForm'),
      }),
      '[data-tour="studyAnonymization"]': t('study.move.anonymization'),
      ...(currentOpenDropdown === 'anonymization' && getDropDownStep({ isDropDownOpen, t })),
      '[data-tour="distributeStudyListOfStudy"]': t('study.move.listOfStudy'),
      '[data-tour="distributeSubmitButton"]': t('study.move.moveButton'),
    };
    return steps;
  };

  let stepsDefinition;
  if (type === 'copyStudy') stepsDefinition = allStepsCopyStudy;
  if (type === 'moveStudy') stepsDefinition = allStepsMoveStudy;

  useReactTour({ stepsDefinition });

  return <HelperComponent />;
};
