import React, { useMemo, useCallback, useState, useEffect } from 'react';
import {
  DataGridPro,
  GridRenderCellParams,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridColumnVisibilityModel,
  // GridColDef,
  GridRowModel,
} from '@mui/x-data-grid-pro';
import { Box, Tooltip } from '@mui/material';
import ViewerIcon from '@mui/icons-material/Image';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { get, pickBy, compact, keys, isArray, isEmpty, intersection, find } from 'lodash';
import { Img } from 'components/Image/Img';
import {
  ARCHIVE_TYPE_NAME_DPGW,
  DATAGRID_SETTINGS_COLUMN_ORDER_MODEL,
  DATAGRID_SETTINGS_COLUMN_SORT_MODEL,
  DATAGRID_SETTINGS_COLUMN_WIDTHS,
  DATA_GRID_DEFAULT_SORTING,
  DATA_GRID_FILTER_HEADER_ROW_HEIGHT,
  DATA_GRID_ROW_HEIGHT,
  DATA_GRID_SERIES_ROW_HEIGHT,
  DATA_GRID_SERIES_ROW_HEIGHT_COMPACT_MODE,
  GRID_STUDY_DETAIL_INSTANCES_SETTINGS_KEY,
  PREVIEW_URL_SIZE,
  PREVIEW_URL_SIZE_COMPACT_MODE,
} from 'constants/constants';
import { IGridInstances } from './_types';
import { getUrlForOpenViewer } from 'modules/Studies/StudyDetail/_api';
import useAlerts from 'components/Alerts/useAlerts';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useGridSettings } from './useGridSettings';
import { stateIsSame } from 'utils/componentOptimizatons';
import { useAppSelector } from 'store/hooks';
import { useGrid } from 'utils/hooks/useGrid';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import useGridLocalization from './useGridLocalization';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import StudyDetailInstancesForm from 'modules/Studies/StudyDetail/StudyDetailInstancesForm';
import { BatchButton } from 'components/Form/BatchButton/BatchButton';

const buttonStyle = { marginRight: '8px', marginBottom: '8px', width: 'fit-content' };

const GridInstancesMUIPlain: React.FC<IGridInstances> = ({
  rows,
  studyAndSerie,
  selecting = true,
  customActions,
  handleLifeCycle = undefined,
}) => {
  const studyID = studyAndSerie.studyUid;
  const archiveID = studyAndSerie.archiveId;
  const { t } = useTranslation('SearchResults');
  const { archives, defViewer, compactMode } = useAppInfo();
  const { addErrorAlert } = useAlerts();
  const gridLocalization = useGridLocalization();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(
    GRID_STUDY_DETAIL_INSTANCES_SETTINGS_KEY,
  );

  const {
    storeGridSettings,
    gridSettings,
    getUpdatedColumnWidthsAfterResize,
    getGridColumnsSort,
    getUpdatedColumnOrderAfterReordering,
  } = useGridSettings();
  const { setGridSettingsAndStore } = useGrid();

  const gridSortModel = getGridColumnsSort(GRID_STUDY_DETAIL_INSTANCES_SETTINGS_KEY);

  const columnVisibilityModel = useAppSelector((state) =>
    get(
      state,
      `app.gridSettings.${GRID_STUDY_DETAIL_INSTANCES_SETTINGS_KEY}.columnVisibilityModel`,
    ),
  );

  const setColumnVisibilityModel = useCallback(
    (newModel: GridColumnVisibilityModel) => {
      storeGridSettings(GRID_STUDY_DETAIL_INSTANCES_SETTINGS_KEY, {
        columnVisibilityModel: pickBy(newModel, (item: Boolean) => item === false), // ukládáme pouze sloupce, které jsou false (= schované)
      });
    },
    [storeGridSettings],
  );

  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [archiveTypeName, setArchiveTypeName] = useState<string>('');
  const [showDialog, toggleDialog] = useState<boolean>(false);
  const [selectedInstancesForRemove, setSelectedInstancesForRemove] = useState<any[]>([]);
  const [selection, setSelection] = useState<any[]>([]);

  useMemo(() => {
    if (archives && studyID && archiveID) {
      const selectedArchive = find(archives, ['id', archiveID]);
      const archiveTypeName = get(selectedArchive, 'type.name', '');
      setArchiveTypeName(archiveTypeName);
    }
  }, [archives, studyID, archiveID]);

  const isDpgw = archiveTypeName === ARCHIVE_TYPE_NAME_DPGW;

  const removeInstances = async (listOfInstances: any[]) => {
    setSelectedInstancesForRemove(listOfInstances);
    toggleDialog(true);
  };

  const removeSelectImages = async () => {
    const listOfInstances = selection.map((item) => find(rows, { sopinstanceUid: item }));
    removeInstances(listOfInstances);
  };

  const onCustomEntityRemove = useCallback(
    async (entity: any) => {
      removeInstances([entity]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const columns = useMemo(() => {
    const columns = [
      {
        field: 'actions',
        headerName: t('actions'),
        type: 'actions',
        hideable: false,
        renderCell: ({ row }: GridRenderCellParams) => {
          let actions = [
            <GridActionsCellItem
              icon={
                <Tooltip title={t('openInBrowserTitle')}>
                  <ViewerIcon />
                </Tooltip>
              }
              label={t('openInBrowserTitle')}
              disabled={!defViewer ? true : false}
              onClick={() => {
                const studyID = studyAndSerie.studyUid;
                const archiveID = studyAndSerie.archiveId;
                const patientId = studyAndSerie.patientId;
                const serieUid = studyAndSerie.serieUid;
                const sopUid = get(row, 'sopinstanceUid', '');

                getUrlForOpenViewer(studyID, patientId, archiveID, serieUid, sopUid).then(
                  (response) =>
                    response
                      ? window.open(response, '_blank')
                      : addErrorAlert(t('Errors:cannotOpenLink')),
                  (error) => addErrorAlert(t('Errors:cannotOpenLink')),
                );
              }}
            />,
            isDpgw && selecting ? (
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('deleteImage')}>
                    <Delete />
                  </Tooltip>
                }
                label={t('deleteImage')}
                onClick={async () => {
                  await onCustomEntityRemove(row);
                }}
              />
            ) : undefined,
          ];

          if (customActions !== undefined) {
            actions = actions.concat(customActions(row));
          }

          return actions;
        },
      },
      {
        field: 'previewUrl',
        headerName: t('Grid:seriesPreview'),
        renderCell: ({ row }: GridRenderCellParams) => {
          const studyID = studyAndSerie.studyUid;
          const archiveID = studyAndSerie.archiveId;
          const patientId = studyAndSerie.patientId;
          const serieUid = studyAndSerie.serieUid;
          const imageIUID = get(row, 'sopinstanceUid', '');
          return (
            <Img
              patientId={patientId}
              productId={archiveID}
              studyIUID={studyID}
              seriesIUID={serieUid}
              imageIUID={imageIUID}
              width={compactMode ? PREVIEW_URL_SIZE_COMPACT_MODE : PREVIEW_URL_SIZE}
              dialogPreview={true}
              canLoadImgs={true}
            />
          );
        },
      },
      { field: 'instanceNumber', headerName: t('instanceNumber') },
    ];

    const reorderedColumnsWithWidths = reorderColumnsByGridSettings(
      injectColumnWidthsIntoColumns(columns, 200),
    );

    const updatedColumns = reorderedColumnsWithWidths.map((column) => ({
      ...column,
      filterable: column.type !== 'actions' && !hiddenColumns.includes(column.field),
    }));

    return updatedColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    addErrorAlert,
    defViewer,
    customActions,
    hiddenColumns,
    injectColumnWidthsIntoColumns,
    reorderColumnsByGridSettings,
  ]);

  const onColumnWidthChange = useCallback(
    async (changedColumn: any) => {
      const columnNewWidth = changedColumn.width;
      const columnName = changedColumn.colDef.field;

      const gridSettingsForAPI = {
        ...get(gridSettings, GRID_STUDY_DETAIL_INSTANCES_SETTINGS_KEY, {}),
        [DATAGRID_SETTINGS_COLUMN_WIDTHS]: getUpdatedColumnWidthsAfterResize(
          columns,
          columnName,
          columnNewWidth,
        ),
      };
      const gridSettingsForRedux = {
        ...gridSettings,
        [GRID_STUDY_DETAIL_INSTANCES_SETTINGS_KEY]: gridSettingsForAPI,
      };

      setGridSettingsAndStore({
        key: GRID_STUDY_DETAIL_INSTANCES_SETTINGS_KEY,
        gridSettingsForAPI,
        gridSettingsForRedux,
      });
    },
    [gridSettings, setGridSettingsAndStore, columns, getUpdatedColumnWidthsAfterResize],
  );

  const onSortModelChange = useCallback(
    async (newSortModel: any) => {
      const gridSettingsForAPI = {
        ...get(gridSettings, GRID_STUDY_DETAIL_INSTANCES_SETTINGS_KEY, {}),
        [DATAGRID_SETTINGS_COLUMN_SORT_MODEL]: newSortModel,
      };
      const gridSettingsForRedux = {
        ...gridSettings,
        [GRID_STUDY_DETAIL_INSTANCES_SETTINGS_KEY]: gridSettingsForAPI,
      };

      setGridSettingsAndStore({
        key: GRID_STUDY_DETAIL_INSTANCES_SETTINGS_KEY,
        gridSettingsForAPI,
        gridSettingsForRedux,
      });
    },
    [gridSettings, setGridSettingsAndStore],
  );

  // Compute hidden columns not to be shown in filters
  useEffect(() => {
    const hiddenColumns = compact(
      keys(columnVisibilityModel).map((columnName) =>
        get(columnVisibilityModel, columnName) === false ? columnName : undefined,
      ),
    );
    setHiddenColumns(hiddenColumns);
  }, [columnVisibilityModel]);

  const getRowId = useCallback((row: GridRowModel) => row.sopinstanceUid, []);

  const QuickSearchToolbar = (props: any) => {
    return (
      <Box sx={{ p: 0.5 }}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        {/* <GridToolbarDensitySelector /> */}
      </Box>
    );
  };

  let indexShiftForReordering = selecting && isDpgw ? 1 : 0;

  const onColumnOrderChange = useCallback(
    async (params: any) => {
      const newColumnOrder = getUpdatedColumnOrderAfterReordering(
        columns,
        params.column.field,
        params.oldIndex - indexShiftForReordering,
        params.targetIndex - indexShiftForReordering,
      );

      const gridSettingsForAPI = {
        ...get(gridSettings, GRID_STUDY_DETAIL_INSTANCES_SETTINGS_KEY, {}),
        [DATAGRID_SETTINGS_COLUMN_ORDER_MODEL]: newColumnOrder.map((column) => column.field),
      };
      const gridSettingsForRedux = {
        ...gridSettings,
        [GRID_STUDY_DETAIL_INSTANCES_SETTINGS_KEY]: gridSettingsForAPI,
      };

      await setGridSettingsAndStore({
        key: GRID_STUDY_DETAIL_INSTANCES_SETTINGS_KEY,
        gridSettingsForAPI,
        gridSettingsForRedux,
      });
    },
    [
      columns,
      getUpdatedColumnOrderAfterReordering,
      gridSettings,
      indexShiftForReordering,
      setGridSettingsAndStore,
    ],
  );


  /*

  const hiddenFields = ['__detail_panel_toggle__', '__check__', 'actions'];

  const getTogglableColumns = (columns: GridColDef[]) => {
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };
  */

  const columnsThatCauseRowsToBeFixedHeight = ['previewUrl'];

  // If all columns that cause rows to have fixed height are hidden, we can set row height to typical DATA_GRID_ROW_HEIGHT
  const gridShouldHaveFixedRowHeight =
    intersection(hiddenColumns, columnsThatCauseRowsToBeFixedHeight).length !==
    columnsThatCauseRowsToBeFixedHeight.length;

  return (
    <>
      {isDpgw && selecting && (
        <BatchButton
          size="small"
          onClick={() => removeSelectImages()}
          label={t('multipleRemoveImages')}
          numberOfSelectedStudies={selection.length}
          style={buttonStyle}
        />
      )}
      <DataGridPro
        sortingOrder={DATA_GRID_DEFAULT_SORTING}
        headerFilters={true}
        getRowId={getRowId}
        density={compactMode && !gridShouldHaveFixedRowHeight ? 'compact' : 'standard'} // MAPO-3319
        autoHeight={isEmpty(rows)}
        rows={rows}
        columns={columns}
        rowHeight={
          gridShouldHaveFixedRowHeight
            ? compactMode
              ? DATA_GRID_SERIES_ROW_HEIGHT_COMPACT_MODE
              : DATA_GRID_SERIES_ROW_HEIGHT
            : DATA_GRID_ROW_HEIGHT
        }
        disableRowSelectionOnClick={true}
        columnVisibilityModel={columnVisibilityModel || {}}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
        onColumnWidthChange={onColumnWidthChange}
        onColumnOrderChange={onColumnOrderChange}
        localeText={gridLocalization}
        sx={{ flex: 1 }}
        hideFooter={true}
        slots={{
          toolbar: QuickSearchToolbar,
        }}
        slotProps={{
          // columnsPanel: {
          //   getTogglableColumns,
          // },
          headerFilterCell: {
            height: DATA_GRID_FILTER_HEADER_ROW_HEIGHT,
            InputComponentProps: {
              size: 'small',
            },
          },
        }}
        checkboxSelection={selecting && isDpgw}
        onRowSelectionModelChange={setSelection}
        sortModel={
          isArray(gridSortModel) && !isEmpty(gridSortModel)
            ? gridSortModel
            : [{ field: 'instanceNumber', sort: 'asc' }]
        }
        onSortModelChange={onSortModelChange}
      />
      {showDialog && (
        <OverflowedDialog key={'edit_dialog_id'} open={true} maxWidth="md" fullWidth={true}>
          <StudyDetailInstancesForm
            toggleDialog={toggleDialog}
            studyAndSerie={studyAndSerie}
            images={selectedInstancesForRemove}
            handleLifeCycle={handleLifeCycle}
          />
        </OverflowedDialog>
      )}
    </>
  );
};

export const GridInstancesMUI = React.memo(GridInstancesMUIPlain, stateIsSame);
