import { Controller, useFormContext } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import csLocale from 'date-fns/locale/cs';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const FormTimePicker = (props: any) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { name, label, defaultValue = null, required } = props;
  let isError = false;
  let errorMessage = '';
  if (!isEmpty(errors) && get(errors, name) !== undefined) {
    isError = true;
    errorMessage = get(errors, `${name}.message`, '') as string;
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      {...props}
      render={({ field: { ref, ...rest } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={csLocale}>
            <TimePicker
              disabled={get(props, 'disabled', false)}
              ampm={false}
              openTo="hours"
              views={['hours', 'minutes', 'seconds']}
              format="HH:mm:ss"
              label={label}
              // renderInput={(params) => (
              //   <TextField
              //     {...params}
              //     size="small"
              //     margin="dense"
              //     fullWidth={true}
              //     error={isError}
              //     helperText={errorMessage}
              //     InputLabelProps={{
              //       className: required ? 'required-label' : '',
              //       required: required || false,
              //     }}
              //   />
              // )}
              slotProps={{
                textField: {
                  size: 'small',
                  margin: 'dense',
                  fullWidth: true,
                  error: isError,
                  helperText: errorMessage,
                  required: required || false,
                  className: required ? 'required-label' : '',
                },
              }}
              {...rest}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};

export default FormTimePicker;
