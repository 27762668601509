import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  find,
  get,
  head,
  includes,
  isArray,
  isEqual,
  isNumber,
  keys,
  last,
  sortBy,
  values,
  without,
} from 'lodash';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip as ToolTipChart, Legend } from 'chart.js';
import {
  Button,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ViewerIcon from '@mui/icons-material/Image';

import { getTestResults } from './_api';

import { SmallButton } from 'components/Form/SmallButton/SmallButton';
import { Papeer } from 'components/Papeer/Papeer';
import Header from 'components/Header/Header';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useTests } from 'utils/hooks/useTests';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { green, red } from 'utils/variables';
import { useViewer } from 'utils/hooks/useViewer';

import { TEST_PASSED } from 'constants/constants';
import { Box } from '@mui/system';
import { getAllComments, getPreviewWithSize } from 'modules/Studies/StudyDetail/_api';
import { Img } from 'components/Image/Img';
import { useUser } from 'utils/hooks/useUser';

ChartJS.register(ArcElement, ToolTipChart, Legend);

const hashedId = true;
const previewWidth = 150;
const mainSx = { width: { xs: '100%', md: '50%', lg: '30%' } };

export const Results: React.FC = () => {
  const { t } = useTranslation('Tests');
  const { isStudyTestType, getQuestionID } = useTests(t);
  const { setPageTitle } = useWithTitle();
  const { hasRole } = useUser();
  const { onViewer } = useViewer();
  const location = useLocation();
  const linkBack = `/tests/${get(location, 'state.linkBack', 'results')}?action=back`;

  const { id } = useEntityInfo(hashedId);

  const [isFetchingResults, setIsFetchingResults] = useState<boolean>(true);
  const [title, setTitle] = useState<string>('');
  const [results, setResults] = useState<any>({});
  const [questionsCount, setQuestionsCount] = useState<number>(0);
  const [resultsIsStudyTestType, setResultsIsStudyTestType] = useState<boolean>(false);
  const [chartData, setChartData] = useState<any>(undefined);
  const [expandedPanels, setExpandedPanels] = useState<any[]>([]);
  const [previews, setPreviews] = useState<any>([]);
  const [previewsLoading, setPreviewsLoading] = useState<boolean>(false);

  const { toggleLoader } = useAppGlobals();

  const getCommentsForStudies = async (questions: any) => {
    if (!isArray(questions)) {
      return [];
    }

    try {
      return await Promise.all(
        questions.map((question) =>
          getAllComments({
            product: { id: question.study.archive.id },
            studyUid: question.study.studyInstanceUid,
          }),
        ),
      );
    } catch (e) {
      return [];
    }
  };

  const getEntity = async () => {
    toggleLoader();
    let results = await getTestResults(id);
    const testIsStudyType = isStudyTestType(get(results, 'test'));

    if (testIsStudyType) {
      results = {
        ...results,
        questionsAnswersResult: results.questionsAnswersResult.map((qa: any) => ({
          ...qa,
          study: JSON.parse(head(values(qa.question))),
        })),
      };

      if (hasRole('ROLE_VIEW_COMMENT_IN_MAMO_TEST_RESULT')) {
        const comments = await getCommentsForStudies(results.questionsAnswersResult);

        results = {
          ...results,
          questionsAnswersResult: results.questionsAnswersResult.map((qa: any, index: number) => ({
            ...qa,
            studyComment: get(last(comments[index]), 'text', ''),
          })),
        };
      }
    }

    const title = `${t('testResults')}: ${get(results, 'test.name')}`;
    setIsFetchingResults(false);
    setTitle(title);
    setResults(results);
    setQuestionsCount(get(results, 'questionsAnswersResult', []).length);
    setResultsIsStudyTestType(testIsStudyType);
    setPageTitle(title);
    const successRate = get(results, 'successRate');
    let chartData = undefined;
    if (isNumber(successRate)) {
      chartData = {
        labels: [t('wrongAnswer'), t('rightAnswer')],
        datasets: [
          {
            data: [(100 - successRate).toFixed(1), successRate.toFixed(1)],
            backgroundColor: [red, green],
            hoverOffset: 4,
          },
        ],
      };
    }
    setChartData(chartData);
    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNewPreviews = (newPreview: any) => {
    setPreviews((prevPreviews: any) => [...prevPreviews, newPreview]);
  };

  const loadPreview = async (archiveID: string, studyID: string, serieId: string) => {
    if (!find(previews, { uid: serieId })) {
      const img = await getPreviewWithSize(archiveID, studyID, serieId, undefined, previewWidth);
      setNewPreviews({ uid: serieId, img });
      return true;
    } else {
      return false;
    }
  };

  const handlePanelChange = (questionID: any) => {
    const expandedPanels2 = includes(expandedPanels, questionID)
      ? without(expandedPanels, questionID)
      : [...expandedPanels, questionID];
    setExpandedPanels(expandedPanels2);
  };

  const openAllResults = () => {
    const questions = get(results, 'questionsAnswersResult', []);
    const expandedPanels = isArray(questions)
      ? questions.map((QA) => getQuestionID(get(QA, 'question')))
      : [];
    setExpandedPanels(expandedPanels);
  };

  const openAllResults2 = async () => {
    openAllResults();
    const questions = get(results, 'questionsAnswersResult', []);
    if (get(results, 'test.allowPreview')) {
      setPreviewsLoading(true);
      await Promise.all(
        questions.map((question: any) => {
          const study = get(question, 'study');
          const archiveID = get(study, 'archive.id');
          const studyID = get(study, 'studyInstanceUid');
          const series = get(study, 'series', []);
          return loadPreview(archiveID, studyID, get(head(series), 'uid') || '');
        }),
      );
      setPreviewsLoading(false);
    }
  };

  const closeAllResults = () => setExpandedPanels([]);

  const allPanelsExpanded = useMemo(() => {
    return questionsCount === expandedPanels.length;
  }, [questionsCount, expandedPanels]);

  const onStudyViewer = (studyString: string) => {
    const study = JSON.parse(studyString);
    const patientId = get(study, 'patient.identificationNumber', '');
    const archiveID = get(study, 'archive.id');
    const studyID = get(study, 'studyInstanceUid');
    onViewer(studyID, patientId, archiveID);
  };

  const Chart = useMemo(() => {
    if (chartData) {
      return (
        <div>
          <Pie data={chartData} width={400} height={200} options={{ maintainAspectRatio: false }} />
        </div>
      );
    } else {
      return null;
    }
  }, [chartData]);

  return (
    <>
      {!isFetchingResults && (
        <>
          <Header title={title} backUrl={linkBack} />
          <Box>
            <Box
              sx={{
                display: 'inline-block',
                padding: 1,
                lineHeight: 1,
                borderRadius: 1,
                mb: 2,
                color: '#fff',
                background: get(results, 'testExecutionStatus') === TEST_PASSED ? green : red,
              }}
            >
              {t(get(results, 'testExecutionStatus') === TEST_PASSED ? 'passed' : 'failed')}
            </Box>
          </Box>
          <Papeer bottomMargin={true}>
            <Box sx={{ textAlign: 'center' }}>
              {t('successRate')}: {get(results, 'successRate')}%
            </Box>
            {Chart}
          </Papeer>
          <Header
            title={t('yourAnswers')}
            button={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  mb: 1,
                  mt: 1,
                }}
              >
                <SmallButton
                  variant="contained"
                  color="inherit"
                  onClick={allPanelsExpanded ? closeAllResults : openAllResults2}
                  sx={{ ml: 1 }}
                >
                  {allPanelsExpanded ? (
                    <ExpandLessIcon sx={{ width: 14, height: 14, mr: 1 }} />
                  ) : (
                    <ExpandMoreIcon sx={{ width: 14, height: 14, mr: 1 }} />
                  )}
                  {t(allPanelsExpanded ? 'closeAll' : 'openAll')}
                </SmallButton>
                {previewsLoading && (
                  <div style={{ marginLeft: '5px', fontSize: '0.875rem', fontWeight: 400 }}>
                    {t('loadingPreviews')} <CircularProgress size={20} />
                  </div>
                )}
              </Box>
            }
          />
          <Box>
            <div>
              {get(results, 'questionsAnswersResult', []).map((QA: any, index: any) => {
                const questionID = getQuestionID(get(QA, 'question'));
                const resultsAnswers = get(QA, 'answers', []);
                const resultsAnswersPositions = get(QA, 'answersPosition', []);
                const sortedResultAnswers = sortBy(
                  keys(resultsAnswers).map((answerID) => {
                    return {
                      answerID,
                      answer: get(resultsAnswers, answerID),
                      position: get(resultsAnswersPositions, answerID),
                    };
                  }),
                  ['position'],
                );

                const userAnswers = get(QA, 'userAnswers');
                const correctAnswers = get(QA, 'correctAnswers');

                const study = get(QA, 'study');
                const patientId = get(study, 'patient.identificationNumber', '');
                const archiveID = get(study, 'archive.id');
                const studyID = get(study, 'studyInstanceUid');
                const series = get(study, 'series', []);

                const seriesIUID = get(head(series), 'uid') || '';
                const preview = find(previews, { uid: seriesIUID });

                const isRightAnswers = isEqual(correctAnswers, userAnswers);
                return (
                  <Accordion
                    key={questionID}
                    expanded={includes(expandedPanels, questionID)}
                    onChange={() => {
                      handlePanelChange(questionID);
                      if (get(results, 'test.allowPreview')) {
                        loadPreview(archiveID, studyID, seriesIUID);
                      }
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box>
                        <Box
                          sx={{
                            display: 'inline-block',
                            p: 0.5,
                            pl: 1,
                            pr: 1,
                            mr: 2,
                            borderRadius: 1,
                            color: '#fff',
                            background: isRightAnswers ? green : red,
                          }}
                        >
                          {t(isRightAnswers ? 'rightAnswer' : 'wrongAnswer')}
                        </Box>
                      </Box>
                      <Box>
                        <Box component="span" sx={{ mr: 2 }}>
                          {index + 1}/{questionsCount}
                        </Box>
                        {resultsIsStudyTestType ? (
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={(event) => {
                              event.stopPropagation();
                              onStudyViewer(get(QA, `question[${questionID}]`));
                            }}
                          >
                            <ViewerIcon /> {t('showInBrowser')}
                          </Button>
                        ) : (
                          get(QA, `question[${questionID}]`)
                        )}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: {
                            xs: 'wrap',
                            lg: 'nowrap',
                          },
                          paddingRight: { xs: '0px', sm: 0 },
                        }}
                      >
                        <Box sx={mainSx}>
                          {sortedResultAnswers.map((answer) => {
                            const answerID = get(answer, 'answerID');
                            const iAnswerID = parseInt(answerID, 10);
                            const isCorrectAnswer = includes(correctAnswers, iAnswerID);
                            const answerIsCorrect =
                              (includes(correctAnswers, iAnswerID) &&
                                includes(userAnswers, iAnswerID)) ||
                              (!includes(correctAnswers, iAnswerID) &&
                                !includes(userAnswers, iAnswerID));
                            const userChoice = includes(userAnswers, iAnswerID);
                            return (
                              <Box key={answerID}>
                                <Tooltip
                                  key={answerID}
                                  placement="bottom-start"
                                  title={`${
                                    isCorrectAnswer ? t('correctAnswer') : t('incorrectanswer')
                                  } ${
                                    answerIsCorrect
                                      ? t('correctlyAnswered')
                                      : t('incorrectlyAnswered')
                                  }`}
                                >
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox disabled={true} checked={userChoice} />
                                    <Box
                                      component="span"
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        ...((answerIsCorrect && userChoice) || isCorrectAnswer
                                          ? {
                                              display: 'inline-block',
                                              p: 0.5,
                                              pl: 1,
                                              pr: 1,
                                              lineHeight: 1,
                                              borderRadius: 1,
                                              color: '#fff',
                                              background: green,
                                            }
                                          : {}),
                                        ...(isCorrectAnswer
                                          ? {
                                              fontWeight: 'bold',
                                              fontSize: '1rem',
                                            }
                                          : {}),
                                      }}
                                    >
                                      {get(answer, 'answer')}
                                    </Box>
                                    {answerIsCorrect && userChoice ? (
                                      <DoneIcon sx={{ ml: 2, color: green }} />
                                    ) : !answerIsCorrect && userChoice ? (
                                      <CancelIcon sx={{ ml: 2, color: red }} />
                                    ) : null}
                                  </Box>
                                </Tooltip>
                              </Box>
                            );
                          })}
                        </Box>
                        {resultsIsStudyTestType && get(results, 'test.allowPreview') && (
                          <Box sx={mainSx}>
                            <Img
                              patientId={patientId}
                              productId={archiveID}
                              studyIUID={studyID}
                              seriesIUID={seriesIUID}
                              topIndex={0}
                              width={previewWidth}
                              dialogPreview={false}
                              preview={preview}
                              setNewPreviews={setNewPreviews}
                              canLoadImgs={false}
                            />
                          </Box>
                        )}
                        {resultsIsStudyTestType &&
                        hasRole('ROLE_VIEW_COMMENT_IN_MAMO_TEST_RESULT') ? (
                          <Box sx={mainSx}>{get(QA, 'studyComment', '')}</Box>
                        ) : null}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </Box>
          {expandedPanels.length > 0 && (
            <Box component="p">
              {`* ${t('explanationCheckbox')}`}
              <br />
              {`* ${t('explanationColor')}`}
              <br />
              <DoneIcon fontSize="small" sx={{ color: green }} /> {` ${t('explanationDoneIcon')}`}
              <br />
              <CancelIcon fontSize="small" sx={{ color: red }} /> {` ${t('explanationCancelIcon')}`}
            </Box>
          )}
        </>
      )}
    </>
  );
};
