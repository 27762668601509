import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';

export const getAllAnnouncements = (): any =>
  api()
    .get(`/portal-api/welcome/getAnnouncements`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllButtons = (): any =>
  api()
    .get(`/portal-api/welcome/getButtons`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);
