import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { find, get, isArray, pick, sortBy, startsWith } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, CardHeader, Grid, Icon } from '@mui/material';
import { getAllAnnouncements, getAllButtons } from './_api';
import { IAnnouncement } from 'modules/Administration/Announcements/_types';
import { useUser } from 'utils/hooks/useUser';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useStorage } from 'utils/hooks/useStorage';
import { IButton } from 'modules/Administration/Buttons/_types';
import { useLanguage } from 'utils/hooks/useLanguage';
import {
  blueBackground,
  darkTypeCardHeader,
  darkTypeCardHover,
  darkTypeCardIcon,
} from 'utils/variables';
import { Announcement } from 'components/Announcement/Announcement';
import { useAppLoader } from 'utils/hooks/useAppLoader';
import { IFetchAnnouncement, IFetchButton } from './_types';
import { getTitle } from 'utils/getTitle';

export const Homepage: React.FC = () => {
  const { t } = useTranslation('Homepage');
  const navigate = useNavigate();
  const { currentLocale } = useLanguage();
  const { storeRedirectionToStore } = useAppLoader();

  const [buttons, fetchButtons] = useState<IFetchButton[]>([]);
  const [announcements, fetchAnnouncements] = useState<IFetchAnnouncement[]>([]);
  const { toggleLoader } = useAppGlobals();
  const { wasRedirected, compactMode } = useAppInfo();
  const { putItem } = useStorage();

  const { roles } = useUser();

  const redirectedToggle = () => storeRedirectionToStore(true);

  const openUrlFromButton = (button: any) => {
    const buttonRequestJson = get(button, 'requestJson', '{}');
    if (buttonRequestJson && buttonRequestJson !== '{}') {
      putItem('buttonRequestJson', buttonRequestJson);
    }
    navigate(button.url);
  };

  const loadEntities = async () => {
    toggleLoader();
    try {
      const [buttons, announcements] = await Promise.all([getAllButtons(), getAllAnnouncements()]);
      let isRedirected = false;
      if (isArray(buttons)) {
        if (!wasRedirected) {
          redirectedToggle();
          const automaticOpenedButton = find(buttons, (item) => item.automaticOpened);
          if (automaticOpenedButton) {
            const url = automaticOpenedButton.url;
            if (url) {
              if (startsWith(url, 'http')) {
                window.open(url, '_blank');
              } else {
                openUrlFromButton(automaticOpenedButton);
                isRedirected = true;
              }
            }
          }
        }
        if (!isRedirected) {
          // Filter buttons with URL and remap those
          const mappedButtons: IFetchButton[] = sortBy(buttons, ['position'])
            .filter((button: IButton) => button.url)
            .map((button: IButton) => ({
              ...pick(button, ['id', 'icon', 'url']),
              title: getTitle(button.languageJsonText, currentLocale),
              onClick: startsWith(button.url, 'http')
                ? () => window.open(button.url, '_blank')
                : () => openUrlFromButton(button),
            }));
          fetchButtons(mappedButtons);
        }
      }
      if (!isRedirected && isArray(announcements)) {
        const mappedAnnouncements = announcements
          .map((announcement: IAnnouncement) => ({
            ...pick(announcement, ['id']),
            type: announcement.importance ? announcement.importance.toLowerCase() : '',
            title: getTitle(announcement.languageJsonText, currentLocale),
          }))
          .filter((announcement) => announcement.title);
        fetchAnnouncements(mappedAnnouncements);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isArray(roles) || !roles.length ? (
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <Announcement label={t('noRoleAnnouncement')} type={'warning'} spaced={true} />
          </Grid>
        </Grid>
      ) : null}
      {announcements.length ? (
        <Box sx={{ mt: 1, mb: compactMode ? 2 : 8 }}>
          <Grid container={true} spacing={1}>
            {announcements.map((announcement: IFetchAnnouncement) => (
              <Grid item={true} xs={12} key={announcement.id}>
                <Announcement
                  label={get(announcement, 'title', '')}
                  type={announcement.type}
                  spaced={true}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : null}
      {buttons.length ? (
        <Box sx={!announcements.length ? { mt: 1 } : {}}>
          <Grid container={true} spacing={2}>
            {buttons.map((button: IFetchButton) => (
              <Grid item={true} xs={12} md={6} lg={4} xl={3} key={button.id}>
                <Card
                  sx={{
                    cursor: 'pointer',
                    transition: (theme: any) => theme.transitions.create(['background']),
                    '&:hover': {
                      background: (theme) =>
                        theme.palette.mode === 'dark' ? darkTypeCardHover : theme.palette.grey[100],
                    },
                  }}
                  onClick={button.onClick}
                >
                  <CardHeader
                    subheader={button.title}
                    sx={{
                      background: (theme) =>
                        theme.palette.mode === 'dark' ? darkTypeCardHeader : blueBackground,
                      padding: 1,
                    }}
                    subheaderTypographyProps={{
                      color: '#fff',
                    }}
                  />
                  <CardContent
                    sx={{
                      textAlign: 'center',
                      boxSizing: 'content-box',
                      pb: 1,
                    }}
                  >
                    {button.icon && (
                      <Icon
                        baseClassName="material-icons"
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === 'dark' ? darkTypeCardIcon : blueBackground,
                          fontSize: compactMode ? 32 : 48,
                        }}
                      >
                        {button.icon}
                      </Icon>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : null}
    </>
  );
};
