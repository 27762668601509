import { get, isArray, isEqual } from 'lodash';
import { Login } from 'modules/Login/Login';
import { PasswordRecovery } from 'modules/Login/PasswordRecovery';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { LoggedUser } from 'modules/LoggedUser/LoggedUser';
import { ISwitchForLogged } from './_types';

const PlainSwitch: React.FC<ISwitchForLogged> = ({
  user,
  roles,
  idleTimeToLogout,
  decodedToken,
}) => {
  return (
    <Routes>
      {!decodedToken || decodedToken.expired ? (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/passwordRecovery" element={<PasswordRecovery />} />
        </>
      ) : (
        <>
          {idleTimeToLogout && isArray(roles) && user.isAuthorized ? (
            <Route path="/*" element={<LoggedUser idleTimeToLogout={idleTimeToLogout} />} />
          ) : null}
        </>
      )}
    </Routes>
  );
};

const stateIsSameSwitch = (oldProps: ISwitchForLogged, newProps: ISwitchForLogged) => {
  return (
    get(oldProps, 'user.isAuthorized') === get(newProps, 'user.isAuthorized') &&
    get(oldProps, 'decodedToken.sub') === get(newProps, 'decodedToken.sub') &&
    get(oldProps, 'decodedToken.expired') === get(newProps, 'decodedToken.expired') &&
    isEqual(get(oldProps, 'roles', []), get(newProps, 'roles', [])) &&
    get(oldProps, 'idleTimeToLogout') === get(newProps, 'idleTimeToLogout')
  );
};

export const SwitchForLogged = React.memo(PlainSwitch, stateIsSameSwitch);
