import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { filter, get, isArray } from 'lodash';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { getAllDictionaries, removeDictionary } from './_api';
import Header from 'components/Header/Header';
import useAlerts from 'components/Alerts/useAlerts';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useLanguages } from 'utils/hooks/useLanguages';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const DictionariesItemDicitionary: React.FC<any> = ({ dictionary }) => {
  const { t } = useTranslation('Dictionaries');
  useWithTitle();
  const navigate = useNavigate();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();
  const { toggleLoader } = useAppGlobals();
  const { languages } = useLanguages();

  const muiGridKey = `${dictionary}ItemMUI`;

  const [rows, setRows] = useState<any[]>([]);

  const loadEntity = async () => {
    toggleLoader();
    setRows([]);
    try {
      const rows = await getAllDictionaries(dictionary);
      if (rows && isArray(rows)) {
        setRows(rows);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictionary]);

  const onCustomEntityDetail = (row: any) => {
    const editLink = `${get(row, 'id')}`;
    navigate(editLink);
  };

  const onEntityRemove = async (id: any) => {
    toggleLoader();
    const removed = await removeDictionary({ id, dictionary });
    if (removed) {
      loadEntity();
      addSuccessAlert(t('deleted'));
    } else {
      addErrorAlert(t('errorDeleting'));
    }
    toggleLoader(false);
  };

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 100,
        renderCell: ({ row }: GridRenderCellParams) => (
          <>
            <GridActionsCellItem
              icon={
                <Tooltip title={t('Grid:edit')}>
                  <EditIcon />
                </Tooltip>
              }
              label={t('Grid:edit')}
              onClick={() => onCustomEntityDetail(row)}
            />
            {dictionary !== 'studyDetailBookmarks' && (
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:delete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('Grid:delete')}
                onClick={() => {
                  dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }));
                }}
                disabled={
                  get(row, 'state', null) === 'NEW' ||
                  !get(row, 'canDelete', true) ||
                  get(row, 'system', false)
                }
              />
            )}
          </>
        ),
      },
      ...(dictionary === 'dose'
        ? [
            { field: 'dicomTag', headerName: t('dicomTag') },
            ...filter(languages, { active: true }).map((lang: any) => ({
              field: lang.abbreviation,
              headerName: lang.name,
              valueGetter: (value: any, row: any) =>
                get(JSON.parse(row.name), lang.abbreviation, ''),
            })),
            { field: 'unit', headerName: t('unit') },
          ]
        : dictionary === 'studyDetailBookmarks'
        ? [
            ...filter(languages, { active: true }).map((lang: any) => ({
              field: lang.abbreviation,
              headerName: lang.name,
              valueGetter: (value: any, row: any) =>
                get(JSON.parse(row.name), lang.abbreviation, ''),
            })),
            { field: 'order', headerName: t('order') },
            { field: 'type', headerName: t('type') },
          ]
        : dictionary === 'decubits'
        ? [
            {
              field: 'code',
              headerName: t('code'),
              valueGetter: (value: any, row: any) =>
                t(`decubitsCode.${get(row, 'code', '').toLowerCase()}`),
            },
            ...filter(languages, { active: true }).map((lang: any) => ({
              field: lang.abbreviation,
              headerName: lang.name,
              valueGetter: (value: any, row: any) =>
                get(JSON.parse(row.translateJson), lang.abbreviation, ''),
            })),
            {
              field: 'bodyPartExaminated',
              headerName: t('bodyPartExaminated'),
              valueGetter: (value: any, row: any) => get(row, 'bodyPartExaminated.dicomValue', ''),
            },
            {
              field: 'imageLaterality',
              headerName: t('imageLaterality'),
              valueGetter: (value: any, row: any) => get(row, 'imageLaterality.dicomValue', ''),
            },
            {
              field: 'patientPosition',
              headerName: t('patientPosition'),
              valueGetter: (value: any, row: any) => get(row, 'patientPosition.dicomValue', ''),
            },
          ]
        : [
            { field: 'code', headerName: t('code') },
            ...filter(languages, { active: true }).map((lang: any) => ({
              field: lang.abbreviation,
              headerName: lang.name,
              valueGetter: (value: any, row: any) =>
                get(JSON.parse(row.text), lang.abbreviation, ''),
            })),
            { field: 'value', headerName: t('value') },
          ]),
    ]),
  );

  return (
    <>
      <Header
        title={t(dictionary)}
        addUrl={`/administration/generalSettings/${dictionary}/create`}
        backUrl="/administration/generalSettings?tab=dictionaries"
      />
      <MuiGrid
        gridKey={muiGridKey}
        rows={rows}
        columns={columns}
        initialSortMode={[
          {
            field:
              dictionary === 'dose'
                ? 'dicomTag'
                : dictionary === 'studyDetailBookmarks'
                ? 'order'
                : dictionary === 'recipients'
                ? 'aet'
                : 'code',
            sort: 'asc',
          },
        ]}
      />
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmAction}
        />
      )}
    </>
  );
};

export default DictionariesItemDicitionary;
