import React from 'react';
import { useTranslation } from 'react-i18next';
import { IStudiesActionDialog } from './_types';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import { stateIsSame } from 'utils/componentOptimizatons';
import { Box, Button, DialogContentText } from '@mui/material';

const PlainStudiesActionDialog: React.FC<IStudiesActionDialog> = ({
  studiesActionDialog,
  setStudiesActionDialog,
  action,
  texts,
}) => {
  const { t } = useTranslation('Studies');

  const handleCloseDialog = () => setStudiesActionDialog(false);

  const handleConfirmDialog = () => {
    handleCloseDialog();
    action();
  };

  return (
    <OverflowedDialog
      title={texts.title}
      open={studiesActionDialog}
      onClose={handleCloseDialog}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth={true}
      withScrolling={false}
      actions={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '@media print': {
              display: 'none',
            },
          }}
        >
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            size="large"
            color="inherit"
            sx={{ mr: 1 }}
          >
            {t('close')}
          </Button>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={handleConfirmDialog}
            data-selenium-selector="edit-confirm-button"
          >
            {t('confirm')}
          </Button>
        </Box>
      }
    >
      <DialogContentText id="alert-dialog-description">{texts.dialogContentText}</DialogContentText>
    </OverflowedDialog>
  );
};

export const StudiesActionDialog = React.memo(PlainStudiesActionDialog, stateIsSame);
