import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import axios from 'axios';
import {
  ICreateRegisterGroupRequest,
  IRegisterGroup,
  IRegisterItem,
} from 'components/TagForm/_types';

export const getAllGroups = (active: any = null): Promise<IRegisterGroup[]> =>
  api()
    .get(`/portal-api/registerGroup/getAll`, { params: active === null ? {} : { active } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllItems = (active: any = null): Promise<IRegisterItem[]> =>
  api()
    .get(`/portal-api/registerGroup/getAllItems`, { params: active === null ? {} : { active } })
    .then((response) => {
      const data = get(response, 'data');
      const items = data.map((item: IRegisterItem) => {
        const value = item.id || '';
        const label = `${item.name} (${get(item, 'registerGroupName', '')})`;
        return { ...item, value, label };
      });
      return items;
    })
    .catch((error) => false);

export const getRegisterGroup = (id: string): Promise<IRegisterGroup> =>
  api()
    .get(`/portal-api/registerGroup/get`, { params: { id } })
    .then((response) => {
      let data = get(response, 'data');
      const languageJsonText = get(data, 'languageJsonText', null);
      if (languageJsonText) {
        data = { ...data, languageJsonText: JSON.parse(languageJsonText) };
      }
      return data;
    })
    .catch((error) => false);

export const editRegisterGroup = (registerGroup: IRegisterGroup): Promise<any | boolean> =>
  api().post(`/portal-api/registerGroup/edit`, registerGroup);

export const createRegisterGroup = (
  registerGroup: ICreateRegisterGroupRequest | IRegisterGroup,
): Promise<any | boolean> => api().post(`/portal-api/registerGroup/create`, registerGroup);

export const setActiveRegisterGroup = (id: string, active: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/registerGroup/setActiveState`, { params: { id, active } })
    .then((response) => true)
    .catch((error) => false);

export const setActiveRegisterItem = (id: string, active: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/registerGroup/registerItemSetActiveState`, { params: { id, active } })
    .then((response) => true)
    .catch((error) => false);

export const createRegisterItem = (registerItem: IRegisterItem): Promise<boolean> =>
  api()
    .post(`/portal-api/registerGroup/registerItemCreate`, registerItem)
    .then((response) => true)
    .catch((error) => false);

export const editRegisterItem = (registerItem: IRegisterItem): Promise<boolean> =>
  api()
    .post(`/portal-api/registerGroup/registerItemEdit`, registerItem)
    .then((response) => true)
    .catch((error) => false);

export const getRegisterItem = (id: string): Promise<IRegisterItem> =>
  api()
    .get(`/portal-api/registerGroup/registerItemGet`, { params: { id } })
    .then((response) => {
      let data = get(response, 'data');
      const languageJsonText = get(data, 'languageJsonText', null);
      if (languageJsonText) {
        data = { ...data, languageJsonText: JSON.parse(languageJsonText) };
      }
      return data;
    })
    .catch((error) => false);

//Scientific Archive
export const findStudiesFromScientificArchive = (searchData: any): Promise<boolean> =>
  api()
    .post(`/portal-api/study/findStudiesFromScientificArchive`, searchData, {})
    .then((response) => get(response, 'data'))
    .catch((thrown) => {
      if (axios.isCancel(thrown)) {
        console.log('Request cancelled: ', thrown.message);
      }
      return false;
    });

export const findStudiesInformationFromScientificArchive = (searchData: any): Promise<boolean> =>
  api()
    .post(`/portal-api/study/findStudiesInformationFromScientificArchive`, searchData, {})
    .then((response) => get(response, 'data'))
    .catch((thrown) => {
      if (axios.isCancel(thrown)) {
        console.log('Request cancelled: ', thrown.message);
      }
      return false;
    });

export const getStudiesByStudyIdentity = (searchData: any): Promise<boolean> =>
  api()
    .post(`/portal-api/study/getStudiesByStudyIdentity`, searchData, {})
    .then((response) => get(response, 'data'))
    .catch((thrown) => {
      if (axios.isCancel(thrown)) {
        console.log('Request cancelled: ', thrown.message);
      }
      return false;
    });
