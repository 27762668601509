import { get } from 'lodash';
import { useAppInfo } from 'utils/hooks/useAppInfo';

const localStorage = window.localStorage;
const sessionStorage = window.sessionStorage;

// These three functions are pulled from hook as they are needed outside React sometimes
export const getItem = (key: string) => localStorage.getItem(`portal.${key}`);
export const putItem = (key: string, value: string) => localStorage.setItem(`portal.${key}`, value);
export const removeItem = (key: string) => localStorage.removeItem(`portal.${key}`);

export const useStorage = () => {
  const { feConfig } = useAppInfo();
  const useSessionStorage = get(feConfig, 'jwtToken.useSessionStorage', false);

  const getItem = (key: string) => {
    if (key === 'token' && useSessionStorage) {
      const sessionToken = sessionStorage.getItem(`portal.${key}`);
      if (sessionToken === null || sessionToken === undefined || sessionToken === 'null') {
        try {
          const lastTimestamp = localStorage.getItem(`portal.lastTimestamp`);
          const currentTimeInMilliseconds = Date.now();
          const difference = (currentTimeInMilliseconds - Number(lastTimestamp)) / 1000;
          if (difference <= 10) {
            sessionStorage.putItem(key, localStorage.getItem(`portal.${key}`));
            return sessionStorage.getItem(`portal.${key}`);
          } else {
            sessionStorage.removeItem(`portal.${key}`);
            localStorage.removeItem(`portal.${key}`);
          }
        } catch (e) {
          console.error(e);
        }
      }
      return sessionToken;
    } else {
      return localStorage.getItem(`portal.${key}`);
    }
  };

  const putItem = (key: string, value: string) => {
    localStorage.setItem(`portal.${key}`, value);
    if (key === 'token' && useSessionStorage) {
      sessionStorage.setItem(`portal.${key}`, value);
    }
  };

  const removeItem = (key: string) => {
    localStorage.removeItem(`portal.${key}`);
    if (key === 'token' && useSessionStorage) {
      sessionStorage.removeItem(`portal.${key}`);
    }
  };

  const getCookie = (name: string) => {
    const value = '; ' + document.cookie;
    const parts: string[] = value.split('; ' + name + '=');
    if (parts && parts.length === 2) {
      return (parts.pop() as string).split(';').shift();
    }
    return null;
  };

  const setCookie = (name: string, value: string, days: number) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  };

  return {
    putItem,
    getItem,
    removeItem,
    clear: () => {
      localStorage.clear();
      sessionStorage.clear();
    },
    setCookie,
    getCookie,
  };
};
