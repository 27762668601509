import React from 'react';
import { useTranslation } from 'react-i18next';

import { IExpiratedDateButtons } from './_types';
import {
  EXPIRATION_BTN_ONE_DAY,
  EXPIRATION_BTN_ONE_MONTH,
  EXPIRATION_BTN_ONE_WEEK,
  EXPIRATION_BTN_THREE_WEEK,
  EXPIRATION_BTN_TWO_WEEK,
} from 'constants/constants';
import { CheckedButton } from 'components/Buttons/CheckedButton';

export const ExpiratedDateButtons: React.FC<IExpiratedDateButtons> = ({
  expiration,
  setExpiration,
}) => {
  const { t } = useTranslation('Folders');
  const buttons = [
    { id: EXPIRATION_BTN_ONE_DAY },
    { id: EXPIRATION_BTN_ONE_WEEK },
    { id: EXPIRATION_BTN_TWO_WEEK },
    { id: EXPIRATION_BTN_THREE_WEEK },
    { id: EXPIRATION_BTN_ONE_MONTH },
  ];
  const renderedButtons = buttons.map((button: any) => (
    <CheckedButton
      key={button.id}
      active={expiration === button.id}
      leftMargin={false}
      rightMargin={true}
      onClick={(e: any) => {
        setExpiration(expiration === button.id ? null : button.id);
      }}
      onDoubleClick={(e: any) => {
        setExpiration(expiration === button.id ? null : button.id);
      }}
      id={button.id}
    >
      {t(button.id)}
    </CheckedButton>
  ));

  return <div>{renderedButtons}</div>;
};
