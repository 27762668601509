import React from 'react';
import { useTranslation } from 'react-i18next';
import { isFunction } from 'lodash';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { IConfirmationDialog } from './_types';
import useAlerts from 'components/Alerts/useAlerts';
import { Box } from '@mui/system';
import Button from 'components/Buttons/Button';

const ConfirmationDialog: React.FC<IConfirmationDialog> = ({
  children,
  title,
  cancelAction,
  confirmAction,
  confirmCallback,
  actionCloseShow = true,
  actionConfirmShow = true,
  actionConfirmDisabled = false,
  actionCloseTitle,
  actionConfirmTitle,
  successMessage,
  errorMessage,
  dialogState,
  setDialogState,
  multipleAction,
  ...rest
}) => {
  const { t } = useTranslation('NotificationTemplates');
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const handleConfirmClick = async () => {
    const response = await confirmAction();
    if (response !== undefined) {
      if (response) {
        if (successMessage) {
          addSuccessAlert(successMessage);
        }
        if (isFunction(confirmCallback)) {
          confirmCallback();
        }
      } else {
        if (errorMessage) {
          addErrorAlert(errorMessage);
        }
      }
    }
  };

  return (
    <>
      <Dialog {...rest}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              displayPrint: 'none',
            }}
          >
            {actionCloseShow && (
              <Button onClick={cancelAction} variant="contained" color="inherit" sx={{ mr: '5px' }}>
                {actionCloseTitle ? actionCloseTitle : t('close')}
              </Button>
            )}
            {actionConfirmShow && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirmClick}
                disabled={actionConfirmDisabled}
              >
                {actionConfirmTitle ? actionConfirmTitle : t('confirm')}
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
