import { api } from 'utils/hooks/useApi';
import { get } from 'lodash';
import { IConnectedModality, IEchoConnectedModality } from './_types.';

export const getConnectedModalities = (id: number): any =>
  api()
    .get(`/portal-api/connectedModalities/getConnectedModalities`, { params: { productId: id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getDPGWUrl = (id: number): any =>
  api()
    .get(`/portal-api/connectedModalities/getLoginURLToDPGW`, { params: { productId: id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editConnectedModality = (
  connectedmodality: IConnectedModality,
): Promise<any | boolean> => api().post(`/portal-api/connectedModalities/edit`, connectedmodality);

export const deleteConnectedModalities = (id: string, aet: string): any =>
  api()
    .get(`/portal-api/connectedModalities/delete`, { params: { productId: id, aet } })
    .then((response) => true)
    .catch((error) => false);

export const echoConnectedModalities = (echoConnectedModality: IEchoConnectedModality): any =>
  api()
    .post(`/portal-api/connectedModalities/echo`, echoConnectedModality)
    .then((response) => true)
    .catch((error) => false);

export const createConnectedModality = (
  connectedmodality: IConnectedModality,
): Promise<any | boolean> =>
  api().post(`/portal-api/connectedModalities/create`, connectedmodality);
