import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';

const useValidationSchema = (t: TFunction) => {
  const { required, withoutDiacritics, hexaColor } = useValidators();

  const ModalityFormSchema = yup.object().shape({
    name: withoutDiacritics(required(yup.string())),
    color: hexaColor(required(yup.string())),
  });

  const formItems = [
    {
      name: 'name',
      label: t('name'),
      required: true,
    },
    {
      name: 'description',
      label: t('description'),
    },
    {
      name: 'color',
      label: t('color'),
      required: true,
    },
    {
      type: 'switch',
      name: 'show',
      label: t('show'),
    },
    {
      type: 'switch',
      name: 'multiframe',
      label: t('multiframe'),
    },
  ];

  return { ModalityFormSchema, formItems };
};

export default useValidationSchema;
