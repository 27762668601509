import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get, isArray } from 'lodash';
import { Box, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { editModality, getAllModalities, removeModality } from './_apiModalities';
import ModalityForm from './ModalityForm';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import Header from 'components/Header/Header';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'modalitiesMUI';

const Modalities: React.FC = () => {
  const { t } = useTranslation('Modalities');
  useWithTitle();
  const { toggleLoader } = useAppGlobals();
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();

  const [dialogState, setDialogState] = useState<boolean>(false);
  const [modalityId, setModalityId] = useState<number>(0);
  const [modalities, setModalities] = useState<any[]>([]);

  const loadEntity = async () => {
    toggleLoader();
    setModalityId(0);
    try {
      const modalities = await getAllModalities();
      if (modalities && isArray(modalities)) {
        setModalities(modalities);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { onEntityRemove } = useEntityRemove(removeModality, t, loadEntity);

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 220,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <Box sx={{ width: '200px' }}>
                <FormControlLabel
                  sx={{ maxHeight: 30 }}
                  control={
                    <Switch
                      checked={row.default}
                      onChange={(e, state) => {
                        dispatch(
                          storeConfirmationDataToStore({
                            title: 'Grid:confirmChangeDefaultModality',
                            id: row,
                            confirmAction: 'confirmActionChangeDefaultModality',
                          }),
                        );
                      }}
                    />
                  }
                  label={(row.default ? t('Grid:default') : '') as string}
                />
              </Box>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                label={t('Grid:edit')}
                onClick={() => onEntityEdit(get(row, 'id'))}
              />
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:delete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('Grid:delete')}
                onClick={() => {
                  dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }));
                }}
                disabled={
                  get(row, 'state', null) === 'NEW' ||
                  !get(row, 'canDelete', true) ||
                  get(row, 'system', false)
                }
              />
            </>
          );
        },
      },
      { field: 'name', headerName: t('name') },
      {
        field: 'color',
        headerName: t('color'),
        filterable: false,
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams) => {
          const value = get(row, 'color', '');
          return value ? (
            <Tooltip id="tooltip-left" title={value} placement="right">
              <Box sx={{ backgroundColor: value, width: '20px', height: '20px' }} />
            </Tooltip>
          ) : null;
        },
      },
      {
        field: 'show',
        headerName: t('show'),
        valueGetter: (value: any, row: any) => (get(row, 'show', false) ? t('yes') : t('no')),
      },
      {
        field: 'multiframe',
        headerName: t('multiframe'),
        valueGetter: (value: any, row: any) => (get(row, 'multiframe', false) ? t('yes') : t('no')),
      },

      { field: 'description', headerName: t('description') },
    ]),
  );

  const onChangeDefaultModality = async (row: any) => {
    toggleLoader();
    const changed = await editModality({ ...row, default: true });
    if (changed) {
      loadEntity();
    }
    toggleLoader(false);
  };

  const confirmActionChangeDefaultModality = () => {
    onChangeDefaultModality(get(confirmationData, 'id'));
    clearAction();
  };

  const toggleDialog = (state: boolean = false) => {
    setDialogState(state);
  };

  const onEntityEdit = async (id: number) => {
    setModalityId(id);
    toggleDialog(id > 0 ? true : false);
  };

  const addItemInDialog = () => {
    setModalityId(0);
    toggleDialog(true);
  };

  const handleClose = () => {
    toggleDialog(false);
    setModalityId(0);
  };

  const id = modalityId ? modalityId : dialogState ? 'create' : null;

  const renderedDialog = (
    <OverflowedDialog
      open={dialogState}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <ModalityForm toggleDialog={toggleDialog} id={id} loadEntity={loadEntity} />
    </OverflowedDialog>
  );

  return (
    <>
      <Header
        title=""
        addItemInDialog={addItemInDialog}
        toggleDialogText={t('add')}
        topMargin={true}
      />
      <MuiGrid
        gridKey={muiGridKey}
        rows={modalities}
        columns={columns}
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
      />
      {renderedDialog}
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={
            confirmationData.confirmAction === 'confirmActionChangeDefaultModality'
              ? confirmActionChangeDefaultModality
              : confirmAction
          }
        />
      )}
    </>
  );
};

export default Modalities;
