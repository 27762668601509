import HelperComponent from 'components/Tour/HelperComponent';
import { getDropDownStep } from 'components/Tour/_dropDownStep';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

export const TourImportNonDicom = ({ files = [] }: any) => {
  const { t } = useTranslation('Reactour');

  const initialSteps = ({ isDropDownOpen, currentOpenDropdown }: any) => {
    const steps = {
      '[data-tour="import-nonDicom-uppySelector"]': t('importNonDicom.uppySelector'),
      '[data-tour="import-nonDicom-findButtons"]': t('importNonDicom.findButtons'),
      '[data-tour="import-nonDicom-patientAndStudy"]': t('importNonDicom.patientAndStudy'),
      ...(currentOpenDropdown === 'patient.sex' && getDropDownStep({ isDropDownOpen, t })),
      ...(currentOpenDropdown === 'modality' &&
        getDropDownStep({ isDropDownOpen, t, selector: 'multiAutocomplete' })),
      '[data-tour="import-nonDicom-decubits"]': t('importNonDicom.decubits'),
      '[data-tour="import-nonDicom-targetArchive"]': t('importNonDicom.targetArchive'),
      ...(currentOpenDropdown === 'archiveId' &&
        getDropDownStep({ isDropDownOpen, t, content: 'importNonDicom.targetArchive' })),

      '[data-tour="import-nonDicom-resetButton"]': t('importNonDicom.resetButton'),
      '[data-tour="import-nonDicom-saveButton"]': t('importNonDicom.saveButton'),
    };
    return steps;
  };

  const extendedSteps = ({ isDropDownOpen }: any) => {
    const steps = {
      '[data-tour="import-nonDicom-grid"]': t('importNonDicom.grid'),
      '[data-tour="import-nonDicom-deleteRow"]': t('importNonDicom.deleteRow'),
      '[data-tour="import-nonDicom-deleteAll"]': t('importNonDicom.deleteAll'),
      '[data-tour="import-nonDicom-uploadFolder"]': t('importNonDicom.uploadFolder'),
      '[data-tour="import-nonDicom-uploadFiles"]': t('importNonDicom.uploadFiles'),
      '[data-tour="import-nonDicom-findButtons"]': t('importNonDicom.findButtons'),
      '[data-tour="import-nonDicom-patientAndStudy"]': t('importNonDicom.patientAndStudy'),
      '[data-tour="import-nonDicom-decubits"]': t('importNonDicom.decubits'),
      '[data-tour="import-nonDicom-targetArchive"]': t('importNonDicom.targetArchive'),
      ...(isDropDownOpen && {
        '.select-react-tour-class': t('importNonDicom.targetArchive'),
      }),
      '[data-tour="import-nonDicom-resetButton"]': t('importNonDicom.resetButton'),
      '[data-tour="import-nonDicom-saveButton"]': t('importNonDicom.saveButton'),
    };
    return steps;
  };

  const stepsDefinition = files?.length ? extendedSteps : initialSteps;
  useReactTour({ stepsDefinition });
  return <HelperComponent />;

  // const [tourSteps, setTourSteps] = useState<any>(files?.length ? extendedSteps : initialSteps);
  // useEffect(() => {
  //   setTourSteps(files?.length ? extendedSteps : initialSteps);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [files, isDropDownOpen]);

  // const steps = useStepsForReactourAdvanced({
  //   allSteps: tourSteps,
  //   isDropDownOpen,
  //   currentTourStep,
  //   setCurrentTourStep,
  // }).steps;

  // const tourControlProps = {
  //   isTourOpen,
  //   setIsTourOpen,
  //   setIsDropDownOpen,
  //   currentTourStep,
  //   setCurrentTourStep,
  // };

  // return {
  //   steps,
  //   tourControlProps,
  // };
};
