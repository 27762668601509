import HelperComponent from 'components/Tour/HelperComponent';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

export const TourImportDicom = ({ patients = [] }: any) => {
  const { t } = useTranslation('Reactour');
  let stepsDefinition: any;

  const initialSteps = ({ isDropDownOpen }: any) => {
    const steps = {
      '[data-tour="import-dicom-uppySelector"]': t('importDicom.uppySelector'),
      '[data-tour="import-dicom-targetArchive"]': t('importDicom.targetArchive'),
      ...(isDropDownOpen && {
        '.select-react-tour-class': t('importDicom.targetArchive'),
      }),
      '[data-tour="import-dicom-saveButton"]': t('importDicom.saveButton'),
    };
    return steps;
  };

  const extendedSteps = ({ isDropDownOpen }: any) => {
    const steps = {
      '[data-tour="import-dicom-grid"]': t('importDicom.grid'),
      '[data-tour="import-dicom-expansionArrow"]': t('importDicom.expansionArrow'),
      '[data-tour="import-dicom-editRow"]': t('importDicom.editRow'),
      '[data-tour="import-dicom-deleteRow"]': t('importDicom.deleteRow'),
      '[data-tour="import-dicom-deleteAll"]': t('importDicom.deleteAll'),
      '[data-tour="import-dicom-uploadFolder"]': t('importDicom.uploadFolder'),
      '[data-tour="import-dicom-uploadFiles"]': t('importDicom.uploadFiles'),
      '[data-tour="import-dicom-targetArchive"]': t('importDicom.targetArchive'),
      ...(isDropDownOpen && {
        '.select-react-tour-class': t('importDicom.targetArchive'),
      }),
      '[data-tour="import-dicom-saveButton"]': t('importDicom.saveButton'),
    };
    return steps;
  };

  stepsDefinition = patients?.length ? extendedSteps : initialSteps;

  useReactTour({ stepsDefinition });

  return <HelperComponent />;
};
