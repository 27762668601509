import HelperComponent from 'components/Tour/HelperComponent';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

interface IUseStepsProps {
  type: 'main' | 'statusDialog';
  showTimelineStep?: boolean;
}

export const TourStudyDetail = ({ type, showTimelineStep }: IUseStepsProps) => {
  const { t } = useTranslation('Reactour');

  const allStepsMain = () => {
    const steps = {
      '[data-tour="study-detail-headerSection"]': t('studyDetail.headerSection'),
      ...(showTimelineStep && {
        '[data-tour="study-detail-timeline"]': t('studyDetail.timeline'),
      }),
      '[data-tour="study-detail-mainSection"]': t('studyDetail.mainSection'),
      '[data-tour="study-detail-mainSection-preview"]': t('studyDetail.mainSection_preview'),
      '[data-tour="study-detail-buttons"]': t('studyDetail.buttons'),
      '[data-tour="study-detail-tabs"]': t('studyDetail.tabs'),
    };
    return steps;
  };

  const allStepsStatusDialog = () => {
    const steps = {
      '[data-tour="study-detail-status-dialog-choices"]': t('studyDetail.statusDialog.choices'),
      '[data-tour="selectAll"]': t('studyDetail.statusDialog.selectAll'),
      '[data-tour="saveButton"]': t('studyDetail.statusDialog.saveButton'),
      '[data-tour="closeButton"]': t('studyDetail.statusDialog.closeButton'),
    };
    return steps;
  };

  let stepsDefinition;
  if (type === 'main') stepsDefinition = allStepsMain;
  if (type === 'statusDialog') stepsDefinition = allStepsStatusDialog;

  useReactTour({ stepsDefinition });

  return <HelperComponent />;
};
