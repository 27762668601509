import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { AppBar, Tab, Tabs } from '@mui/material';

import { Announcements } from '../Announcements/Announcements';
import { Buttons } from '../Buttons/Buttons';

import { useUser } from 'utils/hooks/useUser';

export const AnnouncementsAndButtons: React.FC = () => {
  const { t } = useTranslation('AnnouncementsAndButtons');
  const { hasRole } = useUser();

  let [searchParams] = useSearchParams();
  const tabFromUrl = searchParams.get('tab') || '';
  const tab = tabFromUrl
    ? isArray(tabFromUrl)
      ? tabFromUrl[0]
      : tabFromUrl
    : hasRole('ROLE_BUTTONS')
    ? 'buttons'
    : hasRole('ROLE_ANNOUNCEMENT')
    ? 'announcements'
    : '';

  const [activeTab, setActiveTab] = useState<string>(tab);

  return (
    <>
      <AppBar position="static" color="default" sx={{ mt: 2, mb: 2 }}>
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons={false}
        >
          {hasRole('ROLE_BUTTONS') && <Tab value="buttons" label={t('setButtons')} />}
          {hasRole('ROLE_ANNOUNCEMENT') && (
            <Tab value="announcements" label={t('setAnnouncements')} />
          )}
        </Tabs>
      </AppBar>

      {activeTab === 'announcements' && <Announcements />}
      {activeTab === 'buttons' && <Buttons />}
    </>
  );
};
