import React, { useRef, useMemo, useCallback } from 'react';
import { Button, Grid, Box } from '@mui/material';
import { get, includes } from 'lodash';
import Webcam from 'react-webcam';

import { useLanguage } from 'utils/hooks/useLanguage';
import { useLanguages } from 'utils/hooks/useLanguages';
import FormSelect from 'components/Form/Select/Select';

import styles from './_styles';

const WebcamCapture: React.FC<any> = ({ setBase64Image, webcamConfig, setValue }: any) => {
  const webcamRef = useRef<Webcam>(null);
  const { currentLocale } = useLanguage();
  const { languages } = useLanguages();

  const prepareDicomTags = useCallback(() => {
    const buttons = get(webcamConfig, 'buttons', []).map((button: any) => {
      const buttonName = get(button, `name`, '');
      const name = get(button, `value.${currentLocale}`, get(button, 'name', '?'));
      let degree = 0;
      const { rotate, rotationDegree, rotationDirection } = get(button, 'functionality', {});
      if (rotate) {
        degree =
          rotationDirection === 'left'
            ? 360 - rotationDegree
            : rotationDirection === 'right'
            ? rotationDegree
            : 0;
      }
      return { name, buttonName, degree };
    });

    const additionalDicomAttributes: any = [];
    const dicomTags = get(webcamConfig, 'importDicomTags', []).map((tag: any) => {
      const vr = get(tag, `vr`, '');
      const dicomTag = get(tag, `dicomTag`, '');
      additionalDicomAttributes.push({ tag: dicomTag, vr });
      let name = get(tag, `name.${currentLocale}`, '');
      if (!name) {
        (languages || []).forEach((lang: any) => {
          const otherName = get(tag, `name.${lang.abbreviation}`, '');
          if (otherName) {
            name = otherName;
          }
        });
      }
      const items = get(tag, 'values', []).map((item: any) => {
        const id = get(item, 'dicom_value', '');
        let label = get(item, `translate_json.${currentLocale}`, '');
        if (!label) {
          (languages || []).forEach((lang: any) => {
            const otherLabel = get(item, `translate_json.${lang.abbreviation}`, '');
            if (otherLabel) {
              label = otherLabel;
            }
          });
        }
        const defaultForButtonName = get(item, 'defaultForButtonName', '');
        return { id, label: `${label} (${id})`, defaultForButtonName };
      });
      return { name: dicomTag, label: name, items };
    });
    // console.log('additionalDicomAttributes', additionalDicomAttributes);
    // setValue('additionalDicomAttributes', additionalDicomAttributes);
    return { buttons, dicomTags };
  }, [webcamConfig, languages, currentLocale /*, setValue*/]);

  const { buttons, dicomTags }: any = useMemo(() => prepareDicomTags(), [prepareDicomTags]);

  const capture = (degree: number, buttonName: string) => {
    let degrees = degree;
    if (degrees > 360) {
      degrees = degrees % 360;
    }
    dicomTags.forEach((tag: any) => {
      const name = get(tag, `name`, '');
      const items = get(tag, `items`, '');
      let defaultValue = '';
      items.forEach((item: any) => {
        if (includes(get(item, 'defaultForButtonName', []), buttonName)) {
          defaultValue = item.id;
        }
      });
      setValue(`${name}`, defaultValue);
    });

    const imageSrc = webcamRef.current?.getScreenshot();

    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    if (canvas && canvas.getContext) {
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
      const image = new Image();

      image.onload = () => {
        canvas.width =
          degrees === 90 || degrees === 270
            ? image.height
            : degrees === 0 || degrees === 180
            ? image.width
            : 1.2 * (image.width > image.height ? image.width : image.height);
        canvas.height =
          degrees === 90 || degrees === 270
            ? image.width
            : degrees === 0 || degrees === 180
            ? image.height
            : 1.2 * (image.width > image.height ? image.width : image.height);
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (degrees > 0) {
          ctx.rotate((degrees * Math.PI) / 180);
        }

        ctx.drawImage(
          image,
          degrees === 0 || degrees === 90
            ? 0
            : degrees === 180 || degrees === 270
            ? -1 * image.width
            : degrees < 50
            ? (1 * canvas.width) / 5
            : degrees < 90
            ? (1 * canvas.width) / 6
            : degrees < 140
            ? (-1 * canvas.width) / 5
            : degrees < 180
            ? (-1 * canvas.width) / 1.5
            : degrees < 230
            ? (-1 * canvas.width) / 0.9
            : degrees < 270
            ? (-1 * canvas.width) / 1.1
            : degrees < 330
            ? (-1 * canvas.width) / 1.8
            : degrees < 360
            ? (-1 * canvas.width) / 5.4
            : 0,
          degrees === 0 || degrees === 270
            ? 0
            : degrees === 90 || degrees === 180
            ? -1 * image.height
            : degrees < 50
            ? (-1 * canvas.width) / 5
            : degrees < 90
            ? (-1 * canvas.width) / 1.3
            : degrees < 140
            ? (-1 * canvas.width) / 0.9
            : degrees < 180
            ? (-1 * canvas.width) / 0.9
            : degrees < 230
            ? (-1 * canvas.width) / 1.4
            : degrees < 270
            ? (-1 * canvas.width) / 2.9
            : degrees < 360
            ? (0.2 * canvas.width) / 1.4
            : 0,
        );
        ctx.save();
        const fullQuality = canvas.toDataURL('image/jpeg', 1.0);
        setBase64Image(fullQuality);
      };
      if (imageSrc) {
        image.src = imageSrc;
      }
    }
  };

  // const View: React.SFC<any> = ({ buttons, dicomTags, webcamRef, capture, classes }) => (
  return (
    <>
      <div>
        <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
      </div>
      {buttons && (
        <div>
          {buttons.map((button: any) => (
            <Button
              key={button.buttonName}
              size="small"
              color="primary"
              variant="contained"
              sx={styles.toolbarButton}
              onClick={() => capture(button.degree, button.buttonName)}
            >
              {button.name}
            </Button>
          ))}
        </div>
      )}
      <Box sx={styles.dicomTagSelect}>
        <canvas id="canvas" />
      </Box>
      {/*console.log('dicomTags', dicomTags)*/}
      {dicomTags && (
        <Box sx={styles.dicomTagSelect}>
          <Grid container={true} spacing={8}>
            {dicomTags.map((field: any, i: number) => (
              <Grid item={true} xs={12} md={4} key={`${field.name}_${i}`}>
                <>
                  <FormSelect {...field} name={`${field.name}`} />.
                </>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default WebcamCapture;
