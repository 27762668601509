import { setGridSettings } from 'components/SearchResults/_api';
import { pick } from 'lodash';
import { useAppDispatch } from 'store/hooks';
import { setGridSettingsToStore, setLocalGridSettingsToStore } from 'store/reducers/appReducer';

export const useGrid = () => {
  const dispatch = useAppDispatch();

  const setGridSettingsAndStore = async (settings: {
    key: string;
    gridSettingsForAPI: any;
    gridSettingsForRedux: any;
  }) => {
    dispatch(setGridSettingsToStore(settings.gridSettingsForRedux));
    await setGridSettings(pick(settings, ['key', 'gridSettingsForAPI']));
  };

  const setLocalGridSettingsAndStore = (name: any, data: any, reset = false) => {
    dispatch(setLocalGridSettingsToStore({ name, data, reset }));
  };

  return {
    setGridSettingsAndStore,
    setLocalGridSettingsAndStore,
  };
};
