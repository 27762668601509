import { api } from 'utils/hooks/useApi';
import { get, isArray } from 'lodash';
import { IRole, IWorkplace } from './_types';
import { SET_LISTS } from 'constants/constants';

export const getAllWorkplaces = (activeTab: string = 'db'): Promise<IWorkplace[] | boolean> =>
  api()
    .get(`/portal-api/workPlace/getAll?allowedAuthMethodId=${activeTab}`)
    .then((response) => get(response, 'data') || false)
    .catch((error) => false);

export const findAllWorkplaces = (
  code: string,
  activeTab: string = 'db',
): Promise<IWorkplace[] | boolean> =>
  api()
    .post(`/portal-api/workPlace/findAll?allowedAuthMethodId=${activeTab}`, { code })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllRoles = (): Promise<IRole[] | boolean> =>
  api()
    .get(`/portal-api/workPlace/getAllRoles`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllRolesBySections = (): Promise<IRole[] | boolean> =>
  api()
    .get(`/portal-api/workPlace/getAllRolesBySections`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllRolesAndStore = () => async (dispatch: any) => {
  const roles = await getAllRoles();
  if (isArray(roles)) {
    dispatch({
      payload: { roles },
      type: SET_LISTS,
    });
  }
};

export const getWorkplace = (
  code: string,
  activeTab: string = 'db',
): Promise<IWorkplace | boolean> =>
  api()
    .get(`/portal-api/workPlace/get`, { params: { code, allowedAuthMethodId: activeTab } })
    .then((response) => {
      let data = get(response, 'data');
      const text = get(data, 'text', null);
      if (text) {
        data = { ...data, text: JSON.parse(text) };
      }
      return data;
    })
    .catch((error) => false);

export const editWorkplace = (workplace: IWorkplace, activeTab: string = 'db'): Promise<boolean> =>
  api()
    .post(`/portal-api/workPlace/edit?allowedAuthMethodId=${activeTab}`, workplace)
    .then((response) => true)
    .catch((error) => false);

export const createWorkplace = (
  workplace: IWorkplace,
  activeTab: string = 'db',
): Promise<boolean> =>
  api()
    .post(`/portal-api/workPlace/create?allowedAuthMethodId=${activeTab}`, workplace)
    .then((response) => true)
    .catch((error) => false);

export const createMultipleWorkplaces = (
  workplaces: IWorkplace[],
  activeTab: string = 'db',
): Promise<boolean> =>
  api()
    .post(`/portal-api/workPlace/createMultiple`, workplaces)
    .then((response) => true)
    .catch((error) => false);

export const removeWorkplace = (code: string, activeTab: string = 'db'): Promise<boolean> =>
  api()
    .get(`/portal-api/workPlace/remove?allowedAuthMethodId=${activeTab}`, { params: { code } })
    .then((response) => true)
    .catch((error) => false);

export const getWorkplacesFromLDAP = (): Promise<any> =>
  api()
    .get(`/portal-api/workPlace/getWorkplacesFromLDAP`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const createWorkplacesByLDAP = (data: any): Promise<boolean> =>
  api()
    .post(`/portal-api/workPlace/createWorkplacesByLDAP`, data)
    .then((response) => true)
    .catch((error) => false);

export const setWorkPlaceFavouriteFacility = (
  facilityId: string,
  favourite: boolean,
  workPlaceId: number,
): Promise<any> =>
  api()
    .get(`/portal-api/workPlace/setWorkPlaceFavouriteFacility`, {
      params: { facilityId, favourite, workPlaceId },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const setAllowedFunctionsForArchive = (data: any): Promise<boolean> =>
  api()
    .post(`/portal-api/workPlace/setAllowedFunctionsForArchive?`, data)
    .then((response) => true)
    .catch((error) => false);
