import { TEST_STATE_DRAFT, TEST_TYPE_GENERAL, TEST_TYPE_STUDY } from 'constants/constants';
import { TFunction } from 'i18next';
import { get, head, isArray, keys, lowerCase } from 'lodash';
import { ITest } from 'modules/Tests/_types';

export const useTests = (t: TFunction) => {
  const nullabelOption = [{ id: null, label: t('all') }];

  const mapTestTypesToSelectField = (testTypes: string[], prependNullableOption: boolean = false) =>
    isArray(testTypes)
      ? [
          ...(prependNullableOption ? nullabelOption : []),
          ...testTypes.map((type) => ({ id: type, label: t(lowerCase(type)) })),
        ]
      : [];

  const mapTestStatesToSelectField = (
    testStates: string[],
    prependNullableOption: boolean = false,
  ) =>
    isArray(testStates)
      ? [
          ...(prependNullableOption ? nullabelOption : []),
          ...testStates.map((state) => ({ id: state, label: t(lowerCase(state)) })),
        ]
      : [];

  const testIsDraft = (test: ITest) => get(test, 'state') === TEST_STATE_DRAFT;
  const testIsReady = (test: ITest) => !testIsDraft(test);

  const isGeneralTestType = (test: ITest) => get(test, 'type') === TEST_TYPE_GENERAL;
  const isStudyTestType = (test: ITest) => get(test, 'type') === TEST_TYPE_STUDY;

  const padNumber = (givenNumber: number) => (givenNumber < 10 ? `0${givenNumber}` : givenNumber);

  const parseStudyQuestion = (question: any) => ({
    ...question,
    study: JSON.parse(question.study),
  });

  const getQuestionID = (question: any) => head(keys(question));

  const triggerSubmit = () => {
    const submitButton: HTMLElement = document.querySelector('.search-form-button') as HTMLElement;
    submitButton.focus();
    submitButton.click();
  };

  const parseStudyQuestions = (questions: any) => {
    const mapedQuestions = questions.map((item: any) => ({
      ...item,
      study: JSON.parse(item.question.study),
    }));
    return mapedQuestions;
  };

  return {
    mapTestTypesToSelectField,
    mapTestStatesToSelectField,
    testIsDraft,
    testIsReady,
    isGeneralTestType,
    isStudyTestType,
    padNumber,
    parseStudyQuestion,
    getQuestionID,
    triggerSubmit,
    parseStudyQuestions,
  };
};
