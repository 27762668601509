import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { IFindingReport, IFindingReportSearch } from './_types';

export const getFormData = (): Promise<any> =>
  api()
    .get(`/portal-api/reports/getDataForForm`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getChartData = (settings: any): Promise<any> =>
  api()
    .post(`/portal-api/reports/getChartData`, settings)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getChartDataset = (settings: any): Promise<any> | boolean =>
  api()
    .post(`/portal-api/reports/getChartDataset`, settings)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getStudiesByTagsStatistics = (): Promise<any> =>
  api()
    .get(`/portal-api/reports/getStudiesByTagsStatistics`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getFindingReport = (
  values: IFindingReportSearch,
): Promise<IFindingReport[] | boolean> =>
  api()
    .post(`/portal-api/reports/getFindingReport`, { ...values })
    .then((response) => get(response, 'data'))
    .catch((error) => false);
