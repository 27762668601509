import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, join, trim } from 'lodash';
import { format } from 'date-fns';
import { Box, Tooltip } from '@mui/material';
import { Description as GridDetailIcon, NotInterested as BanIcon } from '@mui/icons-material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { cancelRequest } from './_api';
import useAlerts from 'components/Alerts/useAlerts';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { findTranslation } from 'utils/grid/translate';
import { encryptId } from 'utils/hooks/useApp';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import useRefetchGrid from 'utils/grid/useRefetchGrid';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { DETAIL_QUEUE } from 'constants/constants';
import styles from 'components/SearchResultsMUI/_styles';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const dateTime = 'dd.MM.yyyy HH:mm';
const muiGridKey = 'queueRequestsMUI';

const QueueSearchResults: React.FC<any> = () => {
  const { t } = useTranslation('Queues');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { refetchGrid } = useRefetchGrid();
  const { confirmationData, queueResults, compactMode } = useAppInfo();
  const { currentLocale } = useLanguage();
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 220,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:queueDetail')}>
                    <GridDetailIcon />
                  </Tooltip>
                }
                label={t('Grid:queueDetail')}
                onClick={() => onCustomEntityDetail(row)}
                disabled={!get(row, 'canShowDetail', false)}
                sx={compactMode ? { p: 0 } : {}}
              />
              {get(row, 'canCancelRequest', false) && (
                <GridActionsCellItem
                  icon={
                    <Tooltip title={t('Grid:queueCancel')}>
                      <BanIcon />
                    </Tooltip>
                  }
                  label={t('Grid:queueCancel')}
                  onClick={() => {
                    dispatch(
                      storeConfirmationDataToStore({
                        id: get(row, 'id'),
                        title: 'confirmQueueCancel',
                        confirmAction: 'confirmActionCancelQueue',
                      }),
                    );
                  }}
                  disabled={!get(row, 'canCancelRequest', false)}
                  sx={compactMode ? { p: 0 } : {}}
                />
              )}
            </>
          );
        },
      },
      {
        field: 'dateTime',
        headerName: t('dateTime'),
        valueGetter: (value: any, row: any) => {
          const dateTime = get(row, 'dateTime', null);
          return dateTime && new Date(dateTime);
        },
        type: 'dateTime',
      },
      { field: 'username', headerName: t('username') },
      {
        field: 'customOperation',
        headerName: t('operation'),
        renderCell: ({ row }: GridRenderCellParams) => get(row, 'operation'),
        valueGetter: (value: any, row: any) => {
          const operation = get(row, 'operation');
          const children = get(operation, 'props.children');
          return children || operation;
        },
      },
      {
        field: 'state',
        headerName: t('state'),
        valueGetter: (value: any, row: any) =>
          findTranslation(currentLocale, 'Queues', get(row, 'state', '').toLowerCase()),
      },
      {
        field: 'patientNames',
        headerName: t('patientName'),
        renderCell: ({ row }: GridRenderCellParams) => {
          const patientName = get(row, 'patientNames', []).map((item: any, index: any) => (
            <li key={`patient_names_${index}`}>
              <Tooltip title={t('Grid:studyDetail')}>
                <Box onClick={() => onCustomEntityStudy(item)} sx={styles.gridLink}>
                  {item.name}
                </Box>
              </Tooltip>
            </li>
          ));
          return patientName ? (
            <Box component="ul" sx={{ listStyle: 'none', p: '0', my: 0.25 }}>
              {patientName}
            </Box>
          ) : (
            ''
          );
        },
        valueGetter: (value: any, row: any) => {
          const patientNames = get(row, 'patientNames', []).map((patientName: any) => {
            return get(patientName, 'name');
          });
          return join(patientNames, ' ');
        },
      },
      {
        field: 'studyDateTime',
        headerName: t('studyDateTime'),
        renderCell: ({ row }: GridRenderCellParams) => {
          const studyDateTime = get(row, 'studies', []).map((study: any, index: number) => {
            return (
              <li key={`study_date_time_${index}`}>
                {get(study, 'dateTime', null)
                  ? format(new Date(get(study, 'dateTime')), dateTime)
                  : ''}
              </li>
            );
          });
          return studyDateTime ? (
            <Box component="ul" sx={{ listStyle: 'none', p: '0', my: 0.25 }}>
              {studyDateTime}
            </Box>
          ) : (
            ''
          );
        },
        valueGetter: (value: any, row: any) => {
          const studyDateTime = get(row, 'studies', []).map((item: any) => {
            const dateTime = get(item, 'dateTime', '');
            return dateTime ? format(new Date(dateTime), "dd.MM.yyyy'T'HH:mm:ss") : '';
          });
          return trim(join(studyDateTime, ' '));
        },
      },
      {
        field: 'accessionNumber',
        headerName: t('accessionNumber'),
        renderCell: ({ row }: GridRenderCellParams) => {
          const accessionNumber = get(row, 'studies', []).map((item: any, i: number) => {
            return <li key={`accession_number_${i}`}>{get(item, 'accessionNumber', '') || ''}</li>;
            // <Box component="ul" sx={{ listStyle: 'none', p: '0', my: 0.25 }}>
          });
          return accessionNumber ? (
            <Box component="ul" sx={{ listStyle: 'none', p: '0', my: 0.25 }}>
              {accessionNumber}
            </Box>
          ) : (
            ''
          );
        },
        valueGetter: (value: any, row: any) => {
          const accessionNumber = get(row, 'studies', []).map((item: any) => {
            return get(item, 'accessionNumber', '');
          });
          return trim(join(accessionNumber, ' '));
        },
      },
      { field: 'requestId', headerName: t('requestId'), type: 'number' },
    ]),
  );

  const onCancelQueue = async (id: any) => {
    toggleLoader(false);
    const removed = await cancelRequest(id);
    if (removed) {
      refetchGrid();
      addSuccessAlert(t('succesCancelQueue'));
    } else {
      addErrorAlert(t('errorCancelQueue'));
    }
    toggleLoader(false);
  };

  const onCustomEntityDetail = (row: any) => {
    navigate(`/queueRequests/${encodeURIComponent(get(row, 'requestId', ''))}`, {
      state: { linkBack: DETAIL_QUEUE },
    });
  };

  const onCustomEntityStudy = (item: any) => {
    navigate(`/study/${encryptId(item.iid)}?from=queue`);
  };

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };
  const confirmActionCancelQueue = async () => {
    await onCancelQueue(get(confirmationData, 'id'));
    clearAction();
  };

  return (
    <>
      <MuiGrid
        gridKey={muiGridKey}
        rows={queueResults}
        columns={columns}
        rowHeight={true}
        dataTour="queueRequestGrid"
        initialSortMode={[{ field: 'dateTime', sort: 'desc' }]}
      />
      {confirmationData && confirmationData.id && (
        <ConfirmationDialog
          title={t(confirmationData.title)}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmActionCancelQueue}
        />
      )}
    </>
  );
};

export default QueueSearchResults;
