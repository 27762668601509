import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getStudiesByTagsStatistics } from './_api';
import Header from 'components/Header/Header';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourReports } from './TourReports';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'studiesByStudyTagsStatisticsMUI';

export const StudiesByStudyTagsStatistics: React.FC = () => {
  const { t } = useTranslation('Reports');
  const { title } = useWithTitle();
  const { toggleLoader } = useAppGlobals();
  useWithTitle(); // sets title to document

  const [data, setData] = useState<any[]>([]);

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const loadEntity = async () => {
    toggleLoader();
    const resp = await getStudiesByTagsStatistics();
    if (resp) {
      setData(
        Object.keys(resp.data).map((key, index) => ({
          id: index,
          name: key,
          count: resp.data[key],
        })),
      );
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      [
        { field: 'name', headerName: t('tagName') },
        { field: 'count', headerName: t('tagCount'), type: 'number' },
      ],
      250,
    ),
  );

  const renderedSteps = () => {
    return <TourReports type="byTagStatistics" />;
  };

  return (
    <>
      <Header title={title} TourComponent={renderedSteps()} />
      <MuiGrid
        gridKey={muiGridKey}
        rows={data}
        columns={columns}
        dataTour="statisticsByTagStatistics"
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
      />
    </>
  );
};
