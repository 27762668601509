import React from 'react';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ExitToApp,
  Person,
  Menu as MenuIcon,
  DensitySmall,
  DensityMedium,
} from '@mui/icons-material';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import BasketIcon from '@mui/icons-material/FolderSpecial';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  Switch,
  Stack,
  Badge,
  ListItemIcon,
  ListItemText,
  Menu,
  Theme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { IBar } from './_types';
import { useUser } from 'utils/hooks/useUser';
import { logout } from 'modules/Login/_api';
import { useStorage } from 'utils/hooks/useStorage';
import { useAppLoader } from 'utils/hooks/useAppLoader';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { stateIsSame } from 'utils/componentOptimizatons';
// import { editUserAndUserSettings } from 'modules/Administration/Users/_api';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { setDarkMode, saveDisplayMode } from './_api';
import { useToken } from 'utils/hooks/useToken';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { isEmpty } from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/styles';
import { drawerWidth, drawerWidthMini } from 'utils/variables';

const PlainBar: React.FC<IBar> = () => {
  const { t } = useTranslation('Bar');
  const navigate = useNavigate();
  const { removeItem } = useStorage();
  const {
    fullName,
    fullNameWithPrefixes,
    hasRole,
    unloadUserFromStore,
    // authType,
    // user: { sub },
  } = useUser();
  const { basketStore } = useStudyInfo();
  const { storeLastPageStateToStore, storeRedirectionToStore } = useAppLoader();
  const { toggleMenu, toggleDarkMode, toggleCompactMode } = useAppGlobals();
  const { refreshTokenAndStore } = useToken();
  const { newNotifications: websocketNotifications, hasMiniMenu, feConfigFromApi } = useAppInfo();

  const appTitle = feConfigFromApi?.mainTitle || 'MARIE Portal';

  const { compactMode, isDarkMode } = useAppInfo();
  const theme = useTheme() as Theme;

  const isMobile = useMediaQuery('(max-width:599px)');

  const onSignOut = async () => {
    await logout();
    storeLastPageStateToStore({});

    removeItem('token');
    navigate('/login?loggedOut=true');
    unloadUserFromStore();
    storeRedirectionToStore(false);
  };

  const onUserProfile = () => navigate('/userProfile');

  const onMenuToggle = () => {
    toggleMenu();
  };

  const handleToggleDarkMode = async () => {
    try {
      toggleDarkMode(!isDarkMode); // Set dark mode in redux store
      await setDarkMode(!isDarkMode); // Change it in user settings via API
      await refreshTokenAndStore(); // Store new token
    } catch (e) {}
  };

  const handleToggleCompactMode = async (/* state: boolean */) => {
    const state = !compactMode;
    try {
      toggleCompactMode(state); // Set compact mode in redux store
      await saveDisplayMode(state); // Change it in user settings via API
    } catch (e) {}
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openMobileMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeMobileMenu = () => setAnchorEl(null);

  const mobileMenuOpened = Boolean(anchorEl);

  const DarkModeSwitch = () => (
    <Tooltip title={t('Users:darkMode')} placement="top-start">
      <Stack direction="row" spacing={0} alignItems="center">
        <WbSunnyIcon fontSize="small" />
        <Switch
          size="small"
          color="default"
          checked={isDarkMode}
          onChange={handleToggleDarkMode}
          name="darkMode"
        />
        <Brightness2Icon fontSize="small" />
      </Stack>
    </Tooltip>
  );

  const CompactModeSwitch = () => (
    <Tooltip title={t('compactDisplayMode')} placement="top-start">
      <Stack direction="row" spacing={0} alignItems="center">
        <DensityMedium fontSize="small" />
        <Switch
          size="small"
          color="default"
          checked={compactMode}
          onChange={handleToggleCompactMode}
          name="compactMode"
        />
        <DensitySmall fontSize="small" />
      </Stack>
    </Tooltip>
  );

  return (
    <AppBar
      sx={{
        /*zIndex: (theme) => theme.zIndex.tooltip + 1,*/ width: `calc(100% - ${
          hasMiniMenu ? drawerWidthMini : drawerWidth
        }px)`,
        '@media print': { display: 'none' },
      }}
    >
      <Toolbar
        variant="dense"
        sx={{ pl: { xs: 0.5, sm: 1 }, pr: { sm: 2 }, backgroundColor: theme.palette.primary.main }}
      >
        <IconButton color="inherit" aria-label="Menu" onClick={onMenuToggle} size="large">
          <MenuIcon />
        </IconButton>

        <Typography variant="h6" color="inherit" noWrap={true} sx={{ flexGrow: 1 }}>
          <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
            {appTitle}
          </Link>
        </Typography>

        {hasRole('ROLE_STUDY_TEMPORARY_LIST') && !isEmpty(basketStore) ? (
          <Tooltip title={t('showStudiesFromBasket')} placement="top-start">
            <IconButton
              color="inherit"
              component={Link}
              {...({ to: '/basket' } as any)}
              data-selenium-selector="appBar-basket-button"
              sx={{ p: 1, ml: 1, mr: 1 }}
            >
              <Badge badgeContent={basketStore.length} color="error">
                <BasketIcon color="inherit" />
              </Badge>
            </IconButton>
          </Tooltip>
        ) : null}

        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              onClick={mobileMenuOpened ? closeMobileMenu : openMobileMenu}
            >
              <SettingsIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={mobileMenuOpened}
              onClose={closeMobileMenu}
            >
              <MenuItem onClick={closeMobileMenu} sx={{ justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <DarkModeSwitch />
                </Box>
              </MenuItem>
              <MenuItem onClick={closeMobileMenu} sx={{ justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CompactModeSwitch />
                </Box>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onUserProfile();
                  closeMobileMenu();
                }}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText inset={false} primary={t('userProfile')} />
              </MenuItem>
              <MenuItem onClick={onSignOut}>
                <ListItemIcon>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText inset={false} primary={t('logout')} />
              </MenuItem>
            </Menu>
          </>
        ) : null}

        {!isMobile ? (
          <>
            <Box sx={{ display: 'inline-flex', mr: 2 }}>
              <DarkModeSwitch />
            </Box>
            <Box sx={{ display: 'inline-flex', mr: 2 }}>
              <CompactModeSwitch />
            </Box>

            <Tooltip title={fullNameWithPrefixes} placement="top-start">
              <Button size="small" color="inherit" onClick={onUserProfile}>
                <Person />
                {fullName}
              </Button>
            </Tooltip>
            <IconButton
              color="inherit"
              component={Link}
              {...({ to: '/notifications' } as any)}
              // className={classes.badgeRoot}
              data-selenium-selector="appBar-notifications-button"
            >
              <Badge
                badgeContent={websocketNotifications.length}
                invisible={!websocketNotifications.length}
                color="error" //style={{ color: '#ed5565' }}
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Button size="small" color="inherit" onClick={onSignOut}>
              <ExitToApp />
              {t('logout')}
            </Button>
          </>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

export const Bar = React.memo(PlainBar, stateIsSame);
