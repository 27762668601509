import { get, isArray, isEmpty, isNumber, noop } from 'lodash';
import { useNavigate, Route, Routes } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { Menu } from 'components/Menu/Menu';
import { useToken } from 'utils/hooks/useToken';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppLoader } from 'utils/hooks/useAppLoader';

import { Statistics } from 'modules/Reports/Statistics';
import { StudiesByStudyTagsStatistics } from 'modules/Reports/StudiesByStudyTagsStatistics';
import AuditLogs from 'modules/Logs/AuditLogs';
import { UserForm } from 'modules/Administration/Users/UserForm';
import { UserProfile } from 'modules/Administration/Users/UserProfile';
import { Tools } from 'modules/Administration/Tools/Tools';
import { AnnouncementsAndButtons } from 'modules/Administration/AnnouncementsAndButtons/AnnouncementsAndButtons';
import { AnnouncementForm } from 'modules/Administration/Announcements/AnnouncementForm';
import { ButtonForm } from 'modules/Administration/Buttons/ButtonForm';
import { FacilitiesAndExchangeNetworks } from 'modules/Administration/FacilitiesAndExchangeNetworks/FacilitiesAndExchangeNetworks';
import { FacilityForm } from 'modules/Administration/Facilities/FacilityForm';
import { FacilityExchangeNetworkForm } from 'modules/Administration/Facilities/FacilityExchangeNetworkForm';
import AdminFolders from 'modules/Administration/Folders/AdminFolders';
import ViewFolders from 'modules/Folders/ViewFolders';
import { FolderForm } from 'modules/Administration/Folders/FolderForm';
import { FolderDetail } from 'modules/Administration/Folders/FolderDetail';
import { Filters } from 'modules/Administration/Filters/Filters';
import { FilterForm } from 'modules/Administration/Filters/FilterForm';
import { ModalitiesUnitsStations } from 'modules/Administration/ModalitiesUnitsStations/ModalitiesUnitsStations';
import { Settings } from 'modules/Administration/Settings/Settings';
import { GeneralSettings } from 'modules/Administration/GeneralSettings/GeneralSettings';
import OrderForm from 'modules/Orders/OrderForm';
import Orders from 'modules/Orders/Orders';
import OrderDetail from 'modules/Orders/OrderDetail';
import OrderEdit from 'modules/Orders/OrderEdit';
import { ProductForm } from 'modules/Administration/Products/ProductForm';
import { ProductSettingForm } from 'modules/Administration/Products/ProductSettingForm';
import { DictionariesItem } from 'modules/Administration/Dictionaries/DictionariesItem';
import { DictionaryForm } from 'modules/Administration/Dictionaries/DictionaryForm';
import { WorkplaceForm } from 'modules/Administration/Workplaces/WorkplaceForm';
import { ClinicalPortalAdministration } from 'modules/Administration/ClinicalPortal/ClinicalPortal';
import { ClinicalPortalByPatient } from 'modules/ClinicalPortal/Patient/Patient';
import { ClinicalPortalByPatientDetail } from 'modules/ClinicalPortal/Patient/PatientDetail';
import { ClinicalPortalByPatientDocumentsDetail } from 'modules/ClinicalPortal/Patient/DocumentsDetail';
import { ClinicalPortalDocumentDetailFromFolder } from 'modules/ClinicalPortal/Patient/DocumentDetailFromFolder';
import { ClinicalPortalDocumentDetailFromTags } from 'modules/ClinicalPortal/Patient/DocumentDetailFromTags';
import { ClinicalPortalByNCPeH } from 'modules/ClinicalPortal/Ncpeh/NCPeH';
import { ClinicalPortalByNCPeHDetail } from 'modules/ClinicalPortal/Ncpeh/NCPeHDetail';
import { DocumentsSend } from 'modules/ClinicalPortal/Patient/DocumentsSend';

import { ExportList } from 'modules/Studies/StudyExport/ExportList';
import StudiesExportDetail from 'modules/Studies/StudyExport/StudiesExportDetail';

import ManageTests from 'modules/Tests/ManageTests';
import { TestForm } from 'modules/Tests/TestForm';
import { TestDetail } from 'modules/Tests/TestDetail';
import { RedirectToTest } from 'modules/Tests/RedirectToTest';
import StartTest from 'modules/Tests/StartTest';
import { StartedTest } from 'modules/Tests/StartedTest';
import { AllResults } from 'modules/Tests/AllResults';
import { Results } from 'modules/Tests/Results';
import { GlobalStats } from 'modules/Tests/GlobalStats';
import { StudyDetail } from 'modules/Studies/StudyDetail/StudyDetail';
import DoseMonitoringCalculate from 'modules/Studies/DoseMonitoring/DoseMonitoringCalculate';

import QueueRequests from 'modules/QueueRequests/QueueRequests';
import QueueRequestDetail from 'modules/QueueRequests/QueueRequestDetail';

import Requests from 'modules/Requests/Requests';
import RequestsSearch from 'modules/Requests/RequestsSearch';
import RequestsSendDetail from 'modules/Requests/RequestsSend/RequestsSendDetail';
import RequestsSendDetailEdit from 'modules/Requests/RequestsSend/RequestsSendDetailEdit';
import RequestsDetail from 'modules/Requests/RequestsDetail';
import RequestsEditStudyDetail from 'modules/Requests/RequestsEdit/RequestsEditStudyDetail';
import RequestsEditStudyDetailEdit from 'modules/Requests/RequestsEdit/RequestsEditStudyDetailEdit';
import RequestsEditPatientDetail from 'modules/Requests/RequestsEdit/RequestsEditPatientDetail';
import RequestsEditPatientDetailEdit from 'modules/Requests/RequestsEdit/RequestsEditPatientDetailEdit';
import RequestsMoveDetail from 'modules/Requests/RequestsMove/RequestsMoveDetail';
import RequestsMoveDetailEdit from 'modules/Requests/RequestsMove/RequestsMoveDetailEdit';
import RequestsCopyDetail from 'modules/Requests/RequestsMove/RequestsCopyDetail';
import RequestsCopyDetailEdit from 'modules/Requests/RequestsMove/RequestsCopyDetailEdit';
import RequestsMoveToFolderDetail from 'modules/Requests/RequestsMoveToFolder/RequestsMoveToFolderDetail';
import RequestsMoveToFolderDetailEdit from 'modules/Requests/RequestsMoveToFolder/RequestsMoveToFolderDetailEdit';
import RequestsMoveToTrashDetail from 'modules/Requests/RequestsMoveToTrash/RequestsMoveToTrashDetail';
import RequestsMoveToTrashDetailEdit from 'modules/Requests/RequestsMoveToTrash/RequestsMoveToTrashDetailEdit';

import ShreddingProposals from 'modules/Shreddings/Proposals/Proposals';
import ShreddingHistories from 'modules/Shreddings/Histories/Histories';
import ShreddingHistoriesPrint from 'modules/Shreddings/Histories/HistoryDetail';
import AddressBooks from 'modules/Mdex/AddressBook/AddressBooks';
import { Transmissions } from 'modules/Mdex/Transmissions/Transmissions';
import { Send } from 'modules/Mdex/Send/Send';
import { Cstore } from 'modules/Cstore/Cstore';

import Histories from 'modules/Histories/Histories';
import FindingReports from 'modules/Reports/FindingReports';

import { useUser } from 'utils/hooks/useUser';
import {
  TOKEN_FUNCTION_DETAIL,
  TOKEN_FUNCTION_UPLOAD,
  TOKEN_FUNCTION_UNSIGNED_STUDIES_LIST,
  MEDIA_QUERY_MIN_HEIGHT_FOR_DATA_GRID,
  MEDIA_QUERY_MIN_WIDTH_FOR_DATA_GRID,
} from 'constants/constants';
import { useStudy } from 'utils/hooks/useStudy';
import {
  getUserSetting,
  getAvailableViewers,
  setViewer,
  checkUserPasswordPolicy,
} from 'modules/Administration/Users/_api';
import { SearchList } from 'modules/Search/SearchList';
import StudiesListAddToFolder from 'modules/Studies/StudiesList/StudiesListAddToFolder';
import StudiesDistributeDetail from 'modules/Studies/StudiesDistribute/StudiesDistributeDetail';
import StudiesSendDetail from 'modules/Studies/StudiesSend/StudiesSendDetail';
import { Bar } from 'components/Bar/Bar';
import { stateIsSame } from 'utils/componentOptimizatons';
import { Box } from '@mui/system';
import {
  appBarHeight,
  appBarHeightCompact,
  bodyPadding,
  drawerWidth,
  drawerWidthMini,
} from 'utils/variables';
import { ICheckUserPasswordDialog, ILoggedUser } from './_types';
import { useAppDispatch } from 'store/hooks';
import {
  loadInitialSettings,
  setViewerToStore,
  storeImportUploadingState,
  storeImportMdexUploadingState,
  storeSearchResultsToStore,
} from 'store/reducers/appReducer';
import StudiesEditDetail from 'modules/Studies/StudiesEdit/StudiesEditDetail';
import { StudiesSplitDetail } from 'modules/Studies/StudiesSplit/StudiesSplitDetail';
import RequestsSplitDetail from 'modules/Requests/RequestsSplit/RequestsSplitDetail';
import RequestsSplitDetailEdit from 'modules/Requests/RequestsSplit/RequestsSplitDetailEdit';
import { Homepage } from 'modules/Homepage/Homepage';
import { About } from 'modules/About/About';
import StudiesBasket from 'modules/Studies/StudiesBasket/StudiesBasket';
import { StudiesReorderDetail } from 'modules/Studies/StudiesReorder/StudiesReorderDetail';
import RequestsReorderDetail from 'modules/Requests/RequestsReorder/RequestsReorderDetail';
import RequestsReorderDetailEdit from 'modules/Requests/RequestsReorder/RequestsReorderDetailEdit';
import ImportDicom from 'modules/Import/ImportDicom';
import ImportNonDicom from 'modules/Import/ImportNonDicom';
import { getInitialObjects } from './_api';
import { SearchArchive } from 'modules/Tags/ScientificArchiveSearch/SearchArchive';
import { DocumentsByTags } from 'modules/Tags/IntegrationPlatformSearch/DocumentsByTags';
import { RegisterGroups } from 'modules/Tags/Administration/RegisterGroups';
import { RegisterGroupForm } from 'modules/Tags/Administration/RegisterGroupForm';
import { RegisterGroupDetail } from 'modules/Tags/Administration/RegisterGroupDetail';
import { RegisterItemForm } from 'modules/Tags/Administration/RegisterItemForm';
import { FrontendSettings } from 'modules/Administration/FrontendSettings/FrontendSettings';
import { Websocket } from 'components/WebSocket/Websocket';
import { WebsocketNotification } from 'modules/websocketNotification/WebsocketNotification';
import { WebsocketNotificationDetail } from 'modules/websocketNotification/WebsocketNotificationDetail';
import { logout } from 'modules/Login/_api';
import { useStorage, putItem } from 'utils/hooks/useStorage';
import { RestrictedSection } from 'components/RestrictedSection/RestrictedSection';
import { LegacyPathComponent } from 'components/LegacyPathComponent/LegacyPathComponent';
import { Page404 } from 'components/Form/404/404';
import CheckUserPasswordPolicyDialog from './CheckUserPasswordPolicyDialog';

let tokenRenewIntervalId: number;
let importUploadingIntervalId: number;
let sessionInterval: number;

const PlainLoggedUser: React.FC<ILoggedUser> = ({ idleTimeToLogout }) => {
  const {
    loadAllProductsToStore,
    loadAllowedArchivesForFunctionsAndStore,
    loadAllFiltersToStore,
    loadAllTagsToStore,
    loadNewNotifications,
    storeLastPageStateToStore,
    storeRedirectionToStore,
    loadAllStatusesToStore,
  } = useAppLoader();

  const [viewerDialog, setViewerDialog] = useState<any>({
    open: false,
    items: [],
  });
  const [checkUserPasswordDialog, setCheckUserPasswordDialog] = useState<ICheckUserPasswordDialog>({
    open: false,
    status: '',
    inDays: 0,
  });
  const [timeout, setTimeout] = useState<number>(1000 * 10 * 60);
  // console.log(viewerDialog); //TODO někde se to použije?
  const { generateIID } = useStudy();
  const { fromToken, user, unloadUserFromStore } = useUser();
  const dispatch = useAppDispatch();
  const { toggleLoader } = useAppGlobals();
  const navigate = useNavigate();
  const { removeItem } = useStorage();
  const { isImportUploading, isImportMdexUploading, hasMiniMenu, compactMode } = useAppInfo();

  const { refreshTokenAndStore } = useToken();

  // Just for the variable not to be unused
  noop(viewerDialog);

  const refreshToken = () => {
    refreshTokenAndStore(true);
  };

  const storeTimestamp = () => {
    const currentTimeInMilliseconds = Date.now();
    putItem('lastTimestamp', currentTimeInMilliseconds.toString());
  };

  const isImportUploadingRef = useRef(isImportUploading);
  useEffect(() => {
    isImportUploadingRef.current = isImportUploading;
  });

  const isImportMdexUploadingRef = useRef(isImportMdexUploading);
  useEffect(() => {
    isImportMdexUploadingRef.current = isImportMdexUploading;
  });

  const importUploading = () => {
    const uppyArray = (window as any).UppyGlobalInstances;
    let output = false;
    let outputMdex = false;
    if (isArray(uppyArray)) {
      uppyArray.forEach((item: any) => {
        if (get(item, 'opts.id') === 'uppy') {
          const state = item?.getState();
          const progress = state?.totalProgress;
          if (progress && progress > 0 && progress < 100) {
            output = true;
          }
        }
        if (get(item, 'opts.id') === 'uppyMdex') {
          const state = item?.getState();
          const progress = state?.totalProgress;
          if (progress && progress > 0 && progress < 100) {
            outputMdex = true;
          }
        }
      });
    }
    if (isImportUploadingRef.current !== output) {
      dispatch(storeImportUploadingState(output));
    }
    if (isImportMdexUploadingRef.current !== outputMdex) {
      dispatch(storeImportMdexUploadingState(outputMdex));
    }
  };

  const loadFromToken = () => {
    if (fromToken !== undefined) {
      const fnc = fromToken.function;
      if (fnc === TOKEN_FUNCTION_DETAIL) {
        const studies = get(fromToken, 'data.studies', []);
        if (studies.length === 0) {
          navigate(`study/null`);
        } else if (studies.length === 1) {
          const study = studies[0];
          navigate(`study/${get(study, 'studyInstanceUid')}_${get(study, 'archive.id')}`);
        } else {
          navigate('/studies/list');
          dispatch(
            storeSearchResultsToStore(
              studies.map((result: any) => ({
                ...result,
                iid: generateIID(result),
              })),
            ),
          );

          // TODO: scroll to top
          // scrollToTopForm('search-results', null);
        }
      }

      if (fnc === TOKEN_FUNCTION_UPLOAD) {
        const studyInfo = get(fromToken, 'data', {});
        if (!isEmpty(studyInfo)) {
          navigate('import/nondicom');
        }
      }

      if (fnc === TOKEN_FUNCTION_UNSIGNED_STUDIES_LIST) {
        const requestId = get(fromToken, 'data.requestId', null);
        if (requestId) {
          navigate(`/signature/unsignedStudies?rid=${requestId}`);
        }
      }
    }
  };

  const loadAllDependenciesAfterLogin = async () => {
    toggleLoader();

    const initialObject = await getInitialObjects();
    dispatch(loadInitialSettings(initialObject));

    await Promise.all([
      // loadAllModalitiesToStore(), //
      // loadAllWorkplaceRolesToStore(),
      loadAllProductsToStore(),
      // loadTrashForUserAndStore(),
      // loadAllRequestStatesToStore(),
      // loadGridSettingsToStore(),
      // loadSearchFilterAndStore(),
      loadAllowedArchivesForFunctionsAndStore(),
      loadAllFiltersToStore(),
      loadAllTagsToStore(),
      loadNewNotifications(),
      loadAllStatusesToStore(),
    ]);
    toggleLoader(false);
  };

  const checkDefaultViewer = async () => {
    const userSettings = await getUserSetting(user.sub);
    if (userSettings) {
      const viewer = userSettings.viewer;
      let defViewer = viewer;
      if (!viewer) {
        const viewers = await getAvailableViewers();
        if (isArray(viewers)) {
          if (viewers.length === 1) {
            defViewer = get(viewers, '[0].id');
            setViewer(userSettings.id, get(viewers, '[0].id'));
          } else if (viewers.length > 1) {
            setViewerDialog({
              open: true,
              items: viewers,
              userSettings: userSettings,
            });
          }
        }
      }
      dispatch(setViewerToStore(defViewer));
    }
  };

  const checkUserPassword = async () => {
    const userSettings = await checkUserPasswordPolicy();
    if (userSettings) {
      const status = get(userSettings, 'status');
      const inDays = get(userSettings, 'inDays', 0);
      if (status === 'EXPIRED') {
        setCheckUserPasswordDialog({ open: true, status, inDays });
      }
    }
  };

  // Load all dependencies after login
  useEffect(() => {
    loadAllDependenciesAfterLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set intervals for token renew and import upload
  useEffect(() => {
    refreshToken();
    tokenRenewIntervalId = window.setInterval(refreshToken, 30 * 1000);
    importUploadingIntervalId = window.setInterval(importUploading, 2 * 1000);
    sessionInterval = window.setInterval(storeTimestamp, 5 * 1000);

    return () => {
      clearInterval(tokenRenewIntervalId);
      clearInterval(importUploadingIntervalId);
      clearInterval(sessionInterval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load from token and check viewer
  useEffect(() => {
    if (fromToken) {
      loadFromToken();
    }

    checkDefaultViewer();
    checkUserPassword();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      const timeToLogout = parseInt(idleTimeToLogout, 10);
      const timeout = isNumber(timeToLogout) && timeToLogout > 0 ? timeToLogout : 1000 * 10 * 60;
      setTimeout(timeout);
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idleTimeToLogout]);

  const onIdle = async () => {
    const pathName = window.location.pathname;
    await logout();
    storeLastPageStateToStore({ url: pathName.replace('portal/', '') });

    removeItem('token');
    navigate('/login?inactive=true');
    unloadUserFromStore();
    storeRedirectionToStore(false);
  };

  useIdleTimer({ onIdle, timeout, throttle: 500 });

  return (
    <>
      <Websocket />
      <Bar />
      <Menu />

      <Box
        sx={{
          paddingTop: compactMode
            ? `${appBarHeightCompact + bodyPadding}px`
            : `${appBarHeight + 10}px`,
          paddingLeft: hasMiniMenu
            ? `${drawerWidthMini + bodyPadding}px`
            : `${drawerWidth + bodyPadding}px`,
          paddingBottom: `${bodyPadding}px`,
          paddingRight: `${bodyPadding}px`,
          transition: (theme: any) => theme.transitions.create(['padding']),
          '@media print': {
            padding: `${bodyPadding}px`,
          },
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
          [`@media screen and (min-height: ${MEDIA_QUERY_MIN_HEIGHT_FOR_DATA_GRID}px) AND (min-width: ${MEDIA_QUERY_MIN_WIDTH_FOR_DATA_GRID}px)`]:
            {
              height: '100vh',
            },
        }}
      >
        {/* prettier-ignore */}
        <Routes>
          <Route path="/" element={<Homepage />} />
          {/* About */}
          <Route path="/about" element={<About />} />

          {/* Studies - may be obsolete? - at least some of them */}
          <Route path="/studies" element={<RestrictedSection key="search" allowedRoles={['ROLE_VIEW_STUDY']} Component={SearchList} /> } />
          <Route path="/studies/addToFolder" element={<RestrictedSection key="addToFolder" Component={StudiesListAddToFolder} allowedRoles='ROLE_CAN_OPERATE_WITH_FOLDERS' />} />
          <Route path="/studies/move/detail" element={<RestrictedSection key="MOVE" Component={StudiesDistributeDetail} allowedRoles='ROLE_MOVE' />} />
          <Route path="/studies/copy/detail" element={<RestrictedSection key="COPY" Component={StudiesDistributeDetail} allowedRoles='ROLE_COPY' />} />
          <Route path="/studies/send/detail" element={<RestrictedSection key="send" Component={StudiesSendDetail} allowedRoles={['ROLE_SEND_DR_SEJF', 'ROLE_SEND_EPACS', 'ROLE_SEND_REDIMED']} />} />
          <Route path="/studies/editStudy/detail" element={<RestrictedSection key="editStudy" Component={StudiesEditDetail} allowedRoles='ROLE_UPDATE_STUDY' />} />
          <Route path="/studies/editPatient/detail" element={<RestrictedSection key="editPatient" Component={StudiesEditDetail} allowedRoles='ROLE_UPDATE_PATIENT' />} />
          <Route path="/studies/split/detail" element={<RestrictedSection key="split" Component={StudiesSplitDetail} allowedRoles="ROLE_SPLIT" />} />
          <Route path="/studies/reorder/detail" element={<RestrictedSection key="reorder" Component={StudiesReorderDetail} allowedRoles="ROLE_REORDER" />} />

          <Route path="/study/:iid" element={<RestrictedSection key="study" Component={StudyDetail} allowedRoles='ROLE_VIEW_STUDY' />} />

          {/* Import */}
          <Route path="/import/dicom" element={<RestrictedSection key="dicom" Component={ImportDicom} allowedRoles="ROLE_IMPORT_DICOM" />} />
          <Route path="/import/nondicom" element={<RestrictedSection key="nondicom" Component={ImportNonDicom} allowedRoles="ROLE_IMPORT_NONDICOM" />} />

          <Route path="/doseMonitoring/calculate" element={<RestrictedSection key="calculate" Component={DoseMonitoringCalculate} allowedRoles='ROLE_DOSE_MONITORING' />} />
          <Route path="/basket" element={<RestrictedSection key="basket" Component={StudiesBasket} allowedRoles='ROLE_STUDY_TEMPORARY_LIST' />} />

          <Route path="/reports/statistics" element={<RestrictedSection key="statistics" Component={Statistics} allowedRoles="ROLE_VIEW_STATISTICS" />} />
          <Route path="/reports/studiesByStudyTagsStatistics" element={<RestrictedSection key="studyTagsStatistics" Component={StudiesByStudyTagsStatistics} allowedRoles="ROLE_VIEW_STATISTICS" />} />
          <Route path="/reports/auditLogs" element={<RestrictedSection key="auditLogs" Component={AuditLogs} allowedRoles="ROLE_VIEW_AUDIT_LOGS" />} />
          <Route path="/orders/:id" element={<RestrictedSection key="order" Component={OrderForm} allowedRoles="ROLE_MWL_VIEW" />} />
          <Route path="/orders" element={<RestrictedSection key="orders" Component={Orders} allowedRoles="ROLE_MWL_VIEW" />} />
          <Route path="/orders/:patientId/:accessionNumber/edit" element={<RestrictedSection key="orderEdit" Component={OrderEdit} allowedRoles="ROLE_MWL_VIEW" />} />
          <Route path="/orders/:patientId/:accessionNumber/detail" element={<RestrictedSection key="orderDetail" Component={OrderDetail} allowedRoles="ROLE_MWL_VIEW" />} />


          {/* ROLE_USER */}
          <Route path="/administration/settings/users/:id" key="noUseLocalEdit" element={<RestrictedSection key="user" Component={UserForm} allowedRoles="ROLE_USER" />} />
          <Route path="/administration/settingsFromLocalUserDirectory/users/:id" key="useLocalEdit" element={<RestrictedSection key="useLocalEdit" Component={UserForm} allowedRoles="ROLE_USER" />} />
          <Route path="/userProfile" element={<UserProfile />} />
          <Route path="/administration/tools" element={<RestrictedSection key="tools" Component={Tools} allowedRoles="ROLE_TOOLS_RSA" />} />


          <Route path="/administration/announcementsAndButtons" element={<RestrictedSection key="annoncementsAndButtons" Component={AnnouncementsAndButtons} allowedRoles={["ROLE_BUTTONS", "ROLE_ANNOUNCEMENT"]} />}  />
          <Route path="/administration/announcementsAndButtons/announcement/:id" element={<RestrictedSection key="annoncement" Component={AnnouncementForm} allowedRoles="ROLE_ANNOUNCEMENT" />} />
          <Route path="/administration/announcementsAndButtons/button/:id" element={<RestrictedSection key="button" Component={ButtonForm} allowedRoles="ROLE_BUTTONS" />}  />

          {/* ROLE_FACILITY, ROLE_EXCHANGE_NETWORK */}
          <Route path="/administration/facilitiesAndExchangeNetworks" element={<RestrictedSection key="facilitiesAndExNet" Component={FacilitiesAndExchangeNetworks} allowedRoles={["ROLE_FACILITY", "ROLE_EXCHANGE_NETWORK"]} />}  />
          <Route path="/administration/facilitiesAndExchangeNetworks/:id" element={<RestrictedSection key="facility" Component={FacilityForm} allowedRoles={["ROLE_FACILITY", "ROLE_EXCHANGE_NETWORK"]} />} />
          <Route path="/administration/facilitiesAndExchangeNetworks/:facilityId/:facilityExchangeNetworkId" element={<RestrictedSection key="ExNet" Component={FacilityExchangeNetworkForm} allowedRoles="ROLE_FACILITY" />} />

          {/* ROLE_CAN_ADMINISTRATE_FOLDERS */}
          <Route path="/administration/folders" element={<RestrictedSection key="adminFolders" Component={AdminFolders} allowedRoles="ROLE_CAN_ADMINISTRATE_FOLDERS" />} />
          <Route path="/administration/folders/:id" key="administrationFolderForm" element={<RestrictedSection Component={FolderForm} key="administrationFolderForm" allowedRoles="ROLE_CAN_ADMINISTRATE_FOLDERS" />} />
          <Route path="/administration/folders/:id/detail" key="administrationFolderDetail" element={<RestrictedSection Component={FolderDetail} key="administrationFolderDetail" allowedRoles="ROLE_CAN_ADMINISTRATE_FOLDERS" />} />


          {/* ROLE_CAN_OPERATE_WITH_FOLDERS */}
          <Route path="/folders" element={<RestrictedSection Component={ViewFolders} key="administrationFolderDetail" allowedRoles="ROLE_CAN_OPERATE_WITH_FOLDERS" />} />
          <Route path="/folders/:id" key="folderForm" element={<RestrictedSection Component={FolderForm} key="folderForm" allowedRoles="ROLE_CAN_OPERATE_WITH_FOLDERS" />} />
          <Route path="/folders/:id/detail" key="folderDetail" element={<RestrictedSection Component={FolderDetail} key="folderDetail" allowedRoles="ROLE_CAN_OPERATE_WITH_FOLDERS" />} />

          {/* ROLE_CAN_ADMINISTRATE_CLINICAL_PORTAL */}
          <Route path="/administration/clinicalPortal" element={<RestrictedSection key="clinicalPortal" Component={ClinicalPortalAdministration} allowedRoles="ROLE_CAN_ADMINISTRATE_CLINICAL_PORTAL" />} />

          {/* ROLE_DICTIONARY */}
          <Route path="/administration/generalSettings/:dictionary" element={<RestrictedSection key="dictionaries" Component={DictionariesItem} allowedRoles={['ROLE_DICTIONARY', 'ROLE_DECUBITS_MANAGE', 'ROLE_CAN_MANAGE_CLOUD_PACS_RECIPIENTS']} />} />
          <Route path="/administration/generalSettings/:dictionary/:id" element={<RestrictedSection key="dictionary" Component={DictionaryForm} allowedRoles={['ROLE_DICTIONARY', 'ROLE_DECUBITS_MANAGE']} />} />
          
          {/* ROLE_PRODUCT, ROLE_USER, ROLE_WORKPLACE */}
          <Route path="/administration/settings" key="noUseLocal" element={<RestrictedSection Component={Settings} key="noUseLocal" allowedRoles={['ROLE_PRODUCT', 'ROLE_USER', 'ROLE_WORKPLACE', 'ROLE_ADMINISTRATE_MODALITIES_IN_MARIE', 'ROLE_ADMINISTRATE_MODALITIES_IN_DPGW']} />} /> 
          <Route path="/administration/frontendSettings" element={<RestrictedSection Component={FrontendSettings} key="frontendSettings" allowedRoles='ROLE_FE_SETTINGS_UPDATE' />} />
          <Route path="/administration/settingsFromLocalUserDirectory" key="useLocal" element={<RestrictedSection Component={Settings} key="useLocal" allowedRoles={['ROLE_PRODUCT', 'ROLE_USER', 'ROLE_WORKPLACE', 'ROLE_ADMINISTRATE_MODALITIES_IN_MARIE', 'ROLE_ADMINISTRATE_MODALITIES_IN_DPGW']} />} />

          <Route path="/administration/generalSettings" element={<RestrictedSection key="generalSettings" Component={GeneralSettings} allowedRoles={['ROLE_DICTIONARY', 'ROLE_LANGUAGE', 'ROLE_PRIORITY_AND_OPERATION', 'ROLE_NOTIFICATION_USER', 'ROLE_NOTIFICATION_ADMIN']} />} />

          {/* ROLE_MODALITY, ROLE_UNIT, ROLE_STATION */}
          <Route path="/administration/modalitiesUnitsStations" element={<RestrictedSection key="modalitiesUnitsStations" Component={ModalitiesUnitsStations} allowedRoles={['ROLE_MODALITY', 'ROLE_UNIT', 'ROLE_STATION']} />} />

          {/* ROLE_PRODUCT */}
          <Route path="/administration/settings/product/:id" element={<RestrictedSection key="product" Component={ProductForm} allowedRoles='ROLE_PRODUCT' />} />
          <Route path="/administration/settings/product/:productId/:settingId" element={<RestrictedSection key="productSetting" Component={ProductSettingForm} allowedRoles='ROLE_PRODUCT' />} />
          <Route path="/administration/settingsFromLocalUserDirectory/product/:id" element={<RestrictedSection key="useLocalProductSetting" Component={ProductForm} allowedRoles='ROLE_PRODUCT' />} />
          <Route path="/administration/settingsFromLocalUserDirectory/product/:productId/:settingId" element={<RestrictedSection key="useLocalProductProductSetting" Component={ProductSettingForm} allowedRoles='ROLE_PRODUCT' />} />

          {/* ROLE_WORKPLACE */}
          <Route path="/administration/settings/workplaces/:id" element={<RestrictedSection key="workplace" Component={WorkplaceForm} allowedRoles='ROLE_WORKPLACE' />} />
          <Route path="/administration/settingsFromLocalUserDirectory/workplace/:id" element={<RestrictedSection key="useLocalWorkplace" Component={WorkplaceForm} allowedRoles='ROLE_WORKPLACE' />} />


          {/* Tags: ROLE_SCIENTIFIC_ARCHIVE */}
          <Route path="/tags/archive" element={<RestrictedSection key="tagsArchive" Component={SearchArchive} allowedRoles='ROLE_SCIENTIFIC_ARCHIVE' />} />
          <Route path="/tags/documentsByTags" element={<RestrictedSection key="taggs/document" Component={DocumentsByTags} allowedRoles='ROLE_CAN_SEARCH_BY_TAGS_IN_IP' />} />

          {/* Tags: ROLE_TAGS */}
          <Route path="/tags/registerGroups" element={<RestrictedSection key="tagsRegisterGroups" Component={RegisterGroups} allowedRoles='ROLE_TAGS' />} />
          <Route path="/tags/registerGroups/create" element={<RestrictedSection key="tagsRegisterGroupCreate" Component={RegisterGroupForm} allowedRoles='ROLE_TAGS' />} />
          <Route path="/tags/registerGroups/:id" element={<RestrictedSection key="tagsRegisterGroup" Component={RegisterGroupDetail} allowedRoles='ROLE_TAGS' />} />
          <Route path="/tags/registerGroups/:parentId/subgroups/:id" element={<RestrictedSection key="tagsRegisterGroupSubGroup" Component={RegisterGroupForm} allowedRoles='ROLE_TAGS' />} />
          <Route path="/tags/registerGroups/:parentId/registerItems/:id" element={<RestrictedSection key="tagsRegisterGroupRegisterItem" Component={RegisterItemForm} allowedRoles='ROLE_TAGS' />} />

          <Route path="/exportsList" element={<RestrictedSection key="exportsList" Component={ExportList} allowedRoles={['ROLE_EXPORT_CD', 'ROLE_EXPORT_DICOM', 'ROLE_EXPORT_DVD','ROLE_EXPORT_PICTURE','ROLE_EXPORT_VIDEO']} />} />
          <Route path="/exports/detail" element={<RestrictedSection key="exportDetail" Component={StudiesExportDetail} allowedRoles={['ROLE_EXPORT_CD', 'ROLE_EXPORT_DICOM', 'ROLE_EXPORT_DVD','ROLE_EXPORT_PICTURE','ROLE_EXPORT_VIDEO']} />} />
          
          {/* MAMO - ROLE_MANAGE_TEST */}
          <Route path="/tests/manageTests" element={<RestrictedSection key="manageTests" Component={ManageTests} allowedRoles='ROLE_MANAGE_TEST' />} />
          <Route path="/tests/manageTests/:id" element={<RestrictedSection key="testForm" Component={TestForm} allowedRoles='ROLE_MANAGE_TEST' />} />
          <Route path="/tests/manageTests/:id/detail" element={<RestrictedSection key="testDetail" Component={TestDetail} allowedRoles='ROLE_MANAGE_TEST' />} />
          <Route path="/tests/redirectToTest/:id" element={<RestrictedSection key="testRedirect" Component={RedirectToTest} allowedRoles='ROLE_MANAGE_TEST' />} />

          {/* MAMO - ROLE_MAMO */}
          <Route path="/tests/startTest" element={<RestrictedSection key="startTest" Component={StartTest} allowedRoles='ROLE_MAMO' />} />   
          <Route path="/tests/startTest/:id" element={<RestrictedSection key="startedTest" Component={StartedTest} allowedRoles='ROLE_MAMO' />} />
          <Route path="/tests/startTest/:id/:testIsUnfinished" element={<RestrictedSection key="startedTestUnfinished" Component={StartedTest} allowedRoles='ROLE_MAMO' />} />
          <Route path="/tests/results" element={<RestrictedSection key="testResults" Component={AllResults} allowedRoles={["ROLE_MAMO","ROLE_MAMO_RESULTS"]} />} />
          <Route path="/tests/results/:id" element={<RestrictedSection key="testResult" Component={Results} allowedRoles={["ROLE_MAMO","ROLE_MAMO_RESULTS"]} />} />
          <Route path="/tests/globalStats" element={<RestrictedSection key="globalStats" Component={GlobalStats} allowedRoles='ROLE_VIEW_MAMO_STATISTICS' />} />

          {/* QueueRequest-items: ROLE_SHOW_REALISATOR_QUEUE */}
          <Route path="/queueRequests" element={<RestrictedSection key="queueRequests" Component={QueueRequests} allowedRoles='ROLE_SHOW_REALISATOR_QUEUE' />} />
          <Route path="/queueRequests/:id" element={<RestrictedSection key="queueRequestDetail" Component={QueueRequestDetail} allowedRoles='ROLE_SHOW_REALISATOR_QUEUE' />} />

          {/* Requests - SEND */}
          <Route path="/requests/send/create" element={<RestrictedSection key="requestSendCreate" Component={RequestsSendDetail} allowedRoles={['ROLE_REQUEST_SEND_DR_SEJF','ROLE_REQUEST_SEND_EPACS','ROLE_REQUEST_SEND_REDIMED']} />} />
          <Route path="/requests/send/:id" element={<RestrictedSection key="requestSendEdit" Component={RequestsSendDetailEdit} allowedRoles={['ROLE_REQUEST_SEND_DR_SEJF','ROLE_REQUEST_SEND_EPACS','ROLE_REQUEST_SEND_REDIMED']} />} />

          {/* Requests - SPLIT */}
          <Route path="/requests/split/create"  element={<RestrictedSection key="requestSplitCreate" Component={RequestsSplitDetail} allowedRoles='ROLE_REQUEST_SPLIT' />} />
          <Route path="/requests/split/:id" element={<RestrictedSection key="requestSplitEdit" Component={RequestsSplitDetailEdit} allowedRoles='ROLE_REQUEST_SPLIT' />} />

          {/* Requests - REORDER */}
          <Route path="/requests/reorder/create" element={<RestrictedSection key="requestReorderCreate" Component={RequestsReorderDetail} allowedRoles='ROLE_REQUEST_REORDER' />} />
          <Route path="/requests/reorder/:id" element={<RestrictedSection key="requestReorderEdit" Component={RequestsReorderDetailEdit} allowedRoles='ROLE_REQUEST_REORDER' />} />

          {/* Requests - view */}
          <Route path="/requests" element={<RestrictedSection key="requests" Component={Requests} allowedRoles={['ROLE_REQUEST_COPY', 'ROLE_REQUEST_MOVE', 'ROLE_REQUEST_MOVE_TO_FOLDER', 'ROLE_REQUEST_MOVE_TO_TRASH', 'ROLE_REQUEST_SPLIT', 'ROLE_REQUEST_UPDATE_STUDY', 'ROLE_REQUEST_UPDATE_PATIENT', 'ROLE_REQUEST_SEND_DR_SEJF', 'ROLE_REQUEST_SEND_EPACS', 'ROLE_REQUEST_SEND_REDIMED', 'ROLE_REQUEST_REORDER']} />} />
          <Route path="/requests/search" element={<RestrictedSection key="requestsSearch" Component={RequestsSearch} allowedRoles={['ROLE_REQUEST_COPY', 'ROLE_REQUEST_MOVE', 'ROLE_REQUEST_MOVE_TO_FOLDER', 'ROLE_REQUEST_MOVE_TO_TRASH', 'ROLE_REQUEST_SPLIT', 'ROLE_REQUEST_UPDATE_STUDY', 'ROLE_REQUEST_UPDATE_PATIENT', 'ROLE_REQUEST_SEND_DR_SEJF', 'ROLE_REQUEST_SEND_EPACS', 'ROLE_REQUEST_SEND_REDIMED', 'ROLE_REQUEST_REORDER']} />} />
          <Route path="/requests/:id" element={<RestrictedSection key="requestDetail" Component={RequestsDetail} allowedRoles={['ROLE_REQUEST_COPY', 'ROLE_REQUEST_MOVE', 'ROLE_REQUEST_MOVE_TO_FOLDER', 'ROLE_REQUEST_MOVE_TO_TRASH', 'ROLE_REQUEST_SPLIT', 'ROLE_REQUEST_UPDATE_STUDY', 'ROLE_REQUEST_UPDATE_PATIENT', 'ROLE_REQUEST_SEND_DR_SEJF', 'ROLE_REQUEST_SEND_EPACS', 'ROLE_REQUEST_SEND_REDIMED', 'ROLE_REQUEST_REORDER']} />} />

          {/* Requests - EDIT */}
          <Route path="/requests/editStudy/create" element={<RestrictedSection key="requestEditStudyCreate" Component={RequestsEditStudyDetail} allowedRoles='ROLE_REQUEST_UPDATE_STUDY' />} />
          <Route path="/requests/editStudy/:id" element={<RestrictedSection key="requestEditStudyEdit" Component={RequestsEditStudyDetailEdit} allowedRoles='ROLE_REQUEST_UPDATE_STUDY' />} />
          <Route path="/requests/editPatient/create" element={<RestrictedSection key="requestEditPatientCreate" Component={RequestsEditPatientDetail} allowedRoles='ROLE_REQUEST_UPDATE_PATIENT' />} />
          <Route path="/requests/editPatient/:id" element={<RestrictedSection key="requestEditPatientEdit" Component={RequestsEditPatientDetailEdit} allowedRoles='ROLE_REQUEST_UPDATE_PATIENT' />} />
          
          {/* Requests - MOVE/COPY */}
          <Route path="/requests/move/create" element={<RestrictedSection key="requestMoveCreate" Component={RequestsMoveDetail} allowedRoles='ROLE_REQUEST_MOVE' />} />
          <Route path="/requests/move/:id" element={<RestrictedSection key="requestMoveEdit" Component={RequestsMoveDetailEdit} allowedRoles='ROLE_REQUEST_MOVE' />} />
          <Route path="/requests/copy/create" element={<RestrictedSection key="requestCopyCreate" Component={RequestsCopyDetail} allowedRoles='ROLE_REQUEST_COPY' />} />
          <Route path="/requests/copy/:id" element={<RestrictedSection key="requestCopyEdit" Component={RequestsCopyDetailEdit} allowedRoles='ROLE_REQUEST_COPY' />} />

          {/* Requests - MOVE_TO_FOLDER */}
          <Route path="/requests/moveToFolder/create" element={<RestrictedSection key="requestMoveToFolderCreate" Component={RequestsMoveToFolderDetail} allowedRoles='ROLE_REQUEST_MOVE_TO_FOLDER' />} />
          <Route path="/requests/moveToFolder/:id" element={<RestrictedSection key="requestMoveToFolderEdit" Component={RequestsMoveToFolderDetailEdit} allowedRoles='ROLE_REQUEST_MOVE_TO_FOLDER' />} />

          {/* Requests - MOVE_TO_TRASH */}
          <Route path="/requests/moveToTrash/create" element={<RestrictedSection key="requestTrashCreate" Component={RequestsMoveToTrashDetail} allowedRoles='ROLE_REQUEST_MOVE_TO_TRASH' />} />
          <Route path="/requests/moveToTrash/:id" element={<RestrictedSection key="requestTrashEdit" Component={RequestsMoveToTrashDetailEdit} allowedRoles='ROLE_REQUEST_MOVE_TO_TRASH' />} />

          {/* ROLE_CAN_USE_CLINICAL_PORTAL */}
          <Route path="/clinicPortal/byPatient" element={<RestrictedSection key="clinicPortalByPatient" Component={ClinicalPortalByPatient} allowedRoles='ROLE_CAN_USE_CLINICAL_PORTAL' />} />
          <Route path="/clinicPortal/:id" element={<RestrictedSection key="clinicPortalByPatientDetail" Component={ClinicalPortalByPatientDetail} allowedRoles='ROLE_CAN_USE_CLINICAL_PORTAL' />} />{' '}
          <Route path="/clinicPortal/:id/documents" element={<RestrictedSection key="clinicPortalPatientDocuments" Component={ClinicalPortalByPatientDocumentsDetail} allowedRoles='ROLE_CAN_USE_CLINICAL_PORTAL' />} />
          <Route path="/clinicPortal/documents/:folderId/" element={<RestrictedSection key="clinicPortalDocumentsFolder" Component={ClinicalPortalDocumentDetailFromFolder} allowedRoles='ROLE_CAN_USE_CLINICAL_PORTAL' />} />
          <Route path="/clinicPortal/tag/document" element={<RestrictedSection key="clinicPortalTagDocument" Component={ClinicalPortalDocumentDetailFromTags} allowedRoles='ROLE_CAN_USE_CLINICAL_PORTAL' />} />

          {/* ROLE_SEND_DOCUMENT_AS_PDF */}
          <Route path="/clinicPortal/documentsSend" element={<RestrictedSection key="clinicPortalDocumentsSend" Component={DocumentsSend} allowedRoles={['ROLE_SEND_DOCUMENT_AS_PDF','ROLE_SEND_DOCUMENT_AS_ORIGINAL']} />} />

          {/* ROLE_CAN_USAGE_NCP_INTERFACE */}
          <Route path="/clinicPortal/byNCPeH" element={<RestrictedSection key="clinicPortalByNCPeH" Component={ClinicalPortalByNCPeH} allowedRoles='ROLE_CAN_USAGE_NCP_INTERFACE' />} />
          <Route path="/clinicPortal/byNCPeH/:id" element={<RestrictedSection key="clinicPortalByNCPeHDetail" Component={ClinicalPortalByNCPeHDetail} allowedRoles='ROLE_CAN_USAGE_NCP_INTERFACE' />} />

          {/* Filters */}
          <Route path="/filters" element={<RestrictedSection key="filters" Component={Filters} allowedRoles={['ROLE_CREATE_FILTER','ROLE_EDIT_FILTER','ROLE_DELETE_FILTER']} />} />
          <Route path="/filters/:id" element={<RestrictedSection key="filterDetail" Component={FilterForm} allowedRoles={['ROLE_CREATE_FILTER','ROLE_EDIT_FILTER','ROLE_MANAGE_WORKPLACE_IN_FILTER']} />} />

          {/* Websocket notifications */}
          <Route path="/notifications" element={<RestrictedSection key="notifications" Component={WebsocketNotification} />} />
          <Route path="/notifications/:id" element={<RestrictedSection key="notificationDetail" Component={WebsocketNotificationDetail} />} />

          {/* Shreddings */}
          <Route path="/shreddings/proposals" element={<RestrictedSection key="shreddingProposals" Component={ShreddingProposals} allowedRoles='ROLE_MANAGE_SHREDDING' />} />
          <Route path="/shreddings/histories" element={<RestrictedSection key="shreddingHistories" Component={ShreddingHistories} allowedRoles='ROLE_MANAGE_SHREDDING' />} />
          <Route path="/shreddings/histories/print" element={<RestrictedSection key="shreddingHistoriesPrint" Component={ShreddingHistoriesPrint} allowedRoles='ROLE_MANAGE_SHREDDING' />} />

          {/* Mdex */}
          <Route path="/mdex/sendData" element={<RestrictedSection key="mdexSendData" Component={Send} allowedRoles={['ROLE_MDEX_SEND_DICOM', 'ROLE_MDEX_SEND_NON_DICOM']} />} />
          <Route path="/mdex/addressBook" element={<RestrictedSection key="mdexAddressBook" Component={AddressBooks} allowedRoles='ROLE_MDEX_VIEW_ADDRESS_BOOK' />} />
          <Route path="/mdex/transmissions" element={<RestrictedSection key="mdexTransmissions" Component={Transmissions} allowedRoles={['ROLE_MDEX_VIEW_INCOMING_DATA', 'ROLE_MDEX_VIEW_OUTGOING_DATA',]} />} />

          {/* Cstore */}
          <Route path="/cstore" element={<RestrictedSection key="cstore" Component={Cstore} allowedRoles={['ROLE_CSTORE_TO_EXCHANGE_NETWORK', 'ROLE_CSTORE_TO_ARCHIVE']} />} />

          <Route path="/histories/:action" element={<RestrictedSection key="histories" Component={Histories} />} />

          {/* FindingReports - view */}
          <Route path="/reports/findingReports" element={<RestrictedSection key="findingReports" Component={FindingReports} allowedRoles='ROLE_FINDING_STATISTICS' />} />

          {/* V5 legacy URLs not used anymore */}
          <Route path="/studies/reorder" element={<LegacyPathComponent redirectToStudiesSearch={true} />} />
          <Route path="/studies/send" element={<LegacyPathComponent redirectToStudiesSearch={true} />} />
          <Route path="/studies/reorder" element={<LegacyPathComponent redirectToStudiesSearch={true} />} />
          <Route path="/studies/archiving" element={<LegacyPathComponent redirectToStudiesSearch={true} />} />
          <Route path="/studies/editStudy" element={<LegacyPathComponent redirectToStudiesSearch={true} />} />
          <Route path="/studies/editPatient" element={<LegacyPathComponent redirectToStudiesSearch={true} />} />
          <Route path="/studies/move" element={<LegacyPathComponent redirectToStudiesSearch={true} />} />
          <Route path="/studies/copy" element={<LegacyPathComponent redirectToStudiesSearch={true} />} />

          <Route path="/requests/send" element={<LegacyPathComponent redirectToRequests={true} />} />
          <Route path="/requests/reorder" element={<LegacyPathComponent redirectToRequests={true} />} />
          <Route path="/requests/split" element={<LegacyPathComponent redirectToRequests={true} />} />
          <Route path="/requests/editStudy" element={<LegacyPathComponent redirectToRequests={true} />} />
          <Route path="/requests/editPatient" element={<LegacyPathComponent redirectToRequests={true} />} />
          <Route path="/requests/copy" element={<LegacyPathComponent redirectToRequests={true} />} />
          <Route path="/requests/move" element={<LegacyPathComponent redirectToRequests={true} />} />
          <Route path="/requests/moveToFolder" element={<LegacyPathComponent redirectToRequests={true} />} />
          <Route path="/requests/moveToTrash" element={<LegacyPathComponent redirectToRequests={true} />} />

          <Route path="/login" element={<Homepage />} />
          
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Box>

      <CheckUserPasswordPolicyDialog
        checkUserPasswordDialog={checkUserPasswordDialog}
        setCheckUserPasswordDialog={setCheckUserPasswordDialog}
      />
    </>
  );
};

export const LoggedUser = React.memo(PlainLoggedUser, (oldProps, newProps) =>
  stateIsSame(oldProps, newProps, 'LoggedUser'),
);
