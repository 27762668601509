import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { useTranslation } from 'react-i18next';

const useValidationSchema = () => {
  const { required, withoutDiacritics, allCharsWithoutDiacritics, withoutSpecialChars } =
    useValidators();
  const { t } = useTranslation('ConnectedModalities');

  const AddConnectedModalitiesFormSchema = yup.object().shape({
    ip: allCharsWithoutDiacritics(required(yup.string())),
    port: allCharsWithoutDiacritics(required(yup.string())),
    aet: allCharsWithoutDiacritics(required(yup.string())),
    storageDirectory: withoutSpecialChars(withoutDiacritics(required(yup.string()))),
    manufacturer: allCharsWithoutDiacritics(required(yup.string())),
    comment: allCharsWithoutDiacritics(required(yup.string())),
  });

  const formItems = [
    {
      name: 'ip',
      label: t('ip'),
    },
    {
      name: 'port',
      label: t('port'),
    },
    {
      name: 'aet',
      label: t('aet'),
    },
    {
      name: 'storageDirectory',
      label: t('storageDirectory'),
    },
    {
      name: 'manufacturer',
      label: t('manufacturer'),
    },
    {
      name: 'comment',
      label: t('comment'),
    },
  ];

  return {
    AddConnectedModalitiesFormSchema,
    formItems,
  };
};

export default useValidationSchema;
