import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { IStation } from './_types';

export const getAllStations = (): Promise<IStation[]> =>
  api()
    .get(`/portal-api/station/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getStation = (id: string): Promise<IStation> =>
  api()
    .get(`/portal-api/station/get`, { params: { id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editStation = (station: IStation): Promise<boolean> =>
  api()
    .post(`/portal-api/station/edit`, station)
    .then((response) => true)
    .catch((error) => false);

export const createStation = (station: IStation): Promise<boolean> =>
  api()
    .post(`/portal-api/station/create`, station)
    .then((response) => true)
    .catch((error) => false);

export const removeStation = (id: number): Promise<boolean> =>
  api()
    .get(`/portal-api/station/remove`, { params: { id } })
    .then((response) => true)
    .catch((error) => false);
