import React from 'react';
import { TextField } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import {
  COMPACT_MODE_FORM_ELEMENT_FONT_SIZE,
  COMPACT_MODE_FORM_ELEMENT_HEIGHT,
  COMPACT_MODE_FORM_ELEMENT_LINE_HEIGHT,
} from 'constants/constants';

const InputForm = ({
  control,
  name,
  label,
  required,
  type = 'text',
  disabled = false,
  multiline,
  rows,
  inputProps,
  InputProps,
  defaultValue = '',
  placeholder,
  sx = {},
}: any) => {
  const {
    field: { ref, value, onChange, onBlur, ...props },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const { compactMode } = useAppInfo();

  const handleTrimOnBlur = (event: any) => {
    const trimmedValue = event.target.value.trim();
    onChange(trimmedValue);
    onBlur();
  };

  const maxLength = get(inputProps, 'maxLength', 0);
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const watchedItem = maxLength > 0 ? watch(name, '') : '';
  const countFromMaxLength =
    maxLength > 0 && watchedItem && watchedItem.length > 0
      ? ` (${watchedItem ? watchedItem.length : 0} / ${maxLength})`
      : '';

  let isError = false;
  let errorMessage = '';
  if (!isEmpty(errors) && get(errors, name) !== undefined) {
    isError = true;
    errorMessage = get(errors, `${name}.message`, '') as string;
  }
  return (
    <TextField
      {...props}
      value={value || ''}
      onChange={onChange}
      onBlur={handleTrimOnBlur}
      type={type}
      label={`${label}${countFromMaxLength}`}
      fullWidth={true}
      error={isError}
      helperText={errorMessage}
      margin="dense"
      size="small"
      InputLabelProps={{
        className: required ? 'required-label' : '',
        required: required || false,
        sx: compactMode
          ? {
              fontSize: COMPACT_MODE_FORM_ELEMENT_FONT_SIZE,
              lineHeight: COMPACT_MODE_FORM_ELEMENT_LINE_HEIGHT,
            }
          : {},
      }}
      inputRef={ref}
      placeholder={
        placeholder ? placeholder : /* compactMode ? formatLabelForRequired(label, required) : */ ''
      }
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      inputProps={{
        ...inputProps,
      }}
      InputProps={{
        ...InputProps,
        sx: compactMode
          ? {
              ...(multiline ? {} : { height: COMPACT_MODE_FORM_ELEMENT_HEIGHT }),
              fontSize: COMPACT_MODE_FORM_ELEMENT_FONT_SIZE,
              ...InputProps?.sx,
            }
          : { ...InputProps?.sx },
      }}
      sx={{}}
    />
  );
};

export default InputForm;
