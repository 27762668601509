import React from 'react';
import { useTranslation } from 'react-i18next';

import { IAnnouncement, IAnnouncementComponent, IAnnouncementForm } from './_types';
import { useNavigate } from 'react-router-dom';
import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { get } from 'lodash';
import useValidationSchema from './_form';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { Grid } from '@mui/material';

import { createAnnouncement, editAnnouncement } from './_api';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormSelect from 'components/Form/Select/Select';
import FormSwitch from 'components/Form/Switch/Switch';
import FormDatePicker from 'components/Form/Date/Date';
import FormInput from 'components/Form/Input/Input';
import { ISelectItem } from 'components/Form/Select/_types';
import { Line } from 'components/Line/Line';
import { SimpleCheckboxArray } from 'components/Form/SimpleCheckboxArray/SimpleCheckboxArray';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import useAlerts from 'components/Alerts/useAlerts';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';

import { GridDataType } from 'constants/constants';

const linkBack = '/administration/announcementsAndButtons?tab=announcements';
const apiDateFormat = 'yyyy-MM-dd';

const AnnouncementFormComponent: React.FC<IAnnouncementComponent> = ({
  isCreating,
  entity,
  workplaceItems,
  languages,
  importanceItems,
  defaultValues,
}) => {
  const { t } = useTranslation('Announcements');
  const navigate = useNavigate();
  const { title } = useWithEntityTitle(isCreating, entity, t);
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { compactMode } = useAppInfo();

  const { AnnouncementFormSchema, formItems } = useValidationSchema(t, languages);

  const methods = useForm<IAnnouncementForm>({
    resolver: yupResolver(AnnouncementFormSchema),
    defaultValues,
  });
  const { handleSubmit } = methods;

  const { toggleLoader } = useAppGlobals();

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const dateFrom = new Date(get(values, 'validFrom'));
    const dateTo = new Date(get(values, 'validTo'));
    let languageJsonText = {};
    (languages || []).forEach((lang: any) => {
      const abbr = get(lang, 'abbreviation');
      const key = `languageJsonText_${abbr}`;
      const value = get(values, key, null);
      languageJsonText = { ...languageJsonText, [abbr]: value };
    });
    const announcement: IAnnouncement = {
      id: get(entity, 'id', 0),
      active: get(entity, 'active', true),
      validFrom: `${format(dateFrom, apiDateFormat)}`,
      validTo: `${format(dateTo, apiDateFormat)}`,
      languageJsonText: JSON.stringify(languageJsonText),
      importance: values.importance,
      workPlaces: values.workPlaces,
    };
    const foo = isCreating ? createAnnouncement : editAnnouncement;
    const response = await foo(announcement);
    if (response) {
      addSuccessAlert(t('saved'));
      navigate(linkBack);
    } else {
      addErrorAlert(t('errorSaving'));
    }
    toggleLoader(false);
  });

  const defaultItems = {
    importance: importanceItems,
  };

  const defaultGrid: GridDataType = { xs: 12, md: 6, lg: 3, xl: 2 };

  return (
    <>
      <div>
        <Header title={title} />
        <Papeer>
          <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
              <Grid container={true} spacing={2}>
                {formItems.map((formItem, index) => {
                  const customGrid = get(formItem, 'grid', false)
                    ? (get(formItem, 'grid') as unknown as GridDataType)
                    : defaultGrid;

                  const type = get(formItem, 'type');
                  const name = get(formItem, 'name', '');
                  const items: ISelectItem[] = get(defaultItems, name, []);
                  const isRequired = get(formItem, 'required', false);
                  const isDisabled = get(formItem, 'disabled', false);
                  const label = get(formItem, 'label', '');

                  return (
                    <Grid key={`formItem-${index}`} item={true} {...customGrid}>
                      {type === 'select' ? (
                        <FormSelect
                          name={name}
                          label={label}
                          required={isRequired}
                          items={items}
                          disabled={isDisabled}
                        />
                      ) : type === 'switch' ? (
                        <FormSwitch name={name} label={label} disabled={isDisabled} />
                      ) : type === 'datePicker' ? (
                        <>
                          <FormDatePicker
                            name={name}
                            label={label}
                            isRequired={isRequired}
                            disabled={isDisabled}
                          />
                        </>
                      ) : (
                        <FormInput
                          name={name}
                          label={label}
                          type={type}
                          multiline={get(formItem, 'multiline', false)}
                          rows={get(formItem, 'rows', 1)}
                          required={isRequired}
                          disabled={isDisabled}
                          {...{ autoComplete: get(formItem, 'autoComplete') }}
                        />
                      )}
                    </Grid>
                  );
                })}
                <Grid item={true} xs={12}>
                  <Line />
                </Grid>
                <Grid item={true} xs={12}>
                  <SimpleCheckboxArray
                    name="workPlaces"
                    label={t('workPlaces')}
                    items={workplaceItems}
                    defaultValue={get(defaultValues, 'workPlaces', [])}
                    grid={compactMode ? {} : { xs: 12, md: 6, lg: 2, xl: 2 }}
                    // compactMode={compactMode}
                    required={true}
                    // showCheckAllButton={false}
                    // submitFailed={submitFailed}
                  />
                </Grid>
              </Grid>
              <Grid item={true} xs={12}>
                <Line />
              </Grid>
              <EntityButtons linkBack={linkBack} />
            </form>
          </FormProvider>
        </Papeer>
      </div>
    </>
  );
};

export default AnnouncementFormComponent;
