import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { get, isArray, isNumber, isObject, isString, sortBy } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { createNotification, getLists } from './_apiNotificationTemplates';
import {
  IEventType,
  IReactionType,
  INotificationTemplateForm,
  INotificationTemplateFormContent,
} from './_types';
import useValidationSchema from './_formNotificationTemplates';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormSelect from 'components/Form/Select/Select';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';

import { Line } from 'components/Line/Line';
import useAlerts from 'components/Alerts/useAlerts';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';

const NotificationTemplateForm: React.FC<INotificationTemplateFormContent> = ({
  toggleDialog,
  id,
  loadEntity,
  eventTypeId = null,
}) => {
  const { t } = useTranslation('NotificationTemplates');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const isCreating = id === 'create';
  const { title } = useWithEntityTitle(isCreating, null, t);

  const [eventTypes, fetchEventTypes] = useState<IEventType[]>([]);
  const [reactionTypes, fetchReactionTypes] = useState<IReactionType[]>([]);

  const { NotificationTemplateFormSchema } = useValidationSchema(t);

  const methods = useForm<INotificationTemplateForm>({
    resolver: yupResolver(NotificationTemplateFormSchema),
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const response = await createNotification(values);
    if (response === true) {
      addSuccessAlert(t('saved'));
      loadEntity();
      toggleDialog(false);
    } else {
      addErrorAlert(t(isString(response) ? response : 'errorSaving'));
    }
    toggleLoader(false);
  });

  const getEntity = async () => {
    toggleLoader();
    try {
      const lists = await getLists();
      if (isArray(get(lists, 'eventTypes'))) {
        fetchEventTypes(
          sortBy(
            get(lists, 'eventTypes').map((item: IEventType) => {
              const key = get(item, 'languageKey', '').toLowerCase();
              const tkey = t(key, { returnObjects: true });
              const label = isObject(tkey) ? t(`${key}.default`) : t(key);
              return { id: item.id, label, name: item.name };
            }),
            ['label'],
          ),
        );
      }
      if (isArray(get(lists, 'reactionTypes'))) {
        fetchReactionTypes(
          sortBy(
            get(lists, 'reactionTypes').map((item: IReactionType) => ({
              id: item.id,
              label: t(item.languageKey),
              name: item.name,
            })),
            ['label'],
          ),
        );
      }
      reset(isNumber(eventTypeId) ? { eventTypeID: eventTypeId.toString() } : {});
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header title={title} />
      <Papeer>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <Grid container={true} alignItems="flex-end">
              <Grid item={true} xs={12}>
                <FormSelect
                  name="eventTypeID"
                  label={t('eventType')}
                  items={eventTypes}
                  required={true}
                />
                <FormSelect
                  name="reactionTypeID"
                  label={t('reactionType')}
                  items={reactionTypes}
                  required={true}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Line />
              </Grid>
            </Grid>
            <EntityButtons toggleDialog={toggleDialog} />
          </form>
        </FormProvider>
      </Papeer>
    </>
  );
};

export default NotificationTemplateForm;
