import { useEffect, useState } from 'react';
import { IRestrictedSection } from './_types';
import { useUser } from 'utils/hooks/useUser';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { each, get, isArray, isString } from 'lodash';
import { IRole } from 'modules/Login/_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { RestrictedPage } from './RestrictedPage';

export const RestrictedSection: React.FC<IRestrictedSection> = ({ allowedRoles, Component }) => {
  const { hasRole } = useUser();
  const { lists } = useAppInfo();
  const roles: IRole[] = get(lists, 'roles');
  const { toggleLoader } = useAppGlobals();

  const [accessAllowed, setAccessAllowed] = useState<boolean | null>(null);

  useEffect(() => {
    if (isArray(roles) && roles.length) {
      toggleLoader();
      try {
        let isAllowed = false;

        if (isString(allowedRoles) && hasRole(allowedRoles)) {
          isAllowed = true;
        } else if (isArray(allowedRoles)) {
          each(allowedRoles, (role) => {
            if (hasRole(role)) {
              isAllowed = true;
            }
          });
        } else if (allowedRoles === undefined) {
          isAllowed = true;
        }

        setAccessAllowed(isAllowed);
        toggleLoader(false);
      } catch (e) {
        setAccessAllowed(false);
        toggleLoader(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  if (accessAllowed === null) {
    return null; // renders nothing, blank page
  }
  return accessAllowed ? <Component /> : <RestrictedPage allowedRoles={allowedRoles} />;
};
