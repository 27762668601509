import React from 'react';
import RequestsSearchForm from './RequestsSearchForm';
import RequestsResults from './RequestsResults';
import Header from 'components/Header/Header';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourRequests } from './TourRequests';

const RequestsSend: React.FC<any> = () => {
  const { title } = useWithTitle();
  const renderedSteps = () => {
    return <TourRequests type="main" />;
  };

  return (
    <>
      <Header title={title} addUrl="/requests/search" TourComponent={renderedSteps()} />
      <RequestsSearchForm />
      <RequestsResults />
    </>
  );
};

export default RequestsSend;
