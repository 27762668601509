import { Theme } from '@mui/material';

const styles = {
  detailIcon: {
    width: 24,
    height: 24,
    padding: 0,
  },
  gridLink: ({ palette }: Theme) => ({
    cursor: 'pointer',
    color: palette.mode === 'dark' ? palette.primary.light : palette.primary.dark,
    fontWeight: 'bold',
  }),
  activeButton: {
    maxHeight: 30,
  },
};

export default styles;
