import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { each, get, isArray, isDate, isEmpty, keys, omit, omitBy, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { getConfirmed } from '../_api';
import { IHistorySearch, IHistorySearchForm } from '../_types';
import { IUser } from 'modules/Administration/Users/_types';
import { findUser } from 'modules/Administration/Users/_api';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { generateIID, joinParams } from 'utils/study';
import { encryptId } from 'utils/hooks/useApp';
import { Papeer } from 'components/Papeer/Papeer';
import { CheckedButton } from 'components/Buttons/CheckedButton';
import FormAutocomplete from 'components/Form/Autocomplete/Autocomplete';
import { IAutocompleteOption } from 'components/Form/Autocomplete/_types';
import { ISelectItem } from 'components/Form/Select/_types';
import Button from 'components/Buttons/Button';
import FormSelect from 'components/Form/Select/Select';
import FormDatePicker from 'components/Form/Date/Date';
import {
  DATE_BTN_CUSTOM_DATE,
  DATE_BTN_LAST_MONTH,
  DATE_BTN_LAST_THREE_DAYS,
  DATE_BTN_LAST_WEEK,
  DATE_BTN_TODAY,
  DATE_BTN_YESTERDAY,
} from 'constants/constants';
import { convertSearchDates } from 'utils/search';
import { useStudy } from 'utils/hooks/useStudy';
import { useStorage } from 'utils/hooks/useStorage';
import { parseISO } from 'date-fns';

const apiDateFormatWithDash = 'yyyy-MM-dd';
const apiDateFormat = 'yyyyMMdd';

const FORM_DATA_KEY = 'historySearchForm';

const mainSx = { width: { xs: '100%', md: '50%', lg: '20%' } };

const HistorySearchForm: React.FC<IHistorySearchForm> = ({ setRows }) => {
  const refSubmitButtom = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('SearchForm');

  const [buttonType, setButtonType] = useState<string>('confirmationButton');
  const [sourceType, setSourceType] = useState<string>('');
  const [allUsers, setAllUsers] = useState<IAutocompleteOption[]>([]);
  const [hasCustomDate, setHasCustomDate] = useState(false);

  const { linkBack } = useStudy();
  const { getItem, putItem, removeItem } = useStorage();

  const backFromDetail = linkBack();

  const buttons: ISelectItem[] = [
    { id: 'confirmationButton', label: t('shreddingConfirmation') },
    { id: 'proposalButton', label: t('shreddingProposal') },
  ];
  const sources: ISelectItem[] = [
    { id: 'MASH', label: t('proposal.MASH') },
    { id: 'NIS', label: t('proposal.NIS') },
    { id: 'User', label: t('proposal.User') },
  ];

  const methods = useForm<IHistorySearch>({
    defaultValues: { date: DATE_BTN_CUSTOM_DATE, dateFrom: null, dateTo: null, selectUser: null },
  });

  const { handleSubmit, register, setValue, watch } = methods;
  const selectedDate = watch('date');
  const dateFrom = watch('dateFrom');
  const dateTo = watch('dateTo');
  const selectUser = watch('selectUser');
  const canSearch =
    (buttonType &&
      ((selectedDate && selectedDate !== 'customDate') ||
        (selectedDate === 'customDate' && (isDate(dateFrom) || isDate(dateTo))))) ||
    selectUser ||
    sourceType;

  const { toggleLoader } = useAppGlobals();

  const dateSelectItems = [
    DATE_BTN_TODAY,
    DATE_BTN_YESTERDAY,
    DATE_BTN_LAST_THREE_DAYS,
    DATE_BTN_LAST_WEEK,
    DATE_BTN_LAST_MONTH,
    DATE_BTN_CUSTOM_DATE,
  ].map((id) => ({ id, label: t(`SearchForm:${id}`) }));

  useEffect(() => {
    if (selectedDate === DATE_BTN_CUSTOM_DATE) {
      setHasCustomDate(true);
    } else {
      setHasCustomDate(false);

      // Set dates if selected date is not custom
      if (selectedDate) {
        const dateAttributes = convertSearchDates(selectedDate, dateFrom, dateTo, apiDateFormat);
        setValue('dateFrom', new Date(parseISO(dateAttributes.dateFrom)));
        setValue('dateTo', new Date(parseISO(dateAttributes.dateTo)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const submitHandler = async (values: any) => {
    toggleLoader();
    const selectedDate = get(values, 'date', null);
    callPersistForm(values);
    const userName = get(values, 'selectUser.value', null);
    let formValues = {
      ...values,
      sourceProposal: sourceType,
      userProposal: buttonType === 'proposalButton' && userName ? userName : null,
      userConfirmation: buttonType === 'confirmationButton' && userName ? userName : null,
    };
    if (selectedDate) {
      const dateAttributes = convertSearchDates(
        selectedDate,
        get(formValues, 'dateFrom', ''),
        get(formValues, 'dateTo', ''),
        apiDateFormatWithDash,
      );
      formValues = {
        ...formValues,
        dateFrom: buttonType === 'proposalButton' ? dateAttributes.dateFrom : null,
        dateTo: buttonType === 'proposalButton' ? dateAttributes.dateTo : null,
        dateConfirmationFrom: buttonType === 'confirmationButton' ? dateAttributes.dateFrom : null,
        dateConfirmationTo: buttonType === 'confirmationButton' ? dateAttributes.dateTo : null,
      };
    }
    const newValues = omitBy(omit(formValues, ['date', 'selectUser']), isEmpty);
    await getConfirmed(newValues).then((response) => {
      if (isArray(response)) {
        const confirmedRecords = response.map((result) => {
          const iid = generateIID(result.study);
          const hashIID = encryptId(iid);
          const proposalUser = get(result, 'user', null);
          const confirmationUser = get(result, 'userConfirmation', null);
          const source = result.sourceProposal;
          const user =
            source === 'User'
              ? ' ' +
                joinParams([get(proposalUser, 'lastName', ''), get(proposalUser, 'firstName', '')])
              : '';
          const userConfirmation = joinParams([
            get(confirmationUser, 'lastName', ''),
            get(confirmationUser, 'firstName', ''),
          ]);
          return {
            ...result.study,
            iid,
            hashIID,
            proposal: {
              confirmationDate: result.confirmationDate,
              createdDate: result.createdDate,
              id: result.id,
              requestId: result.requestId,
              sourceProposal: result.sourceProposal,
              source: t(`proposal.${source}`) + user,
              state: result.state,
              user: result.user,
              userConfirmation,
            },
          };
        });
        setRows(confirmedRecords);
      }
    });
    toggleLoader(false);
  };

  const triggerSubmit = () => {
    refSubmitButtom?.current?.click();
  };

  const setDateTypeFieldAndReset = () => {
    setValue('date', null);
    setValue('dateFrom', null);
    setValue('dateTo', null);
    setValue('selectUser', null);
  };

  const resetForm = () => {
    setButtonType('');
    setSourceType('');
    setDateTypeFieldAndReset();
  };

  const loadStart = async () => {
    toggleLoader();
    const allUsers = await findUser({});
    if (isArray(allUsers)) {
      const allSortUsers = sortBy(
        allUsers.map((item: IUser) => {
          const value = item.username;
          const label = `${joinParams([
            item.lastName,
            item.firstName,
            item.email ? '(' + item.email + ')' : '',
          ])}`;
          return { value, label };
        }),
        ['label'],
      );
      setAllUsers(allSortUsers);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadStart();

    if (backFromDetail) {
      const data = getItem(FORM_DATA_KEY);
      if (data) {
        // Parse it to a javaScript object
        try {
          const values = JSON.parse(data);
          const transformedValues = {
            ...values,
            dateFrom: values.dateFrom ? new Date(values.dateFrom) : null,
            dateTo: values.dateTo ? new Date(values.dateTo) : null,
          };
          each(keys(transformedValues), (key: any) => {
            setValue(key, get(transformedValues, key));
          });
          triggerSubmit();
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      removeItem(FORM_DATA_KEY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callPersistForm = (value: any) => {
    putItem(FORM_DATA_KEY, JSON.stringify(value));
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <button hidden={true} ref={refSubmitButtom} type={'submit'} />
          <input {...register('date')} type="hidden" />
          <input {...register('selectUser')} type="hidden" />
          <Papeer bottomMargin={true}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: {
                  xs: 'wrap',
                  lg: 'nowrap',
                },
                paddingRight: { xs: '0px', sm: 0 },
              }}
            >
              <Box
                sx={{ ...mainSx, mt: 1 }}
                data-tour="shreddings-history-confirmationProposalButton"
              >
                {buttons.map((button: ISelectItem) => (
                  <CheckedButton
                    active={buttonType === button.id}
                    key={button.id}
                    topMargin={false}
                    rightMargin={true}
                    onClick={() => {
                      if (buttonType === button.id) {
                        setDateTypeFieldAndReset();
                        setButtonType('');
                      } else {
                        setButtonType(button.id);
                      }
                    }}
                  >
                    {button.label}
                  </CheckedButton>
                ))}
              </Box>

              {buttonType && (
                <>
                  <Box sx={mainSx} data-tour="shreddings-history-authorSelector">
                    <FormAutocomplete
                      name="selectUser"
                      label={t('shreddingUser')}
                      options={allUsers}
                      placeholder={t('select')}
                      sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    />
                  </Box>
                  <Box sx={mainSx} data-tour="historySearchFormShreddingDate">
                    <FormSelect
                      name="date"
                      label={t('shreddingDate')}
                      items={dateSelectItems}
                      sx={{ borderRadius: 0 }}
                      clearable={true}
                      clearCallback={() => {
                        setValue('dateFrom', null);
                        setValue('dateTo', null);
                      }}
                    />
                  </Box>
                  <Box sx={mainSx} data-tour="historySearchFormShreddingDateFrom">
                    <FormDatePicker
                      name="dateFrom"
                      label={t('from')}
                      sx={{ borderRadius: 0 }}
                      disabled={!hasCustomDate}
                    />
                  </Box>
                  <Box sx={mainSx} data-tour="historySearchFormShreddingDateTo">
                    <FormDatePicker
                      name="dateTo"
                      label={t('to')}
                      sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                      disabled={!hasCustomDate}
                    />
                  </Box>
                </>
              )}
            </Box>

            <Grid container={true} spacing={2} alignItems="flex-start">
              <Grid item={true} xs={12} data-tour="shreddings-history-sourceShredding">
                {sources.map((source: ISelectItem) => (
                  <CheckedButton
                    active={sourceType === source.id}
                    key={source.id}
                    rightMargin={true}
                    onClick={() => setSourceType(sourceType === source.id ? '' : source.id)}
                  >
                    {source.label}
                  </CheckedButton>
                ))}
              </Grid>
            </Grid>

            <Grid container={true} spacing={2} alignItems="flex-end">
              <Grid item={true} xs={12}>
                <Box
                  id="search-results"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    '@media print': {
                      display: 'none',
                    },
                  }}
                >
                  <Box sx={{ mr: 1 }}>
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={resetForm}
                      data-tour="shreddings-common-clear"
                    >
                      {t('reset')}
                    </Button>
                  </Box>
                  <Box data-tour="shreddings-common-search">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!canSearch}
                      className="search-form-button"
                    >
                      {t('search')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Papeer>
        </form>
      </FormProvider>
    </>
  );
};

export default HistorySearchForm;
