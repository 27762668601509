import { createTheme } from '@mui/material';
import { get, isString, set } from 'lodash';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { theme as orcz } from './orcz';
import { theme as cktch } from './cktch';

export const useTheme = () => {
  const themes = { orcz, cktch };
  const { theme, mode, feConfigFromApi } = useAppInfo();
  const currentTheme = get(themes, `${theme}`, {});

  const primaryColorFromApi = feConfigFromApi?.primaryColor;

  if (isString(primaryColorFromApi)) {
    set(currentTheme, ['dark', 'palette', 'primary', 'main'], primaryColorFromApi);
    set(currentTheme, ['light', 'palette', 'primary', 'main'], primaryColorFromApi);
    set(currentTheme, ['variables', 'primaryColor'], primaryColorFromApi);
  }

  return {
    theme: createTheme(get(currentTheme, mode)),
    variables: get(currentTheme, 'variables', {}),
  };
};
