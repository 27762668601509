import HelperComponent from 'components/Tour/HelperComponent';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

export const TourStudiesBasket = () => {
  const { t } = useTranslation('Reactour');

  const allSteps = () => {
    const steps = {
      '[data-tour="studiesBasketActionButton"]': t('basket.buttons'),
      '[data-tour="search-header-searchForm-searchResultGrid"]': t('basket.grid'),
      '[data-tour="common-gridActions"]': t('basket.deleteButtons'),
      '[data-tour="common-gridColumns"]': t('common.gridColumns'),
      '[data-tour="common-gridFilters"]': t('common.gridFilters'),
    };
    return steps;
  };

  useReactTour({ stepsDefinition: allSteps });

  return <HelperComponent />;
};
