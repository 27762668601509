import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { isArray, isEmpty, omitBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { findTests } from './_api';
import { Papeer } from 'components/Papeer/Papeer';
import FormSelect from 'components/Form/Select/Select';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useActions } from 'utils/hooks/useActions';
import { useStudy } from 'utils/hooks/useStudy';
import useRefetchGrid from 'utils/grid/useRefetchGrid';
import { useTestLists } from 'utils/hooks/useTestLists';
import Button from 'components/Buttons/Button';
import { useAppInfo } from 'utils/hooks/useAppInfo';

const ManageTestsSearchForm: React.FC<any> = ({
  forStartTest,
  shouldShowStateField = true,
  triggerSubmit,
}) => {
  const { t } = useTranslation('Tests');
  const { compactMode } = useAppInfo();
  const { refetchGrid } = useRefetchGrid();
  const { storeTests } = useActions();
  const { mappedTypes, mappedStates } = useTestLists(t, true);
  const { linkBack } = useStudy();

  const methods = useForm<any>({});

  const { handleSubmit, reset, register } = methods;

  const { toggleLoader } = useAppGlobals();

  const backFromDetail = linkBack();

  const submitHandler = async (values: any) => {
    toggleLoader();
    const transformedValues = omitBy(values, isEmpty);
    const resp = await findTests(transformedValues, forStartTest);
    storeTests([]);
    if (isArray(resp) && !isEmpty(resp)) {
      storeTests(resp);
    }

    refetchGrid();
    toggleLoader(false);
  };

  const loadEntities = async () => {
    toggleLoader();
    storeTests([]);
    refetchGrid();

    if (!backFromDetail) {
      reset({
        forStartTest,
      });
    }
    if (!backFromDetail) {
      setTimeout(() => {
        triggerSubmit();
      }, 250);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    loadEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(submitHandler)}
          data-tour={shouldShowStateField ? 'testsSearchForm' : 'testsStartForm'}
        >
          <input {...register('forStartTest')} type="hidden" />
          <Papeer bottomMargin={true}>
            <Grid container={true} spacing={0}>
              <Grid item={true} xs={12} md={6}>
                <Grid container={true} spacing={compactMode ? 1 : 2}>
                  <Grid item={true} xs={12} md={6} lg={4}>
                    <FormSelect name="type" label={t('type')} items={mappedTypes} />
                  </Grid>
                  {shouldShowStateField && (
                    <Grid item={true} xs={12} md={6} lg={4}>
                      <FormSelect name="state" label={t('state')} items={mappedStates} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                item={true}
                xs={12}
                md={6}
                justifyContent="flex-end"
                alignItems="flex-end"
                display="flex"
              >
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="search-form-button"
                  >
                    {t('search')}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Papeer>
        </form>
      </FormProvider>
    </>
  );
};

export default ManageTestsSearchForm;
