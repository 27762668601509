import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, Grid, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { get, isArray, isEmpty, omit, sortBy } from 'lodash';
import { Delete as DeleteIcon, Help as HelpIcon } from '@mui/icons-material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getFolder, editFolder, createFolder, removeUser, updateUser } from './_api';
import { IFolderForm } from './_types';
import useValidationSchema from './_form';
import UserForm from './UserForm';
import { ExpiratedDateButtons } from './ExpiratedDateButtons';
import { findUser } from '../Users/_api';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { useEntityRemove2 } from 'utils/hooks/useEntityRemove2';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { encryptId } from 'utils/hooks/useApp';
import { settingHasValue } from 'utils/products';
import { joinParams } from 'utils/study';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import useAlerts from 'components/Alerts/useAlerts';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { DELETE_USER_FROM_FOLDER, USER_PERMISSION } from 'constants/constants';
import { TourFolders } from './TourFolders';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'folderFormMUI';

export const FolderForm: React.FC = () => {
  const { t } = useTranslation('Folders');
  const dispatch = useAppDispatch();
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { id, isCreating } = useEntityInfo(true);
  const { confirmationData, portalProductSettings } = useAppInfo();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const [entity, setEntity] = useState<IFolderForm>();
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean | null>(null);
  const [dialogState, toggleDialog] = useState<boolean>(false);
  const [users, setUsers] = useState<any[]>([]);

  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const from = searchParams.get('from') || null;
  const linkBack = `${from === 'view' ? '' : '/administration'}/folders?action=back`;

  const { title } = useWithEntityTitle(isCreating, entity, t);

  const foldersWithExpiration = isEmpty(portalProductSettings)
    ? false
    : settingHasValue(portalProductSettings, 'foldersWithExpiration', 'true');

  const { FolderFormSchema } = useValidationSchema();

  const methods = useForm<IFolderForm>({
    resolver: yupResolver(FolderFormSchema),
  });
  const { handleSubmit, reset, register, setValue, watch } = methods;
  const selectedExpiration = watch('expiration');
  const setExpiration = (expiration: any) => setValue('expiration', expiration);

  const loadEntityByApi = async (folderId: string) => {
    const entity = await getFolder(folderId);
    if (!isEmpty(entity)) {
      setEntity(entity);
    }
    return entity;
  };
  const prepareValues = (values: any) => {
    const users = get(values, 'users', []);
    const preparedValues = isCreating
      ? { ...values, users, expiration: get(values, 'expiration', null) || null }
      : {
          ...omit(values, ['users']),
          users: get(entity, 'users', []),
          expiration: get(values, 'expiration', null) || null,
        };
    return preparedValues;
  };

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const fn = isCreating ? createFolder : editFolder;
    await fn(prepareValues(values)).then(
      (response) => {
        addSuccessAlert(t('saved'));
        const folderId = get(response, 'data.id', 0);
        if (isCreating) {
          loadEntityByApi(folderId);
        }
        navigate(
          isCreating
            ? `${from === 'view' ? '' : '/administration'}/folders/${encryptId(
                folderId,
              )}?from=${from}`
            : linkBack,
        );
      },
      (error) => {
        let tError = 'errorSaving';
        if (get(error, 'response.data', '') === 'error.administration.folder.exists') {
          tError = 'errorFolderExists';
        }
        addErrorAlert(t(tError));
      },
    );
    toggleLoader(false);
  });

  const loadEntity = async (folderId: string) => {
    toggleLoader();
    try {
      let folder: IFolderForm = { name: '', expiration: '' };
      if (!isCreating && folderId) {
        const entity = await loadEntityByApi(folderId);
        if (!isEmpty(entity)) {
          folder = { ...entity };
          setUsers(
            sortBy(
              get(entity, 'users', []).map((item: any) => ({
                ...item,
                folderId: get(entity, 'id'),
              })),
              ['name'],
            ),
          );
        }
      }
      reset({ ...folder });
      setIsFetchingEntity(false);
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  const getEntities = async () => {
    toggleLoader();
    try {
      const allUsers = await findUser({});
      if (isArray(allUsers)) {
        setAllUsers(allUsers);
      }

      await loadEntity(id.toString());
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { onEntityRemove2 } = useEntityRemove2(removeUser, t, loadEntity);

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };
  const confirmAction = async () => {
    await onEntityRemove2(get(confirmationData, 'folderId'), get(confirmationData, 'id'));

    clearAction();
  };
  const onChangeSelect = async (row: any, permission: string) => {
    toggleLoader();
    const folderId = get(row, 'folderId');
    const values = {
      permission,
      username: get(row, 'username'),
    };
    await updateUser(folderId, values).then(
      (response) => {
        addSuccessAlert(t('updateUserPermission'));
        loadEntity(folderId);
      },
      (error) => {
        addErrorAlert(t('errorUpdateUserPermission'));
      },
    );
    toggleLoader(false);
  };
  const confirmActionChangeSelect = async () => {
    await onChangeSelect(get(confirmationData, 'id'), get(confirmationData, 'permission'));

    clearAction();
  };

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      [
        {
          field: 'actions',
          headerName: t('Grid:permissions'),
          type: 'actions',
          hideable: false,
          width: 250,
          renderCell: ({ row }: GridRenderCellParams) => (
            <>
              <Box sx={{ width: '200px' }}>
                <FormControl variant="standard">
                  <Select
                    value={row.permission}
                    onChange={(event: any) => {
                      dispatch(
                        storeConfirmationDataToStore({
                          title: 'confirmChangePermission',
                          id: row,
                          permission: event.target.value,
                          confirmAction: 'confirmActionChangeSelect',
                        }),
                      );
                    }}
                    inputProps={{
                      name: 'permission',
                      id: 'permission-simple',
                    }}
                    style={{ fontSize: 'small' }}
                  >
                    {USER_PERMISSION.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {t(item.label)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:removeUserFromFolder')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('Grid:removeUserFromFolder')}
                onClick={() => {
                  dispatch(
                    storeConfirmationDataToStore({
                      folderId: get(row, 'folderId'),
                      id: get(row, 'id'),
                      title: 'confirmRemoveUserFromFolder',
                      confirmAction: DELETE_USER_FROM_FOLDER,
                    }),
                  );
                }}
                disabled={!get(row, 'canDelete', true)}
              />
            </>
          ),
        },
        {
          field: 'fullname',
          headerName: t('fullname'),
          valueGetter: (value: any, row: any) =>
            `${joinParams([get(row, 'lastName'), get(row, 'firstName')])}`,
        },
        { field: 'email', headerName: t('email') },
      ],
      200,
    ),
  );

  const RenderedGrid = (
    <>
      <Box data-tour="folderUsersAdd">
        <Header
          title={t('users')}
          toggleDialog={toggleDialog}
          toggleDialogText={t('userAdd')}
          topMargin={true}
        />
      </Box>

      <MuiGrid
        gridKey={muiGridKey}
        rows={users}
        columns={columns}
        dataTour="folderUsersGrid"
        initialSortMode={[{ field: 'fullname', sort: 'asc' }]}
      />
    </>
  );

  const RenderedExpiration = foldersWithExpiration ? (
    <Grid container={true} alignItems="flex-end">
      <Grid item={true} xs={12}>
        <div>
          <Typography component="span">
            {t('expiration')}
            <Tooltip title={t('expirationHelp') as string} style={{ cursor: 'pointer' }}>
              <HelpIcon fontSize="small" />
            </Tooltip>
          </Typography>
          <ExpiratedDateButtons
            expiration={selectedExpiration || ''}
            setExpiration={setExpiration}
            compactMode={true}
          />
        </div>
      </Grid>
    </Grid>
  ) : null;

  const renderedSteps = () => {
    return <TourFolders type="edit" />;
  };

  return (
    <>
      {!isFetchingEntity && isFetchingEntity !== null ? (
        <>
          <Header title={title} TourComponent={!isCreating && renderedSteps()} />
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit} data-tour="folderEditForm">
                <input {...register('id')} type="hidden" />
                <input {...register('expiration')} type="hidden" />
                <Grid container={true} alignItems="flex-end">
                  <Grid item={true} xs={12} md={6} lg={3} xl={3}>
                    <FormInput name="name" label={t('name')} required={true} />
                  </Grid>
                </Grid>
                {RenderedExpiration}
                <Grid container={true} alignItems="flex-end">
                  <Grid item={true} xs={12}>
                    <EntityButtons
                      linkBack={linkBack}
                      otherStyles={foldersWithExpiration ? { marginTop: 16 } : {}}
                    />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Papeer>
          {!isCreating && <>{RenderedGrid}</>}
          <OverflowedDialog
            title={t('dialog.user')}
            open={dialogState}
            onClose={() => toggleDialog(false)}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            fullWidth={true}
          >
            <UserForm
              dialogState={dialogState}
              toggleDialog={toggleDialog}
              users={allUsers}
              selectedUsers={get(entity, 'users', [])}
              folderId={get(entity, 'id', '0')}
              loadEntity={loadEntity}
            />
          </OverflowedDialog>
        </>
      ) : (
        isFetchingEntity !== null && (
          <Papeer>
            <Typography>{t('folderNotFound')}</Typography>
          </Papeer>
        )
      )}
      {confirmationData &&
        confirmationData.id &&
        (confirmationData.confirmAction === DELETE_USER_FROM_FOLDER ||
          confirmationData.confirmAction === 'confirmActionChangeSelect') && (
          <ConfirmationDialog
            title={t(`Grid:${confirmationData.title}`)}
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            cancelAction={clearAction}
            confirmAction={
              confirmationData.confirmAction === 'confirmActionChangeSelect'
                ? confirmActionChangeSelect
                : confirmAction
            }
          />
        )}
    </>
  );
};
