import { Column } from '@devexpress/dx-react-grid';
import { TFunction } from 'i18next';

export const useColumns = (columns: Column[], t: TFunction, name = 'cols') => {
  const translateColumns = (columns: Column[], t: any) =>
    columns.map((col: Column) => ({ ...col, title: t(col.title) }));

  return {
    [name]: translateColumns(columns, t),
  };
};
