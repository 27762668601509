import { useParams } from 'react-router';
import { get, isNumber, split } from 'lodash';
import { decryptId } from './useApp';

export const useEntityInfo = (hashedId: boolean = false) => {
  const params = useParams();

  const paramsId = split(get(params, 'id'), '?', 1)[0];
  const isCreating = typeof paramsId === 'string' && paramsId === 'create';
  const id: number | string = isCreating
    ? 'create'
    : isNumber(paramsId)
    ? parseInt(paramsId, 10)
    : paramsId;

  const id2 = !isCreating && hashedId ? parseInt(decryptId(id), 10) : id;
  const paramsPatientId = get(params, 'patientId');
  const paramsAccessionNumber = get(params, 'accessionNumber');

  return { id: id2, isCreating, paramsPatientId, paramsAccessionNumber, paramsId };
};
