import { get } from 'lodash';
import { format } from 'date-fns';
import { TFunction } from 'i18next';

import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useLanguage } from 'utils/hooks/useLanguage';
import { joinParams } from 'utils/study';

const formatDateTime = 'dd. MM. yyyy, HH:mm:ss';
const formatDate = 'dd. MM. yyyy';

const useItemsForOrderDetail = (order: any, t: TFunction, hasRole: any, isFromStudy: boolean) => {
  const { currentLocale } = useLanguage();
  const { portalSetting } = useAppInfo();

  const canImport = hasRole('ROLE_IMPORT_MWL');
  const fieldsForPatient = [
    {
      name: 'name',
      value: `${joinParams([
        get(order, 'patient.name.prefix'),
        get(order, 'patient.name.lastName'),
        get(order, 'patient.name.firstName'),
        get(order, 'patient.name.middleName'),
        get(order, 'patient.name.suffix'),
      ])}
          (${joinParams(
            [
              joinParams(
                [t('identificationNumber'), get(order, 'patient.identificationNumber', '-')],
                ': ',
              ),
              joinParams([t('sex'), t(`sex_${get(order, 'patient.sex', 'O')}`)], ': '),
              get(order, 'patient.dateBirth')
                ? joinParams(
                    [t('dateBirth'), format(new Date(get(order, 'patient.dateBirth')), formatDate)],
                    ': ',
                  )
                : null,
              get(order, 'healthInsuranceCompany')
                ? joinParams(
                    [
                      t('healthInsuranceCompany'),
                      get(
                        order,
                        `healthInsuranceCompany.text.${currentLocale}`,
                        get(order, 'healthInsuranceCompany.value', ''),
                      ),
                    ],
                    ': ',
                  )
                : null,
            ],
            ' | ',
          )})`,
      lg: 6,
    },
  ];

  const fieldsForOrder = [
    {
      name: 'accessionNumber',
      label: t('accessionNumber'),
      value: `${get(order, 'accessionNumber') ? get(order, 'accessionNumber') : ''}`,
    },
    {
      name: 'originalDatetimeRequested',
      label: t('originalDatetimeRequested'),
      value: get(order, 'originalDatetimeRequested')
        ? `${format(new Date(get(order, 'originalDatetimeRequested', '')), formatDateTime)}`
        : '',
    },
    ...(get(order, 'expertness.value', '') || !isFromStudy
      ? [
          {
            name: 'expertness',
            label: t('expertness'),
            value: get(
              order,
              `expertness.text.${currentLocale}`,
              get(order, 'expertness.value', ''),
            ),
          },
        ]
      : []),
    ...(get(order, 'diagnosisBasic', '') || !isFromStudy
      ? [
          {
            name: 'diagnosisBasic',
            label: t('diagnosisBasic'),
            value: get(order, 'diagnosisBasic', ''),
          },
        ]
      : []),
    ...(get(order, 'diagnosisOther', '') || !isFromStudy
      ? [
          {
            name: 'diagnosisOther',
            label: t('diagnosisOther'),
            value: get(order, 'diagnosisOther', ''),
          },
        ]
      : []),
    ...(get(portalSetting, 'seeRequestingPhysician', null) &&
    (get(order, 'requestingPhysician.name.lastName', '') || !isFromStudy)
      ? [
          {
            name: 'requestingPhysician',
            label: t('requestingPhysician'),
            value: `${joinParams([
              get(order, 'requestingPhysician.name.prefix'),
              get(order, 'requestingPhysician.name.lastName'),
              get(order, 'requestingPhysician.name.firstName'),
              get(order, 'requestingPhysician.name.middleName'),
              get(order, 'requestingPhysician.name.suffix'),
            ])}`,
          },
        ]
      : []),
    ...(get(portalSetting, 'seeReferringPhysician', null) &&
    (get(order, 'referringPhysician.name.lastName', '') || !isFromStudy)
      ? [
          {
            name: 'referringPhysician',
            label: t('referringPhysician'),
            value: `${joinParams([
              get(order, 'referringPhysician.name.prefix'),
              get(order, 'referringPhysician.name.lastName'),
              get(order, 'referringPhysician.name.firstName'),
              get(order, 'referringPhysician.name.middleName'),
              get(order, 'referringPhysician.name.suffix'),
            ])}`,
          },
        ]
      : []),
    {
      name: 'priority',
      label: t('priority'),
      value: get(order, `priority.text.${currentLocale}`, get(order, 'priority.value', '')),
    },
    ...(get(order, 'requestedProcedure', '') || !isFromStudy
      ? [
          {
            name: 'requestedProcedure',
            label: t('requestedProcedure'),
            value: get(order, 'requestedProcedure', ''),
          },
        ]
      : []),

    ...((get(order, 'patientDispositions.size', '') &&
      get(order, 'patientDispositions.size') !== 'null') ||
    !isFromStudy
      ? [
          {
            name: 'size',
            label: t('detail_size'),
            value:
              get(order, 'patientDispositions.size') &&
              get(order, 'patientDispositions.size') !== 'null'
                ? `${get(order, 'patientDispositions.size', '')} m`
                : '',
          },
        ]
      : []),
    ...((get(order, 'patientDispositions.weight', '') &&
      get(order, 'patientDispositions.weight') !== 'null') ||
    !isFromStudy
      ? [
          {
            name: 'weight',
            label: t('detail_weight'),
            value:
              get(order, 'patientDispositions.weight') &&
              get(order, 'patientDispositions.weight') !== 'null'
                ? `${get(order, 'patientDispositions.weight', '')} kg`
                : '',
          },
        ]
      : []),
    ...(get(order, 'modality.name', '') || !isFromStudy
      ? [
          {
            name: 'modality',
            label: t('modality'),
            value: get(order, 'modality.name', ''),
          },
        ]
      : []),
    ...(get(order, 'station.code', '') || !isFromStudy
      ? [
          {
            name: 'station',
            label: t('station'),
            value: get(order, 'station.code', ''),
          },
        ]
      : []),
    ...(get(order, 'unit.name', '') || !isFromStudy
      ? [
          {
            name: 'unit',
            label: t('unit'),
            value: get(order, 'unit.name', ''),
          },
        ]
      : []),
    ...(get(order, 'icp', '') || !isFromStudy
      ? [
          {
            name: 'icp',
            label: t('icp'),
            value: get(order, 'icp', ''),
          },
        ]
      : []),
  ];

  const fieldsForPrint = [
    {
      name: 'accessionNumber',
      label: t('accessionNumber'),
      value: `${get(order, 'accessionNumber') ? get(order, 'accessionNumber', '-') : '-'}`,
    },
    {
      name: 'patientName',
      label: t('print.patientName'),
      value: `${joinParams([
        get(order, 'patient.name.prefix'),
        get(order, 'patient.name.lastName'),
        get(order, 'patient.name.firstName'),
        get(order, 'patient.name.middleName'),
        get(order, 'patient.name.suffix'),
      ])}`,
    },
    {
      name: 'identificationNumber',
      label: t('identificationNumber'),
      value: `${get(order, 'patient.identificationNumber', '-')}`,
    },
    {
      name: 'healthInsuranceCompany',
      label: t('healthInsuranceCompany'),
      value: get(
        order,
        `healthInsuranceCompany.text.${currentLocale}`,
        get(order, 'healthInsuranceCompany.value', '-'),
      ),
    },
    {
      name: 'diagnosisBasic',
      label: t('diagnosisBasic'),
      value: get(order, 'diagnosisBasic') ? get(order, 'diagnosisBasic', '-') : '-',
    },
    {
      name: 'requestedProcedure',
      label: t('requestedProcedure'),
      value: get(order, 'requestedProcedure') ? get(order, 'requestedProcedure', '-') : '-',
    },
    ...(get(portalSetting, 'seeRequestingPhysician', null)
      ? [
          {
            name: 'requestingPhysician',
            label: t('requestingPhysician'),
            value: `${
              joinParams([
                get(order, 'requestingPhysician.name.prefix'),
                get(order, 'requestingPhysician.name.lastName'),
                get(order, 'requestingPhysician.name.firstName'),
                get(order, 'requestingPhysician.name.middleName'),
                get(order, 'requestingPhysician.name.suffix'),
              ])
                ? joinParams([
                    get(order, 'requestingPhysician.name.prefix'),
                    get(order, 'requestingPhysician.name.lastName'),
                    get(order, 'requestingPhysician.name.firstName'),
                    get(order, 'requestingPhysician.name.middleName'),
                    get(order, 'requestingPhysician.name.suffix'),
                  ])
                : '-'
            }`,
          },
        ]
      : []),
    ...(get(portalSetting, 'seeReferringPhysician', null)
      ? [
          {
            name: 'referringPhysician',
            label: t('referringPhysician'),
            value: `${
              joinParams([
                get(order, 'referringPhysician.name.prefix'),
                get(order, 'referringPhysician.name.lastName'),
                get(order, 'referringPhysician.name.firstName'),
                get(order, 'referringPhysician.name.middleName'),
                get(order, 'referringPhysician.name.suffix'),
              ])
                ? joinParams([
                    get(order, 'referringPhysician.name.prefix'),
                    get(order, 'referringPhysician.name.lastName'),
                    get(order, 'referringPhysician.name.firstName'),
                    get(order, 'referringPhysician.name.middleName'),
                    get(order, 'referringPhysician.name.suffix'),
                  ])
                : '-'
            }`,
          },
        ]
      : []),
    {
      name: 'expertness',
      label: t('expertness'),
      value: get(order, `expertness.text.${currentLocale}`, get(order, 'expertness.value', '-')),
    },
    {
      name: 'icp',
      label: t('icp'),
      value: get(order, 'icp') ? get(order, 'icp', '-') : '-',
    },
  ];
  const setAction = {
    // TODO - nastavit po zprovoznění jednotlivých funkcí
    update: {
      disable: !hasRole('ROLE_MWL_UPDATE'),
    },
    addComment: {
      disable: !hasRole('ROLE_MWL_COMMENTARY_ALL') && !hasRole('ROLE_MWL_COMMENTARY_MINE'),
    },
    addAttachment: {
      disable: !hasRole('ROLE_MWL_ATTACHMENT_ALL') && !hasRole('ROLE_MWL_ATTACHMENT_MINE'),
    },
    print: { disable: false },
  };

  return { canImport, fieldsForPatient, fieldsForOrder, fieldsForPrint, setAction };
};

export default useItemsForOrderDetail;
