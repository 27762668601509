import Header from 'components/Header/Header';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IStudyResultForGrid } from 'modules/Search/_types';
import { Box, IconButton, Tooltip } from '@mui/material';
import TagsIcon from '@mui/icons-material/LabelOutlined';
import { FormProvider, useForm } from 'react-hook-form';
import { each, get, isEmpty, join, keys, uniq } from 'lodash';
import { useSubmitDocumentsByTagForm } from 'utils/hooks/useSubmitDocumentsByTag';
import { TagFormSearch } from 'components/TagForm/TagFormSearch';
import { ISearchArchiveTagFormValues, ISearchArchiveTagRequest } from '../_types';
import { FormType } from 'components/TagForm/_types';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourIntegrationPlatform } from './TourIntegrationPlatform';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { joinParams } from 'utils/study';
import { format } from 'date-fns';
import styles from 'components/SearchResultsMUI/_styles';
import { useActions } from 'utils/hooks/useActions';
import { useStorage } from 'utils/hooks/useStorage';
import { useStudy } from 'utils/hooks/useStudy';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const FORM_DATA_KEY = 'documentsByTags';
const muiGridKey = 'documentByTagsMUI';

export const DocumentsByTags = () => {
  const refSubmitButtom = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('ClinicalPortal');
  useWithTitle(); // sets title to document
  const { getItem, putItem, removeItem } = useStorage();
  const { storeSelection } = useActions();
  const navigate = useNavigate();
  const { linkBack } = useStudy();
  const backFromDetail = linkBack();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);
  const [rows, setRows] = useState<IStudyResultForGrid[]>([]);

  const showDocuments = async (row: any) => {
    const documents = [row];
    const pathnameForLinkBack = window.location.pathname.replace('portal/', '');
    storeSelection(documents);
    navigate(`/clinicPortal/tag/document?pathname=${pathnameForLinkBack}`);
  };

  const getUniqTagsArray = (documentTags: any) => {
    let uniqTagsArray;
    const tagsArray: any[] = [];
    try {
      documentTags.forEach((documentTag: any) =>
        get(documentTag, 'value', '')
          .split('*^*')
          .forEach((item: any) => {
            tagsArray.push(
              join(
                item
                  .substr(1)
                  .split('#')
                  .map((tag: any) => {
                    return tag;
                  }),
                ' > ',
              ),
            );
          }),
      );
      if (!isEmpty(tagsArray)) {
        uniqTagsArray = uniq(tagsArray).sort((a, b) => {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
      }
    } catch (e) {
      console.debug(e);
    }
    return uniqTagsArray;
  };
  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      [
        {
          field: 'tags',
          headerName: t('tags'),
          valueGetter: (value: any, row: any) => {
            const documentTags = get(row, 'documentTags') || [];
            return getUniqTagsArray(documentTags);
          },
          renderCell: ({ row }: GridCellParams) => {
            const documentTags = get(row, 'documentTags') || [];
            const uniqTagsArray = getUniqTagsArray(documentTags);
            return !isEmpty(uniqTagsArray) ? (
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Tooltip
                  title={
                    <>
                      {(uniqTagsArray || []).map((tag: string) => (
                        <div key={tag}>{tag}</div>
                      ))}
                    </>
                  }
                  placement="right"
                >
                  <IconButton style={{ width: 24, height: 24, padding: 0 }}>
                    <TagsIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ) : null;
          },
        },
        {
          field: 'typeOfDocumentName',
          headerName: t('typeOfDocument'),
          valueGetter: (value: any, row: any) => get(row, 'typeOfDocumentName', ''),
          renderCell: ({ row }: GridCellParams) => {
            const typeOfDocumentName = get(row, 'typeOfDocumentName', '') || '';
            return (
              <Tooltip title={t('showDetail')}>
                <Box onClick={() => showDocuments(row)} sx={styles.gridLink}>
                  {typeOfDocumentName}
                </Box>
              </Tooltip>
            );
          },
        },
        {
          field: 'name',
          headerName: t('name'),
          valueGetter: (value: any, row: any) =>
            joinParams([
              get(row, 'patient.patientLastName', ''),
              get(row, 'patient.patientFirstName', ''),
            ]),
        },
        {
          field: 'patientID',
          headerName: t('patientID'),
          valueGetter: (value: any, row: any) => get(row, 'patient.patientID', ''),
        },
        {
          field: 'patientBirthDate',
          headerName: t('patientBirthDate'),
          width: 125,
          type: 'date',
          renderCell: ({ row }: GridRenderCellParams) =>
            get(row, 'patient.patientBirthDate', '')
              ? format(new Date(get(row, 'patient.patientBirthDate', '')), 'dd.MM.yyyy')
              : '',
          valueGetter: (value: any, row: any) => {
            try {
              return new Date(get(row, 'patient.patientBirthDate', ''));
            } catch (e) {
              return null;
            }
          },
        },
        {
          field: 'createdDateTime',
          headerName: t('createdDateTime'),
          type: 'date',
          valueGetter: (value: any) => value && new Date(value),
          valueFormatter: (value: any) => value && format(value, 'dd. MM. yyyy'),
        },
        { field: 'author', headerName: t('author') },
      ],
      220,
    ),
  );

  const { onSubmitDocumentsByTagForm } = useSubmitDocumentsByTagForm(rows, setRows);

  const methods = useForm<ISearchArchiveTagFormValues>({});
  const { handleSubmit, setValue } = methods;

  const prepareSearchValues = (values: ISearchArchiveTagFormValues) => {
    const requestArray: { id: number }[] = [];
    const tempTags = values.registerItems ? Object.values(values.registerItems) : [];
    tempTags.forEach((tags: any) => {
      if (!isEmpty(tags)) {
        tags.forEach((tag: any) => {
          requestArray.push({
            id: tag.value,
          });
        });
      }
    });
    return { registerItems: requestArray };
  };

  const onSubmit = async (requestData: ISearchArchiveTagRequest) => {
    onSubmitDocumentsByTagForm(requestData);
  };

  const callPersistForm = (value: any) => {
    putItem(FORM_DATA_KEY, JSON.stringify(value));
  };

  const triggerSubmit = () => {
    refSubmitButtom?.current?.click();
  };

  useEffect(() => {
    try {
      if (backFromDetail) {
        const data = getItem(FORM_DATA_KEY);
        if (data) {
          // Parse it to a javaScript object
          const values = JSON.parse(data);
          each(keys(values), (key: any) => {
            if (key === 'registerItems') {
              setValue('registerItems.active', get(values, 'registerItems.active'));
              setValue('registerItems.inactive', get(values, 'registerItems.inactive'));
            } else {
              setValue(key, get(values, key));
            }
          });
          triggerSubmit();
        }
      } else {
        removeItem(FORM_DATA_KEY);
      }
    } catch (e) {
      console.debug(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderedSteps = () => {
    return <TourIntegrationPlatform hasRows={!!rows.length} />;
  };

  return (
    <>
      <Header title={t('Menu:documentsByTags')} TourComponent={renderedSteps()} />
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit((values) => {
            callPersistForm(values);
            const transformedValues = prepareSearchValues(values);
            onSubmit(transformedValues);
          })}
        >
          <button hidden={true} ref={refSubmitButtom} type={'submit'} />
          <TagFormSearch formType={FormType.Default} />
        </form>
      </FormProvider>
      <MuiGrid
        gridKey={muiGridKey}
        rows={rows}
        columns={columns}
        dataTour="integrationPlatformGrid"
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
      />
    </>
  );
};
