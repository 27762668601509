import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { concat, get, isArray, isBoolean, isDate, isEmpty, isUndefined } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/system';
import { Badge, IconButton } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import useValidationSchema from './_form';
import { ISearchFormInner } from './_types';
import { getAllGroups } from 'modules/Tags/_api';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import FormMultiSelect from 'components/Form/MultiSelect/MultiSelect';
import FormDatePicker from 'components/Form/Date/Date';
import { ISelectItem } from 'components/Form/Select/_types';
import FormSwitch from 'components/Form/Switch/Switch';
import { SimpleCheckboxArray } from 'components/Form/SimpleCheckboxArray/SimpleCheckboxArray';
import { Papeer } from 'components/Papeer/Papeer';
import { InputType } from 'constants/constants';
import { SearchFormTags } from './SearchFormTags';
import Button from 'components/Buttons/Button';
import { useDispatch } from 'react-redux';
import { setTourControlPropsToStore } from 'store/reducers/appReducer';

const mainSx = { width: { xs: '100%', md: '50%', lg: '20%' } };
const smallScreenButtonWidth = { minWidth: { xs: 120, sm: 'auto' } };

export const SearchFormInner: React.FC<ISearchFormInner> = ({
  sources,
  searchFieldOpened,
  setSearchFieldOpened,
  hasCustomDate,
  useForOtherForm = false,
  canCreateFilter = false,
  showDialogForSaveFilter,
  canSearch,
  isForModal = false,
  defaultValues = null,
  setSelectedFilter,
  showResetButton = true,
  disableCreateFilter = false,
  hideSaveFilterButton = false,
}) => {
  const { t } = useTranslation('SearchForm');
  const { toggleLoader } = useAppGlobals();
  const { validators, modalities, compactMode } = useAppInfo();
  const { formItems, dateSelectItems, customDateFields, extendedFields } = useValidationSchema(
    t,
    validators,
  );
  const dispatch = useDispatch();

  const { trigger, reset, setValue, watch } = useFormContext();
  const allFormValues = watch();

  const resetAll = () => {
    if (defaultValues) {
      // sem se to dostane z FilterForm z defaultními hodnotami jako pro nový filtr
      reset({ ...defaultValues });
      setValue('tags', []);
      setValue('sources', []);
    } else {
      reset();
      setValue('tags', []);
      setValue('sources', []);
      if (!isUndefined(setSelectedFilter)) {
        // Pro možnost zresetovat výběr filtru v SearchHeader při použití buttonu Vyčistit (reset) v SearchFormInner
        setSelectedFilter('');
      }
    }
  };

  const [activeRegisterGroups, setActiveRegisterGroups] = useState<any[]>([]);
  const [changeCount, setChangeCount] = useState<number>(0);

  const getRegistryGroups = async () => {
    toggleLoader();
    try {
      const registerGroups = await getAllGroups(true);
      if (isArray(registerGroups)) {
        const activeRegisterGroups = registerGroups.map(
          (activeTag) => `tags.${get(activeTag, 'name')}`,
        );
        setActiveRegisterGroups(activeRegisterGroups);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };
  useEffect(() => {
    getRegistryGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const extendedFieldsWithTags = concat(
      concat(extendedFields, ['withoutTags', 'tags.active', 'tags.inactive']),
      activeRegisterGroups,
    );
    let changeCount = 0;
    extendedFieldsWithTags.forEach((item) => {
      const value = get(allFormValues, item);
      if (!isEmpty(value) || isDate(value) || (isBoolean(value) && value)) {
        ++changeCount;
      }
    });
    setChangeCount(changeCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFormValues, activeRegisterGroups]);

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          boxShadow: searchFieldOpened ? 4 : 'none',
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <Papeer
            bottomMargin={true}
            sx={{
              pt: 1,
              borderBottomRightRadius: searchFieldOpened ? 0 : null,
              borderBottomLeftRadius: searchFieldOpened ? 0 : null,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: {
                  xs: 'wrap',
                  lg: 'nowrap',
                },
                paddingRight: { xs: '0px', sm: '48px' },
                position: { xs: 'static', sm: 'relative' },
              }}
              data-tour="search-header-searchForm-searchFormFields"
            >
              <Box sx={mainSx}>
                <FormInput
                  name="patient.id"
                  label={t('patientID')}
                  InputProps={{ sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
                />
              </Box>

              <Box sx={mainSx}>
                <FormInput
                  name="study.accessionNumber"
                  label={t('accessionNumber')}
                  InputProps={{ sx: { borderRadius: 0 } }}
                />
              </Box>

              <Box sx={mainSx}>
                <FormInput
                  name="patient.lastName"
                  label={t('lastName')}
                  InputProps={{ sx: { borderRadius: 0 } }}
                />
              </Box>

              <Box sx={mainSx}>
                <FormMultiSelect
                  name="modalities"
                  label={t('modality')}
                  items={modalities.map((modality) => ({
                    id: modality.name,
                    label: modality.name,
                  }))}
                  sx={{ borderRadius: 0 }}
                  trigger={trigger}
                  includeAllOption={true}
                />
              </Box>

              <Box sx={mainSx}>
                <FormSelect
                  name="studyDate"
                  label={t('studyDate')}
                  items={dateSelectItems}
                  sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  clearable={true}
                  clearCallback={() => {
                    setValue('study.dateFrom', null);
                    setValue('study.dateTo', null);
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                position: { sm: 'absolute' },
                right: 0,
                top: 8,
                textAlign: { xs: 'center', sm: 'right' },
              }}
            >
              <IconButton
                aria-label="delete"
                onClick={() => setSearchFieldOpened(!searchFieldOpened)}
                data-tour="search-header-searchForm-expansionArrow"
              >
                <Badge badgeContent={changeCount} color="primary">
                  {searchFieldOpened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </Badge>
              </IconButton>
            </Box>

            <Box
              display={'flex'}
              flexDirection={'row'}
              flexWrap={'nowrap'}
              justifyContent={'space-between'}
            >
              <Box data-tour="search-header-searchForm-searchSources">
                <SimpleCheckboxArray
                  name="sources"
                  items={sources}
                  grid={{}}
                  required={true}
                  showCheckAllButton={false}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1,
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                }}
              >
                {showResetButton && (
                  <Button
                    sx={smallScreenButtonWidth}
                    variant="contained"
                    color="inherit"
                    onClick={() => resetAll()}
                    data-tour="search-header-searchForm-clearButton"
                  >
                    {t('reset')}
                  </Button>
                )}
                {!useForOtherForm && (
                  <Button
                    sx={smallScreenButtonWidth}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!canSearch}
                    onClick={() => {
                      dispatch(setTourControlPropsToStore({ currentTourStep: 9 }));
                      // setCurrentTourStep(9); // 9 is step of the Grid. If there would be any changes it has to be changed as well.
                    }}
                    className="search-form-button"
                    data-tour="search-header-searchForm-searchButton"
                  >
                    {t('search')}
                  </Button>
                )}
                {canCreateFilter && !hideSaveFilterButton && (
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={showDialogForSaveFilter}
                    sx={smallScreenButtonWidth}
                    data-tour="search-header-searchForm-saveFilterButton"
                    disabled={disableCreateFilter}
                  >
                    {t('saveFiltr')}
                  </Button>
                )}
              </Box>
            </Box>
          </Papeer>
        </Box>
        <Box sx={searchFieldOpened ? undefined : { display: 'none' }}>
          <Box
            sx={{
              position: 'absolute',
              background: (theme) => theme.palette.background.paper,
              zIndex: 1030,
              p: compactMode ? 1 : 2,
              pt: 0,
              left: 0,
              right: 0,
              top: '100%',
              boxShadow: 4,
              borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
              borderBottomRightRadius: (theme) => theme.shape.borderRadius,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: {
                  xs: 'wrap',
                },
              }}
            >
              {formItems.map((formItem, index) => {
                const type = get(formItem, 'type', 'input');
                const name = get(formItem, 'name', '');
                const items: ISelectItem[] = get(formItem, 'items') || [];
                const label = get(formItem, 'label', '');
                return (
                  <Box
                    key={`formItem-${name}`}
                    sx={{
                      width: { xs: '100%', md: '50%', lg: 1 / 5, xl: 1 / 7 },
                      pr: { lg: 1 },
                    }}
                  >
                    {type === 'select' ? (
                      <FormSelect name={name} label={label} items={items} />
                    ) : null}
                    {type === 'reactSelect' ? (
                      <FormMultiSelect
                        name={name}
                        label={label}
                        items={modalities.map((modality) => ({
                          id: modality.name,
                          label: modality.name,
                        }))}
                        placeholder={t('modality')}
                        simpleValue={true}
                        isClearable={true}
                        defaultMenuIsOpen={false}
                        isMulti={true}
                      />
                    ) : null}

                    {type === 'switch' ? <FormSwitch name={name} label={label} /> : null}
                    {type === 'input' ? (
                      <FormInput
                        name={name}
                        label={label}
                        type={type as InputType}
                        {...{ autoComplete: get(formItem, 'autoComplete') }}
                      />
                    ) : null}
                    {type === 'date' ? <FormDatePicker name={name} label={label} /> : null}
                  </Box>
                );
              })}

              <Box
                sx={{
                  width: '100%',
                  display: {
                    lg: 'none',
                    xl: 'block',
                  },
                  ...(isForModal ? { display: { xl: 'none' } } : {}),
                }}
              />
              {customDateFields.map((field) => (
                <Box
                  sx={{
                    width: { xs: '100%', md: '50%', lg: 1 / 5, xl: 1 / 7 },
                    pr: { lg: 1 },
                  }}
                  key={field.name}
                >
                  <FormDatePicker name={field.name} label={field.label} disabled={!hasCustomDate} />
                </Box>
              ))}
            </Box>
            <SearchFormTags />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SearchFormInner;
