import HelperComponent from 'components/Tour/HelperComponent';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

export const TourButton = () => {
  const { t } = useTranslation('Reactour');

  const allSteps = () => {
    const steps = {
      '[data-tour="buttonFormIcon"]': (
        <>
          {t('buttonFormIcon')}
          <p>{t('buttonFormIconExample')}</p>
          {t('buttonFormIconConvert')}
        </>
      ),
    };
    return steps;
  };

  useReactTour({ stepsDefinition: allSteps });

  return <HelperComponent />;
};
