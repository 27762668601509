import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { DialogFolders } from 'modules/Administration/Folders/DialogFolders';
import { stateIsSame } from 'utils/componentOptimizatons';

const buttonStyle = { marginRight: '8px', marginBottom: '8px' };

const PlainStudyDetailFolders: React.FC<any> = ({
  studyActions,
  study,
  seriesWithPreviewUrl,
  selectedSeries,
  setSelectedSeries,
  folderDialogState,
  setFolderDialogState,
}) => {
  const { t } = useTranslation('StudyDetail');
  const [folderItem, setFolderItem] = useState<any>(null);

  return (
    <>
      {!studyActions.addToFolder.disable && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            setFolderDialogState(true);
            setFolderItem(null);
          }}
          style={buttonStyle}
        >
          {t('action.addToFolder')}
        </Button>
      )}
      <DialogFolders
        key="studyDialogFolders"
        study={study}
        selectedStudies={[study]}
        series={seriesWithPreviewUrl}
        selectedSeries={selectedSeries}
        setSelectedSeries={setSelectedSeries}
        action="studyDetail"
        showFoldersByUserAndStudy={true}
        folderDialogState={folderDialogState}
        setFolderDialogState={setFolderDialogState}
        folderItem={folderItem}
      />
    </>
  );
};

export const StudyDetailFolders = React.memo(PlainStudyDetailFolders, stateIsSame);
