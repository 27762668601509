import React from 'react';
import { Typography } from '@mui/material';
import { IAnnouncement } from './_types';
import { TypographyType } from 'constants/constants';
import { danger, info, warning } from 'utils/variables';
import { useAppInfo } from 'utils/hooks/useAppInfo';

export const Announcement: React.FC<IAnnouncement> = ({
  label,
  type,
  spaced = false,
  condensed = false,
  component,
  children,
  sx = {},
}) => {
  const { compactMode } = useAppInfo();
  return (
    <Typography
      component={component ? (component as TypographyType) : 'pre'}
      sx={{
        p: 1,
        border: 1,
        borderRadius: condensed || compactMode ? 2 : 4,
        whiteSpace: 'pre-wrap',
        ...(condensed || compactMode ? { fontSize: 13, pt: 0.5, pb: 0.5 } : {}),
        ...(spaced ? { p: 2 } : {}),
        ...(type === 'info'
          ? { backgroundColor: info.background, color: info.color }
          : type === 'warning'
          ? { backgroundColor: warning.background, color: warning.color }
          : type === 'danger'
          ? { backgroundColor: danger.background, color: danger.color }
          : {}),
        ...sx,
      }}
    >
      {children ? children : label}
    </Typography>
  );
};
