import { IAddGroupTagRequest } from '../_types';
import { getAllGroups } from '../_api';
import {
  IGroupTagsHierarchicalStructure,
  IRegisterGroup,
  IRegisterItem,
  ITagFormAutocomplete,
} from 'components/TagForm/_types';

export const useAddTagsTransformValues = () => {
  const onAddTagsTransformValues = async (values: ITagFormAutocomplete) => {
    return prepareGroupTagStructure(values);
  };

  const prepareGroupTagStructure = async (values: ITagFormAutocomplete) => {
    const groups = await getAllGroups(true);

    const { registerItems } = values;

    const selectedValues: number[] = Object.values(registerItems)
      .filter((item) => item !== null)
      .map((item) => item.value);

    const groupTagStructureArray: IGroupTagsHierarchicalStructure = [];
    groups.forEach((tag: IRegisterGroup) => {
      const createdStructure = createGroupTagStructure(tag, selectedValues);
      groupTagStructureArray.push(...createdStructure);
    });
    return groupTagStructureArray as IGroupTagsHierarchicalStructure;
  };

  const createGroupTagStructure = (
    tag: IRegisterGroup,
    selectedValues: number[],
  ): IGroupTagsHierarchicalStructure => {
    const structures: IGroupTagsHierarchicalStructure = [];
    if (tag.registerItems) {
      //Add groupTag to structure if any value was selected in autocomplete for given tag
      tag.registerItems.forEach((item: IRegisterItem) => {
        if (item.id !== undefined && selectedValues.includes(item.id)) {
          const data = { group: tag, id: item.id, name: item.name };
          const newGroupTagStructure: IAddGroupTagRequest = {
            data: data,
            label: item.name,
          };
          structures.push(newGroupTagStructure);
        }
      });
    }

    //If tag has some children -> Create children structure
    if (tag.children && tag.children.length > 0) {
      tag.children.forEach((child: IRegisterGroup) => {
        const childrenStructure = createGroupTagStructure(child, selectedValues);
        if (childrenStructure.length > 0) {
          structures.forEach((item) => {
            if (selectedValues.includes(item.data.id)) {
              item.children = childrenStructure;
            }
          });
        }
      });
    }
    return structures;
  };

  return { onAddTagsTransformValues };
};
