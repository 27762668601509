import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { parseISO } from 'date-fns';
import { get, isArray, isEmpty, omit, omitBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import useValidationSchema from './_formSearch';
import { getAuditLogs } from './_api';
import { ILogsSearch } from './_types';

import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import FormDatePicker from 'components/Form/Date/Date';
import Button from 'components/Buttons/Button';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useActions } from 'utils/hooks/useActions';

import { DATE_BTN_CUSTOM_DATE, DATE_BTN_TODAY } from 'constants/constants';
import { Box } from '@mui/system';
import { useStudy } from 'utils/hooks/useStudy';
import { convertSearchDates } from 'utils/search';

const apiDateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss";
const apiDateFormat = 'yyyyMMdd';

const mainSx = { width: { xs: '100%', md: '50%', lg: '20%' } };

const LogSearchForm: React.FC = () => {
  const { t } = useTranslation('SearchForm');
  const { storeLogs } = useActions();

  const { linkBack } = useStudy();

  const [isFirstSearch, setFirstSearch] = useState<boolean>(true);
  const [hasCustomDate, setHasCustomDate] = useState(false);
  const [canSearch, setCanSearch] = useState(false);

  let [searchParams] = useSearchParams();

  const backFromDetail = linkBack();
  const requestId = searchParams.get('requestId') || '';
  const date = searchParams.get('date') || '';

  const { dateSelectItems, fields } = useValidationSchema(t);
  const methods = useForm<ILogsSearch>({
    defaultValues: { dateFrom: null, dateTo: null },
  });

  const { handleSubmit, reset, getValues, setValue, watch, register } = methods;
  const selectedDate = watch('date');
  const formValues = getValues();
  useEffect(() => {
    setCanSearch((isFirstSearch && !backFromDetail) || !isEmpty(omitBy(formValues, isEmpty)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  const { toggleLoader } = useAppGlobals();

  useEffect(() => {
    if (selectedDate === DATE_BTN_CUSTOM_DATE) {
      setHasCustomDate(true);
    } else {
      setHasCustomDate(false);

      // Set dates if selected date is not custom
      if (selectedDate) {
        const dateAttributes = convertSearchDates(
          selectedDate,
          get(formValues, 'dateFrom'),
          get(formValues, 'dateTo'),
          apiDateFormat,
        );
        setValue('dateFrom', new Date(parseISO(dateAttributes.dateFrom)));
        setValue('dateTo', new Date(parseISO(dateAttributes.dateTo)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const submitHandler = async (values: any) => {
    toggleLoader();
    if (selectedDate) {
      const dateAttributes = convertSearchDates(
        selectedDate,
        get(values, 'dateFrom', ''),
        get(values, 'dateTo', ''),
        apiDateTimeFormat,
      );
      values = { ...values, dateFrom: dateAttributes.dateFrom, dateTo: dateAttributes.dateTo };
    }
    const transformedValues = omitBy(omit(values, ['date']), isEmpty);
    const resp = await getAuditLogs(transformedValues);
    storeLogs([]);
    if (isArray(resp) && !isEmpty(resp)) {
      storeLogs(
        resp.map((item: any, index: number) => ({
          ...item,
          customIndex: index + 1,
        })),
      );
    }
    setFirstSearch(false);
    toggleLoader(false);
  };

  const triggerSubmit = () => {
    const submitButton: HTMLElement = document.querySelector('.search-form-button') as HTMLElement;
    if (submitButton) {
      submitButton.focus();
      submitButton.click();
    }
  };

  const loadEntities = async () => {
    toggleLoader();
    if (!backFromDetail) {
      reset(
        requestId && date
          ? {
              date: 'customDate',
              dateFrom: new Date(isArray(date) ? date[0] : date),
              dateTo: new Date(isArray(date) ? date[0] : date),
              requestId,
            }
          : {
              date: DATE_BTN_TODAY,
            },
      );

      storeLogs([]);
    }
    if (!backFromDetail) {
      setTimeout(() => {
        triggerSubmit();
        if (requestId && date) {
          setValue('requestId', null);
          setValue('dateFrom', null);
          setValue('dateTo', null);
          setValue('date', undefined);
        }
      }, 250);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    loadEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <input {...register('date')} type="hidden" />
          <input {...register('requestId')} type="hidden" />
          <Papeer bottomMargin={true}>
            <Box
              data-tour="auditLogsForm"
              sx={{
                display: 'flex',
                flexWrap: {
                  xs: 'wrap',
                  lg: 'nowrap',
                },
                paddingRight: { xs: '0px', sm: 0 },
              }}
            >
              {fields.map((field: any, index: number) => (
                <Box sx={mainSx} key={field.name}>
                  <FormInput
                    {...field}
                    type="text"
                    InputProps={{
                      sx:
                        index === 0
                          ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                          : { borderRadius: 0 },
                    }}
                  />
                </Box>
              ))}
              <Box sx={mainSx}>
                <FormSelect
                  name="date"
                  label={t('logDate')}
                  items={dateSelectItems}
                  sx={{ borderRadius: 0 }}
                  clearable={true}
                  clearCallback={() => {
                    setValue('dateFrom', null);
                    setValue('dateTo', null);
                  }}
                />
              </Box>
              <Box sx={mainSx}>
                <FormDatePicker
                  name="dateFrom"
                  label={t('from')}
                  sx={{ borderRadius: 0 }}
                  disabled={!hasCustomDate}
                />
              </Box>
              <Box sx={mainSx}>
                <FormDatePicker
                  name="dateTo"
                  label={t('to')}
                  sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  disabled={!hasCustomDate}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'right',
                gap: 1,
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!canSearch}
                className="search-form-button"
                data-tour="search-header-searchForm-searchButton"
              >
                {t('search')}
              </Button>
            </Box>
          </Papeer>
        </form>
      </FormProvider>
    </>
  );
};

export default LogSearchForm;
