import React from 'react';
import { useTranslation } from 'react-i18next';
import { get, isObject } from 'lodash';
import { Typography } from '@mui/material';

export const ErrorMessage: React.FC<any> = ({ textError }) => {
  const { t } = useTranslation('Errors');

  const errorText = isObject(textError)
    ? t(get(textError, 'code', 'allowedChars'), {
        par1: get(textError, 'par1', '?'),
      })
    : t(textError);

  return (
    <Typography variant="caption" color="error" sx={{ mt: 1 }}>
      {errorText}
    </Typography>
  );
};
