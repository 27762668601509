import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImportNonDicom from 'modules/Import/ImportNonDicom';

const StudyDetailImportImage: React.FC<any> = ({ toggleImportImageDialog, study }) => {
  const { t } = useTranslation('StudyDetail');

  const onCloseDialog = () => {
    toggleImportImageDialog(false);
  };

  return (
    <>
      <Typography component="div" id="scrollToForm">
        <Paper elevation={0}>
          <Dialog
            open={true}
            onClose={() => {
              toggleImportImageDialog(false);
            }}
            aria-labelledby="form-dialog-title"
            maxWidth="xl"
            fullWidth={true}
          >
            <DialogTitle id="form-dialog-title" sx={{ pt: 0, pb: 0 }}>
              <Grid
                container={true}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {t('action.import')}
                <IconButton aria-label="close" onClick={onCloseDialog} size="large">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent sx={{ p: 2, pt: 0 }} id="form-dialog-content">
              <ImportNonDicom
                onOrderCloseDialog={onCloseDialog}
                isImageImportForStudy={true}
                study={study}
              />
            </DialogContent>
          </Dialog>
        </Paper>
      </Typography>
    </>
  );
};

export default StudyDetailImportImage;
