import { get } from 'lodash';

export const getTitle = (txt: string | null | undefined, currentLocale: string) => {
  try {
    let title = txt ? get(JSON.parse(txt), currentLocale) : '';
    if (!title && currentLocale !== 'cs' && currentLocale !== 'en') {
      title = txt ? get(JSON.parse(txt), 'en') : '';
    }
    if (!title && currentLocale !== 'cs') {
      title = txt ? get(JSON.parse(txt), 'cs') : '';
    }
    return title;
  } catch (error) {
    console.debug(error);
    return '';
  }
};
