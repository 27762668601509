import React from 'react';
import { useTranslation } from 'react-i18next';
import { compact, get } from 'lodash';
import { Box, Tooltip } from '@mui/material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import { removeUser } from './_api';
import { IFolderDetailUsers } from './_types';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useEntityRemove2 } from 'utils/hooks/useEntityRemove2';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useActions } from 'utils/hooks/useActions';
import { joinParams } from 'utils/study';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { DELETE_USER_FROM_FOLDER } from 'constants/constants';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const GRID_SETTINGS_KEY: string = 'folderDetailUsers';

export const FolderDetailUsers: React.FC<IFolderDetailUsers> = ({
  canRemoveUser,
  users,
  loadEntity,
  dataTour,
}) => {
  const { t } = useTranslation('Folders');
  const dispatch = useAppDispatch();

  const { confirmationData } = useAppInfo();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } =
    useMuiGrid(GRID_SETTINGS_KEY);

  const { storeConfirmationData } = useActions();

  const { onEntityRemove2 } = useEntityRemove2(removeUser, t, loadEntity);

  const confirmActionRemoveUserFromFolder = async () => {
    onEntityRemove2(get(confirmationData, 'folderId'), get(confirmationData, 'id'));
    clearAction();
  };
  const clearAction = () => storeConfirmationData(null);
  const cancelAction = () => {
    clearAction();
  };

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      compact([
        canRemoveUser
          ? {
              field: 'actions',
              headerName: t('Grid:actions'),
              type: 'actions',
              hideable: false,
              width: 250,
              renderCell: ({ row }: GridRenderCellParams) => (
                <>
                  <GridActionsCellItem
                    icon={
                      <Tooltip title={t('Grid:removeUserFromFolder')}>
                        <DeleteIcon />
                      </Tooltip>
                    }
                    label={t('Grid:removeUserFromFolder')}
                    onClick={() => {
                      dispatch(
                        storeConfirmationDataToStore({
                          folderId: get(row, 'folderId'),
                          id: get(row, 'id'),
                          title: 'confirmRemoveUserFromFolder',
                          confirmAction: DELETE_USER_FROM_FOLDER,
                        }),
                      );
                    }}
                    disabled={!get(row, 'canDelete', true)}
                  />
                </>
              ),
            }
          : null,
        {
          field: 'fullname',
          headerName: t('fullname'),
          valueGetter: (value: any, row: any) =>
            `${joinParams([get(row, 'lastName'), get(row, 'firstName')])}`,
        },
        { field: 'email', headerName: t('email') },
        {
          field: 'permission',
          headerName: t('permission'),
          renderCell: ({ row }: GridRenderCellParams) => (
            <>
              {get(row, 'permission') !== undefined && (
                <Tooltip
                  title={t(`${get(row, 'permission').toLowerCase()}_tooltip`)}
                  placement="top"
                >
                  <Box sx={{ cursor: 'help' }}>{t(get(row, 'permission').toLowerCase())}</Box>
                </Tooltip>
              )}
            </>
          ),
        },
      ]),
      200,
    ),
  );

  return (
    <>
      <MuiGrid
        gridKey={GRID_SETTINGS_KEY}
        rows={users}
        columns={columns}
        initialSortMode={[{ field: 'fullname', sort: 'asc' }]}
        dataTour={dataTour}
      />
      {confirmationData && confirmationData.id && (
        <>
          <ConfirmationDialog
            title={
              confirmationData.title ? t(`Grid:${confirmationData.title}`) : t('confirmDelete')
            }
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            cancelAction={cancelAction}
            confirmAction={confirmActionRemoveUserFromFolder}
          />
        </>
      )}
    </>
  );
};
