import React, { useEffect, /* useMemo, useRef, */ useState } from 'react';
import { get, isEmpty, orderBy, includes, set, indexOf, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Box, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { IDocumentDetailTimeline } from './_types';
import { joinParams } from 'utils/study';
import { useLanguage } from 'utils/hooks/useLanguage';
import { MaterialUiIcon } from 'components/MaterialUiIcon/MaterialUiIcon';

const formatDate = 'dd. MM. yyyy HH:mm:ss';
const formatDateReduced = 'dd. MM. yyyy';

export const DocumentDetailTimeline: React.FC<IDocumentDetailTimeline> = ({
  patientDocuments,
  documentUids,
  gridLayout,
  selectDocumentUuid,
  unselectDocumentUuid,
  setDocumentUids,
  gridLayoutParams,
  replaceLastDocumentUuid,
}) => {
  const { t } = useTranslation('ClinicalPortal');
  const { currentLocale } = useLanguage();
  const [firstActiveUiid, setFirstActiveUiid] = useState<string>('');

  useEffect(() => {
    if (isArray(documentUids) && documentUids.length > 0) {
      setFirstActiveUiid(documentUids[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [documents, setDocuments] = useState<any[]>([]);

  const documentUidsJsonString = JSON.stringify(documentUids);

  const openDocument = (uuid: string) => {
    const maxSelectedDocuments = gridLayoutParams.totalAvailableFields;
    if (documentUids.length < maxSelectedDocuments) {
      setDocuments(
        documents.map((document) => {
          const updatedDocument = {
            ...document,
          };
          if (get(document, 'uuid', null) === uuid) {
            set(updatedDocument, 'selected', true);
          }
          return updatedDocument;
        }),
      );
      selectDocumentUuid(uuid);
    } else if (gridLayout === '1x1') {
      replaceLastDocumentUuid(uuid);
    } else {
      if (indexOf(documentUids, uuid) === -1) {
        replaceLastDocumentUuid(uuid);
      }
    }
  };

  const fillDocuments = (documentUids: any[]) => {
    const documents = orderBy(
      patientDocuments.map((patientDocument) => {
        return {
          ...patientDocument,
          selected: includes(documentUids, get(patientDocument, 'uuid', '')),
        };
      }),
      ['createdDateTime'],
      ['desc'],
    );
    setDocuments(documents);
  };

  useEffect(() => {
    fillDocuments(documentUids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentUidsJsonString]);

  useEffect(() => {
    const maxSelectedDocuments = gridLayoutParams.totalAvailableFields;
    if (documents.length > 0) {
      let selectedDocuments = 0;
      const newDocumentUids: any[] = [];
      const newDocuments = orderBy(
        documents.map((document) => {
          const updatedDocument = {
            ...document,
          };

          if (maxSelectedDocuments === selectedDocuments) {
            set(updatedDocument, 'selected', false);
          }
          if (get(updatedDocument, 'selected', false)) {
            selectedDocuments++;
            newDocumentUids.push(get(updatedDocument, 'uuid'));
          }
          return updatedDocument;
        }),
        ['createdDateTime'],
        ['desc'],
      );
      setDocuments(newDocuments);
      setDocumentUids(newDocumentUids);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridLayout, documents.length /* documentsJsonString */]);

  const TabIcon = (index: number) => {
    const document = documents[index];
    const documentIsSelected = get(document, 'selected', false);
    const externalWorkplaceCode = get(document, 'externalWorkplaceCode');
    return (
      <Tooltip
        title={joinParams([
          get(document, 'typeOfDocument.translateJson')
            ? get(
                JSON.parse(get(document, 'typeOfDocument.translateJson')),
                currentLocale,
                get(document, 'typeOfDocument.name'),
              )
            : `${t('unknownNameOfDocument')}`,
          `(${t('createdDateTime')}:`,
          get(document, 'createdDateTime', '')
            ? format(new Date(get(document, 'createdDateTime', '')), formatDate)
            : `${t('unknownNameDate')}`,
          ` | ${t('author')}:`,
          get(document, 'author') != null
            ? get(document, 'author')
            : `${t('unknownAuthorOfDocument')}`,
          ` | ${t('referenceID')}:`,
          get(document, 'referenceID') != null
            ? get(document, 'referenceID')
            : `${t('unknownReferenceOfDocument')}`,
          ` | ${t('externalWorkplaceCode')}:`,
          externalWorkplaceCode != null
            ? externalWorkplaceCode
            : `${t('unknownExternalWorkplaceCode')}`,
          ')',
        ])}
      >
        <Typography component="div">
          <IconButton aria-label="Close" color="inherit" sx={{ verticalAlign: 'top' }}>
            <MaterialUiIcon
              icon={get(document, 'typeOfDocument.iconName')}
              selected={documentIsSelected}
              style={{
                color:
                  get(document, 'typeOfDocument.iconColor', null) == null
                    ? '#fff'
                    : get(document, 'typeOfDocument.iconColor'),
              }}
            />
          </IconButton>
          <Typography
            component="div"
            style={{
              verticalAlign: 'top',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            {get(document, 'createdDateTime', '')
              ? format(new Date(get(document, 'createdDateTime', '')), formatDateReduced)
              : `${t('unknownNameDate')}`}
          </Typography>
          <Typography
            component="div"
            style={{
              verticalAlign: 'top',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              height: '16px',
            }}
          >
            {externalWorkplaceCode != null ? externalWorkplaceCode : ''}
          </Typography>
        </Typography>
      </Tooltip>
    );
  };

  return (
    <Box sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
      {isEmpty(documents) || documents.length < 2 ? (
        <Box id="timeline" sx={{ mt: 1 }} />
      ) : (
        <Box sx={{ mt: 1, mb: 1, overflow: 'auto' }}>
          <Tabs
            value={firstActiveUiid}
            variant="scrollable"
            scrollButtons={true}
            style={{
              background:
                'linear-gradient(180deg, rgba(0,0,0,0) 28px, rgba(192,192,192,1) 29px, rgba(0,0,0,0) 30px)',
            }}
            sx={{
              position: 'relative',
              '&:after': {
                content: '""',
                width: 40,
                top: 0,
                height: '100%',
                backgroundColor: (theme) => theme.palette.background.paper,
                position: 'absolute',
              },
              '&:before': {
                content: '""',
                width: 40,
                top: 0,
                right: 0,
                height: '100%',
                backgroundColor: (theme) => theme.palette.background.paper,
                position: 'absolute',
              },
              '.MuiTabs-scrollButtons': {
                position: 'relative',
                zIndex: 500,
              },
              '.MuiTabs-flexContainer': { justifyContent: 'space-between' },
            }}
            className="timeline-tabs"
            TabIndicatorProps={{ style: { display: 'none' } }}
          >
            {documents.map((document, index) => {
              const documentIsSelected = get(document, 'selected', false);
              return (
                <Tab
                  key={document.uuid}
                  label={<Box>{TabIcon(index)}</Box>}
                  sx={{
                    '.MuiTabs-root': {
                      minWidth: 120,
                    },
                  }}
                  onClick={() => {
                    setFirstActiveUiid('');
                    if (!documentIsSelected) {
                      openDocument(get(document, 'uuid', null));
                    }
                  }}
                  value={document.uuid}
                />
              );
            })}
          </Tabs>
        </Box>
      )}
    </Box>
  );
};

// export const DocumentDetailTimeline = React.memo(PlainDocumentDetailTimeline, stateIsSame);
