import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { filter, get, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ISearchContextMenu } from './_types';

import { BASKET, SEARCH_FUNCTION_REORDER, DETAIL } from 'constants/constants';
import { useActions } from 'utils/hooks/useActions';
import useAlerts from 'components/Alerts/useAlerts';
import { useNavigate } from 'react-router';
import { useStudy } from 'utils/hooks/useStudy';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { useFunctionList } from 'components/SearchResultsMUI/useFunctionList';

export const SearchContextMenu: React.FC<ISearchContextMenu> = ({
  contextMenu,
  closeContextMenu,
  isRequest,
  selection,
  setSelection,
  actions,
  operations,
  rows,
  setIsContextMenuOpen,
}) => {
  const { t } = useTranslation('SearchResults');
  const { resetStudies, setBulkStudies, setStudies, setStudiesBasket } = useActions();
  const { addSuccessAlert } = useAlerts();
  const navigate = useNavigate();
  const { checkAllowedArchives } = useStudy();

  const { basketStore, sourceStudyForReorderIsInStore, reorderStore, sourceStudyForReorderIID } =
    useStudyInfo();

  function createContextMenuFunctionListButtonClick(selection: any) {
    return function handleFuntionListButtonClick(type: string, link: string) {
      const isReorder = type === 'reorder';
      const mappedType = type === 'edit_patient' ? 'edit' : type;

      const items = selection;

      if (mappedType === BASKET) {
        setStudiesBasket({
          type: mappedType,
          items: isArray(items) ? items : [items],
          currentItems: basketStore,
        });
        addSuccessAlert(t(selection.length > 1 ? 'studiesAddedToBasket' : 'studyAddedToBasket'));
      } else if (!isReorder) {
        resetStudies(mappedType);
        if (selection.length > 0) {
          setBulkStudies({
            type: mappedType,
            items,
          });
        } else {
          setStudies({
            type: mappedType,
            item: items,
          });
        }
      } else {
        if (selection.length > 0) {
          setBulkStudies({
            type: mappedType,
            items: [...reorderStore, ...items],
          });
        } else {
          setStudies({
            type: mappedType,
            item: items,
          });
        }
      }

      // Reset selection so that another study can be selected
      if (isReorder) {
        setSelection([]);
      }

      // Reorder step 1: don't navigate to reorder
      if (type !== 'reorder' || (type === 'reorder' && sourceStudyForReorderIsInStore)) {
        navigate(link);
      }

      setIsContextMenuOpen(false);
    };
  }

  function createContextMenuCallFunctionButtonClick(selection: any) {
    return function handleContextMenuClick(nameFunction: string) {
      let item = null;
      selection = selection[0];
      if (
        nameFunction === 'deleteSelectedStudies' ||
        nameFunction === 'extendExpirationSelectedStudies' ||
        nameFunction === 'getChangeStatusesDialog' ||
        nameFunction === 'proposalShredding' ||
        nameFunction === 'shredding' ||
        nameFunction === 'moveToTrash' ||
        nameFunction === 'archive'
      ) {
        item = selection // pokud je použita akce u vyšetření, tak se bere jen jedno vyšetření
          ? get(selection, 'iid', null)
            ? get(selection, 'iid')
            : null
          : null;
      }
      operations.callFunctionByName(nameFunction, item);
      setIsContextMenuOpen(false);
    };
  }

  //Creating closure for context menu handler/higher order function
  const contextMenuFunctionListButtonClick = createContextMenuFunctionListButtonClick(selection);
  const contextMenuCallFunctionButtonClick = createContextMenuCallFunctionButtonClick(selection);

  const { functionList }: any = useFunctionList({
    actions,
    rows,
    selection: selection,
    operations,
    isRequest,
    isForContextMenu: true,
    contextMenuFunctionListButtonClick: contextMenuFunctionListButtonClick,
    contextMenuCallFunctionButtonClick: contextMenuCallFunctionButtonClick,
  });

  return (
    contextMenu.row && (
      <Menu
        keepMounted={true}
        open={contextMenu.mouseY !== null}
        onClose={closeContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu.mouseY !== null && contextMenu.mouseX !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem key="placeholder" style={{ display: 'none' }} /> {/* focus on first item*/}
        {selection.length > 1 && (
          <MenuItem key="placeholder2" disabled={true}>
            {t('studiesSelected')}: {selection.length}
          </MenuItem>
        )}
        {filter(functionList, ['show', true]).map((item) => {
          let itemIsDisabled = !checkAllowedArchives(item.key, contextMenu.row);
          if (
            item.key === SEARCH_FUNCTION_REORDER &&
            sourceStudyForReorderIsInStore &&
            sourceStudyForReorderIID === contextMenu.row.iid
          ) {
            itemIsDisabled = true;
          }

          if (item.key === DETAIL && !get(contextMenu.row, 'ok', true)) {
            itemIsDisabled = true;
          }

          return (
            <MenuItem key={item.key} onClick={item.onClick} disabled={itemIsDisabled}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} style={{ fontSize: '0.8125rem' }} />
            </MenuItem>
          );
        })}
      </Menu>
    )
  );
};
