import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, isEmpty, isArray, find, isUndefined, compact, isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Box, Button, CircularProgress, Tooltip, Typography } from '@mui/material';
import {
  FolderOpen as FolderIcon,
  ImportExport as ImportExportIcon,
  Print as PrintIcon,
  Description as GridDetailIcon,
  Send as SendIcon,
} from '@mui/icons-material/';
import {
  DataGridPro,
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowModel,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { IDocument, IDocumentGrid, IPatientDocument } from './_types';
import { IFunctionItem } from 'modules/Administration/Folders/_types';
import { DialogFolders } from 'modules/Administration/Folders/DialogFolders';
import { useUser } from 'utils/hooks/useUser';
import { useActions } from 'utils/hooks/useActions';
import { useFilterableColumns } from 'utils/hooks/useFilterableColumns';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { Papeer } from 'components/Papeer/Papeer';
import {
  DATA_GRID_DEFAULT_SORTING,
  DATA_GRID_FILTER_HEADER_ROW_HEIGHT,
  DATA_GRID_ROW_HEIGHT,
  SHOW_DOCUMENT,
} from 'constants/constants';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import styles from 'components/SearchResultsMUI/_styles';
import useGridLocalization from 'components/SearchResultsMUI/useGridLocalization';

const selecting = true;
const getRowId = (row: IPatientDocument) => `${row.uuid}_${row.index}`;

export const DocumentGrid: React.FC<IDocumentGrid> = ({
  masterPatientID,
  facilities,
  bookmarkId,
  allowedTypesOfDocument,
  patientDocuments,
  patient,
  maxCounts,
  setIsPrinting,
  setStandardizedPrint,
  setIsExporting,
  isLoading,
}) => {
  const GRID_SETTINGS_KEY: string = `${bookmarkId}_documentsGrid`;
  const { t } = useTranslation('ClinicalPortal');
  const { hasRole } = useUser();
  const { storeDataForDocumentDetail, storeDocuments, storeSelection } = useActions();
  const navigate = useNavigate();
  const { compactMode } = useAppInfo();
  const patientDocumentsTxt = JSON.stringify(patientDocuments);
  const gridLocalization = useGridLocalization();

  const {
    gridSortModel,
    columnVisibilityModel,
    injectColumnWidthsIntoColumns,
    reorderColumnsByGridSettings,
    changeWidthColumn,
    updateColumnOrder,
    setColumnVisibilityModel,
    sortByColumn,
    getTogglableColumns,
  } = useMuiGrid(GRID_SETTINGS_KEY);

  const defaultHiddenColumnNames = {
    referenceID: false,
    ...(isArray(allowedTypesOfDocument) && allowedTypesOfDocument.length === 1
      ? { typeOfDocumentName: false }
      : {}),
  };

  const columnVisibilityModelWithDefaultHidden = {
    ...(isUndefined(columnVisibilityModel) ? defaultHiddenColumnNames : columnVisibilityModel),
  };

  const canPrintDocuments = hasRole('ROLE_CAN_PRINT_IN_CLINICAL_PORTAL');
  const canExportDocuments = hasRole('ROLE_CAN_EXPORT_IN_CLINICAL_PORTAL');
  const canAddToFolder = hasRole('ROLE_CLINICAL_DOCUMENTS_IN_FOLDER');
  const canSendDocumentsAsPdf = hasRole('ROLE_SEND_DOCUMENT_AS_PDF');
  const canSendDocumentsAsDicom = hasRole('ROLE_SEND_DOCUMENT_AS_DICOM');

  const [patientDocumentsByBookmark, setPatientDocumentsByBookmark] = useState<IPatientDocument[]>(
    [],
  );
  const [canPrintStandardizedDocuments, setCanPrintStandardizedDocuments] =
    useState<boolean>(false);
  const [standardizedPrintDocumentsEnabled, setStandardizedPrintDocumentsEnabled] =
    useState<boolean>(false);
  const [sendDocumentsEnabled, setSendDocumentsEnabled] = useState<boolean>(false);
  const [isEnabledSendOnlyPdfAndHasSelectedDicom, setIsEnabledSendOnlyPdfAndHasSelectedDicom] =
    useState<boolean>(false);
  const [isEnabledSendOnlyDicomAndHasSelectedPdf, setIsEnabledSendOnlyDicomAndHasSelectedPdf] =
    useState<boolean>(false);
  const [shouldShowGrid, setShouldShowGrid] = useState<boolean>(false);
  const [selection, setSelection] = useState<string[]>([]);
  const [selectedPatientDocuments, setSelectedPatientDocuments] = useState<IPatientDocument[]>([]);
  const [folderDialogState, setFolderDialogState] = useState<boolean>(false);
  const [selectedSeries, setSelectedSeries] = useState<any>(false);

  useEffect(() => {
    const filteredPatientDocuments = [...patientDocuments];
    for (let index = 0; index < filteredPatientDocuments.length; index++) {
      const element = filteredPatientDocuments[index];
      const standardizedPrintEnabled = get(
        element,
        'typeOfDocument.standardizedPrintEnabled',
        false,
      );
      if (standardizedPrintEnabled) {
        setCanPrintStandardizedDocuments(true);
        break;
      }
    }
    setPatientDocumentsByBookmark(filteredPatientDocuments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDocumentsTxt]);

  useEffect(() => {
    let isEnabled = true;
    let isEnabledSend = true;
    let isEnabledSendOnlyPdfAndHasSelectedDicom = false;
    let isEnabledSendOnlyDicomAndHasSelectedPdf = false;
    let isFirstDicom;
    for (let index = 0; index < selection.length; index++) {
      const element = selection[index];
      const patientDocument = find(patientDocumentsByBookmark, { uuidex: element });
      const standardizedPrintEnabled = get(
        patientDocument,
        'typeOfDocument.standardizedPrintEnabled',
        false,
      );
      if (!standardizedPrintEnabled) {
        isEnabled = false;
      }
      const allowedTypes = get(patientDocument, 'typeOfDocument.allowedFormats', []);
      const isDicom = find(allowedTypes, { name: 'dicom' }) !== undefined;
      if (index === 0) {
        isFirstDicom = isDicom;
      } else if (isDicom !== isFirstDicom) {
        isEnabledSend = false;
      }
      if (canSendDocumentsAsPdf && !canSendDocumentsAsDicom && isDicom) {
        isEnabledSendOnlyPdfAndHasSelectedDicom = true;
      }
      if (!canSendDocumentsAsPdf && canSendDocumentsAsDicom && !isDicom) {
        isEnabledSendOnlyDicomAndHasSelectedPdf = true;
      }
    }
    setStandardizedPrintDocumentsEnabled(isEnabled);
    setSendDocumentsEnabled(isEnabledSend);
    setIsEnabledSendOnlyPdfAndHasSelectedDicom(isEnabledSendOnlyPdfAndHasSelectedDicom);
    setIsEnabledSendOnlyDicomAndHasSelectedPdf(isEnabledSendOnlyDicomAndHasSelectedPdf);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  const getTypeOfDocumentName = (row: any) => get(row, 'typeOfDocumentName', '') || '';

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 250,
        getActions: ({ row }: GridRowParams) =>
          compact([
            <GridActionsCellItem
              icon={
                <Tooltip title={t('Grid:showDocument')}>
                  <GridDetailIcon />
                </Tooltip>
              }
              label={t('Grid:showDocument')}
              onClick={() => onEntityAction(row, SHOW_DOCUMENT)}
              showInMenu={false}
            />,
            canPrintDocuments && (
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('multiplePrint')}>
                    <PrintIcon />
                  </Tooltip>
                }
                label={t('multiplePrint')}
                onClick={() => onEntityAction(row, 'print_document')}
                showInMenu={true}
              />
            ),
            canPrintStandardizedDocuments && (
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('multipleStandardizedPrint')}>
                    <PrintIcon />
                  </Tooltip>
                }
                label={t('multipleStandardizedPrint')}
                onClick={() => onEntityAction(row, 'standardized_print_document')}
                showInMenu={true}
              />
            ),
            canExportDocuments && (
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('multipleExport')}>
                    <ImportExportIcon />
                  </Tooltip>
                }
                label={t('multipleExport')}
                onClick={() => onEntityAction(row, 'export')}
                showInMenu={true}
              />
            ),
            canAddToFolder && (
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('multipleAddToFolder')}>
                    <FolderIcon />
                  </Tooltip>
                }
                label={t('multipleAddToFolder')}
                onClick={() => onEntityAction(row, 'add_to_folder')}
                showInMenu={true}
              />
            ),
            (canSendDocumentsAsPdf || canSendDocumentsAsDicom) && (
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('sendDocument')}>
                    <SendIcon />
                  </Tooltip>
                }
                label={t('sendDocument')}
                onClick={() => onEntityAction(row, 'send')}
                showInMenu={true}
              />
            ),
          ]),
      },
      {
        field: 'typeOfDocumentName',
        headerName: t('typeOfDocument'),
        hideable: false,
        width: 200,
        renderCell: ({ row }: GridRenderCellParams) => (
          <Tooltip title={getTypeOfDocumentName(row)}>
            <Box onClick={() => onEntityAction(row, SHOW_DOCUMENT)} sx={styles.gridLink}>
              {getTypeOfDocumentName(row)}
            </Box>
          </Tooltip>
        ),
        valueGetter: (value: any, row: any) => getTypeOfDocumentName(row),
      },
      {
        field: 'createdDateTime',
        headerName: t('createdDateTime'),
        renderCell: ({ row }: GridRenderCellParams) =>
          format(new Date(get(row, 'createdDateTime', '')), 'dd.MM.yyyy HH:mm:ss'),
        valueGetter: (value: any) => value && new Date(value),
      },
      { field: 'author', headerName: t('author'), width: 220 },
      { field: 'referenceID', headerName: t('referenceID') },
      { field: 'externalId', headerName: t('externalId'), width: 250 },
      { field: 'externalWorkplaceCode', headerName: t('externalWorkplaceCode') },
      { field: 'externalWorkplaceName', headerName: t('externalWorkplaceName') },
      { field: 'description', headerName: t('description') },
    ]),
  );
  const filterableColumns = useFilterableColumns(GRID_SETTINGS_KEY, columns);

  useEffect(() => {
    const shouldShowGrid =
      isArray(patientDocumentsByBookmark) &&
      patientDocumentsByBookmark.length &&
      isArray(columns) &&
      columns.length
        ? true
        : false;
    setShouldShowGrid(shouldShowGrid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDocumentsByBookmark.length]);

  const remapToUuids = useCallback(
    (selection: string[]) => {
      const uudis: string[] = [];
      if (isArray(patientDocumentsByBookmark) && isArray(selection)) {
        selection.forEach((element) => {
          const patientDocument = find(patientDocumentsByBookmark, { uuidex: element });
          const uuid = get(patientDocument, 'uuid', null);
          if (uuid !== null) {
            uudis.push(uuid);
          }
        });
      }
      return uudis;
    },
    [patientDocumentsByBookmark],
  );

  const prepareDocumentsForDocumentDetail = useCallback(
    (selection: string[], elementName = 'uuidex') => {
      const documents: IDocument[] = [];
      if (isArray(patientDocumentsByBookmark) && isArray(selection)) {
        selection.forEach((element) => {
          const patientDocument = find(patientDocumentsByBookmark, { [elementName]: element });
          const uuid = get(patientDocument, 'uuid', null);
          const typeOfDocument = get(patientDocument, 'typeOfDocument.name', null);
          if (uuid !== null) {
            documents.push({ uuid, typeOfDocument });
          }
        });
      }
      return { masterPatientID, facilities, documents };
    },
    [facilities, masterPatientID, patientDocumentsByBookmark],
  );

  const showDocuments = (documents: string[]) => {
    const dataForDocumentDetail = prepareDocumentsForDocumentDetail(documents, 'uuid');
    storeSelection(documents);
    storeDataForDocumentDetail(dataForDocumentDetail);
    navigate(`${window.location.pathname.replace('portal/', '')}/documents`);
  };

  const onEntityAction = (row: IPatientDocument, action: string) => {
    if (action === SHOW_DOCUMENT) {
      showDocuments([row.uuid]);
    } else if (action === 'print_document' || action === 'standardized_print_document') {
      handleClickPrint([get(row, 'uuidex', '')], action === 'standardized_print_document');
    } else if (action === 'export') {
      handleClickExport([get(row, 'uuidex', '')]);
    } else if (action === 'add_to_folder') {
      handleClickAddToFolder([get(row, 'uuidex', '')]);
    } else if (action === 'send') {
      handleClickSendDocument([get(row, 'uuidex', '')]);
    }
  };

  const handleClickOpen = useCallback(() => {
    const dataForDocumentDetail = prepareDocumentsForDocumentDetail(selection, 'uuidex');
    storeSelection(remapToUuids(selection));
    storeDataForDocumentDetail(dataForDocumentDetail);
    navigate(`${window.location.pathname.replace('portal/', '')}/documents`);
  }, [
    navigate,
    prepareDocumentsForDocumentDetail,
    remapToUuids,
    selection,
    storeDataForDocumentDetail,
    storeSelection,
  ]);

  const handleClickPrint = useCallback(
    (printSelection: string[], standardizedPrint: boolean = false) => {
      storeSelection([]);
      storeSelection(remapToUuids(printSelection));
      setStandardizedPrint(standardizedPrint);
      setIsPrinting(true);
    },
    [remapToUuids, setIsPrinting, setStandardizedPrint, storeSelection],
  );
  const handleClickExport = useCallback(
    (exportSelection: string[]) => {
      storeSelection([]);
      storeSelection(remapToUuids(exportSelection));
      setIsExporting(true);
    },
    [remapToUuids, setIsExporting, storeSelection],
  );
  const handleClickAddToFolder = useCallback(
    (addToFolderSelection: string[]) => {
      const selectedPatientDocuments: any[] = [];
      addToFolderSelection.forEach((uuidex) => {
        const patientDocument = find(patientDocumentsByBookmark, { uuidex });
        if (patientDocument) {
          selectedPatientDocuments.push({ ...patientDocument, patient });
        }
      });
      setSelectedPatientDocuments(selectedPatientDocuments);
      setFolderDialogState(true);
    },
    [patient, patientDocumentsByBookmark],
  );
  const handleClickSendDocument = useCallback(
    (sendSelection: string[]) => {
      const selectedPatientDocuments: any[] = [];
      sendSelection.forEach((uuidex) => {
        const patientDocument = find(patientDocumentsByBookmark, { uuidex });
        if (patientDocument) {
          selectedPatientDocuments.push({ ...patientDocument, patient });
        }
      });
      storeDocuments([]);
      storeDocuments(selectedPatientDocuments);
      const from = window.location.pathname.replace('portal/', '');
      if (!isEmpty(selectedPatientDocuments)) {
        navigate(`/clinicPortal/documentsSend?from=${from}`);
      }
    },
    [navigate, patient, patientDocumentsByBookmark, storeDocuments],
  );

  const functionList: IFunctionItem[] = useMemo(
    () =>
      compact([
        {
          key: SHOW_DOCUMENT,
          label: t('Grid:showDocument'),
          show: true,
          showCount: true,
          maxAllowedItem: get(maxCounts, 'maxViewDocumentsCount', 16),
          onClick: (row: GridRowModel | null) => handleClickOpen(),
          icon: <GridDetailIcon fontSize="small" />,
        },
        canPrintDocuments
          ? {
              key: 'multiplePrint',
              label: t('multiplePrint'),
              show: true,
              showCount: true,
              onClick: (row: GridRowModel | null) => handleClickPrint(selection),
              icon: <PrintIcon fontSize="small" />,
            }
          : null,
        canPrintStandardizedDocuments
          ? {
              key: 'multipleStandardizedPrint',
              label: t('multipleStandardizedPrint'),
              show: true,
              showCount: true,
              itemCount: standardizedPrintDocumentsEnabled ? selection.length : 0,
              onClick: (row: GridRowModel | null) => handleClickPrint(selection, true),
              icon: <PrintIcon fontSize="small" />,
            }
          : null,
        canExportDocuments
          ? {
              key: 'multipleExport',
              label: t('multipleExport'),
              show: true,
              showCount: true,
              maxAllowedItem: get(maxCounts, 'maxExportDocumentsCount', 5),
              onClick: (row: GridRowModel | null) => handleClickExport(selection),
              icon: <ImportExportIcon fontSize="small" />,
            }
          : null,
        canAddToFolder
          ? {
              key: 'multipleAddToFolder',
              label: t('multipleAddToFolder'),
              show: true,
              showCount: true,
              onClick: (row: GridRowModel | null) => handleClickAddToFolder(selection),
              icon: <FolderIcon fontSize="small" />,
            }
          : null,
        canSendDocumentsAsPdf || canSendDocumentsAsDicom
          ? {
              key: 'sendDocument',
              label: t('sendDocument'),
              show: true,
              showCount: true,
              itemCount: isEnabledSendOnlyPdfAndHasSelectedDicom
                ? 0
                : isEnabledSendOnlyDicomAndHasSelectedPdf
                ? 0
                : sendDocumentsEnabled
                ? selection.length
                : 0,
              onClick: (row: GridRowModel | null) => handleClickSendDocument(selection),
              icon: <SendIcon fontSize="small" />,
            }
          : null,
      ]),
    [
      canAddToFolder,
      canExportDocuments,
      canPrintDocuments,
      canPrintStandardizedDocuments,
      canSendDocumentsAsDicom,
      canSendDocumentsAsPdf,
      isEnabledSendOnlyDicomAndHasSelectedPdf,
      isEnabledSendOnlyPdfAndHasSelectedDicom,
      selection,
      sendDocumentsEnabled,
      standardizedPrintDocumentsEnabled,
      maxCounts,
      handleClickAddToFolder,
      handleClickExport,
      handleClickOpen,
      handleClickPrint,
      handleClickSendDocument,
      t,
    ],
  );

  const QuickSearchToolbar = useCallback(
    (props: any) => {
      return (
        <div>
          <Box style={{ padding: '5px', display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
            </Box>
          </Box>
          <Box
            sx={{
              px: 1,
              py: 0.5,
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            {functionList.map((item: IFunctionItem, index: number) => {
              const showCount = isUndefined(item.showCount) ? true : item.showCount;
              const itemCount = get(item, 'itemCount');
              const count = itemCount || selection.length;
              const xcount = showCount && count ? ` (${count})` : '';
              const maxAllowedItem = isUndefined(item.maxAllowedItem) ? 0 : item.maxAllowedItem;
              return (
                <Tooltip key={index} title={item.label + xcount}>
                  <span>
                    <Button
                      key={item.key}
                      size={'medium'}
                      variant="contained"
                      color="primary"
                      onClick={() => item.onClick(null)}
                      disabled={
                        (isNumber(itemCount) && itemCount === 0) ||
                        selection.length === 0 ||
                        (maxAllowedItem > 0 && maxAllowedItem < selection.length)
                      }
                      sx={{ mr: 0.5, mb: 0.5 }}
                    >
                      {item.icon}
                    </Button>
                  </span>
                </Tooltip>
              );
            })}
          </Box>
        </div>
      );
    },
    [selection, functionList],
  );

  return (
    <>
      {shouldShowGrid ? (
        <Box sx={{ flex: 1, display: 'flex', maxHeight: '100%', overflow: 'hidden' }}>
          <Papeer
            id="patient-search-results"
            sx={{ flex: 1, maxHeight: '100%', maxWidth: '100%', ...(compactMode ? { p: 0 } : {}) }}
          >
            <Box sx={{ height: '100% !important', width: '100% !important' }}>
              <DataGridPro
                sortingOrder={DATA_GRID_DEFAULT_SORTING}
                headerFilters={true}
                getRowId={getRowId}
                rowHeight={DATA_GRID_ROW_HEIGHT}
                density={compactMode ? 'compact' : 'standard'}
                rows={patientDocumentsByBookmark}
                columns={filterableColumns}
                localeText={gridLocalization}
                disableRowSelectionOnClick={true}
                slots={{ toolbar: QuickSearchToolbar }}
                slotProps={{
                  columnsManagement: {
                    getTogglableColumns,
                  },
                  headerFilterCell: {
                    height: DATA_GRID_FILTER_HEADER_ROW_HEIGHT,
                    InputComponentProps: {
                      size: 'small',
                    },
                  },
                }}
                checkboxSelection={selecting}
                onRowSelectionModelChange={(newSelectionModel: any) => {
                  setSelection(newSelectionModel);
                }}
                rowSelectionModel={selection}
                columnVisibilityModel={columnVisibilityModelWithDefaultHidden}
                onColumnVisibilityModelChange={setColumnVisibilityModel}
                onColumnWidthChange={(changedColumn: any) =>
                  changeWidthColumn(columns, changedColumn)
                }
                onColumnOrderChange={(params: any) =>
                  updateColumnOrder(columns, params, selecting ? 1 : 0)
                }
                sortModel={
                  isArray(gridSortModel) && !isEmpty(gridSortModel)
                    ? gridSortModel
                    : [{ field: 'createdDateTime', sort: 'desc' }]
                }
                onSortModelChange={sortByColumn}
              />
            </Box>
          </Papeer>
        </Box>
      ) : (
        <Typography>
          {isLoading ? <CircularProgress size={16} style={{ marginLeft: '8px' }} /> : t('noData')}
        </Typography>
      )}
      <DialogFolders
        key={'documentGridDialogFoldersKey'}
        folderDialogState={folderDialogState}
        setFolderDialogState={setFolderDialogState}
        action="documents"
        isLocked={false}
        showFoldersByUserAndStudy={false}
        selectedPatientDocuments={selectedPatientDocuments}
        selectedSeries={selectedSeries}
        setSelectedSeries={setSelectedSeries}
      />
    </>
  );
};
