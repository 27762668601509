import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';

const useAnonymizationSchema = (fields: any[]) => {
  const { requiredItemsFromObjectOrNotObject } = useValidators();

  const anonymizationSchema = requiredItemsFromObjectOrNotObject(yup.string(), fields);

  return { anonymizationSchema };
};

export default useAnonymizationSchema;
