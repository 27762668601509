import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { filter, find, get, includes, isArray, isEmpty, keys, reduce } from 'lodash';
import { useTranslation } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import { format, isDate } from 'date-fns';
import { v1 as uuidv1 } from 'uuid';
import { Box } from '@mui/system';
import { Button, Grid, Tooltip, Typography } from '@mui/material';

import useValidationSchema from './_formSearch';
import { findPatient, getNCPeHCountryConfig } from './_api';
import { INCPeHSearch, INCPeHSearchForm } from './_types';

import useAlerts from 'components/Alerts/useAlerts';
import { Papeer } from 'components/Papeer/Papeer';
import { Line } from 'components/Line/Line';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import FormSwitch from 'components/Form/Switch/Switch';
import FormDatePicker from 'components/Form/Date/Date';
import { CheckedButton } from 'components/Buttons/CheckedButton';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useActions } from 'utils/hooks/useActions';
import { downloadFile } from 'utils/hooks/useApp';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useStudy } from 'utils/hooks/useStudy';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { settingHasValue } from 'utils/products';

const NCPeHSearchForm: React.FC<INCPeHSearchForm> = ({ compactMode, setCanShowResults }) => {
  const { t } = useTranslation('NCPeH');
  const { storeDocument, storePatients, storeRequestSearchResults } = useActions();
  const { linkBack } = useStudy();
  const { portalProductSettings } = useAppInfo();

  const { addErrorAlert } = useAlerts();

  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [countries, setCountries] = useState<any[]>([]);
  const [countriesNames, setCountriesNames] = useState<any>({});
  const [showOtherFields, setShowOtherFields] = useState<boolean>(false);
  const [media, setMedia] = useState<any>({});
  const [documents, setDocuments] = useState<any[]>([]);
  const [documentTypes, setDocumentTypes] = useState<any[]>([]);
  const [idsFields, setIdsFields] = useState<any>(null);
  const [demographicsFields, setDemographicsFields] = useState<any>(null);
  const [filterDocuments, setFilterDocuments] = useState<any[]>([]);
  const [filterIdsFields, setFilterIdsFields] = useState<any[]>([]);
  const [filterDemographicsFields, setFilterDemographicsFields] = useState<any[]>([]);
  const [renderedLine, setRenderedLine] = useState<any>(null);
  const [validatorFields, setValidatorFields] = useState<any[]>([]);
  const [isNationalSearchDefaultValue, setIsNationalSearchDefaultValue] = useState<boolean>(true);

  const { currentLocale } = useLanguage();

  const { SearchFormSchema } = useValidationSchema(t, validatorFields);

  const methods = useForm<INCPeHSearch>({
    resolver: yupResolver(SearchFormSchema),
  });

  const { handleSubmit, reset, getValues, setValue, watch, register } = methods;
  const sourceReason = watch('reason');
  const sourceDocumentType = watch('documentType');
  const formValues = getValues();
  const canSearch =
    !isEmpty(formValues) &&
    selectedCountry &&
    sourceReason &&
    ((!isEmpty(documentTypes) && sourceDocumentType) || isEmpty(documentTypes));

  const { toggleLoader } = useAppGlobals();

  const backFromDetail = linkBack();

  const accessReasons = [
    { id: 'TREATMENT', label: t('treatment') },
    { id: 'EMERGENCY', label: t('emergency') },
  ];

  const submitHandler = async (formValues: any) => {
    toggleLoader();
    let patientIds: any[] = [];
    let demographics: any[] = [];
    keys(formValues).forEach((key) => {
      if (key.substring(0, 4) === 'ids_') {
        const xroot: any = key.substring(4);
        const root = xroot.replaceAll('_', '.');
        const extension = get(formValues, key, '');
        patientIds = [...patientIds, { root, extension }];
      } else if (!includes(['country', 'documentType', 'reason'], key)) {
        const value = get(formValues, key, '');
        const dateFormat = 'dd-MM-yyyy';
        demographics = [
          ...demographics,
          { label: key, value: isDate(value) ? format(value, dateFormat) : value },
        ];
      }
    });

    const values = {
      purposeOfUse: formValues.reason,
      country: formValues.country,
      patientIds,
      ...(isEmpty(demographics) ? {} : { demographics }),
      isNationalSearch: !get(formValues, 'isNationalSearch', false),
    };
    const requestForFindDocuments = {
      ...values,
      documentType: formValues.documentType,
    };
    storeRequestSearchResults(requestForFindDocuments);
    const resp = await findPatient(values);
    storePatients([]);
    if (isArray(resp)) {
      const patientResults = resp.map((item) => ({
        ...item,
        id: item?.id ? item?.id : uuidv1(),
      }));
      storePatients(patientResults);
      setCanShowResults(true);
    } else {
      const data = get(resp, 'data');
      const status = get(resp, 'status');
      const statusText = get(resp, 'statusText');
      const message = get(data, 'reasonPhrase', '')
        ? get(data, 'reasonPhrase', '')
        : get(data, 'originalFaultCode', '')
        ? get(data, 'originalFaultCode', '')
        : get(data, 'message', '')
        ? get(data, 'message', '')
        : t('unknownError');
      const code = get(data, 'reasonCode', '');
      const txt = code
        ? t('submitErrorNCPeH', { message, code })
        : t('submitError', { message: statusText, code: status });
      addErrorAlert(txt);
    }
    toggleLoader(false);
  };

  const loadEntities = async () => {
    toggleLoader();
    if (!backFromDetail) {
      storePatients(null);
    } else {
      setValue('country', null);
    }
    const config = await getNCPeHCountryConfig();

    if (config) {
      const countries = get(config, 'countries');
      if (!isEmpty(countries)) {
        const sortedCountries = countries.sort((f1: any, f2: any) =>
          f1.name.toLowerCase().localeCompare(f2.name.toLowerCase()),
        );
        setCountries(sortedCountries);
        setCountriesNames(
          reduce(
            sortedCountries,
            (result: any, country: any) => {
              result[country['code']] = country['name'];
              return result;
            },
            {},
          ),
        );
        let defaultCountry = find(countries, { code: 'CZ' });
        if (!defaultCountry) {
          defaultCountry = countries[0];
        }
        if (defaultCountry) {
          setSelectedCountry(get(defaultCountry, 'code', ''));
        }
      }
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntities();
    const isNationalSearchDefaultValue = settingHasValue(
      portalProductSettings,
      'isNationalSearchDefaultValue',
      'true',
    );
    setIsNationalSearchDefaultValue(isNationalSearchDefaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkDocumentType = (value: any) => {
    setValue('documentType', value);
  };
  const downloadPdf = (document: any) => {
    const base64PdfString = get(document, 'content');
    const fileName = get(document, 'fileName');
    if (base64PdfString) {
      downloadFile(base64PdfString, fileName);
    }
  };

  useEffect(() => {
    storePatients([]);
    storeDocument(null);
    setValue('country', selectedCountry);
    let showOtherFields = false;
    let idsFields = null;
    let demographicsFields = null;
    if (selectedCountry) {
      reset({
        country: selectedCountry,
        documentType: sourceDocumentType,
        reason: sourceReason,
        isNationalSearch: !isNationalSearchDefaultValue,
      });

      const country = find(countries, { code: selectedCountry });
      if (country) {
        storeDocument(get(country, 'form.searchFields.document', null));
        let media = {};
        const mediaName = get(country, 'form.searchFields.patient.media.name', '')
          ? get(country, 'form.searchFields.patient.media.name')
          : '';
        const mediaEnabledFor = get(country, 'form.searchFields.patient.media.enabledFor', '')
          ? get(country, 'form.searchFields.patient.media.enabledFor')
          : '';
        const mediaFileExtension = (
          get(country, 'form.searchFields.patient.media.fileExtension', '')
            ? get(country, 'form.searchFields.patient.media.fileExtension')
            : ''
        ).toLowerCase();
        const mediaContent = get(country, 'form.searchFields.patient.media.content', '')
          ? get(country, 'form.searchFields.patient.media.content')
          : '';
        if (mediaFileExtension && mediaContent) {
          media = {
            name: mediaName,
            enabledFor: mediaEnabledFor,
            img: `data:image/${mediaFileExtension};base64, ${mediaContent}`,
          };
        }
        setMedia(media);

        const documents: any[] = [];
        const patientInformationNotices = get(country, 'patientInformationNotices', []);
        patientInformationNotices.forEach((item: any) => {
          const docName = get(item, 'name', '') ? get(item, 'name') : '';
          const docDescription = get(item, 'description', '') ? get(item, 'description') : '';
          const docEnabledFor = get(item, 'enabledFor', '') ? get(item, 'enabledFor') : '';
          const docFileExtension = (
            get(item, 'fileExtension', '') ? get(item, 'fileExtension') : ''
          ).toLowerCase();
          const docContent = get(item, 'content', '') ? get(item, 'content') : '';
          if (docFileExtension.toLowerCase() === 'pdf' && docContent && docName) {
            const document = {
              fileName: `${docName}.${docFileExtension}`,
              description: docDescription,
              enabledFor: docEnabledFor,
              content: docContent,
            };
            documents.push(document);
          }
        });
        setDocuments(documents);

        const allowedDocumentTypes = get(country, 'form.allowedDocumentTypes', []);

        const documentTypes = allowedDocumentTypes.map((documentType: any) => ({
          id: documentType,
          label: t(documentType),
        }));

        setDocumentTypes(documentTypes);
        if (allowedDocumentTypes.length > 0) {
          checkDocumentType(allowedDocumentTypes[0]);
        } else {
          checkDocumentType('');
        }

        const patientIds = get(country, 'form.searchFields.patient.ids', []);
        idsFields = (isArray(patientIds) ? patientIds : []).map((item: any, index: any) => {
          const mandatory = get(item, 'mandatory', false);
          const itemFormat = get(item, 'format', '');
          const label = get(
            get(item, 'label'),
            currentLocale,
            get(get(item, 'label'), 'en', get(get(item, 'label'), 'cz', '?')),
          );
          return {
            name: 'ids_' + get(item, 'domain', index).replaceAll('.', '_'),
            label,
            required: mandatory,
            itemFormat,
            enabledFor: get(item, 'enabledFor', ''),
          };
        });
        const patientDemographics = get(country, 'form.searchFields.patient.demographics', []);
        demographicsFields = (isArray(patientDemographics) ? patientDemographics : []).map(
          (item: any, index: any) => {
            const type = get(item, 'type', 'String');
            const mandatory = get(item, 'mandatory', false);
            const itemFormat = get(item, 'format', '');
            const label = get(
              get(item, 'label'),
              currentLocale,
              get(get(item, 'label'), 'en', get(get(item, 'label'), 'cz', '?')),
            );
            return {
              name: get(item, 'contextualDescription', `index_${index}`),
              label,
              required: mandatory,
              ...(type !== 'Date' ? { itemFormat } : {}),
              type: type === 'Date' ? 'datePicker' : null,
              enabledFor: get(item, 'enabledFor', ''),
            };
          },
        );
      }
      showOtherFields = true;
    }
    setIdsFields(idsFields);
    setDemographicsFields(demographicsFields);
    setShowOtherFields(showOtherFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  useEffect(() => {
    const filterDocuments = filter(
      documents,
      (item) => get(item, 'enabledFor') === 'ALL' || get(item, 'enabledFor') === sourceDocumentType,
    );
    const filterIdsFields = filter(
      idsFields,
      (item) => get(item, 'enabledFor') === 'ALL' || get(item, 'enabledFor') === sourceDocumentType,
    );
    const filterDemographicsFields = filter(
      demographicsFields,
      (item) => get(item, 'enabledFor') === 'ALL' || get(item, 'enabledFor') === sourceDocumentType,
    );
    const validatorFields: any[] = [];
    filterIdsFields.forEach((item) => {
      validatorFields.push({
        name: item.name,
        allowedChars: get(item, 'itemFormat', '.'),
        required: get(item, 'required', false),
      });
    });
    filterDemographicsFields.forEach((item) => {
      validatorFields.push({
        name: item.name,
        allowedChars: get(item, 'itemFormat', '.'),
        required: get(item, 'required', false),
      });
    });
    setValidatorFields(validatorFields);

    setFilterDocuments(filterDocuments);
    setFilterIdsFields(filterIdsFields);
    setFilterDemographicsFields(filterDemographicsFields);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceDocumentType, documents]);

  useEffect(() => {
    setRenderedLine(<Line topMargin={compactMode ? 1 : 2} bottomMargin={compactMode ? 1 : 2} />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compactMode]);

  const setCountry = (code: any) => {
    setSelectedCountry(code);
  };

  const checkReason = (value: any) => {
    setValue('reason', value);
  };

  return (
    <Box sx={{ position: 'relative', mb: 1 }}>
      {!isEmpty(countries) ? (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <input {...register('country')} type="hidden" />
            <input {...register('reason')} type="hidden" />
            <Box sx={{ position: 'relative' }}>
              <Box sx={{ position: 'relative', zIndex: 1040 }}>
                <Grid container={true} spacing={1}>
                  <Grid item={true} xs={12} md={8}>
                    <Papeer>
                      <Grid container={true} spacing={1} alignItems="flex-start">
                        <Grid item={true} xs={12} md={6}>
                          <Box sx={{ mb: 2 }}>
                            <ReactFlagsSelect
                              countries={keys(countriesNames)}
                              customLabels={countriesNames}
                              selected={selectedCountry}
                              onSelect={setCountry}
                              placeholder="Select Language"
                              selectedSize={18}
                              optionsSize={14}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      {showOtherFields && !isEmpty(documentTypes) && (
                        <Grid container={true} spacing={8} alignItems="flex-start">
                          <Grid item={true} xs={12}>
                            <Box>{t('documentTypes')}</Box>
                            {documentTypes.map((source: any) => (
                              <CheckedButton
                                size="large"
                                key={source.id}
                                active={sourceDocumentType === source.id}
                                rightMargin={true}
                                onClick={() =>
                                  checkDocumentType(
                                    sourceDocumentType === source.id ? null : source.id,
                                  )
                                }
                              >
                                {source.label}
                              </CheckedButton>
                            ))}
                          </Grid>
                        </Grid>
                      )}
                      {showOtherFields && (
                        <Grid container={true} spacing={8} alignItems="flex-start">
                          <Grid item={true} xs={12} sx={{ mt: 1 }}>
                            <Box>{t('accessReasons')}</Box>
                            {accessReasons.map((source: any) => (
                              <CheckedButton
                                size="large"
                                key={source.id}
                                active={sourceReason === source.id}
                                rightMargin={true}
                                onClick={() => {
                                  checkReason(sourceReason === source.id ? null : source.id);
                                }}
                              >
                                {source.label}
                              </CheckedButton>
                            ))}
                          </Grid>
                        </Grid>
                      )}
                      {showOtherFields &&
                        (!isEmpty(filterIdsFields) || !isEmpty(filterDemographicsFields)) &&
                        renderedLine}
                      <Grid container={true} spacing={1} alignItems="flex-start">
                        {showOtherFields &&
                          !isEmpty(filterIdsFields) &&
                          filterIdsFields.map((field: any) => (
                            <Grid item={true} xs={12} md={6} key={field.name}>
                              {field.type === 'select' && (
                                <FormSelect
                                  name={get(field, 'name')}
                                  label={get(field, 'label')}
                                  items={get(field, 'items')}
                                />
                              )}
                              {get(field, 'type') === 'datePicker' && (
                                <FormDatePicker name={field.name} label={field.label} />
                              )}
                              {!get(field, 'type') && <FormInput {...field} />}
                            </Grid>
                          ))}
                        {showOtherFields &&
                          !isEmpty(filterDemographicsFields) &&
                          filterDemographicsFields.map((field: any) => (
                            <Grid item={true} xs={12} md={6} key={field.name}>
                              {field.type === 'select' && (
                                <FormSelect
                                  name={get(field, 'name')}
                                  label={get(field, 'label')}
                                  items={get(field, 'items')}
                                />
                              )}
                              {get(field, 'type') === 'datePicker' && (
                                <FormDatePicker name={field.name} label={field.label} />
                              )}
                              {!get(field, 'type') && <FormInput {...field} />}
                            </Grid>
                          ))}
                      </Grid>
                      <Box sx={{ pt: 1 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          size="medium"
                          disabled={!canSearch}
                          className="search-form-button"
                          sx={{ mr: 3 }}
                        >
                          {t('search')}
                        </Button>
                        <Tooltip title={t('nationalSearchTooltip')} placement="top">
                          <Box component="span">
                            <FormSwitch name="isNationalSearch" label={t('nationalSearch')} />
                          </Box>
                        </Tooltip>
                      </Box>
                    </Papeer>
                  </Grid>

                  <Grid item={true} xs={12} md={4}>
                    <Papeer>
                      <Typography variant="h6">{t('examples')}</Typography>
                      {!isEmpty(media) &&
                        (get(media, 'enabledFor') === 'ALL' ||
                          get(media, 'enabledFor') === sourceDocumentType) && (
                          <>
                            <div>{media.name}</div>
                            <img
                              src={media.img}
                              style={{ maxWidth: '100%', height: 'auto' }}
                              alt={media.name}
                            />
                          </>
                        )}
                      {!isEmpty(filterDocuments) && (
                        <Typography variant="h6">{t('infoForPatient')}</Typography>
                      )}
                      {filterDocuments.map((item) => (
                        <div key={item.fileName}>
                          <Button
                            onClick={() => downloadPdf(item)}
                            variant="text"
                            sx={{
                              textAlign: 'left',
                              textTransform: 'none',
                              p: 0,
                            }}
                          >
                            {get(item, 'description', '?')}
                          </Button>
                        </div>
                      ))}
                    </Papeer>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        </FormProvider>
      ) : null}
    </Box>
  );
};

export default NCPeHSearchForm;
