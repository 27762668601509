import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get, isArray } from 'lodash';
import { Tooltip } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getAllStations, removeStation } from './_apiStations';
import StationForm from './StationForm';
import Header from 'components/Header/Header';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'stationsMUI';

const Stations: React.FC = () => {
  const { t } = useTranslation('Stations');
  useWithTitle();
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();
  const { toggleLoader } = useAppGlobals();

  const [dialogState, setDialogState] = useState<boolean>(false);
  const [stationId, setStationId] = useState<number>(0);
  const [stations, setStations] = useState<any[]>([]);

  const loadEntity = async () => {
    toggleLoader();
    setStationId(0);
    try {
      const stations = await getAllStations();
      if (stations && isArray(stations)) {
        setStations(stations);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  const { onEntityRemove } = useEntityRemove(removeStation, t, loadEntity);
  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 120,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                label={t('Grid:edit')}
                onClick={() => onEntityEdit(get(row, 'id'))}
              />
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:delete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('Grid:delete')}
                onClick={() => {
                  dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }));
                }}
                disabled={
                  get(row, 'state', null) === 'NEW' ||
                  !get(row, 'canDelete', true) ||
                  get(row, 'system', false)
                }
              />
            </>
          );
        },
      },
      { field: 'code', headerName: t('code') },
      { field: 'aetitle', headerName: t('aetitle') },
      {
        field: 'modality',
        headerName: t('modality'),
        valueGetter: (value: any, row: any) => get(row, 'modality.name', ''),
      },
      {
        field: 'unit',
        headerName: t('unit'),
        valueGetter: (value: any, row: any) => get(row, 'unit.name', ''),
      },
    ]),
  );

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDialog = (state: boolean = false) => {
    setDialogState(state);
  };

  const onEntityEdit = async (id: number) => {
    setStationId(id);
    toggleDialog(id > 0 ? true : false);
  };

  const addItemInDialog = () => {
    setStationId(0);
    toggleDialog(true);
  };

  const handleClose = () => {
    toggleDialog(false);
    setStationId(0);
  };

  const id = stationId ? stationId : dialogState ? 'create' : null;

  const renderedDialog = (
    <OverflowedDialog
      open={dialogState}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <StationForm toggleDialog={toggleDialog} id={id} loadEntity={loadEntity} />
    </OverflowedDialog>
  );

  return (
    <>
      <Header
        title=""
        addItemInDialog={addItemInDialog}
        toggleDialogText={t('add')}
        topMargin={true}
      />
      <MuiGrid
        gridKey={muiGridKey}
        rows={stations}
        columns={columns}
        initialSortMode={[{ field: 'aetitle', sort: 'asc' }]}
      />
      {renderedDialog}
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmAction}
        />
      )}
    </>
  );
};

export default Stations;
