import { useTranslation } from 'react-i18next';
import { get, isArray, sortBy } from 'lodash';
import { getAnonymizationProfiles } from 'modules/Studies/Anonymization/_api';
import { IAnonymizationField } from 'modules/Studies/Anonymization/_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';

export const useAnonymizationProfiles = () => {
  const { t } = useTranslation('StudyAnonymization');
  const { toggleLoader } = useAppGlobals();

  const loadProfiles = async () => {
    toggleLoader();
    const defaultSelectItem = { id: '0', label: t('noAnonymization') };
    let selectItems = [defaultSelectItem];
    let profiles: any[] = [];
    const profilesFromApi = await getAnonymizationProfiles();
    if (isArray(profilesFromApi)) {
      profiles = (profilesFromApi || []).map((profile) => {
        let fields: IAnonymizationField[] = [];
        const values = profile.values;
        if (values != null && values.length > 0) {
          fields = sortBy(
            values.map((val: any) => {
              const dateType: any = 'type';
              const dateItems: any = 'items';
              let field = {
                name: `anonymized_${get(val, 'name', '')}`,
                label: t(get(val, 'name', '')),
                key: get(val, 'name', ''),
                position: 2,
                required: true,
              };
              const type = get(val, 'type');
              if (type === 'date') {
                field = { ...field, [dateType]: 'datePicker', position: 4 };
              }
              if (type === 'set') {
                const items = get(val, 'set', []).map((item: any) => ({
                  id: item,
                  label: item,
                  key: item,
                }));
                field = { ...field, [dateType]: 'select', [dateItems]: items, position: 3 };
              }
              if (type === 'name') {
                field = { ...field, [dateType]: 'name', position: 1 };
              }
              return field;
            }),
            ['position'],
          );
        }

        const repairedFields: IAnonymizationField[] = [];
        fields.forEach((field) => {
          const type = get(field, 'type', null);
          if (type === 'name') {
            ['lastName', 'firstName', 'middleName', 'prefix', 'suffix'].forEach((item) => {
              repairedFields.push({
                name: `anonymized_${item}`,
                label: t(item),
                key: item,
                position: 2,
                required: item === 'lastName',
              });
            });
          } else {
            repairedFields.push(field);
          }
        });

        return {
          ...profile,
          fields: repairedFields,
        };
      });

      selectItems = [
        ...[defaultSelectItem],
        ...profilesFromApi.map((profile) => {
          return {
            id: get(profile, 'name', 'noName'),
            label: `${get(profile, 'name', t('noName'))} ${
              get(profile, 'description', '') !== '' ? `(${get(profile, 'description', '')})` : ''
            }`,
          };
        }),
      ];
    }
    toggleLoader(false);
    return { profiles, selectItems };
  };

  return { loadProfiles };
};
