import React from 'react';
import { useTranslation } from 'react-i18next';
import QueueSearchForm from './QueueSearchForm';
import QueueSearchResults from './QueueSearchResults';
import Header from 'components/Header/Header';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourQueueRequests } from './TourQueueRequests';

const QueueRequests: React.FC = () => {
  const { t } = useTranslation('Queues');
  useWithTitle(); // sets title to document
  const renderedSteps = <TourQueueRequests />;
  return (
    <>
      <Header title={t('stateOfQueue')} TourComponent={renderedSteps} />
      <QueueSearchForm />
      <QueueSearchResults />
    </>
  );
};

export default QueueRequests;
