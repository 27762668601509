import { api } from 'utils/hooks/useApi';
import { get } from 'lodash';
import { IPatient } from 'modules/Search/_types';
import { IPatientManagementSearchForm } from './NonDicom/_types';

export const sendMetadata = (data: any): Promise<any> =>
  api()
    .post(`/portal-api/studyImport/metadata`, data)
    .then((response) => response)
    .catch((error) => false);

export const getRules = (): any =>
  api()
    .get(`/portal-api/studyImport/getRules`)
    .then((response) => {
      const data = get(response, 'data', false);
      return data;
    })
    .catch((error) => false);

export const getProductsByTypeAndFunction = (
  productType: string,
  searchFunction: string,
): Promise<any> =>
  api()
    .get(`/portal-api/product/getProductsByTypeAndFunction`, {
      params: { productType, function: searchFunction },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getPatientsFromPatientManagement = (
  data: IPatientManagementSearchForm,
): Promise<IPatient[] | boolean> =>
  api()
    .post(`/portal-api/studyImport/getPatientsFromPatientManagement`, data)
    .then((response) => {
      return get(response, 'data');
    })
    .catch((error) => false);

export const getPatientInformation = (patientID: string): Promise<any> =>
  api()
    .get(`/portal-api/studyImport/getPatientInformation`, {
      params: { patientID },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);
