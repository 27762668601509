import React from 'react';
import { Box } from '@mui/system';
import { Paper, Typography } from '@mui/material';
import { IWhiteBox } from './_types';

export const WhiteBox: React.FC<IWhiteBox> = ({
  sx = {},
  title,
  children,
  bottomMargin = false,
  topMargin = false,
  displayBlockInline = false,
  bodyWithoutTop = false,
  button,
}) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        pt: 1,
        mb: bottomMargin ? 2 : 0,
        mt: topMargin ? 2 : 0,
        ...sx,
      }}
    >
      {title ? (
        <Typography
          variant="subtitle1"
          sx={{
            borderBottom: '1px solid #ddd',
            display: 'flex',
            mb: 2,
            pb: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            ...(displayBlockInline ? { display: 'block' } : {}),
          }}
        >
          {title}
          {button ? (
            <Box
              sx={{
                ml: 1,
                ...(displayBlockInline ? { display: 'block' } : {}),
              }}
            >
              {button}
            </Box>
          ) : null}
        </Typography>
      ) : null}
      <Box
        component="div"
        sx={{
          ...(bodyWithoutTop ? { pt: 0 } : {}),
        }}
      >
        {children}
      </Box>
    </Paper>
  );
};
