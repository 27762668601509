import { TFunction } from 'i18next';
import { get, isObject } from 'lodash';
import { useWithTitle } from './useWithTitle';

export const useWithEntityTitle = (
  isCreating: boolean,
  entity: any,
  t: TFunction,
  createSlug: string = 'create',
  editSlug: string = 'edit',
) => {
  const { setPageTitle } = useWithTitle();
  const name = isCreating ? '' : get(entity, 'name', '');
  const title = isCreating ? t(createSlug) : `${t(editSlug)} ${isObject(name) ? '' : name}`;
  setPageTitle(title);
  return { title };
};

// TODO: feConfig not loaded to store !
