import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';
import { get } from 'lodash';
import { useValidation } from 'utils/hooks/useValidation';
import {
  DATE_BTN_TODAY,
  DATE_BTN_YESTERDAY,
  DATE_BTN_LAST_THREE_DAYS,
  DATE_BTN_LAST_WEEK,
  DATE_BTN_LAST_MONTH,
  DATE_BTN_CUSTOM_DATE,
} from 'constants/constants';

const useValidationSchema = (t: TFunction, validators: any) => {
  const { required, min, allowedCharsWithListAllowedChars } = useValidators();
  const {
    allRequired,
    patientRequired,
    allMin,
    patientMin,
    allAllowedChars,
    patientAllowedChars,
    allMessage,
    patientMessage,
    allMatches,
    patientMatches,
  } = useValidation(t, validators);

  const SearchFormSchema = yup.object().shape({
    patientId: allRequired('patient.identification')
      ? allowedCharsWithListAllowedChars(
          min(required(yup.string()), allMin('patient.identification')),
          allAllowedChars('patient.identification'),
          allMessage('patient.identification'),
        )
      : yup
          .string()
          .notRequired()
          .test('identificationNumber', allMessage('patient.identification'), function (value) {
            if (!!value) {
              const schema = yup
                .string()
                .min(allMin('patient.identification'))
                .matches(allMatches('patient.identification', true));
              return schema.isValidSync(value);
            }
            return true;
          }),
    lastName: patientRequired('lastName')
      ? allowedCharsWithListAllowedChars(
          min(required(yup.string()), patientMin('lastName')),
          patientAllowedChars('lastName'),
          patientMessage('lastName'),
        )
      : yup
          .string()
          .notRequired()
          .test('lastName', patientMessage('lastName'), function (value) {
            if (!!value) {
              const schema = yup
                .string()
                .min(patientMin('lastName'))
                .matches(patientMatches('lastName'));
              return schema.isValidSync(value);
            }
            return true;
          }),
    firstName: patientRequired('firstName')
      ? allowedCharsWithListAllowedChars(
          min(required(yup.string()), patientMin('firstName')),
          patientAllowedChars('firstName'),
          patientMessage('firstName'),
        )
      : yup
          .string()
          .notRequired()
          .test('firstName', patientMessage('firstName'), function (value) {
            if (!!value) {
              const schema = yup
                .string()
                .min(patientMin('firstName'))
                .matches(patientMatches('firstName'));
              return schema.isValidSync(value);
            }
            return true;
          }),

    accessionNumber: allRequired('accessionNumber')
      ? allowedCharsWithListAllowedChars(
          min(required(yup.string()), allMin('accessionNumber')),
          allAllowedChars('accessionNumber'),
          allMessage('accessionNumber'),
        )
      : yup
          .string()
          .notRequired()
          .test('accessionNumber', allMessage('accessionNumber'), function (value) {
            if (!!value) {
              const schema = yup
                .string()
                .min(allMin('accessionNumber'))
                .matches(allMatches('accessionNumber', true));
              return schema.isValidSync(value);
            }
            return true;
          }),
  });

  const fields = [
    {
      type: 'input',
      name: 'patientId',
      label: t('patientID'),
      inputProps: { maxLength: get(validators, 'patient.identification.max', 64) },
      InputProps: { sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } },
    },
    {
      type: 'input',
      name: 'lastName',
      label: t('lastName'),
      inputProps: { maxLength: get(validators, 'patient.name.lastName.max', 64) },
      InputProps: { sx: { borderRadius: 0 } },
    },
    {
      type: 'input',
      name: 'firstName',
      label: t('firstName'),
      inputProps: { maxLength: get(validators, 'patient.name.firstName.max', 64) },
      InputProps: { sx: { borderRadius: 0 } },
    },
    {
      type: 'select',
      name: 'modalities',
      label: t('modality'),
    },
    {
      type: 'input',
      name: 'accessionNumber',
      label: t('accessionNumber'),
      inputProps: { maxLength: get(validators, 'accessionNumber.max', 64) },
      InputProps: { sx: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } },
    },
  ];

  const dateSelectItems = [
    DATE_BTN_TODAY,
    DATE_BTN_YESTERDAY,
    DATE_BTN_LAST_THREE_DAYS,
    DATE_BTN_LAST_WEEK,
    DATE_BTN_LAST_MONTH,
    DATE_BTN_CUSTOM_DATE,
  ].map((id) => ({ id, label: t(id) }));

  return { SearchFormSchema, fields, dateSelectItems };
};

export default useValidationSchema;
