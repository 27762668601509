import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';

const useValidationSchema = () => {
  const { required } = useValidators();

  const SeriesFormSchema = yup.object().shape({
    reason: required(yup.string()),
  });

  return { SeriesFormSchema };
};

export default useValidationSchema;
