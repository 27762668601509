import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import {
  filter,
  find,
  get,
  groupBy,
  isArray,
  isEmpty,
  keys,
  mapValues,
  omit,
  pick,
  sortBy,
} from 'lodash';

import { getWorkplace, editWorkplace, createWorkplace, getAllRolesBySections } from './_api';
import { ISection, IWorkplace, IWorkplaceForm } from './_types';
import { getAllLanguages } from '../GeneralSettings/_apiLanguages';
import { ILanguage } from '../GeneralSettings/_types';
import { IProduct } from '../Products/_types';
import { getProductByType } from '../Products/_api';

import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { useLanguage } from 'utils/hooks/useLanguage';

import useValidationSchema from './_form';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import { ISelectItem } from 'components/Form/Select/_types';
import { SimpleCheckboxArray } from 'components/Form/SimpleCheckboxArray/SimpleCheckboxArray';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import useAlerts from 'components/Alerts/useAlerts';
import { Line } from 'components/Line/Line';
import WorkplaceFacility from './WorkplaceFacility';
import WorkplaceArchiveActions from './WorkplaceArchiveActions';
import { getAuthConfig } from 'modules/Login/_api';

const compactMode = false;

export const WorkplaceForm: React.FC = () => {
  const { t } = useTranslation('Workplaces');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { id, isCreating } = useEntityInfo();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const [userDirectoryId, setUserDirectoryId] = useState<any>(null);
  const [entity, fetchEntity] = useState<IWorkplace>();
  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);
  const [editingDatabaseWorkPlace, setEditingDatabaseWorkPlace] = useState<boolean>(true);
  const [linkBack, setLinkBack] = useState<string>('/administration/settings?tab=workplaces');
  const [languages, setLanguages] = useState<any[]>([]);
  const [archives, fetchArchives] = useState<any[]>([]);
  const [sections, fetchSections] = useState<any[]>([]);
  const [viewerItems, setViewerItems] = useState<ISelectItem[]>([]);
  const [activeTab, setActiveTab] = useState<string>('TAB_ROLES');
  const [originalArchives, setOriginalArchives] = useState<any[]>([]);

  const { currentLocale } = useLanguage();
  const { title } = useWithEntityTitle(isCreating, entity, t);

  const { WorkplaceFormSchema, formItems, languagesItems } = useValidationSchema(
    t,
    languages,
    editingDatabaseWorkPlace,
  );

  const methods = useForm<IWorkplaceForm>({
    resolver: yupResolver(WorkplaceFormSchema),
  });
  const { handleSubmit, reset, watch, register } = methods;
  const code = watch('code');

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    let roles: any = [];
    if (sections) {
      sections.forEach((section: ISection) => {
        if (section) {
          const key = `sections_${get(section, 'name')}`;
          const value = get(values, key, []);
          if (!isEmpty(value)) {
            roles = [...roles, ...value];
          }
        }
      });
    }
    let text = {};
    (languages || []).forEach((lang: any) => {
      const abbr = get(lang, 'abbreviation');
      const key = `text_${abbr}`;
      const value = get(values, key, null);
      text = { ...text, [abbr]: value };
    });
    const workplace: IWorkplace = {
      archives: get(values, 'archives', null)
        ? get(values, 'archives', []).map((archive: any) => ({
            ...pick(archive, ['id']),
          }))
        : [],
      code: get(values, 'code', ''),
      id: get(entity, 'id', 0),
      roles,
      text: JSON.stringify(text),
      viewer: get(values, 'viewer_id', null) ? { id: get(values, 'viewer_id') || null } : null,
      originalCode: get(entity, 'code', null),
    };
    const fn = isCreating ? createWorkplace : editWorkplace;
    const response = await fn(workplace, userDirectoryId);
    if (response) {
      addSuccessAlert(t('saved'));
      navigate(linkBack);
    } else {
      addErrorAlert(t('errorSaving'));
    }
    toggleLoader(false);
  });

  const getEntities = async () => {
    toggleLoader();
    try {
      let languages = null;
      const languagesLists: ILanguage[] = await getAllLanguages();
      if (isArray(languagesLists)) {
        languages = filter(languagesLists, { active: true });
        setLanguages(languages);
      }

      const viewersLists: IProduct[] = await getProductByType('viewer');
      if (isArray(viewersLists)) {
        const items = [];
        items.push({ id: null, label: t('viewer') });
        viewersLists.forEach((item: IProduct) => {
          items.push({ id: get(item, 'id', null), label: get(item, 'name', '') });
        });
        setViewerItems(items);
      }

      const [sections, archives] = await Promise.all([
        getAllRolesBySections(),
        getProductByType('archive'),
      ]);
      if (isArray(archives)) {
        fetchArchives(
          archives.map((archive) => ({
            ...omit(archive, ['systemSetting']),
          })),
        );
      }
      if (isArray(sections)) {
        fetchSections(
          sortBy(sections, (section) => {
            return get(
              get(section, 'text', false) ? JSON.parse(get(section, 'text', '')) : {},
              currentLocale,
              get(section, 'name'),
            );
          }),
        );
      }

      const activeTab = searchParams.get('userDirectoryId') || null;
      const parCode = searchParams.get('parCode') || null;
      if (activeTab != null) {
        setLinkBack(
          linkBack + '&activeTabId=' + activeTab + (parCode ? '&parCode=' + parCode : ''),
        );
      }
      setUserDirectoryId(activeTab);
      const authConfig = await getAuthConfig();
      const allowedAuthMethods = get(authConfig, 'allowedAuthMethods', []);
      const activetabAllowedMethod = find(allowedAuthMethods, (config) => {
        return get(config, 'id') === activeTab;
      });
      const editingDatabaseWorkPlace = get(activetabAllowedMethod, 'type') === 'db';
      setEditingDatabaseWorkPlace(editingDatabaseWorkPlace);

      let workplace: IWorkplace = {
        id: 0,
        text: '',
        archives: null,
        browser: '',
        code: '',
        roles: null,
        sections: null,
      };

      if (!isCreating && id) {
        const entity = await getWorkplace(
          id.toString(),
          activeTab !== null && activeTab !== '' && typeof activeTab === 'string'
            ? activeTab
            : 'db',
        );
        const selectedRoles = get(entity, 'roles', []);
        const grouped = mapValues(groupBy(selectedRoles, 'section.name'), (clist) =>
          clist.map((role) => omit(role, 'section.name')),
        );

        let defaultValues: IWorkplace = {};
        (languages || []).forEach((lang: any) => {
          const key = `text_${get(lang, 'abbreviation')}`;
          const value = get(entity, `text.${lang.abbreviation}`, '');
          defaultValues = { ...defaultValues, [key]: value };
        });
        if (grouped) {
          keys(grouped).forEach((section: string) => {
            if (section) {
              const key = `sections_${section}`;
              const value = grouped[section];
              defaultValues = { ...defaultValues, [key]: value };
            }
          });
        }

        defaultValues = {
          ...defaultValues,
          viewer_id: get(entity, 'viewer.id'),
        };
        workplace = { ...(typeof entity === 'object' ? entity : {}), ...defaultValues };
        if (entity) {
          fetchEntity(workplace);
          const originalArchives = get(entity, 'archives')
            ? get(entity, 'archives', []).map((archive: any) => ({ id: archive.id }))
            : [];
          setOriginalArchives(originalArchives);
        }
      }
      reset({ ...workplace });
      setIsFetchingEntity(false);
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultItems = {
    viewer_id: viewerItems,
  };

  return (
    <>
      {!isFetchingEntity ? (
        <>
          <Header title={title} />
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <input {...register('id')} type="hidden" />
                <Grid container={true} spacing={2}>
                  {editingDatabaseWorkPlace &&
                    languagesItems.map((formItem, index) => {
                      return (
                        <Grid
                          item={true}
                          xs={12}
                          md={6}
                          lg={3}
                          xl={2}
                          key={`languageItem-${index}`}
                        >
                          <FormInput {...formItem} />
                        </Grid>
                      );
                    })}
                  {formItems.map((formItem, index) => {
                    const type = get(formItem, 'type', 'text');
                    const name = get(formItem, 'name', '');
                    const items: ISelectItem[] = get(defaultItems, name, []);
                    return editingDatabaseWorkPlace || name === 'code' ? (
                      <Grid item={true} xs={12} md={6} lg={3} xl={2} key={`formItem-${index}`}>
                        {type === 'select' ? (
                          <FormSelect {...formItem} items={items} />
                        ) : (
                          <FormInput {...formItem} />
                        )}
                      </Grid>
                    ) : null;
                  })}

                  {editingDatabaseWorkPlace && (
                    <Grid item={true} xs={12}>
                      <SimpleCheckboxArray
                        name="archives"
                        label={t('allowedArchives')}
                        items={archives}
                        defaultValue={get(entity, 'archives', [])}
                        grid={compactMode ? {} : { xs: 12, md: 6, lg: 2, xl: 2 }}
                      />
                    </Grid>
                  )}
                </Grid>
                {editingDatabaseWorkPlace && (
                  <>
                    <AppBar position="static" color="default" sx={{ mt: 1 }}>
                      <Tabs
                        value={activeTab}
                        onChange={(event, value) => setActiveTab(value)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons={false}
                      >
                        <Tab value="TAB_ROLES" label={t('roles')} />
                        <Tab value="TAB_FACILITIES" label={t('userProfileFacilities')} />
                        <Tab value="TAB_ARCHIVE_ACTIONS" label={t('archiveActions')} />
                      </Tabs>
                    </AppBar>
                    {activeTab === 'TAB_ROLES' ? (
                      <Box sx={{ mt: compactMode ? 1 : 2 }}>
                        {sections.map((section, id) => (
                          <Fragment key={id}>
                            <Grid item={true} xs={12}>
                              <SimpleCheckboxArray
                                name={`sections_${get(section, 'name')}`}
                                label={get(
                                  get(section, 'text', false) ? JSON.parse(section.text) : {},
                                  currentLocale,
                                  section.name,
                                )}
                                items={section.roles.map((item: any) => ({
                                  ...item,
                                  name: get(
                                    get(item, 'text', false) ? JSON.parse(item.text) : {},
                                    currentLocale,
                                    item.code,
                                  ),
                                }))}
                                defaultValue={get(entity, `sections_${get(section, 'name')}`)}
                                grid={{ xs: 12, md: 6, lg: 4, xl: 3 }}
                              />
                            </Grid>
                            <Grid item={true} xs={12}>
                              <Line />
                            </Grid>
                          </Fragment>
                        ))}
                      </Box>
                    ) : (
                      <Box sx={{ mt: compactMode ? 1 : 2 }} />
                    )}
                  </>
                )}
                <EntityButtons linkBack={linkBack} otherStyles={{ marginTop: 8 }} />
              </form>
            </FormProvider>
          </Papeer>
        </>
      ) : (
        <Papeer>
          <Typography>{t('workplaceNotFound')}</Typography>
        </Papeer>
      )}
      {activeTab === 'TAB_FACILITIES' && (
        <WorkplaceFacility code={code} workplaceId={get(entity, 'id')} />
      )}
      {activeTab === 'TAB_ARCHIVE_ACTIONS' && (
        <WorkplaceArchiveActions
          code={code}
          workplaceId={get(entity, 'id')}
          originalArchives={originalArchives}
        />
      )}
    </>
  );
};
