import React, { useEffect, useMemo, useState } from 'react';
import { find, get, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid } from '@mui/material';

import { createStudyQuestion, editStudyQuestion } from './_api';
import { IStudyQuestion, IStudyQuestionForm } from './_types';
import useValidationSchema from './_formStudyTestTypeDefinitions';
import { getAllGroups } from 'modules/Tags/_api';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';

import FormSelect from 'components/Form/Select/Select';
import FormInput from 'components/Form/Input/Input';
import useAlerts from 'components/Alerts/useAlerts';

import { TAB_DEFINITIONS } from 'constants/constants';

const mapItemsToSelect = (items: any) =>
  isArray(items) ? items.map((item) => ({ id: item.id, label: item.name })) : [];

const StudyTestTypeDefinitionsForm: React.FC<any> = ({
  id,
  setActiveTab,
  removeEditingTab,
  loadEntity,
  isEdit = false,
  editedDefinition,
}) => {
  const { t } = useTranslation('Tests');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const [entity, setEntity] = useState<IStudyQuestionForm>();
  const [activeRegisterGroups, setActiveRegisterGroups] = useState<any[]>([]);

  const { StudyTestTypeDefinitionsFormSchema } = useValidationSchema();

  const methods = useForm<IStudyQuestionForm>({
    resolver: yupResolver(StudyTestTypeDefinitionsFormSchema),
    defaultValues: { ...entity },
  });
  const { handleSubmit, watch, reset, setValue } = methods;
  const watchGroup = watch('group');

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();

    const output: IStudyQuestion = {
      definition: {
        ...(isEdit ? { id: editedDefinition.id } : {}),
        questionsMax: parseInt(values.questionsMax, 10),
        questionsMin: parseInt(values.questionsMin, 10),
        registerItem: {
          id: values.item,
          registerGroupId: values.group,
        },
      },
      testId: parseInt(id, 10),
    };

    const fn = isEdit ? editStudyQuestion : createStudyQuestion;

    await fn(output).then(
      (response) => {
        addSuccessAlert(t(isEdit ? 'questionEdited' : 'questionAdded'));
        setActiveTab(TAB_DEFINITIONS);
        removeEditingTab();
        loadEntity();
      },
      (error) => addErrorAlert(t('questionSubmitFailed')),
    );

    toggleLoader(false);
  });

  const getEntity = async () => {
    toggleLoader();
    try {
      const activeRegisterGroups = await getAllGroups(true);
      if (isArray(activeRegisterGroups)) {
        setActiveRegisterGroups(activeRegisterGroups);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };
  useEffect(() => {
    getEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isCreating = !isEdit || !editedDefinition;

    const entity: IStudyQuestionForm = {
      group: isCreating ? null : get(editedDefinition, 'registerItem.registerGroupId'),
      item: isCreating ? null : get(editedDefinition, 'registerItem.id'),
      questionsMin: isCreating ? null : get(editedDefinition, 'questionsMin'),
      questionsMax: isCreating ? null : get(editedDefinition, 'questionsMax'),
    };
    setEntity(entity);
    reset({ ...entity });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEdit, editedDefinition]);

  const selectedGroupItems = useMemo(() => {
    const selectedGroupItems = get(find(activeRegisterGroups, { id: watchGroup }), 'registerItems');
    if (!isEdit || (watchGroup && get(entity, 'group') && watchGroup !== get(entity, 'group'))) {
      setValue('item', null);
    }
    return selectedGroupItems;
  }, [activeRegisterGroups, watchGroup, setValue, isEdit, entity]);

  const buttonTitle = t(isEdit ? 'editDefinition' : 'addDefinition');

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <Grid container={true} spacing={1}>
            {activeRegisterGroups.length ? (
              <>
                <Grid item={true} xs={12} md={6} lg={4} xl={2}>
                  <FormSelect
                    name="group"
                    label={t('group')}
                    required={true}
                    items={mapItemsToSelect(activeRegisterGroups)}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6} lg={4} xl={2}>
                  <FormSelect
                    name="item"
                    label={t('item')}
                    required={true}
                    items={mapItemsToSelect(selectedGroupItems)}
                    disabled={!watchGroup}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6} lg={4} xl={2}>
                  <FormInput
                    name="questionsMin"
                    label={t('questionsMin')}
                    type="number"
                    required={true}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6} lg={4} xl={2}>
                  <FormInput
                    name="questionsMax"
                    label={t('questionsMax')}
                    type="number"
                    required={true}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>

          <Grid container={true} spacing={1} justifyContent="flex-end">
            <Grid item={true}>
              <Button variant="contained" color="primary" type="submit">
                {buttonTitle}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default StudyTestTypeDefinitionsForm;
