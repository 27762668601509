import { Controller, useFormContext } from 'react-hook-form';
import { get, isEmpty, isFunction } from 'lodash';
import { Paper, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { IAutocompleteForm } from './_types';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import {
  COMPACT_MODE_FORM_ELEMENT_FONT_SIZE,
  COMPACT_MODE_FORM_ELEMENT_HEIGHT,
  COMPACT_MODE_FORM_ELEMENT_LINE_HEIGHT,
} from 'constants/constants';
import { setTourControlPropsToStore } from 'store/reducers/appReducer';
import { useDispatch } from 'react-redux';

const AutocompleteForm: React.FC<IAutocompleteForm> = ({
  name,
  label,
  options,
  placeholder,
  required = false,
  rules,
  filterSelectedOptions = false,
  disabled = false,
  groupBy,
  getOptionDisabled,
  renderOption,
  disableClearable,
  defaultValue,
  ownerOnChange = undefined,
  index = 0,
  noOptionsText,
  onSelectShowChildren = undefined,
  customOnChangeHandler = undefined,
  customFilterOptions = undefined,
  sx = {},
  optionsIsStringArray = false,
  trigger,
}) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const dispatch = useDispatch();

  let isError = false;
  let errorMessage = '';
  if (!isEmpty(errors) && get(errors, name) !== undefined) {
    isError = true;
    errorMessage = get(errors, `${name}.message`, '') as string;
  }

  //For managing tour logic
  const handleOpen = (element: any) => {
    setTimeout(() => {
      dispatch(setTourControlPropsToStore({ isDropDownOpen: true, currentOpenDropdown: name }));
    }, 100);
  };

  const handleClose = (element: any) => {
    setTimeout(() => {
      dispatch(setTourControlPropsToStore({ isDropDownOpen: false, currentOpenDropdown: null }));
    }, 100);
  };

  const { compactMode } = useAppInfo();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={(props) => {
        return (
          <Autocomplete
            id={name}
            {...(renderOption ? { renderOption } : {})}
            {...(groupBy ? { groupBy } : {})}
            {...(getOptionDisabled ? { getOptionDisabled } : {})}
            {...(disableClearable ? { disableClearable } : {})}
            {...(!isEmpty(defaultValue) ? { defaultValue } : {})}
            getOptionLabel={(option: any) => {
              const typeOfOption = typeof option;
              return typeOfOption === 'string' ? option : option.label ? option.label : '';
            }}
            options={options}
            isOptionEqualToValue={(option: any, value: any) => {
              const typeOfOption = typeof option;
              return typeOfOption === 'string' ? option === value : option.value === value.value;
            }}
            noOptionsText={noOptionsText}
            onChange={async (_: any, data: any) => {
              if (isFunction(customOnChangeHandler)) {
                customOnChangeHandler(_, data);
              }

              if (isFunction(ownerOnChange)) {
                ownerOnChange(data, index);
              }

              if (isFunction(onSelectShowChildren)) {
                onSelectShowChildren(data);
              }
              const newValue = get(data, 'newValue', '');
              setValue(name, optionsIsStringArray && newValue ? newValue : data);
              trigger && trigger(name);
            }}
            filterOptions={customFilterOptions || undefined}
            value={props.field.value}
            filterSelectedOptions={filterSelectedOptions}
            disabled={disabled}
            size="small"
            sx={{ mb: 1 }}
            /*componentsProps={{
              popper: {
                sx: {
                  zIndex: 2002,
                },
              },
            }}*/
            onOpen={handleOpen}
            onClose={handleClose}
            PaperComponent={(props) => (
              <Paper {...props} className="autocomplete-react-tour-class" />
            )}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={label}
                  placeholder={placeholder}
                  InputLabelProps={{
                    className: required ? 'required-label' : '',
                    required: required || false,
                    sx: compactMode
                      ? {
                          fontSize: COMPACT_MODE_FORM_ELEMENT_FONT_SIZE,
                          lineHeight: COMPACT_MODE_FORM_ELEMENT_LINE_HEIGHT,
                        }
                      : {},
                  }}
                  fullWidth
                  name={name}
                  variant="outlined"
                  margin="dense"
                  error={isError}
                  helperText={errorMessage}
                  {...(!isEmpty(defaultValue) ? { defaultValue: defaultValue.label } : {})}
                  InputProps={{
                    ...params?.InputProps,
                    sx: compactMode
                      ? {
                          height: COMPACT_MODE_FORM_ELEMENT_HEIGHT,
                          fontSize: COMPACT_MODE_FORM_ELEMENT_FONT_SIZE,
                          ...sx,
                        }
                      : { ...sx },
                  }}
                />
              );
            }}
          />
        );
      }}
    />
  );
};

export default AutocompleteForm;
