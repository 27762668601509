import React, { useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { find, get, isString, pick } from 'lodash';

import { editProductSetting, getProduct } from './_api';
import { IProductSystemSetting, IProductSystemSettingForm } from './_types';

import useValidationSchema from './_formProductSetting';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';

import useAlerts from 'components/Alerts/useAlerts';
import { ISelectItem } from 'components/Form/Select/_types';

export const ProductSettingForm: React.FC = () => {
  const { t } = useTranslation('Products');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const params = useParams();

  const [entity, fetchEntity] = useState<IProductSystemSetting>();
  const [valueType, setValueType] = useState<string>('');
  const [typeValues, setTypeValues] = useState<any[]>([]);
  const [linkBack, setLinkBack] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  const navigate = useNavigate();

  const { ProductSettingFormSchema, formItems } = useValidationSchema(t, valueType);

  const methods = useForm<IProductSystemSettingForm>({
    resolver: yupResolver(ProductSettingFormSchema),
  });
  const { handleSubmit, reset, register } = methods;

  const prepareValues = (values: any) => {
    let preparedValues: any = {
      ...pick(values, ['active', 'description', 'id', 'type', 'value', 'variable']),
      active: get(entity, 'active', true),
      id: get(entity, 'id'),
      type: get(entity, 'typeOld'),
    };
    return preparedValues;
  };

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const response = await editProductSetting(prepareValues(values));
    if (response) {
      addSuccessAlert(t('saved'));
      navigate(linkBack);
    } else {
      addErrorAlert(t(isString(response) ? response : 'errorSaving'));
    }
    toggleLoader(false);
  });

  const getEntity = async (productId: any, settingId: any) => {
    toggleLoader();
    try {
      setLinkBack(`/administration/settings/product/${productId}`);
      const product = await getProduct(productId);
      setTitle(`${t('editProductSetting')}  ${get(product, 'name', '')}`);

      let productSetting: IProductSystemSettingForm = {
        active: true,
        description: '',
        id: 0,
        type_id: '',
        value: '',
        variable: '',
        type: '',
      };

      if (product) {
        const systemSetting = find(get(product, 'systemSetting', []), {
          id: parseInt(settingId, 10),
        });
        const type = get(systemSetting, 'type', '');
        let valueType = type;
        if (type.includes('ENUM(')) {
          const typeValues = type.substring(5, type.length - 1).split(',');
          setTypeValues((typeValues || []).map((item: any) => ({ id: item, label: item })));
          valueType = 'ENUM';
        } else if (type === 'BOOLEAN') {
          setTypeValues([
            { id: 'true', label: 'true' },
            { id: 'false', label: 'false' },
          ]);
        }
        productSetting = {
          ...systemSetting,
          type_id: valueType,
          typeOld: type,
          type: valueType,
        };
        setValueType(valueType);
        fetchEntity(productSetting);
      }

      reset({ ...productSetting });
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    const productId = get(params, 'productId');
    const settingId = get(params, 'settingId');
    getEntity(productId, settingId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultItems = {
    type_id: [
      { id: 'TEXT', label: 'TEXT' },
      { id: 'BOOLEAN', label: 'BOOLEAN' },
      { id: 'NUMBER', label: 'NUMBER' },
      { id: 'IP', label: 'IP' },
      { id: 'ENUM', label: 'ENUM' },
    ],
    value: typeValues,
  };

  return (
    <>
      {entity ? (
        <>
          <Header title={title} />
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <input {...register('active')} type="hidden" />
                <input {...register('id')} type="hidden" />
                <input {...register('type')} type="hidden" />
                <Grid container={true} alignItems="flex-end" spacing={1}>
                  {formItems.map((formItem, index) => {
                    const type = get(formItem, 'type', 'text');
                    const name = get(formItem, 'name', '');
                    const items: ISelectItem[] = get(defaultItems, name, []);
                    const isRequired = get(formItem, 'required', false);
                    const isDisabled = get(formItem, 'disabled', false);
                    const label = get(formItem, 'label', '');

                    return (
                      <Grid item={true} xs={12} md={6} key={`formItem-${index}`}>
                        {type === 'select' ? (
                          <FormSelect
                            name={name}
                            label={label}
                            required={isRequired}
                            items={items}
                            disabled={isDisabled}
                          />
                        ) : (
                          <FormInput
                            name={name}
                            label={label}
                            type={type}
                            required={isRequired}
                            disabled={isDisabled}
                          />
                        )}
                      </Grid>
                    );
                  })}
                  <EntityButtons
                    linkBack={linkBack}
                    otherStyles={{ marginTop: '8px', marginLeft: '8px' }}
                  />
                </Grid>
              </form>
            </FormProvider>
          </Papeer>
        </>
      ) : (
        <Papeer>
          <Typography>{t('productNotFound')}</Typography>
        </Papeer>
      )}
    </>
  );
};
