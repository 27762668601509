import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormInput from 'components/Form/Input/Input';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { settingHasValue } from 'utils/products';
import { useUser } from 'utils/hooks/useUser';
import { Box } from '@mui/system';

const RequestAdditionalForm: React.FC<any> = ({ isRequest, initialValues }) => {
  const { t } = useTranslation('Studies');
  const { user } = useUser();
  const { portalProductSettings } = useAppInfo();
  const commentaryInOperation = isEmpty(portalProductSettings)
    ? false
    : settingHasValue(portalProductSettings, 'commentaryInOperation', 'true');
  const canComment = !isRequest && commentaryInOperation;
  const fields = [
    { name: 'requestingUser.name.lastName', label: t('requestingUser.lastName') },
    { name: 'requestingUser.name.firstName', label: t('requestingUser.firstName') },
  ];

  const { reset } = useFormContext();

  const loadEntity = async () => {
    reset({
      ...(!isEmpty(initialValues) ? initialValues : {}),
      requestComment: null,
      requestingUser: {
        name: { lastName: get(user, 'last_name'), firstName: get(user, 'first_name') },
      },
    });
  };

  useEffect(() => {
    if (isRequest) {
      loadEntity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isRequest || canComment ? (
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-patient"
            id="panel-patient"
          >
            <Typography variant="subtitle1">
              {t(isRequest ? 'requestComment' : 'comment')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ width: '100%' }}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} md={isRequest ? 6 : 12}>
                  <FormInput
                    name="requestComment"
                    label=""
                    multiline={true}
                    rows={isRequest ? 10 : 5}
                    resize={true}
                  />
                </Grid>
                {isRequest &&
                  fields.map((field: any) => (
                    <Grid item={true} xs={12} md={4} lg={3} key={field.name}>
                      <FormInput {...field} disabled={true} />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      ) : null}
    </>
  );
};

export default RequestAdditionalForm;
