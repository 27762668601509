import { get } from 'lodash';
import { ISerie } from 'modules/Studies/StudyDetail/_types';
import { api } from 'utils/hooks/useApi';

export const getSeriesAndInstances = (
  productId: string | number,
  studyUid: string,
): Promise<ISerie[] | false> =>
  api()
    .get(`/portal-api/study/getSeriesAndInstances`, { params: { productId, studyUid } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);
