import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { AppBar, Box, Tab, Tabs } from '@mui/material';
import { IField } from './_types';
import { TabTransmission } from './TabTransmission';
import { useUser } from 'utils/hooks/useUser';
import { TRANSFER_RECEIVED, TRANSFER_SEND } from 'constants/constants';
import Header from 'components/Header/Header';
import { TourTransmissions } from './TourTransmissions';

export const Transmissions: React.FC = () => {
  const { t } = useTranslation('Transmissions');
  const { hasRole } = useUser();

  const [activeTab, setActiveTab] = useState<string>('');
  const [tabs, setTabs] = useState<IField[]>([]);
  const [RenderedTab, setRenderedTab] = useState<JSX.Element[]>([]);
  const title = t('Mdex:transmissions');

  useEffect(() => {
    const tabs = [];
    if (hasRole('ROLE_MDEX_VIEW_INCOMING_DATA')) {
      tabs.push({ value: 'incoming', label: 'setIncoming' });
    }
    if (hasRole('ROLE_MDEX_VIEW_OUTGOING_DATA')) {
      tabs.push({ value: 'outgoing', label: 'setOutgoing' });
    }
    if (!isEmpty(tabs)) {
      setActiveTab(tabs[0].value);
    }
    setTabs(tabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRole]);

  useEffect(() => {
    const RenderedTab = [];
    if (activeTab) {
      switch (activeTab) {
        case 'incoming':
          RenderedTab.push(
            <Box key="tab_incoming" sx={{ mt: 2 }}>
              <TabTransmission type={TRANSFER_RECEIVED} />
            </Box>,
          );
          break;
        case 'outgoing':
          RenderedTab.push(
            <Box key="tab_outgoing" sx={{ mt: 2 }}>
              <TabTransmission type={TRANSFER_SEND} />
            </Box>,
          );
          break;
      }
    }
    setRenderedTab(RenderedTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const renderedSteps = () => {
    return <TourTransmissions activeTab={activeTab} />;
  };

  return (
    <Box>
      <Header title={title} TourComponent={renderedSteps()} />
      <AppBar
        position="static"
        color="default"
        sx={{ mt: 2, mb: 2 }}
        data-tour="mdex-transmission-navBar"
      >
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons={false}
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={t(tab.label)} />
          ))}
        </Tabs>
      </AppBar>
      <Box>{RenderedTab}</Box>
    </Box>
  );
};
