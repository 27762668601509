import React from 'react';
import { useParams } from 'react-router';
import { get, isEmpty } from 'lodash';
import DictionaryFormDictionary from './DictionaryFormDictionary';
import PhysicianForm from './PhysicianForm';
import { useLanguages } from 'utils/hooks/useLanguages';

export const DictionaryForm: React.FC = () => {
  const { languages } = useLanguages();
  const params = useParams();
  const dictionary = get(params, 'dictionary', null);
  const id = get(params, 'id', null);

  return (
    <>
      {!isEmpty(languages) &&
        (dictionary === 'physicians' ? (
          <PhysicianForm dictionary={dictionary} physicianId={id} />
        ) : (
          <DictionaryFormDictionary dictionary={dictionary} id={id} languages={languages} />
        ))}
    </>
  );
};
