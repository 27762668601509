import { useTranslation } from 'react-i18next';
import { filter, includes, get, isArray } from 'lodash';
import { useLocation, useNavigate } from 'react-router';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import EditPersonIcon from '@mui/icons-material/Attribution';
import BasketIcon from '@mui/icons-material/FolderSpecial';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SplitIcon from '@mui/icons-material/CallSplit';
import FileCopy from '@mui/icons-material/FileCopy';
import SwapHoriz from '@mui/icons-material/SwapHoriz';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import FolderIcon from '@mui/icons-material/FolderOpen';
import ArchiveIcon from '@mui/icons-material/Archive';
import DoseMonitoringExportIcon from '@mui/icons-material/CrisisAlert';
import DoseMonitoringIcon from '@mui/icons-material/TrackChanges';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteAllIcon from '@mui/icons-material/DeleteForever';
import ExtendExpirationIcon from '@mui/icons-material/PlaylistAdd';
import SignatureIcon from '@mui/icons-material/AssignmentInd';
import ShreddingIcon from '@mui/icons-material/RestoreFromTrash';
import ShreddingPrintIcon from '@mui/icons-material/Print';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import GridDetailIcon from '@mui/icons-material/Description';
import { GridRowModel } from '@mui/x-data-grid-pro';
import {
  ARCHIVE,
  LIST,
  SEND,
  COPY,
  MOVE,
  DOSE_MONITORING,
  MOVE_TO_FOLDER,
  MOVE_TO_TRASH,
  EXPORT,
  LIST_UNSIGNED,
  SHREDDING,
  SHREDDING_PRINT,
  PROPOSAL_SHREDDING,
  BASKET,
  SEARCH_FUNCTION_REORDER,
  SEARCH_FUNCTION_SPLIT,
  SEARCH_FUNCTION_EDIT_STUDY,
  SEARCH_FUNCTION_CHANGE_STATUSES,
  REMOVE_STUDY_FROM_FOLDER,
  REMOVE_ALL_STUDIES_FROM_FOLDER,
  EXTEND_EXPIRATION,
  SEARCH_FUNCTION_COPY,
  SEARCH_FUNCTION_MOVE,
  SEARCH_FUNCTION_EDIT_PATIENT,
  REMOVE_STUDIES_FROM_BASKET,
  REMOVE_ALL_STUDIES_FROM_BASKET,
  DETAIL,
  DOSE_MONITORING_EXPORT,
} from 'constants/constants';
import { useUser } from 'utils/hooks/useUser';
import { useActions } from 'utils/hooks/useActions';
import { IFunctionItem } from './_types';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import useAlerts from 'components/Alerts/useAlerts';

export const useFunctionList = ({
  actions = [LIST],
  isRequest,
  rows,
  selection = [],
  isFromColumn = false,
  operations,
  setSelection,
  isForContextMenu = false,
  contextMenuFunctionListButtonClick,
  contextMenuCallFunctionButtonClick,
}: any) => {
  const { t } = useTranslation('SearchResults');
  const { hasRole } = useUser();
  const navigate = useNavigate();
  const { resetStudies, setBulkStudies, setStudies, setStudiesBasket } = useActions();
  const { basketStore, reorderStore, sourceStudyForReorderIsInStore } = useStudyInfo();
  const { addSuccessAlert } = useAlerts();
  const { pathname } = useLocation();
  const action = pathname ? pathname.split('/').slice(-1)[0] : null;

  const canRemoveStudyFromFolder = get(operations, 'canRemoveStudyFromFolder', false);
  const canExtendExpiration = get(operations, 'canExtendExpiration', false);

  const handleFuntionListButtonClick = (
    type: string,
    link: string,
    row: GridRowModel | null = null,
  ) => {
    const isReorder = type === 'reorder';
    const mappedType = type === 'edit_patient' ? 'edit' : type;

    let items =
      selection.length > 0 && !row // pokud je vybráno více řádků, ale je použita akce u vyšetření, tak se bere jen jedno vyšetření
        ? filter(rows, (item) => includes(selection, item.iid))
        : type === 'edit_patient'
        ? get(row, 'patient', {})
        : row;

    if (mappedType === BASKET) {
      setStudiesBasket({
        type: mappedType,
        items: isArray(items) ? items : [items],
        currentItems: basketStore,
      });
      addSuccessAlert(t(selection.length > 1 ? 'studiesAddedToBasket' : 'studyAddedToBasket'));
    } else if (!isReorder) {
      resetStudies(mappedType);
      if (selection.length > 0) {
        setBulkStudies({
          type: mappedType,
          items,
        });
      } else {
        setStudies({
          type: mappedType,
          item: items,
        });
      }
    } else {
      if (selection.length > 0) {
        setBulkStudies({
          type: mappedType,
          items: [...reorderStore, ...items],
        });
      } else {
        setStudies({
          type: mappedType,
          item: items,
        });
      }
    }

    // Reset selection so that another study can be selected
    if (isReorder) {
      setSelection([]);
    }

    // Reorder step 1: don't navigate to reorder
    if (type !== 'reorder' || (type === 'reorder' && sourceStudyForReorderIsInStore)) {
      navigate(link);
    }
  };

  const handleCallFuntionButtonClick = (nameFunction: string, row: GridRowModel | null = null) => {
    let item = null;
    if (
      nameFunction === 'deleteSelectedStudies' ||
      nameFunction === 'extendExpirationSelectedStudies' ||
      nameFunction === 'getChangeStatusesDialog' ||
      nameFunction === 'proposalShredding' ||
      nameFunction === 'shredding' ||
      nameFunction === 'moveToTrash' ||
      nameFunction === 'archive'
    ) {
      item = row // pokud je použita akce u vyšetření, tak se bere jen jedno vyšetření
        ? get(row, 'iid', null)
          ? get(row, 'iid')
          : null
        : null;
    }
    operations.callFunctionByName(nameFunction, item);
  };

  //TODO potřeba zrevidovat všechny funkce
  const list: IFunctionItem[] = [
    {
      key: SHREDDING,
      label: t('setStudyForShredding'),
      show: includes(actions, SHREDDING) && hasRole('ROLE_MANAGE_SHREDDING'),
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleCallFuntionButtonClick('shredding', row)
          : contextMenuCallFunctionButtonClick('shredding');
      },
      icon: <ShreddingIcon fontSize="small" />,
    },
    {
      key: DETAIL,
      label: t('showDetail'),
      show: isFromColumn,
      onClick: (row: GridRowModel | null) => {
        const isOk = get(row, 'ok', true);
        const pathnameForLinkBack = get(row, 'pathnameForLinkBack', null);
        if (!isOk) {
          return false;
        }
        navigate(
          `/study/${get(row, 'hashIID')}${
            pathnameForLinkBack
              ? `?pathname=${pathnameForLinkBack}`
              : action
              ? `?from=${action}`
              : ''
          }`,
        );
      },
      icon: <GridDetailIcon fontSize="small" />,
    },
    {
      key: BASKET,
      label: t('setStudyForBasket'),
      show: includes(actions, BASKET) && hasRole('ROLE_STUDY_TEMPORARY_LIST'),
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleFuntionListButtonClick(BASKET, '', row)
          : contextMenuFunctionListButtonClick(BASKET, '');
      },
      icon: <BasketIcon fontSize="small" />,
      disabled: !selection.length,
    },
    {
      key: REMOVE_STUDIES_FROM_BASKET,
      label: t(isFromColumn ? 'removeStudyFromBasket' : 'removeSelectedStudiesFromBasket'),
      show: includes(actions, REMOVE_STUDIES_FROM_BASKET),
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleCallFuntionButtonClick('deleteSelectedStudies', row)
          : contextMenuCallFunctionButtonClick('deleteSelectedStudies');
      },

      icon: <DeleteIcon fontSize="small" />,
      disabled: !selection.length,
    },
    {
      key: REMOVE_ALL_STUDIES_FROM_BASKET,
      label: t('removeAllStudiesFromBasket'),
      show: includes(actions, REMOVE_ALL_STUDIES_FROM_BASKET) && !isFromColumn && !isForContextMenu,
      onClick: () => handleCallFuntionButtonClick('deleteAllStudies'),
      icon: <DeleteAllIcon fontSize="small" />,
      showCount: false,
    },
    {
      key: REMOVE_STUDY_FROM_FOLDER,
      label: t(isFromColumn ? 'removeStudyFromFolder' : 'removeSelectedStudiesFromFolder'),
      show: includes(actions, REMOVE_STUDY_FROM_FOLDER) && canRemoveStudyFromFolder,
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleCallFuntionButtonClick('deleteSelectedStudies', row)
          : contextMenuCallFunctionButtonClick('deleteSelectedStudies');
      },
      icon: <DeleteIcon fontSize="small" />,
      disabled: !selection.length,
    },
    {
      key: REMOVE_ALL_STUDIES_FROM_FOLDER,
      label: t('removeAllStudiesFromFolder'),
      show:
        includes(actions, REMOVE_ALL_STUDIES_FROM_FOLDER) &&
        canRemoveStudyFromFolder &&
        !isFromColumn &&
        !isForContextMenu,
      onClick: () => handleCallFuntionButtonClick('deleteAllStudies'),
      icon: <DeleteAllIcon fontSize="small" />,
      showCount: false,
    },
    {
      key: EXTEND_EXPIRATION,
      label: t('extendExpiration'),
      show: includes(actions, EXTEND_EXPIRATION) && canExtendExpiration,
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleCallFuntionButtonClick('extendExpirationSelectedStudies', row)
          : contextMenuCallFunctionButtonClick('extendExpirationSelectedStudies');
      },
      icon: <ExtendExpirationIcon fontSize="small" />,
    },
    {
      key: ARCHIVE,
      label: t('setStudyForArchive'),
      show: includes(actions, ARCHIVE) && hasRole('ROLE_ARCHIVATION'),
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleCallFuntionButtonClick(ARCHIVE, row)
          : contextMenuCallFunctionButtonClick(ARCHIVE);
      },
      icon: <ArchiveIcon fontSize="small" />,
    },
    {
      key: LIST_UNSIGNED,
      label: t('multipleSigning'),
      show: includes(actions, LIST_UNSIGNED) && hasRole('ROLE_CAN_SIGN_STUDY'),
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleFuntionListButtonClick('unsigned', 'storeConfirmationData', row)
          : contextMenuFunctionListButtonClick('unsigned', 'storeConfirmationData');
      },
      icon: <SignatureIcon fontSize="small" />,
    },
    {
      key: SEND,
      label: t(isRequest ? 'setStudyForRequestSend' : 'setStudyForSend'),
      show:
        includes(actions, SEND) &&
        (hasRole('ROLE_SEND_DR_SEJF') ||
          hasRole('ROLE_SEND_EPACS') ||
          hasRole('ROLE_SEND_REDIMED')),
      onClick: (row: GridRowModel | null = null) => {
        !isForContextMenu
          ? handleFuntionListButtonClick(
              'send',
              isRequest ? '/requests/send/create' : `/studies/send/detail?from=list`,
              row,
            )
          : contextMenuFunctionListButtonClick(
              'send',
              isRequest ? '/requests/send/create' : `/studies/send/detail?from=list`,
            );
      },
      icon: <SendIcon fontSize="small" />,
    },
    {
      key: SEARCH_FUNCTION_REORDER,
      label: sourceStudyForReorderIsInStore
        ? t(isRequest ? 'setTargetStudyForRequestReorder' : 'setTargetStudyForReorder')
        : t(isRequest ? 'setSourceStudyForRequestReorder' : 'setSourceStudyForReorder'),
      show: includes(actions, SEARCH_FUNCTION_REORDER) && hasRole('ROLE_REORDER'),
      onClick: (row: GridRowModel | null = null) => {
        !isForContextMenu
          ? handleFuntionListButtonClick(
              'reorder',
              isRequest ? '/requests/reorder/create' : '/studies/reorder/detail?from=list',
              row,
            )
          : contextMenuFunctionListButtonClick(
              'reorder',
              isRequest ? '/requests/reorder/create' : '/studies/reorder/detail?from=list',
            );
      },
      icon: sourceStudyForReorderIsInStore ? (
        <ArrowBackIcon fontSize="small" />
      ) : (
        <ArrowForwardIcon fontSize="small" />
      ),
      noSelection: true,
    },
    {
      key: SEARCH_FUNCTION_SPLIT,
      label: t(isRequest ? 'setStudyForRequestSplit' : 'setStudyForSplit'),
      show: includes(actions, SEARCH_FUNCTION_SPLIT) && hasRole('ROLE_SPLIT'),
      onClick: (row: GridRowModel | null = null) => {
        !isForContextMenu
          ? handleFuntionListButtonClick(
              'split',
              isRequest ? '/requests/split/create' : '/studies/split/detail?from=list',
              row,
            )
          : contextMenuFunctionListButtonClick(
              'split',
              isRequest ? '/requests/split/create' : '/studies/split/detail?from=list',
            );
      },
      icon: <SplitIcon fontSize="small" />,
      noSelection: true,
    },
    {
      key: SEARCH_FUNCTION_EDIT_STUDY,
      label: t(isRequest ? 'setStudyForRequestEditStudy' : 'setStudyForEdit'),
      show: includes(actions, SEARCH_FUNCTION_EDIT_STUDY) && hasRole('ROLE_UPDATE_STUDY'),
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleFuntionListButtonClick(
              'edit',
              isRequest ? '/requests/editStudy/create' : '/studies/editStudy/detail?from=list',
              row,
            )
          : contextMenuFunctionListButtonClick(
              'edit',
              isRequest ? '/requests/editStudy/create' : '/studies/editStudy/detail?from=list',
            );
      },
      icon: <EditIcon fontSize="small" />,
      noSelection: true,
    },
    {
      key: SEARCH_FUNCTION_EDIT_PATIENT,
      label: t(isRequest ? 'setStudyForRequestEditPatient' : 'setPatientForEdit'),
      show: includes(actions, SEARCH_FUNCTION_EDIT_PATIENT) && hasRole('ROLE_UPDATE_PATIENT'),
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleFuntionListButtonClick(
              'edit',
              isRequest ? '/requests/editPatient/create' : '/studies/editPatient/detail?from=list',
              row,
            )
          : contextMenuFunctionListButtonClick(
              'edit',
              isRequest ? '/requests/editPatient/create' : '/studies/editPatient/detail?from=list',
            );
      },
      icon: <EditPersonIcon fontSize="small" />,
      noSelection: true,
    },
    {
      key: COPY,
      label: t(isRequest ? 'setStudyForRequestCopy' : 'setStudyForCopy'),
      show: includes(actions, isRequest ? SEARCH_FUNCTION_COPY : COPY) && hasRole('ROLE_COPY'),
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleFuntionListButtonClick(
              'copy',
              isRequest ? '/requests/copy/create' : `/studies/copy/detail?from=list`,
              row,
            )
          : contextMenuFunctionListButtonClick(
              'copy',
              isRequest ? '/requests/copy/create' : `/studies/copy/detail?from=list`,
            );
      },
      icon: <FileCopy fontSize="small" />,
    },
    {
      key: MOVE,
      label: t(isRequest ? 'setStudyForRequestMove' : 'setStudyForMove'),
      show: includes(actions, isRequest ? SEARCH_FUNCTION_MOVE : MOVE) && hasRole('ROLE_MOVE'),
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleFuntionListButtonClick(
              'move',
              isRequest ? '/requests/move/create' : `/studies/move/detail?from=list`,
              row,
            )
          : contextMenuFunctionListButtonClick(
              'move',
              isRequest ? '/requests/move/create' : `/studies/move/detail?from=list`,
            );
      },
      icon: <SwapHoriz fontSize="small" />,
    },
    {
      key: EXPORT,
      label: t('setStudyForExport'),
      show:
        includes(actions, EXPORT) &&
        (hasRole('ROLE_EXPORT_CD') ||
          hasRole('ROLE_EXPORT_DICOM') ||
          hasRole('ROLE_EXPORT_DVD') ||
          hasRole('ROLE_EXPORT_PICTURE') ||
          hasRole('ROLE_EXPORT_VIDEO')),
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleFuntionListButtonClick('export', `/exports/detail?from=list`, row)
          : contextMenuFunctionListButtonClick('export', `/exports/detail?from=list`);
      },
      icon: <ImportExportIcon fontSize="small" />,
    },
    {
      key: MOVE_TO_FOLDER,
      label: t(isRequest ? 'setStudyRequestForMoveToFolder' : 'setStudyForMoveToFolder'),
      show: includes(actions, MOVE_TO_FOLDER) && hasRole('ROLE_CAN_OPERATE_WITH_FOLDERS'),
      onClick: (row: GridRowModel | null = null) => {
        !isForContextMenu
          ? handleFuntionListButtonClick(
              MOVE_TO_FOLDER,
              isRequest ? '/requests/moveToFolder/create' : '/studies/addToFolder?from=list',
              row,
            )
          : contextMenuFunctionListButtonClick(
              MOVE_TO_FOLDER,
              isRequest ? '/requests/moveToFolder/create' : '/studies/addToFolder?from=list',
            );
      },
      icon: <FolderIcon fontSize="small" />,
    },
    {
      key: MOVE_TO_TRASH,
      label: t(isRequest ? 'setStudyForRequestDeleting' : 'setStudyForDeleting'),
      show: includes(actions, MOVE_TO_TRASH) && hasRole('ROLE_CAN_MOVE_TO_TRASH'),
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? isRequest
            ? handleFuntionListButtonClick(MOVE_TO_TRASH, '/requests/moveToTrash/create', row)
            : handleCallFuntionButtonClick(MOVE_TO_TRASH, row)
          : isRequest
          ? contextMenuFunctionListButtonClick(MOVE_TO_TRASH, '/requests/moveToTrash/create')
          : contextMenuCallFunctionButtonClick(MOVE_TO_TRASH);
      },
      icon: <DeleteIcon fontSize="small" />,
    },
    {
      key: PROPOSAL_SHREDDING,
      label: t('setStudyForProposalShredding'),
      show: includes(actions, PROPOSAL_SHREDDING) && hasRole('ROLE_SHREDDING'),
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleCallFuntionButtonClick('proposalShredding', row)
          : contextMenuCallFunctionButtonClick('proposalShredding');
      },
      icon: <ShreddingIcon fontSize="small" />,
    },
    /* 
    {
      key: REMOVE_STUDY_FROM_FOLDER,
      label: t(isFromColumn ? 'removeStudyFromFolder' : 'removeSelectedStudiesFromFolder'),
      show: includes(actions, REMOVE_STUDY_FROM_FOLDER) && canRemoveStudyFromFolder,
      onClick: (row: GridRowModel | null) =>
        handleCallFuntionButtonClick('deleteSelectedStudies', row),
      icon: <DeleteIcon fontSize="small" />,
    },
    */
    {
      key: SHREDDING_PRINT,
      label: t('setStudyForShreddingPrint'),
      show: includes(actions, SHREDDING_PRINT) && hasRole('ROLE_MANAGE_SHREDDING'),
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleFuntionListButtonClick('shreddingPrint', '/shreddings/histories/print', row)
          : contextMenuFunctionListButtonClick('shreddingPrint', '/shreddings/histories/print');
      },
      icon: <ShreddingPrintIcon fontSize="small" />,
    },
    {
      key: SEARCH_FUNCTION_CHANGE_STATUSES,
      label: t(isFromColumn || isForContextMenu ? 'setChangeStatuses' : 'setChangeStatusesTooltip'),
      show:
        includes(actions, SEARCH_FUNCTION_CHANGE_STATUSES) &&
        hasRole('ROLE_CAN_MANAGE_STUDY_STATUS'),
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleCallFuntionButtonClick('getChangeStatusesDialog', row)
          : contextMenuCallFunctionButtonClick('getChangeStatusesDialog');
      },
      icon: <BookmarkIcon fontSize="small" />,
    },
    {
      key: DOSE_MONITORING_EXPORT,
      label: t('multipleCalculateExport'),
      show: includes(actions, DOSE_MONITORING) && hasRole('ROLE_DOSE_MONITORING'),
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleFuntionListButtonClick(
              DOSE_MONITORING,
              '/doseMonitoring/calculate?export=csv',
              row,
            )
          : contextMenuFunctionListButtonClick(
              DOSE_MONITORING,
              '/doseMonitoring/calculate?export=csv',
            );
      },
      icon: <DoseMonitoringExportIcon fontSize="small" />,
    },
    {
      key: DOSE_MONITORING,
      label: t('multipleCalculate'),
      show: includes(actions, DOSE_MONITORING) && hasRole('ROLE_DOSE_MONITORING'),
      onClick: (row: GridRowModel | null) => {
        !isForContextMenu
          ? handleFuntionListButtonClick(DOSE_MONITORING, '/doseMonitoring/calculate', row)
          : contextMenuFunctionListButtonClick(DOSE_MONITORING, '/doseMonitoring/calculate');
      },
      icon: <DoseMonitoringIcon fontSize="small" />,
    },
    // {
    //   key: SELECT_STUDY_TO_IMPORT,

    // }
  ];

  return {
    functionList: filter(list, ['show', true]),
    list,
    handleCallFuntionButtonClick,
    handleFuntionListButtonClick,
  };
};
