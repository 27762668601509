import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { find, get, includes, isFunction, orderBy } from 'lodash';
import {
  Box,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
} from '@mui/material';
import {
  MoreHoriz as MoreHorizIcon,
  Close as CloseIcon,
  ExpandLess,
  ExpandMore,
  FolderOpen as FolderIcon,
  BookmarkBorder as BookmarkBorderIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Print as PrintIcon,
  ImportExport as ImportExportIcon,
  Download as DownloadIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import { IDocumentDetailMenu } from './_types';
import { MaterialUiIcon } from 'components/MaterialUiIcon/MaterialUiIcon';
import { useUser } from 'utils/hooks/useUser';
import { useTranslation } from 'react-i18next';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { checkEldaxDocumentExistence, getOriginalDocument } from './_api';
import useAlerts from 'components/Alerts/useAlerts';
import { useActions } from 'utils/hooks/useActions';
import { format } from 'date-fns';
import { joinParams } from 'utils/study';
import { useLanguage } from 'utils/hooks/useLanguage';
import { DialogFolders } from 'modules/Administration/Folders/DialogFolders';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import StudyDetailTagForm from 'modules/Studies/StudyDetail/StudyDetailTagForm';

const formatDate = 'dd. MM. yyyy';

export const DocumentDetailMenu: React.FC<IDocumentDetailMenu> = ({
  itemKey,
  documentUiid,
  documentUids,
  masterPatientID,
  unselectDocumentUuid,
  patientDocuments,
  setDocumentToGridPosition,
  patientDocument,
  getDocumentTags,
  onlyAddTag,
  setIsPrinting,
  setStandardizedPrint,
  setIsExporting,
  activeRegisterGroups,
  setActiveRegisterGroups,
  menuStyle,
  gridLayoutParams,
  selectingDocumentDisabled,
  setIsDownloadingFromEldax,
}) => {
  const { t } = useTranslation('ClinicalPortal');
  const navigate = useNavigate();
  const { currentLocale } = useLanguage();
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert } = useAlerts();
  const { hasRole, user } = useUser();
  const { storeDocuments, storeSelection } = useActions();

  const [anchor, setAnchor] = useState<any>(null);
  const [anchorSubMenu, setAnchorSubMenu] = useState<any>(null);
  const [openSubMenu, setOpenSubMenu] = useState<boolean>(false);
  const [openFolderDialog, setOpenFolderDialog] = useState<boolean>(false);
  const [openTagDialog, setOpenTagDialog] = useState<boolean>(false);
  const [documents, setDocuments] = useState<any[]>([]);
  const [printAllowed, setPrintAllowed] = useState<boolean>(true);
  const [standardizedPrintAllowed, setStandardizedPrintAllowed] = useState<boolean>(true);
  const [exportAllowed, setExportAllowed] = useState<boolean>(true);
  const [selectedSeries, setSelectedSeries] = useState<any>(false);
  const [downloadFromEldax, setDownloadFromEldax] = useState<boolean>(true);

  const [eldaxDocumentAvailable, setEldaxDocumentAvailable] = useState<boolean>(true);
  const [isLoadingEldaxDocumentStatus, setIsLoadingEldaxDocumentStatus] = useState<boolean>(true);

  const documentUidsJsonString = JSON.stringify(documentUids);
  const patientDocumentJsonString = JSON.stringify(patientDocument);

  const checkIfEldaxDocumentIsAvailable = async (uuid: string) => {
    try {
      const response = await checkEldaxDocumentExistence(
        masterPatientID,
        uuid,
        get(patientDocument, 'typeOfDocument.name', ''),
      );
      setEldaxDocumentAvailable(response);
    } catch (e) {
      setEldaxDocumentAvailable(false);
      return false;
    } finally {
      setIsLoadingEldaxDocumentStatus(false);
    }
  };

  useEffect(() => {
    if (documentUiid === null) return;
    checkIfEldaxDocumentIsAvailable(documentUiid!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentUiid]);

  const fillDocuments = (documentUids: any[]) => {
    setDocuments(
      orderBy(
        patientDocuments.map((patientDocument) => {
          return {
            ...patientDocument,
            selected: includes(documentUids, get(patientDocument, 'uuid', '')),
          };
        }),
        ['createdDateTime'],
        ['desc'],
      ),
    );
  };

  useEffect(() => {
    const allowedFormats = get(patientDocument, 'typeOfDocument.allowedFormats', []);
    const canUseEldax = get(patientDocument, 'typeOfDocument.canUseEldax', false);
    if (allowedFormats.length === 1) {
      const allowedFormat = allowedFormats[0];
      setPrintAllowed(get(allowedFormat, 'printable', true));
      setExportAllowed(get(allowedFormat, 'exportable', true));
    }
    setStandardizedPrintAllowed(
      get(patientDocument, 'typeOfDocument.standardizedPrintEnabled', false),
    );
    setDownloadFromEldax(canUseEldax);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDocumentJsonString]);

  useEffect(() => {
    fillDocuments(documentUids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentUidsJsonString /* documentUids.length */]);

  const handleClick = (event: any) => {
    setAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  const handleCloseSubMenu = () => {
    setAnchorSubMenu(null);
    setOpenSubMenu(false);
  };

  const handleClickSubmenu = (event: any) => {
    setAnchorSubMenu(event.currentTarget);
    setOpenSubMenu(true);
  };

  const handleShowOriginalDocument = async (documentUiid: any) => {
    toggleLoader();
    const originalDocumentResponse = await getOriginalDocument(masterPatientID, documentUiid);
    if (originalDocumentResponse) {
      const originalDocument = get(originalDocumentResponse, 'data', '');
      const documentType = get(originalDocumentResponse, 'headers.content-type', null);

      try {
        const decodedOriginaldocument = decodeURIComponent(escape(atob(originalDocument)));
        const blob = new Blob([decodedOriginaldocument], { type: documentType });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error(error);
      }
    } else {
      addErrorAlert(t('errorEsbGetOriginalDocument'));
    }
    toggleLoader(false);
  };

  const handleClickPrint = (uuid: string | null, standardizedPrint: boolean = false) => {
    storeSelection([]);
    storeSelection([uuid]);
    setStandardizedPrint(standardizedPrint);
    setIsPrinting(true);
  };

  const handleClickExport = (uuid: string | null) => {
    storeSelection([]);
    storeSelection([uuid]);
    setIsExporting(true);
  };

  const handleClickSendDocument = (uuid: string | null) => {
    const selectedDocuments = find(documents, ['uuid', uuid]);
    storeDocuments([]);
    storeDocuments([{ ...selectedDocuments, uuidex: `${uuid}_0` }]);
    const from = window.location.pathname.replace('portal/', '');
    navigate(`/clinicPortal/documentsSend?from=${from}`);
  };

  const handleItemClick = async (newUuid: any, oldUuid: any) => {
    setDocumentToGridPosition(oldUuid, newUuid, itemKey);
  };

  const handleDownloadFromEldax = (uuid: string | null) => {
    storeSelection([]);
    storeSelection([uuid]);
    setIsDownloadingFromEldax(true);
  };

  return (
    <>
      <Box sx={{ ...menuStyle }}>
        <>
          <IconButton
            key="menuBtn"
            aria-label="menu"
            color="inherit"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="menu"
            anchorEl={anchor}
            keepMounted={false}
            open={Boolean(anchor)}
            onClose={handleCloseMenu}
          >
            <MenuItem
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => {
                if (isFunction(unselectDocumentUuid) && documentUiid) {
                  unselectDocumentUuid(documentUiid);
                }
              }}
              disabled={onlyAddTag || documentUiid === null || documentUiid === undefined}
            >
              <ListItemIcon>
                <CloseIcon />
              </ListItemIcon>
              <ListItemText inset={true} primary={t('unselect')} />
            </MenuItem>
            <MenuItem
              key="reselect"
              aria-label="sub-menu"
              aria-haspopup="true"
              color="inherit"
              onClick={handleClickSubmenu}
              disabled={onlyAddTag || selectingDocumentDisabled}
            >
              <ListItemIcon>{openSubMenu ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
              <ListItemText inset={true} primary={t('reselect')} />
            </MenuItem>
            {hasRole('ROLE_CLINICAL_DOCUMENTS_IN_FOLDER') && (
              <MenuItem
                key="folder"
                aria-label="folder"
                color="inherit"
                onClick={() => {
                  handleCloseMenu();
                  setOpenFolderDialog(true);
                }}
                disabled={documentUiid === null || documentUiid === undefined}
              >
                <ListItemIcon>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText inset={true} primary={t('multipleAddToFolder')} />
              </MenuItem>
            )}
            {hasRole('ROLE_CAN_ADD_NEW_TAG_IN_IP') && (
              <MenuItem
                key="tag"
                aria-label="tag"
                color="inherit"
                onClick={() => {
                  handleCloseMenu();
                  setOpenTagDialog(true);
                }}
                disabled={documentUiid === null || documentUiid === undefined}
              >
                <ListItemIcon>
                  <BookmarkBorderIcon />
                </ListItemIcon>
                <ListItemText inset={true} primary={t('addTag')} />
              </MenuItem>
            )}

            {hasRole('ROLE_VIEW_ORIGINAL_DOCUMENT') && (
              <MenuItem
                key="originalDocument"
                aria-label="originalDocument"
                color="inherit"
                onClick={() => {
                  handleShowOriginalDocument(documentUiid);
                  handleCloseMenu();
                }}
                disabled={documentUiid === null || documentUiid === undefined}
              >
                <ListItemIcon>
                  <InsertDriveFileIcon />
                </ListItemIcon>
                <ListItemText inset={true} primary={t('showOriginalDocument')} />
              </MenuItem>
            )}

            {hasRole('ROLE_CAN_PRINT_IN_CLINICAL_PORTAL') && printAllowed && (
              <MenuItem
                key="printDocument"
                aria-label="printDocument"
                color="inherit"
                onClick={() => {
                  handleClickPrint(documentUiid);
                  handleCloseMenu();
                }}
                disabled={documentUiid === null || documentUiid === undefined}
              >
                <ListItemIcon>
                  <PrintIcon />
                </ListItemIcon>
                <ListItemText inset={true} primary={t('multiplePrint')} />
              </MenuItem>
            )}

            {hasRole('ROLE_CAN_PRINT_IN_CLINICAL_PORTAL') &&
              printAllowed &&
              standardizedPrintAllowed && (
                <MenuItem
                  key="printStandardizedDocument"
                  aria-label="printDocument"
                  color="inherit"
                  onClick={() => {
                    handleClickPrint(documentUiid, true);
                    handleCloseMenu();
                  }}
                  disabled={documentUiid === null || documentUiid === undefined}
                >
                  <ListItemIcon>
                    <PrintIcon />
                  </ListItemIcon>
                  <ListItemText inset={true} primary={t('multipleStandardizedPrint')} />
                </MenuItem>
              )}

            {hasRole('ROLE_CAN_EXPORT_IN_CLINICAL_PORTAL') && exportAllowed && (
              <MenuItem
                key="exportDocument"
                aria-label="exportDocument"
                color="inherit"
                onClick={() => {
                  handleClickExport(documentUiid);
                  handleCloseMenu();
                }}
                disabled={documentUiid === null || documentUiid === undefined}
              >
                <ListItemIcon>
                  <ImportExportIcon />
                </ListItemIcon>
                <ListItemText inset={true} primary={t('multipleExport')} />
              </MenuItem>
            )}
            {(hasRole('ROLE_SEND_DOCUMENT_AS_PDF') ||
              hasRole('ROLE_SEND_DOCUMENT_AS_ORIGINAL')) && (
              <MenuItem
                key="sendDocument"
                aria-label="sendDocument"
                color="inherit"
                onClick={() => {
                  handleClickSendDocument(documentUiid /* , documents */);
                  handleCloseMenu();
                }}
                disabled={documentUiid === null || documentUiid === undefined}
              >
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText inset={true} primary={t('sendDocument')} />
              </MenuItem>
            )}
            {hasRole('ROLE_CAN_DOWNLOAD_ELDAX_DOC') && downloadFromEldax && (
              <MenuItem
                key="downloadFromEldax"
                aria-label="downloadFromEldax"
                color="inherit"
                onClick={() => {
                  handleDownloadFromEldax(documentUiid);
                  handleCloseMenu();
                }}
                disabled={
                  documentUiid === null ||
                  documentUiid === undefined ||
                  isLoadingEldaxDocumentStatus ||
                  !eldaxDocumentAvailable
                }
              >
                <ListItemIcon>
                  {isLoadingEldaxDocumentStatus ? <CircularProgress size={20} /> : <DownloadIcon />}
                </ListItemIcon>
                <ListItemText inset={true} primary={t('downloadFromEldax')} />
              </MenuItem>
            )}
          </Menu>
          <Paper>
            <Menu
              id="sub-menu"
              anchorEl={anchorSubMenu}
              keepMounted={false}
              open={Boolean(anchorSubMenu)}
              onClose={handleCloseSubMenu}
              PaperProps={{
                style: {
                  maxHeight: 500,
                },
              }}
            >
              {documents.map((document) => {
                return (
                  <MenuItem
                    key={get(document, 'uuid', 'key')}
                    onClick={() => {
                      handleItemClick(get(document, 'uuid', 'key'), documentUiid);
                      handleCloseSubMenu();
                      handleCloseMenu();
                    }}
                    disabled={get(document, 'selected', false)}
                  >
                    <ListItemIcon>
                      <MaterialUiIcon
                        icon={get(document, 'typeOfDocument.iconName')}
                        selected={get(document, 'selected', false)}
                        style={{
                          color:
                            get(document, 'typeOfDocument.iconColor', null) == null
                              ? '#fff'
                              : get(document, 'typeOfDocument.iconColor'),
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      inset={true}
                      primary={
                        get(document, 'createdDateTime') != null
                          ? format(new Date(get(document, 'createdDateTime', '')), formatDate)
                          : `${t('unknownNameDate')}`
                      }
                      secondary={joinParams([
                        get(document, 'typeOfDocument.translateJson')
                          ? get(
                              JSON.parse(get(document, 'typeOfDocument.translateJson')),
                              currentLocale,
                              get(document, 'typeOfDocument.name'),
                            )
                          : `${t('unknownNameOfDocument')}`,
                        `(${t('author')}:`,
                        get(document, 'author') != null
                          ? get(document, 'author')
                          : `${t('unknownAuthorOfDocument')}`,
                        ` | ${t('referenceID')}:`,
                        get(document, 'referenceID') != null
                          ? get(document, 'referenceID')
                          : `${t('unknownReferenceOfDocument')}`,
                        ')',
                      ])}
                    />
                  </MenuItem>
                );
              })}
            </Menu>
          </Paper>
        </>
      </Box>
      <DialogFolders
        key={'documentGridDialogFoldersKey'}
        folderDialogState={openFolderDialog}
        setFolderDialogState={setOpenFolderDialog}
        action="documents"
        user={user}
        isLocked={false}
        showFoldersByUserAndStudy={false}
        selectedPatientDocuments={[patientDocument]}
        selectedSeries={selectedSeries}
        setSelectedSeries={setSelectedSeries}
      />
      <OverflowedDialog
        open={openTagDialog}
        onClose={() => setOpenTagDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
        title={t('dialog.tag')}
        withScrolling={true}
      >
        <StudyDetailTagForm
          setDialogState={setOpenTagDialog}
          getDocumentTags={getDocumentTags}
          document={patientDocument}
        />
      </OverflowedDialog>
    </>
  );
};
