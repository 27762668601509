import { api } from 'utils/hooks/useApi';
import { get } from 'lodash';

export const createShreddingRecord = (
  archiveId: number,
  studyUID: string,
  source: string = 'User',
): Promise<any> =>
  api()
    .post(`/portal-api/shreddingRecord/create`, {
      source,
      study: { archive: { id: archiveId }, studyInstanceUid: studyUID },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const confirmShreddingRecord = (params: any): Promise<any> =>
  api()
    .get(`/portal-api/shreddingRecord/confirm?${params}`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);
