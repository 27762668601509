import { api } from 'utils/hooks/useApi';
import { get, isString } from 'lodash';
import { INotification } from './_types';

export const getLists = (): Promise<any> =>
  api()
    .get(`/portal-api/notification/templates/getLists`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const createNotification = (notification: INotification): Promise<boolean | string> =>
  api()
    .post(`/portal-api/notification/templates/create`, notification)
    .then(() => true)
    .catch((error) =>
      isString(get(error, 'response.data')) ? get(error, 'response.data') : false,
    );

export const removeNotificationTemplate = (id: number): Promise<boolean> =>
  api()
    .get(`/portal-api/notification/templates/remove`, { params: { id } })
    .then(() => true)
    .catch(() => false);

export const getAllTemplates = (): Promise<any[]> =>
  api()
    .get(`/portal-api/notification/templates/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);
