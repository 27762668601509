import { get, isArray } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { SET_LISTS } from 'constants/constants';

export const getOrder = (patientId: string, accessionNumber: string): Promise<any> =>
  api()
    .get(`/portal-api/studyOrder/get`, { params: { patientId, accessionNumber } })
    .then((response) => {
      const data = get(response, 'data', false);
      return data;
    })
    .catch((error) => false);

export const findOrder = (data: any): Promise<any> =>
  api()
    .post(`/portal-api/studyOrder/find`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const findOrderWithCancel = (data: any): Promise<boolean> =>
  api()
    .post(`/portal-api/studyOrder/find`, data, { signal: (window as any).myAbortController.signal })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editOrder = (station: any): Promise<boolean> =>
  api()
    .post(`/portal-api/studyOrder/edit`, station)
    .then((response) => true)
    .catch((error) => false);

export const createOrder = (station: any): Promise<boolean> =>
  api()
    .post(`/portal-api/studyOrder/create`, station)
    .then((response) => true)
    .catch((error) => false);

export const getAllExpertness = (): Promise<any> =>
  api()
    .get(`/portal-api/studyOrder/getAllExpertness`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllHealthInsuranceCompany = (): Promise<any> =>
  api()
    .get(`/portal-api/studyOrder/getAllHealthInsuranceCompany`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllRequestedProcedure = (): Promise<any> =>
  api()
    .get(`/portal-api/studyOrder/getAllRequestedProcedures`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllPriority = (): Promise<any> =>
  api()
    .get(`/portal-api/studyOrder/getAllPriority `)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllModalities = (): Promise<any> =>
  api()
    .get(`/portal-api/modality/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllModalitiesAndStore = () => async (dispatch: any) => {
  const modalities = await getAllModalities();
  if (isArray(modalities)) {
    dispatch({
      payload: { modalities },
      type: SET_LISTS,
    });
    const modNoShow: any[] = [];
    modalities.forEach((modality) => {
      if (get(modality, 'show', false) === false) {
        modNoShow.push(get(modality, 'name'));
      }
    });
    dispatch({
      payload: { modNoShow },
      type: SET_LISTS,
    });
  }
};

export const removeOrder = (id: number): Promise<boolean> =>
  api()
    .get(`/portal-api/studyOrder/remove`, {
      params: { id },
    })
    .then((response) => true)
    .catch((error) => false);

export const getComment = (id: string): Promise<any> =>
  api()
    .get(`/portal-api/studyOrder/getComment`, { params: { id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editComment = (comment: any): Promise<boolean> =>
  api()
    .post(`/portal-api/studyOrder/editComment`, comment)
    .then((response) => true)
    .catch((error) => false);

export const createComment = (comment: any): Promise<boolean> =>
  api()
    .post(`/portal-api/studyOrder/createComment`, comment)
    .then((response) => true)
    .catch((error) => false);

export const setActiveComment = (id: string, state: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/studyOrder/setActiveComment`, { params: { id, state } })
    .then((response) => true)
    .catch((error) => false);

export const sendMetadata = (data: any): Promise<boolean> =>
  api()
    .post(`/portal-api/studyOrder/metadata`, data)
    .then((response) => true)
    .catch((error) => false);

export const downloadAttachment = (data: any): Promise<any> =>
  api({ responseType: 'blob' })
    .get(`/portal-api/studyOrder/getAttachment`, { params: data })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const deleteAttachment = (data: any): Promise<any> =>
  api()
    .get(`/portal-api/studyOrder/deleteAttachment`, { params: data })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const checkStudyOrderWithComment = (
  patientId: string,
  accessionNumber: string,
): Promise<any> =>
  api()
    .get(`/portal-api/studyOrder/checkStudyOrderWithComment`, {
      params: { patientId, accessionNumber },
    })
    .then((response) => {
      const data = get(response, 'data', false);
      return data;
    })
    .catch((error) => false);
