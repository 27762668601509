import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { AppBar, Box, Tab, Tabs } from '@mui/material';
import { IField } from './_types';
import { DicomForm } from './DicomForm';
import { DicomOrNonDicomForm } from './DicomOrNonDicomForm';
import { useUser } from 'utils/hooks/useUser';
import Header from 'components/Header/Header';
import { TourMdex } from './TourMdex';

export const Send: React.FC = () => {
  const { t } = useTranslation('Mdex');
  const { hasRole } = useUser();
  const title = t('mdexSendTitle');

  let [searchParams] = useSearchParams();
  const tabFromUrl = searchParams.get('tab') || '';
  const tab = tabFromUrl
    ? isArray(tabFromUrl)
      ? tabFromUrl[0]
      : tabFromUrl
    : hasRole('ROLE_MDEX_SEND_DICOM')
    ? 'dicomFromArchive'
    : hasRole('ROLE_MDEX_SEND_NON_DICOM')
    ? 'nondicom'
    : '';

  const [activeTab, setActiveTab] = useState<string>(tab);
  const [tabs, setTabs] = useState<IField[]>([]);

  const renderedSteps = useMemo(() => {
    return <TourMdex activeTab={activeTab} />;
  }, [activeTab]);

  useEffect(() => {
    const tabs = [];
    if (hasRole('ROLE_MDEX_SEND_DICOM')) {
      tabs.push({ value: 'dicomFromArchive', label: 'dicomFromArchive' });
      tabs.push({ value: 'dicomFromStation', label: 'dicomFromStation' });
    }
    if (hasRole('ROLE_MDEX_SEND_NON_DICOM')) {
      tabs.push({ value: 'nondicom', label: 'nondicom' });
    }
    setActiveTab(tabs[0].value);
    setTabs(tabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRole]);

  const CustomAppBar = () => (
    <>
      <AppBar position="static" color="default" sx={{ mb: 2 }} data-tour="mdex-send-navBar">
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons={false}
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={t(tab.label)} />
          ))}
        </Tabs>
      </AppBar>
    </>
  );

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {activeTab === 'dicomFromArchive' && (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '100%',
            }}
            key="tab_dicomFromArchive"
          >
            <Header title={title} TourComponent={renderedSteps} />
            <CustomAppBar />
            <DicomForm />
          </Box>
        )}

        {activeTab === 'dicomFromStation' && (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '100%',
            }}
            key="tab_dicomFromStation"
          >
            <Header title={title} TourComponent={renderedSteps} />
            <CustomAppBar />
            <DicomOrNonDicomForm type="DICOM" />
          </Box>
        )}

        {activeTab === 'nondicom' && (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '100%',
            }}
            key="tab_nondicom"
          >
            <Header title={title} TourComponent={renderedSteps} />
            <CustomAppBar />
            <DicomOrNonDicomForm type="nonDICOM" />
          </Box>
        )}
      </Box>
    </>
  );
};
