import HelperComponent from 'components/Tour/HelperComponent';
import { getDropDownStep } from 'components/Tour/_dropDownStep';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

export const TourStudiesSend = () => {
  const { t } = useTranslation('Reactour');

  const allSteps = ({ isDropDownOpen, currentOpenDropdown }: any) => {
    const steps = {
      '[data-tour="backButton"]': t('study.back'),
      '[data-tour="studySendBy"]': t('study.send.sendBy'),
      ...(currentOpenDropdown !== 'anonymization' &&
        getDropDownStep({ isDropDownOpen, t, selector: 'autocomplete' })),

      ...(currentOpenDropdown === 'anonymization' && getDropDownStep({ isDropDownOpen, t })),
      '[data-tour="studySendListOfStudies"]': t('study.send.listOfStudies'),
      '[data-tour="studySendButton"]': t('study.send.sendButton'),
    };
    return steps;
  };

  useReactTour({ stepsDefinition: allSteps });

  return <HelperComponent />;
};
