import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { AppBar, Tab, Tabs } from '@mui/material';

import Modalities from './Modalities';
import Units from './Units';
import Stations from './Stations';

import { useUser } from 'utils/hooks/useUser';

export const ModalitiesUnitsStations: React.FC = () => {
  const { t } = useTranslation('ModalitiesUnitsStations');
  const { hasRole } = useUser();

  let [searchParams] = useSearchParams();
  const tabFromUrl = searchParams.get('tab') || '';
  const tab = tabFromUrl
    ? isArray(tabFromUrl)
      ? tabFromUrl[0]
      : tabFromUrl
    : hasRole('ROLE_MODALITY')
    ? 'modalities'
    : hasRole('ROLE_UNIT')
    ? 'units'
    : hasRole('ROLE_STATION')
    ? 'stations'
    : '';

  const [activeTab, setActiveTab] = useState<string>(tab);

  return (
    <>
      <AppBar position="static" color="default" sx={{ mt: 2, mb: 2 }}>
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons={false}
        >
          {hasRole('ROLE_MODALITY') && (
            <Tab key="modalities" value="modalities" label={t('setModalities')} />
          )}
          {hasRole('ROLE_UNIT') && <Tab key="units" value="units" label={t('setUnits')} />}
          {hasRole('ROLE_STATION') && (
            <Tab key="stations" value="stations" label={t('setStations')} />
          )}
        </Tabs>
      </AppBar>
      {activeTab === 'modalities' && <Modalities />}
      {activeTab === 'units' && <Units />}
      {activeTab === 'stations' && <Stations />}
    </>
  );
};
