import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';
import HelperComponent from 'components/Tour/HelperComponent';
import { getDropDownStep } from 'components/Tour/_dropDownStep';

interface SearchListStepsProps {
  showActionsInHeader?: boolean;
  isReorderStudyInfo?: boolean;
}

export const TourSearchList = ({
  showActionsInHeader,
  isReorderStudyInfo,
}: SearchListStepsProps) => {
  const { t } = useTranslation('Reactour');

  const allSteps = ({ isDropDownOpen, currentOpenDropdown }: any) => {
    const steps = {
      ...(isReorderStudyInfo && {
        '[data-tour="reorderSourceStudyDeleteButton"]': t('study.reorderSource.deleteButton'),
        '[data-tour="reorderSourceStudyGrid"]': t('study.reorderSource.grid'),
      }),

      ...(showActionsInHeader && {
        '[data-tour="search-header-export-list"]': t('searchList.exportList'),
      }),
      ...(showActionsInHeader && {
        '[data-tour="search-header-sent-history"]': t('searchList.sentHistory'),
      }),
      '[data-tour="search-header-filters"]': t('searchList.filters'),
      ...(currentOpenDropdown === 'filterDropdown' &&
        isDropDownOpen && {
          '.select-react-tour-class-filter': (
            <>
              {t('common.selectItems')}
              <br />
              <em>{t('common.quitSelectItems')}</em>
            </>
          ),
        }),

      '[data-tour="search-header-searchForm-searchFormFields"]': t('searchList.searchFormFields'),
      ...(currentOpenDropdown !== 'filterDropdown' && getDropDownStep({ isDropDownOpen, t })),

      '[data-tour="search-header-searchForm-expansionArrow"]': t('searchList.expansionArrow'),
      '[data-tour="search-header-searchForm-searchSources"]': t('searchList.searchSources'),

      '[data-tour="search-header-searchForm-clearButton"]': t('searchList.clearButton'),
      '[data-tour="search-header-searchForm-searchButton"]': t('searchList.searchButton'),
      '[data-tour="search-header-searchForm-saveFilterButton"]': t('searchList.saveFilterButton'),

      '[data-tour="search-header-searchForm-searchResultGrid"]': t('searchList.searchResultGrid'),
      '[data-tour="common-gridReFetching"]': t('common.gridReFetching'),
      '[data-tour="common-gridColumns"]': t('common.gridColumns'),
      '[data-tour="common-gridFilters"]': t('common.gridFilters'),
    };
    return steps;
  };

  useReactTour({ stepsDefinition: allSteps });

  return <HelperComponent />;
};
