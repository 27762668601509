import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBar, Tab, Tabs } from '@mui/material';
import { ExportListGrid } from './ExportListGrid';
import Header from 'components/Header/Header';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourExport } from './TourExport';

export const ExportList: React.FC = () => {
  const { t } = useTranslation('StudyExport');
  const { title } = useWithTitle();

  const [activeTab, setActiveTab] = useState<string>('validExports');

  const renderedSteps = () => {
    return <TourExport type="list" />;
  };

  return (
    <>
      <Header title={title} TourComponent={renderedSteps()} />
      <AppBar position="static" color="default" sx={{ mb: 2 }} data-tour="export-list-appBar">
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons={false}
        >
          <Tab value="validExports" label={t('validExports')} />
          <Tab value="allExports" label={t('allExports')} />
        </Tabs>
      </AppBar>
      {activeTab === 'validExports' && <ExportListGrid type="Valid" />}
      {activeTab === 'allExports' && <ExportListGrid type="All" />}
    </>
  );
};
