import * as yup from 'yup';
import { TFunction } from 'i18next';
import usePatientAndStudySchema from '../Form/_formForPatientAndStudyEdit';
import useAnonymizationSchema from '../Anonymization/_form';

const useValidationSchema = (
  t: TFunction,
  anonymizationFields: any[],
  validators: any,
  portalSetting: any,
  showEditForm: boolean,
) => {
  const { anonymizationSchema } = useAnonymizationSchema(anonymizationFields);

  const { studyAndPatientEditSchema } = usePatientAndStudySchema({
    t,
    validators,
    portalSetting,
    isStudyEditType: true,
  });

  const distributeSchema = {};

  const combinedSchema = {
    ...distributeSchema,
    ...anonymizationSchema,
    ...(showEditForm ? studyAndPatientEditSchema : {}),
  };
  const StudiesDistributeFormSchema = yup.object().shape({ ...combinedSchema });
  return { StudiesDistributeFormSchema };
};

export default useValidationSchema;
