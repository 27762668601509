import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Img } from 'components/Img/Img';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { IContent } from './_types';

const LoginAndPasswordRecoveryContent: React.FC<IContent> = ({ children }) => {
  const { t } = useTranslation('Login');
  const { isDarkMode, feConfigFromApi } = useAppInfo();

  const [openedPopup, setOpenedPopup] = useState<'contact' | 'label' | null>(null);
  const closePopup = () => setOpenedPopup(null);

  useEffect(() => {
    try {
      if (feConfigFromApi?.login.footer.label.visibleOnStartup) {
        setOpenedPopup('label');
      }
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feConfigFromApi]);

  const Popup = ({
    children,
    position = 'left',
  }: {
    children: React.ReactElement[] | React.ReactElement;
    position?: 'left' | 'right';
  }) => (
    <Box
      sx={{
        position: 'absolute',
        bottom: 30,
        color: '#313F50',
        fontSize: 13,
        p: 3,
        borderRadius: 1,
        boxShadow: 8,
        left: position === 'left' ? 0 : undefined,
        right: position === 'right' ? 0 : undefined,
        bgcolor: 'background.paper',
        zIndex: 1000,
      }}
    >
      <IconButton
        sx={{ position: 'absolute', right: 0, top: 0 }}
        onClick={() => {
          closePopup();
        }}
      >
        <Close />
      </IconButton>
      {children}
    </Box>
  );

  const FooterListItem = ({ children }: any) => (
    <Box
      component="li"
      sx={{
        position: { lg: 'relative' },
        mr: { xs: 0, lg: 3 },
        display: { lg: 'inline-block' },
      }}
    >
      {children}
    </Box>
  );

  return (
    <Box
      sx={{
        minWidth: '100vw',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: isDarkMode ? 'black' : feConfigFromApi?.login.background,
        flexDirection: 'column',
      }}
    >
      {/* Container */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          p: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              lg: 'row',
            },
            alignItems: {
              xs: 'center',
              lg: 'flex-start',
            },
          }}
        >
          <Box
            sx={{
              mr: {
                xs: 0,
                lg: 16,
              },
              maxWidth: {
                xs: 'none',
                lg: 550,
              },
              p: {
                xs: 4,
                lg: 0,
              },
            }}
          >
            <Box sx={{ mb: { xs: 2, lg: 4 } }}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/img/portal-${feConfigFromApi?.login.logo.application.variant}.svg`}
                alt="Marie Portal"
                sx={{
                  maxWidth: '100%',
                  maxHeight: {
                    xs: 40,
                    lg: 60,
                  },
                }}
              />
            </Box>

            <Box>
              <Typography
                component="p"
                sx={{
                  color: feConfigFromApi?.login.leftBlock.color,
                  fontSize: {
                    xs: 20,
                    lg: 26,
                  },
                  fontWeight: 300,
                  whiteSpace: 'pre-line',
                }}
              >
                {feConfigFromApi?.login.leftBlock.text}
              </Typography>
            </Box>

            {feConfigFromApi?.login.logo.customer && feConfigFromApi?.login.logo.customer.src ? (
              <Box sx={{ mt: { xs: 2, lg: 4 } }}>
                <Img
                  {...feConfigFromApi?.login.logo.customer}
                  sx={{ maxHeight: { xs: 50, lg: 'none' }, width: 'auto' }}
                  width={undefined}
                />
              </Box>
            ) : null}
          </Box>

          {children}
        </Box>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          flex: 0,
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: {
            xs: 'center',
            lg: 'flex-end',
          },
          py: 2,
          px: 4,

          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
        }}
      >
        <Box>
          <Typography
            component="ul"
            sx={{
              listStyle: 'none',
              p: 0,
              m: 0,
              mb: {
                xs: 2,
                lg: 0,
              },
              color: feConfigFromApi?.login.footer.color,
              '& a': {
                color: 'inherit',
                textDecoration: 'none',
              },
              '& > li > a:hover, & > li > p:hover': {
                color: `${feConfigFromApi?.login.footer.hoverColor} !important`,
              },
              fontSize: 16,
              fontWeight: 300,
            }}
          >
            {feConfigFromApi?.login.footer.orCzLink &&
            feConfigFromApi?.login.footer.orCzLink.show ? (
              <FooterListItem>
                <a
                  href={feConfigFromApi?.login.footer.orCzLink.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {feConfigFromApi?.login.footer.orCzLink.text || 'OR-CZ spol. s r.o.'}
                </a>
              </FooterListItem>
            ) : null}
            {feConfigFromApi?.login.footer.helpdesk &&
            feConfigFromApi?.login.footer.helpdesk.show ? (
              <FooterListItem>
                <a
                  href={feConfigFromApi?.login.footer.helpdesk.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {feConfigFromApi?.login.footer.helpdesk.text || t('helpdesk')}
                </a>
              </FooterListItem>
            ) : null}
            {feConfigFromApi?.login.footer.contact && feConfigFromApi?.login.footer.contact.show ? (
              <FooterListItem>
                <p
                  style={{
                    cursor: 'pointer',
                    margin: 0,
                    display: 'inline',
                  }}
                  onClick={() => setOpenedPopup('contact')}
                >
                  {feConfigFromApi?.login.footer.contact.text || t('phoneContact')}
                </p>

                {openedPopup === 'contact' ? (
                  <Popup>
                    <Box component="p" sx={{ m: 0, mb: 1, pr: 2 }}>
                      {t('requestsAddPriority')}{' '}
                      <a
                        href={feConfigFromApi?.login.footer.helpdesk.url}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: '#3A8BAB', textDecoration: 'underline' }}
                      >
                        {t('helpdesk')}
                      </a>
                    </Box>
                    <Box component="p" sx={{ m: 0, mb: 1 }}>
                      {feConfigFromApi?.login.footer.contact.text || t('phoneContact')}
                    </Box>
                    <Box component="table" sx={{ whiteSpace: 'nowrap', '& th, & td': { pr: 2 } }}>
                      <tbody>
                        {feConfigFromApi?.login.footer.contact.hours.day.show ? (
                          <tr>
                            <th>{t('dailyRoutine')}</th>
                            <td>{feConfigFromApi?.login.footer.contact.hours.day.hours}</td>
                            <th>
                              {t('phoneAbbr')}.:{' '}
                              {feConfigFromApi?.login.footer.contact.hours.day.phone}
                            </th>
                          </tr>
                        ) : null}

                        {feConfigFromApi?.login.footer.contact.hours.night.show ? (
                          <tr>
                            <th>{t('nightlyRoutine')}</th>
                            <td>{feConfigFromApi?.login.footer.contact.hours.night.hours}</td>
                            <th>
                              {t('phoneAbbr')}.:{' '}
                              {feConfigFromApi?.login.footer.contact.hours.night.phone}
                            </th>
                          </tr>
                        ) : null}

                        {feConfigFromApi?.login.footer.contact.hours.weekend.show ? (
                          <tr>
                            <th>{t('holidays')}</th>
                            <th>
                              {t('phoneAbbr')}.:{' '}
                              {feConfigFromApi?.login.footer.contact.hours.weekend.phone}
                            </th>
                          </tr>
                        ) : null}
                      </tbody>
                    </Box>
                  </Popup>
                ) : null}
              </FooterListItem>
            ) : null}
            {feConfigFromApi?.login.footer.label && feConfigFromApi?.login.footer.label.show ? (
              <FooterListItem>
                <p
                  style={{
                    cursor: 'pointer',
                    margin: 0,
                    display: 'inline',
                  }}
                  onClick={() => setOpenedPopup('label')}
                >
                  {feConfigFromApi?.login.footer.label.text || t('label')}
                </p>

                {openedPopup === 'label' ? (
                  <Popup position="right">
                    <Box
                      component="img"
                      src={feConfigFromApi?.login.footer.label.src}
                      alt="Štítek"
                      sx={{ maxWidth: { xs: '100%', sm: 400 } }}
                    />
                  </Popup>
                ) : null}
              </FooterListItem>
            ) : null}
          </Typography>
        </Box>
        <Box>
          {feConfigFromApi?.login.logo.marie ? (
            <Box>
              <Img
                {...feConfigFromApi?.login.logo.marie}
                sx={{
                  maxHeight: {
                    xs: 40,
                    lg: 'none',
                  },
                  width: 'auto',
                }}
                width={undefined}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default LoginAndPasswordRecoveryContent;
