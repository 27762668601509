import { api } from 'utils/hooks/useApi';
import { get } from 'lodash';

export const importFiles = (data: any) => api().post(`/portal-api/studyImport/metadata`, data);

export const getRules = (): Promise<any> =>
  api()
    .get(`/portal-api/studyImport/getRules`)
    .then((response) => {
      const data = get(response, 'data', false);
      return data;
    })
    .catch((error) => false);

export const getProductsByTypeAndFunction = (
  productType: string,
  searchFunction: string,
): Promise<any> =>
  api()
    .get(`/portal-api/product/getProductsByTypeAndFunction`, {
      params: { productType, function: searchFunction },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getPatientsFromPatientManagement = (data: any): Partial<Promise<any> | boolean> =>
  api()
    .post(`/portal-api/studyImport/getPatientsFromPatientManagement`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getPatientInformation = (patientID: string): Promise<any> =>
  api()
    .get(`/portal-api/studyImport/getPatientInformation`, {
      params: { patientID },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getWebCamImportConfig = (): Promise<any> =>
  api()
    .get(`/portal-api/config/getWebCamImportConfig`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);
