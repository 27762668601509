import React, { useMemo, useCallback, useState, useEffect } from 'react';
import {
  DataGridPro,
  GridRenderCellParams,
  GridRowModel,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridColumnVisibilityModel,
  GridRowId,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { Box, Paper, Stack, Tooltip } from '@mui/material';
import ViewerIcon from '@mui/icons-material/Image';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { parse } from 'date-fns';
import {
  get,
  find,
  pickBy,
  compact,
  keys,
  isArray,
  isEmpty,
  intersection,
  difference,
} from 'lodash';
import { Img } from 'components/Image/Img';
import {
  DATAGRID_SETTINGS_COLUMN_ORDER_MODEL,
  DATAGRID_SETTINGS_COLUMN_SORT_MODEL,
  DATAGRID_SETTINGS_COLUMN_WIDTHS,
  DATA_GRID_DEFAULT_SORTING,
  DATA_GRID_FILTER_HEADER_ROW_HEIGHT,
  DATA_GRID_ROW_HEIGHT,
  DATA_GRID_SERIES_ROW_HEIGHT,
  DATA_GRID_SERIES_ROW_HEIGHT_COMPACT_MODE,
  GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY,
  PREVIEW_URL_SIZE,
  PREVIEW_URL_SIZE_COMPACT_MODE,
} from 'constants/constants';
import { HumanBody } from 'components/HumanBody/HumanBody';
import { IGridSeries, IInstance, IInstanceDetail } from './_types';
import { getInstances, getUrlForOpenViewer } from 'modules/Studies/StudyDetail/_api';
import useAlerts from 'components/Alerts/useAlerts';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useGridSettings } from './useGridSettings';
import { stateIsSame } from 'utils/componentOptimizatons';
import { useAppSelector } from 'store/hooks';
import { useGrid } from 'utils/hooks/useGrid';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import useGridLocalization from './useGridLocalization';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { GridInstancesMUI } from './GridInstancesMUI';

const GridSeriesMUIPlain: React.FC<IGridSeries> = ({
  rows,
  previews,
  setNewPreviews,
  decubits,
  setNewDecubits,
  selecting,
  setSelection,
  customActions,
  showDetailPanel = false,
  handleLifeCycle = undefined,
}) => {
  const { t } = useTranslation('SearchResults');
  const { defViewer, compactMode } = useAppInfo();
  const { addErrorAlert } = useAlerts();
  const gridLocalization = useGridLocalization();
  const { toggleLoader } = useAppGlobals();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(
    GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY,
  );

  const {
    storeGridSettings,
    gridSettings,
    getUpdatedColumnWidthsAfterResize,
    getGridColumnsSort,
    getUpdatedColumnOrderAfterReordering,
  } = useGridSettings();
  const { setGridSettingsAndStore } = useGrid();

  const gridSortModel = getGridColumnsSort(GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY);

  const columnVisibilityModel = useAppSelector((state) =>
    get(state, `app.gridSettings.${GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY}.columnVisibilityModel`),
  );

  const setColumnVisibilityModel = useCallback(
    (newModel: GridColumnVisibilityModel) => {
      storeGridSettings(GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY, {
        columnVisibilityModel: pickBy(newModel, (item: Boolean) => item === false), // ukládáme pouze sloupce, které jsou false (= schované)
      });
    },
    [storeGridSettings],
  );

  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([]);
  const [instances, setInstances] = useState<IInstanceDetail[]>([]);

  const columns = useMemo(() => {
    const columns = [
      {
        field: 'actions',
        headerName: t('actions'),
        type: 'actions',
        hideable: false,
        renderCell: ({ row }: GridRenderCellParams) => {
          let actions = [
            <GridActionsCellItem
              icon={
                <Tooltip title={t('openInBrowserTitle')}>
                  <ViewerIcon />
                </Tooltip>
              }
              label={t('openInBrowserTitle')}
              disabled={!defViewer ? true : false}
              onClick={() => {
                const studyID = row.study.hasOwnProperty('studyInstanceUid')
                  ? get(row, 'study.studyInstanceUid', '')
                  : get(row, 'study.uid', '');
                const archiveID = get(row, 'study.archiveId', '');
                const patientId = row.study.hasOwnProperty('patientIdentificationNumber')
                  ? get(row, 'study.patientIdentificationNumber', '')
                  : get(row, 'study.patientId', '');
                const serieUid = get(row, 'uid', '');

                getUrlForOpenViewer(studyID, patientId, archiveID, serieUid).then(
                  (response) =>
                    response
                      ? window.open(response, '_blank')
                      : addErrorAlert(t('Errors:cannotOpenLink')),
                  (error) => addErrorAlert(t('Errors:cannotOpenLink')),
                );
              }}
            />,
          ];

          if (customActions !== undefined) {
            actions = actions.concat(customActions(row));
          }

          return actions;
        },
      },
      {
        field: 'previewUrl',
        headerName: t('Grid:seriesPreview'),
        renderCell: ({ row }: GridRenderCellParams) => {
          const studyID = row.study.hasOwnProperty('studyInstanceUid')
            ? get(row, 'study.studyInstanceUid', '')
            : get(row, 'study.uid', '');
          const archiveID = get(row, 'study.archiveId', '');
          const patientId = row.study.hasOwnProperty('patientIdentificationNumber')
            ? get(row, 'study.patientIdentificationNumber', '')
            : get(row, 'study.patientId', '');
          const serieUid = get(row, 'uid', '');

          return (
            <Img
              patientId={patientId}
              productId={archiveID}
              studyIUID={studyID}
              seriesIUID={serieUid}
              width={compactMode ? PREVIEW_URL_SIZE_COMPACT_MODE : PREVIEW_URL_SIZE}
              dialogPreview={true}
              preview={find(previews, { uid: get(row, 'uid') })}
              setNewPreviews={setNewPreviews}
              canLoadImgs={true}
            />
          );
        },
      },
      {
        field: 'bodyPart',
        headerName: t('Grid:bodyPart'),
        renderCell: ({ row }: GridRenderCellParams) => {
          const decubit = get(row, 'decubits', null);
          const studyUID = get(row, 'study.uid', null);
          const productId = get(row, 'study.archiveId', null);
          const serieUID = get(row, 'uid', null);
          return decubit ? (
            <div style={{ /*maxWidth: 40*/ width: compactMode ? 28 : 40, margin: '0' }}>
              <HumanBody
                pars={{
                  selectedBodypart: get(row, 'decubits.code', ''),
                  decubits: decubit,
                  studyUID,
                  productId,
                  serieUID,
                }}
                decubit={find(decubits, { id: `${productId}_${studyUID}_${serieUID}` })}
                setNewSaveDecubits={setNewDecubits}
                t={t}
              />
            </div>
          ) : (
            <div>-</div>
          );
        },
      },
      {
        field: 'seriesNumber',
        headerName: t('seriesNumber'),
      },
      {
        field: 'dateTime',
        headerName: t('seriesDateTime'),
        type: 'dateTime',
        valueGetter: (value: any) => value && parse(value, 'yyyyMMdd HHmmss', new Date()),
      },
      {
        field: 'modality',
        headerName: t('modality'),
      },
      {
        field: 'description',
        headerName: t('seriesDescription'),
      },
      { field: 'stationAet', headerName: t('stationAet') },
      {
        field: 'numberOfInstances',
        headerName: t('seriesInstancesCount'),
      },
    ];

    const reorderedColumnsWithWidths = reorderColumnsByGridSettings(
      injectColumnWidthsIntoColumns(columns, 200),
    );

    const updatedColumns = reorderedColumnsWithWidths.map((column) => ({
      ...column,
      filterable: column.type !== 'actions' && !hiddenColumns.includes(column.field),
    }));

    return updatedColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    previews,
    decubits,
    setNewPreviews,
    setNewDecubits,
    t,
    addErrorAlert,
    defViewer,
    customActions,
    hiddenColumns,
    injectColumnWidthsIntoColumns,
    reorderColumnsByGridSettings,
  ]);

  const onColumnWidthChange = useCallback(
    async (changedColumn: any) => {
      const columnNewWidth = changedColumn.width;
      const columnName = changedColumn.colDef.field;

      const gridSettingsForAPI = {
        ...get(gridSettings, GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY, {}),
        [DATAGRID_SETTINGS_COLUMN_WIDTHS]: getUpdatedColumnWidthsAfterResize(
          columns,
          columnName,
          columnNewWidth,
        ),
      };
      const gridSettingsForRedux = {
        ...gridSettings,
        [GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY]: gridSettingsForAPI,
      };

      setGridSettingsAndStore({
        key: GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY,
        gridSettingsForAPI,
        gridSettingsForRedux,
      });
    },
    [gridSettings, setGridSettingsAndStore, columns, getUpdatedColumnWidthsAfterResize],
  );

  const onSortModelChange = useCallback(
    async (newSortModel: any) => {
      const gridSettingsForAPI = {
        ...get(gridSettings, GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY, {}),
        [DATAGRID_SETTINGS_COLUMN_SORT_MODEL]: newSortModel,
      };
      const gridSettingsForRedux = {
        ...gridSettings,
        [GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY]: gridSettingsForAPI,
      };

      setGridSettingsAndStore({
        key: GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY,
        gridSettingsForAPI,
        gridSettingsForRedux,
      });
    },
    [gridSettings, setGridSettingsAndStore],
  );

  // Compute hidden columns not to be shown in filters
  useEffect(() => {
    const hiddenColumns = compact(
      keys(columnVisibilityModel).map((columnName) =>
        get(columnVisibilityModel, columnName) === false ? columnName : undefined,
      ),
    );
    setHiddenColumns(hiddenColumns);
  }, [columnVisibilityModel]);

  const getRowId = useCallback((row: GridRowModel) => row.uid, []);

  const QuickSearchToolbar = (props: any) => {
    return (
      <Box sx={{ p: 0.5 }}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        {/* <GridToolbarDensitySelector /> */}
      </Box>
    );
  };

  let indexShiftForReordering = selecting ? 1 : 0;

  const onColumnOrderChange = useCallback(
    async (params: any) => {
      const newColumnOrder = getUpdatedColumnOrderAfterReordering(
        columns,
        params.column.field,
        params.oldIndex - indexShiftForReordering,
        params.targetIndex - indexShiftForReordering,
      );

      const gridSettingsForAPI = {
        ...get(gridSettings, GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY, {}),
        [DATAGRID_SETTINGS_COLUMN_ORDER_MODEL]: newColumnOrder.map((column) => column.field),
      };
      const gridSettingsForRedux = {
        ...gridSettings,
        [GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY]: gridSettingsForAPI,
      };

      await setGridSettingsAndStore({
        key: GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY,
        gridSettingsForAPI,
        gridSettingsForRedux,
      });
    },
    [
      columns,
      getUpdatedColumnOrderAfterReordering,
      gridSettings,
      indexShiftForReordering,
      setGridSettingsAndStore,
    ],
  );

  // const hiddenFields = ['__detail_panel_toggle__', '__check__', 'actions'];

  // const getTogglableColumns = (columns: GridColDef[]) => {
  //   return columns
  //     .filter((column) => !hiddenFields.includes(column.field))
  //     .map((column) => column.field);
  // };

  const columnsThatCauseRowsToBeFixedHeight = ['bodyPart', 'previewUrl'];

  // If all columns that cause rows to have fixed height are hidden, we can set row height to typical DATA_GRID_ROW_HEIGHT
  const gridShouldHaveFixedRowHeight =
    intersection(hiddenColumns, columnsThatCauseRowsToBeFixedHeight).length !==
    columnsThatCauseRowsToBeFixedHeight.length;

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  // handle funkce na rozbalení images u sérií
  const handleDetailPanelExpandedRowIdsChange = useCallback(
    async (newIds: GridRowId[]) => {
      if (newIds.length > detailPanelExpandedRowIds.length) {
        const searchForStudySeries = get(difference(newIds, detailPanelExpandedRowIds), '[0]', '');
        const isInstanceSerieLoaded = find(instances, { iid: searchForStudySeries });
        let items: IInstance[] = get(isInstanceSerieLoaded, 'items', []);
        const archiveID = get(rows[0], 'study.archiveId');
        const studyID = get(rows[0], 'study.studyInstanceUid');

        if (isInstanceSerieLoaded === undefined) {
          toggleLoader(true);

          const instancesLoad = await getInstances(archiveID, studyID, searchForStudySeries);

          items = instancesLoad || [];
          toggleLoader(false);
          if (instancesLoad) {
            setInstances((prevInstances: IInstanceDetail[]) => [
              ...prevInstances,
              {
                iid: searchForStudySeries,
                items,
                study: { uid: studyID, archiveID },
                serie: { uid: searchForStudySeries },
              },
            ]);
          }
        }
      }
      setDetailPanelExpandedRowIds(newIds);
    },
    [detailPanelExpandedRowIds, instances, rows, toggleLoader],
  );

  const DetailPanel = useCallback(
    (row: GridRowParams) => {
      const instancesToShow = find(instances, { iid: row.id }) as IInstanceDetail;
      const instancesRows = (get(instancesToShow, 'items') || []).map((instance: IInstance) => ({
        ...instance,
      }));
      return (
        <Stack
          sx={{ py: compactMode ? 0.5 : 2, height: '100%', boxSizing: 'border-box' }}
          direction="column"
        >
          <Paper sx={{ flex: 1, mx: 'auto', width: '95%', p: 1 }}>
            <Stack direction="column" spacing={1} sx={{ height: 1 }}>
              <GridInstancesMUI
                rows={instancesRows}
                studyAndSerie={{
                  archiveId: get(row, 'row.study.archiveId'),
                  patientId: get(row, 'row.study.patientIdentificationNumber'),
                  studyUid: get(row, 'row.study.studyInstanceUid'),
                  serieUid: get(row, 'row.uid'),
                }}
                handleLifeCycle={handleLifeCycle}
              />
            </Stack>
          </Paper>
        </Stack>
      );
    },
    [instances, compactMode, handleLifeCycle],
  );

  return (
    <DataGridPro
      sortingOrder={DATA_GRID_DEFAULT_SORTING}
      headerFilters={true}
      getRowId={getRowId}
      density={compactMode && !gridShouldHaveFixedRowHeight ? 'compact' : 'standard'} // MAPO-3319
      autoHeight={isEmpty(rows)}
      rows={rows}
      columns={columns}
      rowHeight={
        gridShouldHaveFixedRowHeight
          ? compactMode
            ? DATA_GRID_SERIES_ROW_HEIGHT_COMPACT_MODE
            : DATA_GRID_SERIES_ROW_HEIGHT
          : DATA_GRID_ROW_HEIGHT
      }
      disableRowSelectionOnClick={true}
      columnVisibilityModel={columnVisibilityModel || {}}
      onColumnVisibilityModelChange={setColumnVisibilityModel}
      onColumnWidthChange={onColumnWidthChange}
      onColumnOrderChange={onColumnOrderChange}
      localeText={gridLocalization}
      sx={{
        flex: 1,
      }}
      getDetailPanelContent={showDetailPanel ? DetailPanel : undefined}
      getDetailPanelHeight={getDetailPanelHeight} // Height based on the content.
      detailPanelExpandedRowIds={detailPanelExpandedRowIds}
      onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
      hideFooter={true}
      slots={{
        toolbar: QuickSearchToolbar,
        detailPanelExpandIcon: ChevronRight,
        detailPanelCollapseIcon: ExpandMore,
      }}
      slotProps={{
        headerFilterCell: {
          height: DATA_GRID_FILTER_HEADER_ROW_HEIGHT,
          InputComponentProps: {
            size: 'small',
          },
        },
      }}
      checkboxSelection={selecting}
      onRowSelectionModelChange={setSelection}
      sortModel={isArray(gridSortModel) ? gridSortModel : []}
      onSortModelChange={onSortModelChange}
    />
  );
};

export const GridSeriesMUI = React.memo(GridSeriesMUIPlain, stateIsSame);
