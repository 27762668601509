import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid } from '@mui/material';
import { setRequestStatus } from './_api';
import useValidationSchema from './_form';
import { IRequestForm, IRequestStateForm } from './_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import useAlerts from 'components/Alerts/useAlerts';
import FormInput from 'components/Form/Input/Input';
import { STATE_REJECTED } from 'constants/constants';

const RequestsStateForm: React.FC<IRequestStateForm> = ({
  request,
  toggleDialog,
  linkBackAfterChangeState,
}) => {
  const { t } = useTranslation('Request');
  const navigate = useNavigate();
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const isCommentRequired = get(request, 'state') === STATE_REJECTED;
  const { RequestFormSchema, formItems } = useValidationSchema(t, isCommentRequired);

  const methods = useForm<IRequestForm>({
    resolver: yupResolver(RequestFormSchema),
  });
  const { handleSubmit, reset, register } = methods;

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    await setRequestStatus(values).then(
      () => {
        addSuccessAlert(t('requestStateChanged'));
        toggleDialog(false);
        if (linkBackAfterChangeState) {
          navigate(linkBackAfterChangeState);
        }
      },
      () => {
        addErrorAlert(t('errorChangingState'));
      },
    );
    toggleLoader(false);
  });

  const getEntity = async () => {
    toggleLoader();
    try {
      let init: IRequestForm = {
        requestId: get(request, 'request.id'),
        status: get(request, 'state'),
        comment: '',
      };

      reset({ ...init });
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <input {...register('requestId')} type="hidden" />
          <input {...register('status')} type="hidden" />
          <Grid container={true} spacing={2}>
            {formItems.map((field: any) => {
              return (
                <Grid item={true} xs={12}>
                  <FormInput {...field} type="text" />
                </Grid>
              );
            })}

            <Grid item={true} xs={12}>
              <Button variant="contained" color="primary" type="submit">
                {t('confirm')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default RequestsStateForm;
