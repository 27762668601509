import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { IHospital } from './_types';

export const getHospitals = (): Promise<IHospital[]> =>
  api()
    .get(`/portal-api/hospital/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editHospital = (data: IHospital): Promise<boolean> =>
  api()
    .post(`/portal-api/hospital/update`, data)
    .then((response) => true)
    .catch((error) => false);

export const createHospital = (data: IHospital): Promise<boolean> =>
  api()
    .post(`/portal-api/hospital/create`, data)
    .then((response) => true)
    .catch((error) => false);

export const removeHospital = (id: number): Promise<boolean> =>
  api()
    .delete(`/portal-api/hospital/delete`, { params: { id } })
    .then((response) => true)
    .catch((error) => false);
