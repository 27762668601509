import React from 'react';
import { IChangeStatusesDialog } from './_types';
import ChangeStatusesForm from './ChangeStatusesForm';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import { stateIsSame } from 'utils/componentOptimizatons';

const PlainChangeStatusesDialog: React.FC<IChangeStatusesDialog> = ({
  changeStatusesDialog,
  setChangeStatusesDialog,
  studies,
  handleLifeCycle,
  callback,
}) => {
  const handleCloseChangeStatusesDialog = () => setChangeStatusesDialog(false);

  return (
    <OverflowedDialog
      open={changeStatusesDialog}
      onClose={handleCloseChangeStatusesDialog}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth={true}
      withScrolling={false}
    >
      <ChangeStatusesForm
        toggleDialog={handleCloseChangeStatusesDialog}
        studies={studies}
        handleLifeCycle={handleLifeCycle}
        callback={callback}
      />
    </OverflowedDialog>
  );
};

export const ChangeStatusesDialog = React.memo(PlainChangeStatusesDialog, stateIsSame);
