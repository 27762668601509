import { Grid, IconButton, Tooltip } from '@mui/material';
import { Papeer } from 'components/Papeer/Papeer';
import { get } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { DataGridPro, GridRowModel, GridRenderCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import { useActions } from 'utils/hooks/useActions';
import Header from 'components/Header/Header';
import { Box } from '@mui/system';
import { DATA_GRID_DEFAULT_SORTING, DATA_GRID_ROW_HEIGHT } from 'constants/constants';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import useGridLocalization from 'components/SearchResultsMUI/useGridLocalization';

export const ReorderSourceStudyInfo: React.FC = () => {
  const { reorderStore, sourceStudyForReorderIsInStore } = useStudyInfo();
  const { t } = useTranslation('Studies');
  const { resetStudies } = useActions();
  const { compactMode } = useAppInfo();

  const getRowId = useCallback((row: GridRowModel) => row.studyInstanceUid, []);

  const gridLocalization = useGridLocalization();

  const columns: GridColDef[] = [
    {
      field: 'patientName',
      headerName: t('patientName'),
      renderCell: ({ row }: GridRenderCellParams) => {
        const lastname = get(row, 'patient.name.lastName', '');
        const firstname = get(row, 'patient.name.firstName', '');
        return `${lastname || ''} ${firstname || ''}`;
      },
    },
    { field: 'accessionNumber', headerName: t('accessionNumber') },
    {
      field: 'patientId',
      headerName: t('patientId'),
      renderCell: ({ row }: GridRenderCellParams) => get(row, 'patient.identificationNumber', ''),
    },
    {
      field: 'studyDate',
      headerName: t('studyDate'),
      width: 200,
      type: 'date',
      renderCell: ({ row }: GridRenderCellParams) =>
        format(new Date(get(row, 'dateTime', '')), 'dd.MM.yyyy'),
    },
    {
      field: 'patientBirthDate',
      headerName: t('patientBirthDate'),
      width: 200,
      renderCell: ({ row }: GridRenderCellParams) =>
        format(new Date(get(row, 'patient.dateBirth', '')), 'dd.MM.yyyy'),
    },
    {
      field: 'modalities',
      headerName: t('modalities'),
      renderCell: ({ row }: GridRenderCellParams) => get(row, 'modalitiesInStudy', []).join(', '),
    },
    { field: 'studyDescription', headerName: t('studyDescription') },
    {
      field: 'archiveName',
      headerName: t('archiveName'),
      renderCell: ({ row }: GridRenderCellParams) => get(row, 'archive.name', ''),
    },
    {
      field: 'patientSex',
      headerName: t('patientSex'),
      width: 150,
      //valueGetter: (value: any, row: any) => get(params, 'row.patient.sex', ''),
    },
    {
      field: 'noOfInstances',
      headerName: t('noOfInstances'),
      renderCell: ({ row }: GridRenderCellParams): string =>
        `${get(row, 'numberOfStudyRelatedSeries', '')}/${get(
          row,
          'numberOfStudyRelatedInstances',
          '',
        )}`,
    },
  ];

  return sourceStudyForReorderIsInStore ? (
    <>
      <Papeer bottomMargin={true}>
        <Grid container={true} alignItems="center">
          <Grid item={true}>
            <Header
              title={`${t('studyReorderTitle')} / ${t('existingSourceStudy')}`}
              // TourComponent={renderedSteps()}
            />
          </Grid>
          <Grid item={true}>
            <Tooltip
              title={t('removeSourceStudyForReorder')}
              data-tour="reorderSourceStudyDeleteButton"
            >
              <IconButton
                onClick={() => resetStudies('reorder')}
                sx={{ mb: compactMode ? 0 : 0.5 }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Box data-tour="reorderSourceStudyGrid">
          <DataGridPro
            sortingOrder={DATA_GRID_DEFAULT_SORTING}
            getRowId={getRowId}
            rows={reorderStore}
            columns={columns}
            disableRowSelectionOnClick={true}
            localeText={gridLocalization}
            hideFooter={true}
            density={compactMode ? 'compact' : 'standard'}
            rowHeight={DATA_GRID_ROW_HEIGHT}
          />
        </Box>
      </Papeer>
    </>
  ) : null;
};
