import React, { useEffect, useState } from 'react';
import { IFindStudyDialog } from './_types';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { SearchForm } from 'components/SeachForm/SearchForm';
import { SEARCH_FUNCTION_IMPORT } from 'constants/constants';
import { IStudyResultForGrid } from 'modules/Search/_types';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { SearchResultsMUI } from 'components/SearchResultsMUI/SearchResultsMUI';
import { useSubmitSearchForm } from 'utils/hooks/useSubmitSearchForm';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { CloseButtonDialog } from 'components/Dialog/CloseDialogButton';
import { ISearchValues } from 'components/SeachForm/_types';

export const FindStudyDialog: React.FC<IFindStudyDialog> = ({
  isOpen,
  setIsOpen,
  actions = [],
  importProps,
  disableStudyAndPatientForm,
  isActionFirstColumn,
  datafromToken,
  setSelectedStudyByToken,
  canUseDataFromToken,
  setCanUseDataFromToken,
}) => {
  const { toggleLoader } = useAppGlobals();
  const [rows, setRows] = useState<IStudyResultForGrid[]>([]);
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);

  const { onSubmitSearchForm } = useSubmitSearchForm(rows, setRows);

  const onSubmit = async (values: ISearchValues) => {
    toggleLoader();
    await onSubmitSearchForm(values);
    toggleLoader(false);
  };

  const handleCloseDialog = () => {
    setRows([]);
    setIsOpen(false);
  };

  useEffect(() => {
    if (rows && rows.length === 1 && canUseDataFromToken) {
      setSelectedStudyByToken({ ...rows[0] });
      setCanUseDataFromToken(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows?.length, canUseDataFromToken]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title" />
      <CloseButtonDialog onClose={handleCloseDialog} />
      <DialogContent sx={{ minHeight: '60vh' }}>
        <SearchForm
          action={SEARCH_FUNCTION_IMPORT}
          onSubmit={onSubmit}
          useForOtherForm={false}
          showFilterInHeader={true}
          isForModal={true}
          {...(datafromToken
            ? {
                datafromToken,
                setSelectedStudyByToken,
                canUseDataFromToken,
                setCanUseDataFromToken,
              }
            : {})}
        />
        {rows && rows.length > 0 && (
          <Box sx={{ height: { xs: '80vh', md: '70vh', lg: '55vh' }, overflowY: 'auto' }}>
            <SearchResultsMUI
              actions={actions}
              rows={rows}
              setRows={setRows}
              selection={selection}
              setSelection={setSelection}
              selecting={false}
              showDetailPanel={false}
              handleCloseDialog={handleCloseDialog}
              importProps={importProps}
              disableStudyAndPatientForm={disableStudyAndPatientForm}
              isActionFirstColumn={isActionFirstColumn}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
