import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';

import {
  INCPeHRequest,
  INCPeHResponse,
  INCPeHRequestForFindDocuments,
  INCPeHRequestForGetDocument,
} from './_types';

export const getNCPeHCountryConfig = (): any =>
  api()
    .get(`/portal-api/config/getNCPeHCountryConfig`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const findPatient = (data: INCPeHRequest): Promise<INCPeHResponse> | boolean =>
  api()
    .post(`/portal-api/ncp/findPatient`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => ({
      data: get(error, 'response.data'),
      status: get(error, 'response.status'),
      statusText: get(error, 'response.statusText'),
    }));

export const getDocuments = (data: INCPeHRequestForFindDocuments): Promise<any> | boolean =>
  api()
    .post(`/portal-api/ncp/findDocuments`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getDocument = (data: INCPeHRequestForGetDocument): Promise<any> | boolean =>
  api()
    .post(`/portal-api/ncp/getDocument`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);
