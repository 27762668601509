import { Help, Image } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import useAlerts from 'components/Alerts/useAlerts';
import { Img } from 'components/Image/Img';
import { format, parse } from 'date-fns';
import { get, sortBy } from 'lodash';
import { getUrlForOpenViewer } from 'modules/Studies/StudyDetail/_api';
import { useTranslation } from 'react-i18next';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useStudy } from 'utils/hooks/useStudy';
import { IRenderInstances } from './_types';
import { PREVIEW_URL_SIZE, PREVIEW_URL_SIZE_COMPACT_MODE } from 'constants/constants';

export const RenderInstances: React.FC<IRenderInstances> = ({
  study,
  serie,
  showInstancesCheckboxes,
  operations,
}) => {
  const { t } = useTranslation('Studies');
  const { defViewer, hidePreview, compactMode } = useAppInfo();
  const { getInstanceTime } = useStudy();
  const { addErrorAlert } = useAlerts();
  const images = serie.image || [];

  const getParsedDate = (dateTime: any, format: string) => {
    let parsedDate: any = null;
    try {
      parsedDate = parse(dateTime, format, new Date());
      if (isNaN(parsedDate)) {
        parsedDate = null;
      }
    } catch (e) {}
    return parsedDate;
  };

  const parsedDate = getParsedDate(get(serie, 'dateTime'), 'yyyyMMdd HHmmss');

  const onViewer = (
    studyID: string,
    patientId: string,
    archiveID: string,
    seriesUID: string,
    sopUid: string,
  ) => {
    getUrlForOpenViewer(studyID, patientId, archiveID, seriesUID, sopUid).then(
      (response) =>
        response ? window.open(response, '_blank') : addErrorAlert(t('cannotOpenLink')),
      (error) => addErrorAlert(t('cannotOpenLink')),
    );
  };

  const {
    setImageSelected,
    setSomeAction,
    someActionNameFromMainComponent,
    currentImage,
    setCurrentImage,
  } = operations;
  const viewDicomAttributes = someActionNameFromMainComponent === 'viewDicomAttributes';

  return (
    <Box sx={{ ml: 8, ...(compactMode ? { my: 0.5 } : {}) }}>
      {sortBy(images, ['instanceNumber']).map((image, index) => {
        const showTime = parsedDate ? getInstanceTime(image, format(parsedDate, 'hh:mm')) : null;
        const createdDateTime =
          parsedDate && showTime ? `${format(parsedDate, 'dd. MM. yyyy')} ${showTime.time}` : '?';

        const label = viewDicomAttributes
          ? `${t('instanceNumber')}: ${get(image, 'instanceNumber', '')}`
          : `${t('created')}: ${createdDateTime} ${t('instanceNumber')}: ${get(
              image,
              'instanceNumber',
              '',
            )}`;

        return (
          <Box key={image.sopinstanceUid} sx={{ display: 'flex', alignItems: 'center' }}>
            {hidePreview ? null : (
              <Img
                patientId={get(study, 'patient.identificationNumber', '')}
                productId={get(study, 'archive.id', '') as string}
                studyIUID={get(study, 'studyInstanceUid', '')}
                seriesIUID={get(serie, 'uid')}
                imageIUID={get(image, 'sopinstanceUid', '')}
                series={null}
                topIndex={0}
                width={compactMode ? PREVIEW_URL_SIZE_COMPACT_MODE : PREVIEW_URL_SIZE}
                dialogPreview={true}
              />
            )}

            {viewDicomAttributes && setCurrentImage ? (
              <Button
                size="small"
                onClick={() => {
                  const imageUID = get(image, 'sopinstanceUid', '');
                  setCurrentImage(imageUID);
                  setSomeAction(study, serie, image);
                }}
                variant={
                  (currentImage && currentImage === get(image, 'sopinstanceUid', '')) ||
                  (!currentImage && index === 0)
                    ? 'contained'
                    : 'text'
                }
              >
                {label}
              </Button>
            ) : (
              <Tooltip title={image.sopinstanceUid} placement="top-end">
                {showInstancesCheckboxes ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={image.imageIsSelected}
                        sx={{ ...(compactMode ? { p: 0, mr: 2 } : {}) }}
                      />
                    }
                    label={label}
                    onChange={(_event) =>
                      setImageSelected(serie.uid, study.iid, image.sopinstanceUid)
                    }
                    sx={{ mr: 1, ml: 0 }}
                  />
                ) : (
                  <>{label}</>
                )}
              </Tooltip>
            )}

            {!viewDicomAttributes && (
              <Tooltip title={t('timeTakenFromSeries')} placement="top-end">
                <Box
                  sx={{
                    ml: 2,
                    cursor: 'help',
                    color: 'rgba(0, 0, 0, 0.54)',
                    display: 'inline-flex',
                  }}
                >
                  <Help style={{ fontSize: 16 }} />
                </Box>
              </Tooltip>
            )}

            {defViewer && (
              <Tooltip title={t('openInBrowser')} placement="top-start">
                <IconButton
                  color="primary"
                  aria-label="Report"
                  onClick={() => {
                    onViewer(
                      get(study, 'studyInstanceUid', ''),
                      get(study, 'patient.identificationNumber', ''),
                      get(study, 'archive.id', '') as unknown as string,
                      get(serie, 'uid', ''),
                      get(image, 'sopinstanceUid', ''),
                    );
                  }}
                  sx={{ ...(compactMode ? { p: 0, mr: 2 } : {}) }}
                >
                  <Image />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
