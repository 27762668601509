import { IconButton } from '@mui/material';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import HelpIcon from '@mui/icons-material/Help';
import { useDispatch } from 'react-redux';
import { setTourControlPropsToStore } from 'store/reducers/appReducer';

const HelperComponent = () => {
  const { compactMode } = useAppInfo();
  const dispatch = useDispatch();
  const { tourControlPropsStore } = useAppInfo();

  return (
    <>
      {!tourControlPropsStore?.isTourOpen && (
        <IconButton
          aria-label="help"
          onClick={() => {
            dispatch(setTourControlPropsToStore({ isTourOpen: true }));
          }}
          size={compactMode ? 'small' : 'large'}
        >
          <HelpIcon fontSize="small" />
        </IconButton>
      )}
    </>
  );
};

export default HelperComponent;
