import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';

const useValidationSchema = () => {
  const { required, date, integer, number, testLengthUnder60Hours, min } = useValidators();

  const TestFormSchema = yup.object().shape({
    name: required(yup.string()),
    type: required(yup.string()),
    validFrom: date(yup.date(), 'required'),
    maxWrongAnswersAllowed: min(integer(number(yup.number())), 0, 'minLengthNumber'),
    maxTestLength: required(testLengthUnder60Hours(yup.string())),
  });

  return {
    TestFormSchema,
  };
};

export default useValidationSchema;
