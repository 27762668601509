import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { synchronizeAddressBook } from './_api';
import Header from 'components/Header/Header';
import useAlerts from 'components/Alerts/useAlerts';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';
import { useFetchAddressBooksAndRenderRecipients } from 'utils/hooks/useFetchAddressBooksAndRenderRecipients';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourAddressBooks } from './TourAddressBooks';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'addressBookMui';

const AddressBooks: React.FC = () => {
  const { t } = useTranslation('Mdex');
  const { toggleLoader } = useAppGlobals();
  const { hasRole } = useUser();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  useWithTitle(); // sets title to document

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const { addressBooks, loadAddressBooks } = useFetchAddressBooksAndRenderRecipients();

  const synchronizeAddressBooks = async () => {
    toggleLoader();
    const resp = await synchronizeAddressBook();
    if (resp) {
      addSuccessAlert(t('synchronized'));
      await loadAddressBooks();
    } else {
      addErrorAlert(t('errorSynchronizing'));
    }
    toggleLoader(false);
  };

  const buttonForSynchronize = hasRole('ROLE_MDEX_CAN_SYNCHRONIZE_ADDRESS_BOOK') ? (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={() => {
        synchronizeAddressBooks();
      }}
      sx={{ ml: 1 }}
      data-tour="mdex-addressBook-synchronize"
    >
      {t('synchronizeAddressBooks')}
    </Button>
  ) : null;

  const getRowId = useCallback((row: GridRowModel) => row.uuid, []);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      { field: 'institutionName', headerName: t('institutionName') },
      { field: 'aeTitle', headerName: t('aeTitle') },
      { field: 'city', headerName: t('city') },
      { field: 'address', headerName: t('address') },
      { field: 'email', headerName: t('email') },
      { field: 'phone', headerName: t('phone') },
    ]),
  );

  const renderedSteps = () => {
    return <TourAddressBooks />;
  };

  return (
    <>
      <Header
        title={t('addressBook')}
        addObject={buttonForSynchronize}
        TourComponent={renderedSteps()}
      />
      <MuiGrid
        gridKey={muiGridKey}
        getRowId={getRowId}
        rows={addressBooks}
        columns={columns}
        dataTour="mdex-addressBook-grid"
      />
    </>
  );
};

export default AddressBooks;
