import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { Line } from 'components/Line/Line';
import FormInput from 'components/Form/Input/Input';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { get } from 'lodash';
import useValidationSchema from './_form';
import { decrypt, download, encrypt, generate } from './_api';
import { apiDataToDownloadFile } from 'utils/hooks/useApp';

import { IRSAForm } from './_types';
import FormSelect from 'components/Form/Select/Select';
import useAlerts from 'components/Alerts/useAlerts';
import { Box } from '@mui/system';

const TabRSA: React.FC = () => {
  const { t } = useTranslation('Tools');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [response, setResponse] = useState<string>('');

  const { RSAFormSchema } = useValidationSchema();
  const methods = useForm<IRSAForm>({
    resolver: yupResolver(RSAFormSchema),
  });
  const { handleSubmit } = methods;

  const submitByAction = async (action: string, values: any) => {
    toggleLoader();

    const bitLength: number = get(values, 'bitLength', 0) as number;
    const hashtext = get(values, 'hashtext', '');
    if (action === 'generate' && bitLength <= 0) {
      addErrorAlert(t('invalidBitLength'));
    } else if (action === 'encrypt' && hashtext === '') {
      addErrorAlert(t('invalidText'));
    } else if (action === 'decrypt' && hashtext === '') {
      addErrorAlert(t('invalidHash'));
    } else {
      if (action === 'generate' && bitLength > 0) {
        const response = await generate(bitLength);
        if (response) {
          addSuccessAlert(t('generated'));
        } else {
          addErrorAlert(t('errorGenerating'));
        }
      } else if (action === 'download') {
        const response = await download();
        if (response) {
          apiDataToDownloadFile(response, 'publicRsaKey.txt');
        }
      } else if (action === 'encrypt') {
        const response = await encrypt(encodeURI(hashtext));
        if (response) {
          setResponse(response.toString());
          addSuccessAlert(t('encrypted'));
        } else {
          addErrorAlert(t('errorEncrypting'));
        }
      } else if (action === 'decrypt') {
        const response = await decrypt(hashtext);
        if (response) {
          setResponse(decodeURI(response.toString()));
          addSuccessAlert(t('decrypted'));
        } else {
          addErrorAlert(t('errorDecrypting'));
        }
      }
    }

    toggleLoader(false);
  };
  return (
    <Typography component="div">
      <Paper elevation={0} sx={{ p: 2, mb: 2 }}>
        <FormProvider {...methods}>
          <form>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} md={6} lg={3} xl={2}>
                <FormSelect
                  name={'bitLength'}
                  label={t('bitLength')}
                  items={[1024, 2048, 4096].map((item: any) => ({ id: item, label: item }))}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mb: 2 }}>
              <Button
                sx={{ mr: 2 }}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleSubmit((values) => submitByAction('generate', values))()}
              >
                {t('generate')}
              </Button>
              <Button
                sx={{ mr: 2 }}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleSubmit((values) => submitByAction('download', values))()}
              >
                {t('download')}
              </Button>
            </Box>

            <Grid item={true} xs={12}>
              <Line />
            </Grid>

            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <FormInput
                  name="hashtext"
                  label={t('hashtext')}
                  {...{ autoComplete: 'hashtext' }}
                />
              </Grid>
            </Grid>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} style={{ wordBreak: 'break-all' }}>
                {response}
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mb: 2 }}>
              <Button
                sx={{ mr: 2 }}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleSubmit((values) => submitByAction('encrypt', values))()}
              >
                {t('encrypt')}
              </Button>

              <Button
                sx={{ mr: 2 }}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleSubmit((values) => submitByAction('decrypt', values))()}
              >
                {t('decrypt')}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Paper>
    </Typography>
  );
};

export default TabRSA;
