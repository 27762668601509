import { format, subDays, subMonths, endOfDay, startOfDay } from 'date-fns';

export const formatDateForSearch = (date: Date, apiDateFormat: string) => {
  return date ? format(date, apiDateFormat) : null;
};

export const formatDateTimeForSearch = (date: Date, apiDateFormat: string, type = '') => {
  let sDate = null;
  if (
    type === 'start' &&
    (apiDateFormat === 'YYYY-MM-DDTHH:mm:ss' || apiDateFormat === "yyyy-MM-dd'T'HH:mm:ss")
  ) {
    sDate = date ? format(startOfDay(date), apiDateFormat) : null;
  } else if (
    type === 'end' &&
    (apiDateFormat === 'YYYY-MM-DDTHH:mm:ss' || apiDateFormat === "yyyy-MM-dd'T'HH:mm:ss")
  ) {
    sDate = date ? format(endOfDay(date), apiDateFormat) : null;
  } else {
    sDate = date ? format(date, apiDateFormat) : null;
  }
  return sDate;
};

export const convertSearchTimes = (time: string, apiTimeFormat: string) => {
  const timeAttributes: any = {
    dateFrom: null,
    dateTo: null,
    timeFrom: null,
    timeTo: null,
  };

  switch (time) {
    case '24h':
      const actualDate = new Date();
      timeAttributes.timeTo = formatDateTimeForSearch(actualDate, apiTimeFormat);
      actualDate.setHours(actualDate.getHours() - 24);
      timeAttributes.timeFrom = formatDateTimeForSearch(actualDate, apiTimeFormat);
      break;
  }
  return timeAttributes;
};

export const convertSearchDates = (
  date: string,
  dateFrom: Date | null,
  dateTo: Date | null,
  apiDateFormat: string,
) => {
  const dateAttributes: any = {
    dateFrom: null,
    dateTo: null,
  };

  switch (date) {
    case 'today':
      dateAttributes.dateFrom = formatDateTimeForSearch(new Date(), apiDateFormat, 'start');
      dateAttributes.dateTo = formatDateTimeForSearch(new Date(), apiDateFormat, 'end');
      break;
    case 'yesterday':
      dateAttributes.dateFrom = formatDateTimeForSearch(
        subDays(new Date(), 1),
        apiDateFormat,
        'start',
      );
      dateAttributes.dateTo = formatDateTimeForSearch(subDays(new Date(), 1), apiDateFormat, 'end');
      break;
    case 'lastTreeDays':
      dateAttributes.dateFrom = formatDateTimeForSearch(
        subDays(new Date(), 3),
        apiDateFormat,
        'start',
      );
      dateAttributes.dateTo = formatDateTimeForSearch(new Date(), apiDateFormat, 'end');
      break;
    case 'lastWeek':
      dateAttributes.dateFrom = formatDateTimeForSearch(
        subDays(new Date(), 7),
        apiDateFormat,
        'start',
      );
      dateAttributes.dateTo = formatDateTimeForSearch(new Date(), apiDateFormat, 'end');
      break;
    case 'lastMonth':
      dateAttributes.dateFrom = formatDateTimeForSearch(
        subMonths(new Date(), 1),
        apiDateFormat,
        'start',
      );
      dateAttributes.dateTo = formatDateTimeForSearch(new Date(), apiDateFormat, 'end');
      break;
    case 'customDate':
      dateAttributes.dateFrom = dateFrom
        ? formatDateTimeForSearch(dateFrom, apiDateFormat, 'start')
        : null;
      dateAttributes.dateTo = dateTo ? formatDateTimeForSearch(dateTo, apiDateFormat, 'end') : null;
      break;
  }

  return dateAttributes;
};
