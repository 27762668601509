import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import HistorySearchForm from './HistorySearchForm';
import { IStudyResultForGrid } from 'modules/Search/_types';
import Header from 'components/Header/Header';
import { SearchResultsMUI } from 'components/SearchResultsMUI/SearchResultsMUI';
import { SHREDDING_PRINT } from 'constants/constants';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourShreddingHistory } from './TourShreddingHistory';

const Histories: React.FC = () => {
  const { t } = useTranslation('Studies');

  const [rows, setRows] = useState<IStudyResultForGrid[]>([]);
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);
  useWithTitle(); // sets title to document
  const renderedSteps = () => {
    return <TourShreddingHistory />;
  };

  return (
    <>
      <Header title={t('shreddingConfirmedTitle')} TourComponent={renderedSteps()} />
      <HistorySearchForm setRows={setRows} />
      <SearchResultsMUI
        rows={rows}
        setRows={setRows}
        actions={[SHREDDING_PRINT]}
        selection={selection}
        setSelection={setSelection}
        isShredding={true}
        isShreddingPrint={true}
        dataTour="shreddings-history-grid"
        nameAsLink={false}
      />
    </>
  );
};

export default Histories;
