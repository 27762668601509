import React from 'react';
import { Button as MuiButton, ButtonProps } from '@mui/material';
import { useAppInfo } from 'utils/hooks/useAppInfo';

export type { ButtonProps };

const Button: React.FC<ButtonProps> = (props) => {
  const { compactMode } = useAppInfo();
  return <MuiButton {...props} size={compactMode ? 'small' : 'medium'} />;
};

export default Button;
