import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';

import useValidationSchema from './_formSeries';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import FormInput from 'components/Form/Input/Input';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { get, isEmpty, isFunction, trim } from 'lodash';
import { deleteImages } from './_api';
import useAlerts from 'components/Alerts/useAlerts';
import { IStudyDetailInstancesForm } from './_types';
import { GridInstancesMUI } from 'components/SearchResultsMUI/GridInstancesMUI';
import { TAB_SERIES } from 'constants/constants';

const StudyDetailInstancesForm: React.FC<IStudyDetailInstancesForm> = ({
  toggleDialog,
  studyAndSerie,
  images,
  handleLifeCycle = undefined,
}) => {
  const { t } = useTranslation('StudyDetail');
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { toggleLoader } = useAppGlobals();
  const { SeriesFormSchema } = useValidationSchema();
  const methods = useForm<any>({
    resolver: yupResolver(SeriesFormSchema),
  });
  const { handleSubmit, watch, reset } = methods;
  const canConfirm = isEmpty(trim(watch('reason')));

  const clearAction = () => {
    toggleDialog(false);
  };

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const prepareValues = {
      reason: get(values, 'reason', ''),
      study: {
        studyInstanceUid: studyAndSerie.studyUid,
        archive: {
          id: studyAndSerie.archiveId,
        },
      },
      series: [
        {
          uid: studyAndSerie.serieUid,
          image: images.map((img: any) => ({ sopinstanceUid: get(img, 'sopinstanceUid', null) })),
        },
      ],
    };
    const response = await deleteImages(prepareValues);
    if (response) {
      addSuccessAlert(t('confirmDeletingImages'));
      clearAction();
      if (isFunction(handleLifeCycle)) {
        handleLifeCycle(studyAndSerie.studyUid, studyAndSerie.archiveId, false, TAB_SERIES);
      }
    } else {
      addErrorAlert(t('errorDeletingImages'));
    }
    toggleLoader(false);
  });

  useEffect(() => {
    reset({ reason: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header title={t('removeImages')} />
      <Papeer>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <Grid container={true} alignItems="flex-end">
              <Grid item={true} xs={12}>
                <FormInput
                  name="reason"
                  label={t('reasonRemoveImages')}
                  required={true}
                  multiline={true}
                  rows={2}
                />
                <EntityButtons
                  confirmReplaceSave={true}
                  toggleDialog={clearAction}
                  disabledSubmit={canConfirm}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Papeer>
      <Papeer>
        <GridInstancesMUI
          rows={images}
          studyAndSerie={{
            archiveId: studyAndSerie.archiveId,
            patientId: studyAndSerie.patientId,
            studyUid: studyAndSerie.studyUid,
            serieUid: studyAndSerie.serieUid,
          }}
          selecting={false}
        />
      </Papeer>
    </>
  );
};

export default StudyDetailInstancesForm;
