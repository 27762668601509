import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compact, get } from 'lodash';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Tooltip } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { getPriorities, removePriority } from './_api';
import PriorityForm from './PriorityForm';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import Header from 'components/Header/Header';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'prioritiesMUI';

const Priorities: React.FC = () => {
  const { t } = useTranslation('PrioritiesAndOperations');
  useWithTitle();
  const { toggleLoader } = useAppGlobals();
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();
  const { hasRole } = useUser();
  const [dialogState, setDialogState] = useState<boolean>(false);
  const [priorityId, setPriorityId] = useState<number>(0);
  const [allPriorities, setAllPriorities] = useState<any[]>([]);
  const [prioritiesName, setPrioritiesName] = useState<any[]>([]);

  const loadEntity = async () => {
    toggleLoader();
    try {
      setPriorityId(0);
      const allPriorities = await getPriorities();
      setAllPriorities(allPriorities);

      const prioritiesName: any[] = [];
      allPriorities.forEach((priority) => {
        prioritiesName.push(get(priority, 'name', null));
      });
      setPrioritiesName(prioritiesName);
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  const { onEntityRemove } = useEntityRemove(removePriority, t, loadEntity);

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const isEditor = hasRole('ROLE_PRIORITY_EDITOR');

  const toggleDialog = (state: boolean = false) => {
    setDialogState(state);
  };

  const onEntityEdit = async (id: number) => {
    setPriorityId(id);
    toggleDialog(id > 0 ? true : false);
  };

  const addItemInDialog = () => {
    setPriorityId(0);
    toggleDialog(true);
  };

  useEffect(() => {
    loadEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const id = priorityId ? priorityId : dialogState ? 'create' : null;

  const renderedDialog = (
    <OverflowedDialog
      title={t('dialog.priority')}
      open={dialogState}
      onClose={() => {
        toggleDialog(false);
        setPriorityId(0);
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <PriorityForm
        toggleDialog={toggleDialog}
        keyValues={prioritiesName}
        id={id}
        loadEntity={loadEntity}
      />
    </OverflowedDialog>
  );

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      compact([
        isEditor
          ? {
              field: 'actions',
              headerName: t('Grid:actions'),
              type: 'actions',
              hideable: false,
              width: 100,
              renderCell: ({ row }: GridRenderCellParams) => {
                return (
                  <>
                    <GridActionsCellItem
                      icon={
                        <Tooltip title={t('Grid:edit')}>
                          <EditIcon />
                        </Tooltip>
                      }
                      label={t('Grid:edit')}
                      onClick={() => onEntityEdit(get(row, 'id'))}
                    />
                    <GridActionsCellItem
                      icon={
                        <Tooltip title={t('Grid:delete')}>
                          <DeleteIcon />
                        </Tooltip>
                      }
                      label={t('Grid:delete')}
                      onClick={() => {
                        dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }));
                      }}
                      disabled={
                        get(row, 'state', null) === 'NEW' ||
                        !get(row, 'canDelete', true) ||
                        get(row, 'system', false)
                      }
                    />
                  </>
                );
              },
            }
          : null,
        { field: 'name', headerName: t('name') },
      ]),
    ),
  );

  return (
    <>
      <Header
        title=""
        {...(isEditor ? { addItemInDialog } : {})}
        toggleDialogText={t('add')}
        topMargin={true}
      />
      <MuiGrid
        gridKey={muiGridKey}
        rows={allPriorities}
        columns={columns}
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
      />
      {renderedDialog}
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmAction}
        />
      )}
    </>
  );
};

export default Priorities;
