import HelperComponent from 'components/Tour/HelperComponent';
import { getDropDownStep } from 'components/Tour/_dropDownStep';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

interface IUseSteps {
  type: 'statistics' | 'byTagStatistics';
  data?: any;
  options?: any;
}

export const TourReports = ({ type, data, options }: IUseSteps) => {
  const { t } = useTranslation('Reactour');

  const allStepsStatistics = ({ isDropDownOpen, currentOpenDropdown }: any) => {
    const steps = {
      '[data-tour="statisticsCheckBoxes"]': t('statistics.checkBoxes'),
      ...(currentOpenDropdown !== 'stationNames' && getDropDownStep({ isDropDownOpen, t })),
      '[data-tour="statisticsAdditionalInfo"]': t('statistics.additionalInfo'),
      ...(currentOpenDropdown === 'stationNames' &&
        getDropDownStep({ isDropDownOpen, t, selector: 'multiAutocomplete' })),
      '[data-tour="statisticsShowButton"]': t('statistics.showButton'),
      ...(data && options ? { '[data-tour="statisticsGrid"]': t('statistics.grid') } : {}),
    };
    return steps;
  };

  const allStepsByTagStatistics = () => {
    const steps = {
      '[data-tour="statisticsByTagStatistics"]': t('statistics.byTagStatistics'),
    };
    return steps;
  };

  let stepsDefinition;
  if (type === 'statistics') stepsDefinition = allStepsStatistics;
  if (type === 'byTagStatistics') stepsDefinition = allStepsByTagStatistics;
  useReactTour({ stepsDefinition });

  return <HelperComponent />;
};
