import { get, includes } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { IUser, IUserFormResponse } from './_types';

export const findUser = (data: any, activeTab: string = 'db'): Promise<IUser[]> =>
  api()
    .post(`/portal-api/user/find?allowedAuthMethodId=${activeTab}`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const findUsersFromDB = (): Promise<IUser[]> =>
  api()
    .post(`/portal-api/user/getAllFromDb`, {})
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getUser = (username: string, activeTab: string = 'db'): Promise<IUser> =>
  api()
    .get(`/portal-api/user/get?allowedAuthMethodId=${activeTab}`, { params: { username } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editUser = (user: IUser, activeTab: string = 'db'): Promise<any | boolean> =>
  api().post(`/portal-api/user/edit?allowedAuthMethodId=${activeTab}`, user);

export const createUser = (user: IUser, activeTab: string = 'db'): Promise<any | boolean> =>
  api().post(`/portal-api/user/create?allowedAuthMethodId=${activeTab}`, user);

export const removeUser = (username: string, activeTab: string = 'db'): Promise<boolean> =>
  api()
    .get(`/portal-api/user/delete?allowedAuthMethodId=${activeTab}`, { params: { username } })
    .then((response) => true)
    .catch((error) => false);

export const getUserSetting = (username: string) =>
  api()
    .get(`/portal-api/userSetting/get`, { params: { username } })
    .then((response) => get(response, 'data'))
    .catch(() => false);

export const getFacilities = (
  getOnlyUsers: boolean = false,
  getOnlyWorkplaces: boolean = false,
  forLoggedUser: boolean = true,
  workPlaceCode: string | null = null,
): Promise<any> | boolean =>
  api()
    .get(`/portal-api/facility/getAll`, {
      params: {
        getOnlyUsers,
        getOnlyWorkplaces,
        forLoggedUser,
        ...(workPlaceCode ? { workPlaceCode } : {}),
      },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const setFavouriteFacility = (facilityId: string, favourite: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/userSetting/setUserFavouriteFacility`, {
      params: { facilityId, favourite },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const setFavouriteFacilities = (facilityIds: any, favourite: boolean): Promise<boolean> =>
  api()
    .post(`/portal-api/userSetting/setUserFavouriteFacilities`, { facilityIds, favourite })
    .then((response) => true)
    .catch((error) => false);

export const editUserAndUserSettings = (
  user: any,
  authMethodId: string = 'db',
): Promise<IUserFormResponse> =>
  api()
    .post(
      `/portal-api/userSetting/editUserAndUserSettings?allowedAuthMethodId=${authMethodId}`,
      user,
    )
    .then((response) => ({ success: true }))
    .catch((error) => ({
      success: false,
      msg: includes([404, 500, 503], get(error, 'response.status'))
        ? 'error.apiNotWorking'
        : get(error, 'response.data', 'error.generalError'),
    }));

export const getAvailableViewers = (): any =>
  api()
    .get(`/portal-api/userSetting/getAvailableViewers`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllButtons = (): any =>
  api()
    .get(`/portal-api/welcome/getButtons`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const removeButton = (buttonId: string): Promise<boolean> =>
  api()
    .get(`/portal-api/userSetting/removeButton`, { params: { buttonId } })
    .then((response) => true)
    .catch((error) => false);

export const addButton = (buttonId: string): Promise<boolean> =>
  api()
    .get(`/portal-api/userSetting/addButton`, { params: { buttonId } })
    .then((response) => true)
    .catch((error) => false);

export const setButtonPosition = (position: string, userButtonId: string): Promise<boolean> =>
  api()
    .get(`/portal-api/userSetting/setButtonPosition`, { params: { position, userButtonId } })
    .then((response) => true)
    .catch((error) => false);

export const setAutomaticOpeningOfButton = (
  state: boolean,
  userButtonId: string,
): Promise<boolean> =>
  api()
    .get(`/portal-api/userSetting/setAutomaticOpeningOfButton`, { params: { state, userButtonId } })
    .then((response) => true)
    .catch((error) => false);

export const setViewer = (userId: any, viewerId: any): Partial<Promise<IUser> | boolean> =>
  api()
    .get(`/portal-api/userSetting/setViewer`, { params: { userId, viewerId } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const setEnabledState = (
  username: string,
  state: boolean,
  activeTab: string = 'db',
): Promise<boolean> =>
  api()
    .get(`/portal-api/user/setEnabledState?allowedAuthMethodId=${activeTab}`, {
      params: { username, state },
    })
    .then((response) => true)
    .catch((error) => false);

export const setTimelineOrderDirection = (order: string): Promise<boolean> =>
  api()
    .get(`/portal-api/userSetting/setTimelineOrderDirection`, { params: { order } })
    .then((response) => true)
    .catch((error) => false);

export const userRetire = (data: any): Promise<IUser[] | boolean> =>
  api()
    .post(`/portal-api/user/retire`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const checkUserPasswordPolicy = (): any =>
  api()
    .get(`/portal-api/user/checkUserPasswordPolicy`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const generateKey = (): Promise<boolean> =>
  api()
    .get(`/portal-api/userSetting/generateKey`, {})
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const setUse2FAForLoginAPI = (use2FAForLogin: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/userSetting/setUse2FAForLogin`, { params: { use2FAForLogin } })
    .then((response) => true)
    .catch((error) => false);
