import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box, Tooltip } from '@mui/material';
import { Assignment as DetailIcon } from '@mui/icons-material';
import { getTransmissions } from './_api';
import { ITabTransmission, ITransmission } from './_types';
import { TransmissionDetail } from './TransmissionDetail';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { bytesToSize } from 'utils/import';
import Header from 'components/Header/Header';
import { TRANSFER_SEND, TRANSFER_RECEIVED } from 'constants/constants';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'transmissionMui';

export const TabTransmission: React.FC<ITabTransmission> = ({ type }) => {
  const { t } = useTranslation('Transmissions');
  const { toggleLoader } = useAppGlobals();

  useWithTitle();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const [allTransmissions, setAllTransmissions] = useState<ITransmission[]>([]);
  const [detailRow, setDetailRow] = useState<any>(null);

  const loadEntity = async () => {
    toggleLoader();
    const allTransmissions = await getTransmissions(type);
    if (allTransmissions) {
      setAllTransmissions(allTransmissions);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    if (type !== '') {
      loadEntity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('actions'),
        type: 'actions',
        hideable: false,
        renderCell: ({ row }: GridRenderCellParams) => (
          <GridActionsCellItem
            icon={
              <Tooltip title={t('detail')}>
                <DetailIcon />
              </Tooltip>
            }
            label={t('detail')}
            onClick={() => onCustomEntityDetail(row)}
          />
        ),
      },
      { field: 'transferStart', headerName: t('transferStart') },
      { field: 'transferEnd', headerName: t('transferEnd') },
      {
        field: `institution${type === TRANSFER_SEND ? 'To' : 'From'}`,
        headerName: t(`institution${type === TRANSFER_SEND ? 'To' : 'From'}`),
      },
      { field: `city${type === TRANSFER_SEND ? 'To' : 'From'}`, headerName: t('city') },
      {
        field: 'patientName',
        headerName: t('patientName'),
        valueGetter: (value: any, row: any) => (get(row, 'patientName') || '').replace('^', ' '),
      },
      { field: 'patientIdent', headerName: t('patientIdent') },
      { field: 'objectIdent', headerName: t('objectIdent') },
      { field: 'objectCount', headerName: t('objectCount') },
      {
        field: 'prepareObjectSize',
        headerName: t('objectSize'),
        valueGetter: (value: any, row: any) => bytesToSize(get(row, 'objectSize', 0) || 0),
      },
    ]),
  );

  const closeDialog = () => {
    setDetailRow(null);
  };
  const onCustomEntityDetail = (row: any) => {
    setDetailRow(row);
  };

  return (
    <>
      <Header title={''} />
      <Box
        data-tour={
          type === TRANSFER_RECEIVED
            ? 'mdex-transmission-gridIncoming'
            : 'mdex-transmission-gridOutgoing'
        }
      >
        <MuiGrid
          gridKey={muiGridKey}
          rows={allTransmissions}
          columns={columns}
          initialSortMode={[{ field: 'transferStart', sort: 'desc' }]}
        />
      </Box>

      <OverflowedDialog
        key={'detailRowDialog'}
        open={detailRow ? true : false}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
        withScrolling={true}
        title={t('transmissionDetail')}
        toggleDialog={closeDialog}
      >
        <TransmissionDetail detailRow={detailRow} />
      </OverflowedDialog>
    </>
  );
};
