import React from 'react';
import { useTranslation } from 'react-i18next';
import { get, isEmpty, isNull } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import useValidationSchema from './_formFilterDialog';
import { IFormFilterDialog, IFormFilterDialogValues } from './_types';
import { saveUserFilter } from 'modules/Administration/Filters/_api';
import FormInput from 'components/Form/Input/Input';
import useAlerts from 'components/Alerts/useAlerts';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';

export const FormFilterDialog: React.FC<IFormFilterDialog> = ({
  allFormValues,
  filterDialogState,
  setFilterDialogState,
}) => {
  const { t } = useTranslation('SearchForm');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { getUserFilters } = useUser();

  const { FilterDialogFormSchema } = useValidationSchema();

  const methods = useForm<IFormFilterDialogValues>({
    resolver: yupResolver(FilterDialogFormSchema),
    defaultValues: { name: '' },
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();

    let formValues = { ...allFormValues };

    const formSource = get(allFormValues, 'source.sources', []);
    if (!isEmpty(formSource)) {
      const minifiedDormSource = formSource.map((source: any) => ({ id: source.id }));
      formValues = { ...formValues, source: { sources: minifiedDormSource } };
    }

    const study = get(allFormValues, 'study', null);
    if (!isNull(study)) {
      let modalities = get(study, 'modalities', []);
      if (!isEmpty(modalities)) {
        const minifiedModalities = modalities.map((modality: any) => ({
          name: modality.name,
          id: modality.id,
        }));
        modalities = minifiedModalities;
      }
      formValues = { ...formValues, study: { ...study, modalities } };
    }
    const tags = get(allFormValues, 'tags', null);
    if (tags != null) {
      formValues = {
        ...formValues,
        tags: tags,
      };
    }

    const values2 = {
      ...values,
      filter: JSON.stringify(formValues),
    };
    await saveUserFilter(values2).then(
      (response) => {
        addSuccessAlert(t('filter.saved'));
        getUserFilters();
        setFilterDialogState(false);
      },
      (error) => {
        let tError = 'errorSaving';
        if (get(error, 'response.data', '') === 'search.filter.is.already.exists') {
          tError = get(error, 'response.data');
        }
        addErrorAlert(t(tError));
      },
    );

    toggleLoader(false);
  });

  return (
    <>
      <Dialog
        open={filterDialogState}
        onClose={() => {
          setFilterDialogState(false);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">{t('filter.dialogTitle')}</DialogTitle>
        <DialogContent>
          <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
              <Grid container={true} alignItems="flex-end">
                <Grid item={true} xs={12}>
                  <FormInput name="name" label={t('filter.name')} />
                </Grid>
                <Grid item={true} xs={12} md={12} lg={12} xl={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                    <Button
                      onClick={() => setFilterDialogState(false)}
                      variant="contained"
                      color="inherit"
                      size="large"
                      sx={{ ml: 1 }}
                    >
                      {t('close')}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                      sx={{ ml: 1 }}
                    >
                      {t('confirm')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FormFilterDialog;
