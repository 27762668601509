import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const CloseButtonDialog = ({ onClose }: any) => (
  <IconButton
    aria-label="Close"
    style={{
      position: 'absolute',
      right: '15px',
      top: '8px',
    }}
    onClick={onClose}
  >
    <CloseIcon />
  </IconButton>
);
