import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { compact, get } from 'lodash';
import { useValidation } from 'utils/hooks/useValidation';
import { IPatientAndStudySchema } from './_types';

const usePatientAndStudySchema = ({
  t,
  validators,
  portalSetting,
  isStudyEditType,
  isImport, // pro import je navíc provádějící lékař a modalita
  withStudyUID,
  modalities,
  isDicomOrder,
  isRequstDistribute,
  isRequestEdit,
  multipleModality,
  orderSelected,
  studySelected,
  editAllowedForOrderImport,
  editAllowedForImportWithStudy,
}: IPatientAndStudySchema) => {
  const {
    requiredAllowedCharsWithListAllowedChars,
    requiredEnabledNotFutureObject,
    requiredOrNotObject,
    requiredOrNotDateTimeObject,
    required,
  } = useValidators();
  const {
    isEnabled,
    allRequired,
    patientRequired,
    allMin,
    patientMin,
    allAllowedChars,
    patientAllowedChars,
    allMessage,
    patientMessage,
    allMatches,
    patientMatches,
  } = useValidation(t, validators);

  const fieldDisabledInImportWithOrder = orderSelected && !editAllowedForOrderImport;
  const fieldDisabledInImportWithStudySelected = studySelected && !editAllowedForImportWithStudy;

  const sexes = [
    { id: 'F', label: t('female') },
    { id: 'M', label: t('male') },
    { id: 'O', label: t('other') },
  ];
  const patientObj = (name: any) => ({
    name,
    isRequired: patientRequired(name),
    minimum: patientMin(name),
    allowedChars: patientAllowedChars(name),
    message: patientMessage(name),
    matches: patientMatches(name),
  });
  const otherObj = (name: any, validatorsName: any, withRepeat: boolean = false) => ({
    name,
    isRequired: allRequired(validatorsName),
    minimum: allMin(validatorsName),
    allowedChars: allAllowedChars(validatorsName),
    message: allMessage(validatorsName),
    matches: allMatches(validatorsName, withRepeat),
  });
  const dateObj = (fieldName: any, name: any, isEnabledName: any) => ({
    fieldName,
    isRequired: allRequired(name),
    isEnabled: isEnabled(isEnabledName),
  });
  const timeObj = (fieldName: any, name: any) => ({
    fieldName,
    isRequired: allRequired(name),
  });
  const requiredObj = (fieldName: any, name: any) => ({
    fieldName,
    isRequired: allRequired(name),
  });

  const studyAndPatientEditSchema = {
    patient: yup.object({
      name: yup.object({
        lastName: requiredAllowedCharsWithListAllowedChars(yup.string(), patientObj('lastName')),
        firstName: requiredAllowedCharsWithListAllowedChars(yup.string(), patientObj('firstName')),
        middleName: requiredAllowedCharsWithListAllowedChars(
          yup.string(),
          patientObj('middleName'),
        ),
        prefix: requiredAllowedCharsWithListAllowedChars(yup.string(), patientObj('prefix')),
        suffix: requiredAllowedCharsWithListAllowedChars(yup.string(), patientObj('suffix')),
      }),

      identificationNumber: requiredAllowedCharsWithListAllowedChars(
        yup.string().nullable(),
        otherObj('identificationNumber', 'patient.identification', true),
      ),
      ...requiredOrNotObject(yup.string(), requiredObj('sex', 'patient.sex')),
      ...requiredEnabledNotFutureObject(
        yup.date(),
        dateObj('dateBirth', 'patient.dateBirth', 'patient.dateBirth.futureAllowed'),
        'required',
      ),
    }),
    ...(isStudyEditType || isImport
      ? {
          study: yup.object({
            studyDescription: requiredAllowedCharsWithListAllowedChars(
              yup.string(),
              otherObj('studyDescription', 'study.description', true),
            ),
            accessionNumber: requiredAllowedCharsWithListAllowedChars(
              yup.string(),
              otherObj('accessionNumber', 'study.accessionNumber', true),
            ),
            ...requiredOrNotObject(
              yup.mixed(),
              requiredObj('patientDispositions.size', 'study.patientDispositions.size'),
            ),
            ...requiredOrNotObject(
              yup.mixed(),
              requiredObj('patientDispositions.weight', 'study.patientDispositions.weight'),
            ),
            ...requiredEnabledNotFutureObject(
              yup.date(),
              dateObj('studyDate', 'study.studyDate', 'study.studyDate.futureAllowed'),
              'required',
            ),
            ...requiredOrNotDateTimeObject(
              yup.date(),
              timeObj('studyTime', 'study.studyTime'),
              'required',
            ),
          }),
          ...(isImport
            ? {
                modality: yup.lazy((value) =>
                  multipleModality
                    ? required(
                        yup
                          .array()
                          .of(yup.object().nullable())
                          .nullable()
                          .test(
                            'empty-array',
                            t('Errors:required'),
                            (value) => value === null || (Array.isArray(value) && value.length > 0),
                          ),
                      )
                    : required(yup.object().nullable()),
                ),
              }
            : {}),

          ...(get(portalSetting, 'seeRequestingPhysician', null) && !isDicomOrder
            ? {
                requestingPhysician: yup
                  .object({
                    name: yup.object({
                      lastName: requiredAllowedCharsWithListAllowedChars(
                        yup.string(),
                        otherObj(
                          'requestingPhysician.name.lastName',
                          'study.requestingPhysician.name.lastName',
                          true,
                        ),
                      ),
                      firstName: requiredAllowedCharsWithListAllowedChars(
                        yup.string(),
                        otherObj(
                          'requestingPhysician.name.firstName',
                          'study.requestingPhysician.name.firstName',
                          true,
                        ),
                      ),
                      middleName: requiredAllowedCharsWithListAllowedChars(
                        yup.string(),
                        otherObj(
                          'requestingPhysician.name.middleName',
                          'study.requestingPhysician.name.middleName',
                          true,
                        ),
                      ),
                      prefix: requiredAllowedCharsWithListAllowedChars(
                        yup.string(),
                        otherObj(
                          'requestingPhysician.name.prefix',
                          'study.requestingPhysician.name.prefix',
                          true,
                        ),
                      ),
                      suffix: requiredAllowedCharsWithListAllowedChars(
                        yup.string(),
                        otherObj(
                          'requestingPhysician.name.suffix',
                          'study.requestingPhysician.name.suffix',
                          true,
                        ),
                      ),
                    }),
                  })
                  .nullable(),
              }
            : {}),
          ...(get(portalSetting, 'seeReferringPhysician', null) && !isDicomOrder
            ? {
                referringPhysician: yup
                  .object({
                    name: yup.object({
                      lastName: requiredAllowedCharsWithListAllowedChars(
                        yup.string(),
                        otherObj(
                          'referringPhysician.name.lastName',
                          'study.referringPhysician.name.lastName',
                          true,
                        ),
                      ),
                      firstName: requiredAllowedCharsWithListAllowedChars(
                        yup.string(),
                        otherObj(
                          'referringPhysician.name.firstName',
                          'study.referringPhysician.name.firstName',
                          true,
                        ),
                      ),
                      middleName: requiredAllowedCharsWithListAllowedChars(
                        yup.string(),
                        otherObj(
                          'referringPhysician.name.middleName',
                          'study.referringPhysician.name.middleName',
                          true,
                        ),
                      ),
                      prefix: requiredAllowedCharsWithListAllowedChars(
                        yup.string(),
                        otherObj(
                          'referringPhysician.name.prefix',
                          'study.referringPhysician.name.prefix',
                          true,
                        ),
                      ),
                      suffix: requiredAllowedCharsWithListAllowedChars(
                        yup.string(),
                        otherObj(
                          'referringPhysician.name.suffix',
                          'study.referringPhysician.name.suffix',
                          true,
                        ),
                      ),
                    }),
                  })
                  .nullable(),
              }
            : {}),
          ...(get(portalSetting, 'seePerformingPhysician', null) && isImport && !isDicomOrder
            ? {
                performingPhysician: yup
                  .object({
                    name: yup.object({
                      lastName: requiredAllowedCharsWithListAllowedChars(
                        yup.string(),
                        otherObj(
                          'performingPhysician.name.lastName',
                          'study. performingPhysician.name.lastName',
                          true,
                        ),
                      ),
                      firstName: requiredAllowedCharsWithListAllowedChars(
                        yup.string(),
                        otherObj(
                          'performingPhysician.name.firstName',
                          'study.performingPhysician.name.firstName',
                          true,
                        ),
                      ),
                      middleName: requiredAllowedCharsWithListAllowedChars(
                        yup.string(),
                        otherObj(
                          'performingPhysician.name.middleName',
                          'study.performingPhysician.name.middleName',
                          true,
                        ),
                      ),
                      prefix: requiredAllowedCharsWithListAllowedChars(
                        yup.string(),
                        otherObj(
                          'performingPhysician.name.prefix',
                          'study.performingPhysician.name.prefix',
                          true,
                        ),
                      ),
                      suffix: requiredAllowedCharsWithListAllowedChars(
                        yup.string(),
                        otherObj(
                          'performingPhysician.name.suffix',
                          'study.performingPhysician.name.suffix',
                          true,
                        ),
                      ),
                    }),
                  })
                  .nullable(),
              }
            : {}),
        }
      : {}),
  };

  const setPhysician = (whatPhysician: string, validators: any) => {
    return [
      { type: 'div', label: t(whatPhysician) },
      {
        name: `${whatPhysician}.name.lastName`,
        label: t('lastName'),
        required: get(validators, `study.${whatPhysician}.name.lastName.required`, false),
        disabled: fieldDisabledInImportWithOrder || fieldDisabledInImportWithStudySelected,
        inputProps: {
          maxLength: get(validators, `study.${whatPhysician}.name.lastName.max`, 60),
        },
      },
      {
        name: `${whatPhysician}.name.firstName`,
        label: t('firstName'),
        required: get(validators, `study.${whatPhysician}.name.firstName.required`, false),
        disabled: fieldDisabledInImportWithOrder || fieldDisabledInImportWithStudySelected,
        inputProps: {
          maxLength: get(validators, `study.${whatPhysician}.name.firstName.max`, 60),
        },
      },
      {
        name: `${whatPhysician}.name.middleName`,
        label: t('middleName'),
        required: get(validators, `study.${whatPhysician}.name.middleName.required`, false),
        disabled: fieldDisabledInImportWithOrder || fieldDisabledInImportWithStudySelected,
        inputProps: {
          maxLength: get(validators, `study.${whatPhysician}.name.middleName.max`, 60),
        },
      },
      {
        name: `${whatPhysician}.name.prefix`,
        label: t('prefix'),
        required: get(validators, `study.${whatPhysician}.name.prefix.required`, false),
        disabled: fieldDisabledInImportWithOrder || fieldDisabledInImportWithStudySelected,
        inputProps: {
          maxLength: get(validators, `study.${whatPhysician}.name.prefix.max`, 60),
        },
      },
      {
        name: `${whatPhysician}.name.suffix`,
        label: t('suffix'),
        required: get(validators, `study.${whatPhysician}.name.suffix.required`, false),
        disabled: fieldDisabledInImportWithOrder || fieldDisabledInImportWithStudySelected,
        inputProps: {
          maxLength: get(validators, `study.${whatPhysician}.name.suffix.max`, 60),
        },
      },
    ];
  };

  let fields: any = {};
  fields = {
    ...fields,
    patient: [
      [
        {
          name: 'patient.name.lastName',
          label: t('lastName'),
          required: get(validators, 'patient.name.lastName.required', false),
          inputProps: { maxLength: get(validators, 'patient.name.lastName.max', 60) },
          disabled: orderSelected,
        },
        {
          name: 'patient.name.firstName',
          label: t('firstName'),
          required: get(validators, 'patient.name.firstName.required', false),
          inputProps: { maxLength: get(validators, 'patient.name.firstName.max', 60) },
          disabled: orderSelected,
        },
        {
          name: 'patient.name.middleName',
          label: t('middleName'),
          required: get(validators, 'patient.name.middle.required', false),
          inputProps: { maxLength: get(validators, 'patient.name.middleName.max', 60) },
          disabled: orderSelected,
        },
        {
          name: 'patient.name.prefix',
          label: t('prefix'),
          required: get(validators, 'patient.name.prefix.required', false),
          inputProps: { maxLength: get(validators, 'patient.name.prefix.max', 60) },
          disabled: orderSelected,
        },
        {
          name: 'patient.name.suffix',
          label: t('suffix'),
          required: get(validators, 'patient.name.suffix.required', false),
          inputProps: { maxLength: get(validators, 'patient.name.suffix.max', 60) },
          disabled: orderSelected,
        },
      ],
      [
        {
          name: 'patient.identificationNumber',
          label: t('patientID'),
          required: get(validators, 'patient.identification.required', false),
          inputProps: { maxLength: get(validators, 'patient.identification.max', 64) },
          hasOwnChange: true,
          disabled: orderSelected,
        },
        {
          type: 'select',
          name: 'patient.sex',
          label: t('sex'),
          items: sexes,
          required: get(validators, 'patient.sex.required', false),
          inputProps: { maxLength: get(validators, 'patient.identification.max', 1) },
          disabled: orderSelected,
        },
        {
          type: 'datePicker',
          name: 'patient.dateBirth',
          label: t('birthDate'),
          required: get(validators, 'patient.dateBirth.required', false),
          disabled: orderSelected,
        },
      ],
    ],
  };
  if (isStudyEditType || isImport) {
    fields = {
      ...fields,
      study: compact([
        [
          {
            name: `${isRequstDistribute || isRequestEdit ? '' : 'study.'}studyDescription`,
            label: t('studyDescription'),
            grid: { xs: 12, md: 6, lg: 6 },
            required: get(validators, 'study.description.required', false),
            inputProps: { maxLength: get(validators, 'study.description.max', 64) },
            disabled: fieldDisabledInImportWithOrder || fieldDisabledInImportWithStudySelected,
          },
          {
            type: 'datePicker',
            name: `${isRequestEdit ? '' : 'study.'}studyDate`,
            label: t('studyDate'),
            required: get(validators, 'study.studyDate.required', false),
            disabled: fieldDisabledInImportWithOrder || fieldDisabledInImportWithStudySelected,
          },
          {
            type: 'timePicker',
            name: `${isRequestEdit ? '' : 'study.'}studyTime`,
            label: t('studyTime'),
            placeholder: 'hh:mm:ss',
            required: get(validators, 'study.studyTime.required', false),
            disabled: fieldDisabledInImportWithOrder || fieldDisabledInImportWithStudySelected,
          },
          {
            name: `${isRequstDistribute || isRequestEdit ? '' : 'study.'}accessionNumber`,
            label: t('accessionNumber'),
            required: get(validators, 'study.accessionNumber.required', false),
            inputProps: { maxLength: get(validators, 'study.accessionNumber.max', 64) },
            disabled: orderSelected || studySelected,
          },
          {
            type: 'number',
            name: `${isRequstDistribute || isRequestEdit ? '' : 'study.'}patientDispositions.size`,
            label: t('sizeLabel'),
            required: get(validators, 'study.patientDispositions.size.required', false),
            disabled: fieldDisabledInImportWithOrder || fieldDisabledInImportWithStudySelected,
            inputProps: {
              maxLength: get(validators, 'study.patientDispositions.size.max', 16),
              min: '0',
              step: 'any',
              autoComplete: 'off',
              placeholder: t('sizePlaceHolder'),
            },
            tooltip: t('sizeTooltip'),
          },
          {
            type: 'number',
            name: `${isRequestEdit ? '' : 'study.'}patientDispositions.weight`,
            label: t('weightLabel'),
            required: get(validators, 'study.patientDispositions.weight.required', false),
            disabled: fieldDisabledInImportWithOrder || fieldDisabledInImportWithStudySelected,
            inputProps: {
              maxLength: get(validators, 'study.patientDispositions.weight.max', 16),
              min: '0',
              step: 'any',
              autoComplete: 'off',
              placeholder: t('weightPlaceHolder'),
            },
            tooltip: t('weightTooltip'),
          },
        ],
        get(portalSetting, 'seeRequestingPhysician', null) && !isDicomOrder
          ? setPhysician('requestingPhysician', validators)
          : null,
        get(portalSetting, 'seeReferringPhysician', null) && !isDicomOrder
          ? setPhysician('referringPhysician', validators)
          : null,
        get(portalSetting, 'seePerformingPhysician', null) && isImport && !isDicomOrder
          ? setPhysician('performingPhysician', validators)
          : null,
        compact([
          isImport
            ? {
                type: multipleModality ? 'multipleAutocomplete' : 'autocomplete',
                name: 'modality',
                label: t('modality'),
                grid: { xs: 12, md: 12, lg: 12 },
                options: modalities,
                required: true,
                disabled: fieldDisabledInImportWithOrder || fieldDisabledInImportWithStudySelected,
              }
            : null,
        ]),
        compact([
          withStudyUID
            ? {
                name: 'study.studyInstanceUid',
                label: t('studyUID'),
                grid: { xs: 12, md: 6, lg: 4 },
                required: true,
                disabled: true,
              }
            : null,
        ]),
      ]),
    };
  }

  return { studyAndPatientEditSchema, fields };
};

export default usePatientAndStudySchema;
