import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { IModality } from './_types';

export const getAllModalities = (): Promise<IModality[]> =>
  api()
    .get(`/portal-api/modality/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getModality = (id: string): Promise<IModality> =>
  api()
    .get(`/portal-api/modality/get`, { params: { id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editModality = (modality: IModality): Promise<any | boolean> =>
  api()
    .post(`/portal-api/modality/edit`, modality)
    .then((response) => ({ status: true, data: response.data }))
    .catch((error) =>
      get(error, 'response.data') === 'error.administration.modality.exists.with.this.name' ||
      get(error, 'response.data') === 'error.administration.modality.exists.with.this.color'
        ? get(error, 'response.data')
        : false,
    );

export const createModality = (modality: IModality): Promise<any | boolean> =>
  api()
    .post(`/portal-api/modality/create`, modality)
    .then((response) => ({ status: true, data: response.data }))
    .catch((error) =>
      get(error, 'response.data') === 'error.administration.modality.exists.with.this.name' ||
      get(error, 'response.data') === 'error.administration.modality.exists.with.this.color'
        ? get(error, 'response.data')
        : false,
    );

export const removeModality = (id: number): Promise<boolean> =>
  api()
    .get(`/portal-api/modality/remove`, { params: { id } })
    .then((response) => true)
    .catch((error) => false);
