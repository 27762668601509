import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';

const useValidationSchema = () => {
  const { required } = useValidators();

  const FolderFormSchema = yup.object().shape({
    name: required(yup.string()),
  });

  const UserFormSchema = yup.object().shape({
    // username: min(yup.array(), 1, 'required'),
    permission: required(yup.string()),
  });

  return { FolderFormSchema, UserFormSchema };
};

export default useValidationSchema;
