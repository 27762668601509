import React from 'react';
import { Switch, FormControlLabel } from '@mui/material';
import { useController } from 'react-hook-form';

const SwitchForm = ({ control, name, label, disabled, defaultValue = false }: any) => {
  const {
    field: { ref, value, onChange, ...inputProps },
    // meta: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
    defaultValue,
  });
  return (
    <FormControlLabel
      control={
        <Switch
          {...inputProps}
          checked={!!value}
          onChange={(event) => onChange(event.target.checked)}
          name={name}
          inputRef={ref}
          color="primary"
        />
      }
      disabled={disabled}
      label={label}
    />
  );
};

export default SwitchForm;
