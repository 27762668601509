import { api } from 'utils/hooks/useApi';
import { get } from 'lodash';

export const generate = (bitLength: number): Promise<boolean> =>
  api()
    .get(`/portal-api/rsa/generate`, { params: { bitLength } })
    .then((response) => true)
    .catch((error) => false);

export const download = (): any =>
  api()
    .get(`/portal-api/rsa/download`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const encrypt = (text: string): Promise<string | boolean> =>
  api()
    .get(`/portal-api/rsa/encrypt`, { params: { text } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const decrypt = (hash: string): Promise<string | boolean> =>
  api()
    .get(`/portal-api/rsa/decrypt`, { params: { hash } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);
