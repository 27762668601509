import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get, isObject } from 'lodash';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Tooltip } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { getIndexForUser } from './_apiNotificationSettings';
import NotificationSettingForm from './NotificationSettingForm';
import Header from 'components/Header/Header';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'notificationSettingsMUI';

const NotificationSettings: React.FC = () => {
  const { t } = useTranslation('NotificationTemplates');
  useWithTitle();
  const { toggleLoader } = useAppGlobals();
  const [dialogState, setDialogState] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [rows, setRows] = useState<any[]>([]);

  const loadEntity = async () => {
    toggleLoader();
    try {
      setId(0);
      const response = await getIndexForUser();
      if (response) {
        setRows(response);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  const toggleDialog = (state: boolean = false) => {
    setDialogState(state);
  };

  const onEntityEdit = async (id: number) => {
    setId(id);
    toggleDialog(id > 0 ? true : false);
  };

  useEffect(() => {
    loadEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pomid = id ? id : dialogState ? 'create' : null;
  const renderedDialog = (
    <OverflowedDialog
      open={dialogState}
      onClose={() => {
        toggleDialog(false);
        setId(0);
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <NotificationSettingForm toggleDialog={toggleDialog} id={pomid} loadEntity={loadEntity} />
    </OverflowedDialog>
  );

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 90,
        renderCell: ({ row }: GridRenderCellParams) => (
          <GridActionsCellItem
            icon={
              <Tooltip title={t('Grid:edit')}>
                <EditIcon />
              </Tooltip>
            }
            label={t('Grid:edit')}
            onClick={() => onEntityEdit(get(row, 'eventType.id'))}
          />
        ),
      },
      {
        field: 'languageEventType',
        headerName: t('eventType'),
        valueGetter: (value: any, row: any) => {
          const key = get(row, 'eventType.languageKey', '').toLowerCase();
          const tkey = t(key, { returnObjects: true });
          return isObject(tkey) ? t(`${key}.default`) : t(key);
        },
      },
      {
        field: 'reactionTypeName',
        headerName: t('reactionType'),
        valueGetter: (value: any, row: any) => t(get(row, 'reactionType.name', '').toLowerCase()),
      },
      {
        field: 'eventType.languageKey',
        headerName: t('eventType'),
        valueGetter: (value: any, row: any) => get(row, 'eventType.languageKey', ''),
      },
    ]),
  );

  return (
    <>
      <Header title="" />
      <MuiGrid
        gridKey={muiGridKey}
        rows={rows}
        columns={columns}
        initialSortMode={[{ field: 'languageEventType', sort: 'asc' }]}
      />
      {renderedDialog}
    </>
  );
};

export default NotificationSettings;
