import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Typography } from '@mui/material';

import { IHeader } from './_types';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import Button from 'components/Buttons/Button';

const Header: React.FC<IHeader> = ({
  title,
  gutterBottom = true,
  addUrl,
  backUrl,
  isLocked,
  button,
  topMargin = false,
  toggleDialog,
  toggleDialogText,
  addItemInDialog,
  toggleDialogIcon,
  addObject,
  loading,
  loadingDescription,
  TourComponent,
}) => {
  const { t } = useTranslation('Header');
  const { compactMode } = useAppInfo();

  return (
    <Typography
      variant="h6"
      gutterBottom={gutterBottom}
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: 0,
        mb: compactMode ? 0 : 0.5,
        '@media print': {
          display: 'none',
        },
      }}
    >
      {isLocked && <LockIcon sx={{ mr: 1 }} />}
      {title}
      {addUrl && (
        <Button
          variant="contained"
          color="primary"
          component={Link}
          {...({ to: addUrl } as any)}
          data-tour="addButton"
          sx={{ ml: title ? 1 : 0, mb: 0.5 }}
        >
          <AddIcon sx={{ mr: 1 }} fontSize={compactMode ? 'small' : 'medium'} />
          {t('add')}
        </Button>
      )}
      {toggleDialog && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => toggleDialog(true)}
          sx={{ ml: 1, mb: 0.5 }}
        >
          {toggleDialogIcon === 'delete' ? (
            <DeleteIcon sx={{ mr: 1 }} fontSize={compactMode ? 'small' : 'medium'} />
          ) : (
            <AddIcon sx={{ mr: 1 }} fontSize={compactMode ? 'small' : 'medium'} />
          )}
          {toggleDialogText ? toggleDialogText : t('addWorkplacesFromLdap')}
        </Button>
      )}
      {addItemInDialog && (
        <Button
          variant="contained"
          color="primary"
          onClick={addItemInDialog}
          sx={{ ml: title ? 1 : 0, mb: 0.5 }}
        >
          <AddIcon sx={{ ml: 1, mr: 1 }} fontSize={compactMode ? 'small' : 'medium'} />
          {toggleDialogText ? toggleDialogText : t('addWorkplacesFromLdap')}
        </Button>
      )}
      {addObject}
      {backUrl && (
        <Button
          variant="contained"
          color="inherit"
          component={Link}
          {...({ to: backUrl } as any)}
          sx={{ ml: 1, mb: 0.5 }}
          data-tour="backButton"
        >
          {t('back')}
        </Button>
      )}
      {button ? button : null}
      {loading && (
        <Typography
          component="span"
          sx={{
            ml: 2,
            fontSize: 'normal',
            fontWeight: 'normal',
          }}
        >
          {loadingDescription}
          <CircularProgress size={16} style={{ marginLeft: '8px' }} />
        </Typography>
      )}
      {TourComponent}
    </Typography>
  );
};

export default Header;
