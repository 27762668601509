import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { filter, get, isEmpty } from 'lodash';
import { Box, Tooltip } from '@mui/material';
import {
  Edit as EditIcon,
  Description as GridDetailIcon,
  Delete as DeleteIcon,
  AttachFile as AttachIcon,
} from '@mui/icons-material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { removeOrder } from './_api';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { settingHasValue } from 'utils/products';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { encryptId } from 'utils/hooks/useApp';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { useUser } from 'utils/hooks/useUser';
import { format } from 'date-fns';
import styles from 'components/SearchResultsMUI/_styles';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'orderSearchResultsMui';

const OrderSearchResults: React.FC<any> = ({ rows, setRows, dataTour }) => {
  const { t } = useTranslation('Orders');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { hasRole } = useUser();
  const { onEntityRemove } = useEntityRemove(removeOrder, t);

  const { confirmationData, portalProductSettings } = useAppInfo();
  const showRequestingPhysician = !isEmpty(portalProductSettings)
    ? settingHasValue(portalProductSettings, 'seeRequestingPhysician', 'true')
    : false;

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const onCustomEntityDetail = (row: any) =>
    navigate(
      `/orders/${encryptId(get(row, 'patient.identificationNumber', ''))}/${encryptId(
        get(row, 'accessionNumber', ''),
      )}/detail`,
      { state: { linkBack: get(row, 'operationType') } },
    );

  const onCustomEntityUpdate = (row: any) =>
    navigate(
      `/orders/${encryptId(get(row, 'patient.identificationNumber', ''))}/${encryptId(
        get(row, 'accessionNumber', ''),
      )}/edit`,
      { state: { linkBack: get(row, 'operationType') } },
    );

  const onRemove = async (id: number) => await onEntityRemove(id);
  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };
  const confirmAction = async () => {
    const resp = await onRemove(get(confirmationData, 'id'));
    clearAction();
    if (resp) {
      setRows(
        filter(rows, (item: any) => item.id !== get(confirmationData, 'id')).map((item) => ({
          ...item,
          uniqId: `${item.accessionNumber}_${get(item, 'patient.identificationNumber')}`,
          canUpdate: hasRole('ROLE_MWL_UPDATE'),
        })),
      );
    }
  };

  const getName = (row: any) =>
    `${get(row, 'patient.name.lastName', '') || ''} ${
      get(row, 'patient.name.firstName', '') || ''
    }`;

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 160,
        headerAlign: 'left',
        align: 'left',
        renderCell: ({ row }: GridRenderCellParams) => (
          <>
            <GridActionsCellItem
              icon={
                <Tooltip title={t('Grid:orderDetail')}>
                  <GridDetailIcon />
                </Tooltip>
              }
              label={t('Grid:orderDetail')}
              onClick={() => onCustomEntityDetail(row)}
            />
            <GridActionsCellItem
              icon={
                <Tooltip title={t('Grid:orderEdit')}>
                  <EditIcon />
                </Tooltip>
              }
              label={t('Grid:orderEdit')}
              onClick={() => onCustomEntityUpdate(row)}
            />
            {get(row, 'state', null) === 'NEW' && (
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:orderDelete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('Grid:orderDelete')}
                onClick={() => dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }))}
              />
            )}
            {!isEmpty(get(row, 'attachments', [])) && (
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:mwlHasAttachments')} style={{ cursor: 'help' }}>
                    <AttachIcon />
                  </Tooltip>
                }
                label={t('Grid:mwlHasAttachments')}
              />
            )}
          </>
        ),
      },
      {
        field: 'originalDatetimeRequested',
        headerName: t('datetime'),
        renderCell: ({ row }: GridRenderCellParams) =>
          get(row, 'originalDatetimeRequested', '')
            ? format(new Date(get(row, 'originalDatetimeRequested', '')), 'dd. MM. yyyy HH:mm')
            : '',
      },
      {
        field: 'name',
        headerName: t('name'),
        hideable: false,
        renderCell: ({ row }: GridRenderCellParams) => (
          <Tooltip title={getName(row)}>
            <Box onClick={() => onCustomEntityDetail(row)} sx={styles.gridLink}>
              {getName(row)}
            </Box>
          </Tooltip>
        ),
        valueGetter: (value: any, row: any) => getName(row),
      },
      {
        field: 'patientId',
        headerName: t('identificationNumber'),
        valueGetter: (value: any, row: any) => get(row, 'patient.identificationNumber', ''),
      },
      { field: 'accessionNumber', headerName: t('accessionNumber') },
      {
        field: 'unit',
        headerName: t('unit'),
        valueGetter: (value: any, row: any) => get(row, 'unit.name', ''),
      },
      {
        field: 'station',
        headerName: t('station'),
        valueGetter: (value: any, row: any) => get(row, 'station.aetitle', ''),
      },
      {
        field: 'modality',
        headerName: t('modality'),
        valueGetter: (value: any, row: any) => get(row, 'modality.name', ''),
      },
      {
        field: 'state',
        headerName: t('state'),
      },
      ...(showRequestingPhysician
        ? [
            {
              field: 'requestingPhysician',
              headerName: t('requestingPhysician'),
              valueGetter: (value: any, row: any) =>
                `${get(row, 'requestingPhysician.name.lastName', '') || ''} ${
                  get(row, 'requestingPhysician.name.firstName', '') || ''
                }`,
            },
          ]
        : []),
      { field: 'requestedProcedure', headerName: t('requestedProcedure') },
    ]),
  );

  const getRowId = (row: any) => row.uniqId;

  return (
    <>
      <MuiGrid
        gridKey={muiGridKey}
        getRowId={getRowId}
        rows={rows}
        columns={columns}
        dataTour={dataTour || 'orderSearchResultsGrid'}
        initialSortMode={[{ field: 'originalDatetimeRequested', sort: 'desc' }]}
      />
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={t('confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmAction}
        />
      )}
    </>
  );
};

export default OrderSearchResults;
