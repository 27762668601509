import React, { useEffect, useState } from 'react';
import { get, find, isArray, isEmpty, indexOf } from 'lodash';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { IDICOMDocument } from './_types';
import { getStudyAdditionalInfo } from 'modules/Search/_api';
import { getStudiesByStudyIdentity } from 'modules/Tags/_api';
import { IStudyResultForGrid } from 'modules/Search/_types';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { generateIID } from 'utils/study';
import { binaryToDec, encryptId } from 'utils/hooks/useApp';
import { SearchResultsMUI } from 'components/SearchResultsMUI/SearchResultsMUI';
import { LIST, VIEWER } from 'constants/constants';

export const DICOMDocument: React.FC<IDICOMDocument> = ({ document }) => {
  const { archivesForFunctions, gridSettings } = useAppInfo();

  const [rows, setRows] = useState<IStudyResultForGrid[]>([]);
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);
  const columnVisibilityModel = get(gridSettings, 'studyMUI.columnVisibilityModel', {});
  const columnVisibilityModelJsonString = JSON.stringify(columnVisibilityModel);

  const loadAdditionalInfo = async (
    studiesToSave: any,
    studyAdditionalInfo: any,
    extendedInfoFlag: number,
  ) => {
    let studies = null;
    await getStudyAdditionalInfo({
      extendedInfoFlag: binaryToDec(extendedInfoFlag),
      studies: studyAdditionalInfo,
    }).then((resp) => {
      if (isArray(resp)) {
        const studiesWithAdditionalInfo = studiesToSave.map((item: any) => {
          const study = find(resp, (std) => {
            return (
              get(item, 'studyInstanceUid', 1) === get(std, 'studyInstanceUid', 2) &&
              get(item, 'archive.id', 1) === get(std, 'archive.id', 2)
            );
          });
          return {
            ...item,
            studyExtendedInfo: { ...study, ...get(item, 'studyExtendedInfo', {}) },
          };
        });
        setRows([...studiesWithAdditionalInfo]);
        studies = [...studiesWithAdditionalInfo];
      }
    });
    return studies;
  };

  const fetchStudy = async (searchData: any) => {
    getStudiesByStudyIdentity(searchData).then((studies: any) => {
      if (studies !== false) {
        const studyAdditionalInfo = studies.map((item: any) => ({
          archive: { id: get(item, 'archive.id', null) },
          studyInstanceUid: get(item, 'studyInstanceUid', null),
        }));
        const studiesToSave = studies.map((result: any) => {
          const iid = generateIID(result);
          return {
            ...result,
            iid,
            hashIID: encryptId(iid),
          };
        });
        setRows([...studiesToSave]);
        let extendedInfoFlag = 0;

        const gridSettingsHiddenColumnNames = get(gridSettings, 'study.defaultHiddenColumnNames');
        if (isArray(gridSettingsHiddenColumnNames) && !isEmpty(gridSettingsHiddenColumnNames)) {
          const sendInfos = indexOf(gridSettingsHiddenColumnNames, 'sendInfos');
          const folderInfos = indexOf(gridSettingsHiddenColumnNames, 'folderInfos');
          const hasAttachment = indexOf(gridSettingsHiddenColumnNames, 'hasAttachment');
          const tags = indexOf(gridSettingsHiddenColumnNames, 'tags');
          const schreddings = indexOf(gridSettingsHiddenColumnNames, 'shredding');
          if (sendInfos === -1) {
            extendedInfoFlag = extendedInfoFlag + 1;
          }
          if (folderInfos === -1) {
            extendedInfoFlag = extendedInfoFlag + 10;
          }
          if (hasAttachment === -1) {
            extendedInfoFlag = extendedInfoFlag + 100;
          }
          if (tags === -1) {
            extendedInfoFlag = extendedInfoFlag + 10000;
          }
          if (schreddings === -1) {
            extendedInfoFlag = extendedInfoFlag + 1000000;
          }
        }
        loadAdditionalInfo(studiesToSave, studyAdditionalInfo, extendedInfoFlag).then((resp) => {
          const aet = indexOf(gridSettingsHiddenColumnNames, 'stationAet') === -1;
          if (aet) {
            loadAdditionalInfo(resp, studyAdditionalInfo, 1000);
          }
        });
      } else {
        setRows([]);
      }
    });
  };

  const loadDocument = async () => {
    const archives = get(archivesForFunctions, 'SEARCH', []);
    const docObject = JSON.parse(document);
    const searchData = {
      studyIdentities: archives.map((ar: any) => {
        return {
          archive: { id: ar.id },
          studyInstanceUid: get(docObject, 'studyUid', ''),
        };
      }),
    };
    fetchStudy(searchData);
  };

  useEffect(() => {
    loadDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnVisibilityModelJsonString]);

  return (
    <>
      {!isEmpty(rows) && (
        <SearchResultsMUI
          rows={rows}
          setRows={setRows}
          actions={[VIEWER]}
          action={LIST}
          selecting={false}
          selection={selection}
          setSelection={setSelection}
          sx={{ p: 0 }}
        />
      )}
    </>
  );
};
