import { IconButton, Tooltip } from '@mui/material';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import ShowPreviewIcon from '@mui/icons-material/ViewCarousel';
import HidePreviewIcon from '@mui/icons-material/ViewCarouselOutlined';
import { useSettings } from 'utils/hooks/useSettings';

export const PreviewButton: React.FC = () => {
  const { t } = useTranslation('Studies');
  const { gridSettings } = useAppInfo();
  const hidePreview = get(gridSettings, 'preview.hidePreview', false);
  const { setGridSettings } = useSettings();

  const togglePreview = () => {
    const togglePreview = get(gridSettings, 'preview.hidePreview', false);
    const gridSettingsForAPI = { ...gridSettings.preview, hidePreview: !togglePreview };
    const gridSettingsForRedux = { ...gridSettings, preview: gridSettingsForAPI };

    setGridSettings({ key: 'preview', gridSettingsForAPI, gridSettingsForRedux });
  };

  return (
    <Tooltip title={t(hidePreview ? 'showPreview' : 'hidePreview') as string}>
      <IconButton size="small" sx={{ ml: 1 }} onClick={togglePreview}>
        {hidePreview ? <HidePreviewIcon /> : <ShowPreviewIcon />}
      </IconButton>
    </Tooltip>
  );
};
