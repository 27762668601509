import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Grid, Typography } from '@mui/material';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { stateIsSame } from 'utils/componentOptimizatons';
import { IStudyDetailReport } from './_types';
import { getReport } from './_api';
import { Box } from '@mui/system';

const PlainStudyDetailReport: React.FC<IStudyDetailReport> = ({ study }) => {
  const { t } = useTranslation('Studies');
  const { toggleLoader } = useAppGlobals();
  const [report, setReport] = useState<any>(null);

  const loadEntity = async () => {
    toggleLoader();
    const report = await getReport(
      parseInt(get(study, 'archive.id', '') as unknown as string, 10),
      get(study, 'studyInstanceUid'),
    );
    if (report) {
      setReport(report);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isEmpty(report) ? (
        <Typography component="div">
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <Box component="label" sx={{ fontWeight: 'bold' }}>
                {t('author')}
              </Box>
              <div>{get(report, 'author', '')}</div>
            </Grid>
            <Grid item={true} xs={12}>
              <Box component="label" sx={{ fontWeight: 'bold' }}>
                {t('date')}
              </Box>
              <div>
                {get(report, 'date', false)
                  ? format(new Date(report.date), 'dd.MM.yyyy, HH:mm:ss')
                  : ' '}
              </div>
            </Grid>
            <Grid item={true} xs={12}>
              <Box component="label" sx={{ fontWeight: 'bold' }}>
                {t('text')}
              </Box>
              <div>{get(report, 'report', ' ')}</div>
            </Grid>
          </Grid>
        </Typography>
      ) : (
        <Typography component="div">{t('reportForStudyNotFound')}</Typography>
      )}
    </>
  );
};

export const StudyDetailReport = React.memo(PlainStudyDetailReport, stateIsSame);
