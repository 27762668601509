import React from 'react';
import { get } from 'lodash';
import { shallowEqual } from 'react-redux';
import { IconButton } from '@mui/material';
import { SnackbarProvider, useSnackbar } from 'notistack';

import { IAlert } from './_types';
import CloseIcon from '@mui/icons-material/Close';
import { DEFAULT_HIDE_DURATION } from 'constants/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { alertRemove } from 'store/reducers/appReducer';

const Alerts: React.FC = () => {
  const dispatch = useAppDispatch();
  const alerts: IAlert[] = useAppSelector((state) => state.app.alerts, shallowEqual);

  const removeFromRedux = (id: string) => dispatch(alertRemove(id));

  const RenderAlerts = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    alerts.forEach((alert) => {
      const key = get(alert, 'id');
      const mesage = get(alert, 'text', '?');
      const variant = get(alert, 'type');
      const autoHideDuration = get(alert, 'hideDuration', DEFAULT_HIDE_DURATION);
      enqueueSnackbar(mesage, {
        key,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        variant,
        autoHideDuration,
        action: () => (
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => closeSnackbar(key)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        ),
        // onExiting: () => removeFromRedux(key),
      });
      removeFromRedux(key);
    });

    return <></>;
  };

  return (
    <div>
      {alerts && (
        <SnackbarProvider maxSnack={10} hideIconVariant data-selenium-selector="notification">
          <RenderAlerts />
        </SnackbarProvider>
      )}
    </div>
  );
};

export default React.memo(Alerts);
