import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useReactTour } from 'components/Tour/useReactTour';
import HelperComponent from 'components/Tour/HelperComponent';

export const TourCstore = ({ activeTab }: any) => {
  const { t } = useTranslation('Reactour');
  const { mdexDicomPatient } = useAppInfo();
  let cstoreStepsDefinition: any;
  const initialStepsDicom = {
    '[data-tour="cstore-dicom-uppySelector"]': t('importDicom.uppySelector'),
  };

  const extendedStepsDicom = {
    '[data-tour="cstore-dicom-grid"]': t('importDicom.grid'),
    '[data-tour="import-dicom-expansionArrow"]': t('importDicom.expansionArrow'),
    '[data-tour="import-dicom-deleteRow"]': t('importDicom.deleteRow'),
    '[data-tour="cstore-dicom-deleteAll"]': t('importDicom.deleteAll'),
    '[data-tour="cstore-dicom-uploadFolder"]': t('importDicom.uploadFolder'),
    '[data-tour="cstore-dicom-uploadFiles"]': t('importDicom.uploadFiles'),
  };

  const [stepsForDicom, setStepsForDicom] = useState<any>(
    mdexDicomPatient?.length ? extendedStepsDicom : initialStepsDicom,
  );

  useEffect(() => {
    setStepsForDicom(mdexDicomPatient?.length ? extendedStepsDicom : initialStepsDicom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mdexDicomPatient]);

  const allStepsForSave = ({ isDropDownOpen, currentOpenDropdown }: any) => {
    const steps = {
      '[data-tour="cstore-navBar"]': t('cstore.save.navBar'),
      ...stepsForDicom,
      '[data-tour="cstore-archive-id"]': t('cstore.save.archiveId'),
      ...(isDropDownOpen &&
        currentOpenDropdown === 'archiveId' && {
          '.select-react-tour-class': t('cstore.save.archiveId'),
        }),
      '[data-tour="cstore-target-aet"]': t('cstore.save.targetAet'),
      '[data-tour="cstore-target-ip"]': t('cstore.save.targetIp'),
      '[data-tour="cstore-target-port"]': t('cstore.save.targetPort'),
      '[data-tour="cstore-source-aet"]': t('cstore.save.sourceAet'),
      '[data-tour="cstore-save-clear"]': t('cstore.save.clear'),
      '[data-tour="cstore-save-echo"]': t('cstore.save.echo'),
      '[data-tour="cstore-save-button"]': t('cstore.save.button'),
    };
    return steps;
  };

  const allStepsForSend = ({ isDropDownOpen, currentOpenDropdown }: any) => {
    const steps = {
      '[data-tour="cstore-navBar"]': t('cstore.send.navBar'),
      ...stepsForDicom,
      '[data-tour="cstore-recipients"]': t('cstore.send.recipients'),
      ...(isDropDownOpen &&
        currentOpenDropdown === 'networks.fac' && {
          '.autocomplete-react-tour-class': t('cstore.send.recipients'),
        }),
      '[data-tour="cstore-networks"]': t('cstore.send.networks'),
      ...(isDropDownOpen &&
        currentOpenDropdown === 'networks.type' && {
          '.select-react-tour-class': t('cstore.send.networks'),
        }),
      '[data-tour="cstore-send-button"]': t('cstore.send.button'),
    };
    return steps;
  };

  if (activeTab === 'save') cstoreStepsDefinition = allStepsForSave;
  if (activeTab === 'send') cstoreStepsDefinition = allStepsForSend;

  useReactTour({ stepsDefinition: cstoreStepsDefinition });

  return (
    <>
      <HelperComponent />
    </>
  );
};
