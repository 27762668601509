import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { IFilter, IFilterForm } from './_types';

export const getAllFilter = (): Promise<IFilter[]> | boolean =>
  api()
    .get(`/portal-api/searchFilter/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllFilterForUser = (): Promise<IFilter[]> | boolean =>
  api()
    .get(`/portal-api/searchFilter/getAllForUser`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const removeFilter = (id: number): Promise<boolean> =>
  api()
    .get(`/portal-api/searchFilter/delete`, { params: { id } })
    .then((response) => true)
    .catch((error) => false);

export const getFilter = (id: string): Promise<IFilterForm> | boolean =>
  api()
    .get(`/portal-api/searchFilter/get`, { params: { id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editFilter = (searchFilter: IFilter): Promise<boolean> =>
  api()
    .post(`/portal-api/searchFilter/update`, searchFilter)
    .then((response) => true)
    .catch((error) => false);

export const createFilter = (searchFilter: IFilter): Promise<boolean> =>
  api()
    .post(`/portal-api/searchFilter/saveWorkplaceFilter`, searchFilter)
    .then((response) => true)
    .catch((error) => false);

export const setActiveFilter = (id: string, state: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/searchFilter/setActiveState`, { params: { id, state } })
    .then((response) => true)
    .catch((error) => false);

export const saveUserFilter = (data: IFilter): Promise<any | boolean> =>
  api().post(`/portal-api/searchFilter/saveUserFilter`, data);
