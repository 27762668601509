import { useStorage } from './useStorage';
import { useAppDispatch } from 'store/hooks';
import { validatorsLoad } from 'store/reducers/appReducer';

export const useValidators = () => {
  const dispatch = useAppDispatch();
  const { getItem } = useStorage();

  let storageValidators;
  try {
    storageValidators = JSON.parse(getItem('validators') as string);
  } catch (e) {
    storageValidators = {};
  }

  const loadValidatorsToStore = (validators: any) => dispatch(validatorsLoad(validators));

  return { storageValidators, loadValidatorsToStore };
};
