import React, { useEffect, useState } from 'react';
import { filter, get, isArray, pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import { createGeneralQuestion, editGeneralQuestion } from './_api';
import { IAnswerForm, IGeneralQuestionForm } from './_types';
import useValidationSchema from './_formTestTypeDefinitions';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';

import FormInput from 'components/Form/Input/Input';
import FormSwitch from 'components/Form/Switch/Switch';
import useAlerts from 'components/Alerts/useAlerts';

import { TAB_DEFINITIONS } from 'constants/constants';
import { Box } from '@mui/system';

const emptyAnswer: IAnswerForm = { id: null, text: '', correctness: false, state: '', position: 0 };

const GeneralTestTypeDefinitionsForm: React.FC<any> = ({
  id,
  setActiveTab,
  removeEditingTab,
  loadEntity,
  isEdit = false,
  editedDefinition,
}) => {
  const { t } = useTranslation('Tests');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const [entity, setEntity] = useState<IGeneralQuestionForm>();

  const { TestTypeDefinitionsFormSchema } = useValidationSchema();

  const methods = useForm<IGeneralQuestionForm>({
    resolver: yupResolver(TestTypeDefinitionsFormSchema),
    defaultValues: { ...entity },
  });
  const { handleSubmit, reset, register, control } = methods;
  const { fields, append, remove } = useFieldArray({ name: 'step.answers', control });

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const answers = get(values, 'step.answers', null);

    if (!isArray(answers) || (isArray(answers) && !answers.length)) {
      // No answer added
      addErrorAlert(t('atLeaseOneAnswerMustBeAdded'));
    } else if (isArray(answers) && !filter(answers, 'correctness').length) {
      // No correct answer selected
      addErrorAlert(t('atLeaseOneAnswerMustBeCorrect'));
    } else {
      const request = {
        ...values,
        step: {
          ...values.step,
          answers: answers.map((answer, index) => ({ ...answer, position: index + 1 })),
          ...(isEdit ? { ...pick(editedDefinition, ['id', 'state']) } : {}),
        },
      };

      const fn = isEdit ? editGeneralQuestion : createGeneralQuestion;

      await fn(request).then(
        (response) => {
          addSuccessAlert(t(isEdit ? 'questionEdited' : 'questionAdded'));
          setActiveTab(TAB_DEFINITIONS);
          removeEditingTab();
          loadEntity();
        },
        (error) => addErrorAlert(t('questionSubmitFailed')),
      );
    }
    toggleLoader(false);
  });

  useEffect(() => {
    const isCreating = !isEdit || !editedDefinition;
    const entity: IGeneralQuestionForm = {
      step: isCreating
        ? {
            id: null,
            label: '',
            position: 0,
            answers: [emptyAnswer],
            state: '',
            time: 0,
          }
        : { ...pick(editedDefinition, ['label', 'position', 'answers', 'id', 'state', 'time']) },
      testId: id,
    };
    setEntity(entity);
    reset({ ...entity });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEdit, editedDefinition]);

  const buttonTitle = t(isEdit ? 'editDefinition' : 'addDefinition');

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <input {...register('testId')} type="hidden" />
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={12} md={6} data-tour="testDetailDefinitionsQuestion">
              <FormInput name="step.label" label={t('label')} required={true} />
            </Grid>
            <Grid item={true} xs={12} md={3} lg={2} xl={1} data-tour="testDetailDefinitionsOrder">
              <FormInput name="step.position" label={t('position')} required={true} />
            </Grid>
          </Grid>
          <Grid
            container={true}
            spacing={1}
            sx={{ cursor: 'n-resize' }}
            data-tour="testDetailDefinitionsAnswer"
          >
            <Grid item={true} xs={12}>
              <Box sx={{ mb: 1 }} data-tour="testDetailDefinitionsAddAnswer">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    append(emptyAnswer);
                  }}
                >
                  {t('addAnswer')}
                </Button>
              </Box>
            </Grid>
            {fields.map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  <Grid item={true} xs={2}>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', pt: 1 }}
                    >
                      <div>
                        <IconButton
                          onClick={() => remove(index)}
                          disabled={
                            fields.length === 1 || (fields.length > 1 && fields.length > index + 1)
                          }
                          size="large"
                        >
                          <CancelIcon fontSize="small" />
                        </IconButton>
                      </div>
                      <Box component="label" sx={{ fontWeight: 'bold', cursor: 'inherit' }}>
                        {t('answer')} #{index + 1}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item={true} xs={5}>
                    <FormInput
                      name={`step.answers[${index}].text`}
                      label={''}
                      defaultValue={`${item.text}`}
                      required={true}
                    />
                  </Grid>
                  <Grid item={true} xs={2} key={item.id} style={{ paddingTop: '12px' }}>
                    <FormSwitch
                      name={`step.answers[${index}].correctness`}
                      label={t('correctness')}
                      defaultValue={item.correctness}
                    />
                  </Grid>
                  <Grid item={true} xs={1} sx={{ display: 'none' }}>
                    <FormInput
                      name={`step.answers[${index}].id`}
                      label={''}
                      type="number"
                      defaultValue={item.id}
                    />
                  </Grid>
                  <Grid item={true} xs={1} sx={{ display: 'none' }}>
                    <FormInput
                      name={`step.answers[${index}].state`}
                      label={''}
                      defaultValue={`${item.state}`}
                    />
                  </Grid>
                  <Grid item={true} xs={12} style={{ padding: 0 }} />
                </React.Fragment>
              );
            })}
          </Grid>

          <Grid container={true} spacing={1} justifyContent="flex-end">
            <Grid item={true}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                data-tour="testDetailDefinitionsAddQuestion"
              >
                {buttonTitle}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default GeneralTestTypeDefinitionsForm;
