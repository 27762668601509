import { useState } from 'react';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { isFunction } from 'lodash';

import useRefetchGrid from 'utils/grid/useRefetchGrid';
import { TFunction } from 'i18next';
import useAlerts from 'components/Alerts/useAlerts';

export const useEntityRemove = (
  removeFn: (x: any, y?: string) => object,
  t: TFunction,
  loadEntity?: (x?: string | any, y?: string) => object,
  successAlertCode: string = 'deleted',
  errorAlertCode: string = 'errorDeleting',
  activeTab: string = '',
) => {
  const { toggleLoader } = useAppGlobals();
  const [lastRemoved, setLastRemoved] = useState<any>(null);
  const { refetchGrid } = useRefetchGrid();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const onEntityRemove = async (id: any) => {
    toggleLoader();
    const removed = await (activeTab ? removeFn(id, activeTab) : removeFn(id));
    if (removed) {
      if (isFunction(loadEntity)) {
        await loadEntity();
      } else {
        refetchGrid();
      }
      addSuccessAlert(t(successAlertCode));
      setLastRemoved(new Date());
    } else {
      addErrorAlert(t(errorAlertCode));
    }
    toggleLoader(false);
    return removed ? true : false;
  };

  return { lastRemoved, onEntityRemove };
};
