import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { get, isArray } from 'lodash';
import { Button, Dialog, DialogActions, DialogContent, Grid, Paper } from '@mui/material';

import { INotificationTemplateForm, INotificationSettingsDialog } from './_types';
import { getDataForEditUserNotificationTemplate, saveUserNotificationTemplate } from './_api';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import SaveIcon from '@mui/icons-material/Save';
import FormSelect from 'components/Form/Select/Select';
import { ISelectItem } from 'components/Form/Select/_types';
import useValidationSchema from './_form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GridDataType } from 'constants/constants';
import Header from 'components/Header/Header';
import useAlerts from 'components/Alerts/useAlerts';
import { Box } from '@mui/system';

const NotificationSettingsDialog: React.FC<INotificationSettingsDialog> = ({
  notificationItem,
  closeDialog,
  getNotifications,
}) => {
  const { t } = useTranslation('NotificationTemplates');

  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [notificationData, fetchNotificationData] = useState<any>({});

  const { NotificationTemplateFormSchema, formItems } = useValidationSchema(t);

  const methods = useForm<INotificationTemplateForm>({
    resolver: yupResolver(NotificationTemplateFormSchema),
  });
  const { handleSubmit, reset } = methods;

  const { toggleLoader } = useAppGlobals();

  const onSubmit = handleSubmit(async (values: any) => {
    toggleLoader();
    await saveUserNotificationTemplate(values).then(
      () => {
        addSuccessAlert(t('saved'));
        closeDialog();
      },
      () => addErrorAlert(t('error.errorSaving')),
    );

    await getNotifications();
    toggleLoader(false);
  });

  const getEntity = async () => {
    toggleLoader();
    const eventTypeId = get(notificationItem, 'eventType.id');
    if (eventTypeId) {
      const dataForNotifications = await getDataForEditUserNotificationTemplate(eventTypeId);

      const data = {
        ...dataForNotifications,
        reactionTypes: isArray(dataForNotifications.notificationTemplates)
          ? dataForNotifications.notificationTemplates.map((template: any) => ({
              ...template.reactionType,
              label: t(template.reactionType.languageKey),
              id: template.id,
            }))
          : [],
      };
      fetchNotificationData(data);
      reset({ notificationTemplateId: '' });
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultItems = {
    notificationTemplateId: get(notificationData, 'reactionTypes', []),
  };

  const defaultGrid: GridDataType = { xs: 12, md: 6, lg: 4, xl: 3 };

  return (
    <Dialog
      open={true}
      onClose={closeDialog}
      aria-labelledby="notification-settings-dialog-title"
      maxWidth="md"
      fullWidth={true}
    >
      {notificationData && (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <Box sx={{ mr: 2, mb: 2 }}>
              <DialogContent>
                <Header
                  title={`${t('edit')} ${t(get(notificationData, 'eventType.languageKey'))}`}
                />
                <Paper elevation={0}>
                  <Grid container={true} alignItems="flex-end">
                    {formItems.map((formItem, index) => {
                      const customGrid = get(formItem, 'grid', false)
                        ? (get(formItem, 'grid') as unknown as GridDataType)
                        : defaultGrid;

                      const type = get(formItem, 'type', 'text');
                      const name = get(formItem, 'name', '');
                      const items: ISelectItem[] = get(defaultItems, name, []);
                      const isRequired = get(formItem, 'required', false);
                      const isDisabled = get(formItem, 'disabled', false);
                      const label = get(formItem, 'label', '');

                      return (
                        <Grid key={`formItem-${index}`} item={true} {...customGrid}>
                          {type === 'select' && (
                            <FormSelect
                              name={name}
                              label={label}
                              required={isRequired}
                              items={items}
                              disabled={isDisabled}
                            />
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Paper>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDialog} variant="contained" size="large" sx={{ mr: 1 }}>
                  {t('close')}
                </Button>
                <Button variant="contained" color="primary" type="submit" size="large">
                  <SaveIcon sx={{ mr: 1 }} />
                  {t('confirm')}
                </Button>
              </DialogActions>
            </Box>
          </form>
        </FormProvider>
      )}
    </Dialog>
  );
};

export default NotificationSettingsDialog;
