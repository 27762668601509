const variables = {
  primaryColor: '#388bab',
  primaryColorDark: '#2a6d88',
  blueBackground: '#2f4050',
  fontSize: 12,
  paper: '#fff',
  default: '#f3f3f4',
  darkDefault: '#212121',
  darkPaper: '#383838',
};

const light = {
  typography: {
    fontSize: variables.fontSize,
  },
  palette: {
    mode: 'light',
    // Is overridden by primaryColorFromApi in useTheme.ts
    primary: { main: variables.primaryColor },
    background: {
      paper: variables.paper,
      default: variables.default,
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px',
        },
      },
    },
    // MuiTableRow: {
    //   defaultProps: {
    //     hover: true,
    //   },
    // },
    // MuiTabs: {
    //   styleOverrides: {
    //     root: {
    //       background: variables.paper,
    //     },
    //     tabsIndicator: {
    //       background: variables.blueBackground,
    //     },
    //   },
    // },

    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: variables.default,
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: variables.default,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: variables.default, // replace '#yourColor' with your desired color
          // ... other styles you wish to apply
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: variables.default, // replace '#yourColor' with your desired color
          // ... other styles you wish to apply
        },
      },
    },
    /* MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 2000,
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          zIndex: 2001,
        },
      },
    },*/
  },
  mixins: {
    MuiDataGrid: {
      containerBackground: '#fff',
    },
  },
};

const dark = {
  mixins: {
    MuiDataGrid: {
      containerBackground: variables.darkPaper,
    },
  },
  typography: {
    fontSize: variables.fontSize,
  },
  palette: {
    mode: 'dark',
    primary: { main: variables.primaryColorDark },
    background: {
      paper: variables.darkPaper,
      default: variables.darkDefault,
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px',
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: variables.darkDefault,
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: variables.darkDefault,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: variables.darkDefault, // replace '#yourColor' with your desired color
          // ... other styles you wish to apply
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: variables.darkDefault, // replace '#yourColor' with your desired color
          // ... other styles you wish to apply
        },
      },
    },
    // MuiTableRow: {
    //   defaultProps: {
    //     hover: true,
    //   },
    // },
    // MuiTabs: {
    //   styleOverrides: {
    //     root: {
    //       background: '#383838',
    //     },
    //     tabsIndicator: {
    //       background: variables.blueBackground,
    //     },
    //   },
    // },
    /*MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 2000,
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          zIndex: 2001,
        },
      },
    },*/
  },
};

export const theme = {
  variables,
  light,
  dark,
};
