import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import {
  filter,
  find,
  forEach,
  get,
  includes,
  isArray,
  isEmpty,
  orderBy,
  remove as removeFromLodash,
  size,
  sortBy,
  upperCase,
} from 'lodash';
import { Box, Button, Grid } from '@mui/material';
import { getDefaultAet, cstoreMetadata } from './_api';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useDicomParser } from 'components/Import/useFileParser';
import { createUppy } from 'utils/import';
import { useUser } from 'utils/hooks/useUser';
import useAlerts from 'components/Alerts/useAlerts';
import Import from 'components/Import/Import';
import DicomGrid from 'components/Import/DicomGrid';
import { Papeer } from 'components/Papeer/Papeer';
import { ISeries } from 'components/Import/_types';

import CustomUppyFolderInput from 'modules/Import/CustomUppyFolderInput';
import CustomUppyFileInput from 'modules/Import/CustomUppyFileInput';

import {
  getAll,
  getStudySendAddressBook,
} from 'modules/Administration/FacilitiesAndExchangeNetworks/_apiExchangeNetworks';
import {
  IExchangeNetwork,
  IStudySendAddressBook,
  IStudySendAddressBookNetwork,
} from 'modules/Administration/FacilitiesAndExchangeNetworks/_types';
import { v1 as uuidv1 } from 'uuid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { primaryColor } from 'utils/variables';
import { IAutocompleteOption } from 'components/Form/Autocomplete/_types';
import FormAutocomplete from 'components/Form/Autocomplete/Autocomplete';
import FormSelect from 'components/Form/Select/Select';
import { ISelectItem } from 'components/Form/Select/_types';
import { Announcement } from 'components/Announcement/Announcement';
import { useAppDispatch } from 'store/hooks';
import { setMdexDicomPatientToStore } from 'store/reducers/appReducer';

const mapStudySendAddressBookItem = (studySendAddressBook: IStudySendAddressBook) => {
  const networks = get(studySendAddressBook, 'networks', null);
  let icons = '';
  'REM'.split('').forEach((type) => {
    icons += find(networks, { type }) ? type : '';
  });
  return {
    value: studySendAddressBook.id,
    label: `${studySendAddressBook.institutionName} (${studySendAddressBook.city})`,
    id: get(studySendAddressBook, 'id', null),
    networks,
    icons,
  };
};

export const DicomForm: React.FC = () => {
  const { t } = useTranslation('Studies');
  const { toggleLoader } = useAppGlobals();
  const { hasRole, user } = useUser();
  const { spacing } = useAppInfo();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const dispatch = useAppDispatch();

  const [patients, setPatients] = useState<any[]>([]);
  const { parseDicomFiles } = useDicomParser({ setPatients });
  const [timestamp, setTimestamp] = useState(new Date().getTime().toString());
  const [uppy, setUppy] = useState(() =>
    createUppy('uppyCstore', parseDicomFiles, timestamp, undefined),
  );
  const [selectedSeries, setSelectedSeries] = useState<Map<string, ISeries>>(new Map());
  const [seriesDetailOpened, setSeriesDetailOpened] = useState<{ [id: string]: boolean }>({});

  const [exchangeNetworks, setExchangeNetworks] = useState<IExchangeNetwork[]>([]);
  const [facilitiesSelect, setFacilitiesSelect] = useState<any | IAutocompleteOption[]>([]);
  const [prepareNetworks, setPrepareNetworks] = useState<ISelectItem[]>([]);
  const [aet, setAet] = useState<string>('');

  const methods = useForm<any>({ defaultValues: {} });
  const { handleSubmit, reset, setValue, watch } = methods;

  const networkTypeWatch = watch('networks.type');

  useEffect(() => {
    const files = patients.flatMap((patient) => get(patient, 'files', []));
    let filesIds: string[] = [];
    files.forEach((file: any) => {
      filesIds.push(get(file, 'id'));
    });
    dispatch(setMdexDicomPatientToStore(filesIds));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patients]);

  const removePatient = (patient: any) => {
    const files = get(patient, 'files', []);
    forEach(files, (file) => {
      uppy.removeFile(get(file, 'id'));
    });

    setPatients((prevPatients) => [...prevPatients.filter((pat: any) => pat.id !== patient.id)]);
  };

  const removeAllPatients = () => {
    forEach(patients, (patient) => {
      const files = get(patient, 'files', []);
      forEach(files, (file) => {
        uppy.removeFile(get(file, 'id'));
      });
    });
    setPatients([]);
  };

  const onSubmit = async (values: any) => {
    toggleLoader();
    uppy.upload().then(async () => {
      // Upload complete
      let sourceAet = aet;
      let targetAet = '';
      let targetIp = '';
      let targetPort = 0;
      const networkType = upperCase(get(values, 'networks.type', ''));
      const facNetworks = values.networks.fac.networks || [];
      let selectedNetwork = {};
      facNetworks.forEach((network: any) => {
        if (upperCase(get(network, 'type', '')[0]) === networkType) {
          selectedNetwork = { ...network };
        }
      });
      if (networkType === 'M') {
        // mdex
        targetAet = get(selectedNetwork, 'aetitle', '');
        targetIp = get(selectedNetwork, 'dicomHost', '');
        targetPort = get(selectedNetwork, 'dicomPort', 0);
      } else if (networkType === 'R' || networkType === 'E') {
        // redimed/epacs
        let exchangeNetwork = {};
        exchangeNetworks.forEach((network) => {
          if (upperCase(get(network, 'name', ' ')[0]) === networkType) {
            exchangeNetwork = network;
          }
        });
        targetAet = get(selectedNetwork, 'aetitle', '');
        targetIp = get(exchangeNetwork, 'ip', '');
        targetPort = get(exchangeNetwork, 'port', 0);
      }
      const transformedValues = {
        sourceAet,
        targetIp,
        targetAet,
        targetPort,
        path: `${timestamp}_${user?.sub}`,
        timestamp: timestamp,
        typeOfUpload: 'cstore',
      };
      const resp = await cstoreMetadata(transformedValues);
      toggleLoader(false);
      if (resp) {
        addSuccessAlert(t('Cstore:successfullyCstore'));
        setPatients([]);
        const newTimestamp = new Date().getTime().toString();
        setTimestamp(newTimestamp);
        setUppy(() => createUppy('uppyCstore', parseDicomFiles, timestamp));
        reset({});
      } else {
        addErrorAlert(t('Cstore:errorCstore'));
      }
    });
  };

  const getFacilitiesSelect = (
    studySendAddressBook: Partial<boolean | Promise<IStudySendAddressBook[]>> & any[],
  ) => {
    if (isArray(studySendAddressBook)) {
      const favouriteLength = filter(studySendAddressBook, ['favourite', true]).length;
      const othersLength = filter(studySendAddressBook, ['favourite', false]).length;
      const facilitiesSelect = studySendAddressBook
        .sort((f1, f2) =>
          f1.institutionName
            .replace('(CZ) ', '')
            .replace('(SK) ', '')
            .localeCompare(f2.institutionName.replace('(CZ) ', '').replace('(SK) ', '')),
        )
        .map((item: IStudySendAddressBook) => {
          const isFavourite = get(item, 'favourite', false);
          const id = get(item, 'id');
          return {
            ...mapStudySendAddressBookItem(item),
            sorted: isFavourite
              ? `${t('favourite')} (${favouriteLength})`
              : `${t('others')} (${othersLength})`,
            id,
            favourite: isFavourite,
          };
        });
      setFacilitiesSelect(facilitiesSelect);
      return facilitiesSelect;
    }
  };

  const getPrepareNetworks = (networks: any, t: any) => {
    const prepareNetworks = isArray(networks)
      ? sortBy(networks, ['priority']).map((item: any) => ({
          id: item.type,
          label: t(`${item.type}`),
        }))
      : [];
    setPrepareNetworks(prepareNetworks);
    return prepareNetworks;
  };
  const getExchangeNetworksFromFacility = (data: any, selectedType = '') => {
    const networks = getPrepareNetworks(get(data, 'networks', null), t);
    const defaultType = selectedType ? selectedType : isArray(networks) ? networks[0].id : '';
    setValue(`networks.type`, defaultType);
  };

  const loadEntities = async () => {
    toggleLoader();
    try {
      const [defaultAetResp, studySendAddressBookResponse, exchangeNetworks] = await Promise.all([
        getDefaultAet(),
        getStudySendAddressBook(),
        getAll(),
      ]);

      let aet = '';
      if (defaultAetResp) {
        aet = get(defaultAetResp, 'aet', '');
        setAet(aet);
      }

      let ex: IExchangeNetwork[] = [];
      if (isArray(exchangeNetworks)) {
        const activeExchangeNetworks = orderBy(
          removeFromLodash(exchangeNetworks, (obj) => obj.active === true),
          ['item.name'],
          ['asc'],
        );
        ex = activeExchangeNetworks.map((exchangeNetwork) => {
          const name = get(exchangeNetwork, 'name', '').toLowerCase();
          if (name === 'epacs') {
            return { ...exchangeNetwork, nameType: 'E' };
          } else if (name === 'redimed') {
            return { ...exchangeNetwork, nameType: 'R' };
          } else {
            return { ...exchangeNetwork };
          }
        });
        setExchangeNetworks(ex);
      }

      let studySendAddressBook: IStudySendAddressBook[] = [];
      if (isArray(studySendAddressBookResponse)) {
        const avalaibleType: string[] = [];
        avalaibleType.push('M');
        if (hasRole('ROLE_SEND_EPACS') && find(ex, { nameType: 'E' })) avalaibleType.push('E');
        if (hasRole('ROLE_SEND_REDIMED') && find(ex, { nameType: 'R' })) avalaibleType.push('R');
        studySendAddressBookResponse.forEach((item: IStudySendAddressBook) => {
          const networks: IStudySendAddressBookNetwork[] = [];
          get(item, 'networks', []).forEach((network: IStudySendAddressBookNetwork) => {
            if (includes(avalaibleType, get(network, 'type'))) {
              networks.push(network);
            }
          });
          if (!isEmpty(networks)) {
            let id: any = get(item, 'id', 0);
            if (id === 0) {
              id = uuidv1();
            }
            studySendAddressBook.push({ ...item, networks, id });
          }
        });
        getFacilitiesSelect(studySendAddressBook);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Papeer>
            <Grid container spacing={1}>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Announcement
                  label={t('allowedFilesDicom')}
                  type={'info'}
                  spaced={true}
                  component={'div'}
                />
              </Grid>

              {!patients.length && (
                <Grid item xs={12} data-tour="cstore-dicom-uppySelector">
                  <Import uppy={uppy} />
                </Grid>
              )}
              {!!patients.length && (
                <>
                  <Grid item xs={12} data-tour="cstore-dicom-grid">
                    <DicomGrid
                      patients={patients}
                      removePatient={removePatient}
                      uppy={uppy}
                      enableMasterDetail={true}
                      selectedSeries={selectedSeries}
                      setSelectedSeries={setSelectedSeries}
                      seriesDetailOpened={seriesDetailOpened}
                      setSeriesDetailOpened={setSeriesDetailOpened}
                      hideEditPatient={true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    sx={{ mt: 1, gap: 1 }}
                  >
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={removeAllPatients}
                      data-tour="cstore-dicom-deleteAll"
                    >
                      {t('Import:removeAll')}
                    </Button>
                    <Box data-tour="cstore-dicom-uploadFolder">
                      <CustomUppyFolderInput uppy={uppy} />
                    </Box>
                    <Box data-tour="cstore-dicom-uploadFiles">
                      <CustomUppyFileInput uppy={uppy} />
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Papeer>
          <Papeer sx={{ mt: 2 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container={true} spacing={spacing}>
                  <Grid item={true} xs={12} md={6} data-tour="cstore-recipients">
                    <FormAutocomplete
                      name={`networks.fac`}
                      label={t('facility')}
                      options={facilitiesSelect.sort(
                        (a: any, b: any) =>
                          -get(b, 'sorted', '').localeCompare(get(a, 'sorted', '')),
                      )}
                      groupBy={(option: any) => option.sorted}
                      getOptionDisabled={(option: any) => option.disabled}
                      renderOption={(props: any, option: any) => {
                        return (
                          <Box
                            component="li"
                            sx={{ '& > div': { ml: -1, mr: 1, flexShrink: 0 } }}
                            {...props}
                          >
                            <Box
                              sx={{
                                borderRadius: '25px',
                                width: 40,
                                height: 16,
                                backgroundColor: primaryColor,
                                position: 'relative',
                              }}
                            >
                              <Box
                                sx={{
                                  fontSize: 11,
                                  color: 'white',
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                }}
                              >
                                {option.icons}
                              </Box>
                            </Box>
                            {option.label}
                          </Box>
                        );
                      }}
                      disableClearable={true}
                      placeholder={t('selectFacility')}
                      required={true}
                      ownerOnChange={getExchangeNetworksFromFacility}
                      index={0}
                      defaultValue={null}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={4} data-tour="cstore-networks">
                    <FormSelect
                      name="networks.type"
                      label={t('selectExchangeNetwork')}
                      items={prepareNetworks}
                      disabled={size(prepareNetworks) < 2}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={2}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={!patients.length || !networkTypeWatch}
                      sx={{ mt: 1, float: 'right' }}
                      data-tour="cstore-send-button"
                    >
                      {t('send')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Papeer>
        </form>
      </FormProvider>
    </>
  );
};
