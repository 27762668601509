import { filter, find, get, inRange, isArray, isEmpty, isFunction, reduce } from 'lodash';
import { ISerie } from 'modules/Studies/StudyDetail/_types';
import { useState } from 'react';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { decodeIID } from 'utils/study';
import { getSeriesAndInstances } from './_api';
import { ISerieImageWithStates, ISerieWithStates, IStudyWithStates } from './_types';

/**
 * @param someFunctionFromMainComponent - předaná funkce 'onGetDicomAttributes' z hlavní komponenty 'GetDicomAttributes'
 * @param someActionNameFromMainComponent - předaný název akce 'viewDicomAttributes' z hlavní komponenty 'GetDicomAttributes'
 */
export const useSeriesViewer = (
  someFunctionFromMainComponent: any = null,
  someActionNameFromMainComponent: string = '',
) => {
  const { toggleLoader } = useAppGlobals();
  const [studiesForViewer, setStudiesForViewer] = useState<IStudyWithStates[]>([]);
  const [currentImage, setCurrentImage] = useState<string>('');

  const viewDicomAttributes = someActionNameFromMainComponent === 'viewDicomAttributes';

  const numberOfSelectedStudies = filter(studiesForViewer, (study) => study.studyIsSelected).length;
  const numberOfSelectedSeries = reduce(
    studiesForViewer,
    (result, study) => {
      const numberOfSelectedSeries = filter(
        study.loadedSeries,
        (serie) => serie.serieIsSelected,
      ).length;
      return result + numberOfSelectedSeries;
    },
    0,
  );
  let numberOfSelectedImages = 0;
  studiesForViewer.forEach((study) => {
    filter(get(study, 'loadedSeries', []), (serie) => serie.serieIsSelected).forEach(
      (selectedSeries) => {
        const selectedImages = filter(
          get(selectedSeries, 'image', []),
          (image) => image.imageIsSelected,
        ).length;
        numberOfSelectedImages = numberOfSelectedImages + selectedImages;
      },
    );
  });
  const somethingSelected =
    numberOfSelectedStudies + numberOfSelectedSeries + numberOfSelectedImages;

  const setStudyOpened = async (iid: string) => {
    const study = find(studiesForViewer, { iid })!;
    const newState = !study.studyIsOpened;
    const studyHasSeriesLoaded = get(study, 'loadedSeries', []).length > 0;
    const { studyID, archiveID } = decodeIID(iid);
    let loadedSeries: ISerie[] = [];
    if (newState && !studyHasSeriesLoaded) {
      toggleLoader();
      const series = await getSeriesAndInstances(archiveID, studyID);
      if (isArray(series)) {
        loadedSeries = series;
      }
      toggleLoader(false);
    }

    setStudiesForViewer(
      studiesForViewer.map((study) => {
        if (study.iid === iid) {
          const markAsSelected = study.studyIsSelected;
          const listOfSeries = get(study, 'listOfSeries', []);
          return {
            ...study,
            studyIsOpened: newState,
            loadedSeries: studyHasSeriesLoaded
              ? study.loadedSeries
              : isArray(loadedSeries)
              ? loadedSeries.map((serie) => {
                  /**
                   * Pokud je v listOfSeries neprázdný seznam sérií, pak nemusí být úplný (nějaké série mohly být odebrány).
                   * V tomto případě kontrolovat u série z loadedSeries, zda se vyskytuje v listOfSeries. Pokud nevyskytuje,
                   * pak nemůže být defaultně zaškrtnutá po prvotním rozbalení.
                   */
                  const serieFromListOfSeries =
                    isArray(listOfSeries) && !isEmpty(listOfSeries)
                      ? find(listOfSeries, { uid: serie.uid })!
                      : null;
                  const canSerieAndImageSelected =
                    serieFromListOfSeries !== undefined && markAsSelected;
                  return {
                    ...serie,
                    serieIsSelected: canSerieAndImageSelected,
                    serieIsOpened: false,
                    image: serie.image.map((image) => ({
                      ...image,
                      imageIsSelected: canSerieAndImageSelected,
                    })),
                  };
                })
              : [],
          };
        }
        return study;
      }),
    );
  };

  const setSerieOpened = (iid: string, uid: string) => {
    setStudiesForViewer(
      studiesForViewer.map((study) => {
        if (study.iid === iid) {
          return {
            ...study,
            loadedSeries: study.loadedSeries.map((serie) => {
              if (serie.uid === uid) {
                return { ...serie, serieIsOpened: !serie.serieIsOpened };
              }
              return serie;
            }),
          };
        }
        return study;
      }),
    );
  };

  const setStudySelected = (iid: string) => {
    setStudiesForViewer(
      studiesForViewer.map((study) => {
        if (study.iid === iid) {
          const newState = !study.studyIsSelected;
          return {
            ...study,
            studyIsSelected: newState,
            loadedSeries: study.loadedSeries.map((serie) => ({
              ...serie,
              serieIsSelected: newState,
            })),
          };
        }
        return study;
      }),
    );
  };

  const setSerieSelected = (uid: string, studyIid: string) => {
    const study = find(studiesForViewer, { iid: studyIid })!;

    const changedSeries = study.loadedSeries.map((serie) => {
      if (serie.uid === uid) {
        const newState = !serie.serieIsSelected;
        return {
          ...serie,
          serieIsSelected: newState,
          serieIsIndeterminate: false,
          image: serie.image.map((image) => ({ ...image, imageIsSelected: newState })),
        };
      }
      return serie;
    });

    const selectedSeriesInStudy = filter(changedSeries, (serie) => serie.serieIsSelected).length;

    setStudiesForViewer(
      studiesForViewer.map((study) => {
        if (study.iid === studyIid) {
          return {
            ...study,
            studyIsSelected: selectedSeriesInStudy > 0 ? true : false,
            loadedSeries: changedSeries,
          };
        }
        return study;
      }),
    );
  };

  const setImageSelected = (uid: string, studyIid: string, sopinstanceUid: string) => {
    const study = find(studiesForViewer, { iid: studyIid })!;
    const serie = find(study.loadedSeries, { uid })!;
    const image = find(serie.image, { sopinstanceUid })!;

    const newState = !image.imageIsSelected;

    const changedStudies = studiesForViewer.map((study) => {
      if (study.iid === studyIid) {
        const changedSeries = study.loadedSeries.map((serie) => {
          if (serie.uid === uid) {
            const newImages = serie.image.map((image) => {
              if (sopinstanceUid === image.sopinstanceUid) {
                return { ...image, imageIsSelected: newState };
              }
              return image;
            });

            const imagesSelectedInSeries = getImagesSelectionInfo(newImages);

            return {
              ...serie,
              serieIsSelected: !imagesSelectedInSeries.noneImagesSelected,
              serieIsIndeterminate: imagesSelectedInSeries.someImagesSelected,
              image: newImages,
            };
          }
          return serie;
        });

        const selectedSeriesInStudy = filter(
          changedSeries,
          (serie) => serie.serieIsSelected,
        ).length;

        return {
          ...study,
          studyIsSelected: selectedSeriesInStudy > 0 ? true : false,
          loadedSeries: changedSeries,
        };
      }
      return study;
    });

    setStudiesForViewer(changedStudies);
  };

  const getImagesSelectionInfo = (images: ISerieImageWithStates[]) => {
    const numberOfSelectedImages = filter(images, { imageIsSelected: true }).length;

    // All the images are selected
    const allImagesInSeriesSelected = () => {
      if (!isArray(images) || !images.length) {
        return false;
      }
      return filter(images, { imageIsSelected: true }).length === images.length;
    };

    // Some of the images, but not all
    const someImagesInSeriesSelected = () => {
      if (!isArray(images) || !images.length) {
        return false;
      }
      if (numberOfSelectedImages === 0) {
        return false;
      }
      return inRange(numberOfSelectedImages, images.length);
    };

    // None of the images is selected
    const noneImagesInSeriesSelected = () => {
      if (!isArray(images) || !images.length) {
        return false;
      }
      return numberOfSelectedImages === 0;
    };

    return {
      allImagesSelected: allImagesInSeriesSelected(),
      someImagesSelected: someImagesInSeriesSelected(),
      noneImagesSelected: noneImagesInSeriesSelected(),
    };
  };

  const setSomeAction = (
    study: IStudyWithStates,
    serie: ISerieWithStates | null = null,
    image: ISerieImageWithStates | null = null,
  ) => {
    if (isFunction(someFunctionFromMainComponent)) {
      let attributes = {};
      if (viewDicomAttributes && study && serie && image) {
        const imageUID = get(image, 'sopinstanceUid', '');
        setCurrentImage(imageUID);
        const dicomAttributes = {
          studyUID: get(study, 'studyInstanceUid', ''),
          productId: get(study, 'archive.id', ''),
          seriesUID: get(serie, 'uid', ''),
          imageUID,
        };
        attributes = dicomAttributes;
      }
      someFunctionFromMainComponent(attributes);
    }
  };

  return {
    studiesForViewer,
    setStudiesForViewer,
    operations: {
      setStudyOpened,
      setStudySelected,
      setSerieSelected,
      setSerieOpened,
      setImageSelected,
      setSomeAction,
      someActionNameFromMainComponent,
      currentImage,
      setCurrentImage,
    },
    setStudyOpened,
    setStudySelected,
    setSerieSelected,
    numberOfSelectedStudies,
    numberOfSelectedSeries,
    numberOfSelectedImages,
    somethingSelected,
    getImagesSelectionInfo,
  };
};
