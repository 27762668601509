import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';

const useValidationSchema = (keyValues: { identifiers: string[]; names: string[] }) => {
  const { required, unique } = useValidators();
  const HospitalFormSchema = yup.object().shape({
    identifier: unique(required(yup.string()), keyValues.identifiers),
    name: unique(required(yup.string().nullable()), keyValues.names),
    alternativeIdentifier: required(yup.string()),
  });

  return { HospitalFormSchema };
};

export default useValidationSchema;
