import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { IStudiesExportReportDialog } from './_types';
import { find, get } from 'lodash';
import { format } from 'date-fns';

const StudiesExportReportDialog: React.FC<IStudiesExportReportDialog> = ({
  dialogState,
  setDialogState,
  reports,
}) => {
  const { t } = useTranslation('Studies');
  const [report, setReport] = useState<any>({});

  useEffect(() => {
    const report = find(reports, { iid: dialogState.iid });
    setReport(report);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports.length, dialogState.iid]);

  return (
    <>
      <Dialog
        open={dialogState.opened}
        onClose={() => setDialogState('')}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">{t('reportTitle')}</DialogTitle>
        <DialogContent>
          <Typography component="div">
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <label>
                  <Box sx={{ fontWeight: 'bold' }}>{t('author')}</Box>
                </label>
                <div>{get(report, 'author', '')}</div>
              </Grid>
              <Grid item={true} xs={12}>
                <label>
                  <Box sx={{ fontWeight: 'bold' }}>{t('date')}</Box>
                </label>
                <div>
                  {get(report, 'date', false) ? format(report.date, 'dd.MM.yyyy, HH:mm:ss') : ' '}
                </div>
              </Grid>
              <Grid item={true} xs={12}>
                <label>
                  <Box sx={{ fontWeight: 'bold' }}>{t('text')}</Box>
                </label>
                <div>{get(report, 'report', ' ')}</div>
              </Grid>
            </Grid>
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default StudiesExportReportDialog;
