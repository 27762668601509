import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Grid, Tooltip } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { compact, difference, find, get, isEmpty, isString } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getFacility, editFacility, createFacility, removeFacilityExchangeNetwork } from './_api';
import { IFacility, IFacilityForm } from './_types';
import useValidationSchema from './_form';
import { getAll } from '../FacilitiesAndExchangeNetworks/_apiExchangeNetworks';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSwitch from 'components/Form/Switch/Switch';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import useAlerts from 'components/Alerts/useAlerts';
import { Line } from 'components/Line/Line';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const linkBack = '/administration/facilitiesAndExchangeNetworks';
const muiGridKey = 'facilityFormMUI';

export const FacilityForm: React.FC = () => {
  const { t } = useTranslation('Facilities');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();
  const params = useParams();
  const [entity, fetchEntity] = useState<IFacility>();
  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);
  const [id, setId] = useState<any>();
  const [isCreating, setIsCreating] = useState<boolean>(true);
  const [unSelectedExchangeNetworks, setUnSelectedExchangeNetworks] = useState<any[]>([]);
  const navigate = useNavigate();
  const { title } = useWithEntityTitle(isCreating, entity, t);

  const { FacilityFormSchema, formItems } = useValidationSchema(t);

  const methods = useForm<IFacilityForm>({
    resolver: yupResolver(FacilityFormSchema),
  });
  const { handleSubmit, reset, register } = methods;

  const loadEntity = async (id2: any, isCreating2: boolean) => {
    toggleLoader();

    try {
      const exchangeNetworks = await getAll();

      let facility: IFacility = {
        id: 0,
        name: '',
        city: '',
        country: '',
        code: '',
        activeFacility: false,
        active: true,
        facilityExchangeNetworks: [],
      };

      if (!isCreating2 && id2) {
        const entity = await getFacility(id2.toString());
        facility = { ...entity };
        if (entity) {
          fetchEntity(facility);

          const allExchangeNetworkNames = exchangeNetworks.map((item: any) => item.name);
          const useExchangeNetworks = exchangeNetworks.map((item: any) => {
            const findFacilityExchangeNetwork = find(
              get(entity, 'facilityExchangeNetworkModels'),
              (item2: any) => {
                return item2.exchangeNetwork.name === item.name;
              },
            );
            return get(findFacilityExchangeNetwork, 'exchangeNetwork.name', null);
          });
          const unSelectedExchangeNetworks = difference(
            allExchangeNetworkNames,
            compact(useExchangeNetworks),
          );
          setUnSelectedExchangeNetworks(unSelectedExchangeNetworks);
        }
      }

      reset({ ...facility });
      setIsFetchingEntity(false);
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const fn = isCreating ? createFacility : editFacility;
    const response = await fn(values);
    if (response) {
      addSuccessAlert(t('saved'));
      if (isCreating) {
        setId(get(response, 'id'));
        setIsCreating(false);
        loadEntity(get(response, 'id'), false);
      } else {
        navigate(linkBack);
      }
    } else {
      addErrorAlert(t(isString(response) ? response : 'errorSaving'));
    }
    toggleLoader(false);
  });

  useEffect(() => {
    const isCreating = typeof get(params, 'id') === 'string' && get(params, 'id') === 'create';
    const id: number | string = isCreating ? 'create' : parseInt(get(params, 'id', ''), 10);
    setId(id);
    setIsCreating(isCreating);
    loadEntity(id, isCreating);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCustomEntityRemove = async (facilityExchangeNetworkId: number) => {
    const removed = await removeFacilityExchangeNetwork(facilityExchangeNetworkId);
    if (removed) {
      await loadEntity(id, false);
      addSuccessAlert(t('deleted'));
    } else {
      addErrorAlert(t('errorDeleting'));
    }
  };

  const confirmAction = () => {
    onCustomEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const onCustomEntityDetail = (row: any) => {
    navigate(`/administration/facilitiesAndExchangeNetworks/${id}/${get(row, 'id')}`);
  };

  const clearAction = () => dispatch(storeConfirmationDataToStore(null));

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 120,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                label={t('Grid:edit')}
                onClick={() => onCustomEntityDetail(row)}
              />
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:delete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('Grid:delete')}
                onClick={() => {
                  dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }));
                }}
                disabled={
                  get(row, 'state', null) === 'NEW' ||
                  !get(row, 'canDelete', true) ||
                  get(row, 'system', false)
                }
              />
            </>
          );
        },
      },
      {
        field: 'name',
        headerName: t('facilityExchangeNetwork.exhangeNetwork.name'),
        valueGetter: (value: any, row: any) => row.exchangeNetwork.name,
      },
      { field: 'identifier', headerName: t('facilityExchangeNetwork.identifier') },
    ]),
  );

  return (
    <>
      {!isFetchingEntity && (
        <>
          <Header title={title} />
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <input {...register('id')} type="hidden" />
                <input {...register('active')} type="hidden" />
                <Grid container={true} alignItems="flex-end">
                  <Grid item={true} xs={12} md={6} lg={3} xl={2}>
                    {formItems.map((formItem, index) => {
                      const type = get(formItem, 'type', 'text');
                      const name = get(formItem, 'name', '');
                      const isRequired = get(formItem, 'required', false);
                      const label = get(formItem, 'label', '');

                      return (
                        <React.Fragment key={`formItem-${index}`}>
                          {type === 'switch' ? (
                            <FormSwitch name={name} label={label} />
                          ) : (
                            <FormInput
                              name={name}
                              label={label}
                              type={type}
                              required={isRequired}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid item={true} xs={12}>
                  <Line />
                </Grid>
                <EntityButtons linkBack={linkBack} />
              </form>
            </FormProvider>
          </Papeer>
          {!isEmpty(entity) && (
            <>
              <Header
                title={t('facilityExchangeNetworks')}
                addUrl={
                  isEmpty(unSelectedExchangeNetworks)
                    ? ''
                    : `/administration/facilitiesAndExchangeNetworks/${get(entity, 'id')}/create${
                        unSelectedExchangeNetworks.length === 1
                          ? `?name=${unSelectedExchangeNetworks[0]}`
                          : ''
                      }`
                }
              />
              <MuiGrid
                gridKey={muiGridKey}
                rows={get(entity, 'facilityExchangeNetworkModels', [])}
                columns={columns}
                initialSortMode={[{ field: 'name', sort: 'asc' }]}
              />
              {confirmationData && confirmationData.id && !confirmationData.editDialog && (
                <ConfirmationDialog
                  title={
                    confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')
                  }
                  open={true}
                  aria-labelledby="form-dialog-title"
                  fullWidth={true}
                  cancelAction={clearAction}
                  confirmAction={confirmAction}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
