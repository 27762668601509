import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  CircularProgress,
  Collapse,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import { filter, get, isArray } from 'lodash';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import Logo2 from 'components/Logo/Logo2';
import HomeIcon from '@mui/icons-material/Home';

import { useMenuItems } from 'utils/hooks/useMenuItems';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { Link, NavLink } from 'react-router-dom';
import { blueBackground, drawerWidth, drawerWidthMini, lightBlue, version } from 'utils/variables';
import { format } from 'date-fns';
import MiniLogoOR from 'components/Logo/MiniLogoOR';
import styles from './_styles';
import { useTranslation } from 'react-i18next';
import { ORCZ, CKTCH } from 'constants/constants';

export const Menu: React.FC = () => {
  const { t } = useTranslation('Menu');
  const { menuItems } = useMenuItems();
  const { hasMiniMenu, isDarkMode, feConfig, feConfigFromApi } = useAppInfo();
  const { pathname } = useLocation();
  const defaultSubmenu = get(pathname.match(/\/([a-zA-Z]+)\//i), '[1]');
  const [submenu, toggleSubmenu] = useState(defaultSubmenu);

  const drawerBackgroundColor = get(feConfigFromApi, 'menuBackground', blueBackground);
  const drawerColor = get(feConfigFromApi, 'menuColor', '#a7b1c2');
  const drawerLogo = get(feConfigFromApi, 'menuLogo.src');
  const drawerLogoWidth = get(feConfigFromApi, 'menuLogo.width');
  const drawerLogoHeight = get(feConfigFromApi, 'menuLogo.height');
  const copyrightDate = format(new Date(), 'yyyy');

  const isActive = (link: string) => {
    if (submenu) {
      return submenu === link || defaultSubmenu === link;
    }
    return pathname === `/${link}`;
  };

  const getIsDisplayed = (link: string) => {
    if (link === 'studies') {
      return pathname.startsWith('/studies') || pathname.startsWith('/study');
    }

    return pathname.startsWith(`/${link}`);
  };

  const isCktch = get(feConfig, 'usedBranding', ORCZ) === CKTCH;

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: { zIndex: 1099 },
      }}
      sx={{
        displayPrint: 'none',
        '& .MuiDrawer-paper': {
          width: hasMiniMenu ? drawerWidthMini : drawerWidth,
          overflow: hasMiniMenu ? 'visible' : 'auto',
          '&::-webkit-scrollbar': {
            width: 5,
          },
          background: isDarkMode ? '#383838' : drawerBackgroundColor,
          color: isDarkMode ? '#fff' : drawerColor,

          /* Track */
          '&::-webkit-scrollbar-track': {
            background: '#fff',
          },

          /* Handle */
          '&::-webkit-scrollbar-thumb': {
            background: lightBlue,
          },

          /* Handle on hover */
          '&::-webkit-scrollbar-thumb:hover': {
            bgcolor: 'primary.main',
          },
        },
      }}
    >
      <Box sx={styles.drawerContainer}>
        <Box sx={styles.drawerTop}>
          {!hasMiniMenu && (
            <NavLink to="/" onClick={() => toggleSubmenu(undefined)}>
              <Box sx={styles.logo}>
                {drawerLogo ? (
                  <Box>
                    <img
                      src={drawerLogo}
                      alt="logo"
                      width={drawerLogoWidth}
                      height={drawerLogoHeight}
                    />
                  </Box>
                ) : (
                  <Logo2 />
                )}
              </Box>
            </NavLink>
          )}
          {hasMiniMenu && (
            <div style={{ paddingLeft: 8 }}>
              <IconButton color="inherit" component={Link} {...({ to: '/' } as any)}>
                <span style={{ color: '#fff' }}>
                  <HomeIcon color="inherit" />
                </span>
              </IconButton>
            </div>
          )}
          <List sx={{ fontSize: 13 }}>
            {filter(menuItems, { show: true }).map((parent: any) => {
              const parentIsActive = isActive(parent.link);
              const isDisplayed = getIsDisplayed(parent.link);
              const hasSubmenu = isArray(parent.submenu) && parent.submenu.length ? true : false;
              const submenuItems = filter(parent.submenu, { show: true });
              const isLink = !hasSubmenu && !parent.url;
              const isAdministration = parent.link === 'administration';

              return (
                <Box
                  key={parent.link}
                  sx={{
                    ...(hasMiniMenu
                      ? {
                          position: 'relative',
                          ':hover > div:last-child': {
                            display: 'block',
                          },
                        }
                      : {}),
                    ...((parentIsActive || isDisplayed) && !hasMiniMenu
                      ? {
                          borderLeft: 4,
                          borderColor: 'primary.main',
                          background: isDarkMode ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0.125)',
                          fontWeight: 'bold',
                        }
                      : {}),
                  }}
                >
                  <Tooltip
                    title={hasMiniMenu ? parent.label : ''}
                    placement="top"
                    key={parent.link}
                  >
                    <ListItem
                      button={true}
                      onClick={() => {
                        toggleSubmenu(undefined);
                        if (parent.url) {
                          return window.open(parent.url, '_blank');
                        }
                        if (hasSubmenu) {
                          return toggleSubmenu(parentIsActive ? null : parent.link);
                        }
                      }}
                      {...(isLink
                        ? {
                            component: Link,
                            to: `/${parent.link}`,
                          }
                        : {})}
                    >
                      <ListItemIcon
                        sx={{ color: isDarkMode ? 'white' : drawerColor, minWidth: 'auto' }}
                      >
                        {parent.icon}
                      </ListItemIcon>
                      {!hasMiniMenu ? (
                        <>
                          <ListItemText
                            primary={parent.label}
                            disableTypography={true}
                            sx={{ px: 2, mt: 0.25, mb: 0.25 }}
                          />
                          {parent.uploadingState ? <CircularProgress size={20} /> : null}
                          {hasSubmenu ? (
                            <Box
                              sx={{
                                transition: 'transform 0.1s linear',
                                transform: parentIsActive ? 'rotate(90deg)' : 'none',
                                display: 'flex',
                              }}
                            >
                              <KeyboardArrowRight />
                            </Box>
                          ) : null}
                        </>
                      ) : null}
                    </ListItem>
                  </Tooltip>
                  {hasSubmenu ? (
                    <Box
                      sx={{
                        ...(hasMiniMenu
                          ? {
                              top: '-10px',
                              left: '100%',
                              whiteSpace: 'nowrap',
                              position: 'absolute',
                              display: 'none',
                              border: 1,
                              borderLeft: '0px',
                              borderColor: 'divider',
                              background: isDarkMode ? '#383838' : blueBackground,
                              color: isDarkMode ? '#fff' : drawerColor,
                            }
                          : {}),
                        ...(hasMiniMenu && isAdministration ? { top: 'auto', bottom: 0 } : {}),
                        ...(!submenuItems.length ? { display: 'none' } : {}),
                      }}
                    >
                      <Collapse in={hasMiniMenu || parentIsActive} timeout="auto">
                        <List
                          sx={{
                            ...(hasMiniMenu && isAdministration
                              ? {
                                  display: 'grid',
                                  gridTemplateColumns: 'repeat(2,1fr)',
                                }
                              : {}),
                          }}
                        >
                          {submenuItems.map((submenuItem) => (
                            <ListItem
                              key={`${parent.link}-${submenuItem.link}`}
                              sx={hasMiniMenu ? { py: 0 } : {}}
                            >
                              <ListItemText disableTypography={true}>
                                <NavLink
                                  to={`/${parent.link}/${submenuItem.link}`}
                                  style={({ isActive }) => ({
                                    display: 'block',
                                    padding: hasMiniMenu ? '10px' : '0 0 0 56px',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    fontWeight: isActive ? 'bold' : 'normal',
                                  })}
                                >
                                  {submenuItem.label}
                                </NavLink>
                              </ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </Box>
                  ) : null}
                </Box>
              );
            })}
          </List>
        </Box>
        <Box sx={[styles.drawerBottom, hasMiniMenu ? styles.hidden : {}]}>
          <MiniLogoOR />
          <Typography
            sx={
              isDarkMode ? styles.bottomText : isCktch ? styles.cktchBottomText : styles.bottomText
            }
          >
            <Box component="span">{t('portalVersion')}:</Box> {version}
          </Typography>
          <Typography
            sx={[
              isDarkMode ? styles.bottomText : isCktch ? styles.cktchBottomText : styles.bottomText,
              hasMiniMenu ? styles.hidden : {},
            ]}
          >
            <Box
              component="a"
              href="https://www.orcz.cz"
              target="_blank"
              sx={
                isDarkMode
                  ? styles.bottomLink
                  : isCktch
                  ? styles.cktchBottomLink
                  : styles.bottomLink
              }
            >
              OR-CZ spol. s r.o. © 2015 - {copyrightDate}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};
