import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';

const useValidationSchema = (t: TFunction) => {
  const { required } = useValidators();

  const FormSchema = yup.object().shape({
    institutionName: required(yup.mixed()),
  });

  return { FormSchema };
};

export default useValidationSchema;
