import { compact, get, head, includes, isEmpty, isNull, isString, size } from 'lodash';
import { useEffect, useMemo } from 'react';
import { joinParams } from 'utils/study';
import {
  DISTRIBUTE_COPY_AND_UPDATE_OK,
  DISTRIBUTE_COPY_OK,
  DISTRIBUTE_MOVE_AND_UPDATE_OK,
  DISTRIBUTE_MOVE_OK,
  SAVE_STRUCTURED_REPORT_OK,
  SEND_STUDY_OK,
  SHREDDING_STUDY_OK,
  UPDATE_PATIENT_OK,
  UPDATE_STUDY_OK,
  UPLOAD_STUDY_OK,
} from 'constants/constants';
import { useNotificationsState } from './hooks/useNotificationsState';
import { sign } from 'modules/Studies/StudyExport/_api';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import useAlerts from 'components/Alerts/useAlerts';
import { useTranslation } from 'react-i18next';
import webstomp from 'webstomp-client';
import { Button, IconButton, Link, Snackbar, SnackbarContent } from '@mui/material';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import CloseIcon from '@mui/icons-material/Close';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useUser } from 'utils/hooks/useUser';
import blue from '@mui/material/colors/blue';
import { IWebsocketNotification } from 'modules/websocketNotification/_types';
import { useNavigate } from 'react-router';
import { useAppLoader } from 'utils/hooks/useAppLoader';
import { getTranslation } from 'utils/translationUtils';
import i18n from 'constants/translations/i18n';

const textLowercase = (text: string) => (isString(text) ? text.toLowerCase() : '');

function getPatientName(parsedNotification: IWebsocketNotification) {
  const studyRequestStudies = get(parsedNotification, 'requestId.studyRequestStudies', []);
  const eventId: string = get(parsedNotification, 'eventId', '');
  const correctStudiesSize = size(studyRequestStudies) === 1;
  if (
    eventId === UPLOAD_STUDY_OK ||
    eventId === UPDATE_STUDY_OK ||
    eventId === UPDATE_PATIENT_OK ||
    eventId === SAVE_STRUCTURED_REPORT_OK ||
    eventId === SHREDDING_STUDY_OK ||
    (eventId === SEND_STUDY_OK && correctStudiesSize) ||
    (eventId === DISTRIBUTE_COPY_OK && correctStudiesSize) ||
    (eventId === DISTRIBUTE_MOVE_OK && correctStudiesSize) ||
    (eventId === DISTRIBUTE_MOVE_AND_UPDATE_OK && correctStudiesSize) ||
    (eventId === DISTRIBUTE_COPY_AND_UPDATE_OK && correctStudiesSize)
  ) {
    if (!isEmpty(studyRequestStudies)) {
      return `${joinParams([
        get(head(studyRequestStudies), 'provided.patient.name.lastName', ''),
        get(head(studyRequestStudies), 'provided.patient.name.firstName', ''),
      ])}`;
    }
  }
  return null;
}

function getAccessionNumber(parsedNotification: IWebsocketNotification) {
  const studyRequestStudies = get(parsedNotification, 'requestId.studyRequestStudies', []);
  const eventId: string = get(parsedNotification, 'eventId', '');
  const correctStudiesSize = size(studyRequestStudies) === 1;
  if (
    eventId === UPLOAD_STUDY_OK ||
    eventId === UPDATE_STUDY_OK ||
    eventId === SAVE_STRUCTURED_REPORT_OK ||
    eventId === SHREDDING_STUDY_OK ||
    (eventId === SEND_STUDY_OK && correctStudiesSize) ||
    (eventId === DISTRIBUTE_COPY_OK && correctStudiesSize) ||
    (eventId === DISTRIBUTE_MOVE_OK && correctStudiesSize) ||
    (eventId === DISTRIBUTE_MOVE_AND_UPDATE_OK && correctStudiesSize) ||
    (eventId === DISTRIBUTE_COPY_AND_UPDATE_OK && correctStudiesSize)
  ) {
    if (!isEmpty(studyRequestStudies)) {
      return get(head(studyRequestStudies), 'provided.accessionNumber', '');
    }
  }
  return null;
}

function getArchive(parsedNotification: IWebsocketNotification) {
  if (get(parsedNotification, 'eventId') === UPDATE_PATIENT_OK) {
    const studyRequestStudies = get(parsedNotification, 'requestId.studyRequestStudies', []);
    if (!isEmpty(studyRequestStudies)) {
      return get(head(studyRequestStudies), 'provided.archive.name', '');
    }
  }
  return null;
}

export const Websocket = () => {
  const { user } = useUser();
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert } = useAlerts();
  const { t } = useTranslation('WebsocketNotifications');
  const navigate = useNavigate();
  const { loadNewNotifications } = useAppLoader();

  const {
    notificationToShow,
    hiddenNotifications,
    setNotification,
    setHiddenNotification,
    loadNotificationsFromApi,
  } = useNotificationsState();

  const handleDownloadAttachment = async (id: number) => {
    toggleLoader();
    const resp = await sign(id);
    const hash = get(resp, 'data');
    if (hash) {
      window.open(`/portal-api/studyExport/download?signature=${hash}`);
    } else {
      addErrorAlert(t('downloadError'));
    }
    toggleLoader(false);
  };

  useEffect(() => {
    let socket: any;
    let ws: any;
    let shouldReconnect: boolean = true;
    let reconnectInterval: any;
    let reconnectDelay: number = 1000;
    const initialize = async () => {
      if (!shouldReconnect) return;
      const surname = get(user, 'sub');
      await loadNotificationsFromApi();
      if (surname) {
        const wsUrl = `${window.location.protocol === 'http:' ? 'ws://' : 'wss://'}${
          window.location.host
        }/portal-api/notifySocket`;
        socket = new WebSocket(wsUrl);
        ws = webstomp.over(socket, { debug: false });
        ws.connect({}, () => {
          ws.subscribe(`/user/${surname}/queue/notify`, async (message: any) => {
            const notification = get(message, 'body');
            try {
              const parsedNotification = JSON.parse(notification);
              setNotification({
                id: parsedNotification.id,
                parsedNotification,
              });
              await loadNotificationsFromApi();
              await loadNewNotifications();
            } catch (e) {
              console.debug(e);
            }
          });
          // Reset the reconnection delay after a successful connection
          reconnectDelay = 1000;
        });
      }
      socket.onclose = () => {
        if (!shouldReconnect) return;
        reconnectInterval = setTimeout(initialize, reconnectDelay);
        // Exponential backoff, capped at 5 minutes
        reconnectDelay = Math.min(reconnectDelay * 2, 300000); // 300000 ms is 5 minutes
      };
    };
    initialize();

    return () => {
      shouldReconnect = false; // Prevent reconnect attempts after unmounting
      clearTimeout(reconnectInterval); // Clear the reconnect attempt timeout
      if (ws && ws.connected) {
        ws.disconnect();
      }
      if (socket) {
        socket.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { actions, notificationToShowMessage } = useMemo(() => {
    const parsedNotification = get(notificationToShow, 'parsedNotification', null);
    const requestId = get(parsedNotification, 'requestId.id');
    const eventId = get(parsedNotification, 'eventId', '');
    let languageKey: string = get(parsedNotification, 'templateId.eventType.languageKey', '');

    const requestIdOperationType = get(parsedNotification, 'requestId.operationType', '');

    const patientName = parsedNotification && getPatientName(parsedNotification);
    const accessionNumber = parsedNotification && getAccessionNumber(parsedNotification);
    const archive = parsedNotification && getArchive(parsedNotification);

    const showDownloadExportButton = eventId === 'EXPORT_STUDY_OK';
    const showListButton =
      eventId !== 'WORKPLACE_CHANGED' &&
      eventId !== 'SEND_MDEX_OK' &&
      eventId !== 'SEND_MDEX_NOK' &&
      !showDownloadExportButton;

    const errorType = get(parsedNotification, 'requestId.responseData.errorType');
    let reason = '';
    const isNok = languageKey
      ? languageKey.substring(languageKey.length - 3, languageKey.length) === 'nok'
      : false;

    if (isNok) {
      reason = errorType === '1' || errorType === '2' || errorType === '4' ? errorType : 'unknown';
    }

    languageKey = getTranslation(
      languageKey,
      i18n.getResourceBundle(i18n.language, 'WebsocketNotifications'),
    );

    const actions = compact([
      showListButton ? (
        <Button
          size="small"
          color="inherit"
          key="list"
          component={Link}
          onClick={() => {
            notificationToShow && setHiddenNotification(notificationToShow.id);
            navigate('/notifications');
          }}
        >
          {t('list')}
        </Button>
      ) : null,
      showDownloadExportButton ? (
        <Button
          size="small"
          color="inherit"
          key="listExport"
          component={Link}
          onClick={() => {
            notificationToShow && setHiddenNotification(notificationToShow.id);
            navigate('/exportsList');
          }}
        >
          {t('listExport')}
        </Button>
      ) : null,
      showDownloadExportButton ? (
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            notificationToShow && setHiddenNotification(notificationToShow.id);
            requestId && handleDownloadAttachment(requestId);
          }}
          title={t('downloadAttachment')}
        >
          <DownloadIcon />
        </IconButton>
      ) : null,
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => notificationToShow && setHiddenNotification(notificationToShow.id)}
      >
        <CloseIcon />
      </IconButton>,
    ]);

    let notificationMessage: ReactJSXElement | string = t(languageKey);

    if (eventId === 'WORKPLACE_CHANGED') {
      notificationMessage = (
        <>
          {t('workplaceChangedFirstPArt')}
          <br />
          {t('workplaceChangedSecondPArt')}
        </>
      );
    }

    if (eventId === 'STUDY_REQUEST_NEW') {
      notificationMessage = t(languageKey) + t(textLowercase(requestIdOperationType));
    }

    if (eventId === 'SEND_MDEX_OK' || eventId === 'SEND_MDEX_NOK') {
      notificationMessage = t(eventId);
    }

    notificationMessage = (
      <div>
        {notificationMessage}
        {!isNull(patientName) && (
          <>
            <br />
            {t('patient')}: {patientName}
          </>
        )}
        {!isNull(accessionNumber) && (
          <>
            <br />
            {t('accessionNumber')}: {accessionNumber}
          </>
        )}
        {!isNull(archive) && (
          <>
            <br />
            {t('sourceArchive')}: {archive}
          </>
        )}
        {reason && (
          <>
            <br />
            {t('errorDescription')}: {t(`nok.${reason}`)}
          </>
        )}
      </div>
    );

    return {
      actions,
      notificationToShowMessage: notificationMessage,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationToShow, t, setHiddenNotification]);

  return (
    <div>
      {notificationToShow && (
        <div>
          <Snackbar
            key={notificationToShow.id}
            autoHideDuration={15000}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={!includes(hiddenNotifications, notificationToShow.id)}
            onClose={() => setHiddenNotification(notificationToShow.id)}
            disableWindowBlurListener={false}
            ClickAwayListenerProps={{
              onClickAway: () => false,
            }}
            data-selenium-selector="notification-websocket"
          >
            <SnackbarContent
              message={notificationToShowMessage}
              style={{
                backgroundColor: blue[600],
                color: '#fff',
              }}
              action={actions}
            />
          </Snackbar>
        </div>
      )}
    </div>
  );
};
