// import dicomParser from 'dicom-parser';
import { isIE11 } from 'utils/variables';

export const apiDataToDownloadFile = (data: any, filename: string) => {
  if (navigator.appVersion.toString().indexOf('.NET') > 0) {
    // OBSOLETE, IE 11
    // const msSaveBlob = get(window, 'navigator.msSaveBlob');
    // if (msSaveBlob !== undefined) {
    //   msSaveBlob(data, filename);
    // }
  } else {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const a = document.createElement('a');
    const url = URL.createObjectURL(blob);
    a.href = url;
    a.download = decodeURI(filename);
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
};

const b64toBlob = (content: any, contentType: string) => {
  contentType = contentType || '';
  const sliceSize = 512;
  // method which converts base64 to binary
  const byteCharacters = window.atob(content);

  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, {
    type: contentType,
  }); // statement which creates the blob
  return blob;
};
const injectWindowPrint = () => {
  window.print();
};
export const printOrDownloadBase64String = (
  content: any,
  contentType: string,
  fileName: string,
) => {
  try {
    const blob = b64toBlob(content, contentType);
    if (isIE11) {
      // TO DO IE 11
      // const msSaveOrOpenBlob = get(window, 'navigator.msSaveOrOpenBlob');
      // if (msSaveOrOpenBlob !== undefined) {
      //   msSaveOrOpenBlob(blob, fileName);
      // }
    } else {
      const blobUrl = URL.createObjectURL(blob);

      const theWindow = window.open(blobUrl);
      if (theWindow != null) {
        const theDoc = theWindow.document;
        const theScript = document.createElement('script');
        theScript.innerHTML = `window.onload = ${injectWindowPrint.toString()};`;
        theDoc.body.appendChild(theScript);
      } else {
        console.error('Can not open more new windows! Popup is blocked!');
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const downloadFile = (pdfBase64: any, fileName: any) => {
  try {
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    const nav = window.navigator as any;
    if (nav.msSaveOrOpenBlob) {
      const byteCharacters = window.atob(pdfBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      nav.msSaveOrOpenBlob(blob, fileName);
    } else {
      // For Other Browsers
      const linkSource = 'data:application/pdf;base64,' + pdfBase64;
      const link = document.createElement('a');
      link.href = linkSource;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();

      window.addEventListener(
        'focus',
        () => {
          URL.revokeObjectURL(link.href);
        },
        { once: true },
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const encryptId = (iid: any) => {
  try {
    return encodeURIComponent(btoa(iid));
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const decryptId = (hash: any) => {
  try {
    return atob(decodeURIComponent(hash));
  } catch (e) {
    console.log(e);
    return '_';
  }
};

export const binaryToDec = (binary: any) => {
  try {
    return parseInt(binary.toString(), 2);
  } catch (e) {
    console.log(e);
    return 0;
  }
};

/* export const dumpDICOMDIR = (file) => {
  let dataSet;
  const output = { images: [] };

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = async (file2) => {
      const arrayBuffer = reader.result;
      const byteArray = new Uint8Array(arrayBuffer as ArrayBuffer);
      dataSet = dicomParser.parseDicom(byteArray);
      set(output, 'fileSetID', dataSet.string('x00041130'));
      set(output, 'version', dataSet.string('x00020013'));
      for (const j in dataSet.elements) {
        if (dataSet.elements.hasOwnProperty(j)) {
          const element = dataSet.elements[j];
          if (element.tag === 'x00041220') {
            for (const item in element.items) {
              if (element.items.hasOwnProperty(item)) {
                for (const k in element.items[item].dataSet.elements) {
                  if (element.items[item].dataSet.elements.hasOwnProperty(k)) {
                    if (element.items[item].dataSet.elements[k].tag === 'x00041430') {
                      const type = element.items[item].dataSet.string('x00041430');
                      switch (type) {
                        // case "PATIENT" :
                        // var patient = element.items[item].dataSet.string('x00100010');
                        // var id = element.items[item].dataSet.string('x00100020');
                        // // output += "<li>Clinical Case Name (PatientName) : " +  patient + "</li>"; 
                        // output += "<li>Clinical Case ID : " +  id + "</li>"; 
                        // break;
                        // case "STUDY" :
                        // var study = element.items[item].dataSet.string('x00081030');
                        // output += "<li>STUDY : " + study + "</li>";
                        // break;
                        // case "SERIES" :
                        // var modality = element.items[item].dataSet.string('x00080060');
                        // output += "<li>SERIES MODALITY: " + modality + "</li>";
                        // break;
                        case 'IMAGE':
                          const path = element.items[item].dataSet.string('x00041500');
                          // const instance = element.items[item].dataSet.string('x00200013');
                          if (path) {
                            output.images.push(path.replace('\\', '/'));
                          }
                          break;
                        default:
                          const path2 = element.items[item].dataSet.string('x00041500');
                          if (path2) {
                            output.images.push(path2.replace('\\', '/'));
                          }
                          break;
                      } // end switch
                    } // end if tag
                  } // end if hasOwnProperty(k)
                } // end for k
              } // end if hasOwnProperty(item)
            } // end for item
          } // end if tag
        } // end if hasOwnProperty(j)
      } // end for j
    };
    resolve(output);
    reader.readAsArrayBuffer(file.file);
  });
}; */
