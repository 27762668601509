import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filter, get, isArray, isEmpty, join, sortBy } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Grid, Icon } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import {
  batchRemove,
  getAll,
  workPlaceAutoAssign,
  workPlaceManualAssign,
  workPlaceManualUnassign,
} from './_api';
import { getAllWorkplaces } from '../Workplaces/_api';
import { IWorkplace } from '../Workplaces/_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import FormSwitch from 'components/Form/Switch/Switch';
import { Announcement } from 'components/Announcement/Announcement';
import useAlerts from 'components/Alerts/useAlerts';
import { CheckedButton } from 'components/Buttons/CheckedButton';
import Button from 'components/Buttons/Button';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import {
  ASSIGN_BTN_AUTOMATIC,
  ASSIGN_BTN_MANUAL,
  SELECTED_BTN_SPLIT_BY,
} from 'constants/constants';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'buttonsMUI';

const ButtonsGrid: React.FC<any> = ({ action, dialogState, toggleDialog, refreshGrid, title }) => {
  const { t } = useTranslation('Buttons');
  const { toggleLoader } = useAppGlobals();
  const { spacing } = useAppInfo();
  const { currentLocale } = useLanguage();

  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const [buttons, setButtons] = useState<any[]>([]);
  const [workplaces, setWorkplaces] = useState<any[]>([]);
  const [selection, setSelection] = useState<any[]>([]);
  const [automaticType, setAutomaticType] = useState<string | any>(ASSIGN_BTN_AUTOMATIC);
  const [manualType, setManualType] = useState<string | any>(null);
  const [userButtonUpdate, setUserButtonUpdate] = useState<boolean>(false);
  const [reloadGrid, setReloadGrid] = useState<boolean>(false);

  const canRemove = !isEmpty(selection);
  const canSubmit = automaticType || manualType;

  const methods = useForm<any>();
  const { handleSubmit, setValue, watch } = methods;
  const watchUserButtonUpdate: boolean = watch('userButtonUpdate');
  const watchManualUnassign: boolean = watch('manualUnassign');
  if (userButtonUpdate !== watchUserButtonUpdate) {
    setUserButtonUpdate(watchUserButtonUpdate);
  }

  const loadEntity = async (first: boolean = false) => {
    toggleLoader();
    try {
      const buttons = await getAll();
      if (buttons && isArray(buttons)) {
        const filteredButtons = filter(buttons, { active: action === SELECTED_BTN_SPLIT_BY });
        const allId: any[] = [];
        filteredButtons.forEach((button) => {
          allId.push(get(button, 'id'));
        });
        setButtons(filteredButtons);
        if (first) {
          setSelection(allId);
          if (action === SELECTED_BTN_SPLIT_BY) {
            const workplaces = await getAllWorkplaces();
            if (isArray(workplaces)) {
              setWorkplaces(
                workplaces.map((item) => ({
                  ...item,
                  name: get(
                    get(item, 'text', false) ? JSON.parse(get(item, 'text', '')) : {},
                    currentLocale,
                    get(item, 'code', '?'),
                  ),
                })),
              );
            }
          }
        }
        setReloadGrid(true);
      } else {
        setButtons([]);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const userButtonUpdate = get(values, 'userButtonUpdate', false);
    const response = await workPlaceAutoAssign(userButtonUpdate);
    if (response) {
      addSuccessAlert(t('successSplitButtons'));
      setReloadGrid(true);
      toggleDialog(false);
    } else {
      addErrorAlert(t('errorSplitButtons'));
    }
    toggleLoader(false);
  });

  const setAnyType = (automatic: any, manual: any) => {
    setAutomaticType(automatic);
    setManualType(manual);
  };

  useEffect(() => {
    loadEntity(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const splitManualSelectedButtons = async (workPlaceId: any) => {
    toggleLoader();
    const workPlaceManualAssignOrUnassign = watchManualUnassign
      ? workPlaceManualUnassign
      : workPlaceManualAssign;
    const response = await workPlaceManualAssignOrUnassign(userButtonUpdate, {
      buttonIds: selection,
      workPlaceId,
    });
    if (response) {
      addSuccessAlert(t('successSplitButtons'));
      loadEntity();
    } else {
      addErrorAlert(t('errorSplitButtons'));
    }
    toggleLoader(false);
  };

  const deleteSelectedButtons = async () => {
    toggleLoader();
    const response = await batchRemove({ buttonIds: selection });
    if (response) {
      addSuccessAlert(t('removedSelected'));
      loadEntity();
      toggleDialog(false);
    } else {
      addErrorAlert(t('errorRemovingSelected'));
    }
    toggleLoader(false);
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'icon',
        headerName: t('icon'),
        renderCell: ({ row }: GridRenderCellParams) => (
          <Icon baseClassName="material-icons">{row.icon}</Icon>
        ),
        filterable: false,
        sortable: false,
      },
      {
        field: 'languageJsonText',
        headerName: t('languageJsonText'),
        renderCell: ({ row }: GridRenderCellParams) => {
          const value = get(row, 'languageJsonText', '');
          return value ? (
            <Box component="ul" sx={{ listStyle: 'none', p: '0', my: 0.25 }}>
              {Object.keys(JSON.parse(value)).map((item, i) =>
                JSON.parse(value)[item] ? (
                  <li key={i}>
                    <span title={item}>{`${item}: ${JSON.parse(value)[item]}`}</span>
                  </li>
                ) : null,
              )}
            </Box>
          ) : (
            ''
          );
        },
        valueGetter: (_value: any, row: any) => {
          const value = get(row, 'languageJsonText', '');
          const stringValue = value
            ? Object.keys(JSON.parse(value)).map((item, i) =>
                JSON.parse(value)[item] ? `${item}: ${JSON.parse(value)[item]}` : null,
              )
            : '';
          return join(stringValue, ' ');
        },
      },
      { field: 'url', headerName: t('url') },
      {
        field: 'workPlaces',
        headerName: t('workplaces'),
        valueGetter: (value: any, row: any) =>
          isArray(get(row, 'workPlaces', null))
            ? sortBy(
                get(row, 'workPlaces').map((workPlace: IWorkplace) =>
                  get(
                    get(workPlace, 'text', false) ? JSON.parse(get(workPlace, 'text', '')) : {},
                    currentLocale,
                    workPlace.code,
                  ),
                ),
              ).join(', ')
            : null,
      },
      {
        field: 'system',
        headerName: t('system'),
        valueGetter: (value: any, row: any) => (get(row, 'system', false) ? t('yes') : t('no')),
      },
    ]),
  );

  const renderedButtons = workplaces.map((workplace: any) => (
    <Button
      key={workplace.id}
      color="primary"
      variant="contained"
      onClick={() => splitManualSelectedButtons(workplace.id)}
      sx={{ mr: 1, mt: 1 }}
      disabled={isEmpty(selection)}
    >
      {workplace.name}
    </Button>
  ));

  useEffect(() => {
    if (reloadGrid) {
      setReloadGrid(false);
      refreshGrid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadGrid]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <OverflowedDialog
          title={title}
          open={dialogState}
          onClose={() => toggleDialog(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth={true}
          toggleDialog={toggleDialog}
          dialogTitleActions={
            action === SELECTED_BTN_SPLIT_BY && (
              <Grid container={true} spacing={spacing}>
                <Grid item={true} xs={12}>
                  <CheckedButton
                    active={automaticType ? true : false}
                    rightMargin={true}
                    onClick={() => {
                      setAnyType(
                        automaticType === ASSIGN_BTN_AUTOMATIC ? null : ASSIGN_BTN_AUTOMATIC,
                        null,
                      );
                      setValue(
                        'type',
                        automaticType === ASSIGN_BTN_AUTOMATIC ? null : ASSIGN_BTN_AUTOMATIC,
                      );
                    }}
                  >
                    {t('automatic')}
                  </CheckedButton>
                  <CheckedButton
                    active={manualType ? true : false}
                    rightMargin={true}
                    onClick={() => {
                      setAnyType(null, manualType === ASSIGN_BTN_MANUAL ? null : ASSIGN_BTN_MANUAL);
                      setValue('type', manualType === ASSIGN_BTN_MANUAL ? null : ASSIGN_BTN_MANUAL);
                    }}
                  >
                    {t('manual')}
                  </CheckedButton>
                  <FormSwitch name={'userButtonUpdate'} label={t('userButtonUpdate')} />
                </Grid>
              </Grid>
            )
          }
          actionsSx={{ justifyContent: 'space-between', mx: 2, my: 1 }}
          actions={
            action === SELECTED_BTN_SPLIT_BY ? (
              <>
                {automaticType === ASSIGN_BTN_AUTOMATIC && (
                  <Button variant="contained" color="primary" type="submit" disabled={!canSubmit}>
                    <SaveIcon />
                    {t('save')}
                  </Button>
                )}
                {manualType === ASSIGN_BTN_MANUAL && (
                  <div>
                    {renderedButtons}
                    <FormSwitch name={'manualUnassign'} label={t('manualUnassign')} />
                  </div>
                )}
                {automaticType !== ASSIGN_BTN_AUTOMATIC && manualType !== ASSIGN_BTN_MANUAL && (
                  <div />
                )}
                <Button
                  onClick={() => toggleDialog(false)}
                  variant="contained"
                  color="inherit"
                  sx={{ mr: 1 }}
                >
                  {t('close')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => deleteSelectedButtons()}
                  disabled={!canRemove}
                >
                  <DeleteIcon />
                  {`${t('delete')} (${selection.length}/${buttons.length})`}
                </Button>

                <Button
                  onClick={() => toggleDialog(false)}
                  variant="contained"
                  color="inherit"
                  sx={{ mr: 1 }}
                >
                  {t('close')}
                </Button>
              </>
            )
          }
        >
          {action === SELECTED_BTN_SPLIT_BY ? (
            <Grid container={true} spacing={spacing}>
              <Grid item={true} xs={12}>
                <Box sx={{ mt: 1.5, mb: 2.5 }}>
                  <Announcement label="" type="info" spaced={true} component={'div'}>
                    {manualType === ASSIGN_BTN_MANUAL
                      ? t('assignButtonManualWarning')
                      : automaticType === ASSIGN_BTN_AUTOMATIC
                      ? t('assignButtonsAutomaticWarning')
                      : null}
                    {(manualType || automaticType) && <br />}
                    {t('assignUserWarning')}
                  </Announcement>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ mb: 1 }}>
              <Announcement
                label={t('deleteInactiveButtonsWarning')}
                type={'warning'}
                spaced={true}
                component={'div'}
              />
            </Box>
          )}
          {((action === SELECTED_BTN_SPLIT_BY && manualType === ASSIGN_BTN_MANUAL) ||
            action !== SELECTED_BTN_SPLIT_BY) && (
            <MuiGrid
              gridKey={muiGridKey}
              rows={buttons}
              columns={columns}
              rowHeight={true}
              rowSelecting={{ selecting: true, selection, setSelection }}
            />
          )}
        </OverflowedDialog>
      </form>
    </FormProvider>
  );
};

export default ButtonsGrid;
