import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { IExistingSendRequest } from './RequestsSend/_types';
import { IExistingSplitRequest } from './RequestsSplit/_types';
import { IEditPatientRequest } from 'modules/websocketNotification/_types';

export const getRequest = (
  id: number,
): Promise<IExistingSendRequest | IExistingSplitRequest | IEditPatientRequest | false> =>
  api()
    .get(`/portal-api/studyRequest/get`, { params: { id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const setRequestStatus = (data: any): Promise<any | boolean> =>
  api()
    .post(`/portal-api/studyRequest/setStatus`, { ...data })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const searchRequests = (values: any): Promise<any | boolean> =>
  api()
    .post(`/portal-api/studyRequest/searchRequests`, { ...values })
    .then((response) => get(response, 'data'))
    .catch((error) => false);
