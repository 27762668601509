import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { getFormats, removeFormat, updateFormat } from './_api';
import { IFormat } from './_types';
import { FormatForm } from './FormatForm';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import Button from 'components/Buttons/Button';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'clinicalPortalFormatsMUI';

const ClinicalPortalFormatsComponent: React.FC<any> = ({ formats, setFormats }) => {
  const { t } = useTranslation('ClinicalPortalSettings');
  const dispatch = useAppDispatch();
  const { compactMode, confirmationData } = useAppInfo();

  const [showDialog, toggleDialog] = useState<boolean>(false);
  const [formatId, setFormatId] = useState<number>(0);

  const { toggleLoader } = useAppGlobals();

  const loadEntity = async () => {
    toggleLoader();
    const data = await getFormats();
    setFormats(data);
    setFormatId(0);
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeFormat = async (row: IFormat) => {
    toggleLoader();
    const changed = await updateFormat(row);
    if (changed) {
      await loadEntity();
    }
    toggleLoader(false);
  };

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
    setFormatId(0);
  };

  const onChangePrintable = async (row: IFormat, state: boolean) =>
    await changeFormat({ ...row, printable: state });

  const confirmActionChangePrintable = () => {
    onChangePrintable(get(confirmationData, 'row'), get(confirmationData, 'state'));
    clearAction();
  };

  const onChangeExportable = async (row: IFormat, state: boolean) =>
    await changeFormat({ ...row, exportable: state });

  const confirmActionChangeExportable = () => {
    onChangeExportable(get(confirmationData, 'row'), get(confirmationData, 'state'));
    clearAction();
  };

  const { onEntityRemove } = useEntityRemove(removeFormat, t, loadEntity);

  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const onEntityEdit = async (id: number) => {
    setFormatId(id);
    toggleDialog(id > 0 ? true : false);
  };

  const handleClose = () => {
    toggleDialog(false);
    setFormatId(0);
  };
  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 450,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <Box sx={{ width: '200px' }}>
                <FormControlLabel
                  sx={{ maxHeight: 30 }}
                  control={
                    <Switch
                      checked={row.printable}
                      onChange={(e, state) => {
                        dispatch(
                          storeConfirmationDataToStore({
                            title: 'Grid:confirmChangePrintable',
                            row,
                            id: get(row, 'id'),
                            state,
                            confirmAction: 'confirmActionChangePrintable',
                          }),
                        );
                      }}
                    />
                  }
                  label={t('Grid:printable') as string}
                />
              </Box>
              <Box sx={{ width: '200px' }}>
                <FormControlLabel
                  sx={{ maxHeight: 30 }}
                  control={
                    <Switch
                      checked={row.exportable}
                      onChange={(e, state) => {
                        dispatch(
                          storeConfirmationDataToStore({
                            title: 'Grid:confirmChangeExportable',
                            row,
                            id: get(row, 'id'),
                            state,
                            confirmAction: 'confirmActionChangeExportable',
                          }),
                        );
                      }}
                    />
                  }
                  label={t('Grid:exportable') as string}
                />
              </Box>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                label={t('Grid:edit')}
                onClick={() => onEntityEdit(get(row, 'id'))}
              />
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:delete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('Grid:delete')}
                onClick={() => {
                  dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }));
                }}
                disabled={
                  get(row, 'state', null) === 'NEW' ||
                  !get(row, 'canDelete', true) ||
                  get(row, 'system', false)
                }
              />
            </>
          );
        },
      },
      { field: 'name', headerName: t('tabFormatsName') },
    ]),
  );

  const id = formatId ? formatId : 'create';

  return (
    <>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setFormatId(0);
            toggleDialog(true);
          }}
          sx={{ minHeight: 22, ml: 0, mb: compactMode ? 1 : 2, lineHeight: 1 }}
        >
          <AddIcon sx={{ width: 20, height: 20, mr: 1 }} />
          {t('add')}
        </Button>
      </div>

      <MuiGrid
        gridKey={muiGridKey}
        rows={formats}
        columns={columns}
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
      />

      <OverflowedDialog
        key={'TAB_FORMAT'}
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
        withScrolling={true}
      >
        <FormatForm formatId={id} toggleDialog={toggleDialog} loadEntity={loadEntity} />
      </OverflowedDialog>

      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={
            confirmationData.confirmAction === 'confirmActionChangePrintable'
              ? confirmActionChangePrintable
              : confirmationData.confirmAction === 'confirmActionChangeExportable'
              ? confirmActionChangeExportable
              : confirmAction
          }
        />
      )}
    </>
  );
};

export default ClinicalPortalFormatsComponent;
