import React from 'react';
import { useTranslation } from 'react-i18next';
import { get, set } from 'lodash';
import { Dialog, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  CloudDownload as DownloadIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { deleteAttachment, downloadAttachment } from './_api';
import OrderDetailAttachmentForm from './OrderDetailAttachmentForm';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { apiDataToDownloadFile } from 'utils/hooks/useApp';
import useRefetchGrid from 'utils/grid/useRefetchGrid';
import { useAllowedType } from 'utils/hooks/useAllowedType';
import { isIE11, isIE12 } from 'utils/variables';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import useAlerts from 'components/Alerts/useAlerts';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { DELETE_ATTACHMENT, DOWNLOAD, VIEW_ATTACHEMENT } from 'constants/constants';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'orderDetailAttachmentsMui';

const OrderDetailAttachments: React.FC<any> = ({
  attachments,
  dialogState,
  handleDialog,
  order,
  loadOrder,
  setActiveTab,
  accessionNumber,
  patientId,
  availableRemove,
}) => {
  const { t } = useTranslation('StudyDetail');
  const { allowedType } = useAllowedType();
  const { toggleLoader } = useAppGlobals();
  const dispatch = useAppDispatch();
  const { refetchGrid } = useRefetchGrid();
  const { addErrorAlert } = useAlerts();
  const { confirmationData } = useAppInfo();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const onView = async (row: any) => {
    toggleLoader();
    const fileName = get(row, 'name');
    const checkAllowedType = allowedType(fileName);
    if (get(checkAllowedType, 'allowed', false)) {
      const data = await downloadAttachment({ accessionNumber, attachmentID: row.id, patientId });
      const blobFile = new Blob([data], {
        type: get(checkAllowedType, 'contentType', `application/octet-stream`),
      });
      set(blobFile, 'lastModifiedDate', new Date());
      set(blobFile, 'name', fileName);
      const file: File = blobFile as File;
      //
      if (!isIE11 && !isIE12) {
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      } else {
        // IE11 TO DO?
        // const msSaveOrOpenBlob = get(window, 'navigator.msSaveOrOpenBlob');
        // if (msSaveOrOpenBlob !== undefined) {
        //   msSaveOrOpenBlob(file, fileName);
        // }
      }
    } else {
      addErrorAlert(t('unableShowAttachement'));
    }
    toggleLoader(false);
  };
  const onDownload = async (row: any) => {
    toggleLoader();
    const data = await downloadAttachment({ accessionNumber, attachmentID: row.id, patientId });
    apiDataToDownloadFile(data, row.name);
    toggleLoader(false);
  };
  const onRemove = async (row: any) => {
    toggleLoader();
    const resp = await deleteAttachment({
      accessionNumber: accessionNumber,
      fileId: row.id,
      patientId: patientId,
    });
    if (resp) {
      await loadOrder();
      refetchGrid();
    }
    toggleLoader(false);
  };

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };
  const confirmAction = async () => {
    await onRemove(get(confirmationData, 'row'));
    clearAction();
  };

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 120,
        renderCell: ({ row }: GridRenderCellParams) => (
          <>
            <GridActionsCellItem
              icon={
                <Tooltip title={t(`Grid:${VIEW_ATTACHEMENT}`)}>
                  <VisibilityIcon />
                </Tooltip>
              }
              label={t(`Grid:${VIEW_ATTACHEMENT}`)}
              onClick={() => onView(row)}
              disabled={!get(row, 'canShowInBrowser', false)}
            />
            <GridActionsCellItem
              icon={
                <Tooltip title={t(`Grid:${DOWNLOAD}`)}>
                  <DownloadIcon />
                </Tooltip>
              }
              label={t(`Grid:${DOWNLOAD}`)}
              onClick={() => onDownload(row)}
            />
            {availableRemove && (
              <GridActionsCellItem
                icon={
                  <Tooltip title={t(`Grid:${DELETE_ATTACHMENT}`)}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t(`Grid:${DELETE_ATTACHMENT}`)}
                onClick={() =>
                  dispatch(
                    storeConfirmationDataToStore({
                      row,
                      removeAttachment: true,
                    }),
                  )
                }
                disabled={!get(row, 'canDelete', false)}
              />
            )}
          </>
        ),
      },
      { field: 'name', headerName: t('filename'), width: 250 },
      {
        field: 'createdWhen',
        headerName: t('createdWhen'),
        type: 'dateTime',
        valueGetter: ({ value }: any) => value && new Date(value),
      },
      {
        field: 'size',
        headerName: t('size'),
        valueGetter: (value: any, row: any) =>
          `${(get(row, 'size', 0) / 1024 / 1024).toFixed(1)} MB`,
      },
      {
        field: 'user',
        headerName: t('user'),
        valueGetter: (value: any, row: any) =>
          `${get(row, 'user.lastName', '') || ''} ${get(row, 'user.firstName', '') || ''} `,
      },
    ]),
  );

  return (
    <>
      <MuiGrid
        gridKey={muiGridKey}
        rows={attachments}
        columns={columns}
        sxPapeer={{ p: 0 }}
        initialSortMode={[{ field: 'createdWhen', sort: 'desc' }]}
      />
      {confirmationData && confirmationData.row && confirmationData.removeAttachment && (
        <ConfirmationDialog
          title={t('confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmAction}
        />
      )}
      <Dialog
        open={dialogState}
        onClose={() => handleDialog('attachments', false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">{t('dialog_attachment')}</DialogTitle>
        <DialogContent>
          {dialogState && (
            <OrderDetailAttachmentForm
              order={order}
              loadOrder={loadOrder}
              refetchGrid={refetchGrid}
              setActiveTab={setActiveTab}
              handleDialog={handleDialog}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OrderDetailAttachments;
