import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { filter, get, includes, isEmpty } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { Box, Button, Grid } from '@mui/material';
import useValidationSchema from './_form';
import { send } from './_api';
import { IDicomForm } from './_types';
import { IStudyResultForGrid } from 'modules/Search/_types';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useSubmitSearchForm } from 'utils/hooks/useSubmitSearchForm';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useFetchAddressBooksAndRenderRecipients } from 'utils/hooks/useFetchAddressBooksAndRenderRecipients';
import { SearchForm } from 'components/SeachForm/SearchForm';
import { SearchResultsMUI } from 'components/SearchResultsMUI/SearchResultsMUI';
import { useGridColumns } from 'components/SearchResultsMUI/useGridColumns';
import { Papeer } from 'components/Papeer/Papeer';
import useAlerts from 'components/Alerts/useAlerts';

export const DicomForm: React.FC<any> = ({ tourControlProps }: any) => {
  const { t } = useTranslation('Mdex');
  const { columns } = useGridColumns({});
  const { toggleLoader } = useAppGlobals();
  useWithTitle();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { renderedRecipients } = useFetchAddressBooksAndRenderRecipients(true);
  const { searchResults } = useAppInfo();

  const [rows, setRows] = useState<IStudyResultForGrid[]>([]);
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);

  const { onSubmitSearchForm } = useSubmitSearchForm(rows, setRows);

  const onSubmit = async (values: any) => {
    onSubmitSearchForm(values, columns);
  };

  const { FormSchema } = useValidationSchema(t);

  const methods = useForm<IDicomForm>({
    resolver: yupResolver(FormSchema),
    defaultValues: { institutionName: null },
  });

  const { handleSubmit, reset, watch } = methods;

  const institutionNameWatch = watch('institutionName');

  const submitHandler = async (values: any) => {
    toggleLoader();
    const filterItems = filter(rows, (item) => includes(selection, item.iid));
    const studies = filterItems.map((item) => ({
      studyInstanceUid: get(item, 'studyInstanceUid'),
      archive: { id: get(item, 'archive.id', 0) },
    }));

    const transformedValues = {
      recipient: get(values, 'institutionName.value'),
      type: 'DICOM_ARCHIVE',
      studies,
    };
    const response = await send(transformedValues);
    if (response) {
      addSuccessAlert(t('submitSendOk'));
    } else {
      addErrorAlert(t('submitSendError'));
    }
    reset();
    setSelection([]);
    toggleLoader(false);
  };

  useEffect(() => {
    if (!isEmpty(searchResults)) {
      setRows(searchResults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SearchForm
        onSubmit={onSubmit}
        useForOtherForm={true}
        tourControlPropsParent={tourControlProps}
      />
      <SearchResultsMUI
        rows={rows}
        setRows={setRows}
        actions={[]}
        selection={selection}
        setSelection={setSelection}
        disableContextMenu={true}
      />
      <Papeer sx={{ mt: 2 }}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <Grid container={true} alignItems="flex-start">
              <Grid item={true} xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flex: 1 }} data-tour="mdex-send-recipients">
                    {renderedRecipients}
                  </Box>
                  <Box data-tour="mdex-send-button">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                      disabled={isEmpty(selection) || !institutionNameWatch}
                      className="search-form-button"
                      data-tour="searchButtonTours"
                      sx={{ ml: 2 }}
                    >
                      {t('send')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Papeer>
    </>
  );
};
