import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { compact, each, get, isArray, keys, sortBy } from 'lodash';
import { v1 as uuidv1 } from 'uuid';

import { findTestStatistics } from './_api';
import { findUser } from 'modules/Administration/Users/_api';
import GlobalStatsErrorRate from './GlobalStatsErrorRate';
import GlobalStatsExecutedTests from './GlobalStatsExecutedTests';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormSelect from 'components/Form/Select/Select';
import FormAutocomplete from 'components/Form/Autocomplete/Autocomplete';
import useAlerts from 'components/Alerts/useAlerts';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { convertSearchDates } from 'utils/search';
import { joinParams } from 'utils/study';

import { Box } from '@mui/system';
import { TourTests } from './TourTests';
import Button from 'components/Buttons/Button';
import FormDatePicker from 'components/Form/Date/Date';
import {
  DATE_BTN_CUSTOM_DATE,
  DATE_BTN_LAST_MONTH,
  DATE_BTN_LAST_THREE_DAYS,
  DATE_BTN_LAST_WEEK,
  DATE_BTN_TODAY,
  DATE_BTN_YESTERDAY,
  TEST_STATS_TYPE_ERROR_RATE,
  TEST_STATS_TYPE_EXECUTED,
} from 'constants/constants';
import { useStudy } from 'utils/hooks/useStudy';
import { useStorage } from 'utils/hooks/useStorage';
import { parseISO } from 'date-fns';

const apiDateFormatWithDash = 'yyyy-MM-dd';
const apiDateFormat = 'yyyyMMdd';

const FORM_DATA_KEY = 'globalStatsForm';

const mainSx = { width: { xs: '100%', md: '50%', lg: '20%' } };

export const GlobalStats: React.FC = () => {
  const refSubmitButtom = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('Tests');
  const { addErrorAlert } = useAlerts();
  const { toggleLoader } = useAppGlobals();
  const { setPageTitle } = useWithTitle();

  let [searchParams] = useSearchParams();
  const fromDetail = searchParams.get('type') || '';

  const { linkBack } = useStudy();
  const { getItem, putItem, removeItem } = useStorage();

  const backFromDetail = linkBack();

  const [hasCustomDate, setHasCustomDate] = useState(false);
  const [types, setTypes] = useState<any[]>([]);
  const [usersWithAllOption, setUsers] = useState<any[]>([]);
  const [title, setTitle] = useState<string>('');
  const [statistics, setStatistics] = useState<any>(null);

  const [oldFromDate, setOldFromDate] = useState<any>('');
  const [oldToDate, setOldToDate] = useState<any>('');
  const [oldType, setOldType] = useState<string>('');
  const [oldUsername, setOldUsername] = useState<any>(null);

  const methods = useForm<any>({
    defaultValues: { dateFrom: '', dateTo: '' },
  });
  const { handleSubmit, reset, register, setValue, watch } = methods;
  const selectedDate = watch('date');
  const fromDate = watch('dateFrom');
  const toDate = watch('dateTo');
  const type = watch('type');
  const username = watch('username');

  const dateSelectItems = [
    DATE_BTN_TODAY,
    DATE_BTN_YESTERDAY,
    DATE_BTN_LAST_THREE_DAYS,
    DATE_BTN_LAST_WEEK,
    DATE_BTN_LAST_MONTH,
    DATE_BTN_CUSTOM_DATE,
  ].map((id) => ({ id, label: t(`SearchForm:${id}`) }));

  useEffect(() => {
    if (selectedDate === DATE_BTN_CUSTOM_DATE) {
      setHasCustomDate(true);
    } else {
      setHasCustomDate(false);

      // Set dates if selected date is not custom
      if (selectedDate) {
        const dateAttributes = convertSearchDates(selectedDate, fromDate, toDate, apiDateFormat);
        setValue('dateFrom', new Date(parseISO(dateAttributes.dateFrom)));
        setValue('dateTo', new Date(parseISO(dateAttributes.dateTo)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const triggerSubmit = () => {
    const submitButton: HTMLElement = document.querySelector('.search-form-button') as HTMLElement;
    submitButton.focus();
    submitButton.click();
  };

  const loadEntity = async () => {
    setTimeout(() => {
      triggerSubmit();
    }, 250);
  };

  const stateSetStatistics = (statistics: any, type: any = null) => {
    const studyStatistics = get(statistics, 'studyStatistics');
    let transformedStatistics;
    if (type === TEST_STATS_TYPE_EXECUTED) {
      transformedStatistics = statistics;
    } else {
      transformedStatistics = {
        ...statistics,
        studyStatistics: isArray(studyStatistics)
          ? studyStatistics.map((statistic) => ({ ...statistic, id: uuidv1() }))
          : studyStatistics,
      };
    }
    setStatistics(transformedStatistics);
  };

  const isExecutedStatsType = useMemo(() => {
    setStatistics(null);

    return type === TEST_STATS_TYPE_EXECUTED;
  }, [type]);

  useMemo(() => {
    if (
      selectedDate === 'customDate' &&
      type === TEST_STATS_TYPE_EXECUTED &&
      oldType === TEST_STATS_TYPE_ERROR_RATE
    ) {
      reset({
        type: TEST_STATS_TYPE_EXECUTED,
        date: DATE_BTN_CUSTOM_DATE,
        dateFrom: oldFromDate ? new Date(oldFromDate) : '',
        dateTo: oldToDate ? new Date(oldToDate) : '',
        username: oldUsername,
      });
    }
    if (selectedDate === 'customDate' && type === TEST_STATS_TYPE_EXECUTED) {
      setOldFromDate(fromDate);
      setOldToDate(toDate);
      setOldUsername(username);
    }
    setOldType(type);
  }, [
    selectedDate,
    fromDate,
    toDate,
    type,
    oldFromDate,
    oldToDate,
    oldType,
    username,
    oldUsername,
    reset,
  ]);

  const searchDisabled =
    isExecutedStatsType &&
    (!selectedDate || (selectedDate === DATE_BTN_CUSTOM_DATE && (!fromDate || !toDate)));

  const submitHandler = async (values: any) => {
    const type = get(values, 'type');

    const username = get(values, 'username.value');

    callPersistForm(values);
    const selectedDate = get(values, 'date', null);
    const { dateFrom: from, dateTo: to } = convertSearchDates(
      selectedDate,
      get(values, 'dateFrom', ''),
      get(values, 'dateTo', ''),
      apiDateFormatWithDash,
    );

    const requestValues = {
      ...(type !== TEST_STATS_TYPE_ERROR_RATE && username ? { username } : {}),
      ...(type !== TEST_STATS_TYPE_ERROR_RATE ? { from, to } : {}),
      type,
    };

    toggleLoader();
    try {
      const stats = await findTestStatistics(requestValues);
      if (stats) {
        stateSetStatistics(stats, type);
      }
    } catch (e) {
      stateSetStatistics(null);
      addErrorAlert(t('cannotLoadStatistics'));
    }
    toggleLoader(false);
  };

  const getEntity = async () => {
    toggleLoader();
    const title = t('globalStats');
    setTitle(title);
    setPageTitle(title);

    try {
      const types = [
        { id: TEST_STATS_TYPE_EXECUTED, label: t('executedTests') },
        { id: TEST_STATS_TYPE_ERROR_RATE, label: t('errorRate') },
      ];
      setTypes(types);
      const allOption = {
        value: null,
        label: t('allUsers'),
      };

      const users = await findUser({});
      if (users) {
        const mappedUsers = sortBy(
          compact(users).map((user: any) => ({
            value: get(user, 'username'),
            label: `${joinParams([
              user.lastName,
              user.firstName,
              user.email ? `(${user.email})` : '',
            ])}`,
          })),
          ['label'],
        );
        const usersWithAllOption = [allOption, ...(isArray(mappedUsers) ? mappedUsers : [])];
        setUsers(usersWithAllOption);
      }

      if (backFromDetail) {
        const data = getItem(FORM_DATA_KEY);
        if (data) {
          // Parse it to a javaScript object
          const values = JSON.parse(data);
          const transformedValues = {
            ...values,
            dateFrom: values.dateFrom ? new Date(values.dateFrom) : null,
            dateTo: values.dateTo ? new Date(values.dateTo) : null,
          };
          each(keys(transformedValues), (key: any) => {
            setValue(key, get(transformedValues, key));
          });
        }
      } else {
        removeItem(FORM_DATA_KEY);
        reset({
          type: fromDetail === 'errorRate' ? TEST_STATS_TYPE_ERROR_RATE : TEST_STATS_TYPE_EXECUTED,
          date: DATE_BTN_LAST_MONTH,
          dateFrom: '',
          dateTo: '',
          username: allOption,
        });
      }
      loadEntity();
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callPersistForm = (value: any) => {
    putItem(FORM_DATA_KEY, JSON.stringify(value));
  };

  // const { stepsGlobalStatistics, tourControlProps } = TourTests({
  //   hasGlobalStatsResult: !statistics?.length,
  //   statsType: type,
  // });

  const renderedSteps = () => {
    return <TourTests type="globalStatistics" statsType={type} />;
  };

  return (
    <>
      <Header title={title} TourComponent={renderedSteps()} />
      <Papeer>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitHandler)} data-tour="testsGlobalStatisticsForm">
            <input {...register('date')} type="hidden" />
            <button hidden={true} ref={refSubmitButtom} type={'submit'} />
            <Box
              sx={{
                display: 'flex',
                flexWrap: {
                  xs: 'wrap',
                  lg: 'nowrap',
                },
                paddingRight: { xs: '0px', sm: 0 },
              }}
            >
              <Box sx={mainSx}>
                <FormSelect
                  name="type"
                  label={t('statType')}
                  items={types}
                  sx={
                    isExecutedStatsType
                      ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                      : {}
                  }
                />
              </Box>
              {isExecutedStatsType && (
                <>
                  {usersWithAllOption && (
                    <Box sx={mainSx}>
                      <FormAutocomplete
                        name="username"
                        label={t('fullname')}
                        options={usersWithAllOption}
                        placeholder={t('select')}
                        sx={{ borderRadius: 0 }}
                      />
                    </Box>
                  )}
                  <Box sx={mainSx}>
                    <FormSelect
                      name="date"
                      label={t('datePeriod')}
                      items={dateSelectItems}
                      sx={{ borderRadius: 0 }}
                      clearable={true}
                      clearCallback={() => {
                        setValue('dateFrom', null);
                        setValue('dateTo', null);
                      }}
                    />
                  </Box>
                  <Box sx={mainSx}>
                    <FormDatePicker
                      name="dateFrom"
                      label={t('SearchForm:from')}
                      sx={{ borderRadius: 0 }}
                      disabled={!hasCustomDate}
                    />
                  </Box>
                  <Box sx={mainSx}>
                    <FormDatePicker
                      name="dateTo"
                      label={t('SearchForm:to')}
                      sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                      disabled={!hasCustomDate}
                    />
                  </Box>
                </>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'right',
                gap: 1,
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={searchDisabled}
                className="search-form-button"
              >
                {t('search')}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Papeer>
      {isExecutedStatsType && (
        <GlobalStatsExecutedTests
          successRate={get(statistics, 'executedTests.successRate')}
          testExecutions={get(statistics, 'executedTests.testExecutions', [])}
          dataTour="testsGlobalStatisticsGrid"
        />
      )}
      {!isExecutedStatsType && (
        <GlobalStatsErrorRate
          studyStatistics={get(statistics, 'studyStatistics', [])}
          dataTour="testsGlobalStatisticsGrid"
        />
      )}
    </>
  );
};
