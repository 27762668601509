import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';

const useValidationSchema = (t: TFunction, canEditProfile: boolean) => {
  const { /* max, */ required } = useValidators();

  const UserProfileFormSchema = yup.object().shape({
    username: required(yup.string()),
    lastName: required(yup.string()),
    firstName: required(yup.string()),
    // firstName: required(max(yup.string(), 3)),
    // viewer: required(yup.mixed()),
  });

  const formItems = [
    {
      type: 'input',
      name: 'username',
      label: t('username'),
      required: true,
      autoComplete: 'username',
      disabled: true,
    },
    {
      type: 'input',
      name: 'lastName',
      label: t('lastname'),
      required: true,
      autoComplete: 'last-name',
      disabled: !canEditProfile,
    },

    {
      type: 'input',
      name: 'firstName',
      label: t('firstname'),
      required: true,
      autoComplete: 'first-name',
      disabled: !canEditProfile,
    },
    {
      type: 'input',
      name: 'middleName',
      label: t('middlename'),
      autoComplete: 'middle-name',
      disabled: !canEditProfile,
    },
    {
      type: 'input',
      name: 'prefix',
      label: t('prefix'),
      autoComplete: 'prefix',
      disabled: !canEditProfile,
    },
    {
      type: 'input',
      name: 'suffix',
      label: t('suffix'),
      autoComplete: 'suffix',
      disabled: !canEditProfile,
    },
    {
      type: 'input',
      name: 'password',
      label: t('password'),
      inputType: 'password',
      disabled: !canEditProfile,
      autoComplete: 'new-password',
    },
    {
      type: 'input',
      name: 'confirmPassword',
      label: t('confirmPassword'),
      inputType: 'password',
      disabled: !canEditProfile,
      autoComplete: 'new-password',
    },
    {
      type: 'input',
      name: 'email',
      label: t('email'),
      disabled: !canEditProfile,
    },
    {
      type: 'input',
      name: 'icp',
      label: t('icp'),
      placeholder: t('icp'),
      disabled: !canEditProfile,
    },
    {
      type: 'select',
      name: 'viewer',
      label: t('userProfileViewer'),
      disabled: !canEditProfile,
    },
    {
      type: 'select',
      name: 'language',
      label: t('userProfileLanguage'),
      disabled: !canEditProfile,
    },
    {
      type: 'switch',
      name: 'darkMode',
      label: t('darkMode'),
    },
    {
      type: 'input',
      name: 'pomWorkPlaces',
      label: t('userProfileWorkPlaces'),
      disabled: true,
      grid: { xs: 12, lg: 9 },
    },
  ];

  return { UserProfileFormSchema, formItems };
};

export default useValidationSchema;
