import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { compact, get } from 'lodash';
import { Box, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Edit as EditIcon } from '@mui/icons-material';
import { setActiveRegisterGroup } from '../_api';
import { IRegisterGroupGroups } from './_types';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useActions } from 'utils/hooks/useActions';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'registerGroupGroups';

export const RegisterGroupGroups: React.FC<IRegisterGroupGroups> = ({ items, loadEntity }) => {
  const { t } = useTranslation('RegisterGroups');
  const { toggleLoader } = useAppGlobals();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { confirmationData } = useAppInfo();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const { storeConfirmationData } = useActions();

  const onCustomEntityDetail = (row: any) => {
    navigate(`/tags/registerGroups/${get(row, 'id')}`);
  };

  const onChangeActive = async (id: string, isActive: boolean) => {
    toggleLoader();
    const changed = await setActiveRegisterGroup(id, isActive);
    if (changed) {
      loadEntity();
    }
    toggleLoader(false);
  };

  const clearAction = () => storeConfirmationData(null);

  const confirmActionChangeActive = async () => {
    await onChangeActive(get(confirmationData, 'id'), get(confirmationData, 'state'));
    clearAction();
  };

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      compact([
        {
          field: 'actions',
          headerName: t('Grid:actions'),
          type: 'actions',
          hideable: false,
          width: 180,
          renderCell: ({ row }: GridRenderCellParams) => (
            <>
              <Box sx={{ width: '200px' }}>
                <FormControlLabel
                  sx={{ maxHeight: 30 }}
                  control={
                    <Switch
                      checked={row.active}
                      onChange={(e, state) => {
                        dispatch(
                          storeConfirmationDataToStore({
                            title: 'Grid:confirmChangeState',
                            id: get(row, 'id'),
                            state,
                            confirmAction: 'confirmActionChangeActive',
                          }),
                        );
                      }}
                    />
                  }
                  label={(row.active ? t('active') : t('inactive')) as string}
                  disabled={get(row, 'disabledRow', false)}
                />
              </Box>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                label={t('Grid:edit')}
                onClick={() => onCustomEntityDetail(row)}
                disabled={!get(row, 'canUpdate', true)}
              />
            </>
          ),
        },
        { field: 'name', headerName: t('name'), hideable: false, width: 300 },
      ]),
    ),
  );

  return (
    <>
      <MuiGrid
        gridKey={muiGridKey}
        rows={items}
        columns={columns}
        hideGridToolbarColumnsButton={true}
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
      />
      {confirmationData &&
        confirmationData.id &&
        confirmationData.confirmAction === 'confirmActionChangeActive' && (
          <ConfirmationDialog
            title={t(confirmationData.title)}
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            cancelAction={clearAction}
            confirmAction={confirmActionChangeActive}
          />
        )}
    </>
  );
};
