import { SET_LISTS, SET_TRASH } from 'constants/constants';
import { get, isArray } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { IProduct, IProductSetting } from './_types';

export const getAll = (): Promise<IProduct[]> =>
  api()
    .get(`/portal-api/product/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getProduct = (id: string): Promise<any> =>
  api()
    .get(`/portal-api/product/get`, { params: { id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editProduct = (product: IProduct): Promise<boolean> =>
  api()
    .post(`/portal-api/product/edit`, product)
    .then((response) => true)
    .catch((error) => false);

export const createProduct = (product: IProduct): Promise<boolean> =>
  api()
    .post(`/portal-api/product/create`, product)
    .then((response) => true)
    .catch((error) => false);

export const setActiveProduct = (id: string, state: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/product/setActiveState`, { params: { id, state } })
    .then((response) => true)
    .catch((error) => false);

export const removeProduct = (id: string): Promise<boolean> =>
  api()
    .get(`/portal-api/product/remove`, { params: { id } })
    .then((response) => true)
    .catch((error) => false);

export const getProductTypes = (): Promise<any> =>
  api()
    .get(`/portal-api/product/getAllProductTypes`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getProductByType = (productType: string): Promise<any> =>
  api()
    .get(`/portal-api/product/getProductsByType`, { params: { productType } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getProductByTypeAndStore = (productType: string) => async (dispatch: any) => {
  let products = null;
  let response = await getProductByType(productType);
  if (isArray(response)) {
    products = { [productType]: response };
  }
  const otherProductType = 'realisator';
  response = await getProductByType(otherProductType);
  if (isArray(response)) {
    products = { ...products, [otherProductType]: response };
  }
  const portalProduct = 'portal';
  response = await getProductByType(portalProduct);
  if (isArray(response)) {
    products = { ...products, [portalProduct]: response };
  }
  if (products) {
    dispatch({
      payload: { products },
      type: SET_LISTS,
    });
  }
};

export const getTrashForUser = (): Promise<any> =>
  api()
    .get(`/portal-api/product/getTrashForUser`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getTrashForUserAndStore = () => async (dispach: any) => {
  const trash = await getTrashForUser();
  if (trash !== false) {
    dispach({
      payload: trash,
      type: SET_TRASH,
    });
  }
};

export const getProductsByTypeAndFunction = (
  productType: string,
  searchFunction: string,
): Promise<any> =>
  api()
    .get(`/portal-api/product/getProductsByTypeAndFunction`, {
      params: { productType, function: searchFunction },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getProductsByTypeAndFunctions = (
  productType: string,
  searchFunction: any,
): Promise<any> =>
  api()
    .post(`/portal-api/product/getProductsByTypeAndFunctions`, {
      productType,
      functions: [...searchFunction],
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllDefaultSystemSettings = (): Promise<any> =>
  api()
    .get(`/portal-api/product/getAllDefaultSystemSettings`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editProductSetting = (setting: IProductSetting): Promise<boolean> =>
  api()
    .post(`/portal-api/product/editSystemSetting`, setting)
    .then((response) => true)
    .catch((error) => false);

export const getLoginUrl = (productId: string): Promise<any> =>
  api()
    .get(`/portal-api/product/getLoginURL`, { params: { productId } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);
