import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Box, Typography } from '@mui/material';
import Header from 'components/Header/Header';
import NCPeHSearchForm from './NCPeHSearchForm';
import NCPeHSearchResults from './NCPeHSearchResults';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourNCPeH } from './TourNCPeH';

export const ClinicalPortalByNCPeH: React.FC = () => {
  const { t } = useTranslation('NCPeH');
  const { compactMode, patients } = useAppInfo();
  useWithTitle(); // sets title to document

  const renderedSteps = () => {
    return <TourNCPeH canShowResults={!isEmpty(patients) && patients.length} />;
  };

  const [canShowResults, setCanShowResults] = useState<boolean>(false);

  return (
    <Typography component="div">
      <Header title={t('title')} TourComponent={renderedSteps()} />
      <Box data-tour="ncpehSearchForm">
        <NCPeHSearchForm compactMode={compactMode} setCanShowResults={setCanShowResults} />
      </Box>
      <Box data-tour="ncpehGrid">
        {!isEmpty(patients) && canShowResults && <NCPeHSearchResults items={patients} />}
      </Box>
    </Typography>
  );
};
