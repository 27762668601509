import React, { useCallback, useMemo, useState } from 'react';
import { find, get, isArray, isEmpty, isString, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

import StudyDetailSeriesForm from './StudyDetailSeriesForm';

import { useAppInfo } from 'utils/hooks/useAppInfo';

import { BatchButton } from 'components/Form/BatchButton/BatchButton';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';

import { ARCHIVE_TYPE_NAME_DPGW, DELETE_SERIE, VIEWER } from 'constants/constants';
import { stateIsSame } from 'utils/componentOptimizatons';
import { getPreview, usePreview } from 'utils/hooks/usePreview';
import { useUser } from 'utils/hooks/useUser';
import { ISerieDetail, IStudyDetailSeries } from './_types';
import { useEffect } from 'react';
import { getSeries } from './_api';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { GridSeriesMUI } from 'components/SearchResultsMUI/GridSeriesMUI';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';

const buttonStyle = { marginRight: '8px', marginBottom: '8px' };

const PlainStudyDetailSeries: React.FC<IStudyDetailSeries> = ({
  study,
  handleLifeCycle,
  previews,
  setNewPreviews,
  saveDecubits,
  setNewSaveDecubits,
  setSelectedSeries,
  setFolderDialogState,
}) => {
  const { t } = useTranslation('StudyDetail');
  const { defViewer } = useAppInfo();
  const { hasRole } = useUser();
  const canOperateWithFolders = hasRole('ROLE_CAN_OPERATE_WITH_FOLDERS');
  const { toggleLoader } = useAppGlobals();

  const [showDialog, toggleDialog] = useState<boolean>(false);
  const [selection, setSelection] = useState<any[]>([]);
  const [selectedSeriesForRemove, setSelectedSeriesForRemove] = useState<any[]>([]);
  const { products, realisator } = usePreview();
  const archiveID = get(study, 'archive.id');
  const studyID = get(study, 'studyInstanceUid');
  const patientId = get(study, 'patient.identificationNumber', '');
  const [archiveTypeName, setArchiveTypeName] = useState<string>('');
  const [series, setSeries] = useState<ISerieDetail[]>([]);

  const loadAllSeries = async () => {
    toggleLoader();
    const series = await getSeries(archiveID, studyID);
    if (isArray(series) && archiveID > 0 && isArray(products) && realisator && !isEmpty(series)) {
      const seriesWithPreviewUrl = sortBy(
        series.map((serie) => {
          return {
            ...serie,
            id: get(serie, 'uid'),
            numberSerie: isString(get(serie, 'seriesNumber'))
              ? parseInt(get(serie, 'seriesNumber'), 10)
              : 0,
            study: {
              studyInstanceUid: get(study, 'studyInstanceUid', ''),
              patientIdentificationNumber: patientId,
              archiveId: get(study, 'archive.id', ''),
            },
            previewUrl: getPreview(archiveID, get(study, 'studyInstanceUid'), serie),
          };
        }),
        ['modality', 'numberSerie'],
      );

      setSeries(seriesWithPreviewUrl as unknown as ISerieDetail[]);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadAllSeries();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMemo(() => {
    if (products && studyID && archiveID) {
      const selectedArchive = find(products, ['id', archiveID]);
      const archiveTypeName = get(selectedArchive, 'type.name', '');
      setArchiveTypeName(archiveTypeName);
    }
  }, [products, studyID, archiveID]);

  const isDpgw = archiveTypeName === ARCHIVE_TYPE_NAME_DPGW;
  // const hidePreviewForPrint = false;

  const actions = [];
  if (defViewer) {
    actions.push(VIEWER);
  }
  if (isDpgw) {
    actions.push(DELETE_SERIE);
  }

  const removeSeries = async (listOfSeries: any[]) => {
    setSelectedSeriesForRemove(listOfSeries);
    toggleDialog(true);
  };

  const addSelectSeriesToFolder = async () => {
    const listOfSeries = selection.map((item) => find(series, { uid: item }));
    setSelectedSeries(listOfSeries);
    setFolderDialogState(true);
  };
  const removeSelectSeries = async () => {
    const listOfSeries = selection.map((item) => find(series, { uid: item }));
    removeSeries(listOfSeries);
  };
  const onCustomEntityRemove = useCallback(
    async (entity: any) => {
      removeSeries([entity]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      {!isEmpty(series) && (
        <>
          {canOperateWithFolders && (
            <BatchButton
              size="small"
              onClick={() => addSelectSeriesToFolder()}
              label={t('multipleAddToFolder')}
              numberOfSelectedStudies={selection.length}
              style={buttonStyle}
            />
          )}
          {isDpgw && (
            <BatchButton
              size="small"
              onClick={() => removeSelectSeries()}
              label={t('multipleRemoveSeries')}
              numberOfSelectedStudies={selection.length}
              style={buttonStyle}
            />
          )}

          <GridSeriesMUI
            rows={series}
            previews={previews}
            setNewPreviews={setNewPreviews}
            decubits={saveDecubits}
            setNewDecubits={setNewSaveDecubits}
            selecting={canOperateWithFolders}
            setSelection={setSelection}
            customActions={
              isDpgw
                ? (row) => [
                    <GridActionsCellItem
                      icon={
                        <Tooltip title={t('deleteSerie')}>
                          <Delete />
                        </Tooltip>
                      }
                      label={t('deleteSerie')}
                      onClick={async () => {
                        await onCustomEntityRemove(row);
                      }}
                    />,
                  ]
                : undefined
            }
            showDetailPanel={true}
            handleLifeCycle={handleLifeCycle}
          />
        </>
      )}
      {showDialog && (
        <OverflowedDialog key={'edit_dialog_id'} open={true} maxWidth="md" fullWidth={true}>
          <StudyDetailSeriesForm
            toggleDialog={toggleDialog}
            study={study}
            series={selectedSeriesForRemove}
            previews={previews}
            setNewPreviews={setNewPreviews}
            decubits={saveDecubits}
            setNewDecubits={setNewSaveDecubits}
            handleLifeCycle={handleLifeCycle}
          />
        </OverflowedDialog>
      )}
    </>
  );
};

export const StudyDetailSeries = React.memo(PlainStudyDetailSeries, stateIsSame);
