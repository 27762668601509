import * as yup from 'yup';
import { TFunction } from 'i18next';
import usePatientAndStudySchema from '../Form/_formForPatientAndStudyEdit';

const useValidationSchema = (
  t: TFunction,
  validators: any,
  portalSetting: any,
  isStudyEditType: boolean,
) => {
  const { studyAndPatientEditSchema } = usePatientAndStudySchema({
    t,
    validators,
    portalSetting,
    isStudyEditType,
  });

  const StudiesEditFormSchema = yup.object().shape({
    ...studyAndPatientEditSchema,
  });

  return { StudiesEditFormSchema };
};

export default useValidationSchema;
