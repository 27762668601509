import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { stateIsSame } from 'utils/componentOptimizatons';
import { IExposures, IStudyDetailDoses } from './_types';
import { getDose } from './_api';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const getRowId = (row: any) => row.bodyPartExamined;
const muiGridKey = 'dosesMUI';

const PlainStudyDetailDoses: React.FC<IStudyDetailDoses> = ({ study }) => {
  const { t } = useTranslation('Studies');
  const { toggleLoader } = useAppGlobals();
  const [exposures, setExposures] = useState<IExposures[]>([]);

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const loadEntity = async () => {
    toggleLoader();
    const doses = await getDose(study.studyInstanceUid);
    if (doses) {
      setExposures(get(doses, 'exposures', null));
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'bodyPartExamined',
        headerName: t('bodyPartExamined'),
      },
      {
        field: 'dose',
        headerName: t('dose'),
        type: 'number',
        valueGetter: (value: any, row: any) => {
          const dlp = get(row, 'dlp', 0);
          const dap = get(row, 'dap', 0);
          return dlp > 0 ? dlp : dap;
        },
      },
    ]),
  );
  return (
    <>
      <MuiGrid
        gridKey={muiGridKey}
        rows={exposures}
        columns={columns}
        getRowId={getRowId}
        sxPapeer={{ p: 0 }}
      />
    </>
  );
};

export const StudyDetailDoses = React.memo(PlainStudyDetailDoses, stateIsSame);
