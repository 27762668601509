import React, { useEffect, useState } from 'react';
import { find, get, isEmpty, isObject } from 'lodash';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Tooltip } from '@mui/material';
import { getDocument, getDocuments } from './_api';
import { INCPeHDetail, INCPeHSearch } from './_types';
import useValidationSchema from './_formSearch';
import useAlerts from 'components/Alerts/useAlerts';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useLanguage } from 'utils/hooks/useLanguage';
import { joinParams } from 'utils/study';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { apiDataToDownloadFile } from 'utils/hooks/useApp';
import { scrollToTopForm } from 'utils/scrollTo';
import DetailIcon from '@mui/icons-material/Assignment';
import DownloadIcon from '@mui/icons-material/CloudDownload';

import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const formatDate = 'dd. MM. yyyy';
const muiGridKey = 'ncpehDetail';

export const ClinicalPortalByNCPeHDetail: React.FC<INCPeHDetail> = ({
  isFromSearch,
  parId,
  changePatients,
  setChangePatients,
}) => {
  const { t } = useTranslation('NCPeH');
  const { toggleLoader } = useAppGlobals();
  const { id } = useEntityInfo();
  const { addErrorAlert } = useAlerts();
  const { patientDocument, patients, requestForFindDocuments } = useAppInfo();
  const { currentLocale } = useLanguage();

  const linkBack = '/clinicPortal/byNCPeH?action=back';

  const patientId = parId || id;

  const [entity, setEntity] = useState<any[]>([]);
  const [patientDocuments, setPatientDocuments] = useState<any[]>([]);
  const [fieldsForPatientName, setFieldsForPatientName] = useState<any>({});
  const [fieldsForPatientInfo, setFieldsForPatientInfo] = useState<any[]>([]);
  const [fields, setFields] = useState<any[]>([]);
  const [validatorFields, setValidatorFields] = useState<any[]>([]);

  const { SearchFormSchema } = useValidationSchema(t, validatorFields);

  const methods = useForm<INCPeHSearch>({
    resolver: yupResolver(SearchFormSchema),
  });

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const { handleSubmit, reset } = methods;

  const loadPatient = async (id: any) => {
    const patient = find(patients, { id });
    if (patient) {
      setEntity(patient);
    }
  };

  const loadDocuments = async (values: any) => {
    toggleLoader();
    const documents = await getDocuments(values);
    if (documents) {
      setPatientDocuments(
        documents.map((document: any) => ({
          ...document,
          creationDate: new Date(document.creationDate),
          id: document.uuid,
          requestForFindDocuments: values,
        })),
      );
    } else {
      addErrorAlert(t('submitError'));
      setPatientDocuments([]);
    }
    toggleLoader(false);
  };

  const submitHandler = async (formValues: any) => {
    const values = { ...requestForFindDocuments, ...formValues };
    loadDocuments(values);
  };

  useEffect(() => {
    if (changePatients || id) {
      loadPatient(patientId);
      setPatientDocuments([]);
      if (isObject(setChangePatients)) {
        setChangePatients(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePatients]);

  useEffect(() => {
    if (!isEmpty(entity)) {
      const countryCode = get(entity, 'country', '');
      const tCountryCode = t(countryCode);
      const fields: any[] = [];
      const validatorFields: any[] = [];
      const items = [
        { name: 'dispensationPINCode', values: get(patientDocument, 'dispensationPINCode', {}) },
        { name: 'prescriptionId', values: get(patientDocument, 'prescriptionId', {}) },
      ];
      items.forEach((item) => {
        if (
          !isEmpty(item.values) &&
          (get(item, 'values.enabledFor') === 'ALL' ||
            get(item, 'values.enabledFor') === get(requestForFindDocuments, 'documentType'))
        ) {
          const mandatory = get(item, 'values.mandatory', false);
          const itemFormat = get(item, 'values.format', '.');
          const label = get(
            get(item, 'values.label'),
            currentLocale,
            get(get(item, 'values.label'), 'en', get(get(item, 'values.label'), 'cz', '?')),
          );
          fields.push({
            name: item.name,
            label,
            required: mandatory,
          });
          validatorFields.push({
            name: item.name,
            allowedChars: itemFormat,
            required: mandatory,
          });
        }
      });

      const fieldsForPatientName = {
        name: 'name',
        value: `${joinParams([
          get(entity, 'familyName'),
          get(entity, 'givenName'),
          `(${t('patientBirthDate')}: ${format(
            new Date(get(entity, 'birthDate', '')),
            formatDate,
          )} | ${t('patientSex')}: ${t('patient_sex_' + get(entity, 'administrativeGender'))})`,
        ])}
      `,
        lg: 6,
      };
      setFieldsForPatientName(fieldsForPatientName);

      const fieldsForPatientInfo = [
        {
          name: 'address',
          label: t('address'),
          value: joinParams(
            [
              get(entity, 'streetAddress', ''),
              joinParams([get(entity, 'postalCode', ''), get(entity, 'city', '')]),
              countryCode && tCountryCode !== `NCPeH.${countryCode}` ? t(countryCode) : countryCode,
            ],
            ', ',
          ),
        },
      ];
      setFieldsForPatientInfo(fieldsForPatientInfo);
      setFields(fields);
      setValidatorFields(validatorFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  useEffect(() => {
    if (patientDocuments) {
      scrollToTopForm('search-results', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDocuments]);

  useEffect(() => {
    reset({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestForFindDocuments]);

  const onDownload = async (row: any) => {
    toggleLoader();
    const request = {
      ...get(row, 'requestForFindDocuments', {}),
      documentIdUUID: get(row, 'uuid', null),
      repositoryId: get(row, 'repositoryId', null),
      homeCommunityId: get(row, 'hcid', null),
      targetLanguage: currentLocale,
      format: 'XML',
    };
    const data = await getDocument(request);
    if (data) {
      apiDataToDownloadFile(data, `${row.uuid}.xml`);
    } else {
      addErrorAlert(t('downloadError'));
    }

    toggleLoader(false);
  };

  const onViewPatientSummary = async (row: any) => {
    toggleLoader();
    const request = {
      ...get(row, 'requestForFindDocuments', {}),
      documentIdUUID: get(row, 'uuid', null),
      repositoryId: get(row, 'repositoryId', null),
      homeCommunityId: get(row, 'hcid', null),
      targetLanguage: currentLocale,
      format: 'HTML_STRING ',
    };
    const data = await getDocument(request);
    if (data) {
      try {
        const decodedOriginaldocument = decodeURIComponent(encodeURIComponent(window.atob(data)));
        const blob = new Blob([decodedOriginaldocument], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
        URL.revokeObjectURL(url);
      } catch (error) {
        try {
          const blob = new Blob([window.atob(data)], { type: 'text/html' });
          const url = URL.createObjectURL(blob);
          window.open(url, '_blank');
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      addErrorAlert(t('downloadError'));
    }

    toggleLoader(false);
  };

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      [
        {
          field: 'actions',
          headerName: t('Grid:actions'),
          type: 'actions',
          hideable: false,
          width: 80,
          renderCell: ({ row }: GridRenderCellParams) => {
            return (
              <>
                <GridActionsCellItem
                  icon={
                    <Tooltip title={t('Grid:showDocument')}>
                      <DetailIcon />
                    </Tooltip>
                  }
                  label="Detail"
                  onClick={() => onViewPatientSummary(row)}
                />
                <GridActionsCellItem
                  icon={
                    <Tooltip title={t('Grid:download')}>
                      <DownloadIcon />
                    </Tooltip>
                  }
                  label="Detail"
                  onClick={() => onDownload(row)}
                />
              </>
            );
          },
        },
        {
          field: 'creationDate',
          headerName: t('creationDate'),
          valueGetter: (_value: any, row: any) => {
            const value = get(row, 'creationDate', '');
            try {
              return format(new Date(value), formatDate);
            } catch (e) {
              return '';
            }
          },
        },
        { field: 'description', headerName: t('description') },
        { field: 'author', headerName: t('author') },
      ],
      250,
    ),
  );

  return (
    <>
      {entity && (
        <>
          <Papeer sx={{ p: 2, mt: 1 }}>
            <Box
              sx={{
                '@media print': {
                  display: 'none',
                },
              }}
            >
              <Header
                title={
                  isFromSearch ? get(fieldsForPatientName, 'value', '?') : t('documentsDetail')
                }
                backUrl={isFromSearch ? undefined : linkBack}
              />

              <Box
                id="patient"
                sx={{
                  '@media print': {
                    display: 'none',
                  },
                }}
              >
                {!isFromSearch && <Box>{`${get(fieldsForPatientName, 'value', '?')}`}</Box>}
                <Grid container={true} spacing={8}>
                  {fieldsForPatientInfo.map((field: any) => (
                    <Grid item={true} xs={12} {...field.grid} key={field.name}>
                      <label style={{ fontWeight: 'bold' }}>{field.label}</label>
                      <div>{field.value}</div>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>

            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(submitHandler)}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12}>
                    <Grid container={true} spacing={8} alignItems="flex-start">
                      {isEmpty(fields) ? (
                        <Grid item={true} xs={12} />
                      ) : (
                        fields.map((field: any) => (
                          <Grid item={true} xs={12} md={6} key={field.name}>
                            <FormInput {...field} />
                          </Grid>
                        ))
                      )}
                    </Grid>
                    <Box sx={{ mt: 1 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="medium"
                        className="search-form-button"
                      >
                        {t('searchDocuments')}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Papeer>
          {patientDocuments !== null && patientDocuments.length > 0 && (
            <>
              <MuiGrid
                gridKey={muiGridKey}
                rows={patientDocuments}
                columns={columns}
                initialSortMode={[{ field: 'creationDate', sort: 'desc' }]}
                rowHeight={true}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
