import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@mui/material';
import { find, get, includes, indexOf, isArray, join, omit, pick } from 'lodash';

import { getUser, editUser, createUser, userRetire } from './_api';
import { IUserForm } from './_types';

import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';

import useValidationSchema from './_formUser';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSwitch from 'components/Form/Switch/Switch';
import { Line } from 'components/Line/Line';
import { SimpleCheckboxArray } from 'components/Form/SimpleCheckboxArray/SimpleCheckboxArray';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';

import useAlerts from 'components/Alerts/useAlerts';
import { getAllWorkplaces } from '../Workplaces/_api';
import { IWorkplace } from '../Workplaces/_types';
import { useLanguage } from 'utils/hooks/useLanguage';
import { getAuthConfig } from 'modules/Login/_api';
import { useUser } from 'utils/hooks/useUser';
import { format, parseISO } from 'date-fns';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { Announcement } from 'components/Announcement/Announcement';
import { useRulesForPassword } from 'utils/hooks/useRulesForPassword';

export const UserForm: React.FC = () => {
  const { t } = useTranslation('Users');
  const { toggleLoader } = useAppGlobals();
  const { compactMode } = useAppInfo();
  const { minimalPasswordLength, passwordMustContain, passwordPolicyEnabled } =
    useRulesForPassword();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { id, isCreating } = useEntityInfo();
  const [entity, fetchEntity] = useState<IUserForm>();
  const [workplaceItems, getWorkplaceItems] = useState<IWorkplace[]>([]);
  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);
  const [linkBack, setLinkBack] = useState<string>('');
  const [userDirectoryId, setUserDirectoryId] = useState<any>(null);
  const [canAddOrEditUsers, setCanAddOrEditUsers] = useState<boolean>(false);
  const [confirmationDialogState, setConfirmationDialogState] = useState<boolean>(false);

  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { title } = useWithEntityTitle(isCreating, entity, t);
  const { currentLocale } = useLanguage();
  const { hasRole } = useUser();

  const isRetired = get(entity, 'retired', false);
  const retiredDate = get(entity, 'retiredAt', null);

  const { UserFormSchema, formItems } = useValidationSchema(
    t,
    canAddOrEditUsers,
    isCreating,
    isRetired,
  );

  const methods = useForm<IUserForm>({
    resolver: yupResolver(UserFormSchema),
  });
  const { handleSubmit, reset, register } = methods;

  const prepareValues = (values: any) => {
    const enabled = get(values, 'enabled', true);
    const workplaces = get(values, 'workPlaces', []);
    const preparedValues: any = {
      ...omit(
        values,
        get(values, 'password') === '' ? ['password', 'confirmPassword'] : ['confirmPassword'],
      ),
      workPlaces: workplaces.map((workplace: any) => {
        let id = 0;
        if (typeof get(workplace, 'id') === 'number') {
          id = get(workplace, 'id', 0);
        }
        return { ...workplace, id };
      }),
      authMethod: userDirectoryId,
      enabled: enabled === 'true' || enabled === true,
    };
    return preparedValues;
  };

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    if (get(values, 'password') !== get(values, 'confirmPassword')) {
      addErrorAlert(t('error.invalidPassword'));
    } else {
      const fn = isCreating ? createUser : editUser;
      await fn(prepareValues(values), userDirectoryId).then(
        (response) => {
          addSuccessAlert(t('saved'));
          navigate(linkBack);
        },
        (error) => {
          let tError = 'error.errorSaving';
          const errorCode = get(error, 'response.data.errorCode', '');
          const sError = get(error, 'response.data', '');
          const aErrors = get(error, 'response.data.errors', null);
          if (
            includes(
              [
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
                '20',
                '21',
                '22',
                '23',
                '24',
              ],
              errorCode,
            )
          ) {
            tError = `Login:error.passwordRecovery.${errorCode}`;
          } else if (sError === 'error.administration.user.exists') {
            tError = 'error.errorUserExists';
          } else if (aErrors) {
            const i = indexOf(aErrors, 'validate.administration.user.password.required');
            if (i >= 0) {
              tError = 'error.errorUserPasswordRequired';
            }
          }
          addErrorAlert(t(tError));
        },
      );
    }
    toggleLoader(false);
  });

  const getEntities = async () => {
    toggleLoader();
    try {
      let user: IUserForm = {
        id: 0,
        username: '',
        firstName: '',
        lastName: '',
        middleName: '',
        prefix: '',
        suffix: '',
        password: '',
        confirmPassword: '',
        email: '',
        icp: '',
        userIsInternal: false,
        userCanBeNotifiedAboutRequestActions: false,
        enabled: true,
        workPlaces: [],
      };
      const activeTab = searchParams.get('userDirectoryId') || null;
      setUserDirectoryId(activeTab);
      const authConfig = await getAuthConfig();
      const allowedAuthMethods = get(authConfig, 'allowedAuthMethods', []);
      const activetabAllowedMethod = find(allowedAuthMethods, (config) => {
        return get(config, 'id') === activeTab;
      });
      setLinkBack(`/administration/settings?tab=users&activeTabId=${activeTab}`);
      setCanAddOrEditUsers(
        activetabAllowedMethod !== undefined
          ? get(activetabAllowedMethod, 'canManage', false)
          : false,
      );
      if (!isCreating && id) {
        const entity = await getUser(
          id.toLocaleString(),
          typeof activeTab === 'string' ? activeTab : '',
        );
        const workplaces2 = get(entity, 'workPlaces', []).map((item: any) =>
          pick(item, ['id', 'text', 'code']),
        );
        user = {
          ...entity,
          workPlaces: workplaces2.map((workplace: any) => ({
            ...workplace,
            name: get(
              get(workplace, 'text', '') ? JSON.parse(get(workplace, 'text', '')) : {},
              currentLocale,
              workplace.code,
            ),
          })),
        };
        if (entity) {
          fetchEntity(user);
        }
      }
      const workplaces = await getAllWorkplaces(typeof activeTab === 'string' ? activeTab : '');
      if (isArray(workplaces)) {
        const workplaces2 = workplaces.map((item: any) => pick(item, ['id', 'text', 'code']));
        const workplaceItems = workplaces2
          ? workplaces2.map((workplace) => ({
              ...workplace,
              id: get(workplace, 'id', 0) === 0 ? get(workplace, 'code') : get(workplace, 'id'),
              name: get(
                get(workplace, 'text', '') ? JSON.parse(get(workplace, 'text', '')) : {},
                currentLocale,
                workplace.code,
              ),
            }))
          : [];
        getWorkplaceItems(workplaceItems);
      }
      reset({ ...user });
      setIsFetchingEntity(false);
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parsedDate = retiredDate && parseISO(retiredDate);
  const renderedOtherButton = (
    <>
      <span>
        &nbsp;-&nbsp;
        {t('revertInfoText', {
          retiredDate: parsedDate && format(parsedDate, 'dd.MM.yyyy'),
        })}
      </span>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => setConfirmationDialogState(true)}
        sx={{ ml: 1 }}
      >
        {t('cancelTermination')}
      </Button>
    </>
  );

  const handleCancelAction = () => {
    setConfirmationDialogState(false);
  };

  const handleCancelTermination = async () => {
    toggleLoader(true);
    const resp = await userRetire({ userId: entity?.id, revert: true });
    toggleLoader(false);
    return resp ? true : false;
  };

  const callBackCancelTermination = () => {
    getEntities();
    handleCancelAction();
  };

  return (
    <>
      {!isFetchingEntity && (
        <>
          <Header
            title={title}
            addObject={
              isRetired && hasRole('ROLE_EMPLOYMENT_MANAGEMENT') ? renderedOtherButton : null
            }
          />
          {userDirectoryId === 'db' && passwordPolicyEnabled && (
            <Box sx={{ pb: compactMode ? 1 : 2 }}>
              <Announcement
                label=""
                type="info"
                spaced={true}
                component={'ul'}
                sx={{ listStyle: 'none' }}
              >
                <li>
                  {t('Login:ruleForPasswordRecovery.minimalPasswordLength', {
                    par1: minimalPasswordLength,
                  })}
                </li>
                {passwordMustContain.length > 0 && (
                  <li>
                    {t('Login:ruleForPasswordRecovery.mustContain', {
                      par1: join(passwordMustContain, ', '),
                    })}
                  </li>
                )}
              </Announcement>
            </Box>
          )}
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <input {...register('id')} type="hidden" />
                <input {...register('enabled')} type="hidden" />
                <Grid container={true} spacing={2}>
                  {formItems.map((formItem, index) => {
                    const type = get(formItem, 'type', 'text');
                    const name = get(formItem, 'name', '');

                    return (
                      <React.Fragment key={`formItem-${index}`}>
                        {name === 'password' && <Grid item={true} xs={12} style={{ padding: 0 }} />}
                        {name === 'userIsInternal' && (
                          <Grid item={true} xs={12}>
                            <Line topMargin={0} bottomMargin={0} />
                          </Grid>
                        )}
                        <Grid item={true} xs={12} md={6} lg={3} xl={2}>
                          {type === 'switch' ? (
                            <FormSwitch
                              {...formItem}
                              disabled={searchParams.get('userDirectoryId') === 'ldap'}
                            />
                          ) : (
                            <FormInput {...formItem} />
                          )}
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                  <Grid item={true} xs={12}>
                    <Line topMargin={0} bottomMargin={0} />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <SimpleCheckboxArray
                      name="workPlaces"
                      label={t('workPlaces')}
                      items={workplaceItems}
                      defaultValue={get(entity, 'workPlaces', [])}
                      grid={compactMode ? {} : { xs: 12, md: 6, lg: 2, xl: 2 }}
                      compactMode={compactMode}
                      required={true}
                      showCheckAllButton={canAddOrEditUsers}
                      setAllCheckboxDisabled={!canAddOrEditUsers || (!isCreating && isRetired)}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Line topMargin={0} bottomMargin={0} />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <EntityButtons linkBack={linkBack} disabledSubmit={!isCreating && isRetired} />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Papeer>
        </>
      )}
      <ConfirmationDialog
        title={t('reallyCancelTermination')}
        open={confirmationDialogState}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
        cancelAction={handleCancelAction}
        confirmAction={handleCancelTermination}
        confirmCallback={callBackCancelTermination}
        successMessage={t('cancelTerminationSuccessfully')}
        errorMessage={t('errorSavingRevert')}
      >
        <Typography variant="body1">{t('afterRevert')}</Typography>
      </ConfirmationDialog>
    </>
  );
};
