import React, { useRef } from 'react';
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { get, isEmpty, isFunction } from 'lodash';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  COMPACT_MODE_FORM_ELEMENT_FONT_SIZE,
  COMPACT_MODE_FORM_ELEMENT_HEIGHT,
  COMPACT_MODE_FORM_ELEMENT_LINE_HEIGHT,
} from 'constants/constants';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setTourControlPropsToStore } from 'store/reducers/appReducer';

const SelectForm = ({
  control,
  name,
  label,
  items,
  required,
  disabled = false,
  defaultValue = '',
  sx,
  clearable = false,
  clearCallback,
}: any) => {
  const { compactMode } = useAppInfo();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const inputLabel = useRef(null);
  // const [labelWidth, setLabelWidth] = useState(0);
  const {
    field: { ref, value, ...inputProps },
    // meta: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
    defaultValue,
  });
  const {
    formState: { errors },
  } = useFormContext();
  let isError = false;
  let errorMessage = '';
  if (!isEmpty(errors) && get(errors, name) !== undefined) {
    isError = true;
    errorMessage = get(errors, `${name}.message`, '') as string;
  }

  //For managing tour logic
  const handleOpen = (element: any) => {
    setTimeout(() => {
      dispatch(setTourControlPropsToStore({ isDropDownOpen: true, currentOpenDropdown: name }));
    }, 100);
  };

  const handleClose = (element: any) => {
    setTimeout(() => {
      dispatch(setTourControlPropsToStore({ isDropDownOpen: false, currentOpenDropdown: null }));
    }, 100);
  };

  // useEffect(() => {
  //   setLabelWidth(get(inputLabel, 'current.offsetWidth', 0));
  // }, []);

  return (
    <FormControl size="small" fullWidth={true} margin="dense" error={isError} disabled={disabled}>
      {value !== undefined && value !== '' && clearable ? (
        <Box sx={{ position: 'absolute', right: 26, zIndex: 999 }}>
          <Tooltip title={t('Form:clearValue')}>
            <IconButton
              onClick={() => {
                inputProps.onChange('');
                if (isFunction(clearCallback)) {
                  clearCallback();
                }
              }}
              size={compactMode ? 'small' : 'medium'}
            >
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}
      <InputLabel
        ref={inputLabel}
        htmlFor={name}
        sx={
          compactMode
            ? {
                fontSize: COMPACT_MODE_FORM_ELEMENT_FONT_SIZE,
                lineHeight: COMPACT_MODE_FORM_ELEMENT_LINE_HEIGHT,
              }
            : {}
        }
      >
        {label} {required ? <span className="req-label">*</span> : null}
      </InputLabel>
      <Select
        onOpen={handleOpen}
        onClose={handleClose}
        id={name}
        labelId={`outlined-${name}`}
        value={value || ''}
        {...inputProps}
        label={label}
        size="small"
        inputProps={{
          name,
          id: `input_${name}`,
        }}
        inputRef={ref}
        sx={{
          ...sx,
          ...(compactMode
            ? {
                fontSize: COMPACT_MODE_FORM_ELEMENT_FONT_SIZE,
                height: COMPACT_MODE_FORM_ELEMENT_HEIGHT,
              }
            : {}),
        }}
        MenuProps={{
          classes: { paper: 'select-react-tour-class' },
        }}
      >
        {items.map((item: any) => (
          <MenuItem key={item.id} value={item.id}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default SelectForm;
