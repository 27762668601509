import React, { useMemo, useState } from 'react';
import { get, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Papeer } from 'components/Papeer/Papeer';
import FormSwitch from 'components/Form/Switch/Switch';
import { joinParams } from 'utils/study';
import { Button } from '@mui/material';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { Box } from '@mui/system';
const formatDateTime = 'dd. MM. yyyy, HH:mm:ss';
const formatDate = 'dd. MM. yyyy';

const StudyDetailPrint: React.FC<any> = ({
  printSetting,
  setPrintSetting,
  printDialogState,
  setPrintDialogState,
  study,
  comments,
  series,
  activeFacility,
  createPdfAndPrint,
}) => {
  const { t } = useTranslation('StudyDetail');
  const { toggleLoader } = useAppGlobals();
  const [isPrinting, toggleIsPrinting] = useState<boolean>(false);

  const methods = useForm({});
  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    setPrintDialogState(false);
    toggleIsPrinting(true);
    const printSetting = {
      printShowDialog: true,
      printComments: values.printComments,
      printSeries: values.printSeries,
    };
    await setPrintSetting(printSetting);
    createPdfAndPrint(printSetting);
    toggleLoader(false);
  });

  useMemo(() => {
    reset({
      printDetail: true,
      printComments: get(printSetting, 'printComments', false),
      printSeries: get(printSetting, 'printSeries', false),
    });
  }, [printSetting, reset]);

  const printObject = {
    patientName: `${joinParams([
      get(study, 'patient.name.prefix'),
      get(study, 'patient.name.lastName'),
      get(study, 'patient.name.firstName'),
      get(study, 'patient.name.middleName'),
      get(study, 'patient.name.suffix'),
    ])}`,
    referringPhysician: `${joinParams([
      get(study, 'referringPhysician.name.prefix'),
      get(study, 'referringPhysician.name.lastName'),
      get(study, 'referringPhysician.name.firstName'),
      get(study, 'referringPhysician.name.middleName'),
      get(study, 'referringPhysician.name.suffix'),
    ])}`,
    identificationNumber: get(study, 'patient.identificationNumber', '-'),
    dateBirth: get(study, 'patient.dateBirth')
      ? format(new Date(get(study, 'patient.dateBirth')), formatDate)
      : '',
    dateTime: get(study, 'dateTime')
      ? format(new Date(get(study, 'dateTime')), formatDateTime)
      : '',
    accessionNumber: get(study, 'accessionNumber') ? get(study, 'accessionNumber', '') : '',
    studyDescription: get(study, 'studyDescription', ''),
    patientSex: get(study, 'patient.sex', 'O'),
  };

  return (
    <>
      <Dialog
        open={printDialogState}
        onClose={() => {
          setPrintDialogState(false);
          toggleIsPrinting(false);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">{t('print.setting')}</DialogTitle>
        <DialogContent>
          <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
              <Grid container={true} alignItems="flex-end">
                <Grid item={true} xs={12}>
                  <FormSwitch
                    name="printDetail"
                    label={t('print_setting_detail')}
                    disabled={true}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <FormSwitch name="printComments" label={t('print_setting_comments')} />
                </Grid>
                <Grid item={true} xs={12}>
                  <FormSwitch name="printSeries" label={t('print_setting_series')} />
                </Grid>
                <Grid item={true} xs={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      displayPrint: 'none',
                    }}
                  >
                    <Button
                      onClick={() => setPrintDialogState(false)}
                      variant="contained"
                      size="large"
                      sx={{ mr: 1 }}
                    >
                      {t('close')}
                    </Button>
                    <Button variant="contained" color="primary" type="submit" size="large">
                      {t('confirm')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
      {isPrinting && (
        <Typography component="div" sx={{ display: 'none', displayPrint: 'block' }}>
          <Papeer>
            <Table sx={{ borderCollapse: 'collapse', width: '100%' }}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Grid container={true} spacing={2} justifyContent="space-between">
                      <Grid item={true}>
                        {`${t('print.facility')}: ${get(activeFacility, 'name', '')}`}
                      </Grid>
                      <Grid item={true}>
                        <Box
                          sx={{
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                            mt: 1,
                            lineHeight: 1,
                          }}
                        >
                          {get(activeFacility, 'city', '')}
                        </Box>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Table sx={{ borderCollapse: 'collapse', width: '100%' }}>
                      <TableBody>
                        <TableRow>
                          <TableCell>{t('print.patient')}</TableCell>
                          <TableCell>{t('print.patientId')}</TableCell>
                          <TableCell>{t('print.dateBirth')}</TableCell>
                          <TableCell>{t('print.sex')}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>{printObject.patientName}</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{printObject.identificationNumber}</strong>
                          </TableCell>
                          <TableCell>{printObject.dateBirth}</TableCell>
                          <TableCell>{t(`patient.sex.${printObject.patientSex}`)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Grid container={true} spacing={2} justifyContent="space-between">
                      <Grid item={true}>
                        {`${t('print.accessionNumber')}: \u00A0 ${printObject.accessionNumber}`}
                      </Grid>
                      <Grid item={true}>
                        {t('print.dateTime')}: {printObject.dateTime}
                      </Grid>
                    </Grid>
                    <div>
                      {t('studyDescription')}: {printObject.studyDescription}
                    </div>
                  </TableCell>
                </TableRow>
                {get(printSetting, 'printComments', false) && (
                  <TableRow>
                    <TableCell>{t('comments')}</TableCell>
                    <TableCell>
                      {isArray(comments) &&
                        comments.map((comment: any) => (
                          <div key={comment.id}>
                            {comments[0] !== comment && (
                              <Box
                                component="hr"
                                sx={{
                                  display: 'none',
                                  displayPrint: 'block',
                                  borderTop: '1px solid #ddd',
                                }}
                              />
                            )}
                            <Grid container={true} spacing={2} justifyContent="space-between">
                              <Grid item={true}>
                                <strong>
                                  {get(comment, 'createdBy.lastName', '')}
                                  {get(comment, 'createdBy.firstName', '')}
                                </strong>
                              </Grid>
                              <Grid item={true}>
                                <strong>
                                  {format(new Date(get(comment, 'createdWhen')), formatDateTime)}
                                </strong>
                              </Grid>
                            </Grid>
                            <Box
                              component="p"
                              sx={{
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                                mt: 1,
                                lineHeight: 1,
                              }}
                            >
                              {comment.text}
                            </Box>
                          </div>
                        ))}
                    </TableCell>
                  </TableRow>
                )}
                {get(printSetting, 'printSeries', false) && (
                  <TableRow>
                    <TableCell>{t('series')}</TableCell>
                    <TableCell>
                      {/*isArray(series) && (
                      <StudyDetailSeries
                        series={series}
                        hidePagingPanel={true}
                        hidePreviewForPrint={true}
                      />
                    )*/}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>{t('print.referringPhysician')}</TableCell>
                  <TableCell>{printObject.referringPhysician}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Papeer>
        </Typography>
      )}
    </>
  );
};

export default StudyDetailPrint;
