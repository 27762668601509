import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { Fade, CircularProgress, Button } from '@mui/material';

import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

const Loader: React.FC = () => {
  const isLoading = useSelector((state) => get(state, 'app.isLoading'));
  const showCancelButton = useSelector((state) => get(state, 'app.showCancelButton'));
  const { t } = useTranslation('Homepage');

  React.useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        (window as any).myAbortController.abort();
        (window as any).myAbortController = new AbortController();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <Fade in={isLoading}>
      <Box
        sx={{
          left: 0,
          top: 0,
          background: 'rgba(255, 255, 255, .6)',
          zIndex: 2000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          flexDirection: 'column',
        }}
      >
        <CircularProgress color="primary" size={100} />
        {showCancelButton && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              (window as any).myAbortController.abort();
              (window as any).myAbortController = new AbortController();
            }}
            sx={{ mt: 3 }}
          >
            {t('cancel')}
          </Button>
        )}
      </Box>
    </Fade>
  );
};

export default React.memo(Loader);
