import React, { useEffect, useState } from 'react';
import { get, isArray, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { getFormItems, setActiveFormItem, setBasicFormItem } from './_api';
import { FormItemForm } from './FormItemForm';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'clinicalPortalFormItemsMUI';

const ClinicalPortalFormItemsComponent: React.FC<any> = ({
  formItems,
  setFormItems,
  languages,
}) => {
  const { t } = useTranslation('ClinicalPortalSettings');
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();

  const [showDialog, toggleDialog] = useState<boolean>(false);
  const [formItemId, setFormItemId] = useState<number>(0);

  const { toggleLoader } = useAppGlobals();

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
    setFormItemId(0);
  };

  const loadEntity = async () => {
    toggleLoader();
    const data = await getFormItems();
    if (data && isArray(data)) {
      setFormItems(data.map((item: any) => ({ ...item, length: data.length })));
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeActiveFormItem = async (id: string, isActive: boolean) => {
    toggleLoader();
    const changed = await setActiveFormItem(id, isActive);
    if (changed) {
      loadEntity();
    }
    toggleLoader(false);
  };

  const confirmActionChangeActiveFormItem = () => {
    onChangeActiveFormItem(get(confirmationData, 'id'), get(confirmationData, 'state'));
    clearAction();
  };

  const onChangeBasic = async (id: string, isBasic: boolean) => {
    toggleLoader();
    const changed = await setBasicFormItem(id, isBasic);
    if (changed) {
      loadEntity();
    }
    toggleLoader(false);
  };

  const confirmActionChangeBasic = () => {
    onChangeBasic(get(confirmationData, 'id'), get(confirmationData, 'state'));
    clearAction();
  };

  const onEntityEdit = async (id: number) => {
    setFormItemId(id);
    toggleDialog(id > 0 ? true : false);
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 360,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <Box sx={{ width: '200px' }}>
                <FormControlLabel
                  sx={{ maxHeight: 30 }}
                  control={
                    <Switch
                      checked={row.basic}
                      onChange={(e, state) => {
                        dispatch(
                          storeConfirmationDataToStore({
                            title: 'Grid:confirmChangeState',
                            row,
                            id: get(row, 'id'),
                            state,
                            confirmAction: 'confirmActionChangeBasic',
                          }),
                        );
                      }}
                    />
                  }
                  label={t('Grid:basicForm') as string}
                  disabled={get(row, 'activeFacility', false) || get(row, 'disabledRow', false)}
                />
              </Box>
              <Box sx={{ width: '200px' }}>
                <FormControlLabel
                  sx={{ maxHeight: 30 }}
                  control={
                    <Switch
                      checked={row.active}
                      onChange={(e, state) => {
                        dispatch(
                          storeConfirmationDataToStore({
                            title: 'Grid:confirmChangeState',
                            row,
                            id: get(row, 'id'),
                            state,
                            confirmAction: 'confirmActionChangeActiveFormItem',
                          }),
                        );
                      }}
                    />
                  }
                  label={t('Grid:enabled') as string}
                  disabled={get(row, 'activeFacility', false) || get(row, 'disabledRow', false)}
                />
              </Box>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                label={t('Grid:edit')}
                onClick={() => onEntityEdit(get(row, 'id'))}
              />
            </>
          );
        },
      },
      { field: 'name', headerName: t('formItemName') },
      ...(languages || []).map((lang: any) => {
        return {
          field: lang.abbreviation,
          headerName: lang.name,
          valueGetter: (value: any, row: any) =>
            get(JSON.parse(row.nameTranslate), lang.abbreviation, ''),
        };
      }),
      { field: 'position', headerName: t('formItemPosition'), type: 'number', width: 100 },
    ]),
  );

  return (
    <>
      {!isEmpty(formItems) && !isEmpty(languages) && (
        <>
          <MuiGrid
            gridKey={muiGridKey}
            rows={formItems}
            columns={columns}
            initialSortMode={[{ field: 'position', sort: 'asc' }]}
          />
          <OverflowedDialog
            key={'TAB_FORM_ITEM'}
            open={showDialog}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            fullWidth={true}
            withScrolling={true}
          >
            <FormItemForm
              formItemId={formItemId}
              toggleDialog={toggleDialog}
              loadEntity={loadEntity}
              dispatch={dispatch}
              formItemsLength={formItems.length}
            />
          </OverflowedDialog>
          {confirmationData && confirmationData.id && !confirmationData.editDialog && (
            <ConfirmationDialog
              title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
              open={true}
              aria-labelledby="form-dialog-title"
              fullWidth={true}
              cancelAction={clearAction}
              confirmAction={
                confirmationData.confirmAction === 'confirmActionChangeBasic'
                  ? confirmActionChangeBasic
                  : confirmActionChangeActiveFormItem
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default ClinicalPortalFormItemsComponent;
