import { compact, forEach, get, isArray, isString, sortBy, without } from 'lodash';
import queryString from 'query-string';
import {
  STUDY_AVAILABILITY_ARCHIVED,
  STUDY_AVAILABILITY_OFFLINE,
  STUDY_AVAILABILITY_ONLINE,
} from 'constants/constants';
import { IStudy } from 'modules/Studies/StudyDetail/_types';
import { IStudy as IStudySearch, IStudyResultForGrid } from 'modules/Search/_types';

export const generateIID = (study: IStudySearch | IStudy) =>
  `${get(study, 'studyInstanceUid')}_${get(study, 'archive.id')}`;

export const decodeIID = (iid: string) => {
  const [studyID, archiveID] = iid.split('_');
  return { studyID, archiveID };
};

export const canSplitStudy = (study: IStudy) => get(study, 'numberOfStudyRelatedInstances') > 1;

export const studyCanBeArchived = (study: IStudy | IStudyResultForGrid) => {
  return getAvailability(study) === STUDY_AVAILABILITY_ONLINE;
};

export const getAvailability = (study: IStudy | IStudyResultForGrid) => get(study, 'availability');

export const getTitleForArchivingStudyIcon = (study: IStudy) => {
  let title = 'availability.unavailable';
  switch (getAvailability(study)) {
    case STUDY_AVAILABILITY_ONLINE:
      title = 'availability.online';
      break;
    case STUDY_AVAILABILITY_ARCHIVED:
      title = 'availability.archived';
      break;
    case STUDY_AVAILABILITY_OFFLINE:
      title = 'availability.offline';
      break;
  }
  return title;
};

export const joinParams = (params: any[], joiner: string = ' ') =>
  isArray(params) && isString(joiner) ? without(compact(params), ['null']).join(joiner) : null;

export const getCommentNameWithLogin = (user: any) =>
  `${joinParams([get(user, 'lastName'), get(user, 'firstName')])} (${get(user, 'username')})`;

export const getParam = (searchString: string, paramName: string) =>
  get(queryString.parse(searchString), paramName, null);

export const sortSeriesBySeriesNumber = (series: any) =>
  isArray(series)
    ? sortBy(
        series.map((serie: any) => ({
          ...serie,
          numberSerie: isNaN(get(serie, 'seriesNumber')) ? 0 : get(serie, 'seriesNumber') * 1,
        })),
        ['modality', 'numberSerie'],
      )
    : null;

export const generateUuidWithoutDots = (uid: string) => uid.replace(/\./g, '-');

export const isPatientMatching = (studies: any, patientMatching: any) => {
  let isPatientMatch = true;
  let i = 0;
  let firstPatientDateBirth: any = null;
  let firstPatientId: any = null;
  let firstPatientName: any = null;
  let firstPatientSex: any = null;
  let firstPatientUUID: any = null;
  let patientDateBirth = null;
  let patientId = null;
  let patientName = null;
  let patientSex = null;
  let patientUUID = null;
  forEach(studies, (study) => {
    if (i === 0) {
      firstPatientDateBirth = get(study, 'patient.dateBirth', null);
      firstPatientId = get(study, 'patient.identificationNumber', null);
      firstPatientName = joinParams(
        [
          get(study, 'patient.name.prefix'),
          get(study, 'patient.name.lastName'),
          get(study, 'patient.name.firstName'),
          get(study, 'patient.name.middleName'),
          get(study, 'patient.name.suffix'),
        ],
        '#',
      );
      firstPatientSex = get(study, 'patient.sex', null);
      firstPatientUUID = get(study, 'patientUUID', null);
    } else {
      patientDateBirth = get(study, 'patient.dateBirth', null);
      patientId = get(study, 'patient.identificationNumber', null);
      patientName = joinParams(
        [
          get(study, 'patient.name.prefix'),
          get(study, 'patient.name.lastName'),
          get(study, 'patient.name.firstName'),
          get(study, 'patient.name.middleName'),
          get(study, 'patient.name.suffix'),
        ],
        '#',
      );
      patientSex = get(study, 'patient.sex', null);
      patientUUID = get(study, 'patientUUID', null);
      if (
        (get(patientMatching, 'patientDateBirth', false) &&
          firstPatientDateBirth !== patientDateBirth) ||
        (get(patientMatching, 'patientID', false) && firstPatientId !== patientId) ||
        (get(patientMatching, 'patientName', false) && firstPatientName !== patientName) ||
        (get(patientMatching, 'patientSex', false) && firstPatientSex !== patientSex) ||
        (get(patientMatching, 'patientUUID', false) && firstPatientUUID !== patientUUID)
      ) {
        isPatientMatch = false;
      }
    }
    i++;
  });

  return isPatientMatch;
};
