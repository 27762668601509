import React from 'react';
import clsx from 'clsx';
import '@fortawesome/fontawesome-free/css/all.css';
import { set } from 'lodash';
import { useAppInfo } from 'utils/hooks/useAppInfo';

export const MaterialUiIcon: React.FC<any> = ({
  icon,
  selected = false,
  style,
  useDiv = false,
}) => {
  const { isDarkMode } = useAppInfo();
  if (!icon) {
    if (useDiv) {
      return <div />;
    } else {
      icon = selected ? 'fa-circle' : 'fa-dot-circle';
    }
  }

  let iconstyle = selected ? { style } : { style: {} };
  set(iconstyle, 'style.background', isDarkMode ? '#383838' : 'white');

  return <i className={clsx(`fas fa-1x ${icon}`)} style={{ ...iconstyle.style }} />;
};
