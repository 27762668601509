import * as yup from 'yup';
import { TFunction } from 'i18next';
import useValidators from 'components/Form/validations/useValidators';
import { useValidation } from 'utils/hooks/useValidation';

const useValidationSchema = (t: TFunction, validators: any) => {
  const { required } = useValidators();
  const { allMessage } = useValidation(t, validators);

  const SrFormSchema = yup.object().shape({
    seriesDescription: required(yup.string()),
    physician: yup.object().shape({
      lastName: yup
        .string()
        .notRequired()
        .test('lastName', allMessage('lastName', 'minLength'), function (value) {
          if (!!value) {
            const schema = yup.string().min(2);
            return schema.isValidSync(value);
          }
          return true;
        }),
    }),
    arrayItems: yup.array().of(
      yup.object().shape({
        text: required(yup.string()),
      }),
    ),
    language: required(yup.string()),
  });

  return { SrFormSchema };
};

export default useValidationSchema;
