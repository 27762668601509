import React, { useCallback, useMemo, useState } from 'react';
import { filter, get, isArray, pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import ExportIcon from '@mui/icons-material/ImportExport';

import { exportComment, setActiveComment } from './_api';
import StudyDetailCommentForm from './StudyDetailCommentForm';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useStudy } from 'utils/hooks/useStudy';
import { useActions } from 'utils/hooks/useActions';
import { useAppInfo } from 'utils/hooks/useAppInfo';

import { Line } from 'components/Line/Line';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import useAlerts from 'components/Alerts/useAlerts';
import { useUser } from 'utils/hooks/useUser';
import { useEffect } from 'react';
import { stateIsSame } from 'utils/componentOptimizatons';
import { IComment, IStudyDetailComments } from './_types';
import { Box } from '@mui/system';

const formatDateTime = 'dd. MM. yyyy, HH:mm:ss';

const PlainStudyDetailComments: React.FC<IStudyDetailComments> = ({ study, setStudy }) => {
  const { t } = useTranslation('StudyDetail');
  const { hasRole, user } = useUser();
  const [commentItem, setCommentItem] = useState<any>(null);
  const [dialogState, setDialogState] = useState<boolean>(false);
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { toggleLoader } = useAppGlobals();
  const { confirmationData, lastPageState } = useAppInfo();
  const { storeConfirmationData } = useActions();
  const { getCommentNameWithLogin, getStudyCommentsByStudyID, studyIsLocked } = useStudy();
  const [comments, setComments] = useState<IComment[]>([]);

  const isLocked = useMemo(
    () => studyIsLocked(study),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [study],
  );

  const [initialComment, setInitialComment] = useState<any>(null);

  const getAllComments = useCallback(async () => {
    const archiveID = get(study, 'archive.id');
    const studyID = get(study, 'studyInstanceUid');
    if (archiveID && studyID) {
      toggleLoader();
      const comments = await getStudyCommentsByStudyID(archiveID, studyID);
      setComments(comments);
      toggleLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [study]);

  // Load all comments
  useEffect(() => {
    getAllComments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [study]);

  useMemo(() => {
    const commentForm = get(lastPageState, 'form.comment', null);
    if (commentForm != null) {
      const comment = get(commentForm, 'values.comment.text', null);
      console.log('comment:', comment);
      if (comment !== null) {
        setInitialComment(comment);
        setDialogState(true);
      }
    }
  }, [lastPageState, setDialogState]);

  const onChangeActive = async (id: string) => {
    toggleLoader();
    const changed = await setActiveComment(id, false);
    if (changed) {
      await getAllComments();
    }
    toggleLoader(false);
  };
  const onExportComment = async (id: string, text: string) => {
    toggleLoader();
    const changed = await exportComment({ comment: { id, text }, study });
    if (changed) {
      addSuccessAlert(t('commentExported'));
    } else {
      addErrorAlert(t('commentNotExported'));
    }
    toggleLoader(false);
  };
  const clearAction = () => {
    storeConfirmationData(null);
  };
  const cancelAction = () => {
    clearAction();
  };
  const confirmAction = async () => {
    onChangeActive(get(confirmationData, 'id'));
    clearAction();
  };
  const confirmActionExportComment = async () => {
    onExportComment(get(confirmationData, 'id'), get(confirmationData, 'text'));
    clearAction();
  };

  const renderedComments = useMemo(() => {
    return (
      <>
        {hasRole('ROLE_COMMENTARY_ALL') || hasRole('ROLE_COMMENTARY_MINE') ? (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              setDialogState(true);
              setCommentItem(null);
            }}
            sx={{ mb: 2 }}
          >
            {t('action.addComment')}
          </Button>
        ) : null}

        {isArray(comments)
          ? comments.map((comment, index) => {
              const createdByAuthor = getCommentNameWithLogin(get(comment, 'createdBy'));
              const modifiedByAuthor = getCommentNameWithLogin(get(comment, 'modifiedBy'));
              const commentCreatedByLoggedUser =
                get(comment, 'createdBy.username') === get(user, 'sub');

              return (
                <div key={comment.id}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {get(comment, 'modifiedBy') && (
                      <Tooltip
                        title={
                          <>
                            <div>{t('modified')}:</div>
                            <div>
                              {modifiedByAuthor} -{' '}
                              {format(new Date(comment.modifiedWhen), formatDateTime)}
                            </div>
                          </>
                        }
                      >
                        <HistoryIcon sx={{ mr: 1, color: 'inherit' }} />
                      </Tooltip>
                    )}
                    <Tooltip title={t('commentCreateTitle') as String}>
                      <div>
                        {get(comment, 'createdBy.email') ? (
                          <Link
                            href={`mailto: ${comment.createdBy.email}`}
                            underline="none"
                            sx={{ color: (theme) => theme.palette.success.main }}
                          >
                            {createdByAuthor}
                          </Link>
                        ) : (
                          createdByAuthor
                        )}{' '}
                        - {format(new Date(comment.createdWhen), formatDateTime)}
                      </div>
                    </Tooltip>

                    {!isLocked && (
                      <>
                        {filter(
                          [
                            {
                              title: 'commentUpdate',
                              children: <EditIcon />,
                              onClick: () => {
                                setDialogState(true);
                                setCommentItem(comment);
                              },
                              show:
                                hasRole('ROLE_COMMENTARY_ALL') ||
                                (commentCreatedByLoggedUser && hasRole('ROLE_COMMENTARY_MINE')),
                            },
                            {
                              title: 'commentDelete',
                              children: <DeleteIcon />,
                              onClick: () => {
                                storeConfirmationData({
                                  id: comment.id,
                                });
                              },
                              show: hasRole('ROLE_COMMENTARY_ALL'),
                            },
                            {
                              title: 'commentExport',
                              children: <ExportIcon />,
                              onClick: () => {
                                storeConfirmationData({
                                  title: 'confirmExport',
                                  id: comment.id,
                                  text: comment.text,
                                  confirmAction: 'confirmActionExportComment',
                                });
                              },
                              show: hasRole('ROLE_COMMENTARY_SEND'),
                            },
                          ],
                          'show',
                        ).map((button) => (
                          <Tooltip key={button.title} title={t(button.title) as String}>
                            <IconButton
                              {...pick(button, ['children', 'onClick'])}
                              sx={{ padding: (theme) => theme.spacing(1 / 4), ml: 1 }}
                              size="large"
                            />
                          </Tooltip>
                        ))}
                      </>
                    )}
                  </Box>
                  <Box
                    sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', mt: 1, lineHeight: 1 }}
                  >
                    {comment.text}
                  </Box>
                  {++index < comments.length ? <Line topMargin={2} bottomMargin={2} /> : null}
                </div>
              );
            })
          : null}
        <Dialog
          open={dialogState}
          onClose={() => setDialogState(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="xl"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">{t('dialog_comment')}</DialogTitle>
          <DialogContent>
            <StudyDetailCommentForm
              setDialogState={setDialogState}
              commentItem={commentItem}
              getComments={getAllComments}
              study={study}
              setStudy={setStudy}
              initialComment={initialComment}
            />
          </DialogContent>
        </Dialog>
        {confirmationData && confirmationData.id && (
          <ConfirmationDialog
            title={confirmationData.title ? t(confirmationData.title) : t('confirmDelete')}
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            cancelAction={cancelAction}
            confirmAction={
              confirmationData.confirmAction === 'confirmActionExportComment'
                ? confirmActionExportComment
                : confirmAction
            }
          />
        )}
      </>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments, confirmationData, dialogState]);

  return <>{renderedComments}</>;
};

export const StudyDetailComments = React.memo(PlainStudyDetailComments, stateIsSame);
