import { useTranslation } from 'react-i18next';
import { useReactTour } from 'components/Tour/useReactTour';
import HelperComponent from 'components/Tour/HelperComponent';
import { getDropDownStep } from 'components/Tour/_dropDownStep';

export const TourShreddingHistory = () => {
  const { t } = useTranslation('Reactour');

  const allSteps = ({ isDropDownOpen, currentOpenDropdown }: any) => {
    const steps = {
      '[data-tour="shreddings-history-confirmationProposalButton"]': t(
        'shreddings.history.confirmationProposalButton',
      ),
      '[data-tour="shreddings-history-authorSelector"]': t('shreddings.history.authorSelector'),
      ...(currentOpenDropdown === 'selectUser' &&
        getDropDownStep({ isDropDownOpen, t, selector: 'autocomplete' })),

      '[data-tour="historySearchFormShreddingDate"]': t('shreddings.history.dateSelect'),
      ...(currentOpenDropdown === 'date' && getDropDownStep({ isDropDownOpen, t })),

      '[data-tour="historySearchFormShreddingDateFrom"]': t('shreddings.history.dateFrom'),
      '[data-tour="historySearchFormShreddingDateTo"]': t('shreddings.history.dateTo'),

      '[data-tour="shreddings-history-sourceShredding"]': t('shreddings.history.sourceShredding'),
      '[data-tour="shreddings-common-clear"]': t('shreddings.common.clear'),
      '[data-tour="shreddings-common-search"]': t('shreddings.history.search'),
      '[data-tour="shreddings-history-grid"]': t('shreddings.history.grid'),
      '[data-tour="common-gridReFetching"]': t('common.gridReFetching'),
      '[data-tour="common-gridColumns"]': t('common.gridColumns'),
      '[data-tour="common-gridFilters"]': t('common.gridFilters'),
    };
    return steps;
  };

  useReactTour({ stepsDefinition: allSteps });
  return <HelperComponent />;
};
