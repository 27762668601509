import { Provider } from 'react-redux';
import 'constants/translations/i18n';

import { store } from 'store/store';
import { Theme } from 'components/Theme/Theme';
import MuiXLicense from 'MuiXLicense';
import { TourProvider } from '@reactour/tour';

(window as any).myAbortController = new AbortController();

export const App = () => {
  return (
    <Provider store={store}>
      <MuiXLicense />
      <TourProvider
        steps={[]}
        keyboardHandler={(e, clickProps: any) => {
          if (e.key === 'ArrowRight') {
            clickProps.setCurrentStep(
              Math.min(clickProps.currentStep + 1, clickProps.steps.length - 1),
            );
          }
          if (e.key === 'ArrowLeft') {
            clickProps.setCurrentStep(Math.max(clickProps.currentStep - 1, 0));
          }
          if (e.key === 'Escape') {
            clickProps.setIsOpen(false);
          }
        }}
      >
        <Theme />
      </TourProvider>
    </Provider>
  );
};
