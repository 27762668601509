export const useStyles = (showDecubitWithTooltip: boolean) => {
  return {
    styleHumanBody: {
      width: '100%',
      paddingBottom: '237.44%',
      position: 'relative',
      backgroundPosition: '0 0',
      backgroundSize: 'contain',
      '& > div': {
        cursor: 'help',
        position: 'absolute',
        backgroundImage: `url(/img/body/silueta.png)`,
        '&.selected': {
          backgroundImage: 'url(/img/body/silueta-hover.png)',
        },
      },
    } as const,
    styleHumanBodyHover: {
      '& > div': {
        cursor: 'help',
        position: 'absolute',
        backgroundImage: `url(/img/body/silueta.png)`,
        '&:hover': {
          backgroundImage: 'url(/img/body/silueta-hover.png)',
          cursor: 'pointer',
        },
        '&.selected': {
          backgroundImage: 'url(/img/body/silueta-hover.png)',
        },
      },
      ///OLD VALUES
      // '& > div': {
      //   cursor: 'pointer',
      //   '&:hover': {
      //     backgroundImage: 'url(/img/body/silueta-hover.png)',
      //   },
      // },
    } as const,

    humanBody_head: {
      width: '100%',
      paddingBottom: showDecubitWithTooltip ? '35.66%' : '34.5%',
      top: 0,
      left: 0,
      backgroundSize: 'cover',
    } as const,
    humanBody_larm: {
      width: showDecubitWithTooltip ? '26.21%' : '25%',
      top: showDecubitWithTooltip ? '14.98%' : '15%',
      paddingBottom: '105%',
      left: 0,
      backgroundSize: showDecubitWithTooltip ? '381.53%' : '404%',
      backgroundPosition: showDecubitWithTooltip ? '0 27%' : '0 26.5%',
    } as const,
    humanBody_rarm: {
      width: showDecubitWithTooltip ? '26.21%' : '25%',
      top: showDecubitWithTooltip ? '14.98%' : '15%',
      paddingBottom: '105%',
      right: 0,
      backgroundSize: showDecubitWithTooltip ? '381.53%' : '404%',
      backgroundPosition: showDecubitWithTooltip ? 'right 0 top 27%' : 'right 0 top 26.5%',
    } as const,
    humanBody_body: {
      width: '47.78%',
      top: showDecubitWithTooltip ? '14.98%' : '15%',
      paddingBottom: '105%',
      left: '26%',
      backgroundSize: showDecubitWithTooltip ? '209.29%' : '205.29%',
      backgroundPosition: '50% 27%',
    } as const,
    humanBody_lleg: {
      width: '50%',
      paddingBottom: showDecubitWithTooltip ? '97%' : '96%',
      bottom: 0,
      left: showDecubitWithTooltip ? 0 : '2px',
      backgroundSize: showDecubitWithTooltip ? '200%' : '197%',
      backgroundPosition: '0 100%',
    } as const,
    humanBody_rleg: {
      width: '50%',
      paddingBottom: showDecubitWithTooltip ? '97%' : '96%',
      bottom: 0,
      right: showDecubitWithTooltip ? 0 : '2px',
      backgroundSize: showDecubitWithTooltip ? '200%' : '197%',
      backgroundPosition: 'right 0 top 100%',
    } as const,
  };
};
