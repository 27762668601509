import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';

const useValidationSchema = (t: TFunction) => {
  const { required } = useValidators();

  const NotificationTemplateFormSchema = yup.object().shape({
    notificationTemplateId: required(yup.string()),
  });

  const formItems = [
    {
      type: 'select',
      name: 'notificationTemplateId',
      label: t('reactionType'),
    },
  ];

  return { NotificationTemplateFormSchema, formItems };
};

export default useValidationSchema;
