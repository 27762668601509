import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';
import { get, isEmpty, isFunction, isString, isUndefined } from 'lodash';
import { HexColorPicker } from 'react-colorful';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getModality, editModality, createModality } from './_apiModalities';
import { IModality, IModalityForm, IModalityFormContent } from './_types';
import useValidationSchema from './_formModality';

import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSwitch from 'components/Form/Switch/Switch';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import useAlerts from 'components/Alerts/useAlerts';
import { Box } from '@mui/system';

const ModalityForm: React.FC<IModalityFormContent> = ({
  toggleDialog,
  id,
  loadEntity,
  setNewModality = null,
  isCreatingFromImport = false,
}) => {
  const { t } = useTranslation('Modalities');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const { compactMode } = useAppInfo();

  const [entity, fetchEntity] = useState<IModality>();
  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);
  const [colorPickerVisible, setColorPickerVisible] = useState<boolean>(false);

  const isCreating = id === 'create';
  const { title } = useWithEntityTitle(isCreating, entity, t);

  const { ModalityFormSchema, formItems } = useValidationSchema(t);

  const methods = useForm<IModalityForm>({
    resolver: yupResolver(ModalityFormSchema),
  });
  const { handleSubmit, reset, register, watch, setValue } = methods;
  const watchColor = watch('color');

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const fn = isCreating ? createModality : editModality;
    const response = await fn(values);
    console.log(response);
    if (response?.status) {
      addSuccessAlert(t('saved'));
      loadEntity();
      toggleDialog(false);
      if (isCreating && isCreatingFromImport) {
        if (isFunction(setNewModality)) {
          setNewModality(response.data);
        }
      }
    } else {
      addErrorAlert(t(isString(response) ? response : 'errorSaving'));
    }
    toggleLoader(false);
  });

  const getEntity = async () => {
    toggleLoader();
    try {
      let modality: IModality = {
        id: 0,
        name: '',
        description: '',
        color: '',
        show: true,
        multiframe: false,
      };

      if (!isCreating && isUndefined(entity) && id !== null) {
        const response = await getModality(id.toString());
        modality = { ...response };
        if (!isEmpty(response)) {
          fetchEntity(response);
        }
      }

      reset({ ...modality });
      setIsFetchingEntity(false);
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setColor = (color: string) => {
    setValue('color', color);
  };

  const toggleColorPicker = () => {
    setColorPickerVisible(!colorPickerVisible);
  };
  return (
    <>
      {!isFetchingEntity && (
        <>
          {!isCreatingFromImport && <Header title={title} />}
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <input {...register('id')} type="hidden" />
                <Grid container={true} alignItems="flex-end">
                  <Grid item={true} xs={12} md={6}>
                    {formItems.map((formItem, index) => {
                      const type = get(formItem, 'type', 'text');
                      const name = get(formItem, 'name', '');
                      const isRequired = get(formItem, 'required', false);
                      const label = get(formItem, 'label', '');

                      return (
                        <React.Fragment key={`formItem-${index}`}>
                          <div style={{ position: 'relative' }}>
                            {name === 'color' && (
                              <>
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    right: (theme) => theme.spacing(2),
                                    top: (theme) =>
                                      compactMode ? theme.spacing(1) : theme.spacing(2),
                                    width: (theme) => theme.spacing(4),
                                    height: (theme) => theme.spacing(3),
                                    border: '1px solid #000',
                                    zIndex: 69,
                                    cursor: 'pointer',
                                    backgroundColor: `${watchColor}`,
                                  }}
                                  onClick={toggleColorPicker}
                                />
                              </>
                            )}
                            {type === 'switch' ? (
                              <FormSwitch name={name} label={label} />
                            ) : (
                              <FormInput
                                name={name}
                                label={label}
                                type={type}
                                required={isRequired}
                              />
                            )}
                            {name === 'color' && colorPickerVisible && (
                              <HexColorPicker color={watchColor} onChange={setColor} />
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })}
                    <EntityButtons toggleDialog={toggleDialog} />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Papeer>
        </>
      )}
    </>
  );
};

export default ModalityForm;
