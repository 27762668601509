import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { AppBar, Tab, Tabs } from '@mui/material';

import ExchangeNetworks from './ExchangeNetworks';
import { Facilities } from '../Facilities/Facilities';

import { useUser } from 'utils/hooks/useUser';
import { useWithTitle } from 'utils/hooks/useWithTitle';

export const FacilitiesAndExchangeNetworks: React.FC = () => {
  const { t } = useTranslation('FacilitiesAndExchangeNetworks');
  useWithTitle(); // sets title to document
  const { hasRole } = useUser();

  let [searchParams] = useSearchParams();
  const tabFromUrl = searchParams.get('tab') || '';
  const tab = tabFromUrl
    ? isArray(tabFromUrl)
      ? tabFromUrl[0]
      : tabFromUrl
    : hasRole('ROLE_FACILITY')
    ? 'facilities'
    : hasRole('ROLE_EXCHANGE_NETWORK')
    ? 'exchangeNetworks'
    : '';

  const [activeTab, setActiveTab] = useState<string>(tab);

  return (
    <>
      <AppBar position="static" color="default" sx={{ mt: 2, mb: 2 }}>
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons={false}
        >
          {hasRole('ROLE_FACILITY') && (
            <Tab key="facilities" value="facilities" label={t('setFacilities')} />
          )}
          {hasRole('ROLE_EXCHANGE_NETWORK') && (
            <Tab key="exchangeNetworks" value="exchangeNetworks" label={t('setExchangeNetwork')} />
          )}
        </Tabs>
      </AppBar>

      {activeTab === 'facilities' && <Facilities />}
      {activeTab === 'exchangeNetworks' && <ExchangeNetworks />}
    </>
  );
};
