import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'auditLogRequestsMUI';

const LogSearchResults: React.FC = () => {
  const { t } = useTranslation('Logs');
  const { logResults } = useAppInfo();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'dateTime',
        headerName: t('dateTime'),
        valueGetter: (value: any, row: any) => {
          const dateTime = get(row, 'dateTime', null);
          return dateTime && new Date(dateTime);
        },
        type: 'dateTime',
      },
      { field: 'author', headerName: t('username') },
      { field: 'ip', headerName: t('ipAddress') },
      { field: 'activity', headerName: t('activity') },
      { field: 'subject', headerName: t('subject') },
      { field: 'typeOfObject', headerName: t('typeOfObject') },
      { field: 'patientID', headerName: t('patientID') },
      { field: 'note', headerName: t('note') },
      { field: 'requestId', headerName: t('requestId') },
    ]),
  );

  const getRowId = useCallback((row: GridRowModel) => row.customIndex, []);

  return (
    <>
      <MuiGrid
        gridKey={muiGridKey}
        getRowId={getRowId}
        rows={logResults}
        columns={columns}
        dataTour="auditLogsGrid"
        initialSortMode={[{ field: 'dateTime', sort: 'asc' }]}
      />
    </>
  );
};

export default LogSearchResults;
