import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Box, Typography } from '@mui/material';
import { getVersionAndLabel } from './_api';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { version } from 'utils/variables';
import { useWithTitle } from 'utils/hooks/useWithTitle';

export const About: React.FC = () => {
  const { t } = useTranslation('About');
  useWithTitle(); // sets title to document
  const { feConfig, feConfigFromApi } = useAppInfo();
  const showAboutImage = get(feConfig, 'showAboutImage', false);
  const [backendVersion, setBackendVersion] = useState<string>('');

  const loadEntity = async () => {
    try {
      await getVersionAndLabel()
        .then((response: any) => {
          setBackendVersion(get(response, 'version', '4.0.0'));
        })
        .catch((error: any) => console.debug(error));
    } catch (e) {
      console.debug(e);
    }
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header title={t('about')} />
      <Papeer>
        <Typography gutterBottom={true}>{t('content')}</Typography>
        <Typography>
          {t('portalVersion')} {version} / {backendVersion}
        </Typography>
        {showAboutImage && (
          <div>
            <Box
              component="img"
              src={get(feConfigFromApi, 'login.footer.label.src', '')}
              alt={t('label')}
              sx={{ maxWidth: 400, mt: 2.5 }}
            />
          </div>
        )}
        <Typography gutterBottom={true}>
          <b>UDI:</b>
          {` ${get(feConfigFromApi, 'udi', '')}`}
        </Typography>
      </Papeer>
    </>
  );
};
