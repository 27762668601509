import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
// import { StyledEngineProvider } from '@mui/material/styles';
import { Start } from 'modules/Start/Start';
import Loader from 'components/Loader/Loader';
import { useTheme } from 'themes/useTheme';
import { stateIsSame } from 'utils/componentOptimizatons';

const PlainTheme: React.FC<any> = () => {
  const { theme } = useTheme();

  useEffect(() => {
    if (window.location.pathname.indexOf('/portal') === -1) {
      window.location.href = '/portal';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {/* <StyledEngineProvider injectFirst={true}> */}
      <BrowserRouter basename="/portal">
        <Start />
        <Loader />
      </BrowserRouter>
      {/* </StyledEngineProvider> */}
    </ThemeProvider>
  );
};

export const Theme = React.memo(PlainTheme, stateIsSame);
