import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { get } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { createRegisterGroup } from '../_api';
import useValidationSchema from './_form';

// import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import useRefetchGrid from 'utils/grid/useRefetchGrid';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import useAlerts from 'components/Alerts/useAlerts';
import { ICreateRegisterGroupRequest, IRegisterGroup } from 'components/TagForm/_types';

export const RegisterGroupForm: React.FC = () => {
  const { t } = useTranslation('RegisterGroups');
  const params = useParams();
  const parentId = get(params, 'parentId', null);
  const linkBack = parentId ? `/tags/registerGroups/${parentId}` : '/tags/registerGroups';
  const { toggleLoader } = useAppGlobals();
  const { refetchGrid } = useRefetchGrid();
  const { addErrorAlert } = useAlerts();
  const { setPageTitle } = useWithTitle();

  const [title, setTitle] = useState<string>('');
  const navigate = useNavigate();

  const { RegisterGroupSchema } = useValidationSchema();

  const methods = useForm<IRegisterGroup>({
    resolver: yupResolver(RegisterGroupSchema),
  });
  const { handleSubmit, reset, register } = methods;

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const { name, active } = values;
    const registerGroup: ICreateRegisterGroupRequest = {
      name,
      ...(parentId ? { parent: { id: parentId } } : { active }),
    };

    await createRegisterGroup(registerGroup).then(
      (response) => {
        navigate(linkBack);
      },
      (error) => {
        let tError = 'errorSaveRegisterGroup';
        if (get(error, 'response.data', '').includes('RegisterGroup with code')) {
          tError = 'errorRegisterGroupExists';
        }
        addErrorAlert(t(tError));
      },
    );
    toggleLoader(false);
  });

  const getEntities = async () => {
    toggleLoader();
    try {
      let registerGroup: IRegisterGroup = {
        id: 0,
        active: true,
        name: '',
        // children: [],
        registerItems: [],
      };
      reset({ ...registerGroup });
      refetchGrid();
    } catch (e) {
      console.debug(e);
    }
    let title = parentId ? t('createSubRegisterGroups') : t('create');
    setPageTitle(title);
    setTitle(title);

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* {isCreating ? ( */}
      <>
        <Header title={title} />
        <Papeer>
          <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
              <input {...register('id')} type="hidden" />
              <input {...register('active')} type="hidden" />
              <Grid container={true}>
                <Grid item={true} xs={12} md={4} lg={3}>
                  <FormInput name="name" label={t('name')} required={true} />
                </Grid>
              </Grid>
              <EntityButtons linkBack={linkBack} />
            </form>
          </FormProvider>
        </Papeer>
      </>
    </>
  );
};
