import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { compact, difference, find, get, isArray, keys, pickBy } from 'lodash';
import { useAppGlobals } from './useAppGlobals';
import { IStudyResultForGrid } from 'modules/Search/_types';
import { getStudies, setSearchFilter } from 'modules/Search/_api';
import { generateIID } from 'utils/study';
import { encryptId } from 'utils/hooks/useApp';
import { useGridExtendedInfo } from 'components/SearchResultsMUI/useGridExtendedInfo';
import { useGridColumns } from 'components/SearchResultsMUI/useGridColumns';
import { getStudiesByStudyIdentity } from 'modules/Administration/Folders/_api';
import { useActions } from 'utils/hooks/useActions';

export const useSubmitSearchForm = (
  rows: IStudyResultForGrid[],
  setRows: React.Dispatch<React.SetStateAction<IStudyResultForGrid[]>>,
  action: string = '',
) => {
  const controller2 = useMemo(() => new AbortController(), []);
  const { toggleLoader } = useAppGlobals();
  const { storeSearchResults } = useActions();
  const columnVisibilityModel = useSelector((state) =>
    get(state, 'app.gridSettings.studyMUI.columnVisibilityModel', {}),
  );
  const allStatuses = useSelector((state) => get(state, 'app.lists.statuses', []));
  const { loadExtendedInfo } = useGridExtendedInfo({ rows, setRows });
  const { columns } = useGridColumns({ action });

  const onSubmitSearchForm = async (values: any, specificColumns: any = columns) => {
    setRows([]);
    storeSearchResults([]);
    toggleLoader(true, true);
    setSearchFilter({ sources: get(values, 'source.sources') });
    await getStudies(values, {}).then(async (studies) => {
      toggleLoader(false);
      if (isArray(studies)) {
        const rows: IStudyResultForGrid[] = [
          ...studies.map((study) => {
            const iid = generateIID(study);
            const medoroStudyFlags = get(study, 'medoroStudyFlags', []);
            const statuses = compact(
              (medoroStudyFlags ? medoroStudyFlags : []).map((studyFlag) => {
                return find(allStatuses, { uuid: studyFlag }) || null;
              }),
            );
            return { ...study, iid, hashIID: encryptId(iid), statuses };
          }),
        ];
        setRows(rows);
        storeSearchResults(rows);
        const cols = keys(pickBy(columnVisibilityModel, (item: Boolean) => item === false)).map(
          (item: any) => item,
        );
        const columnsForExtendedInfo = difference(
          specificColumns.map((item: any) => item.field), // vezmeme všechny sloupce
          cols, // vyhodíme ty, který jsou skrytý
        );
        await loadExtendedInfo(columnsForExtendedInfo, rows);
      }
    });
    toggleLoader(false);
  };

  const onStudiesInFolder = (
    searchCriteriaObject: any,
    id: any,
    apiCalledCount: any,
    setApiCalledCount: any,
    setCountOfLoadedStudies: any,
    specificColumns: any = columns,
  ) => {
    let countOfLoadedStudies = 0;
    let dynApiCalledCount = apiCalledCount;
    searchCriteriaObject.forEach(async (qr: any) => {
      const searchData = {
        studyIdentities: qr,
        folderId: id,
      };
      const studies = await getStudiesByStudyIdentity(searchData, {
        signal: controller2.signal,
      });
      if (studies && isArray(studies)) {
        const rows: IStudyResultForGrid[] = [
          ...(studies || []).map((study: any) => {
            const iid = generateIID(study);
            const medoroStudyFlags = get(study, 'medoroStudyFlags', []);
            const statuses = compact(
              (medoroStudyFlags ? medoroStudyFlags : []).map((studyFlag: any) => {
                return find(allStatuses, { uuid: studyFlag }) || null;
              }),
            );
            return {
              ...study,
              iid,
              hashIID: encryptId(iid),
              pathnameForLinkBack: window.location.pathname.replace('portal/', ''),
              statuses,
              fromFolder: true,
              folderId: id,
            };
          }),
        ];
        setRows((prevRows) => [...rows]); // pokud bude chunkování, tak takhle [...prevRows, ...rows]
        const cols = keys(pickBy(columnVisibilityModel, (item: Boolean) => item === false)).map(
          (item: any) => item,
        );
        loadExtendedInfo(
          difference(
            specificColumns.map((item: any) => item.field), // vezmeme všechny sloupce
            cols, // vyhodíme ty, který jsou skrytý
          ),
          rows,
          true,
        );
        countOfLoadedStudies += studies.length;
        setCountOfLoadedStudies(countOfLoadedStudies);
        if (apiCalledCount === 0) {
          toggleLoader(false);
        }
        dynApiCalledCount = dynApiCalledCount + 1;
        setApiCalledCount(dynApiCalledCount);
      }
    });
  };

  return { onSubmitSearchForm, onStudiesInFolder };
};
