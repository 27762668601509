import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { filter, get, isArray, isEmpty, isFunction, omit } from 'lodash';
import { Grid, Link, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { createTypeOfDocument, getTypeOfDocument, updateTypeOfDocument } from './_api';
import { IClinicalPortalSetting, ITypeOfDocumentForm } from './_types';
import useValidationSchema from './_form';

import { Papeer } from 'components/Papeer/Papeer';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import Header from 'components/Header/Header';
import useAlerts from 'components/Alerts/useAlerts';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import FormInput from 'components/Form/Input/Input';
import FormSwitch from 'components/Form/Switch/Switch';

import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';

import { Line } from 'components/Line/Line';
import { SimpleCheckboxArray } from 'components/Form/SimpleCheckboxArray/SimpleCheckboxArray';
import { MaterialUiIcon } from 'components/MaterialUiIcon/MaterialUiIcon';
import { HexColorPicker } from 'react-colorful';
import { isIE11 } from 'utils/variables';
import { ILanguage } from '../GeneralSettings/_types';
import { getAllLanguages } from '../GeneralSettings/_apiLanguages';
import { Box } from '@mui/system';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';

const compactMode = false;

export const TypeOfDocumentForm: React.FC<any> = ({
  typeOfDocumentId,
  toggleDialog,
  loadEntity,
  formats,
  dispatch,
}) => {
  const { t } = useTranslation('ClinicalPortalSettings');
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { id } = useEntityInfo();
  const [entity, fetchEntity] = useState<IClinicalPortalSetting>();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [languages, setLanguages] = useState<any[]>([]);
  const [colorPickerVisible, setColorPickerVisible] = useState<boolean>(false);

  const { title } = useWithEntityTitle(
    isCreating,
    entity,
    t,
    'createTypeOfDocument',
    'editTypeOfDocument',
  );
  const { TypeOfDocumentFormSchema } = useValidationSchema(t, languages);

  const methods = useForm<any>({
    resolver: yupResolver(TypeOfDocumentFormSchema),
  });
  const { handleSubmit, reset, register, watch, setValue } = methods;
  const watchIconName = watch('iconName');
  const watchIconColor = watch('iconColor');

  const { toggleLoader } = useAppGlobals();

  const clearAction = () => {
    toggleDialog(false);
    if (isFunction(dispatch)) {
      dispatch(storeConfirmationDataToStore(null));
    }
  };

  const prepareValues = (values: any) => {
    let translateJson = {};
    const aKey: any[] = [];
    (languages || []).forEach((lang: any) => {
      const abbr = get(lang, 'abbreviation');
      const key = `translateJson_${abbr}`;
      const value = get(values, key, null);
      translateJson = { ...translateJson, [abbr]: value };
      aKey.push(key);
    });
    const preparedValues: any = {
      ...omit(values, aKey),
      translateJson: JSON.stringify(translateJson),
    };
    return preparedValues;
  };
  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const fn = isCreating ? createTypeOfDocument : updateTypeOfDocument;
    await fn(prepareValues(values)).then(
      (response) => {
        addSuccessAlert(t('savedTypeOfDocument'));
        clearAction();
        loadEntity();
      },
      (error) => {
        let tError = 'errorSavingTypeOfDocument';
        if (get(error, 'response.data', '') === 'error.administration.typeOfDocument.exists') {
          tError = 'errorTypeOfDocumentExists';
        }
        addErrorAlert(t(tError));
      },
    );

    toggleLoader(false);
  });

  const getEntity = async () => {
    toggleLoader();
    try {
      let languages = null;
      const languagesLists: ILanguage[] = await getAllLanguages();
      if (isArray(languagesLists)) {
        languages = filter(languagesLists, { active: true });
        setLanguages(languages);
      }

      let typeOfDocument: ITypeOfDocumentForm = {
        id: 0,
        name: '',
        translateJson: '',
        allowedFormats: [],
        iconName: '',
        iconColor: '',
      };
      const entityId = typeOfDocumentId ? typeOfDocumentId : id;
      const isCreating = entityId === 'create';
      setIsCreating(isCreating);

      if (!isCreating && entityId) {
        const entity: any = await getTypeOfDocument(entityId);
        if (entity) {
          let translateJson = {};
          (languages || []).forEach((lang: any) => {
            const key = `translateJson_${get(lang, 'abbreviation')}`;
            const value = get(entity, `translateJson.${lang.abbreviation}`, '');
            translateJson = { ...translateJson, [key]: value };
          });
          typeOfDocument = { ...entity, ...translateJson };
          fetchEntity(entity);
        }
      }
      reset({ ...typeOfDocument });
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setColor = (color: string) => {
    setValue('iconColor', color);
  };

  const toggleColorPicker = () => {
    setColorPickerVisible(!colorPickerVisible);
  };

  return (
    <>
      {!isEmpty(entity) || isCreating ? (
        <Typography component="div">
          <Header title={title} />
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <input {...register('id')} type="hidden" />
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12} md={4}>
                    <FormInput
                      name="name"
                      label={t('typeOfDocumentTechnicalName')}
                      required={true}
                    />
                  </Grid>
                  <Grid item={true} xs={12} style={{ padding: 0 }} />
                  {(languages || []).map((lang: any) => (
                    <Grid item={true} xs={12} md={4} key={lang.id}>
                      <FormInput
                        name={`translateJson_${lang.abbreviation}`}
                        label={`${t('typeOfDocumentName')} - ${get(lang, 'name', '')}`}
                        required={get(lang, 'default', false)}
                      />
                    </Grid>
                  ))}
                  <Grid item={true} xs={12}>
                    <Line bottomMargin={1} />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <SimpleCheckboxArray
                      name="allowedFormats"
                      label={t('allowedFormats')}
                      items={formats}
                      defaultValue={get(entity, 'allowedFormats', [])}
                      grid={compactMode ? {} : { xs: 12, md: 6, lg: 2, xl: 2 }}
                      required={true}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Line bottomMargin={1} />
                  </Grid>
                  <Grid item={true} xs={6} md={4}>
                    <Box sx={{ position: 'relative' }}>
                      <Box
                        sx={{
                          position: 'absolute',
                          ml: 28,
                          top: compactMode ? 4 : 8,
                          fontSize: '1.5rem',
                        }}
                      >
                        <MaterialUiIcon
                          icon={watchIconName}
                          selected={true}
                          style={{
                            color: watchIconColor,
                          }}
                          useDiv={true}
                        />
                      </Box>
                      <FormInput
                        name="iconName"
                        label={t('typeOfDocumentIconName')}
                        required={true}
                      />
                    </Box>
                  </Grid>
                  <Grid item={true} xs={6} md={4}>
                    <Typography>
                      <Link
                        href={
                          isIE11
                            ? 'https://fontawesome.com/v4.7.0/icons/'
                            : 'https://fontawesome.com/icons?d=gallery&m=free'
                        }
                        target="_blank"
                        rel="noreferrer"
                        underline="none"
                        sx={{
                          mt: compactMode ? '8px' : 2,
                          color: 'inherit',
                          display: 'inline-block',
                        }}
                      >
                        <i className="fas fa-search" />
                        {` `}
                        {t('searchIcons')}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={6} md={4}>
                    <div style={{ position: 'relative' }}>
                      <Box
                        sx={{
                          position: 'absolute',
                          right: (theme) => theme.spacing(2),
                          top: (theme) => (compactMode ? theme.spacing(1) : theme.spacing(2)),
                          width: (theme) => theme.spacing(4),
                          height: (theme) => theme.spacing(3),
                          border: '1px solid #000',
                          zIndex: 69,
                          cursor: 'pointer',
                          backgroundColor: `${watchIconColor}`,
                        }}
                        onClick={toggleColorPicker}
                      />
                      <FormInput
                        name="iconColor"
                        label={t('typeOfDocumentIconColor')}
                        required={true}
                      />
                      {colorPickerVisible && (
                        <HexColorPicker color={watchIconColor} onChange={setColor} />
                      )}
                    </div>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Line bottomMargin={1} />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <FormSwitch
                      name="canUseEldax"
                      label={t('canUseEldax')}
                      defaultValue={get(entity, 'canUseEldax', false)}
                    />
                  </Grid>
                  <Grid item={true} xs={12} />
                </Grid>

                <EntityButtons toggleDialog={clearAction} />
              </form>
            </FormProvider>
          </Papeer>
        </Typography>
      ) : (
        <Typography>{t('formatNotFound')}</Typography>
      )}
    </>
  );
};
