import React, { useEffect, useState } from 'react';
import { get, isArray, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { getBookmarks, removeBookmark, updateBookmark } from './_api';
import { IBookmark } from './_types';
import { BookmarkForm } from './BookmarkForm';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import Button from 'components/Buttons/Button';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'clinicalPortalBookmarksMUI';

const ClinicalPortalBookmarksComponent: React.FC<any> = ({
  typesOfDocument,
  bookmarks,
  setBookmarks,
  currentLocale,
  languages,
}) => {
  const { t } = useTranslation('ClinicalPortalSettings');
  const dispatch = useAppDispatch();
  const { compactMode, confirmationData } = useAppInfo();

  const [showDialog, toggleDialog] = useState<boolean>(false);
  const [bookmarkId, setBookmarkId] = useState<number>(0);

  const { toggleLoader } = useAppGlobals();

  const loadEntity = async () => {
    toggleLoader();
    const data = await getBookmarks();
    if (data && isArray(data)) {
      const bookmark = data.map((item) => ({ ...item, currentLocale }));
      setBookmarks(bookmark);
    }
    setBookmarkId(0);
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
    setBookmarkId(0);
  };

  const { onEntityRemove } = useEntityRemove(
    removeBookmark,
    t,
    loadEntity,
    'deletedBookmark',
    'errorDeletingBookmark',
  );

  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const changeBookmark = async (row: IBookmark) => {
    toggleLoader();
    const changed = await updateBookmark(row);
    if (changed) {
      await loadEntity();
    }
    toggleLoader(false);
  };
  const onChangeEnabled = async (row: IBookmark, state: boolean) =>
    await changeBookmark({ ...row, enabled: state });

  const confirmActionChangeEnabled = () => {
    onChangeEnabled(get(confirmationData, 'row'), get(confirmationData, 'state'));
    clearAction();
  };

  const onEntityEdit = async (id: number) => {
    setBookmarkId(id);
    toggleDialog(id > 0 ? true : false);
  };

  const handleClose = () => {
    toggleDialog(false);
    setBookmarkId(0);
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 200,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <Box>
                <FormControlLabel
                  sx={{ maxHeight: 30 }}
                  control={
                    <Switch
                      checked={row.enabled}
                      onChange={(e, state) => {
                        dispatch(
                          storeConfirmationDataToStore({
                            title: 'Grid:confirmChangeEnabled',
                            row,
                            id: get(row, 'id'),
                            state,
                            confirmAction: 'confirmActionChangeEnabled',
                          }),
                        );
                      }}
                    />
                  }
                  label={t('Grid:enabled') as string}
                  disabled={row.system}
                />
              </Box>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                label={t('Grid:edit')}
                onClick={() => onEntityEdit(get(row, 'id'))}
              />
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:delete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('Grid:delete')}
                onClick={() => {
                  dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }));
                }}
                disabled={
                  get(row, 'state', null) === 'NEW' ||
                  !get(row, 'canDelete', true) ||
                  get(row, 'system', false)
                }
              />
            </>
          );
        },
      },
      ...(languages || []).map((lang: any) => {
        return {
          field: lang.abbreviation,
          headerName: lang.name,
          valueGetter: (value: any, row: any) => get(JSON.parse(row.name), lang.abbreviation, ''),
        };
      }),
      {
        field: 'allowedTypesOfDocument',
        headerName: t('allowedTypesOfDocument'),
        valueGetter: (value: any, row: any) => {
          const loc = get(row, 'locale', 'cs');
          const typesOfDocument = sortBy(get(row, 'allowedTypesOfDocument', []), ['name'])
            .map((item) => {
              let name = get(item, 'name', '');
              const translateJson = JSON.parse(get(item, 'translateJson', null));
              if (translateJson) {
                name = get(translateJson, loc, name);
              }
              return name;
            })
            .join(', ');
          return typesOfDocument;
        },
      },
      { field: 'position', headerName: t('bookmarksPosition'), type: 'number', width: 100 },
    ]),
  );

  return (
    <>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setBookmarkId(0);
            toggleDialog(true);
          }}
          sx={{ minHeight: 22, ml: 0, mb: compactMode ? 1 : 2, lineHeight: 1 }}
        >
          <AddIcon sx={{ width: 20, height: 20, mr: 1 }} />
          {t('add')}
        </Button>
      </div>
      <MuiGrid
        gridKey={muiGridKey}
        rows={bookmarks}
        columns={columns}
        initialSortMode={[{ field: 'position', sort: 'asc' }]}
      />

      <OverflowedDialog
        key={'TAB_BOOKMARK'}
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
        withScrolling={true}
      >
        <BookmarkForm
          bookmarkId={bookmarkId || 'create'}
          toggleDialog={toggleDialog}
          loadEntity={loadEntity}
          typesOfDocument={typesOfDocument}
        />
      </OverflowedDialog>

      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={
            confirmationData.confirmAction === 'confirmActionChangeEnabled'
              ? confirmActionChangeEnabled
              : confirmAction
          }
        />
      )}
    </>
  );
};

export default ClinicalPortalBookmarksComponent;
