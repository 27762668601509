import { get, sortBy } from 'lodash';
import { createOrUpdateRegisterItem } from 'modules/Studies/StudyDetail/_api';
import { useEffect, useState } from 'react';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import {
  INewTagAutocomplete,
  IRegisterGroup,
  IRegisterItem,
  ITagAutocomplete,
  ITagFormHookProps,
} from '../_types';
import useAlerts from 'components/Alerts/useAlerts';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FilterOptionsState, createFilterOptions } from '@mui/material';
import { IAutocompleteOption } from 'components/Form/Autocomplete/_types';
import { getRegisterGroup } from 'modules/Tags/_api';

export const useAddTagForm = (args: ITagFormHookProps, isExpandableStructure = true) => {
  const { toggleLoader } = useAppGlobals();
  const { addSuccessAlert, addErrorAlert } = useAlerts();
  const { t } = useTranslation();
  const [selectedData, setSelectedData] = useState<ITagAutocomplete | INewTagAutocomplete>();
  const [selectedItem, setSelectedItem] = useState<IRegisterItem>();
  const { setValue } = useFormContext();
  const [updatedOptions, setUpdatedOptions] = useState<ITagAutocomplete[]>();
  const [showNested, setShowNested] = useState<{ [key: string]: boolean }>({});

  const { parentSelectedItem, isRootLevel } = args;

  //Create options for autocomplete fields
  const createRegisterItemsOptions = (tag: IRegisterGroup) => {
    //For Root level show all options
    if (isRootLevel) {
      return sortBy(
        tag.registerItems?.map((tagItem: IRegisterItem) => ({
          value: tagItem.id || -1,
          label: tagItem.name || '',
          labelLowerCase: tagItem.name.toLowerCase() || '',
          tagItem,
        })) || [],
        ['labelLowerCase'],
      );
    }

    //Update option if user added tag through autocomplete
    if (updatedOptions && updatedOptions.length > 0) return updatedOptions;

    //For non-root autocomplete filter options
    return sortBy(
      filterRegisterItems(tag).map((tagItem: IRegisterItem) => ({
        value: tagItem.id || -1,
        label: tagItem.name || '',
        labelLowerCase: tagItem.name.toLowerCase() || '',
        tagItem,
      })),
      ['labelLowerCase'],
    );
  };

  const filterRegisterItems = (tag: IRegisterGroup): IRegisterItem[] => {
    return (
      tag.registerItems?.filter((tagItem: IRegisterItem) => {
        return tagItem.parentRegisterItems.some(
          (item: IRegisterItem) => item?.name === parentSelectedItem?.name,
        );
      }) || []
    );
  };

  //Handling when user type its own value
  const customHandleOnChange = async (
    data: ITagAutocomplete | INewTagAutocomplete,
    tag: IRegisterGroup,
  ) => {
    setSelectedData(data);

    //Check null and if the type is INewTagAutocomplete
    if (data && 'isNewItem' in data) {
      toggleLoader();

      const resp = await createOrUpdateRegisterItem({
        name: get(data, 'newValue'),
        active: true,
        registerGroupId: tag.id,
        parentRegisterItems: parentSelectedItem ? [parentSelectedItem] : [],
      });

      const updatedGroup: IRegisterGroup = await getRegisterGroup(String(tag.id));
      setUpdatedOptions(createRegisterItemsOptions(updatedGroup));

      if (resp) {
        addSuccessAlert(t('StudyTags:registerItemSaved', { registerItemName: get(resp, 'name') }));
      } else {
        addErrorAlert(t('StudyTags:errorSaveRegisterItem'));
      }

      setValue(`registerItems.${tag.name}`, {
        value: get(resp, 'id'),
        label: get(resp, 'name'),
        tagItem: resp,
      });

      toggleLoader(false);
    }
  };

  //For filtering options + used for creating new IRegisterItem manually
  const customFilterOption = (
    options: IAutocompleteOption[],
    params: FilterOptionsState<IAutocompleteOption>,
  ): IAutocompleteOption[] => {
    const filter = createFilterOptions<any>();
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some((option) => inputValue === option.label);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        newValue: `${inputValue}`,
        label: `${t('StudyTags:create')} "${inputValue}"`,
        isNewItem: true,
      });
    }
    return filtered;
  };

  //If the group tag has selected value, toggle children of group tag
  const toggleNested = (tag: IRegisterGroup, show: boolean) => {
    const newShowState = { ...showNested };

    if (show) {
      newShowState[tag.name] = true;
    } else {
      // Get and Reset nested states to hidden
      const childNames = getChildNames(tag);
      childNames.forEach((childName) => {
        newShowState[childName] = false;
        // Reset the values in the form for each child
        setValue(`registerItems.${childName}`, null);
      });
    }

    setShowNested(newShowState);
  };

  //Used for resetting values of children when parent group tag is deselected
  const getChildNames = (tag: IRegisterGroup): string[] => {
    let names = [tag.name];
    if (tag.children) {
      for (let child of tag.children) {
        names = names.concat(getChildNames(child));
      }
    }
    return names;
  };

  const onSelectShowChildren = (tag: IRegisterGroup) => {
    return (value: IAutocompleteOption | null) => {
      if (isExpandableStructure) {
        toggleNested(tag, !!value);
      }
    };
  };

  useEffect(() => {
    setSelectedItem(get(selectedData, 'tagItem'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  return {
    customHandleOnChange,
    customFilterOption,
    updatedOptions,
    onSelectShowChildren,
    selectedItem,
    createRegisterItemsOptions,
    expandableStructure: {
      isExpandableStructure,
      showNested,
      toggleNested,
    },
  };
};
