import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';

const useValidationSchema = (t: TFunction) => {
  const { required, hexaColor } = useValidators();

  const ExchangeNetworkFormSchema = yup.object().shape({
    color: hexaColor(required(yup.string())),
  });

  const formItems = [
    {
      name: 'description',
      label: t('description'),
    },
    {
      name: 'ip',
      label: t('ip'),
    },
    {
      name: 'port',
      label: t('port'),
    },
    {
      name: 'urlForUpdate',
      label: t('urlForUpdate'),
    },
    {
      name: 'color',
      label: t('color'),
      required: true,
    },
  ];

  return { ExchangeNetworkFormSchema, formItems };
};

export default useValidationSchema;
