import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { IStudy, IStudyUpdate } from './_types';

export const getAllStudies = (): Promise<IStudy[]> =>
  api()
    .get(`/portal-api/studyDatabaseAdministration/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const studyUpdate = (data: IStudyUpdate): Promise<boolean> =>
  api()
    .put(`/portal-api/studyDatabaseAdministration/studyUpdate`, data)
    .then((response) => true)
    .catch((error) => false);
