import { find, get } from 'lodash';

let attributes = {};

export const useSrDictionary = () => {
  const getTagValue = (tag: string) => {
    const tagObject = find(attributes, ['tag', tag]);
    return get(tagObject, 'value', null);
  };

  const calculateBMI = () => {
    const height = getTagValue('(0010,1020)');
    const weight = getTagValue('(0010,1030)');
    if (
      height !== undefined &&
      height !== null &&
      weight !== undefined &&
      weight !== null &&
      height > 0
    ) {
      return weight / (height * height);
    }
    return 'n/a';
  };

  const calculateAge = () => {
    const patientBirthStr: any = getTagValue('(0010,0030)');
    if (patientBirthStr !== null) {
      if (/^(\d){8}$/.test(patientBirthStr)) {
        const y = patientBirthStr.substr(0, 4);
        const m = patientBirthStr.substr(4, 2);
        const d = patientBirthStr.substr(6, 2);
        const ageDifMs = new Date().getTime() - new Date(y, m, d).getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970).toString();
      }
    }
    return 'n/a';
  };

  const replaceExpression = (expressioncontent: string, isExpression: boolean) => {
    switch (expressioncontent) {
      case 'age':
        return calculateAge();
      case 'bmi':
        return calculateBMI();
      default:
        const dicomTag = expressioncontent.match(/\(.*?\)/)
          ? expressioncontent
          : `(${expressioncontent})`;
        const dicomTagValue = getTagValue(dicomTag);
        if (dicomTagValue !== null) {
          if (isExpression) {
            return `'${dicomTagValue}'`;
          } else {
            return dicomTagValue;
          }
        }
        return 'n/a';
    }
  };

  const regex = /\${([^}]*)\}/;

  const fillStringWithData: any = (value: any, isExpression: boolean) => {
    const dollarCount = (value.match(/\$/g) || []).length;

    const expression = (regex.exec(value) || [])[0];
    const expressionContent = (regex.exec(value) || [])[1];

    const replacedExpression = value.replace(
      expression,
      replaceExpression(expressionContent, isExpression),
    );
    if (dollarCount > 1) {
      return fillStringWithData(replacedExpression, isExpression);
    }
    return replacedExpression;
  };
  const evalReplType = (repl: any) => {
    return fillStringWithData(repl, false);
  };
  const evalExprType = (expr: string) => {
    let dataExpression = fillStringWithData(expr, true);

    const regexbrackets = /\b(\w*string\w*)\b\(([^)]*)\)/;
    const expression = regexbrackets.exec(dataExpression);
    if (expression !== null) {
      dataExpression = dataExpression.replace(expression[0], expression[2]);
    }
    try {
      // eslint-disable-next-line no-new-func
      return Function('"use strict";return (' + dataExpression + ')')();
    } catch (e) {
      console.error(e);
      return dataExpression;
    }
  };

  return {
    evalReplType,
    evalExprType,
  };
};
