import axios from 'axios';
import { getItem, removeItem } from './useStorage';

export const api = (settings = {}) => {
  const storageToken: string = getItem('token') as string;
  const storageState: string = getItem('openidState') as string;
  const storageCode: string = getItem('openidCode') as string;

  let api;
  if (storageState && storageCode) {
    const encCode = Buffer.from(storageCode).toString('base64');
    const encState = Buffer.from(storageState).toString('base64');
    removeItem('openidState');
    removeItem('openidCode');
    api = axios.create({
      ...{
        headers: {
          'IDM-code': `${encCode}`,
          'IDM-state': `${encState}`,
          'Content-Type': 'application/json',
        },
      },
      ...settings,
    });
  } else {
    api = axios.create({
      ...(storageToken
        ? {
            headers: {
              Authorization: `Bearer ${storageToken}`,
              'Content-Type': 'application/json',
            },
          }
        : { headers: { 'Content-Type': 'application/json' } }),
      ...settings,
    });
  }

  return api;
};
