import { useState } from 'react';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { isFunction } from 'lodash';

import useRefetchGrid from 'utils/grid/useRefetchGrid';
import { TFunction } from 'i18next';
import useAlerts from 'components/Alerts/useAlerts';

export const useEntityRemove3 = (
  removeFn: (x: any, y: any) => object,
  t: TFunction,
  loadEntity?: (id: any) => object,
) => {
  const { toggleLoader } = useAppGlobals();
  const [lastRemoved, setLastRemoved] = useState<any>(null);
  const { refetchGrid } = useRefetchGrid();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const onEntityRemove3 = async (id1: any, id2: any) => {
    toggleLoader();
    const removed = await removeFn(id1, id2);
    if (removed) {
      if (isFunction(loadEntity)) {
        await loadEntity(id1);
      } else {
        refetchGrid();
      }
      addSuccessAlert(t('deletedDocument'));
      setLastRemoved(new Date());
    } else {
      addErrorAlert(t('errorDeletingDocument'));
    }
    toggleLoader(false);
    return removed ? true : false;
  };

  return { lastRemoved, onEntityRemove3 };
};
