import { Theme } from '@mui/material';

const styles = {
  icon: {
    fontSize: 16,
  },
  startButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: ({ spacing }: Theme) => ({
    marginRight: spacing(),
  }),
  label: {
    fontWeight: 'bold',
  },
  linkTutorial: {
    color: 'inherit',
    display: 'inline-block',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  headerWrapperFixed: ({ spacing, palette }: Theme) => ({
    top: spacing(8),
    zIndex: 1300,
    position: 'sticky',
    // paddingLeft: spacing(2),
    // paddingRight: spacing(2),
    // marginLeft: spacing(-2),
    // marginRight: spacing(-2),
    paddingY: 1,
    backgroundColor: palette.mode === 'dark' ? '#303030' : '#f9f9f9',
  }),
  headerWrapperFixedForCompactMode: ({ spacing }: Theme) => ({
    top: spacing(5),
  }),
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 2,
  },
  headerRightWrapper: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
  timeWrapper: ({ spacing }: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(2),
  }),
  time: ({ spacing }: Theme) => ({
    fontSize: 16,
    marginLeft: spacing(),
  }),
  finishButton: ({ spacing }: Theme) => ({
    marginLeft: spacing(),
    marginRight: spacing(),
  }),
  question: ({ spacing }: Theme) => ({
    fontSize: 22,
    fontWeight: 'bold',
    // marginTop: spacing(2),
    display: 'flex',
    alignItems: 'center',
  }),
  questionClickable: {
    cursor: 'pointer',
  },
  questionIcon: {
    fontSize: 36,
  },
  openAllButton: ({ spacing }: Theme) => ({
    marginLeft: spacing(2),
  }),
  openAllButtonIcon: {
    width: 14,
    height: 14,
  },
  errorMessage: ({ spacing }: Theme) => ({
    marginBottom: spacing(),
  }),
  questionOrder: ({ spacing }: Theme) => ({
    display: 'inline-block',
    marginRight: spacing(2),
  }),
  answers: ({ spacing }: Theme) => ({
    marginLeft: spacing(4),
    width: '100%',
  }),
};

export default styles;
