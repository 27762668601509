import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { compact, find, get, isEmpty, sortBy } from 'lodash';
import { Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { addUsers } from './_api';
import useValidationSchema from './_form';
import { IUserForm } from './_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import FormAutocompleteMultiple from 'components/Form/Autocomplete/AutocompleteMultiple';
import FormSelect from 'components/Form/Select/Select';
import { Papeer } from 'components/Papeer/Papeer';
import SaveIcon from '@mui/icons-material/Save';
import useAlerts from 'components/Alerts/useAlerts';
import { useUser } from 'utils/hooks/useUser';
import { joinParams } from 'utils/study';
import { USER_PERMISSION } from 'constants/constants';
import { Box } from '@mui/system';

const UserForm: React.FC<any> = ({ toggleDialog, users, selectedUsers, folderId, loadEntity }) => {
  const { t } = useTranslation('Folders');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const { user } = useUser();
  const username = get(user, 'sub', '');

  const { UserFormSchema } = useValidationSchema();

  const methods = useForm<IUserForm>({
    resolver: yupResolver(UserFormSchema),
  });
  const { handleSubmit, register } = methods;

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();

    const usernameArray: any[] = get(values, 'username', []);
    if (isEmpty(usernameArray)) {
      addErrorAlert(t('userRequired'));
    } else {
      const usersWithPermission = usernameArray.map((user: any) => ({
        username: user.value,
        permission: get(values, 'permission', 'READ'),
      }));
      await addUsers(folderId, { usersWithPermission }).then(
        (response) => {
          addSuccessAlert(t('userAddedToFolder'));
          loadEntity(folderId);
          toggleDialog(false);
        },
        (error) => {
          addErrorAlert(t('errorUserAddedToFolder'));
        },
      );
    }
    toggleLoader(false);
  });

  useEffect(() => {
    register('username');
  });

  const usersSelect = sortBy(
    compact(
      compact(users).map((item: any) => {
        const value = get(item, 'username', '');
        const lastName = get(item, 'lastName', '');
        const firstName = get(item, 'firstName', '');
        const email = get(item, 'email', '');
        const userIsSelected = find(selectedUsers, { username: value });
        const label = `${joinParams([lastName, firstName, email ? `(${email})` : ''])}`;
        // Bez aktualniho uzivatele a bez jiz drive vybranych uzivatelu
        return value !== username && userIsSelected === undefined
          ? {
              value,
              label,
            }
          : undefined;
      }),
    ),
    ['label'],
  );
  const permission = USER_PERMISSION.map((item: any) => ({
    id: item.value,
    label: t(item.label),
  }));

  return (
    <>
      {users ? (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <Grid container={true} spacing={0}>
              <Grid item={true} xs={12}>
                <FormAutocompleteMultiple
                  name="username"
                  label={t('fullname')}
                  options={usersSelect}
                  required={true}
                  placeholder={t('select')}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <FormSelect
                  name="permission"
                  label={t('permission')}
                  required={true}
                  items={permission}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    displayPrint: 'none',
                  }}
                >
                  <Button
                    onClick={() => toggleDialog(false)}
                    variant="contained"
                    color="inherit"
                    size="large"
                    sx={{ mr: 1 }}
                  >
                    {t('close')}
                  </Button>
                  <Button variant="contained" color="primary" type="submit" size="large">
                    <SaveIcon sx={{ mr: 1 }} />
                    {t('save')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      ) : (
        <Papeer>
          <Typography>{t('usersNotFound')}</Typography>
        </Papeer>
      )}
    </>
  );
};

export default UserForm;
