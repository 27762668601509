import React, { useEffect, useState } from 'react';
import { get, isArray, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Tooltip } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { getTypesOfDocument, removeTypeOfDocument } from './_api';
import { TypeOfDocumentForm } from './TypeOfDocumentForm';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import Button from 'components/Buttons/Button';
import { MaterialUiIcon } from 'components/MaterialUiIcon/MaterialUiIcon';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { useAppDispatch } from 'store/hooks';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'clinicalPortalTypesOfDocumentMUI';

const ClinicalPortalTypesOfDocumentComponent: React.FC<any> = ({
  formats,
  typesOfDocument,
  setTypesOfDocument,
  currentLocale,
  languages,
}) => {
  const { t } = useTranslation('ClinicalPortalSettings');
  const dispatch = useAppDispatch();
  const { compactMode, confirmationData } = useAppInfo();

  const [showDialog, toggleDialog] = useState<boolean>(false);
  const [typeOfDocumentId, setTypeOfDocumentId] = useState<number>(0);

  const { toggleLoader } = useAppGlobals();

  const loadEntity = async () => {
    toggleLoader();
    const data = await getTypesOfDocument();
    if (data && isArray(data)) {
      const typeOfDocuments = data.map((item) => ({
        ...item,
        currentLocale,
      }));
      setTypesOfDocument(typeOfDocuments);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
    setTypeOfDocumentId(0);
  };

  const { onEntityRemove } = useEntityRemove(
    removeTypeOfDocument,
    t,
    loadEntity,
    'deletedTypeOfDocument',
    'errorDeletingTypeOfDocument',
  );

  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const onEntityEdit = async (id: number) => {
    setTypeOfDocumentId(id);
    toggleDialog(id > 0 ? true : false);
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 150,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                label={t('Grid:edit')}
                onClick={() => onEntityEdit(get(row, 'id'))}
              />
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:delete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('Grid:delete')}
                onClick={() => {
                  dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }));
                }}
                disabled={
                  get(row, 'state', null) === 'NEW' ||
                  !get(row, 'canDelete', true) ||
                  get(row, 'system', false)
                }
              />
            </>
          );
        },
      },
      { field: 'name', headerName: t('typesOfDocumentName') },
      ...(languages || []).map((lang: any) => {
        return {
          field: lang.abbreviation,
          headerName: lang.name,
          valueGetter: (value: any, row: any) =>
            get(JSON.parse(row.translateJson), lang.abbreviation, ''),
        };
      }),
      {
        field: 'allowedFormats',
        headerName: t('allowedFormats'),
        valueGetter: (value: any, row: any) =>
          sortBy(get(row, 'allowedFormats', []), ['name'])
            .map((item) => get(item, 'name', ''))
            .join(', '),
      },
      {
        field: 'iconName',
        headerName: t('typesOfDocumentIconName'),
        sortable: false,
        filterable: false,
        renderCell: ({ row }: GridRenderCellParams) => {
          const value = get(row, 'iconName');
          const iconColor = get(row, 'iconColor');
          return (
            <MaterialUiIcon
              icon={value}
              style={{
                color: iconColor,
              }}
              selected={true}
              useDiv={true}
            />
          );
        },
      },
    ]),
  );

  return (
    <>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setTypeOfDocumentId(0);
            toggleDialog(true);
          }}
          sx={{ minHeight: 22, ml: 0, mb: compactMode ? 1 : 2, lineHeight: 1 }}
        >
          <AddIcon sx={{ width: 20, height: 20, mr: 1 }} />
          {t('add')}
        </Button>
      </div>

      <MuiGrid
        gridKey={muiGridKey}
        rows={typesOfDocument}
        columns={columns}
        initialSortMode={[{ field: 'name', sort: 'asc' }]}
      />

      <OverflowedDialog
        key={'TAB_FORMAT'}
        open={showDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
        withScrolling={true}
      >
        <TypeOfDocumentForm
          typeOfDocumentId={typeOfDocumentId ? typeOfDocumentId : 'create'}
          toggleDialog={toggleDialog}
          loadEntity={loadEntity}
          formats={formats}
        />
      </OverflowedDialog>

      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmAction}
        />
      )}
    </>
  );
};

export default ClinicalPortalTypesOfDocumentComponent;
