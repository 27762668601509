import { useState, useEffect } from 'react';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { filter, isArray } from 'lodash';

import { ILanguage } from 'modules/Administration/GeneralSettings/_types';
import { getAllLanguages } from 'modules/Administration/GeneralSettings/_apiLanguages';

export const useLanguages = () => {
  const { toggleLoader } = useAppGlobals();
  const [languages, setLanguages] = useState<any[]>([]);

  const loadLanguages = async () => {
    toggleLoader();
    let languages = null;
    const languagesLists: ILanguage[] = await getAllLanguages();
    if (isArray(languagesLists)) {
      languages = filter(languagesLists, { active: true });
      setLanguages(languages);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadLanguages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { languages };
};
