import React, { useEffect, useState } from 'react';
import { get, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';

import { getOrder } from './_api';
import OrderDetailContent from './OrderDetailContent';

import { stateIsSame } from 'utils/componentOptimizatons';
import { useUser } from 'utils/hooks/useUser';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAllowedType } from 'utils/hooks/useAllowedType';
import { encryptId } from 'utils/hooks/useApp';

const PlainOrderDetailFromStudy: React.FC<any> = ({
  accessionNumber,
  patientId,
  hashIID,
  order2,
  setOrder2,
  mainTab,
}) => {
  const { t } = useTranslation('Orders');
  const { hasRole, user } = useUser();
  const { allowedType } = useAllowedType();
  const { toggleLoader } = useAppGlobals();

  const [order, setOrder] = useState<any>(null);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [validEntity, setValidEntity] = useState<boolean>(false);
  const [oldPatientId, setOldPatientId] = useState<string>('');
  const [oldAccessionNumber, setOldAccessionNumber] = useState<string>('');

  const linkBack = `/orders/${encryptId(patientId)}/${encryptId(
    accessionNumber,
  )}/detail?backTo=${hashIID}`;

  const loadOrder = async () => {
    let validEntity = false;
    if (order2 && patientId === oldPatientId && accessionNumber === oldAccessionNumber) {
      const attachs = get(order2, 'attachments', []);
      setAttachments(isArray(attachs) ? attachs : []);
      validEntity = true;
    } else {
      setOldPatientId(oldPatientId);
      setOldAccessionNumber(accessionNumber);
      const order = await getOrder(
        decodeURIComponent(patientId),
        decodeURIComponent(accessionNumber),
      );
      if (order) {
        const hic = get(order, 'healthInsuranceCompany.text', false);
        if (hic) {
          order.healthInsuranceCompany.text = JSON.parse(hic);
        }
        const exp = get(order, 'expertness.text', false);
        if (exp) {
          order.expertness.text = JSON.parse(exp);
        }
        const pri = get(order, 'priority.text', false);
        if (pri) {
          order.priority.text = JSON.parse(pri);
        }
        order.canUpdate = hasRole('ROLE_MWL_UPDATE');
        setOrder(order);
        setOrder2(order);

        let attachs = get(order, 'attachments', []);
        if (attachs) {
          attachs = attachs.map((item: any) => {
            return {
              ...item,
              canDelete:
                hasRole('ROLE_MWL_ATTACHMENT_ALL') ||
                (get(user, 'sub', 'aktualni uzivatel') ===
                  get(item, 'user.username', 'autor prilohy') &&
                  hasRole('ROLE_MWL_ATTACHMENT_MINE')),
              canShowInBrowser: get(allowedType(get(item, 'name')), 'allowed', false),
            };
          });
        }
        setAttachments(isArray(attachs) ? attachs : []);
        validEntity = true;
      }
    }
    setValidEntity(validEntity);
  };

  const getEntity = async () => {
    toggleLoader();
    loadOrder();
    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {validEntity ? (
        <OrderDetailContent
          order={order2 || order}
          loadOrder={loadOrder}
          attachments={attachments}
          user={user}
          linkBack={linkBack}
          patientId={patientId}
          accessionNumber={accessionNumber}
          isFromStudy={true}
          mainTab={mainTab}
          hasRole={hasRole}
        />
      ) : (
        t('orderFromStudyNotFound')
      )}
    </>
  );
};

export const OrderDetailFromStudy = React.memo(PlainOrderDetailFromStudy, stateIsSame);
