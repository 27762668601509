import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { IPDFDocument } from './_types';
import { Document, Page } from 'react-pdf';
import { Box, IconButton } from '@mui/material';
import { NavigateBefore, NavigateNext, ZoomIn, ZoomOut } from '@mui/icons-material';

const scaleStep = 0.5;
const scaleMax = 5.0;
const scaleMin = 0.5;

export const PDFDocument: React.FC<IPDFDocument> = ({ document }) => {
  const { t } = useTranslation('ClinicalPortal');

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState<number>(1.0);
  const [numPages, setNumPages] = useState<any>(null);
  const [RenderActionBar, setRenderActionBar] = useState<JSX.Element>();

  const onDocumentLoadSuccess = (numPages: any) => {
    setNumPages(get(numPages, '_pdfInfo.numPages', 0));
  };
  const handleClickPrevious = () => {
    setPageNumber(pageNumber - 1);
  };
  const handleClickNext = () => {
    setPageNumber(pageNumber + 1);
  };
  const handleClickZoomIn = () => {
    setScale(scale + scaleStep);
  };
  const handleClickZoomOut = () => {
    setScale(scale - scaleStep);
  };

  useEffect(() => {
    const RenderActionBar = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: 20 * scale,
          paddingRight: 20 * scale,
        }}
      >
        <Box>
          <IconButton
            key="zoomIn"
            aria-label="zoomIn"
            color="inherit"
            onClick={handleClickZoomIn}
            disabled={scaleMax === scale}
          >
            <ZoomIn />
          </IconButton>
          <IconButton
            key="zoomOut"
            aria-label="zoomOut"
            color="inherit"
            onClick={handleClickZoomOut}
            disabled={scaleMin === scale}
          >
            <ZoomOut />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            key="menuPrev"
            aria-label="previous"
            color="inherit"
            onClick={handleClickPrevious}
            disabled={pageNumber === 1}
          >
            <NavigateBefore />
          </IconButton>
          {t('pdfPage')} {pageNumber} {t('pdfPageOf')} {numPages}
          <IconButton
            key="menuNext"
            aria-label="next"
            color="inherit"
            onClick={handleClickNext}
            disabled={numPages === pageNumber}
          >
            <NavigateNext />
          </IconButton>
        </Box>
      </Box>
    );
    setRenderActionBar(RenderActionBar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, scale, numPages]);

  return (
    <Box>
      {RenderActionBar}
      <Document
        file={`data: application/pdf;base64,${document}`}
        onLoadSuccess={onDocumentLoadSuccess}
        // style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
        // className={classes.PDFDocument}
      >
        <Page
          // className={{classes.PDFPage}}
          /* styles={{PDFPage: {
        '& > canvas': {
          margin: '0 auto',
          maxWidth: '100%',
          height: 'auto !important',
        },
      },}} */
          scale={scale}
          pageNumber={pageNumber}
          loading={t('pdfLoading')}
          error={t('pdfLoadingError')}
        />
      </Document>
      {RenderActionBar}
    </Box>
  );
};
