import * as yup from 'yup';
import { isEmpty } from 'lodash';
import { TFunction } from 'i18next';
import useValidators from 'components/Form/validations/useValidators';

const useValidationSchema = (t: TFunction, validators: any) => {
  const { requiredAllItemsFromObjectOrNotObject } = useValidators();

  const SearchFormSchema = yup.object().shape({
    ...(!isEmpty(validators)
      ? { ...requiredAllItemsFromObjectOrNotObject(yup.string(), validators) }
      : {}),
  });

  return { SearchFormSchema };
};

export default useValidationSchema;
