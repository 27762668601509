import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';

export const sendStudy = (data: any, studies: any): Promise<any | boolean> =>
  api()
    .post(`/portal-api/study/send`, { ...data, studies })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const createForSend = (data: any, studies: any): Promise<any | boolean> =>
  api()
    .post(`/portal-api/studyRequest/createForSend`, { ...data, studies })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editRequestForSend = (values: any): Promise<any | boolean> =>
  api().post(`/portal-api/studyRequest/editForSend`, values);
