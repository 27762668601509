import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useAppInfo } from 'utils/hooks/useAppInfo';

export const useRulesForPassword = () => {
  const { t } = useTranslation('Login');
  const { passwordPolicyConfig } = useAppInfo();

  const [mustContain, setMustContain] = useState<string[]>([]);

  useEffect(() => {
    const capitalLettersRequired = get(passwordPolicyConfig, 'capitalLettersRequired', false)
      ? true
      : false;
    const smallLettersRequired = get(passwordPolicyConfig, 'smallLettersRequired', false)
      ? true
      : false;
    const digitsRequired = get(passwordPolicyConfig, 'digitsRequired', false) ? true : false;
    const specialCharsRequired = get(passwordPolicyConfig, 'specialCharsRequired', false)
      ? true
      : false;
    const mustContain = [];
    if (capitalLettersRequired)
      mustContain.push(t('ruleForPasswordRecovery.capitalLettersRequired'));
    if (smallLettersRequired) mustContain.push(t('ruleForPasswordRecovery.smallLettersRequired'));
    if (digitsRequired) mustContain.push(t('ruleForPasswordRecovery.digitsRequired'));
    if (specialCharsRequired) mustContain.push(t('ruleForPasswordRecovery.specialCharsRequired'));
    setMustContain(mustContain);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordPolicyConfig]);

  return {
    passwordPolicyEnabled: get(passwordPolicyConfig, 'enabled', false),
    minimalPasswordLength: get(passwordPolicyConfig, 'minimalPasswordLength', 0) || 0,
    passwordMustContain: mustContain,
  };
};
