import React, { useEffect, useState } from 'react';
import { filter, get, includes, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { Typography } from '@mui/material';
import { getCountOfProposals } from '../_api';
import ProposalSearchForm from './ProposalSearchForm';
import { IStudy, IStudyResultForGrid } from 'modules/Search/_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useActions } from 'utils/hooks/useActions';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useStudy } from 'utils/hooks/useStudy';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import Header from 'components/Header/Header';
import useAlerts from 'components/Alerts/useAlerts';
import { WarningMessage } from 'components/Form/Messages/WarningMessage';
import { SearchResultsMUI } from 'components/SearchResultsMUI/SearchResultsMUI';
import { SHREDDING, VIEWER } from 'constants/constants';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourProposalsShredding } from './TourProposalsShredding';

const Proposals: React.FC = () => {
  const { t } = useTranslation('Studies');
  const { toggleLoader } = useAppGlobals();
  const { confirmationData } = useAppInfo();
  const { storeConfirmationData } = useActions();
  const { studiesShredding } = useStudy();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const [countOfProposalRecords, setCountOfProposalRecords] = useState<number>(0);
  const [refreshForm, setRefreshForm] = useState<boolean>(false);
  const [rows, setRows] = useState<IStudyResultForGrid[]>([]);
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);
  const [items, setItems] = useState<IStudy[]>([]);
  useWithTitle(); // sets title to document

  const loadStart = async () => {
    toggleLoader();
    const countOfProposals = await getCountOfProposals();
    setCountOfProposalRecords(countOfProposals && countOfProposals > 0 ? countOfProposals : 0);
    toggleLoader(false);
  };

  useEffect(() => {
    loadStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRefreshForm = () => {
    setRefreshForm(true);
  };

  const shreddingSelectedStudies = (items: GridRowSelectionModel) => {
    storeConfirmationData({
      title:
        isArray(items) && items.length === 1
          ? 'confirmRemoveStudyFromFolder'
          : 'confirmRemoveStudiesFromFolder',
      id: 'studies',
      items,
    });
  };

  const callFunctionByName = (nameFunction: string, item: string | null = null) => {
    const items = item ? [item] : selection;
    const filterItems = filter(rows, (item) => includes(items, item.iid));
    setItems(filterItems);
    if (nameFunction === 'shredding') {
      shreddingSelectedStudies(items);
    }
  };

  const clearAction = () => storeConfirmationData(null);
  const cancelAction = () => {
    clearAction();
  };

  const confirmAction = async () => {
    await studiesShredding({
      studies: items,
      addSuccessAlert,
      addErrorAlert,
      callback: getRefreshForm,
      t,
    });
    clearAction();
  };

  const renderedSteps = () => {
    return <TourProposalsShredding />;
  };

  return (
    <>
      <Header title={t('shreddingProposalsTitle')} TourComponent={renderedSteps()} />
      <WarningMessage
        textError={
          countOfProposalRecords > 0
            ? { code: 'shreddingProposalsExist', par1: countOfProposalRecords }
            : 'shreddingProposalsNoExist'
        }
      />
      {countOfProposalRecords > 0 && (
        <>
          <ProposalSearchForm
            setRows={setRows}
            refreshForm={refreshForm}
            setRefreshForm={setRefreshForm}
          />
          <SearchResultsMUI
            rows={rows}
            setRows={setRows}
            actions={[SHREDDING, VIEWER]}
            selection={selection}
            setSelection={setSelection}
            isShredding={true}
            operations={{ callFunctionByName }}
            dataTour="shreddings-proposals-grid"
          />

          {confirmationData && confirmationData.id && (
            <ConfirmationDialog
              title={t('confirmShreddingTitle')}
              open={true}
              aria-labelledby="form-dialog-title"
              fullWidth={true}
              cancelAction={cancelAction}
              confirmAction={confirmAction}
            >
              <Typography component="div">
                {t('confirmShredding', {
                  par1: confirmationData.items.length
                    ? confirmationData.items.length
                    : get(confirmationData, 'id')
                    ? 1
                    : 0,
                })}
              </Typography>
            </ConfirmationDialog>
          )}
        </>
      )}
    </>
  );
};

export default Proposals;
