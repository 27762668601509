import HelperComponent from 'components/Tour/HelperComponent';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

export const TourClinicalPortalByPatient = ({ canShowResults }: any) => {
  const { t } = useTranslation('Reactour');

  const allSteps = () => {
    const steps = {
      '[data-tour="patientSearchForm"]': t('clinicalPortal.byPatient.searchForm'),
      ...(canShowResults && {
        '[data-tour="patientGrid"]': t('clinicalPortal.byPatient.grid'),
        '[data-tour="common-gridColumns"]': t('common.gridColumns'),
        '[data-tour="common-gridFilters"]': t('common.gridFilters'),
      }),
    };
    return steps;
  };

  useReactTour({ stepsDefinition: allSteps });

  return <HelperComponent />;
};
