import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';
import { get, range } from 'lodash';

const useValidationSchema = (
  t: TFunction,
  settings: any,
  activeChartDataType: string,
  activeChartDataSource: string,
  quantity: string,
  monthSelectDisabled: boolean,
) => {
  const { min, required } = useValidators();
  const StatisticsFormSchema = yup.object().shape({
    ...(activeChartDataType === quantity ? { year: required(yup.string()) } : {}),
    chartType: required(yup.string()),
    archives: yup.object({
      id: required(yup.mixed()),
    }),
    ...(activeChartDataSource !== 'MODALITY'
      ? { stationNames: min(yup.array(), 1, 'required') }
      : {}),
    ...(activeChartDataSource === 'MODALITY'
      ? { modalityNames: min(yup.array(), 1, 'required') }
      : {}),
  });

  const formItems = [
    {
      name: 'year',
      label: t('year'),
      type: 'select',
      items: [
        ...(activeChartDataType === 'FILLING' ? [{ id: '', label: '-' }] : []),
        ...get(
          settings,
          activeChartDataType === 'FILLING' ? 'yearsOfFilling' : 'yearsOfQuantity',
          [],
        )
          .sort((a: any, b: any) => b - a)
          .map((item: any) => ({ id: item, label: item })),
      ],
      disabled: false,
      required: activeChartDataType === quantity,
    },
    {
      name: 'month',
      label: t('month'),
      type: 'select',
      items: [
        { id: '', label: '-' },
        ...range(1, 13).map((id) => ({
          id,
          label: t(`${id}`),
        })),
      ],
      disabled: monthSelectDisabled,
    },
    {
      name: 'chartType',
      label: t('chartType'),
      type: 'select',
      items: get(settings, 'chartType', []).map((item: any) => ({ id: item, label: t(item) })),
      required: true,
    },
    {
      name: 'archives.id',
      label: t('archives'),
      type: 'select',
      items: get(settings, 'archives', []).map((item: any) => ({ id: item.id, label: item.name })),
      required: true,
    },
  ];

  return { StatisticsFormSchema, formItems };
};

export default useValidationSchema;
