import { IStudyResultForGrid } from 'modules/Search/_types';
import { useSelector } from 'react-redux';
import { useAppGlobals } from './useAppGlobals';
import { difference, get, isArray, isString, keys, pickBy } from 'lodash';
import { useGridExtendedInfo } from 'components/SearchResultsMUI/useGridExtendedInfo';
import { useGridColumns } from 'components/SearchResultsMUI/useGridColumns';

import { generateIID } from 'utils/study';
import { encryptId } from './useApp';
import {
  findStudiesInformationFromScientificArchive,
  getStudiesByStudyIdentity,
} from 'modules/Tags/_api';
import { ISearchArchiveTagRequest } from 'modules/Tags/_types';

export const useSubmitSearchTagForm = (
  rows: IStudyResultForGrid[],
  setRows: React.Dispatch<React.SetStateAction<IStudyResultForGrid[]>>,
) => {
  const { toggleLoader } = useAppGlobals();
  const columnVisibilityModel = useSelector((state) =>
    get(state, 'app.gridSettings.studyMUI.columnVisibilityModel', {}),
  );
  const { loadExtendedInfo } = useGridExtendedInfo({ rows, setRows });
  const { columns } = useGridColumns({});

  const onSubmitSearchTagForm = async (
    values: ISearchArchiveTagRequest,
    specificColumns: any = columns,
  ) => {
    setRows([]);
    toggleLoader();

    let searchedValues = {};
    if (isArray(values.registerItems) && values.registerItems.length) {
      searchedValues = { ...searchedValues, registerItems: values.registerItems };
    }
    if (isString(values.comment) && values.comment.length) {
      searchedValues = { ...searchedValues, comment: values.comment };
    }

    const response = await findStudiesInformationFromScientificArchive(searchedValues);
    if (typeof response !== 'boolean') {
    }
    const dataForRequest = { studyIdentities: response };

    await getStudiesByStudyIdentity(dataForRequest).then((studies) => {
      if (isArray(studies)) {
        const rows: IStudyResultForGrid[] = [
          ...studies.map((study) => {
            const iid = generateIID(study);
            return { ...study, iid, hashIID: encryptId(iid) };
          }),
        ];
        setRows(rows);
        const cols = keys(pickBy(columnVisibilityModel, (item: Boolean) => item === false)).map(
          (item: any) => item,
        );
        loadExtendedInfo(
          difference(
            specificColumns.map((item: any) => item.field), // vezmeme všechny sloupce
            cols, // vyhodíme ty, který jsou skrytý
          ),
          rows,
        );
      }
    });
    toggleLoader(false);
  };
  return { onSubmitSearchTagForm };
};
