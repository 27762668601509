import { find, get, isArray } from 'lodash';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { getSettingValueForVariable } from 'utils/products';

let products: any[] = [];
let realisator: any = null;

export const usePreview = () => {
  const { archives, realisator: realisatorFromAppInfo, modNoShow } = useAppInfo();

  products = archives;
  realisator = realisatorFromAppInfo;

  return { products, realisator, modNoShow };
};

export const getPreview = (archiveID: any, studyUid: any, series: any, image?: any) => {
  let studyArchive = null;
  let previewUrl = null;
  if (archiveID > 0 && isArray(products) && realisator && series) {
    studyArchive = find(products, ['id', parseInt(archiveID, 10)]);
    let settings = get(studyArchive, 'systemSetting');
    const sourceAeTitle = getSettingValueForVariable(settings, 'aeTitle');
    const sourceIp = getSettingValueForVariable(settings, 'ip');
    const sourcePort = getSettingValueForVariable(settings, 'dicomPort');

    settings = get(realisator, 'systemSetting');
    const realProtocol = getSettingValueForVariable(settings, 'protocol');
    const realIp = getSettingValueForVariable(settings, 'ip');
    const realPort = getSettingValueForVariable(settings, 'port');

    const studyUID = `,"studyUID":"${studyUid}"`;
    const seriesUID = `,"seriesUID":"${get(series, 'uid', '')}"`;
    const imageUID = image ? `,"imageUID":"${get(image, 'sopinstanceUid', '')}"` : '';

    previewUrl = `${realProtocol}://${realIp}:${realPort}/realisator/preview?{"sourceAeTitle":"${sourceAeTitle}","sourceIp":"${
      realIp === sourceIp ? 'localhost' : sourceIp
    }","sourcePort":"${sourcePort}"${studyUID}${seriesUID}${imageUID}}`;
  }
  return previewUrl;
};
