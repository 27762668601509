import { get, filter, isArray, head, find } from 'lodash';
import { IProduct, ISystemSettings } from 'modules/Administration/Products/_types';

export const filterActiveProducts = (products: IProduct[]) =>
  isArray(products) ? filter(products, 'active') : [];

export const getFirstActiveProduct = (products: IProduct[]) => head(filterActiveProducts(products));

export const getSystemSetting = (settings: ISystemSettings[], key: string, variable: string) =>
  find(settings, [key, variable]);

export const settingHasValue = (settings: ISystemSettings[], variable: string, givenValue: any) =>
  get(getSystemSetting(settings, 'variable', variable), 'value') === givenValue;

export const getSettingValueForVariable = (settings: ISystemSettings[], variable: string) =>
  get(getSystemSetting(settings, 'variable', variable), 'value');
