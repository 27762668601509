import HelperComponent from 'components/Tour/HelperComponent';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';
interface ITourTags {
  type: 'main' | 'detail';
}

export const TourTags = ({ type }: ITourTags) => {
  const { t } = useTranslation('Reactour');

  const allSteps = () => {
    const steps = {
      '[data-tour="addButton"]': t('registerGroups.addButton'),
      '[data-tour="registerGroupsGrid"]': (
        <>
          {t('common.bodyTable')} {t('registerGroups.grid')}
        </>
      ),
    };
    return steps;
  };

  const allRegisterGroupDetailSteps = () => {
    const steps = {
      '[data-tour="registerGroupsDetailForm"]': t('registerGroups.detail.form'),
      '[data-tour="registerGroupsDetailAddChildren"]': t(
        'registerGroups.detail.registerGroupsChildrenAdd',
      ),
      '[data-tour="registerGroupsDetailGrid"]': t(
        'registerGroups.detail.registerGroupsChildrenGrid',
      ),
      '[data-tour="registerGroupsTagsAdd"]': t('registerGroups.detail.tagsAdd'),
      '[data-tour="registerGroupsTagsGrid"]': t('registerGroups.detail.tagsGrid'),
    };
    return steps;
  };

  let stepsDefinition;
  if (type === 'main') stepsDefinition = allSteps;
  if (type === 'detail') stepsDefinition = allRegisterGroupDetailSteps;
  useReactTour({ stepsDefinition });

  return <HelperComponent />;
};
