import React, { useEffect, useState } from 'react';
import { find, get, isArray, isEmpty, sortBy, split } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, Grid, IconButton, Toolbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { IDicom, IGetDicomAttributes } from './_types';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import { useSeriesViewer } from 'components/SeriesViewer/useSeriesViewer';
import { SeriesViewer } from 'components/SeriesViewer/SeriesViewer';
import { getDicomAttributes } from 'modules/Studies/StudyDetail/_api';
import { shortener } from 'utils/grid/GridFormatters';
import { getSeriesAndInstances } from 'components/SeriesViewer/_api';
import { generateIID } from 'utils/study';
import { IStudyWithStates } from 'components/SeriesViewer/_types';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { Papeer } from 'components/Papeer/Papeer';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'dicomAttributes';

/**
 * @param {Dicom object} attr - Dicom object
 * @param {array[Dicom object]} attrs - new remapped array with dicom objects
 * @param {string | null} id - parent id
 */
export const prepareTreeFromDicomAttrs = (
  attr: IDicom,
  attrs: IDicom[],
  id: string | null = null,
) => {
  const tagIsSequence = get(attr, 'tagIsSequence') === 'true';
  const id2 = (id ? `${id}||${attr.tag}` : attr.tag) + attrs.length.toString(); // Pro jednoznačný identifikátor
  const rootLevel = split(id2, '||').length - 1;
  attrs.push({
    ...attr,
    id: id2,
    rootLevel,
    value: tagIsSequence ? '' : attr.value,
  });
  if (tagIsSequence) {
    const valueAttrs: IDicom[] = JSON.parse(attr.value);
    if (isArray(valueAttrs)) {
      valueAttrs.forEach((subAttr) => prepareTreeFromDicomAttrs(subAttr, attrs, id2));
    }
  }
};

const GetDicomAttributes: React.FC<IGetDicomAttributes> = ({
  study,
  openDicomAttributesDialog,
  setOpenDicomAttributesDialog,
}) => {
  const { t } = useTranslation('StudyDetail');
  const { toggleLoader } = useAppGlobals();
  const { isDarkMode } = useAppInfo();

  const [attributes, setAttributes] = useState<any[]>([]);
  const [image, setImage] = useState<any>(null);
  const [serie, setSerie] = useState<any>(null);
  const [objectOfDicomAttributes, setObjectOfDicomAttributes] = useState<any[]>([]);

  const onGetDicomAttributes = async (dicomAttributes: any) => {
    if (dicomAttributes) {
      const sDicomAttributes = JSON.stringify(dicomAttributes);
      const findDicomAttributes = find(objectOfDicomAttributes, { key: sDicomAttributes });
      toggleLoader();
      if (findDicomAttributes !== undefined) {
        setAttributes(get(findDicomAttributes, 'val'));
      } else {
        const attributes = await getDicomAttributes(dicomAttributes);
        const attrs: IDicom[] = [];
        if (isArray(attributes)) {
          attributes.forEach((attr: IDicom) => {
            prepareTreeFromDicomAttrs(attr, attrs);
          });
        }

        setAttributes(attrs);
        setObjectOfDicomAttributes([
          ...objectOfDicomAttributes,
          { key: sDicomAttributes, val: attrs },
        ]);
      }
      toggleLoader(false);
    }
  };

  const { studiesForViewer, setStudiesForViewer, operations } = useSeriesViewer(
    onGetDicomAttributes,
    'viewDicomAttributes',
  );

  const loadStart = async () => {
    if (study) {
      toggleLoader();
      try {
        // Load series and instances
        const archiveID: number = study.archive.id!;
        const studyID = get(study, 'studyInstanceUid');
        const series = await getSeriesAndInstances(archiveID, studyID);
        const iid = generateIID(study);

        if (isArray(series)) {
          const sortedSeries = sortBy(
            series.map((serie) => ({
              ...serie,
              numberSerie: isNaN(parseInt(get(serie, 'seriesNumber')))
                ? 0
                : parseInt(get(serie, 'seriesNumber')),
            })),
            ['modality', 'numberSerie'],
          );
          const loadedSeries = sortedSeries.map((serie, index) => ({
            ...serie,
            serieIsSelected: false,
            serieIsOpened: index === 0 ? true : false,
            image: isArray(serie.image)
              ? sortBy(
                  serie.image.map((image) => ({ ...image })),
                  ['instanceNumber'],
                )
              : [],
          }));
          const studies = [
            {
              ...study,
              iid,
              studyIsSelected: false,
              studyIsOpened: false,
              loadedSeries,
            } as unknown as IStudyWithStates,
          ];

          setStudiesForViewer(studies);
        }
      } catch (e) {}
      toggleLoader(false);
    }
  };

  useEffect(() => {
    if (openDicomAttributesDialog && !isEmpty(study) && isEmpty(studiesForViewer)) {
      loadStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [study, openDicomAttributesDialog, studiesForViewer]);

  useEffect(() => {
    if (
      !isEmpty(studiesForViewer) &&
      !isEmpty(studiesForViewer[0].loadedSeries) &&
      isEmpty(image)
    ) {
      const instance = get(studiesForViewer[0].loadedSeries[0], 'image[0]');

      setSerie(studiesForViewer[0].loadedSeries[0]);
      setImage(instance);
      operations.setCurrentImage(instance.sopinstanceUid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studiesForViewer, image]);

  useEffect(() => {
    if (!isEmpty(image) && get(image, 'sopinstanceUid')) {
      onGetDicomAttributes({
        studyUID: get(study, 'studyInstanceUid', ''),
        productId: get(study, 'archive.id', ''),
        seriesUID: get(serie, 'uid', ''),
        imageUID: get(image, 'sopinstanceUid', ''),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  const handleCloseDicomAttributesDialog = () => {
    setOpenDicomAttributesDialog(false);
    setAttributes([]);
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      [
        {
          field: 'tag',
          headerName: t('dc.tag'),
          renderCell: ({ row }: GridRenderCellParams) => {
            const rootLevel = get(row, 'rootLevel');
            const isLeveled = rootLevel > 0;
            return (
              <Box
                sx={
                  isLeveled
                    ? {
                        display: 'flex',
                        alignItems: 'center',
                        pl: rootLevel * 2,
                      }
                    : {}
                }
              >
                {isLeveled && <SubdirectoryArrowRightIcon sx={{ width: 20, height: 20, mr: 1 }} />}
                {shortener(get(row, 'tag', ''), 100)}
              </Box>
            );
          },
        },
        { field: 'VR', headerName: t('dc.vr') },
        { field: 'tagName', headerName: t('dc.tagName') },
        { field: 'value', headerName: t('dc.value') },
      ],
      250,
    ),
  );

  return (
    <>
      <OverflowedDialog
        key={'imagePreview'}
        open={openDicomAttributesDialog}
        onClose={handleCloseDicomAttributesDialog}
        aria-labelledby="form-dialog-title"
        fullScreen={true}
        withScrolling={true}
        contentStyle={{ padding: 0 }}
        dialogStyle={{ paddingTop: 6 }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar variant="dense">
            <Box sx={{ flex: 1 }} />
            <IconButton
              onClick={handleCloseDicomAttributesDialog}
              sx={{ color: isDarkMode ? 'white' : 'black' }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: 'flex',
            margin: '10px',
            justifyContent: 'center',
            maxWidth: '100%',
            height: 'auto',
          }}
        >
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={4}>
              <Box sx={{ mt: 0 }}>
                <Papeer>
                  <SeriesViewer
                    studies={studiesForViewer}
                    showStudiesCheckboxes={false}
                    showSeriesCheckboxes={false}
                    operations={operations}
                    renderStudy={false}
                    allowOpenSeries={true}
                  />
                </Papeer>
              </Box>
            </Grid>
            <Grid item={true} xs={8}>
              <MuiGrid
                gridKey={muiGridKey}
                rows={attributes}
                columns={columns}
                initialSortMode={[{ field: 'tag', sort: 'asc' }]}
              />
            </Grid>
          </Grid>
        </Box>
      </OverflowedDialog>
    </>
  );
};

export default GetDicomAttributes;
