import React, { useState, useEffect } from 'react';
import { filter, find, get, isArray, isEmpty, pick } from 'lodash';

import { getButton } from './_api';
import { IButtonForm } from './_types';
import { getAllWorkplaces } from '../Workplaces/_api';
import { ILanguage } from '../GeneralSettings/_types';
import { getAllLanguages } from '../GeneralSettings/_apiLanguages';

import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import ButtonFormComponent from './ButtonFormComponent';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { SEARCH_FUNCTION_ALL } from 'constants/constants';

export const ButtonForm: React.FC = () => {
  const { toggleLoader } = useAppGlobals();
  const { id, isCreating } = useEntityInfo();
  const { getArchivesForFunctions, getDefaultSources } = useAppInfo();

  const sources: { name: string; id: number }[] = getArchivesForFunctions(SEARCH_FUNCTION_ALL).map(
    (archive) => pick(archive, ['name', 'id']),
  );

  const defaultSources = getDefaultSources().map((sourceId) =>
    pick(find(sources, { id: sourceId }), ['name', 'id']),
  );

  const defaultFilter: IButtonForm = {
    id: 0,
    active: true,
    requestJson: null,
    icon: '',
    text: '',
    url: '',
    workPlaces: [],
    languageJsonText: '',
    showSearchForm: false,

    patient: {
      id: '',
      firstName: '',
      middleName: '',
      lastName: '',
      sex: '',
      dateBirthFrom: null,
      dateBirthTo: null,
      description: '',
      referringPhysician: '',
    },
    study: {
      accessionNumber: '',
      dateFrom: null,
      dateTo: null,
    },
    modalities: [],
    studyDate: '',
    sources: defaultSources as { name: string; id: number }[],
  };
  const defaultWorkplaces: any[] = [];

  const [entity, fetchEntity] = useState<IButtonForm>();

  const [isSystemButton, setSystemButton] = useState<boolean>(false);
  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);
  const [workplaceItems, getWorkplaceItems] = useState<any[]>();
  const [languages, setLanguages] = useState<any[]>([]);
  const [defaultValues, setDefaultValues] = useState({});

  const { currentLocale } = useLanguage();

  const getEntities = async () => {
    toggleLoader();
    try {
      const workplaces = await getAllWorkplaces();
      let workplaceItems: any[] = [];
      if (isArray(workplaces)) {
        const mappedWorkplaces = workplaces.map((item: any) => pick(item, ['id', 'text', 'code']));
        workplaceItems = mappedWorkplaces
          ? mappedWorkplaces.map((workplace) => ({
              ...workplace,
              name: get(
                get(workplace, 'text', '') ? JSON.parse(get(workplace, 'text', '')) : {},
                currentLocale,
                workplace.code,
              ),
            }))
          : [];
        getWorkplaceItems(workplaceItems);
      }

      let defaultValues = {};
      let transformedValues = {};

      let button: IButtonForm = { ...defaultFilter };
      let customWorkplaces: any[] = defaultWorkplaces;
      let isSystemButton = false;
      let languages = null;
      const languagesLists: ILanguage[] = await getAllLanguages();
      if (isArray(languagesLists)) {
        languages = filter(languagesLists, { active: true });
        setLanguages(languages);
      }
      if (!isCreating && id) {
        const entity = await getButton(id.toString());
        const requestJson = get(entity, 'requestJson', '');
        const userFilter = requestJson ? JSON.parse(requestJson) : null;
        if (userFilter) {
          transformedValues = {
            ...userFilter,
            studyDate: userFilter.date,
            patient: {
              ...userFilter.patient,
              dateBirthFrom: userFilter.patient?.dateBirthFrom
                ? new Date(userFilter.patient.dateBirthFrom)
                : null,
              dateBirthTo: userFilter.patient?.dateBirthTo
                ? new Date(userFilter.patient.dateBirthTo)
                : null,
            },
            study: {
              ...userFilter.study,
              dateFrom: userFilter.study?.dateFrom ? new Date(userFilter.study.dateFrom) : null,
              dateTo: userFilter.study?.dateTo ? new Date(userFilter.study.dateTo) : null,
            },
          };
        }
        isSystemButton = get(entity, 'system', false);
        setSystemButton(isSystemButton);
        button = {
          ...button,
          ...entity,
          ...transformedValues,
          showSearchForm: userFilter ? true : false,
        };
        if (entity) {
          fetchEntity({
            ...button,
            ...entity,
            ...transformedValues,
            showSearchForm: userFilter ? true : false,
          });
        }
        const selectedWorkplaces = get(entity, 'workPlaces', []);
        if (isArray(workplaces) && !isEmpty(selectedWorkplaces)) {
          workplaces.forEach((item) => {
            selectedWorkplaces.forEach((selWorkplace) => {
              if (item.id === selWorkplace.id) {
                defaultWorkplaces.push(item);
              }
            });
          });
        }
        if (!isSystemButton) {
          (languages || []).forEach((lang: any) => {
            const key = `languageJsonText_${get(lang, 'abbreviation')}`;
            const value = get(button, `languageJsonText.${lang.abbreviation}`, '');
            defaultValues = { ...defaultValues, [key]: value };
          });
        }
        defaultValues = { ...button, ...defaultValues, workPlaces: customWorkplaces };
      } else {
        customWorkplaces = workplaceItems;
        defaultValues = { ...button, workPlaces: customWorkplaces };
      }
      setDefaultValues(defaultValues);
      setIsFetchingEntity(false);
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isFetchingEntity && (
        <ButtonFormComponent
          isCreating={isCreating}
          entity={entity}
          workplaceItems={workplaceItems}
          languages={languages}
          defaultValues={defaultValues}
          isSystemButton={isSystemButton}
          sources={sources}
        />
      )}
    </>
  );
};
