import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { filter, get, isObject } from 'lodash';
import { format } from 'date-fns';
import { Box, Tooltip } from '@mui/material';
import GridDetailIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';

import { cloneTest, publishTest, removeTest } from './_api';
import ConfirmDialog from './ConfirmDialog';
import ManageTestsSearchForm from './ManageTestsSearchForm';

import Header from 'components/Header/Header';
import useAlerts from 'components/Alerts/useAlerts';

import { findTranslation } from 'utils/grid/translate';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useAppInfo } from 'utils/hooks/useAppInfo';

import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { encryptId } from 'utils/hooks/useApp';
import { useTests } from 'utils/hooks/useTests';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';

import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourTests } from './TourTests';
import { GridActionsCellItem, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useActions } from 'utils/hooks/useActions';
import styles from 'components/SearchResultsMUI/_styles';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'manageTestResultsMui';

const ManageTests: React.FC = () => {
  const { t } = useTranslation('Tests');
  const { currentLocale } = useLanguage();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const navigate = useNavigate();
  const { toggleLoader } = useAppGlobals();
  const { compactMode, testResults } = useAppInfo();
  const { onEntityRemove } = useEntityRemove(removeTest, t);
  const { storeTests } = useActions();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const [testToClone, setTestToClone] = useState<boolean>(true);
  const [testToPublish, setTestToPublish] = useState<boolean>(true);
  const [testToRemove, setTestToRemove] = useState<any>(true);

  const { triggerSubmit, testIsReady } = useTests(t);

  useWithTitle(); // sets title to document

  const submitTestToClone = async (id: number) => {
    toggleLoader();
    await cloneTest(id).then(
      async ({ id: newTestId }: any) => {
        addSuccessAlert(t('testCloned'));
        setTestToClone(false);
        navigate(`/tests/manageTests/${encryptId(newTestId)}/detail`);
      },
      () => {
        addErrorAlert(t('errorCloning'));
        toggleLoader(false);
      },
    );
  };
  const submitTestToPublish = async (id: number) => {
    toggleLoader();
    await publishTest(id).then(
      async () => {
        addSuccessAlert(t('testPublished'));
        setTestToPublish(false);
        await triggerSubmit();
      },
      () => {
        addErrorAlert(t('errorPublishing'));
        toggleLoader(false);
      },
    );
  };

  const onCustomEntityDetail = (row: any) =>
    navigate(`/tests/manageTests/${encryptId(get(row, 'id', ''))}/detail`);

  const onCustomEntityUpdate = (row: any) =>
    navigate(`/tests/manageTests/${encryptId(get(row, 'id', ''))}`);

  const deleteCancelAction = () => {
    setTestToRemove(false);
  };

  const onRemove = async (id: number) => await onEntityRemove(id);

  const deleteConfirmAction = async () => {
    const resp = await onRemove(get(testToRemove, 'id'));
    deleteCancelAction();
    if (resp) {
      storeTests(filter(testResults, (item: any) => item.id !== get(testToRemove, 'id')));
    }
  };

  const getName = (row: any) => get(row, 'name', '') || '';

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 185,
        headerAlign: 'left',
        align: 'left',
        getActions: ({ row }: GridRowParams) => [
          <Box data-tour="testsActions">
            <GridActionsCellItem
              icon={
                <Tooltip title={t('Grid:detail')}>
                  <GridDetailIcon />
                </Tooltip>
              }
              label="Detail"
              onClick={() => onCustomEntityDetail(row)}
            />

            <GridActionsCellItem
              icon={
                <Tooltip title={t('Grid:edit')}>
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              onClick={() => onCustomEntityUpdate(row)}
              disabled={testIsReady(row)}
            />

            <GridActionsCellItem
              icon={
                <Tooltip title={t('clone')}>
                  <FilterNoneIcon />
                </Tooltip>
              }
              label="Clone"
              onClick={() => setTestToClone(row)}
            />

            <GridActionsCellItem
              icon={
                <Tooltip title={t('publish')}>
                  <PublishIcon />
                </Tooltip>
              }
              label="Publish"
              disabled={!get(row, 'readyToPublish')}
              onClick={() => setTestToPublish(row)}
            />

            <GridActionsCellItem
              icon={
                <Tooltip title={t('Grid:delete')}>
                  <DeleteIcon />
                </Tooltip>
              }
              label="Remove"
              onClick={() => setTestToRemove(row)}
              disabled={testIsReady(row)}
            />
          </Box>,
        ],
      },

      {
        field: 'name',
        headerName: t('name'),
        hideable: false,
        renderCell: ({ row }: GridRenderCellParams) => (
          <Tooltip title={getName(row)}>
            <Box onClick={() => onCustomEntityDetail(row)} sx={styles.gridLink}>
              {getName(row)}
            </Box>
          </Tooltip>
        ),
        valueGetter: (value: any, row: any) => getName(row),
      },
      {
        field: 'description',
        headerName: t('description'),
      },
      {
        field: 'validFrom',
        headerName: t('validFrom'),
        renderCell: ({ row }: GridRenderCellParams) =>
          get(row, 'validFrom', '')
            ? format(new Date(get(row, 'validFrom', '')), 'dd. MM. yyyy')
            : '',
      },
      {
        field: 'validTo',
        headerName: t('validTo'),
        renderCell: ({ row }: GridRenderCellParams) =>
          get(row, 'validTo', '') ? format(new Date(get(row, 'validTo', '')), 'dd. MM. yyyy') : '',
      },
      {
        field: 'state',
        headerName: t('state'),
        valueGetter: (value: any, row: any) =>
          findTranslation(currentLocale, 'Tests', get(row, 'state', '').toLowerCase()),
      },
      {
        field: 'type',
        headerName: t('type'),
        valueGetter: (value: any, row: any) =>
          findTranslation(currentLocale, 'Tests', get(row, 'type', '').toLowerCase()),
      },
    ]),
  );

  const renderedSteps = () => {
    return <TourTests type="manage" />;
  };

  return (
    <>
      <Header
        title={t('testsList')}
        addUrl="/tests/manageTests/create"
        TourComponent={renderedSteps()}
      />
      <ManageTestsSearchForm
        forStartTest={false}
        compactMode={compactMode}
        triggerSubmit={triggerSubmit}
      />
      {testResults && (
        <MuiGrid gridKey={muiGridKey} rows={testResults} columns={columns} dataTour="testsGrid" />
      )}

      {isObject(testToClone) && (
        <ConfirmDialog
          closeDialog={() => setTestToClone(false)}
          entity={testToClone}
          action={submitTestToClone}
          dialogTitle={t('confirmCloneTitle')}
        />
      )}
      {isObject(testToPublish) && (
        <ConfirmDialog
          closeDialog={() => setTestToPublish(false)}
          entity={testToPublish}
          action={submitTestToPublish}
          dialogTitle={t('confirmPublishTitle')}
        />
      )}
      {isObject(testToRemove) && (
        <ConfirmationDialog
          title={t('confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={deleteCancelAction}
          confirmAction={deleteConfirmAction}
        />
      )}
    </>
  );
};

export default ManageTests;
