import * as yup from 'yup';
import useAnonymizationSchema from '../Anonymization/_form';

const useValidationSchema = (anonymizationFields: any[]) => {
  const { anonymizationSchema } = useAnonymizationSchema(anonymizationFields);

  const exportSchema = {};

  const combinedSchema = {
    ...exportSchema,
    ...anonymizationSchema,
  };
  const StudiesExportFormSchema = yup.object().shape({
    ...combinedSchema,
  });
  return { StudiesExportFormSchema };
};

export default useValidationSchema;
