export const useForm2 = () => {
  const formatLabelForRequired = (label: any, required: boolean = false) =>
    label ? `${label}${required ? ' *' : ''}` : '';

  const getDateBirthdayFromPatientID = (value: any) => {
    // https://cs.wikipedia.org/wiki/Rodné_číslo
    // https://lorenc.info/3MA381/overeni-spravnosti-rodneho-cisla.htm
    // https://www.zizka.ch/pages/programming/ruzne/rodne-cislo-identifikacni-cislo-rc-ico-kontrola-validace.html
    // MAPO-2295 KLON- FNM - MS Portál - rodné číslo - Jsou RČ která obsahují "X" na pozici 10. znaku jako zcela validní součást.
    let patientId = '';
    let sex = 'M';
    for (const c of value) {
      if (!isNaN(c.toString())) {
        patientId += c;
      }
    }
    try {
      if (patientId.length < 9 || value.length > 11) {
        throw new Error();
      }
      let year = parseInt(patientId.substr(0, 2), 10);
      let month = parseInt(patientId.substr(2, 2), 10);
      const day = parseInt(patientId.substr(4, 2), 10);

      if (patientId.length === 9) {
        if (
          (value.length === 10 && value.substr(9, 1).toLowerCase() === 'x') ||
          (value.length === 11 &&
            value.substr(10, 1).toLowerCase() === 'x' &&
            value.substr(6, 1).toLowerCase() === '/')
        ) {
          year += 2000;
        } else if (year < 54) {
          year += 1900;
        } else {
          throw new Error();
        }
      }
      if (patientId.length === 10) {
        const c = parseInt(patientId.substr(9, 1), 10);
        let m = parseInt(patientId.substr(0, 9), 10) % 11;
        if (m === 10) {
          m = 0;
        }
        if (m !== c) {
          throw new Error();
        }
        year += year < 54 ? 2000 : 1900;
      }
      if (month > 70 && year > 2003) {
        month -= 70;
      } else if (month > 50) {
        month -= 50;
        sex = 'F';
      } else if (month > 20 && year > 2003) {
        month -= 20;
      }
      const d = new Date();
      if (year > d.getFullYear() || month === 0 || month > 12 || day === 0 || day > 31) {
        throw new Error();
      }

      return { sex, date: new Date(year, month - 1, day) };
    } catch (e) {
      // console.log('Personal identification number is not valid!');
      return { sex: undefined, date: undefined };
    }
  };

  const camelToDash = (str: string) => {
    // Zkonvertuje všechna velká písmena na malé písmeno s podtržítkem (bez podtržítka na začátku slova)
    // např. ikona: s názvem AddHomeWork bude zkonvertována na add_home_work
    return str.replace(/[A-Z]/g, (m, offset) => (offset > 0 ? '_' : '') + m.toLowerCase());
  };
  return {
    formatLabelForRequired,
    getDateBirthdayFromPatientID,
    camelToDash,
  };
};
