import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';
import { find, get, isArray, pick } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getFacility, createFacilityExchangeNetwork, editFacilityExchangeNetwork } from './_api';
import { IFacilityExchangeNetworkForm } from './_types';
import useValidationSchema from './_form';
import { getAll } from '../FacilitiesAndExchangeNetworks/_apiExchangeNetworks';
import { IExchangeNetwork } from '../FacilitiesAndExchangeNetworks/_types';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';

import useAlerts from 'components/Alerts/useAlerts';
import { ISelectItem } from 'components/Form/Select/_types';
import { useWithTitle } from 'utils/hooks/useWithTitle';

export const FacilityExchangeNetworkForm: React.FC = () => {
  const { t } = useTranslation('Facilities');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const params = useParams();

  const [title, setTitle] = useState<string>('FacilityExchangeNetwork');

  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);
  const [isCreating, setIsCreating] = useState<boolean>(true);
  const [exchangeNetworkIdItems, setExchangeNetworkIdItems] = useState<ISelectItem[]>([]);
  const [linkBack, setLinkBack] = useState<string>('/');
  const navigate = useNavigate();
  const { setPageTitle } = useWithTitle();

  const { FacilityExchangeNetworkFormSchema } = useValidationSchema(t);

  const methods = useForm<IFacilityExchangeNetworkForm>({
    resolver: yupResolver(FacilityExchangeNetworkFormSchema),
  });
  const { handleSubmit, reset, register } = methods;

  const loadEntity = async (facilityId: any, facilityExchangeNetworkId: any) => {
    toggleLoader();

    const linkBack = `/administration/facilitiesAndExchangeNetworks/${facilityId}`;
    setLinkBack(linkBack);
    const isCreating = facilityExchangeNetworkId === 'create';
    setIsCreating(isCreating);

    try {
      let facilityExchangeNetwork: IFacilityExchangeNetworkForm = {
        id: 0,
        identifier: '',
        facilityId,
        exchangeNetworkId: 0,
      };
      const facility = await getFacility(facilityId.toString());
      const title = `${
        isCreating ? t('facilityExchangeNetwork.add') : `${t('facilityExchangeNetwork.edit')}`
      } ${get(facility, 'name', '')}`;
      setPageTitle(title);
      setTitle(title);
      if (facility && !isCreating) {
        const entity: any = find(get(facility, 'facilityExchangeNetworkModels', []), {
          id: parseInt(facilityExchangeNetworkId, 10),
        });
        facilityExchangeNetwork = {
          ...entity,
          exchangeNetworkId: get(entity, 'exchangeNetwork.id', 0),
        };
      }

      const exchangeNetworks = await getAll();
      if (isArray(exchangeNetworks)) {
        const items: any = exchangeNetworks.map((item: IExchangeNetwork) => ({
          id: get(item, 'id'),
          label: item.name,
        }));
        setExchangeNetworkIdItems(items);
      }

      reset({ ...facilityExchangeNetwork });
      setIsFetchingEntity(false);
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  const onSubmit = handleSubmit(async (values: IFacilityExchangeNetworkForm) => {
    toggleLoader();
    const facilityExchangeNetworkDetail = {
      ...pick(values, ['facilityId', 'id', 'identifier']),
      exchangeNetwork: { id: get(values, 'exchangeNetworkId') },
    };
    const fn = isCreating ? createFacilityExchangeNetwork : editFacilityExchangeNetwork;
    const response = await fn(facilityExchangeNetworkDetail);
    if (response) {
      addSuccessAlert(t('exchangeNetworkSaved'));
      navigate(linkBack);
    } else {
      addErrorAlert(t('exchangeNetworkErrorSaving'));
    }
    toggleLoader(false);
  });

  useEffect(() => {
    const facilityId = get(params, 'facilityId');
    const facilityExchangeNetworkId = get(params, 'facilityExchangeNetworkId');
    loadEntity(facilityId, facilityExchangeNetworkId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isFetchingEntity ? (
        <>
          <Header title={title} />
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <input {...register('id')} type="hidden" />
                <input {...register('facilityId')} type="hidden" />
                <Grid container={true} alignItems="flex-end">
                  <Grid item={true} xs={12} md={6}>
                    <FormInput
                      name="identifier"
                      label={t('facilityExchangeNetwork.identifier')}
                      required={true}
                    />
                    {isCreating ? (
                      <FormSelect
                        name="exchangeNetworkId"
                        label={t('facilityExchangeNetworks')}
                        required={true}
                        items={exchangeNetworkIdItems}
                      />
                    ) : (
                      <input {...register('exchangeNetworkId')} type="hidden" />
                    )}
                    <EntityButtons linkBack={linkBack} />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Papeer>
        </>
      ) : (
        <Papeer>
          <Typography>{t('facilityExchangeNetworkNotFound')}</Typography>
        </Papeer>
      )}
    </>
  );
};
