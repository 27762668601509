import * as yup from 'yup';
import useAnonymizationSchema from '../Anonymization/_form';

const useValidationSchema = (anonymizationFields: any[]) => {
  const { anonymizationSchema } = useAnonymizationSchema(anonymizationFields);

  const sendSchema = {};

  const combinedSchema = {
    ...sendSchema,
    ...anonymizationSchema,
  };
  const StudiesSendFormSchema = yup.object().shape({ ...combinedSchema });

  return { StudiesSendFormSchema };
};

export default useValidationSchema;
