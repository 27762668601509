import { get, isDate, keys } from 'lodash';
import { isFuture } from 'date-fns';

export const isValidDate = (dateString: string) => {
  // First check for the pattern
  if (!/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  const parts = dateString.split('.');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) {
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
};

export const dmyToDate = (dmy: any) => {
  const [day, month, year] = dmy.split('.');
  return new Date(year, month - 1, day);
};

export const ymdToDate = (ymd: any) => {
  const [year, month, day] = ymd.split('-');
  return new Date(year, month - 1, day);
};

// Never used?
// export const replaceSpecCharsByDot = (date: any) => {
//   return date.replace(/([-*+?,^$|(){}\[\]/\s\<\>:=_%])/gm, '.');
// };

export const formatTime = (secondsBetweenStartAndEnd: number, t: any) => {
  let output = {};
  if (secondsBetweenStartAndEnd < 60) {
    output = {
      h: 0,
      m: 0,
      s: secondsBetweenStartAndEnd,
    };
  } else if (secondsBetweenStartAndEnd < 3600) {
    output = {
      h: 0,
      m: secondsBetweenStartAndEnd / 60,
      s: secondsBetweenStartAndEnd % 60,
    };
  } else {
    const secondsLeft = secondsBetweenStartAndEnd % 3600;

    output = {
      h: secondsBetweenStartAndEnd / 3600,
      m: secondsLeft / 60,
      s: secondsLeft % 60,
    };
  }

  return keys(output)
    .map((timePart) => `${get(output, timePart).toFixed(0)} ${t('time_abbr_' + timePart)}`)
    .join(' ');
};

export const isDateInFuture = (date: any) => {
  if (isDate(date) && date.toString() !== 'Invalid Date' ? true : false) {
    return isFuture(date);
  } else {
    return false;
  }
};
