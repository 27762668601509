import { get } from 'lodash';

export const usePatientManagementDialogFields = (formValues: any, t: any, validators: any) => {
  const lastName: string = formValues.lastName || '';
  const firstName: string = formValues.firstName || '';
  const id = formValues.id || '';

  // Conditions for disabling fields and search condition
  const disableSearch =
    validators || (lastName.length > 1 && firstName.length > 1) || id.length > 1 ? false : true;
  const disabledName = !validators && id ? true : false;
  const disabledId = !validators && (lastName || firstName) ? true : false;

  const fields = [
    {
      name: 'lastName',
      label: t('lastName'),
      inputProps: { maxLength: get(validators, 'patient.name.lastName.max', 64) },
      disabled: disabledName,
    },
    {
      name: 'firstName',
      label: t('firstName'),
      inputProps: { maxLength: get(validators, 'patient.name.firstName.max', 64) },
      disabled: disabledName,
    },
    {
      name: 'id',
      label: t('patientID'),
      inputProps: { maxLength: get(validators, 'patient.identification.max', 64) },
      disabled: disabledId,
    },
  ];

  return {
    fields,
    disableSearch,
  };
};
