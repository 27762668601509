import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { compact, get, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import webstomp from 'webstomp-client';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { GridRenderCellParams, GridActionsCellItem, GridRowModel } from '@mui/x-data-grid-pro';
import { Assignment as DetailIcon } from '@mui/icons-material';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';
import { isIE11 } from 'utils/variables';
import { joinParams } from 'utils/study';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import Header from 'components/Header/Header';
import useAlerts from 'components/Alerts/useAlerts';
import { Papeer } from 'components/Papeer/Papeer';
import { DETAIL_REQUEST } from 'constants/constants';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'historiesMUI';

const Histories: React.FC = () => {
  const { t } = useTranslation('Histories');
  useWithTitle(); // sets title to document
  const navigate = useNavigate();
  const params = useParams();
  const action = get(params, 'action', 'send');

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const { toggleLoader } = useAppGlobals();

  const { user } = useUser();
  const { addErrorAlert } = useAlerts();

  const [histories, setHistories] = useState<any[]>([]);
  const [percents, setPercents] = useState<number>(0);
  const [canShowGrid, setCanShowGrid] = useState<boolean>(false);

  const getRowId = useCallback((row: GridRowModel) => row.uniqId, []);

  const parseMessage = (message: any) => {
    const historiesLength = histories.length;
    const bodyMessage = get(message, 'body');
    try {
      const parsedMessage = JSON.parse(bodyMessage);
      const historiesFromMessage = get(parsedMessage, 'studyHistory', null);
      if (historiesFromMessage && isArray(historiesFromMessage)) {
        const modifiedHistoriesFromMessage = historiesFromMessage.map((history, index) => ({
          ...history,
          id: index + 1 + historiesLength,
          action,
          uniqId: `${get(history, 'requestId', '')}_${get(history, 'accessionNumber', '')}`,
        }));
        setHistories((prevHistories) => [...prevHistories, ...modifiedHistoriesFromMessage]);
      }
      const percents = get(parsedMessage, 'percents', null);
      setPercents(percents);
      setCanShowGrid(true);
      return percents >= 100;
    } catch (e) {
      console.debug(e);
      return 0;
    }
  };

  const disconnectWs = () => {
    try {
      if (get(window, 'subscribedStudyHistory')) {
        (window as any).subscribedStudyHistory.unsubscribe();
      }
    } catch (e) {
      console.debug(e);
    }
    try {
      if (get(window, 'ws')) {
        (window as any).ws.disconnect();
      }
    } catch (e) {
      console.debug(e);
    }
  };

  const loadEntities = async () => {
    toggleLoader();

    const data = {
      username: get(user, 'sub', ''),
      operation: action.toUpperCase(),
      rowCount: 50,
    };

    const wsUrl = `${window.location.protocol === 'http:' ? 'ws://' : 'wss://'}${
      window.location.host
    }/portal-api/studyHistory`;

    try {
      const socket = new WebSocket(wsUrl);
      (window as any).ws = webstomp.over(socket, { debug: false });
      (window as any).ws.connect({}, () => {
        (window as any).subscribedStudyHistory = (window as any).ws.subscribe(
          `/operationHistory/response`,
          async (message: any) => {
            const canDisconnect = parseMessage(message);
            if (canDisconnect) {
              disconnectWs();
            }
          },
        );
        (window as any).ws.send('/operationHistory/get', JSON.stringify(data));
      });
    } catch (e) {
      addErrorAlert(t(isIE11 ? 'webSocketErrorInIE' : 'webSocketError'));
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    loadEntities();

    return () => {
      if (canShowGrid) {
        disconnectWs();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      compact([
        {
          field: 'actions',
          headerName: t('Grid:actions'),
          type: 'actions',
          hideable: false,
          renderCell: ({ row }: GridRenderCellParams) => (
            <GridActionsCellItem
              icon={
                <Tooltip title={t('requestDetail')}>
                  <DetailIcon />
                </Tooltip>
              }
              label={t('requestDetail')}
              onClick={() => onCustomEntityDetail(row)}
            />
          ),
        },
        {
          field: 'requestCreatedWhen',
          headerName: t('requestCreatedWhen'),
          type: 'dateTime',
          valueGetter: (value: any) => value && new Date(value),
          valueFormatter: (value: any) => value && format(value, 'dd. MM. yyyy HH:mm'),
        },
        {
          field: 'user',
          headerName: t('user'),
          valueGetter: (value: any, row: any) =>
            `${joinParams([
              get(row, 'requestCreatedBy.lastName'),
              get(row, 'requestCreatedBy.firstName'),
            ])}`,
        },
        {
          field: 'patientId',
          headerName: t('patientId'),
          valueGetter: (value: any, row: any) => get(row, 'patient.identificationNumber', ''),
        },
        {
          field: 'name',
          headerName: t('name'),
          valueGetter: (value: any, row: any) =>
            `${joinParams([
              get(row, 'patient.name.lastName'),
              get(row, 'patient.name.firstName'),
            ])}`,
        },
        {
          field: 'dateTime',
          headerName: t('dateTime'),
          type: 'dateTime',
          valueGetter: (value: any) => value && new Date(value),
          valueFormatter: (value: any) => value && format(value, 'dd. MM. yyyy HH:mm'),
        },
        { field: 'accessionNumber', headerName: t('accessionNumber') },
        action === 'send' ? { field: 'sentMethod', headerName: t('sentMethod') } : null,
        action === 'send' ? { field: 'recipient', headerName: t('recipient') } : null,
      ]),
      200,
    ),
  );

  const onCustomEntityDetail = (row: any) => {
    navigate(`/requests/${encodeURIComponent(get(row, 'requestId', ''))}`, {
      state: { linkBack: DETAIL_REQUEST + '/' + get(row, 'action', 'back') },
    });
  };

  return (
    <>
      <Header title={t(`Menu:histories.${action}`)} />
      {percents < 100 && (
        <Papeer>
          <Box>
            {`${t('loadingData')} ${percents.toFixed(1)}% `}
            <CircularProgress size={16} />
          </Box>
        </Papeer>
      )}
      <MuiGrid
        gridKey={muiGridKey}
        getRowId={getRowId}
        rows={histories}
        columns={columns}
        initialSortMode={[{ field: 'requestCreatedWhen', sort: 'desc' }]}
      />
    </>
  );
};

export default Histories;
