import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { get, isString, join } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Collapse, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { IPasswordRecovery } from './_types';
import { checkPassword, passwordRecovery } from './_api';
import useValidationSchema from './_formPasswordRecovery';
import LoginAndPasswordRecoveryContent from './LoginAndPasswordRecoveryContent';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import useAlerts from 'components/Alerts/useAlerts';
import { useRulesForPassword } from 'utils/hooks/useRulesForPassword';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';

export const PasswordRecovery: React.FC = () => {
  const { t } = useTranslation('Login');
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id') || '';
  const { PasswordRecoveryFormSchema } = useValidationSchema();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const navigate = useNavigate();
  const { minimalPasswordLength, passwordMustContain, passwordPolicyEnabled } =
    useRulesForPassword();
  const [formError, setFormError] = useState<string>();

  const methods = useForm<IPasswordRecovery>({
    resolver: yupResolver(PasswordRecoveryFormSchema),
  });
  const { handleSubmit, register, reset } = methods;
  const { toggleLoader } = useAppGlobals();

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    if (get(values, 'password') !== get(values, 'confirmPassword')) {
      setFormError(t('Users:error.invalidPassword'));
    } else {
      const checkPasswordResponse = await checkPassword(values.password);
      if (checkPasswordResponse === true) {
        setFormError('');
      } else {
        const errorCode = get(checkPasswordResponse, 'errorCode', '');
        setFormError(
          t(
            errorCode && isString(errorCode)
              ? `error.passwordRecovery.${errorCode}`
              : 'error.generalPasswordRecoveryError',
          ),
        );
        addErrorAlert(t('passwordNoChanged'));
      }
      if (checkPasswordResponse === true) {
        const response = await passwordRecovery(values.password, values.hash);
        if (response === 'OK') {
          setFormError('');
          addSuccessAlert(t('passwordChanged'));
          navigate('/login');
        } else {
          const errorCode = get(response, 'errorCode', '');
          setFormError(
            t(
              errorCode && isString(errorCode)
                ? `error.passwordRecovery.${errorCode}`
                : 'error.generalPasswordRecoveryError',
            ),
          );
          addErrorAlert(t('passwordNoChanged'));
        }
      }
    }
    toggleLoader(false);
  });

  useEffect(() => {
    reset({ hash: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <LoginAndPasswordRecoveryContent>
      <Box sx={{ maxWidth: { sm: 400 } }}>
        <Papeer sx={{ flex: 0 }}>
          <>
            <Box sx={{ margin: '0 auto' }}>
              <Box>
                <FormProvider {...methods}>
                  <form onSubmit={onSubmit}>
                    <input {...register('hash')} type="hidden" />
                    <FormInput
                      name="password"
                      label={t('newPassword')}
                      type="password"
                      required={true}
                    />
                    <FormInput
                      name="confirmPassword"
                      label={t('Users:confirmPassword')}
                      type="password"
                      required={true}
                    />
                    {passwordPolicyEnabled && (
                      <Box
                        component="ul"
                        sx={{
                          p: 0,
                          pt: 1,
                          m: 0,
                          ml: 2,
                          fontSize: 12,
                          fontWeight: 300,
                        }}
                      >
                        <li>
                          {t('ruleForPasswordRecovery.minimalPasswordLength', {
                            par1: minimalPasswordLength,
                          })}
                        </li>
                        {passwordMustContain.length > 0 && (
                          <li>
                            {t('ruleForPasswordRecovery.mustContain', {
                              par1: join(passwordMustContain, ', '),
                            })}
                          </li>
                        )}
                      </Box>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth={true}
                      data-selenium-selector="login-button"
                      sx={{ mb: 1, mt: 2 }}
                    >
                      {t('Buttons:confirm')}
                    </Button>
                    <Button
                      size="small"
                      color="inherit"
                      variant="contained"
                      onClick={() => navigate('/login')}
                      fullWidth={true}
                    >
                      {t('backToLogin')}
                    </Button>
                  </form>
                </FormProvider>
              </Box>
            </Box>

            <Collapse in={formError ? true : false} timeout="auto">
              {formError && (
                <Typography align="center" color="error" sx={{ mt: 2 }}>
                  {formError}
                </Typography>
              )}
            </Collapse>
          </>
        </Papeer>
      </Box>
    </LoginAndPasswordRecoveryContent>
  );
};
