import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { get, isArray, isObject, sortBy } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  getDataForEditUserNotificationTemplate,
  saveUserNotificationTemplate,
} from './_apiNotificationSettings';
import {
  INotificationSettingForm,
  INotificationTemplate,
  INotificationSettingFormContent,
} from './_types';
import useValidationSchema from './_formNotificationSettings';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormSelect from 'components/Form/Select/Select';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import { Line } from 'components/Line/Line';
import useAlerts from 'components/Alerts/useAlerts';

const NotificationSettingForm: React.FC<INotificationSettingFormContent> = ({
  toggleDialog,
  id,
  loadEntity,
}) => {
  const { t } = useTranslation('NotificationTemplates');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const [entity, fetchEntity] = useState<INotificationTemplate>();
  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);
  const [title, setTitle] = useState<string>('');

  const { NotificationSettingFormSchema } = useValidationSchema(t);

  const methods = useForm<INotificationSettingForm>({
    resolver: yupResolver(NotificationSettingFormSchema),
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    await saveUserNotificationTemplate(values).then(
      () => {
        addSuccessAlert(t('saved'));
        loadEntity();
        toggleDialog(false);
      },
      () => {
        addErrorAlert(t('errorSaving'));
      },
    );
    toggleLoader(false);
  });

  const getEntity = async () => {
    toggleLoader();
    try {
      let notificationTemplate: INotificationTemplate = {};
      if (id) {
        const entity = await getDataForEditUserNotificationTemplate(id.toString());
        notificationTemplate = {
          ...entity,
          reactionTypes: isArray(entity.notificationTemplates)
            ? sortBy(
                entity.notificationTemplates.map((template: any) => ({
                  ...template.reactionType,
                  label: t(template.reactionType.languageKey),
                  id: template.id,
                })),
                ['label'],
              )
            : [],
        };
        if (entity) {
          const key = get(entity, 'eventType.languageKey', '').toLowerCase();
          const tkey = t(key, { returnObjects: true });
          const label = isObject(tkey) ? t(`${key}.default`) : t(key);
          setTitle(`${t('edit')} ${label}`);
          fetchEntity(notificationTemplate);
        }
      }
      reset({ notificationTemplateId: '' });
      setIsFetchingEntity(false);
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isFetchingEntity && (
        <>
          <Header title={title} />
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <Grid container={true} alignItems="flex-end">
                  <Grid item={true} xs={12}>
                    <FormSelect
                      name="notificationTemplateId"
                      label={t('reactionType')}
                      required={true}
                      items={get(entity, 'reactionTypes', [])}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Line />
                  </Grid>
                </Grid>
                <EntityButtons toggleDialog={toggleDialog} />
              </form>
            </FormProvider>
          </Papeer>
        </>
      )}
    </>
  );
};

export default NotificationSettingForm;
