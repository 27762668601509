import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';

import {
  IClinicalPortalDocumentDetail,
  IPatient,
  IPatientRequest,
  IPatientResponse,
} from './_types';

export const findPatient = (data: IPatientRequest): Promise<IPatientResponse> | boolean =>
  api()
    .post(`/portal-api/clinicalPortal/findPatient`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getPatient = (masterPatientID: string): Promise<IPatient> =>
  api()
    .get(`/portal-api/clinicalPortal/getPatientInformation`, {
      params: { masterPatientID },
    })
    .then((response) => {
      return get(response, 'data');
    })
    .catch((error) => false);

export const getDocuments = (
  masterPatientID: any,
  bookmarkId: any,
  facility: string,
): Promise<any> | boolean =>
  api()
    .get(`/portal-api/clinicalPortal/findDocuments`, {
      params: { masterPatientID, bookmarkId, facility },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getDocumentsByTags = (data: any): Promise<any> =>
  api()
    .post(`/portal-api/clinicalPortal/getDocumentsByTags`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const addDocumentTag = (value: any): Promise<any | boolean> =>
  api().post(`/portal-api/clinicalPortal/addDocumentTag`, value);

export const getAllDocumentTags = (documentUuid: string): Promise<any> =>
  api()
    .get(`/portal-api/clinicalPortal/getAllDocumentTags`, { params: { documentUuid } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const setActiveDocumentTag = (documentTagId: number, status: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/clinicalPortal/setActiveDocumentTag`, { params: { documentTagId, status } })
    .then((response) => true)
    .catch((error) => false);

export const getDocument = (
  masterPatientID: any,
  uuid: string,
  typeOfDocument: string,
): Promise<any> =>
  api()
    .get(`/portal-api/clinicalPortal/getDocumentPrintInfo`, {
      params: { masterPatientID, uuid, typeOfDocument },
    })
    .then((response) => {
      return get(response, 'data');
    })
    .catch((error) => false);

export const getDocumentDetail = (
  masterPatientID: any,
  facilities: string[],
  documents: IClinicalPortalDocumentDetail[],
): Promise<any> | boolean =>
  api()
    .post(`/portal-api/clinicalPortal/getDocumentDetail`, {
      masterPatientID,
      facilities,
      documents,
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const sendDocuments = (data: any): Promise<any> | boolean =>
  api()
    .post(`/portal-api/clinicalPortal/sendDocuments`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getPdfDocument = (
  masterPatientID: string,
  uuid: string,
  typeOfDocument: string,
  standardizedPrint: boolean = false,
): Promise<any> =>
  api()
    .get(`/portal-api/clinicalPortal/getPdfDocument`, {
      params: { masterPatientID, uuid, typeOfDocument, standardizedPrint },
    })
    .then((response) => {
      return get(response, 'data');
    })
    .catch((error) => false);

export const downloadPdfDocument = (
  masterPatientID: any,
  uuid: string,
  typeOfDocument: string,
  standardizedPrint: boolean = false,
): Promise<any> =>
  api({ responseType: 'blob' })
    .get(`/portal-api/clinicalPortal/downloadPdfDocument`, {
      params: { masterPatientID, uuid, typeOfDocument, standardizedPrint },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const downloadFromEldax = (
  masterPatientID: any,
  uuid: string,
  typeOfDocument: string,
): Promise<any> =>
  api()
    .post('/portal-api/clinicalPortal/downloadFromEldax', { masterPatientID, uuid, typeOfDocument })
    .then((response) => get(response, 'data'))
    .catch((error) => get(error, 'response.data') || false);

export const checkEldaxDocumentExistence = (
  masterPatientID: any,
  uuid: string,
  typeOfDocument: string,
): Promise<any> =>
  api()
    .post('/portal-api/clinicalPortal/checkEldaxDocumentExistence', {
      masterPatientID,
      uuid,
      typeOfDocument,
    })
    .then((response) => true);

export const getOriginalDocument = (masterPatientID: any, uuid: string): Promise<any> =>
  api()
    .get(`/portal-api/clinicalPortal/getOriginalDocument`, {
      params: { masterPatientID, uuid },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => false);
