import HelperComponent from 'components/Tour/HelperComponent';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

interface ITourFolders {
  type: 'main' | 'edit' | 'details';
  canAdministrateFolders?: boolean;
}

export const TourFolders = ({ type, canAdministrateFolders }: ITourFolders) => {
  const { t } = useTranslation('Reactour');

  const allSteps = () => {
    const steps = {
      ...(canAdministrateFolders ? { '[data-tour="addButton"]': t('folders.addButton') } : {}),
      '[data-tour="common-gridColumns"]': t('common.gridColumns'),
      '[data-tour="common-gridFilters"]': t('common.gridFilters'),
      '.MuiDataGrid-columnHeaders': t('common.headTableWithSortAndFilter'),
      '[data-tour="foldersGrid"]': (
        <>
          {t('common.bodyTable')} {t('folders.bodyTable')}
        </>
      ),
    };
    return steps;
  };

  const allEditSteps = () => {
    const steps = {
      '[data-tour="folderEditForm"]': t('folders.edit.form'),
      '[data-tour="folderUsersAdd"]': t('folders.edit.usersAdd'),
      '[data-tour="folderUsersGrid"]': t('folders.edit.usersGrid'),
    };
    return steps;
  };

  const allDetailSteps = () => {
    const steps = {
      '[data-tour="foldersDetailForm"]': t('folders.detail.form'),
      '[data-tour="foldersDetailTabs"]': t('folders.detail.tabs'),
      '[data-tour="foldersDetailGrids"]': t('folders.detail.grids'),
    };
    return steps;
  };

  let stepsDefinition;
  if (type === 'main') stepsDefinition = allSteps;
  if (type === 'edit') stepsDefinition = allEditSteps;
  if (type === 'details') stepsDefinition = allDetailSteps;

  useReactTour({ stepsDefinition });
  return <HelperComponent />;
};
