import React, { useEffect, useState } from 'react';
import { compact, find, get, includes, isArray } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box, Button, DialogContentText, Grid } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { getDocument, sendDocuments } from './_api';
import { sendStudy } from 'modules/Studies/StudiesSend/_api';
import { IPatientDocument, ISendData, ISendDocument } from './_types';
import { getStudiesByStudyIdentity } from 'modules/Tags/_api';
import { IArchive } from 'modules/Search/_types';
import { IStudy } from 'modules/Mdex/Send/_types';
import RequestAdditionalForm from 'modules/Studies/Form/RequestAdditionalForm';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useToken } from 'utils/hooks/useToken';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { joinParams } from 'utils/study';
import useAlerts from 'components/Alerts/useAlerts';
import Header from 'components/Header/Header';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import { SEJF_SENDFORM_DICOM, SEJF_SENDFORM_PDF } from 'constants/constants';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const selecting = true;
const getRowId = (row: IPatientDocument) => row.uuid;
const muiGridKey = 'documentsSendMUI';

export const DocumentsSend: React.FC = () => {
  const { t } = useTranslation('ClinicalPortal');
  const { toggleLoader } = useAppGlobals();
  const { refreshTokenAndStore } = useToken();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { archivesForFunctions, compactMode, documentsFromStore } = useAppInfo();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const from = searchParams.get('from') || null;
  const linkBack = from ? from : '/clinicPortal';

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isDicom, setIsDicom] = useState<boolean>(false);
  const [archives, setArchives] = useState<IArchive[]>([]);
  const [selection, setSelection] = useState<string[]>([]);

  const canSend = selection.length > 0;

  const methods = useForm();
  const { handleSubmit, watch } = methods;
  const watchAllFields = watch();

  const historyPushWithTimeOut = () => {
    setTimeout(() => {
      navigate(linkBack);
    }, 1500);
  };

  const callApiForSendDocuments = async (data: ISendData) => {
    await refreshTokenAndStore();
    const resp = await sendDocuments(data);
    if (resp) {
      addSuccessAlert(t('submitSendOk'));
      navigate(linkBack);
      if (includes(resp, 'http')) {
        window.open(resp, '_blank');
      }
    } else {
      addErrorAlert(t('submitSendError'));
    }
  };

  const callApiForSendStudies = async (data: ISendData, studies: IStudy[]) => {
    await refreshTokenAndStore();
    const resp = await sendStudy(data, studies);
    if (resp) {
      addSuccessAlert(t('submitSendOk'));
      navigate(linkBack);
      if (includes(resp, 'http')) {
        window.open(resp, '_blank');
      }
    } else {
      addErrorAlert(t('submitSendError'));
    }
  };

  const handleFormSubmit = async (values: any) => {
    toggleLoader();
    const requestComment = get(values, 'requestComment', null) || null;
    let data: ISendData = {};
    const documents: ISendDocument[] = [];
    let studies: IStudy | any[] = [];
    if (isDicom) {
      // pro DICOM odeslat studies
      const studyIdentities: IStudy[] = [];
      const uidsAndArchives = await Promise.all(
        documentsFromStore.map(async (document) => {
          if (includes(selection, get(document, 'uuid', ''))) {
            const documentResponse = await getDocument(
              get(document, 'patient.masterPatientID', null),
              get(document, 'uuid', ''),
              get(document, 'typeOfDocument.name'),
            );
            if (documentResponse) {
              const documentData = get(documentResponse, 'document.data', null);
              if (documentData) {
                const docObject = JSON.parse(documentData);
                return archives.map((ar) => {
                  return {
                    archive: { id: ar.id },
                    studyInstanceUid: get(docObject, 'studyUid', ''),
                  };
                });
              }
            }
          }
          return null;
        }),
      );
      compact(uidsAndArchives).forEach((firstLevel) => {
        if (isArray(firstLevel)) {
          firstLevel.forEach((item) => {
            studyIdentities.push(item);
          });
        }
      });
      const studiesByStudyIdentity = await getStudiesByStudyIdentity({ studyIdentities });
      studies = (isArray(studiesByStudyIdentity) ? studiesByStudyIdentity : []).map((study) => ({
        archive: { id: get(study, 'archive.id', null) },
        studyInstanceUid: get(study, 'studyInstanceUid', null),
      }));
      data = {
        sendForm: SEJF_SENDFORM_DICOM,
        sendType: 'DrSejf',
        requestComment,
      };
      callApiForSendStudies(data, studies);
    } else {
      // odeslat documents
      documentsFromStore.forEach((document) => {
        if (includes(selection, get(document, 'uuid', ''))) {
          documents.push({
            masterPatientID: get(document, 'patient.masterPatientID', ''),
            uuid: get(document, 'uuid', ''),
            typeOfDocument: get(document, 'typeOfDocument.name'),
          });
        }
      });
      data = {
        sendForm: SEJF_SENDFORM_PDF,
        documents,
        requestComment,
      };
      callApiForSendDocuments(data);
    }

    historyPushWithTimeOut();

    toggleLoader(false);
  };

  const callHandleSubmit = async (values: any) => {
    handleFormSubmit(values);
  };

  const onSubmit = handleSubmit(async (values) => {
    callHandleSubmit(values);
  });

  const loadEntities = async () => {
    toggleLoader();
    // If there are no documents -> redirect
    if (documentsFromStore.length < 1) {
      toggleLoader(false);
      return navigate(linkBack);
    } else {
      const uuids: string[] = [];
      documentsFromStore.forEach((document, index: number) => {
        if (index === 0) {
          const allowedTypes = get(document, 'typeOfDocument.allowedFormats', []);
          const isDicom = find(allowedTypes, { name: 'dicom' }) !== undefined;
          setIsDicom(isDicom);
        }
        uuids.push(get(document, 'uuid', ''));
      });
      const archives = get(archivesForFunctions, 'SEARCH', []);
      setArchives(archives);
      setSelection(compact(uuids));
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      { field: 'typeOfDocumentName', headerName: t('typeOfDocument'), width: 200 },
      {
        field: 'name',
        headerName: t('name'),
        valueGetter: (value: any, row: any) =>
          `${joinParams([
            get(row, 'patient.patientLastName'),
            get(row, 'patient.patientFirstName'),
          ])}`,
      },
      {
        field: 'patientID',
        headerName: t('patientID'),
        valueGetter: (value: any, row: any) => get(row, 'patient.patientID'),
      },
      {
        field: 'patientBirthDate',
        headerName: t('patientBirthDate'),
        renderCell: ({ row }: GridRenderCellParams) =>
          format(new Date(get(row, 'patient.patientBirthDate', '')), 'dd.MM.yyyy'),
        valueGetter: (value: any) => value && new Date(value),
      },
      {
        field: 'createdDateTime',
        headerName: t('createdDateTime'),
        renderCell: ({ row }: GridRenderCellParams) =>
          format(new Date(get(row, 'createdDateTime', '')), 'dd.MM.yyyy'),
        valueGetter: (value: any) => value && new Date(value),
      },
      { field: 'author', headerName: t('author'), width: 220 },
    ]),
  );

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const sendingConfirmed = () => {
    setOpenDialog(false);
    callHandleSubmit(watchAllFields);
  };

  const renderedDialog = (
    <OverflowedDialog
      title={t('Studies:document.send_documents.confirmation')}
      open={openDialog}
      onClose={() => {
        setOpenDialog(false);
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
      actions={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '@media print': {
              display: 'none',
            },
          }}
        >
          <Button
            onClick={() => setOpenDialog(false)}
            variant="contained"
            size="large"
            color="inherit"
            sx={{ mr: 1 }}
          >
            {t('Studies:close')}
          </Button>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={sendingConfirmed}
            data-selenium-selector="edit-confirm-button"
          >
            {t('Studies:confirm')}
          </Button>
        </Box>
      }
    >
      <DialogContentText id="alert-dialog-description">
        {t('Studies:selected_documents') + selection.length}
      </DialogContentText>
    </OverflowedDialog>
  );

  return (
    <>
      <Header
        title={t(isDicom ? 'sendDicomDocumentsTitle' : 'sendPdfDocumentsTitle')}
        backUrl={`${linkBack}?action=back`}
      />
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <MuiGrid
            gridKey={muiGridKey}
            getRowId={getRowId}
            rows={documentsFromStore}
            columns={columns}
            rowSelecting={{
              selecting,
              selection,
              setSelection,
            }}
            initialSortMode={[{ field: 'name', sort: 'asc' }]}
          />

          <Grid container={true} spacing={16}>
            <Grid item={true} xs={12}>
              <RequestAdditionalForm isRequest={false} />
            </Grid>
          </Grid>
          <Grid container={true} justifyContent="flex-end">
            <Box
              sx={{
                mr: 2,
                mt: 2,
                '@media print': {
                  display: 'none',
                },
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size={compactMode ? 'small' : 'medium'}
                disabled={!canSend}
                onClick={handleClickOpen}
              >
                <SendIcon sx={{ mr: 1 }} />
                {t('send')}
              </Button>
            </Box>
          </Grid>
        </form>
      </FormProvider>

      {renderedDialog}
    </>
  );
};
