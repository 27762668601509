import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { IOperation, IPriority } from './_types';

export const getOperations = (): Promise<IOperation[]> =>
  api()
    .get(`/portal-api/priorityOperations/getOperations`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const setOperationPriority = (
  idOperation: number,
  idPriority: number,
): Promise<any | boolean> =>
  api().post(`/portal-api/priorityOperations/setOperationPriority`, {
    id: idOperation,
    priority: { id: idPriority },
  });

export const getPriorities = (): Promise<IPriority[]> =>
  api()
    .get(`/portal-api/priorityOperations/getPriorities`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getPriority = (id: string): Promise<IPriority> =>
  api()
    .get(`/portal-api/priorityOperations/get`, { params: { id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editPriority = (operationPriorityModel: IPriority): Promise<boolean> =>
  api()
    .post(`/portal-api/priorityOperations/update`, operationPriorityModel)
    .then((response) => true)
    .catch((error) => false);

export const createPriority = (operationPriorityModel: IPriority): Promise<boolean> =>
  api()
    .post(`/portal-api/priorityOperations/create`, operationPriorityModel)
    .then((response) => true)
    .catch((error) => false);

export const removePriority = (id: number): Promise<boolean> =>
  api()
    .get(`/portal-api/priorityOperations/delete`, { params: { id } })
    .then((response) => true)
    .catch((error) => false);
