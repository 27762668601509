import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { filter, get, isArray, pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Button, IconButton, Link, Tooltip } from '@mui/material';
import LabelIcon from '@mui/icons-material/Label';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { setActiveStudyTag } from './_api';
import StudyDetailTagForm from './StudyDetailTagForm';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useStudy } from 'utils/hooks/useStudy';
import { useActions } from 'utils/hooks/useActions';
import { useAppInfo } from 'utils/hooks/useAppInfo';

import { Line } from 'components/Line/Line';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import { stateIsSame } from 'utils/componentOptimizatons';
import { IStudyDetailTags, IStudyTag } from './_types';
import { useUser } from 'utils/hooks/useUser';
import { Box } from '@mui/system';

const formatDateTime = 'dd. MM. yyyy, HH:mm:ss';

const PlainStudyDetailTags: React.FC<IStudyDetailTags> = ({ study }) => {
  const { t } = useTranslation('StudyDetail');
  const { toggleLoader } = useAppGlobals();
  const { confirmationData } = useAppInfo();
  const { storeConfirmationData } = useActions();
  const { getCommentNameWithLogin, studyIsLocked, getStudyTagsByStudyID } = useStudy();
  const { hasRole, user } = useUser();
  const [studyTags, setStudyTags] = useState<IStudyTag[]>([]);
  const [dialogState, setDialogState] = useState<boolean>(false);

  const getAllStudyTags = useCallback(async () => {
    const archiveID = get(study, 'archive.id');
    const studyID = get(study, 'studyInstanceUid');
    if (archiveID && studyID) {
      toggleLoader();
      const tags = await getStudyTagsByStudyID(archiveID, studyID);
      setStudyTags(tags);
      toggleLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [study]);

  // Load all comments
  useEffect(() => {
    getAllStudyTags();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [study]);

  const isLocked = useMemo(
    () => studyIsLocked(study),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [study],
  );

  const onChangeActive = async (id: string) => {
    toggleLoader();
    const changed = await setActiveStudyTag(id, false);
    if (changed) {
      await getAllStudyTags();
    }
    toggleLoader(false);
  };
  const clearAction = () => {
    storeConfirmationData(null);
  };
  const cancelAction = () => {
    clearAction();
  };
  const confirmAction = async () => {
    onChangeActive(get(confirmationData, 'id'));
    clearAction();
  };

  const renderedStudyTags = useMemo(() => {
    return (
      <>
        {hasRole('ROLE_CAN_ADD_NEW_TAG') ? (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              setDialogState(true);
            }}
            sx={{ mb: 0.5 }}
          >
            {t('action.addTag')}
          </Button>
        ) : null}
        {isArray(studyTags) && studyTags.length
          ? studyTags.map((studyTag, index) => {
              const createdByAuthor = getCommentNameWithLogin(get(studyTag, 'createdBy'));
              const commentCreatedByLoggedUser =
                get(studyTag, 'createdBy.username') === get(user, 'sub');
              return (
                <React.Fragment key={studyTag.id}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip title={t('studyTagsCreateTitle') as String}>
                      <div>
                        {get(studyTag, 'createdBy.email') ? (
                          <Link
                            href={`mailto: ${studyTag.createdBy.email}`}
                            underline="none"
                            sx={{ color: (theme) => theme.palette.success.main }}
                          >
                            {createdByAuthor}
                          </Link>
                        ) : (
                          createdByAuthor
                        )}{' '}
                        - {format(new Date(studyTag.createdWhen), formatDateTime)}
                      </div>
                    </Tooltip>

                    {!isLocked && (
                      <>
                        {filter(
                          [
                            {
                              title: 'studyTagsDelete',
                              children: <DeleteIcon />,
                              onClick: () => {
                                storeConfirmationData({
                                  id: studyTag.id,
                                });
                              },
                              show: hasRole('ROLE_TAGS') || commentCreatedByLoggedUser,
                            },
                          ],
                          'show',
                        ).map((button) => (
                          <Tooltip key={button.title} title={t(button.title) as String}>
                            <IconButton
                              {...pick(button, ['children', 'onClick'])}
                              sx={{ padding: (theme) => theme.spacing(1 / 4), ml: 1 }}
                              size="large"
                            />
                          </Tooltip>
                        ))}
                      </>
                    )}
                  </Box>
                  <div>
                    {studyTag.value.split('*^*').map((item: any) => {
                      let j = 0;
                      return (
                        <div key={item}>
                          <Box
                            sx={{
                              display: 'inline-flex',
                              border: '1px solid #ccc',
                              borderRadius: 20,
                              marginTop: 1,
                            }}
                          >
                            <LabelIcon sx={{ fontSize: 20, color: '#676a6c', mr: 1, ml: 1 }} />
                            {item
                              .substr(1)
                              .split('#')
                              .map((tag: any) => {
                                j++;
                                return (
                                  <Box
                                    component="span"
                                    sx={{ mr: 1, display: 'inline-flex' }}
                                    key={tag}
                                  >
                                    {j > 1 && <KeyboardArrowRightIcon />}
                                    {tag}
                                  </Box>
                                );
                              })}
                          </Box>
                        </div>
                      );
                    })}
                  </div>
                  {++index < studyTags.length ? <Line topMargin={2} bottomMargin={2} /> : null}
                </React.Fragment>
              );
            })
          : null}
      </>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyTags]);

  return (
    <>
      {renderedStudyTags}
      {
        <OverflowedDialog
          open={dialogState}
          onClose={() => setDialogState(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}
          title={t('dialog.tag')}
        >
          <StudyDetailTagForm
            setDialogState={setDialogState}
            getStudyTags={getAllStudyTags}
            study={study}
            studies={[study]}
          />
        </OverflowedDialog>
      }
      {confirmationData && confirmationData.id && (
        <ConfirmationDialog
          title={t('confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={cancelAction}
          confirmAction={confirmAction}
        />
      )}
    </>
  );
};

export const StudyDetailTags = React.memo(PlainStudyDetailTags, stateIsSame);
