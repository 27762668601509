import { useMemo } from 'react';
import { Tooltip } from '@mui/material';
import ViewerIcon from '@mui/icons-material/Image';
import ECGIcon from '@mui/icons-material/Favorite';
import IsNoOkIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { get, filter, includes, pick, remove } from 'lodash';
import { useTranslation } from 'react-i18next';

import { decodeIID } from 'utils/study';
import { getUrlForOpenViewer } from 'modules/Studies/StudyDetail/_api';
import {
  SEARCH_FUNCTION_REORDER,
  SELECT_STUDY_TO_IMPORT,
  VIEWER,
  DETAIL,
  ECG,
} from 'constants/constants';
import { useFunctionList } from './useFunctionList';
import { IStudyResultForGrid } from 'modules/Search/_types';
import { IFunctionItem, IActionItem } from './_types';
import { useStudy } from 'utils/hooks/useStudy';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import CheckIcon from '@mui/icons-material/Check';
import useAlerts from 'components/Alerts/useAlerts';
import { exportECG } from 'modules/Search/_api';
import { apiDataToDownloadFile } from 'utils/hooks/useApp';
import { useActions } from 'utils/hooks/useActions';

export const useGridActions = ({
  actions,
  operations,
  isRequest = false,
  isFromColumn = false,
  setSelection,
  handleCloseDialog,
  importProps,
  disableStudyAndPatientForm,
}: any) => {
  const { t } = useTranslation('SearchResults');
  const { functionList } = useFunctionList({
    actions,
    operations,
    isRequest,
    isFromColumn,
    setSelection,
  });
  const { checkAllowedArchives } = useStudy();
  const { sourceStudyForReorderIID, sourceStudyForReorderIsInStore } = useStudyInfo();
  const { addErrorAlert } = useAlerts();
  const { resetStudies } = useActions();

  const allActions: IActionItem[] = useMemo(
    () => [
      {
        name: ECG.toLowerCase(),
        action: (row: IStudyResultForGrid) => {
          return includes(get(row, 'modalitiesInStudy', []), 'ECG') ? (
            <GridActionsCellItem
              icon={
                <Tooltip title={t('downloadECG')}>
                  <ECGIcon />
                </Tooltip>
              }
              label={t('openInBrowserTitle')}
              onClick={async () => {
                const { studyID, archiveID } = decodeIID(row.iid);
                const resp = await exportECG(archiveID, studyID);
                const data = get(resp, 'data', null);
                if (data) {
                  const filename = get(
                    get(resp, 'headers.content-disposition').split('='),
                    '[1]',
                    'ekg.dcm',
                  );
                  apiDataToDownloadFile(data, filename);
                } else {
                  addErrorAlert(t('downloadEcgError'));
                }
              }}
            />
          ) : (
            <></>
          );
        },
      },
      //Select button
      {
        name: SELECT_STUDY_TO_IMPORT.toLowerCase(),
        action: (row: IStudyResultForGrid) => {
          return (
            <GridActionsCellItem
              onClick={() => {
                const { setFormValues } = importProps;
                const values = {
                  ...pick(row, [
                    'accessionNumber',
                    'referringPhysician',
                    'requestingPhysician',
                    'performingPhysician',
                    'patientDispositions',
                    'studyInstanceUid',
                  ]),
                  patient: {
                    ...row.patient,
                    dateBirth: row.patient?.dateBirth ? new Date(row.patient.dateBirth) : null,
                  },
                  study: {
                    // ...pick(row, [
                    //   'accessionNumber',x
                    //   'referringPhysician',
                    //   'requestingPhysician',
                    //   'patientDispositions',
                    //   'studyInstanceUid',
                    // ]),
                    accessionNumber: get(row, 'accessionNumber', ''),
                    studyDescription: get(row, 'studyDescription', ''),
                    patientDisposition: get(row, ''),
                    studyInstanceUid: get(row, 'studyInstanceUid', ''),
                    // studyDate: get(row, 'originalDatetimeRequested', null)
                    //   ? new Date(row.originalDatetimeRequested)
                    //   : null,
                    // studyTime: get(row, 'originalDatetimeRequested', null)
                    //   ? new Date(row.originalDatetimeRequested)
                    //   : null,
                  },
                  archiveId: get(row, 'archiveId', ''),
                };
                setFormValues('patient', values.patient);
                setFormValues('study', values.study);
                setFormValues('archiveId', get(row, 'archiveId', ''));
                setFormValues(
                  'modality',
                  get(row, 'modalitiesInStudy', []).map((modality: string) => ({
                    label: modality,
                    value: modality,
                  })),
                );
                setFormValues('referringPhysician', get(row, 'referringPhysician'));
                setFormValues('requestingPhysician', get(row, 'requestingPhysician'));
                setFormValues('performingPhysician', get(row, 'performingPhysician'));
                handleCloseDialog();
                disableStudyAndPatientForm(true);
              }}
              icon={
                <Tooltip title={t('importIcon')}>
                  <CheckIcon sx={{ width: 16, height: 16, marginRight: 1 }} />
                </Tooltip>
              }
              label={t('selectForImport')}
            />
          );
        },
      },

      {
        name: VIEWER.toLowerCase(),
        action: (row: IStudyResultForGrid) => {
          const isOk = get(row, 'ok', true);
          return isOk && get(row, 'containsAllSeries', true) ? (
            <GridActionsCellItem
              icon={
                <Tooltip title={t('openInBrowserTitle')}>
                  <ViewerIcon />
                </Tooltip>
              }
              label={t('openInBrowserTitle')}
              disabled={!isOk}
              onClick={() => {
                const { studyID, archiveID } = decodeIID(row.iid);
                const patientId = get(row, 'patientId', '');
                getUrlForOpenViewer(studyID, patientId, archiveID).then((response) =>
                  response
                    ? window.open(response, '_blank')
                    : addErrorAlert(t('Studies:cannotOpenLink')),
                );
              }}
            />
          ) : (
            <GridActionsCellItem
              icon={
                <Tooltip title={t('isNoOk')}>
                  <IsNoOkIcon />
                </Tooltip>
              }
              label={t('isNoOk')}
            />
          );
        },
      },
      ...(sourceStudyForReorderIsInStore
        ? [
            {
              name: VIEWER.toLowerCase(),
              action: (row: IStudyResultForGrid) =>
                sourceStudyForReorderIID === row.iid ? (
                  <GridActionsCellItem
                    icon={
                      <Tooltip title={t('Studies:removeSourceStudyForReorder')}>
                        <DeleteIcon fontSize="small" />
                      </Tooltip>
                    }
                    label={t('Studies:removeSourceStudyForReorder')}
                    onClick={async () => {
                      resetStudies('reorder');
                    }}
                  />
                ) : (
                  <></>
                ),
            },
          ]
        : []),
      // práce se studiemi
      ...functionList.map((item: IFunctionItem) => ({
        name: item.key.toLowerCase(),
        action: (row: IStudyResultForGrid) => {
          let itemIsDisabled = !checkAllowedArchives(item.key, [row]);
          if (
            item.key === SEARCH_FUNCTION_REORDER &&
            sourceStudyForReorderIsInStore &&
            sourceStudyForReorderIID === row.iid
          ) {
            itemIsDisabled = true;
          }

          if (item.key === DETAIL && !get(row, 'ok', true)) {
            itemIsDisabled = true;
          }

          // pokud jsou maximálně 3 akce (např. při přeskupení nebo v dočasné složce nebo v detailu prac. seznamu),
          // tak se akce nezobrazí v menu a přidá se k ní tooltip
          const actionsWithoutRemoveAllStudies = [...actions];
          remove(actionsWithoutRemoveAllStudies, (action) => {
            return (
              action === 'removeAllStudiesFromBasket' || action === 'removeAllStudiesFromFolder'
            );
          });
          return actionsWithoutRemoveAllStudies.length <= 3 || sourceStudyForReorderIsInStore ? (
            <>
              {!sourceStudyForReorderIsInStore || sourceStudyForReorderIID !== row.iid ? (
                <Tooltip title={item.label}>
                  <GridActionsCellItem
                    icon={item.icon}
                    label={item.label}
                    onClick={() => item.onClick(row)}
                    showInMenu={false}
                    disabled={itemIsDisabled}
                  />
                </Tooltip>
              ) : null}
            </>
          ) : (
            <GridActionsCellItem
              icon={item.icon}
              label={item.label}
              onClick={() => item.onClick(row)}
              showInMenu={true}
              disabled={itemIsDisabled}
            />
          );
        },
      })),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, functionList, checkAllowedArchives],
  );

  const actionsToShow = useMemo(
    () =>
      filter(allActions, (action: IActionItem) =>
        includes(
          actions.map((act: string) => act.toLowerCase()),
          action.name.toLowerCase(),
        ),
      ),
    [actions, allActions],
  );

  return {
    actionsToShow,
  };
};
