import { api } from 'utils/hooks/useApi';
import { get, isObject } from 'lodash';
import { IComment, IExportComment, ISRLanguage, ISRDataTypes, ISerie } from './_types';
import { FORBIDDEN } from 'constants/constants';
import { IRegisterItem } from 'components/TagForm/_types';

export const getStudy = (productId: string, studyUid: string): Promise<any> =>
  api()
    .get(`/portal-api/study/getStudy`, { params: { productId, studyUid } })
    .then((response) => get(response, 'data'))
    .catch((error) => {
      // stutus 403 is returned from API when user doesn't have permission to view study
      if (error.response.status === 403) {
        return FORBIDDEN;
      }
      return false;
    });

export const searchWithoutAccessControlGetFromTimeline = (
  paramsProductId: string,
  paramsStudUid: string,
  productId: string,
  studyUid: string,
): Promise<any> =>
  api()
    .post(`/portal-api/study/searchWithoutAccessControl`, {
      authorizationObject: {
        studyIdentity: {
          archive: {
            id: paramsProductId,
          },
          studyInstanceUid: paramsStudUid,
        },
      },
      function: 'GET_FROM_TIMELINE',
      searchCriteria: {
        exactSearch: true,
        studyIdentities: [
          {
            archive: {
              id: productId,
            },
            studyInstanceUid: studyUid,
          },
        ],
      },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => {
      return false;
    });

export const searchWithoutAccessControlForTimeline = (
  productId: string,
  studyUid: string,
  patientID: any,
  sources: any,
): Promise<boolean> =>
  api()
    .post(`/portal-api/study/searchWithoutAccessControl`, {
      authorizationObject: {
        studyIdentity: {
          archive: {
            id: productId,
          },
          studyInstanceUid: studyUid,
        },
      },
      function: 'FOR_TIMELINE',
      searchCriteria: {
        exactSearch: true,
        patient: { id: patientID },
        source: {
          sources,
        },
      },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => {
      // stutus 403 is returned from API when user doesn't have permission to view study
      if (error.response.status === 403) {
        return FORBIDDEN;
      }
      return false;
    });

export const getSeriesForFolder = (
  productId: string,
  studyUid: string,
  folderId: string,
): Promise<ISerie[] | false> =>
  api()
    .get(`/portal-api/folder/getSeries`, { params: { productId, studyUid, folderId } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getSeries = (productId: number, studyUid: string): Promise<ISerie[] | false> =>
  api()
    .get(`/portal-api/study/getSeries`, { params: { productId, studyUid } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getInstances = (
  productId: string,
  studyUid: string,
  seriesUid: string,
): Promise<any> =>
  api()
    .get(`/portal-api/study/getInstances`, { params: { productId, studyUid, seriesUid } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getValuesFromDicomFile = (
  productId: number,
  studyUID: string,
  dicomTags: any,
): Promise<any | boolean> =>
  api()
    .post(
      `/portal-api/studyDetail/getValuesFromDicomFile?productId=${productId}&studyUID=${studyUID}`,
      dicomTags,
    )
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getStatusOfStudy = (productId: string, studyUID: string): Promise<any> =>
  api()
    .get(`/portal-api/studyDetail/statusOfStudy`, { params: { productId, studyUID } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const reopenStudy = (productId: string, studyUID: string): Promise<any> =>
  api()
    .get(`/portal-api/study/reopen`, { params: { productId, studyUID } })
    .then((response) => get(response, 'data'))
    .catch((error) => {
      // stutus 403 is returned from API when user doesn't have permission to view study
      if (error.response.status === 403) {
        return FORBIDDEN;
      }
      return false;
    });

export const getAllComments = (comment: any): Promise<any> =>
  api()
    .post(`/portal-api/studyDetail/getAllCommentsByProductAndStudyUID`, comment)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getComment = (id: string): Partial<Promise<IComment> | boolean> =>
  api()
    .get(`/portal-api/studyDetail/getComment`, { params: { id } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const editComment = (comment: IComment): Promise<boolean> =>
  api()
    .post(`/portal-api/studyDetail/editComment`, comment)
    .then((response) => true)
    .catch((error) => false);

export const createComment = (comment: IComment): Promise<boolean> =>
  api()
    .post(`/portal-api/studyDetail/createComment`, comment)
    .then((response) => true)
    .catch((error) => false);

export const setActiveComment = (id: string, isActive: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/studyDetail/setActiveComment`, { params: { id, isActive } })
    .then((response) => true)
    .catch((error) => false);

export const exportComment = (commentExport: IExportComment): Promise<boolean> =>
  api()
    .post(`/portal-api/studyDetail/exportComment`, commentExport)
    .then((response) => true)
    .catch((error) => false);

export const getAllStudyTags = (data: any): Promise<any> =>
  api()
    .post(`/portal-api/studyDetail/getAllStudyTagsByProductAndStudyUid`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const setActiveStudyTag = (studyTagId: string, status: boolean): Promise<boolean> =>
  api()
    .get(`/portal-api/studyDetail/setActiveStudyTag`, { params: { studyTagId, status } })
    .then((response) => true)
    .catch((error) => false);

export const getUrlForOpenViewer = (
  studyUID: string,
  patientId: string,
  productId: string,
  seriesUid: string | null = null,
  sopUid: string | null = null,
): Promise<any> =>
  api()
    .get(`/portal-api/studyDetail/getUrlForOpenViewer`, {
      params: { studyUID, patientId, productId, seriesUid, sopUid },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getUrlForOpenAllInViewer = (data: any): Promise<any> =>
  api()
    .post(`/portal-api/studyDetail/getUrlForOpenAllInViewer`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const addStudyTag = (value: any): Promise<any | boolean> =>
  api().post(`/portal-api/studyDetail/addStudyTag`, value);

export const createOrUpdateRegisterItem = (registerItem: IRegisterItem): Promise<boolean> =>
  api()
    .post(`/portal-api/registerGroup/registerItemCreateOrUpdate`, registerItem)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const deleteStudy = (studyUID: string, productId: number): Promise<boolean> =>
  api()
    .get(`/portal-api/studyDetail/delete`, { params: { studyUID, productId } })
    .then((response) => true)
    .catch((error) => false);

export const getSuccessfullyOperations = (productId: string, studyUID: string): Promise<any> =>
  api()
    .get(`/portal-api/studyDetail/getSuccessfullyOperations`, { params: { productId, studyUID } })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllSRLanguages = (): Promise<ISRLanguage[] | boolean> =>
  api()
    .get(`/portal-api/srDictionaries/getAllLanguages`, {})
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllSRDataTypes = (): Promise<ISRDataTypes[]> | boolean =>
  api()
    .get(`/portal-api/srDictionaries/getAllDataTypes`, {})
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getSrMacrodictionary = (): Promise<any[]> =>
  api()
    .get(`/portal-api/srDictionaries/getDictionary`, {})
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const createSR = (values: any): Promise<boolean> =>
  api()
    .post(`/portal-api/studyDetail/createSR`, values)
    .then((response) => true)
    .catch((error) => false);

export const getPreview = (
  productId: string,
  studyUID: string,
  seriesUID: string,
  imageUID: string,
): Promise<any> =>
  api()
    .get(`/portal-api/studyDetail/getPreview`, {
      params: { productId, studyUID, seriesUID, imageUID },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getPreviewWithSize = (
  productId: string,
  studyUID: string,
  seriesUID: string,
  imageUID: string | undefined,
  size: number,
): Promise<any> =>
  api()
    .get(`/portal-api/studyDetail/getPreview`, {
      params: { productId, studyUID, seriesUID, imageUID, size },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getDicomAttributes = (dicomAttributes: any): Promise<any> =>
  api()
    .get(`/portal-api/studyDetail/getDicomAttributes`, { params: dicomAttributes })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const createPrintablePDF = (data: any): Promise<any> =>
  api()
    .post(`/portal-api/studyDetail/createPrintablePDF`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const deleteSeries = (data: any): Promise<any> =>
  api()
    .post(`/portal-api/studyDetail/deleteSeries`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const deleteImages = (data: any): Promise<any> =>
  api()
    .post(`/portal-api/studyDetail/deleteImages`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getReport = (productId: number, studyUid: string): Promise<boolean> =>
  api()
    .post(`/portal-api/studyExport/getStudyReport`, { productId, studyUid })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getDose = async (uid: string): Promise<any | false> => {
  try {
    return await api()
      .get(`/portal-api/studyDose/getDose`, { params: { uid } })
      .then((response) => {
        const data = get(response, 'data');
        return isObject(data) ? { ...data, uid } : null;
      })
      .catch((error) => false);
  } catch (e) {
    return false;
  }
};

export const getAllStudyDetailBookmarks = (): Promise<any> =>
  api()
    .get(`/portal-api/studyDetailBookmarks/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getDecubitFromDicom = (
  productId: string,
  studyUID: string,
  seriesUID: string,
  imageUID: string | any = null,
): Promise<any> =>
  api()
    .get(`/portal-api/studyDetail/getDecubitFromDicom`, {
      params: { productId, studyUID, seriesUID, imageUID },
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getStudyStatuses = (): Promise<boolean> =>
  api()
    .get(`/portal-api/study/getStudyStatuses`, {})
    .then((response) => get(response, 'data'))
    .catch((thrown) => {
      return false;
    });

export const setStudyStatuses = (data: any): Promise<any> =>
  api()
    .post(`/portal-api/study/setStudyStatuses`, data)
    .then((response) => get(response, 'data'))
    .catch((error) => false);
