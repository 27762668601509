import { format } from 'date-fns';
import { TFunction } from 'i18next';
import {
  compact,
  filter,
  find,
  first,
  get,
  isEmpty,
  isNull,
  last,
  omit,
  omitBy,
  set,
  sortBy,
  uniq,
  values,
} from 'lodash';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { requestedUserName } from './_grid';
import { useStudy } from 'utils/hooks/useStudy';
import {
  requestHasStatus,
  requestStatusIncludes,
  userHasIssuedRequest,
  userHasRightForRequestOperationType,
} from 'utils/hooks/useRequest';
import {
  OPERATION_TYPE_COPY,
  OPERATION_TYPE_CREATE_SR,
  OPERATION_TYPE_EDIT_PATIENT,
  OPERATION_TYPE_EDIT_STUDY,
  OPERATION_TYPE_MOVE,
  OPERATION_TYPE_MOVE_TO_FOLDER,
  OPERATION_TYPE_MOVE_TO_TRASH,
  OPERATION_TYPE_REORDER,
  OPERATION_TYPE_SEND,
  OPERATION_TYPE_SPLIT,
  SEARCH_FUNCTION_EDIT_PATIENT,
  SEARCH_FUNCTION_EDIT_STUDY,
  STATE_ABORTED,
  STATE_APPROVED,
  STATE_FINISHED,
  STATE_NEW,
  STATE_REJECTED,
} from 'constants/constants';

const formatDateTime = 'dd. MM. yyyy, HH:mm';
const formatDate = 'dd. MM. yyyy';
const toolbarButtonIcon = { fontSize: 20, mr: 1 };

const useItemsForRequestDetail = (
  rawRequest: any,
  folders: any[],
  products: any,
  user: any,
  isQueue: boolean,
  hasRole: (role: string) => boolean,
  callUrl: (url: string) => void,
  setRequestState: (request: any, state: any, title: any) => void,
  t: TFunction,
) => {
  const { getPatientSexSlug, lowerCaseFirstLetter } = useStudy();

  let request: any = null;
  if (!isEmpty(products) && rawRequest && folders && user) {
    request = { ...rawRequest };
    if (request) {
      const comments = get(request, 'comments', []);
      const history = get(request, 'history', []);
      const data = get(request, 'data');
      const studyRequestStudies = sortBy(get(request, 'studyRequestStudies'), 'id');
      const reqId = get(request, 'id', 0);
      const requestedStudies = studyRequestStudies.map((study: any) => {
        return get(study, 'requested', null)
          ? {
              operationType: get(request, 'operationType', ''),
              studyType: 'requested',
              state: get(study, 'state', ''),
              reqId,
              ...get(study, 'requested', null),
            }
          : {};
      });
      const providedStudies = studyRequestStudies.map((study: any) => {
        return get(study, 'provided', null)
          ? {
              operationType: get(request, 'operationType', ''),
              studyType: 'provided',
              state: get(study, 'state', ''),
              reqId,
              ...get(study, 'provided', null),
            }
          : {};
      });
      const operationType = get(request, 'operationType', '').toLowerCase();
      let vars = {};
      let oldPatient = {};
      let std = {};
      if (operationType === OPERATION_TYPE_SEND.toLowerCase()) {
        vars = {
          ...(get(data, 'sendType')
            ? { sendType: t('data.sendType.' + get(data, 'sendType')) }
            : {}),
          ...(get(data, 'drSejfAccount') ? { drSejfAccount: get(data, 'drSejfAccount') } : {}),
          ...(get(data, 'exchangeNetwork.name')
            ? { exchangeNetwork: get(data, 'exchangeNetwork.name') }
            : {}),
          ...(get(data, 'facility.name') ? { facility: get(data, 'facility.name') } : {}),
          ...(get(data, 'includeStudyReport')
            ? { includeStudyReport: get(data, 'includeStudyReport', null) ? t('yes') : t('no') }
            : {}),
          ...(get(data, 'viewer')
            ? { viewer: get(data, 'viewer', null) ? t('yes') : t('no') }
            : {}),
        };
        std = {
          ...{ requestedStudies },
        };
      } else if (
        operationType === OPERATION_TYPE_EDIT_STUDY.toLowerCase() ||
        operationType === OPERATION_TYPE_EDIT_PATIENT.toLowerCase()
      ) {
        const patientName = {
          ...(get(data, 'patient.name.lastName')
            ? { patientLastName: get(data, 'patient.name.lastName') }
            : {}),
          ...(get(data, 'patient.name.firstName')
            ? { patientFirstName: get(data, 'patient.name.firstName') }
            : {}),
          ...(get(data, 'patient.name.middleName')
            ? { patientMiddleName: get(data, 'patient.name.middleName') }
            : {}),
          ...(get(data, 'patient.name.prefix')
            ? { patientPrefix: get(data, 'patient.name.prefix') }
            : {}),
          ...(get(data, 'patient.name.suffix')
            ? { patientSuffix: get(data, 'patient.name.suffix') }
            : {}),
        };
        const referringPhysician = {
          ...(get(data, 'referringPhysician.name.lastName')
            ? { referringPhysicianLastName: get(data, 'referringPhysician.name.lastName') }
            : {}),
          ...(get(data, 'referringPhysician.name.firstName')
            ? { referringPhysicianFirstName: get(data, 'referringPhysician.name.firstName') }
            : {}),
          ...(get(data, 'referringPhysician.name.middleName')
            ? { referringPhysicianMiddleName: get(data, 'referringPhysician.name.middleName') }
            : {}),
          ...(get(data, 'referringPhysician.name.prefix')
            ? { referringPhysicianPrefix: get(data, 'referringPhysician.name.prefix') }
            : {}),
          ...(get(data, 'referringPhysician.name.suffix')
            ? { referringPhysicianSuffix: get(data, 'referringPhysician.name.suffix') }
            : {}),
        };
        const requestingPhysician = {
          ...(get(data, 'requestingPhysician.name.lastName')
            ? { requestingPhysicianLastName: get(data, 'requestingPhysician.name.lastName') }
            : {}),
          ...(get(data, 'requestingPhysician.name.firstName')
            ? { requestingPhysicianFirstName: get(data, 'requestingPhysician.name.firstName') }
            : {}),
          ...(get(data, 'requestingPhysician.name.middleName')
            ? {
                requestingPhysicianMiddleName: get(data, 'requestingPhysician.name.middleName'),
              }
            : {}),
          ...(get(data, 'requestingPhysician.name.prefix')
            ? { requestingPhysicianPrefix: get(data, 'requestingPhysician.name.prefix') }
            : {}),
          ...(get(data, 'requestingPhysician.name.suffix')
            ? { requestingPhysicianSuffix: get(data, 'requestingPhysician.name.suffix') }
            : {}),
        };
        const studyData = {
          ...(get(data, 'studyDescription')
            ? { studyDescription: get(data, 'studyDescription') }
            : {}),
          ...(get(data, 'studyDate')
            ? { studyDate: format(new Date(get(data, 'studyDate')), formatDate) }
            : {}),
          ...(get(data, 'studyTime') ? { studyTime: get(data, 'studyTime') } : {}),
          ...(get(data, 'accessionNumber')
            ? { accessionNumber: get(data, 'accessionNumber') }
            : {}),
        };
        vars = {
          ...patientName,
          ...(get(data, 'patient.identificationNumber')
            ? { patientId: get(data, 'patient.identificationNumber') }
            : {}),
          patientDateBirth: get(data, 'patient.dateBirth')
            ? format(new Date(get(data, 'patient.dateBirth')), formatDate)
            : null,
          ...(get(data, 'patient.sex')
            ? { patientSex: t(getPatientSexSlug(get(data, 'patient.sex'))) }
            : {}),
          ...studyData,
          ...referringPhysician,
          ...requestingPhysician,
        };
        if (operationType === OPERATION_TYPE_EDIT_PATIENT.toLowerCase()) {
          oldPatient = omitBy(
            {
              patientLastName: get(data, 'oldPatient.name.lastName'),
              patientFirstName: get(data, 'oldPatient.name.firstName'),
              patientMiddleName: get(data, 'oldPatient.name.middleName'),
              patientPrefix: get(data, 'oldPatient.name.prefix'),
              patientSuffix: get(data, 'oldPatient.name.suffix'),
              patientId: get(data, 'oldPatient.identificationNumber'),
              patientDateBirth: get(data, 'oldPatient.dateBirth')
                ? format(new Date(get(data, 'oldPatient.dateBirth')), formatDate)
                : null,
              patientSex: t(getPatientSexSlug(get(data, 'oldPatient.sex'))),
            },
            isNull,
          );
        }
        std = {
          requestedStudies,
          ...(get(request, 'status') === STATE_FINISHED ? { providedStudies } : {}),
        };
      } else if (operationType === OPERATION_TYPE_REORDER.toLowerCase()) {
        let series = 0;
        let images = 0;
        values(get(data, 'series')).forEach((val) => {
          series++;
          images = images + val.length;
        });
        vars = {
          series,
          images,
        };
        std = {
          ...{
            sourceStudy:
              get(request, 'status') === STATE_FINISHED
                ? first(providedStudies)
                : first(requestedStudies),
          },
          ...{
            targetStudy:
              get(request, 'status') === STATE_FINISHED
                ? last(providedStudies)
                : last(requestedStudies),
          },
        };
      } else if (operationType === OPERATION_TYPE_SPLIT.toLowerCase()) {
        let series = 0;
        let images = 0;
        const modalities: any[] = [];
        get(data, 'targetStudy.listOfSeries').forEach((serie: any) => {
          modalities.push(get(serie, 'modality', null));
          series++;
          images = images + get(serie, 'image').length;
        });
        set(data, 'targetStudy.numberOfStudyRelatedSeries', series);
        set(data, 'targetStudy.numberOfStudyRelatedInstances', images);
        set(data, 'targetStudy.archive', get(data, 'sourceStudy.archive'));
        vars = {
          includeComments: get(data, 'includeComments', false) ? t('yes') : t('no'),
          series,
          images,
        };
        std = {
          ...{
            sourceStudy:
              get(request, 'status') === STATE_FINISHED
                ? find(requestedStudies, ['state', STATE_FINISHED])
                : first(requestedStudies),
          },
          ...(get(request, 'status') === STATE_FINISHED
            ? {
                sourceNewStudy: find(providedStudies, ['state', STATE_FINISHED]),
              }
            : {}),
          ...{
            targetStudy:
              get(request, 'status') === STATE_FINISHED
                ? find(providedStudies, ['state', STATE_NEW])
                : {
                    ...get(request, 'data.targetStudy'),
                    modalitiesInStudy: uniq(compact(modalities)),
                  },
          },
        };
      } else if (
        operationType === OPERATION_TYPE_COPY.toLowerCase() ||
        operationType === OPERATION_TYPE_MOVE.toLowerCase()
      ) {
        vars = {
          archiveTo: get(data, 'productTo.name', '?'),
        };
        std = {
          requestedStudies,
          ...(get(request, 'status') === STATE_FINISHED ? { providedStudies } : {}),
        };
      } else if (operationType === OPERATION_TYPE_MOVE_TO_FOLDER.toLowerCase()) {
        vars = {
          selectFolders: folders,
        };
        std = {
          ...{ requestedStudies },
        };
      } else if (operationType === OPERATION_TYPE_MOVE_TO_TRASH.toLowerCase()) {
        vars = {};
        std = { ...{ requestedStudies } };
      } else if (operationType === OPERATION_TYPE_CREATE_SR.toLowerCase()) {
        vars = {};
        std = { ...{ providedStudies } };
      }

      if (get(data, 'deidentifyProfile')) {
        let update = {};
        if (get(data, 'deidentifyProfile.name') === 'update') {
          get(data, 'deidentifyProfile.values', []).forEach((item: any) => {
            if (item.name === 'PatientName') {
              const name = item.value.split('^');
              update = {
                ...update,
                ...(get(name, '[0]') ? { patientLastName: get(name, '[0]') } : {}),
                ...(get(name, '[1]') ? { patientFirstName: get(name, '[1]') } : {}),
                ...(get(name, '[2]') ? { patientMiddleName: get(name, '[2]') } : {}),
                ...(get(name, '[3]') ? { patientPrefix: get(name, '[3]') } : {}),
                ...(get(name, '[4]') ? { patientSuffix: get(name, '[4]') } : {}),
              };
            } else {
              update = { ...update, [lowerCaseFirstLetter(item.name)]: item.value };
            }
          });
        }
        vars = {
          ...vars,
          anonymization: get(data, 'deidentifyProfile.name'),
          ...update,
        };
      }

      let inArchive = '';
      if (operationType === OPERATION_TYPE_EDIT_PATIENT.toLowerCase()) {
        inArchive = get(request, 'data.source.sources')
          ? get(request, 'data.source.sources', '')[0]
          : '';
        if (inArchive) {
          const product = find(products, ['id', inArchive]);
          if (!isEmpty(product)) {
            inArchive = get(product, 'name', inArchive);
          }
        }
      }
      request = {
        title: `${t(isQueue ? 'queueRequestDetail' : 'requestDetail')} - ${t(
          operationType === OPERATION_TYPE_COPY.toLowerCase() ||
            operationType === OPERATION_TYPE_MOVE.toLowerCase()
            ? get(request, 'data.distributeMethod')
            : operationType,
        )}`,
        basic: {
          operationType: `${t(operationType)} ${inArchive ? t('inArchive') + inArchive : ''}`,
          requestingUser: requestedUserName(request),
          createdWhen: format(new Date(get(request, 'createdWhen', '')), formatDateTime),
          state: t(get(request, 'status', '').toLowerCase()),
        },
        comments,
        std,
        history,
        data: vars,
        oldPatient,
        operationType,
      };
    } else {
      request = false;
    }
  }
  const operation = get(rawRequest, 'operationType');
  const buttons = [
    {
      show:
        requestHasStatus(rawRequest, STATE_NEW) &&
        userHasRightForRequestOperationType(rawRequest, hasRole),
      onClick: () => {
        setRequestState(rawRequest, STATE_APPROVED, t('requestApprove'));
      },
      children: (
        <>
          <CheckCircleIcon sx={toolbarButtonIcon} />
          {t('requestApprove')}
        </>
      ),
    },
    {
      show:
        requestHasStatus(rawRequest, STATE_NEW) &&
        userHasRightForRequestOperationType(rawRequest, hasRole),
      onClick: () => {
        setRequestState(rawRequest, STATE_REJECTED, t('requestReject'));
      },
      children: (
        <>
          <CancelIcon sx={toolbarButtonIcon} />
          {t('requestReject')}
        </>
      ),
    },
    {
      show:
        userHasIssuedRequest(rawRequest, user) &&
        requestStatusIncludes(rawRequest, [STATE_NEW, STATE_REJECTED, STATE_ABORTED]),
      onClick: () => {
        callUrl(
          `/requests/${
            operation === SEARCH_FUNCTION_EDIT_PATIENT
              ? 'editPatient'
              : operation === SEARCH_FUNCTION_EDIT_STUDY
              ? 'editStudy'
              : operation === OPERATION_TYPE_MOVE_TO_FOLDER
              ? 'moveToFolder'
              : operation === OPERATION_TYPE_MOVE_TO_TRASH
              ? 'moveToTrash'
              : operation.toLowerCase()
          }/${get(rawRequest, 'id')}`,
        );
      },
      children: (
        <>
          <EditIcon sx={toolbarButtonIcon} />
          {t('requestEdit')}
        </>
      ),
    },
    {
      show:
        userHasIssuedRequest(rawRequest, user) &&
        requestStatusIncludes(rawRequest, [STATE_NEW, STATE_REJECTED]),
      onClick: () => {
        setRequestState(rawRequest, STATE_ABORTED, t('requestAbort'));
      },
      children: (
        <>
          <DeleteIcon sx={toolbarButtonIcon} />
          {t('requestAbort')}
        </>
      ),
    },
  ];

  return {
    request,
    buttons: filter(buttons, (button) => button.show !== false).map((button) => ({
      ...omit(button, ['show']),
    })),
  };
};

export default useItemsForRequestDetail;
