import React, { useEffect, useState } from 'react';
import { useUser } from 'utils/hooks/useUser';
import { filter, get, isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { createFolder, getAllByUser } from './_api';
import { IDialogFolderForm } from './_types';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import useRefetchGrid from 'utils/grid/useRefetchGrid';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { joinParams } from 'utils/study';
import useAlerts from 'components/Alerts/useAlerts';
import FormInput from 'components/Form/Input/Input';
import Button from 'components/Buttons/Button';
import { Papeer } from 'components/Papeer/Papeer';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const GRID_SETTINGS_KEY: string = 'dialogFoldersFormMUI';

const DialogFoldersForm: React.FC<any> = ({
  user,
  showGridWithFolders,
  refreshGridAfterAddNew,
  selection,
  setSelection,
  loadInitialSelection = false,
  sxPapeer = {},
}) => {
  const { t } = useTranslation('Folders');
  const { toggleLoader } = useAppGlobals();
  const { refetchGrid } = useRefetchGrid();
  const { hasRole } = useUser();
  const { compactMode } = useAppInfo();
  const { addErrorAlert } = useAlerts();

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } =
    useMuiGrid(GRID_SETTINGS_KEY);

  const [folders, setFolders] = useState<any[]>([]);

  const methods = useForm<IDialogFolderForm>({});
  const { handleSubmit, watch } = methods;

  const prepareValues = (values: any) => {
    const users = get(values, 'users', []);
    const preparedValues = { ...values, users };
    return preparedValues;
  };

  const loadEntity = async () => {
    const username = get(user, 'sub', '???');

    toggleLoader();

    const folders = await getAllByUser();
    if (folders && isArray(folders)) {
      setFolders(
        filter(
          folders.map((item) => {
            const owner = get(item, 'user.username', '!!!') === username;
            const permission = owner ? '' : (get(item, 'users[0].permission') as unknown as any);
            const canUpdate = owner || permission === 'WRITE_PLUS_UPDATE';
            const canDelete = owner;
            const canSelect =
              owner ||
              permission === 'READ_PLUS_WRITE' ||
              permission === 'WRITE_MINUS_DELETE' ||
              permission === 'WRITE_PLUS_UPDATE';
            return {
              ...item,
              canUpdate,
              canDelete,
              canSelect,
            };
          }),
          { canSelect: true },
        ),
      );
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refetchGrid();
    const ids = folders.map((folder) => folder.id);
    const newSelection = selection.filter((id: number) => ids.includes(id));
    if (selection.length !== newSelection.length) {
      setSelection(newSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders.length]);

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    await createFolder(prepareValues(values)).then(
      (response) => {
        if (refreshGridAfterAddNew) {
          loadEntity();
        }
      },
      (error) => {
        let tError = 'errorSaving';
        if (get(error, 'response.data', '') === 'error.administration.folder.exists') {
          tError = 'errorFolderExists';
        }
        addErrorAlert(t(tError));
      },
    );
    toggleLoader(false);
  });

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'name',
        headerName: t('name'),
        hideable: false,
      },
      {
        field: 'owner',
        headerName: t('owner'),
        width: 200,
        valueGetter: (value: any, row: any) =>
          `${joinParams([get(row, 'user.lastName'), get(row, 'user.firstName')])}`,
      },
    ]),
  );

  const canAdministrateFolders = hasRole('ROLE_CAN_ADMINISTRATE_FOLDERS');

  return (
    <>
      {canAdministrateFolders && (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <Papeer sx={sxPapeer}>
              <Grid container={true} spacing={2} data-tour="addToFolder-NewFolder">
                <Grid item={true} xs={12} md={6}>
                  <FormInput name="name" label={t('nameOfNew')} inputProps={{ maxLength: 30 }} />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      sx={{ mt: !compactMode ? 0.5 : 0 }}
                      disabled={!watch('name')?.trim()}
                    >
                      {t('createButton')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Papeer>
          </form>
        </FormProvider>
      )}

      {showGridWithFolders && (
        <>
          {canAdministrateFolders && (
            <Box component="h4" sx={{ display: 'flex', alignItems: 'center', mt: 1.5, mb: 1 }}>
              {t('selectFromList')}
            </Box>
          )}
          <MuiGrid
            gridKey={GRID_SETTINGS_KEY}
            rows={folders}
            columns={columns}
            rowSelecting={{
              selecting: true,
              selection,
              setSelection,
              loadInitialSelection,
            }}
            dataTour="addToFolder-GridExistingFolders"
            sxPapeer={sxPapeer}
            initialSortMode={[{ field: 'name', sort: 'asc' }]}
          />
        </>
      )}
    </>
  );
};

export default DialogFoldersForm;
