const variables = {
  primaryColor: '#a90607',
  blueBackground: '#a90607',
  fontSize: 12,
};

const light = {
  typography: {
    fontSize: variables.fontSize,
  },
  palette: {
    mode: 'light',
    primary: { main: variables.primaryColor },
    background: {
      paper: '#fff',
      default: '#f3f3f4',
    },
  },
  components: {
    MuiTableCell: {
      root: {
        padding: '4px',
      },
    },
    MuiTabs: {
      root: {
        background: '#fff',
      },
      tabsIndicator: {
        background: variables.blueBackground,
      },
    },
    /* MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 2000,
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          zIndex: 2001,
        },
      },
    },*/
  },
};

const dark = {
  typography: {
    fontSize: variables.fontSize,
  },
  palette: {
    mode: 'dark',
    primary: { main: variables.primaryColor },
  },
  components: {
    MuiTableCell: {
      root: {
        padding: '4px',
      },
    },
    /*MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 2000,
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          zIndex: 2001,
        },
      },
    },*/
  },
};

export const theme = {
  variables,
  light,
  dark,
};
