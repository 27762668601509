import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';

const useValidationSchema = (t: TFunction) => {
  const { required, withoutDiacritics } = useValidators();

  const UnitFormSchema = yup.object().shape({
    code: withoutDiacritics(required(yup.string())),
    name: required(yup.string()),
  });

  const formItems = [
    {
      name: 'code',
      label: t('code'),
      required: true,
    },
    {
      name: 'name',
      label: t('name'),
      required: true,
    },
  ];

  return { UnitFormSchema, formItems };
};

export default useValidationSchema;
