import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get, find, isArray, isEmpty, reverse, sortBy, filter, isNull } from 'lodash';
import { Badge, Box, CircularProgress, Grid, IconButton, Paper, Tooltip } from '@mui/material';
import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LabelIcon from '@mui/icons-material/Label';
import { IDocumentDetail } from './_types';
import { getAllDocumentTags, getDocument, setActiveDocumentTag } from './_api';
import { getCommentNameWithLogin, joinParams } from 'utils/study';
import { format } from 'date-fns';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useActions } from 'utils/hooks/useActions';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { Line } from 'components/Line/Line';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { DocumentDetailMenu } from './DocumentDetailMenu';
import { DICOMDocument } from './DICOMDocument';
import { PDFDocument } from './PDFDocument';
import { RTFDocument } from './RTFDocument';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import { Papeer } from 'components/Papeer/Papeer';

const formatDateWithoutTime = 'dd. MM. yyyy';
const formatDateTime = 'dd. MM. yyyy, HH:mm:ss';

export const DocumentDetail: React.FC<IDocumentDetail> = ({
  patientDocument,
  patientDocumentUuid,
  masterPatientID,
  unselectDocumentUuid,
  documentUids,
  patientDocuments,
  setDocumentToGridPosition,
  itemKey,
  onlyAddTag,
  setIsPrinting,
  setStandardizedPrint,
  setIsExporting,
  requestedDocuments,
  activeRegisterGroups,
  setActiveRegisterGroups,
  gridLayoutParams,
  selectingDocumentDisabled,
  setIsDownloadingFromEldax,
}) => {
  const { t } = useTranslation('ClinicalPortal');
  const { toggleLoader } = useAppGlobals();
  const { currentLocale } = useLanguage();
  const { storeConfirmationData } = useActions();
  const { confirmationData } = useAppInfo();

  const [isDicom, setIsDicom] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNotLoaded, setIsNotLoaded] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [documentTags, setDocumentTags] = useState<any[] | null>(null);
  const [documentUiid, setDocumentUiid] = useState<string | null>(null);
  const [document, setDocument] = useState<any>(null);
  const [documentType, setDocumentType] = useState<any>(null);
  const [documentTypeFormat, setDocumentTypeFormat] = useState<any>(null);
  const [documentExtension, setDocumentExtension] = useState<any>(null);
  const [documentHeader, setDocumentHeader] = useState<any>();
  const [RenderedDocumentTags, setRenderedDocumentTags] = useState<any>();
  // const [isNotEmptyDocument, setIsNotEmptyDocument] = useState<boolean>(false);
  const [documentFullScrenRender, setDocumentFullScrenRender] = useState<JSX.Element>();

  // const documentExtensionJsonString = JSON.stringify(documentExtension);
  // const documentHeaderJsonString = JSON.stringify(documentHeader);
  const patientDocumentJsonString = JSON.stringify(patientDocument);
  const documentTagsJsonString = JSON.stringify(documentTags);
  // const renderedDocumentTagsJsonString = JSON.stringify(RenderedDocumentTags);
  // const documentUidsJsonString = JSON.stringify(documentUids);

  const isNotEmptyDocument =
    (document != null &&
      documentType != null &&
      documentTypeFormat != null &&
      !isNull(documentUiid)) ||
    documentExtension != null;

  const getDocumentTags = async (docUiid: any) => {
    if (docUiid || (docUiid === undefined && documentUiid)) {
      const documentTags = await getAllDocumentTags(docUiid || documentUiid);
      if (isArray(documentTags) && documentTags.length) {
        setDocumentTags(reverse(sortBy(filter(documentTags, ['active', true]), ['id'])));
      } else {
        setDocumentTags(null);
      }
    } else {
      setDocumentTags(null);
    }
  };

  const loadDocument = async (masterPatientID: any, documentUiid: any, typeOfDocument: any) => {
    let hasInitialized = true;
    if (masterPatientID !== null && documentUiid !== null && typeOfDocument !== null) {
      setIsLoading(true);
      let documentResponse = find(requestedDocuments, { uuid: documentUiid });
      if (!documentResponse) {
        await getDocument(masterPatientID, documentUiid, typeOfDocument).then((response: any) => {
          documentResponse = response;
        });
      }
      if (documentResponse) {
        await getDocumentTags(documentUiid);
        setDocument(null);
        setDocumentUiid(documentUiid);
        setDocumentType(typeOfDocument);
        const documentData = get(documentResponse, 'document', null);
        if (documentData != null) {
          setDocumentTypeFormat(get(documentData, 'format', null));
          setDocument(get(documentData, 'data', {}));
        }
        setDocumentExtension(get(documentResponse, 'documentTextAdditionalInfo', {}));
        hasInitialized = false;
        setIsNotLoaded(false);
      } else {
        setIsNotLoaded(true);
      }
      setIsLoading(false);
    }
    if (hasInitialized) {
      setDocument(null);
      setDocumentExtension(null);
      setDocumentUiid(null);
      setDocumentType(null);
      setDocumentTypeFormat(null);
      getDocumentTags(null);
    }
  };

  const getIsDicom = (allowedTypes: any) => {
    setIsDicom(
      find(allowedTypes, {
        name: 'dicom',
      }) !== undefined,
    );
  };

  const loadEntity = async () => {
    getIsDicom(get(patientDocument, 'typeOfDocument.allowedFormats', []));
    await loadDocument(
      masterPatientID,
      get(patientDocument, 'uuid', null),
      get(patientDocument, 'typeOfDocument.name', null),
    );
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDocumentUuid]);

  useEffect(() => {
    const externalWorkplaceName = get(patientDocument, 'externalWorkplaceName');
    setDocumentHeader([
      {
        name: 'patient',
        value: `${joinParams([
          get(patientDocument, 'patientEntity.patientPrefix'),
          get(patientDocument, 'patientEntity.patientLastName'),
          get(patientDocument, 'patientEntity.patientFirstName'),
          get(patientDocument, 'patientEntity.patientMiddleName'),
          get(patientDocument, 'patientEntity.patientSuffix'),
          `(${t('patientIdentificationNumber')}:`,
          get(patientDocument, 'patientEntity.patientIdentificationNumber', '-') != null
            ? get(patientDocument, 'patientEntity.patientIdentificationNumber', '-')
            : '-',
          ' | ',
          `${t('patientBirthDate')}:`,
          get(patientDocument, 'patientEntity.patientBirthDate', '')
            ? format(
                new Date(get(patientDocument, 'patientEntity.patientBirthDate', '')),
                formatDateWithoutTime,
              )
            : '',
          ')',
        ])}`,
        fontWeight: 'bold',
      },
      {
        name: 'nameOfdocument',
        value: `${joinParams([
          get(patientDocument, 'typeOfDocument.translateJson')
            ? get(
                JSON.parse(get(patientDocument, 'typeOfDocument.translateJson')),
                currentLocale,
                get(patientDocument, 'typeOfDocument.name'),
              )
            : `${t('unknownNameOfDocument')}`,
        ])}
        `,
        fontWeight: 'bold',
        fontSize: 'large',
      },
      {
        name: 'name',
        value: `${joinParams([
          `(${t('createdDateTime')}:`,
          get(patientDocument, 'createdDateTime') != null
            ? format(
                new Date(get(patientDocument, 'createdDateTime', '')),
                formatDateWithoutTime /* {
                locale,
              } */,
              )
            : `${t('unknownNameDate')}`,
          ` | ${t('author')}:`,
          get(patientDocument, 'author') != null
            ? get(patientDocument, 'author')
            : `${t('unknownAuthorOfDocument')}`,
          ` | ${t('referenceID')}:`,
          get(patientDocument, 'referenceID') != null
            ? get(patientDocument, 'referenceID')
            : `${t('unknownReferenceOfDocument')}`,
          ` | ${t('externalWorkplaceName')}:`,
          externalWorkplaceName != null
            ? externalWorkplaceName
            : `${t('unknownExternalWorkplaceName')}`,
          ')',
        ])}
        `,
        lg: 6,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDocumentJsonString]);

  useEffect(() => {
    setRenderedDocumentTags(
      isArray(documentTags) && documentTags.length
        ? documentTags.map((documentTag, index) => {
            const createdByAuthor = getCommentNameWithLogin(get(documentTag, 'createdBy'));
            const canDelete = true;
            return (
              <Box sx={{ display: 'inline-flex', pr: 3 }} key={documentTag.id}>
                {documentTag.value.split('*^*').map((item: any) => {
                  let j = 0;
                  return (
                    <Tooltip
                      title={
                        <div>
                          {createdByAuthor} -{' '}
                          {documentTag.createdWhen
                            ? format(new Date(documentTag.createdWhen), formatDateTime)
                            : ''}
                        </div>
                      }
                      key={item}
                    >
                      <Badge
                        sx={{
                          mr: 1.5,
                          '.MuiBadge-badge': {
                            right: -8,
                            top: 3,
                          },
                        }}
                        badgeContent={
                          canDelete ? (
                            <Tooltip
                              key="documentTagDelete"
                              title={t('documentTagDelete')}
                              placement="top"
                            >
                              <IconButton
                                sx={{
                                  mr: 1.5,
                                  '.MuiIconButton-root': {
                                    padding: 0,
                                    margin: 0,
                                    top: '25%',
                                  },
                                }}
                                onClick={() => storeConfirmationData({ id: documentTag.id })}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            0
                          )
                        }
                      >
                        <Box
                          sx={{
                            display: 'inline-flex',
                            border: '1px solid #ccc',
                            borderRadius: 20,
                            marginTop: 0.5,
                          }}
                        >
                          <LabelIcon sx={{ fontSize: 20, color: '#676a6c', mr: 1, ml: 1 }} />
                          {item
                            .substr(1)
                            .split('#')
                            .map((tag: any) => {
                              j++;
                              return (
                                <Box
                                  component="span"
                                  key={tag}
                                  sx={{ display: 'inline-flex', mr: 1 }}
                                >
                                  {j > 1 && <KeyboardArrowRightIcon />}
                                  {tag}
                                </Box>
                              );
                            })}
                        </Box>
                      </Badge>
                    </Tooltip>
                  );
                })}
              </Box>
            );
          })
        : '',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTagsJsonString]);

  const showBigPreview = (value: boolean) => {
    setFullScreen(value);
  };

  const documentRender = (
    <Papeer>
      <Box id="document">
        <Grid container={true} spacing={2}>
          {isNotEmptyDocument ? (
            <Grid item={true} key={'header'} xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                  {documentHeader.map((field: any) => (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontWeight: get(field, 'fontWeight', 'normal'),
                        fontSize: get(field, 'fontSize', 'normal'),
                      }}
                    >
                      {field.value}
                    </Box>
                  ))}
                  {RenderedDocumentTags}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    onClick={() => {
                      toggleLoader();
                      showBigPreview(!fullScreen);
                    }}
                  >
                    {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                  </IconButton>
                  {!fullScreen && (
                    <DocumentDetailMenu
                      itemKey={itemKey}
                      documentUiid={documentUiid}
                      documentUids={documentUids}
                      masterPatientID={masterPatientID}
                      unselectDocumentUuid={unselectDocumentUuid}
                      patientDocuments={patientDocuments}
                      setDocumentToGridPosition={setDocumentToGridPosition}
                      patientDocument={patientDocument}
                      getDocumentTags={getDocumentTags}
                      onlyAddTag={onlyAddTag}
                      setIsPrinting={setIsPrinting}
                      setStandardizedPrint={setStandardizedPrint}
                      setIsExporting={setIsExporting}
                      activeRegisterGroups={activeRegisterGroups}
                      setActiveRegisterGroups={setActiveRegisterGroups}
                      gridLayoutParams={gridLayoutParams}
                      selectingDocumentDisabled={selectingDocumentDisabled}
                      setIsDownloadingFromEldax={setIsDownloadingFromEldax}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          ) : (
            <Grid item={true} key="unknownDocument" xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  {t(isNotLoaded ? 'notLoadedDocument' : 'unknownDocument')}
                </Box>
                <DocumentDetailMenu
                  itemKey={itemKey}
                  documentUiid={documentUiid}
                  documentUids={documentUids}
                  masterPatientID={masterPatientID}
                  patientDocuments={patientDocuments}
                  setDocumentToGridPosition={setDocumentToGridPosition}
                  menuStyle={{}}
                  patientDocument={patientDocument}
                  onlyAddTag={onlyAddTag}
                  setIsPrinting={setIsPrinting}
                  setStandardizedPrint={setStandardizedPrint}
                  setIsExporting={setIsExporting}
                  activeRegisterGroups={activeRegisterGroups}
                  setActiveRegisterGroups={setActiveRegisterGroups}
                  gridLayoutParams={gridLayoutParams}
                  selectingDocumentDisabled={selectingDocumentDisabled}
                  setIsDownloadingFromEldax={setIsDownloadingFromEldax}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Line topMargin={1} bottomMargin={2} />
      {documentExtension != null &&
        (!isEmpty(get(documentExtension, 'anesthesia')) ||
          !isEmpty(get(documentExtension, 'diagnosis')) ||
          !isEmpty(get(documentExtension, 'hospitalization')) ||
          !isEmpty(get(documentExtension, 'performance')) ||
          !isEmpty(get(documentExtension, 'prescription')) ||
          !isEmpty(get(documentExtension, 'procedure')) ||
          !isEmpty(get(documentExtension, 'transfer'))) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            {get(documentExtension, 'hospitalization.from') !== undefined && (
              <>
                {`${t('hospitalizationFrom')} ${
                  get(documentExtension, 'hospitalization.from')
                    ? format(
                        new Date(get(documentExtension, 'hospitalization.from')),
                        formatDateTime,
                      )
                    : ''
                }`}
                &nbsp;
              </>
            )}
            {get(documentExtension, 'hospitalization.to') !== undefined && (
              <>
                {`${t('hospitalizationTo')} ${
                  get(documentExtension, 'hospitalization.to')
                    ? format(new Date(get(documentExtension, 'hospitalization.to')), formatDateTime)
                    : ''
                }`}
              </>
            )}
            {(get(documentExtension, 'hospitalization.from') !== undefined ||
              get(documentExtension, 'hospitalization.to') !== undefined) && <br />}
            {get(documentExtension, 'diagnosis.code') !== undefined && (
              <>
                {`${t('diagnosisCode')} ${get(documentExtension, 'diagnosis.code')}`}
                <br />
              </>
            )}
            {get(documentExtension, 'diagnosis.type') !== undefined && (
              <>
                {`${t('diagnosisType')} ${get(documentExtension, 'diagnosis.type')}`}
                <br />
              </>
            )}
            {get(documentExtension, 'diagnosis.description') !== undefined && (
              <>
                {`${t('diagnosisDescription')} ${get(documentExtension, 'diagnosis.description')}`}
                <br />
              </>
            )}
            {get(documentExtension, 'diagnosis.typeCode') !== undefined && (
              <>
                {`${t('diagnosisTypeCode')} ${get(documentExtension, 'diagnosis.typeCode')}`}
                <br />
              </>
            )}
            {get(documentExtension, 'diagnosis.typeDescription') !== undefined && (
              <>
                {`${t('diagnosisTypeDescription')} ${get(
                  documentExtension,
                  'diagnosis.typeDescription',
                )}`}
                <br />
              </>
            )}
            {get(documentExtension, 'diagnosis.priorityCode') !== undefined && (
              <>
                {`${t('diagnosisPriorityCode')} ${get(
                  documentExtension,
                  'diagnosis.priorityCode',
                )}`}
                <br />
              </>
            )}
            {get(documentExtension, 'procedure.fromTime') !== undefined && (
              <>
                {`${t('procedureFromTime')} ${format(
                  get(documentExtension, 'procedure.fromTime'),
                  formatDateTime,
                )}`}
                &nbsp;
              </>
            )}
            {get(documentExtension, 'procedure.toTime') !== undefined && (
              <>
                {`${t('procedureToTime')} ${
                  get(documentExtension, 'procedure.toTime')
                    ? format(new Date(get(documentExtension, 'procedure.toTime')), formatDateTime)
                    : ''
                }`}
              </>
            )}
            {(get(documentExtension, 'procedure.fromTime') !== undefined ||
              get(documentExtension, 'procedure.toTime') !== undefined) && <br />}
            {get(documentExtension, 'anesthesia.fromTime') !== undefined && (
              <>
                {`${t('anesthesiaFromTime')} ${
                  get(documentExtension, 'anesthesia.fromTime')
                    ? format(
                        new Date(get(documentExtension, 'anesthesia.fromTime')),
                        formatDateTime,
                      )
                    : ''
                }`}
                &nbsp;
              </>
            )}
            {get(documentExtension, 'anesthesia.toTime') !== undefined && (
              <>
                {`${t('anesthesiaToTime')} ${
                  get(documentExtension, 'anesthesia.toTime')
                    ? format(new Date(get(documentExtension, 'anesthesia.toTime')), formatDateTime)
                    : ''
                }`}
                <br />
              </>
            )}
            {get(documentExtension, 'performance.fromTime') !== undefined && (
              <>
                {`${t('performanceFromTime')} ${
                  get(documentExtension, 'performance.fromTime')
                    ? format(
                        new Date(get(documentExtension, 'performance.fromTime')),
                        formatDateTime,
                      )
                    : ''
                }`}
                &nbsp;
              </>
            )}
            {get(documentExtension, 'performance.toTime') !== undefined && (
              <>
                {`${t('performanceToTime')} ${format(
                  new Date(get(documentExtension, 'performance.toTime')),
                  formatDateTime,
                )}`}
                <br />
              </>
            )}
            {get(documentExtension, 'procedure.description') !== undefined && (
              <>
                {`${t('procedureDescription')} ${get(documentExtension, 'procedure.description')}`}
                <br />
              </>
            )}
            {get(documentExtension, 'procedure.reoperation') !== undefined && (
              <>
                {`${t('procedureReoperation')} ${get(documentExtension, 'procedure.reoperation')}`}
                <br />
              </>
            )}
            {get(documentExtension, 'procedure.operatingRoom') !== undefined && (
              <>
                {`${t('procedureOperatingRoom')} ${get(
                  documentExtension,
                  'procedure.operatingRoom',
                )}`}
                <br />
              </>
            )}
            {get(documentExtension, 'transfer.enteredAt') !== undefined && (
              <>
                {`${t('transferEnteredAt')} ${get(documentExtension, 'transfer.enteredAt')}`}
                <br />
              </>
            )}
            {get(documentExtension, 'transfer.fromTime') !== undefined && (
              <>
                {`${t('transferFromTime')} ${format(
                  new Date(get(documentExtension, 'transfer.fromTime')),
                  formatDateTime,
                )}`}
                &nbsp;
              </>
            )}
            {get(documentExtension, 'transfer.toTime') !== undefined && (
              <>
                {`${t('transferToTime')} ${format(
                  new Date(get(documentExtension, 'transfer.toTime')),
                  formatDateTime,
                )}`}
                <br />
              </>
            )}
            {!isEmpty(get(documentExtension, 'prescription.drug', [])) && (
              <>
                {get(documentExtension, 'prescription.drug', []).map((drug: any) => {
                  return (
                    <>
                      {get(drug, 'code') !== undefined && (
                        <>
                          {`${t('prescriptionDrugCode')} ${get(drug, 'code')}`}
                          <br />
                        </>
                      )}
                      {get(drug, 'name') !== undefined && (
                        <>
                          {`${t('prescriptionDrugName')} ${get(drug, 'name')}`}
                          <br />
                        </>
                      )}
                      {get(drug, 'description') !== undefined && (
                        <>
                          {`${t('prescriptionDrugDescription')} ${get(drug, 'description')}`}
                          <br />
                        </>
                      )}
                      {get(drug, 'exporig') !== undefined && (
                        <>
                          {`${t('prescriptionDrugExporig')} ${get(drug, 'exporig')}`}
                          <br />
                        </>
                      )}
                      {get(drug, 'dsig') !== undefined && (
                        <>
                          {`${t('prescriptionDrugDsig')} ${get(drug, 'dsig')}`}
                          <br />
                        </>
                      )}
                      ------------------------------------------------------------------------------------------------------------------------------------------
                      <br />
                    </>
                  );
                })}
              </>
            )}
            {get(documentExtension, 'prescription.numberOfDrugPrescription') !== undefined && (
              <>
                {`${t('prescriptionNumberOfDrugPrescription')} ${get(
                  documentExtension,
                  'prescription.numberOfDrugPrescription',
                )}`}
                <br />
              </>
            )}
            <br />
          </Box>
        )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: new RegExp('application/pdf').test(documentTypeFormat)
            ? 'center'
            : 'left',
          alignItems: 'center',
          overflow: new RegExp('application/json').test(documentTypeFormat) ? 'auto' : 'unset',
        }}
      >
        {document != null && documentType != null && documentTypeFormat != null && (
          <div style={{ maxWidth: '-webkit-fill-available' }}>
            {isDicom === true ? (
              <>
                {new RegExp('application/json').test(documentTypeFormat) && (
                  <DICOMDocument document={document} />
                )}
              </>
            ) : (
              <>
                {new RegExp('application/pdf').test(documentTypeFormat) && (
                  <PDFDocument document={document} />
                )}
                {new RegExp('text/plain').test(documentTypeFormat) && (
                  <Box sx={{ maxWidth: '100%', overflow: 'auto' }}>
                    <Box
                      component="span"
                      sx={{
                        whiteSpace: 'pre-wrap',
                        fontFamily: 'monospace',
                        display: 'block',
                      }}
                    >
                      {document}
                    </Box>
                  </Box>
                )}
                {new RegExp('text/rtf').test(documentTypeFormat) && (
                  <RTFDocument document={document} />
                )}
              </>
            )}
          </div>
        )}
      </Box>
    </Papeer>
  );

  useEffect(() => {
    const documentFullScrenRender = (
      <OverflowedDialog
        key={'fullScreenPreview'}
        fullScreen={true}
        open={fullScreen}
        onClose={setFullScreen}
        aria-labelledby="form-dialog-title"
        withScrolling={true}
        contentStyle={{ padding: 0 }}
      >
        {documentRender}
      </OverflowedDialog>
    );
    setDocumentFullScrenRender(documentFullScrenRender);
    toggleLoader(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreen]);

  const onChangeActive = async (id: number) => {
    toggleLoader();
    const changed = await setActiveDocumentTag(id, false);
    if (changed) {
      await getDocumentTags(documentUiid);
    }
    toggleLoader(false);
  };
  const clearAction = () => {
    storeConfirmationData(null);
  };

  const cancelAction = () => {
    clearAction();
  };
  const confirmAction = async () => {
    onChangeActive(get(confirmationData, 'id'));
    clearAction();
  };

  return (
    <>
      <Paper
        sx={{
          position: 'relative',
          width: '100%',
          ...(isLoading || !isNotEmptyDocument ? { height: 300 } : {}),
          overflow: 'auto',
        }}
      >
        {isLoading && (
          <Box
            sx={{
              background: 'rgba(255,255,255,0.8)',
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              //zIndex: 9999,
            }}
          >
            <CircularProgress size={50} />
          </Box>
        )}

        {!isLoading ? (
          documentRender
        ) : (
          <div id="document">
            <Grid container={true} spacing={16} style={{ marginTop: 8 }}>
              <Grid item={true} xs={12}>
                <div style={{ margin: 8 }}>{t('loadingData')}</div>
              </Grid>
            </Grid>
          </div>
        )}

        {confirmationData && confirmationData.id && (
          <ConfirmationDialog
            title={t('confirmDelete')}
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            cancelAction={cancelAction}
            confirmAction={confirmAction}
          />
        )}
        {documentFullScrenRender}
      </Paper>
    </>
  );
};
