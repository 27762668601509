import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';
import { get, isFunction, isString, omit, pick } from 'lodash';
import { createPhysician, editPhysician, getPhysician } from './_api';
import { IPhysicianForm } from './_types';

import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';

import useValidationSchema from './_form';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSwitch from 'components/Form/Switch/Switch';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';

import useAlerts from 'components/Alerts/useAlerts';

const PhysicianForm: React.FC<any> = ({ dictionary, physicianId, typePhysician, setModality }) => {
  const { t } = useTranslation('Dictionaries');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const id = typePhysician ? 'create' : physicianId;
  const isCreating = id === 'create';
  const linkBack2 = !typePhysician ? `/administration/generalSettings/physicians` : '';

  const [entity, fetchEntity] = useState<IPhysicianForm>();
  const [isFetchingEntity, setIsFetchingEntity] = useState<boolean>(true);
  const navigate = useNavigate();
  const { title } = useWithEntityTitle(isCreating, entity, t, 'createPhysician', 'editPhysician');

  const { PhysicianFormSchema, physicianFormItems } = useValidationSchema(
    t,
    [],
    dictionary,
    isCreating,
  );

  const methods = useForm<IPhysicianForm>({
    resolver: yupResolver(PhysicianFormSchema),
  });
  const { handleSubmit, reset, register } = methods;

  const prepareValues = (values: any) => {
    let preparedValues: any = {
      ...pick(values, ['id']),
      name: {
        firstName: get(values, 'firstName'),
        lastName: get(values, 'lastName'),
        middleName: get(values, 'middleName'),
        prefix: get(values, 'prefix'),
        suffix: get(values, 'suffix'),
      },
      shown: get(values, 'shown', false),
      referringPhysician:
        get(values, 'referringPhysician') === 'true' || get(values, 'referringPhysician') === true,
      requestingPhysician:
        get(values, 'requestingPhysician') === 'true' ||
        get(values, 'requestingPhysician') === true,
    };
    return preparedValues;
  };

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const fn = isCreating ? createPhysician : editPhysician;
    const preparedValues = prepareValues(values);
    const response = await fn(preparedValues);
    if (response === true) {
      addSuccessAlert(t('saved'));
      if (linkBack2) {
        navigate(linkBack2);
      } else if (isFunction(setModality)) {
        setModality(preparedValues);
      }
    } else {
      addErrorAlert(t(isString(response) ? response : 'errorSaving'));
    }
    toggleLoader(false);
  });

  const getEntity = async () => {
    toggleLoader();
    try {
      let init: IPhysicianForm = {
        id: 0,
        firstName: '',
        lastName: '',
        middleName: '',
        prefix: '',
        suffix: '',
        requestingPhysician:
          !typePhysician || (typePhysician && typePhysician === 'requestingPhysician'),
        referringPhysician:
          !typePhysician || (typePhysician && typePhysician === 'referringPhysician'),
        shown: true,
      };

      if (!isCreating && id && dictionary) {
        const entity = await getPhysician(id);
        if (entity) {
          init = {
            ...omit(entity, ['name']),
            firstName: get(entity, 'name.firstName'),
            lastName: get(entity, 'name.lastName'),
            middleName: get(entity, 'name.middleName'),
            prefix: get(entity, 'name.prefix'),
            suffix: get(entity, 'name.suffix'),
          };
        }

        if (entity) {
          fetchEntity(init);
        }
      }

      reset({ ...init });
      setIsFetchingEntity(false);
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isFetchingEntity && (
        <>
          {!typePhysician && <Header title={title} />}
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <input {...register('id')} type="hidden" />
                <input {...register('shown')} type="hidden" />
                {typePhysician && <input {...register('requestingPhysician')} type="hidden" />}
                {typePhysician && <input {...register('referringPhysician')} type="hidden" />}
                <Grid container={true} spacing={2}>
                  {physicianFormItems.map((field: any) => {
                    return (
                      <Grid item={true} xs={12}>
                        <FormInput {...field} type="text" />
                      </Grid>
                    );
                  })}
                  {!typePhysician && (
                    <Grid item={true} xs={12}>
                      <FormSwitch name="requestingPhysician" label={t('requestingPhysician')} />
                    </Grid>
                  )}
                  {!typePhysician && (
                    <Grid item={true} xs={12}>
                      <FormSwitch name="referringPhysician" label={t('referringPhysician')} />
                    </Grid>
                  )}

                  <Grid item={true} xs={12}>
                    <EntityButtons linkBack={linkBack2} />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Papeer>
        </>
      )}
    </>
  );
};

export default PhysicianForm;
