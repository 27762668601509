import HelperComponent from 'components/Tour/HelperComponent';
import { useReactTour } from 'components/Tour/useReactTour';
import { useTranslation } from 'react-i18next';

export const TourStudiesSplit = () => {
  const { t } = useTranslation('Reactour');

  const allSteps = () => {
    const steps = {
      '[data-tour="backButton"]': t('study.back'),
      '[data-tour="splitStudyExistingStudyForm"]': t('study.split.existingStudyForm'),
      '[data-tour="splitStudyNewStudyButton"]': t('study.split.newStudyButton'),
      '[data-tour="splitStudyNewStudyForm"]': t('study.split.newStudyForm'),
      '[data-tour="splitStudyExistingStudySeries"]': t('study.split.existingStudySeries'),
      '[data-tour="splitStudyNewStudySeries"]': t('study.split.newStudySeries'),
      '[data-tour="splitStudyOtherChoices"]': t('study.split.otherChoices'),
      '[data-tour="splitStudySplitButton"]': t('study.split.splitButton'),
    };
    return steps;
  };

  useReactTour({ stepsDefinition: allSteps });

  return <HelperComponent />;
};
