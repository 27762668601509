import { filter, isArray, omit } from 'lodash';
import React, { useMemo } from 'react';
import { Button, Grid, GridSpacing } from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import ReorderIcon from '@mui/icons-material/ArrowBack';
import SplitIcon from '@mui/icons-material/CallSplit';
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/FileCopy';
import MoveIcon from '@mui/icons-material/SwapHoriz';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import AddImageIcon from '@mui/icons-material/AddToPhotos';
import PrintIcon from '@mui/icons-material/Print';
import ArchiveArchivedIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import ShreddingIcon from '@mui/icons-material/RestoreFromTrash';
import DoseMonitoringIcon from '@mui/icons-material/TrackChanges';
import CreateSR from '@mui/icons-material/Receipt';
import ViewDicomAttributesIcon from '@mui/icons-material/ViewList';
import BookmarkIcon from '@mui/icons-material/Bookmark';

import { IStudyDetailButtons } from './_types';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { stateIsSame } from 'utils/componentOptimizatons';
import { useUser } from 'utils/hooks/useUser';
import { useActions } from 'utils/hooks/useActions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { generateIID } from 'utils/study';
import { Box } from '@mui/system';

const PlainStudyDetailButtons: React.FC<IStudyDetailButtons> = ({
  printSetting,
  studyActions,
  study,
  setPrintDialogState,
  createPdfAndPrint,
  setChangeStatusesDialog,
  setSrDialogState,
  setOpenDicomAttributesDialog,
  toggleImportWebcamDialog,
  toggleImportImageDialog,
}) => {
  const { spacing, compactMode } = useAppInfo();
  const { hasRole } = useUser();
  const { resetStudies, setStudies, storeConfirmationData } = useActions();
  const { t } = useTranslation('StudyDetail');
  const navigate = useNavigate();

  const buttons = useMemo(() => {
    const callAction = (action: string, url: string) => {
      const iid = generateIID(study);
      const isPatientEdit = action === 'edit_patient';
      const type = isPatientEdit ? 'edit' : action;
      resetStudies(type);
      setStudies({
        type,
        //item: isPatientEdit ? get(study, 'patient', {}) : { ...study, iid },
        item: { ...study, iid },
      });
      navigate(url);
    };
    /* const createPdfAndPrint = async (printSetting: any) => {
      const data = {
        pdfOptions: {
          printDetail: true,
          printComments: get(printSetting, 'printComments', true),
          printSeries: get(printSetting, 'printSeries', false),
        },
        studyIdentity: {
          archive: { id: archiveID * 1 },
          studyInstanceUid: studyID,
        },
      };
      const base64PdfString = await createPrintablePDF(data);
      const contentType = 'application/pdf';
      const fileName = 'study-detail.pdf';
      printOrDownloadBase64String(base64PdfString, contentType, fileName);
    }; */
    const onPrint = async () => {
      if (printSetting.printShowDialog) {
        setPrintDialogState(true);
      } else {
        createPdfAndPrint(printSetting);
      }
    };

    const buttonsSx = compactMode ? { fontSize: 16, mr: 0.5 } : { fontSize: 20, mr: 1 };

    const buttons = [
      {
        show:
          hasRole('ROLE_SEND_DR_SEJF') ||
          hasRole('ROLE_SEND_EPACS') ||
          hasRole('ROLE_SEND_REDIMED'),
        onClick: () => callAction('send', studyActions.send.url),
        disabled: studyActions.send.disable,
        children: (
          <>
            <SendIcon sx={buttonsSx} />
            {t('action.send')}
          </>
        ),
      },
      {
        show: hasRole('ROLE_REORDER'),
        onClick: () => callAction('reorder', studyActions.reorder.url),
        disabled: studyActions.reorder.disable,
        children: (
          <>
            <ReorderIcon sx={buttonsSx} />
            {t('action.reorder')}
          </>
        ),
      },
      {
        show: hasRole('ROLE_SPLIT'),
        onClick: () => callAction('split', studyActions.split.url),
        disabled: studyActions.split.disable,
        children: (
          <>
            <SplitIcon sx={buttonsSx} />
            {t('action.split')}
          </>
        ),
      },
      {
        show: hasRole('ROLE_UPDATE_STUDY'),
        onClick: () => callAction('edit', studyActions.updateStudy.url),
        disabled: studyActions.updateStudy.disable,
        children: (
          <>
            <EditIcon sx={buttonsSx} />
            {t('action.update.study')}
          </>
        ),
      },
      {
        show: hasRole('ROLE_UPDATE_PATIENT'),
        onClick: () => callAction('edit_patient', studyActions.updatePatient.url),
        disabled: studyActions.updatePatient.disable,
        children: (
          <>
            <Box component="i" className="fas fa-user-edit" sx={buttonsSx} />
            {t('action.update.patient')}
          </>
        ),
      },
      {
        show: hasRole('ROLE_COPY'),
        onClick: () => callAction('copy', studyActions.copy.url),
        disabled: studyActions.copy.disable,
        children: (
          <>
            <CopyIcon sx={buttonsSx} />
            {t('action.copy')}
          </>
        ),
      },
      {
        show: hasRole('ROLE_MOVE'),
        onClick: () => callAction('move', studyActions.move.url),
        disabled: studyActions.move.disable,
        children: (
          <>
            <MoveIcon sx={buttonsSx} />
            {t('action.move')}
          </>
        ),
      },
      {
        show: hasRole('ROLE_ARCHIVATION'),
        onClick: () => {
          storeConfirmationData({
            action: 'archive',
            title: 'Studies:confirmArchivingStudiesTitle',
            content: t('Studies:confirmArchivingStudies', { par1: 1 }),
          });
        },
        disabled: studyActions.archive.disable,
        children: (
          <>
            <ArchiveArchivedIcon sx={buttonsSx} />
            {t('action.archive')}
          </>
        ),
      },
      {
        show:
          hasRole('ROLE_EXPORT_CD') ||
          hasRole('ROLE_EXPORT_DICOM') ||
          hasRole('ROLE_EXPORT_DVD') ||
          hasRole('ROLE_EXPORT_PICTURE') ||
          hasRole('ROLE_EXPORT_VIDEO'),
        onClick: () => callAction('export', studyActions.export.url),
        disabled: studyActions.export.disable,
        children: (
          <>
            <ImportExportIcon sx={buttonsSx} />
            {t('action.export')}
          </>
        ),
      },
      {
        show: hasRole('ROLE_IMPORT_STUDY'),
        // onClick: () => callAction('import', studyActions.import.url),
        onClick: () => toggleImportImageDialog(),
        disabled: studyActions.import.disable,
        children: (
          <>
            <AddImageIcon sx={buttonsSx} />
            {t('action.import')}
          </>
        ),
      },
      {
        show: hasRole('ROLE_IMPORT_FROM_WEBCAM'),
        onClick: () => {
          toggleImportWebcamDialog();
        },
        disabled: studyActions.importFromWebcam.disable,
        children: (
          <>
            <AddImageIcon sx={buttonsSx} />
            {t('action.importFromWebcam')}
          </>
        ),
      },
      {
        onClick: () => setSrDialogState(true),
        disabled: studyActions.createSR.disable,
        children: (
          <>
            <CreateSR sx={buttonsSx} />
            {t('action.createSR')}
          </>
        ),
      },
      {
        onClick: () => onPrint(),
        disabled: studyActions.print.disable,
        children: (
          <>
            <PrintIcon sx={buttonsSx} />
            {t('action.print')}
          </>
        ),
      },
      {
        show: hasRole('ROLE_CAN_MOVE_TO_TRASH'),
        onClick: () => {
          storeConfirmationData({
            action: 'moveToTrash',
            title: 'confirmMoveStudyToTrashTitle',
            content: t('confirmMoveStudyToTrashContent'),
          });
        },
        disabled: studyActions.delete.disable,
        children: (
          <>
            <DeleteIcon sx={buttonsSx} />
            {t('action.delete')}
          </>
        ),
      },
      {
        show: hasRole('ROLE_SHREDDING'),
        onClick: () => {
          storeConfirmationData({
            action: 'proposalShredding',
            title: 'confirmProposalShreddingTitle',
            content: t('confirmProposalShredding', { par1: 1 }),
          });
        },
        disabled: studyActions.proposalShredding.disable,
        children: (
          <>
            <ShreddingIcon sx={buttonsSx} />
            {t('action.proposalShredding')}
          </>
        ),
      },
      {
        show: hasRole('ROLE_DOSE_MONITORING'),
        onClick: () => callAction('doseMonitoring', studyActions.doseMonitoring.url),
        disabled: studyActions.doseMonitoring.disable,
        children: (
          <>
            <DoseMonitoringIcon sx={buttonsSx} />
            {t('action.doseMonitoring')}
          </>
        ),
      },
      {
        show: hasRole('ROLE_SHOW_STUDY_DICOM_TAGS'),
        onClick: () => setOpenDicomAttributesDialog(true),
        disabled: studyActions.viewDicomAttributes.disable,
        children: (
          <>
            <ViewDicomAttributesIcon sx={buttonsSx} />
            {t('action.viewDicomAttributes')}
          </>
        ),
      },
      {
        show: hasRole('ROLE_CAN_MANAGE_STUDY_STATUS'),
        onClick: () => setChangeStatusesDialog(true),
        disabled: studyActions.changeStatuses.disable,
        children: (
          <>
            <BookmarkIcon sx={buttonsSx} />
            {t('action.changeStatuses')}
          </>
        ),
      },
    ];
    return filter(buttons, (button) => button.show !== false).map((button) => ({
      ...omit(button, ['show']),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasRole,
    navigate,
    printSetting,
    resetStudies,
    studyActions,
    setStudies,
    storeConfirmationData,
    study,
    setPrintDialogState,
    createPdfAndPrint,
    setChangeStatusesDialog,
    setSrDialogState,
    setOpenDicomAttributesDialog,
    compactMode,
    toggleImportWebcamDialog,
    t,
  ]);

  return (
    <Grid container={true} spacing={spacing as GridSpacing} data-tour="study-detail-buttons">
      <Grid item={true}>
        {isArray(buttons)
          ? buttons.map((button, index) => (
              <Button
                key={`button${index}`}
                size="small"
                color="inherit"
                sx={{
                  mr: compactMode ? 0.5 : 1,
                  mt: compactMode ? 0.5 : 1,
                  border: '1px solid #ccc',
                }}
                {...button}
              />
            ))
          : null}
      </Grid>
    </Grid>
  );
};

export const StudyDetailButtons = React.memo(PlainStudyDetailButtons, stateIsSame);
