import React, { useEffect, useState } from 'react';
import { filter, get, isEmpty, isFunction } from 'lodash';
import { Box, Tooltip } from '@mui/material';
import { IHumanBody } from './_types';
import { useStyles } from './_styles';
import { getDecubitFromDicom } from 'modules/Studies/StudyDetail/_api';
import { DECUBITS_CODE } from 'constants/constants';

export const HumanBody: React.FC<IHumanBody> = ({
  allDecubits,
  selectedBodypart,
  setSelectedBodypart,
  setDecubitsByPartId,
  pars,
  decubit,
  setNewSaveDecubits,
  t,
}) => {
  const [title, setTitle] = useState<string>('');
  const [bodyPart, setBodyPart] = useState<any>(null);
  const showDecubitWithTooltip = title || isEmpty(allDecubits) ? true : false;
  const style = useStyles(showDecubitWithTooltip);

  const bodyParts = DECUBITS_CODE.map((item) => ({
    id: item.value,
    tooltip: {
      title: t(`decubitsCode.${item.label}`),
      placement:
        item.label === 'larm' || item.label === 'lleg'
          ? 'left'
          : item.label === 'rarm' || item.label === 'rleg'
          ? 'right'
          : item.label === 'body'
          ? 'right-start'
          : 'top',
    },
    className: `humanBody_${item.label}`,
  }));

  const setItemsToSelect = (partId: any) => {
    const decubitsdByPartId = filter(allDecubits, { code: partId });
    setSelectedBodypart && setSelectedBodypart(partId);
    setDecubitsByPartId && setDecubitsByPartId(decubitsdByPartId);
  };

  const getTitle = (decubitForTitle: any) => {
    const bodyPart = get(decubitForTitle, 'code', '');
    const code = bodyPart ? t(`decubitsCode.${bodyPart.toLowerCase()}`) : '';
    const bodyPartExaminated = get(decubitForTitle, 'bodyPartExaminated.dicomValue', '-');
    const imageLaterality = get(decubitForTitle, 'imageLaterality.dicomValue', '-');
    const patientPosition = get(decubitForTitle, 'patientPosition.dicomValue', '-');
    const title = code || '?';
    const dicomValues = ` (${bodyPartExaminated} | ${imageLaterality} | ${patientPosition})`;
    const decubitTitle = title.indexOf('decubitsCode.') !== -1 ? '?' : title;
    setTitle(decubitTitle + dicomValues);
    setBodyPart(bodyPart);
  };

  const fillSiluette = async () => {
    try {
      const selectedBodypart = get(pars, 'selectedBodypart', '');
      const decubitForTooltip = get(pars, 'decubits', null);
      const findDecubit = get(decubit, 'decubit', null);
      if (decubitForTooltip && !selectedBodypart) {
        const studyUID = get(pars, 'studyUID', null);
        const productId = get(pars, 'productId', null);
        const serieUID = get(pars, 'serieUID', null);
        if (productId && studyUID && serieUID) {
          if (findDecubit) {
            getTitle(findDecubit);
          } else {
            const decubitFromApi = await getDecubitFromDicom(productId, studyUID, serieUID);
            if (decubitFromApi) {
              getTitle(decubitFromApi);
              if (isFunction(setNewSaveDecubits)) {
                setNewSaveDecubits({
                  id: `${productId}_${studyUID}_${serieUID}`,
                  decubit: decubitFromApi,
                });
              }
            }
          }
        }
      } else if (decubitForTooltip) {
        getTitle(decubitForTooltip);
      }
    } catch (e) {
      console.debug(e);
    }
  };

  useEffect(() => {
    fillSiluette();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showDecubitWithTooltip ? (
    <Tooltip title={title}>
      <Box sx={style.styleHumanBody}>
        {bodyParts.map((part) => (
          <Box
            key={`human_${part.id}`}
            sx={get(style, get(part, 'className', ''), {})}
            className={selectedBodypart === part.id || bodyPart === part.id ? 'selected' : ''}
          />
        ))}
      </Box>
    </Tooltip>
  ) : (
    <Box
      sx={{
        ...style.styleHumanBody,
      }}
    >
      {bodyParts.map((part) => {
        return (
          <Tooltip
            title={t(`Studies:${part.tooltip.title}`)}
            placement={(part.tooltip.placement as unknown as any) || 'top'}
            key={`human__hover_${part.id}`}
          >
            <Box
              sx={get(style, get(part, 'className', ''), {})}
              className={selectedBodypart === part.id ? 'selected' : ''}
              onClick={() => setItemsToSelect(part.id)}
            />
          </Tooltip>
        );
      })}
    </Box>
  );
};
